import {
  GetPolicyTemplateInstanceRequestParams,
  ListPolicyTemplateInstancesRequestParams,
  patch_via_put,
  PolicyTemplateInstance,
  PolicyTemplatesService,
  ResourceTypeEnum,
} from '@agilicus/angular';
import { map, Observable } from 'rxjs';

export function createNewPolicyTemplateInstance$(
  policyTemplatesService: PolicyTemplatesService,
  newPolicyTemplateInstance: PolicyTemplateInstance
): Observable<PolicyTemplateInstance | undefined> {
  return policyTemplatesService.createPolicyTemplateInstance({
    PolicyTemplateInstance: newPolicyTemplateInstance,
  });
}

export function getPolicyTemplateInstanceById$(
  policyTemplatesService: PolicyTemplatesService,
  id: string,
  orgId: string | undefined
): Observable<PolicyTemplateInstance | undefined> {
  const getDesktopRequestParams: GetPolicyTemplateInstanceRequestParams = {
    instance_id: id,
    org_id: orgId,
  };
  return policyTemplatesService.getPolicyTemplateInstance(getDesktopRequestParams);
}

export function getPolicyTemplateInstancesList$(
  policyTemplatesService: PolicyTemplatesService,
  orgId: string | undefined
): Observable<Array<PolicyTemplateInstance>> {
  const listDesktopResourceRequestParams: ListPolicyTemplateInstancesRequestParams = {
    org_id: orgId,
  };
  return policyTemplatesService.listPolicyTemplateInstances(listDesktopResourceRequestParams).pipe(
    map((resp) => {
      return resp.policy_template_instances;
    })
  );
}

export function getPolicyTemplateInstanceForResource$(
  policyTemplatesService: PolicyTemplatesService,
  orgId: string | undefined,
  resourceId: string,
  resourceType: ResourceTypeEnum
): Observable<PolicyTemplateInstance> {
  const listPolicyTemplateInstancesRequestParams: ListPolicyTemplateInstancesRequestParams = {
    org_id: orgId,
    object_id: resourceId,
    object_type: resourceType,
  };
  return policyTemplatesService.listPolicyTemplateInstances(listPolicyTemplateInstancesRequestParams).pipe(
    map((resp) => {
      return resp.policy_template_instances[0] ? resp.policy_template_instances[0] : undefined;
    })
  );
}

export function deleteExistingPolicyTemplateInstance$(
  policyTemplatesService: PolicyTemplatesService,
  id: string,
  orgId: string
): Observable<any> {
  return policyTemplatesService.deletePolicyTemplateInstance({
    instance_id: id,
    org_id: orgId,
  });
}

export function updateExistingPolicyTemplateInstance$(
  policyTemplatesService: PolicyTemplatesService,
  policyTemplateInstanceToUpdate: PolicyTemplateInstance
): Observable<PolicyTemplateInstance | undefined> {
  const putter = (policyTemplateInstance: PolicyTemplateInstance) => {
    return policyTemplatesService.replacePolicyTemplateInstance({
      instance_id: policyTemplateInstance.metadata.id,
      PolicyTemplateInstance: policyTemplateInstance,
    });
  };
  const getter = (policyTemplateInstance: PolicyTemplateInstance) => {
    return policyTemplatesService.getPolicyTemplateInstance({
      instance_id: policyTemplateInstance.metadata.id,
      org_id: policyTemplateInstance.spec.org_id,
    });
  };
  return patch_via_put(policyTemplateInstanceToUpdate, getter, putter);
}
