<h3 class="usage-metrics-heading">Usage Metrics</h3>

  <div *ngIf="hasPermissions !== undefined && !hasPermissions">
    <portal-no-permissions restrictionType="section"></portal-no-permissions>
  </div>

  <div *ngIf="hasPermissions" class="table-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Type </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef> Active / Price </th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltip]="getProductPriceTooltip()"
          [matTooltipDisabled]="getProductPriceTooltip() === ''"
        >
          {{element.active}}
        </td>
      </ng-container>

      <ng-container matColumnDef="provisioned">
        <th mat-header-cell *matHeaderCellDef> Provisioned / Price </th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltip]="getProductPriceTooltip()"
          [matTooltipDisabled]="getProductPriceTooltip() === ''"
        >
          {{element.provisioned}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>