import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ConnectorSelectionComponent } from './components/connector-selection/connector-selection.component';

@NgModule({
  imports: [CommonModule, MatOptionModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule],
  declarations: [ConnectorSelectionComponent],
  exports: [ConnectorSelectionComponent],
})
export class ConnectorBasedStepperModule {}
