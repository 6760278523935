import { PatchErrorImpl, PermissionsService, ResourcePermission, User } from '@agilicus/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, throwError } from 'rxjs';
import { getIgnoreErrorsHeader } from '../http-interceptors/http-interceptor-utils';

export function createNewResourcePermission$(
  permissionsService: PermissionsService,
  resourcePermission: ResourcePermission
): Observable<ResourcePermission> {
  return permissionsService.createResourcePermission({
    ResourcePermission: resourcePermission,
  });
}

export function createNewResourcePermissionAndHideErrorNotifications$(
  permissionsService: PermissionsService,
  resourcePermission: ResourcePermission
): Observable<ResourcePermission> {
  return permissionsService.createResourcePermission(
    {
      ResourcePermission: resourcePermission,
    },
    'body',
    getIgnoreErrorsHeader()
  );
}

export function getExistingResourcePermission$(
  permissionsService: PermissionsService,
  resourcePermission: ResourcePermission
): Observable<ResourcePermission | undefined> {
  return permissionsService
    .listResourcePermissions({
      user_id: resourcePermission.spec.user_id,
      org_id: resourcePermission.spec.org_id,
      resource_id: resourcePermission.spec.resource_id,
      resource_type: resourcePermission.spec.resource_type,
      resource_role_name: resourcePermission.spec.resource_role_name,
    })
    .pipe(
      map((resp) => {
        if (!resp?.resource_permissions || resp.resource_permissions.length === 0) {
          return undefined;
        }
        return resp.resource_permissions[0];
      })
    );
}

export function createNewResourcePermissionAndHandleConflict$(
  permissionsService: PermissionsService,
  resourcePermission: ResourcePermission
): Observable<ResourcePermission | undefined> {
  return createNewResourcePermissionAndHideErrorNotifications$(permissionsService, resourcePermission).pipe(
    catchError((err: HttpErrorResponse | Error | PatchErrorImpl) => {
      if (err instanceof HttpErrorResponse && err.status === 400 && err.error.error_code === 'UNIQUE_CONFLICT') {
        return getExistingResourcePermission$(permissionsService, resourcePermission);
      }
      return throwError(err);
    })
  );
}
