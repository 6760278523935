<portal-page-info
  pageTitle="Forwarding Services"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasAppsPermissions !== undefined && !hasAppsPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasAppsPermissions">
  <portal-table-layout
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [rowObjectName]="rowObjectName"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    (updateEvent)="updateEvent($event)"
    (updateSelection)="updateSelection($event)"
    (removeSelected)="deleteSelected($event)"
  >
  </portal-table-layout>
</div>
