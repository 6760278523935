import { Group, GroupsService, ListCombinedUserDetailsResponse, User, UsersService } from '@agilicus/angular';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  Renderer2,
  EventEmitter,
} from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import { createNewGroup$, updateExistingGroup$ } from '@app/core/api/group-api-utils';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { MapObject, UnparseData } from 'ngx-papaparse/lib/interfaces/unparse-data';
import { catchError, concatMap, EMPTY, forkJoin, map, Observable, Observer, of, Subject, takeUntil } from 'rxjs';
import { addRowNumbers, checkValidEntry, CsvData, removeInvalidColumns, removeWhitespace, UploadStatus } from '../csv-utils';
import { getFile, uploadIsCsv } from '../file-utils';
import { GroupAdminComponent } from '../group-admin/group-admin.component';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';

export interface GroupWithCount extends Group {
  count: number;
}

export interface UploadedGroup extends Group, CsvData {
  users?: string;
  count?: string;
}

export interface UserWithErrorResp {
  user: User;
  error?: any;
}

@Component({
  selector: 'portal-groups-csv',
  templateUrl: './groups-csv.component.html',
  styleUrls: ['./groups-csv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsCsvComponent implements OnInit, OnDestroy {
  @Input() public tableData: Array<any> = [];
  @Output() private updateEvent = new EventEmitter<any>();
  public groupNameToGroupMap: Map<string, Group> = new Map();
  public emailToUserMap: Map<string, User> = new Map();
  private unsubscribe$: Subject<void> = new Subject<void>();
  public allGroups$ = new Observable<Array<Group>>();
  public allUsers$ = new Observable<Array<ListCombinedUserDetailsResponse>>();
  private orgId: string;
  public buttonDescription = 'GROUPS';
  public uploadButtonTooltipText = 'Upload a csv file in format "first_name", "count", "users" with user names separated by a semicolon';
  public isUploading = false;

  public validHeaders = new Set(['first_name', 'count', 'users']);

  public progressBarController: ProgressBarController = new ProgressBarController();

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private papa: Papa,
    private renderer: Renderer2,
    public groupAdmin: GroupAdminComponent,
    private groupsService: GroupsService,
    private usersService: UsersService
  ) {}

  public ngOnInit(): void {
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    orgId$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
      this.orgId = resp;
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getGroupsToDownload(data: Array<Group>): UnparseData {
    const downloadedGroups: Array<Array<string>> = [];
    const fields: string[] = ['first_name', 'count', 'users'];
    for (const group of data) {
      const targetGroup = [group.first_name, group.members.length.toString(), group.members.map((member) => member.email).join(';'), ,];
      downloadedGroups.push(targetGroup);
    }
    const mapObject: MapObject = {
      fields: fields,
      data: downloadedGroups,
    };
    return mapObject;
  }

  public unparseDataToCsv(data: Array<Group>): string {
    const downloadedGroups = this.getGroupsToDownload(data);
    const options: UnparseConfig = {
      quotes: true,
      header: true,
      newline: '\n',
    };
    return this.papa.unparse(downloadedGroups, options);
  }

  public downloadGroups(): void {
    this.allGroups$ = this.groupAdmin.getAllGroups();
    this.allGroups$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (groups) => {
        const groupsCsv = this.unparseDataToCsv(groups);
        const link = this.renderer.createElement('a');
        const blob = new Blob([groupsCsv], { type: 'text/csv' });
        link.href = window.URL.createObjectURL(blob);
        link.download = 'groups_data.csv';
        link.click();
      },
      (error) => {
        this.notificationService.error('Failed to download groups. Please try again.');
      }
    );
  }

  public onReadGroups(event: any): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.resetProgressBar();
    this.emailToUserMap.clear();
    this.groupNameToGroupMap.clear();
    this.allUsers$ = this.groupAdmin.getAllUsers();
    this.allGroups$ = this.groupAdmin.getAllGroups();
    forkJoin([this.allUsers$, this.allGroups$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([usersResp, groupsResp]) => {
          for (const user of usersResp[0].combined_user_details) {
            this.emailToUserMap.set(user.status.user.email, user.status.user);
          }
          for (const group of groupsResp) {
            this.groupNameToGroupMap.set(group.first_name, group);
          }
          const readGroupsObservable$ = this.readGroups(event);
          if (readGroupsObservable$ === undefined) {
            return;
          }
          readGroupsObservable$.forEach((next) => {});
        },
        (error) => {
          this.notificationService.error('Failed to build group name to group map. Please try again.');
        }
      );
  }

  /**
   * Parses the csv into JSON to be submitted to the api via http requests.
   */
  public readGroups(event: any): Observable<unknown> {
    const uploadContent = getFile(event);
    if (uploadContent === undefined) {
      return EMPTY;
    }
    if (!uploadIsCsv(uploadContent)) {
      this.notificationService.error(
        'This file does not appear to be in CSV format. Please upload a CSV file or rename the file with ".csv" extension.' +
          ' File is of type "' +
          uploadContent.type +
          '". Expected type "text/csv".'
      );
      return EMPTY;
    }
    const papaObservable$ = new Observable((observer) => {
      this.papa.parse(uploadContent, {
        complete: (result) => {
          if (result.data.length === 0) {
            this.notificationService.error('No groups to upload');
            observer.complete();
            this.isUploading = false;
            return;
          }
          this.parseGroupsToUpload(result.data, observer);
        },
        error: (error) => {
          this.notificationService.error('Failed to read file. ' + error.message);
        },
        header: true,
        transformHeader: (result) => {
          const strArr = result.trim().split(/[\ ]+/);
          const newHeader = strArr.join('_').toLowerCase();
          return newHeader;
        },
        skipEmptyLines: 'greedy',
      });
    });
    return papaObservable$;
  }

  /**
   * Searches for invalid/duplicated groups, as well as non-existent users.
   * If any are found, the user is notified and the upload is canceled.
   * If all groups are valid they are uploaded via http requests.
   */
  private parseGroupsToUpload(csvParseResult: Array<UploadedGroup>, observer: Observer<UploadStatus>): void {
    const updatedCsvParseResult = removeInvalidColumns(csvParseResult, this.validHeaders);
    addRowNumbers(updatedCsvParseResult);
    if (this.checkForUndefinedEntries(updatedCsvParseResult)) {
      observer.complete();
      return;
    }
    const newUserEmailsSet: Set<string> = new Set();
    for (const group of updatedCsvParseResult) {
      removeWhitespace(group);
      this.parseUsers(group, newUserEmailsSet);
    }
    const validGroups: Array<UploadedGroup> = [];
    const invalidGroups: Array<UploadedGroup> = [];
    this.setValidAndInvalidGroups(updatedCsvParseResult, validGroups, invalidGroups);
    const duplicatedGroups = this.getDuplicatedGroups(validGroups);
    if (invalidGroups.length === 0 && duplicatedGroups.length === 0) {
      this.uploadGroups(updatedCsvParseResult, observer, newUserEmailsSet);
    } else {
      this.createUploadErrorNotification(invalidGroups, duplicatedGroups);
      observer.complete();
      this.isUploading = false;
    }
  }

  private checkForUndefinedEntries(csvParseResult: Array<UploadedGroup>): boolean {
    for (const group of csvParseResult) {
      if (group.first_name === undefined || group.users === undefined) {
        this.notificationService.error('The following CSV row is missing a field: ' + group.csvRowNumber);
        return true;
      }
    }
    return false;
  }

  /**
   * Parses the semicolon separated string of user names into
   * an array of existing user objects and a set of new user names.
   * The array of user objects is then assigned to the group's 'members' property.
   */
  private parseUsers(group: UploadedGroup, newUserEmailsSet: Set<string>): void {
    if (group.users === '') {
      group.members = [];
      return;
    }
    const userNamesArray = group.users
      .split(';')
      .map((user) => user.trim())
      .filter((userName) => !!userName);
    const existingUsersArray: Array<User> = [];
    userNamesArray.forEach((user) => {
      if (user !== '') {
        const fullUserObject = this.emailToUserMap.get(user);
        if (fullUserObject !== undefined) {
          existingUsersArray.push(fullUserObject);
        } else {
          newUserEmailsSet.add(user);
        }
      }
    });
    group.members = existingUsersArray;
    return;
  }

  private setValidAndInvalidGroups(
    csvParseResult: Array<UploadedGroup>,
    validGroups: Array<UploadedGroup>,
    invalidGroups: Array<UploadedGroup>
  ): void {
    for (const group of csvParseResult) {
      if (this.checkValidUpload(group)) {
        this.setUploadedGroupProperties(group);
        validGroups.push(group);
      } else {
        invalidGroups.push(group);
      }
    }
  }

  private setUploadedGroupProperties(group: UploadedGroup): void {
    group.org_id = this.orgId;
  }

  private checkValidUpload(group: UploadedGroup): boolean {
    if (!checkValidEntry(group.first_name, false)) {
      return false;
    }
    return true;
  }

  private getDuplicatedGroups(validGroups: Array<UploadedGroup>): Array<UploadedGroup> {
    const duplicatedGroups: Array<UploadedGroup> = [];
    for (const group of validGroups) {
      if (this.checkDuplicatedGroup(group, validGroups)) {
        duplicatedGroups.push(group);
      }
    }
    return duplicatedGroups;
  }

  private checkDuplicatedGroup(targetGroup: UploadedGroup, validGroups: Array<UploadedGroup>): boolean {
    let count = 0;
    for (const group of validGroups) {
      if (group.first_name.toLowerCase() === targetGroup.first_name.toLowerCase()) {
        count++;
      }
    }
    if (count > 1) {
      return true;
    }
    return false;
  }

  private uploadGroups(groups: Array<UploadedGroup>, observer: Observer<UploadStatus>, newUserEmailsSet: Set<string>): void {
    this.isUploading = true;
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.initializeProgressBar();
    this.changeDetector.detectChanges();
    const failedUserCreateList: Array<User> = [];
    const userAndGroupsObservable$ = this.getUserAndGroupsObservable$(groups, newUserEmailsSet, failedUserCreateList);
    this.handleGroupsObservable(userAndGroupsObservable$, observer, failedUserCreateList);
  }

  private getUserAndGroupsObservable$(
    groups: Array<UploadedGroup>,
    newUserEmailsSet: Set<string>,
    failedUserCreateList: Array<User>
  ): Observable<Array<UploadedGroup>> {
    return forkJoin(this.prepareUsersToCreate$(newUserEmailsSet)).pipe(
      concatMap((resp) => {
        for (const user of resp) {
          if (!!user && !user.error) {
            this.emailToUserMap.set(user.user.email, user.user);
          }
          if (!!user?.error) {
            failedUserCreateList.push(user.user);
          }
        }
        return forkJoin(this.prepareGroupsToUpload$(groups));
      })
    );
  }

  private prepareUsersToCreate$(newUserEmailsSet: Set<string>): Array<Observable<UserWithErrorResp> | undefined> {
    const newUserEmailsAsArray = Array.from(newUserEmailsSet);
    if (newUserEmailsAsArray.length === 0) {
      return [of(undefined)];
    }
    const newUsersAsArray: Array<User> = newUserEmailsAsArray.map((userEmail) => {
      return { email: userEmail, org_id: this.orgId };
    });
    const observablesArray$: Array<Observable<UserWithErrorResp>> = [];
    for (const user of newUsersAsArray) {
      observablesArray$.push(
        this.usersService.createUser({ User: user }).pipe(
          map((resp) => {
            return {
              user: resp,
            };
          }),
          catchError((err) => {
            return of({ user: user, error: err });
          })
        )
      );
    }
    return observablesArray$;
  }

  private prepareGroupsToUpload$(groups: Array<UploadedGroup>): Array<Observable<UploadedGroup>> {
    const totalGroupsToUpload = groups.length;
    let uploadsComplete = 0;
    const observablesArray = [];
    for (const group of groups) {
      const userEmailsList = group.users
        .split(';')
        .map((user) => user.trim())
        .filter((userName) => !!userName);
      // Get the updated list of group members, including newly created ones:
      group.members = userEmailsList.map((userEmail) => this.emailToUserMap.get(userEmail));
      // Check if group exists
      const currentGroup = this.groupNameToGroupMap.get(group.first_name);
      if (currentGroup === undefined) {
        observablesArray.push(
          createNewGroup$(this.groupsService, group).pipe(
            map((resp) => {
              uploadsComplete++;
              // Need to reassign the progressBarController in order to
              // trigger the update in the template.
              this.progressBarController = this.progressBarController.updateProgressBarValue(totalGroupsToUpload, uploadsComplete);
              this.changeDetector.detectChanges();
              return group;
            }),
            catchError((err) => {
              return of({ ...group, error: err });
            })
          )
        );
      } else {
        const new_group: Group = {
          id: currentGroup.id,
          ...group,
        };
        observablesArray.push(
          updateExistingGroup$(this.groupsService, new_group).pipe(
            map((resp) => {
              uploadsComplete++;
              // Need to reassign the progressBarController in order to
              // trigger the update in the template.
              this.progressBarController = this.progressBarController.updateProgressBarValue(totalGroupsToUpload, uploadsComplete);
              this.changeDetector.detectChanges();
              return new_group;
            }),
            catchError((err) => {
              return of({ ...new_group, error: err });
            })
          )
        );
      }
    }
    return observablesArray;
  }

  private handleGroupsObservable(
    groupsObservable: Observable<Array<UploadedGroup>>,
    observer: Observer<UploadStatus>,
    failedUserCreateList: Array<User>
  ): void {
    groupsObservable.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (respArray) => {
        const failedUploads: Array<UploadedGroup> = [];
        respArray.forEach((resp) => {
          // If the response is not an error it will not have an 'error' property
          // and, therefore, resp.error will be undefined for successful responses
          if (resp.error !== undefined) {
            observer.next(UploadStatus.FAIL);
            failedUploads.push(resp);
          } else {
            observer.next(UploadStatus.PASS);
          }
        });
        if (failedUploads.length === 0) {
          this.notificationService.success('All groups successfully uploaded');
          this.delayHideProgressBar();
        } else {
          this.notificationService.error(
            'The following groups failed to upload: "' + this.createNotificationStringFromObject(failedUploads, 'first_name') + '"'
          );
          // Stop buffering when uploads fail.
          // Need to reassign the progressBarController in order to
          // trigger the update in the template.
          this.progressBarController = this.progressBarController.onFailedUpload();
          this.changeDetector.detectChanges();
        }
      },
      (errorResp) => {
        console.log('errorResp');
        console.log(errorResp);
        this.notificationService.error('There was an error uploading the file');
      },
      () => {
        this.updateEvent.emit();
        observer.complete();
        this.isUploading = false;
        if (failedUserCreateList.length !== 0) {
          const failedUsersListString = failedUserCreateList.join(', ');
          this.notificationService.error(`Failed to create the following users: "${failedUsersListString}"`);
        }
      }
    );
  }

  private createNotificationStringFromObject(groups: Array<UploadedGroup>, displayValue: string): string {
    return groups.map((group) => group[displayValue]).join('; ');
  }

  private createUploadErrorNotification(invalidGroups: Array<UploadedGroup>, duplicatedGroups: Array<UploadedGroup>): void {
    let message = '';
    if (invalidGroups.length > 0) {
      message += 'The following CSV rows are invalid: "' + this.createNotificationStringFromObject(invalidGroups, 'csvRowNumber') + '" ';
    }
    if (duplicatedGroups.length > 0) {
      message +=
        'The following CSV rows contain duplicated groups: "' +
        this.createNotificationStringFromObject(duplicatedGroups, 'csvRowNumber') +
        '" ';
    }
    this.notificationService.error(message);
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideProgressBar(): void {
    setTimeout(() => {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }
}
