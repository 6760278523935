<portal-page-info
  pageTitle="Sub-Organisations"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasUsersPermissions !== undefined && !hasUsersPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasUsersPermissions" 
  class="sub-org-admin-container"
>
  <portal-table-layout
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [filterMenuOptions]="filterMenuOptions"
    [rowObjectName]="rowObjectName"
    [buttonsToShow]="buttonsToShow"
    [customButtons]="customButtons"
    (updateEvent)="updateEvent($event)"
    (removeSelected)="removeSelected($event)"
    (refreshDataSource)="refreshDataSource()"
  >
  </portal-table-layout>
</div>
