import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  DoCheck,
  OnDestroy,
} from '@angular/core';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { NotificationService } from '@app/core';
import { FilterChipOptions } from '../filter-chip-options';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { getValuesFromInputEventValue, pluralizeString } from '../utils';
import { InputData } from './input-data';
import { ChiplistInput } from './chiplist-input';
import {
  addChipOnAutoSelectEvent,
  areDuplicateInputValuesEntered,
  areExistingChipValuesEntered,
  areInvalidValuesEntered,
  areNonexistantOptionValuesEntered,
  createChiplistInput,
  doValuesExistInOptionsList,
  getAlreadyExistsChipValueErrorMessage,
  getFilteredValues,
  getIncompleteChipValueErrorMessage,
  getMaxChiplistLength,
  onNewChipAdded,
  updateElementFromChiplistValuesAndDetechChanges,
  updateMultiAutocompleteInputOnFirstOptionSelection,
} from './custom-chiplist-input.utils';
import { cloneDeep, isEqual } from 'lodash-es';
import { Subject, take, takeUntil } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ChiplistExpandDialogComponent, ChiplistExpandDialogData } from '../chiplist-expand-dialog/chiplist-expand-dialog.component';
import { getDefaultDialogConfig } from '../dialog-utils';
import { ChiplistExpandLoadingDialogComponent } from '../chiplist-expand-loading-dialog/chiplist-expand-loading-dialog.component';

@Component({
  selector: 'portal-custom-chiplist-input',
  templateUrl: './custom-chiplist-input.component.html',
  styleUrls: ['./custom-chiplist-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomChiplistInputComponent<T extends InputData> implements DoCheck, OnDestroy {
  @Input() public element: T;
  @Input() public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA, TAB],
  };
  @Input() public chiplistInput: ChiplistInput<T> = createChiplistInput('');
  /**
   * Set this to true when the chiplistInput is generated in the template
   * rather than a property of the component
   */
  @Input() public isTemplateGeneratedChiplistInput = false;
  @Input() public isChipRemovable = true;
  @Input() public keyTabManager: KeyTabManager = new KeyTabManager();
  @Input() public appearance = '';
  @Input() public placeholder = '';
  @Input() public removeFromAllowedValues: (element: T, chiplistInput: ChiplistInput<T>, optionValue: string) => boolean;
  @Input() public required = false;
  @Input() public showAllChips = false;
  @Input() public filteredChiplistDisplayValues: Array<any>;
  @Output() public updateEvent = new EventEmitter<T>();
  @Output() public removeChip = new EventEmitter<object | string>();
  @Output() public triggerChangeDetectionInParentComponent = new EventEmitter<any>();
  @Output() public triggerRowDirtyEvent = new EventEmitter<ChiplistInput<T>>();
  @Output() public forceRowUpdateEvent = new EventEmitter<T>();
  private previousElementCopy: T;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private localAddOnInput = true;

  public getMaxChiplistLength = getMaxChiplistLength;
  public pluralizeString = pluralizeString;

  @ViewChild('auto', { static: false }) public matAutocomplete: MatAutocomplete;
  @ViewChild('trigger', { static: false }) public matAutocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private loadingDialog: MatDialog
  ) {}

  public ngDoCheck(): void {
    if (!this.chiplistInput) {
      this.chiplistInput = createChiplistInput('');
    }
    if (!this.chiplistInput.filteredValues) {
      this.chiplistInput.filteredValues = getFilteredValues(this.chiplistInput.formControl, this.chiplistInput);
      this.changeDetector.detectChanges();
    }
    if (!isEqual(this.element, this.previousElementCopy)) {
      // If the input element, such as a row in a table, has been modified,
      // we need to manually trigger the change detection in order for the chips
      // in the chiplist to be redrawn with the correct values.
      this.chiplistInput.filteredValues = getFilteredValues(this.chiplistInput.formControl, this.chiplistInput);
      this.changeDetector.detectChanges();
      this.previousElementCopy = cloneDeep(this.element);
    }
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onRemoveChip(chipValue: any): void {
    this.removeChip.emit(chipValue);
  }

  private onSingleAutocompleteSelection(
    chipValue: string,
    inputElement: HTMLInputElement,
    chiplistInput: ChiplistInput<T>,
    element: T
  ): void {
    addChipOnAutoSelectEvent(chipValue, element, chiplistInput);
    inputElement.value = '';
    chiplistInput.formControl.setValue('');
    chiplistInput.isFormInputDirty = false;
  }

  private onMultiAutocompleteSelection(
    chipValue: string,
    inputElement: HTMLInputElement,
    chiplistInput: ChiplistInput<T>,
    element: T
  ): void {
    if (chiplistInput.isFirstMultiAutocompleteOptionSelected) {
      const newChipStringValue = chiplistInput.getDisplayValue(chiplistInput.getElementFromValue(chipValue, element));
      if (areExistingChipValuesEntered(element, chiplistInput, [newChipStringValue], this.notificationService)) {
        chiplistInput.inError = true;
        this.notificationService.error(getAlreadyExistsChipValueErrorMessage());
        return;
      }
      addChipOnAutoSelectEvent(chipValue, element, chiplistInput);
    }
    this.setMultiAutocompleteInputValue(chipValue, inputElement, chiplistInput, element);
  }

  private handleFirstOptionSelection(chipValue: string, inputElement: HTMLInputElement, chiplistInput: ChiplistInput<T>, element: T): void {
    const newInputValue = updateMultiAutocompleteInputOnFirstOptionSelection(chipValue, chiplistInput);
    inputElement.value = newInputValue;
    chiplistInput.formControl.setValue(newInputValue);
    chiplistInput.isFirstMultiAutocompleteOptionSelected = true;
    chiplistInput
      .getSecondaryFilteredValues(chiplistInput, inputElement.value)
      .pipe(takeUntil(this.unsubscribe$), take(1))
      .subscribe((secondaryOptionsList: Array<any>) => {
        if (secondaryOptionsList.length === 1) {
          this.onChiplistAutocompleteSelection(secondaryOptionsList[0], inputElement, chiplistInput, element);
        }
      });
  }

  private handleSecondOptionSelection(inputElement: HTMLInputElement, chiplistInput: ChiplistInput<T>): void {
    inputElement.value = '';
    chiplistInput.formControl.setValue('');
    chiplistInput.isFirstMultiAutocompleteOptionSelected = false;
    chiplistInput.isFormInputDirty = false;
  }

  private setMultiAutocompleteInputValue(
    chipValue: string,
    inputElement: HTMLInputElement,
    chiplistInput: ChiplistInput<T>,
    element: T
  ): void {
    if (!chiplistInput.isFirstMultiAutocompleteOptionSelected) {
      this.handleFirstOptionSelection(chipValue, inputElement, chiplistInput, element);
    } else {
      this.handleSecondOptionSelection(inputElement, chiplistInput);
    }
    setTimeout(() => {
      if (!!this.matAutocompleteTrigger) {
        // This will become undefined when we hide the chiplist autocomplete list
        // after passing the "getMaxChiplistLength"
        this.matAutocompleteTrigger.openPanel();
      }
    }, 200);
  }

  /**
   * Will return true if the input is not free form and a user enters values that do not exist in the options list.
   */
  private areNonExistentChipValuesEntered(valuesArray: Array<string>, chiplistInput: ChiplistInput<T>): boolean {
    if (!chiplistInput.isFreeform && !doValuesExistInOptionsList(valuesArray, chiplistInput)) {
      return true;
    }
    return false;
  }

  public onChiplistAutocompleteSelection(
    chipValue: string,
    inputElement: HTMLInputElement,
    chiplistInput: ChiplistInput<T>,
    element: T
  ): void {
    chiplistInput.addOnInput = false;
    this.localAddOnInput = false;
    const valuesArray = [chipValue];
    this.setCellToDirty(valuesArray, element, chiplistInput);
    if (areExistingChipValuesEntered(element, chiplistInput, valuesArray, this.notificationService)) {
      chiplistInput.inError = true;
      this.notificationService.error(getAlreadyExistsChipValueErrorMessage());
      return;
    }
    chiplistInput.autoDropdownOptionSelected = true;
    if (chiplistInput.hasMultiAutocomplete) {
      this.onMultiAutocompleteSelection(chipValue, inputElement, chiplistInput, element);
    } else {
      this.onSingleAutocompleteSelection(chipValue, inputElement, chiplistInput, element);
    }
    // If we get here, everything is good, so we update the element and chiplistInput.
    chiplistInput.inError = false;
    this.updateEventFunc(element);
  }

  /**
   * We need to delay the input event if the input has an autocomplete dropdown. When a user selects
   * from the autocomplete list it fires both a select and a blur event which results in both
   * "delayAndAddChipOnInputEvent" and "onChiplistAutocompleteSelection" being called. Both events being fired
   * right after one another can cause unexpected errors to occur.
   */
  public delayAndAddChipOnInputEvent(event: MatChipInputEvent, element: T, chiplistInput: ChiplistInput<T>): void {
    const inputElement = event.input;
    const valuesArray = getValuesFromInputEventValue(inputElement.value);
    this.setCellToDirty(valuesArray, element, chiplistInput);
    if (valuesArray.length === 0) {
      // If the user presses enter and there is no inputElement value then we want to keytab to the next inputElement,
      // so we set the inputs newChipAdded flag back to false.
      chiplistInput.newChipAdded = false;
      chiplistInput.isFormInputDirty = false;
      chiplistInput.inError = false;
      return;
    }
    setTimeout(async () => {
      if (
        (this.isTemplateGeneratedChiplistInput && this.localAddOnInput) ||
        (!this.isTemplateGeneratedChiplistInput && !!chiplistInput.addOnInput)
      ) {
        // Please note: Due to the delay, this code will never be called when the user
        // selects (clicks on) an option in the auto-complete dropdown:
        await this.onChiplistInputEvent(event, valuesArray, element, chiplistInput);
      }
      chiplistInput.addOnInput = true;
      this.localAddOnInput = true;
      this.triggerChangeDetectionInParentComponent.emit();
    }, 200);
  }

  private setCellToDirty(valuesArray: Array<string>, element: T, chiplistInput: ChiplistInput<T>): void {
    if (valuesArray.length === 0) {
      return;
    }
    element.dirty = true;
    chiplistInput.isFormInputDirty = true;
    this.triggerRowDirtyEvent.emit(chiplistInput);
  }

  private async onChiplistInputEvent(
    event: MatChipInputEvent,
    valuesArray: Array<string>,
    element: T,
    chiplistInput: ChiplistInput<T>
  ): Promise<void> {
    if (chiplistInput.hasMultiAutocomplete && valuesArray.length > 1) {
      chiplistInput.inError = true;
      this.notificationService.error('Semicolon separated lists are not supported with the "multi-dropdown" chiplist functionality');
      return;
    }
    if (areExistingChipValuesEntered(element, chiplistInput, valuesArray, this.notificationService)) {
      chiplistInput.inError = true;
      this.notificationService.error(getAlreadyExistsChipValueErrorMessage());
      return;
    }
    if (this.areNonExistentChipValuesEntered(valuesArray, chiplistInput)) {
      chiplistInput.inError = true;
      this.notificationService.error(getIncompleteChipValueErrorMessage(chiplistInput));
      return;
    }
    // We need to flag the element here when a value is entered in the chiplist input
    // so that we prevent navigating away from the screen without saving the chip value.
    element.isValid = false;
    element.resetIsValid = true;
    if (!chiplistInput.hasAutocomplete) {
      // If the input does not have an autocomplete dropdown then we can just add the chip.
      await this.addChipOnInputEvent(event, element, chiplistInput);
      return;
    }
    await this.handleChiplistInputEvent(event, chiplistInput, element);
  }

  private async handleChiplistInputEvent(event: MatChipInputEvent, chiplistInput: ChiplistInput<T>, element: T): Promise<void> {
    if (this.isTemplateGeneratedChiplistInput && !this.localAddOnInput) {
      return;
    }
    if (!chiplistInput.addOnInput) {
      return;
    }
    if (this.isOptionAlreadySelected(element, chiplistInput, event.value)) {
      chiplistInput.inError = true;
      this.notificationService.error('This option has already been selected. Please try again.');
      return;
    }
    if (chiplistInput.hasMultiAutocomplete) {
      if (chiplistInput.isFirstMultiAutocompleteOptionSelected) {
        await this.addChipOnInputEvent(event, element, chiplistInput);
      }
      this.setMultiAutocompleteInputValue(event.value, event.input, chiplistInput, element);
    } else {
      await this.addChipOnInputEvent(event, element, chiplistInput);
    }
  }

  /**
   * Adds a new chip to the chips input when the user enters a 'separatorKeysCode'
   */
  private async addChipOnInputEvent(event: MatChipInputEvent, element: T, chiplistInput: ChiplistInput<T>): Promise<void> {
    const inputElement = event.input;
    const valuesArray = getValuesFromInputEventValue(event.value);
    if (valuesArray.length === 0) {
      return;
    }
    const areInvalidValuesEnteredResult = await areInvalidValuesEntered(element, chiplistInput, valuesArray, this.notificationService);
    if (
      areDuplicateInputValuesEntered(valuesArray, this.notificationService) ||
      areNonexistantOptionValuesEntered(element, chiplistInput, valuesArray, this.notificationService) ||
      areInvalidValuesEnteredResult ||
      areExistingChipValuesEntered(element, chiplistInput, valuesArray, this.notificationService)
    ) {
      chiplistInput.inError = true;
      return;
    }
    // We need to make a copy of the element here otherwise the component will
    // freeze any arrays within the element after it has been modified once,
    // therefore, causing an error on the second update.
    const copyOfElement = cloneDeep(element);
    updateElementFromChiplistValuesAndDetechChanges(valuesArray, copyOfElement, chiplistInput, this.changeDetector);
    element[chiplistInput.name] = copyOfElement[chiplistInput.name];
    // Resets the input value so that the next chip to be entered starts as empty
    if (inputElement) {
      inputElement.value = '';
    }
    onNewChipAdded(chiplistInput);
    // If we get here, everything is good, so we update the element and chiplistInput.
    chiplistInput.inError = false;
    chiplistInput.isFormInputDirty = false;
    this.updateEventFunc(element);
  }

  public onChiplistKeyTab(currentValue: string, currentId: string, chiplistInput: ChiplistInput<T>): void {
    if (chiplistInput.autoDropdownOptionSelected) {
      // If the user is selecting an option from the dropdown
      // we do not want to "keytab" to another input so that the user can enter subsequent chips.
      chiplistInput.autoDropdownOptionSelected = false;
      return;
    }
    this.keyTabManager.keyTabChipList(currentValue, currentId, this.matAutocompleteTrigger, chiplistInput.newChipAdded);
  }

  /**
   * Checks if the option from the autocomplete dropdown has already been
   * added to the chiplist.
   */
  public isOptionAlreadySelected(element: T, chiplistInput: ChiplistInput<T>, optionValue: string): boolean {
    const chiplistValues = chiplistInput.getChiplistValues(element, chiplistInput);
    if (!chiplistValues) {
      return false;
    }
    for (const item of chiplistValues) {
      if (chiplistInput.getDisplayValue(item) === optionValue) {
        return true;
      }
    }
    return false;
  }

  public removeSelfValue(element: T, chiplistInput: ChiplistInput<T>, optionValue: string): boolean {
    if (this.removeFromAllowedValues) {
      return this.removeFromAllowedValues(element, chiplistInput, optionValue);
    }
    return false;
  }

  private updateEventFunc(element: T): void {
    this.updateEvent.emit(element);
    this.changeDetector.detectChanges();
  }

  public getInputTooltip(chiplistInput: ChiplistInput<T>): string {
    if (!chiplistInput.hasMultiAutocomplete) {
      return 'Separate multiple entries by a semicolon';
    }
    return '';
  }

  public getSortedFilteredAutocompleteValues(filteredList: Array<any>): Array<any> {
    const sortedFilteredList = filteredList.sort((lhs: any, rhs: any) => {
      return this.chiplistInput
        .getDisplayValue(lhs, this.chiplistInput)
        .localeCompare(this.chiplistInput.getDisplayValue(rhs, this.chiplistInput));
    });
    return sortedFilteredList;
  }

  public getChiplistValuesList(element: T): Array<any> {
    return this.chiplistInput.getChiplistValues(element, this.chiplistInput);
  }

  public getChiplistValuesToDisplayInInputList(element: T): Array<any> {
    const allChips = this.getChiplistValuesList(element);
    let filteredChips = [];
    if (!!this.filteredChiplistDisplayValues) {
      for (const value of allChips) {
        if (this.filteredChiplistDisplayValues.includes(value)) {
          filteredChips.push(value);
        }
      }
    } else {
      filteredChips = allChips;
    }
    if (this.showAllChips) {
      return filteredChips;
    }
    return filteredChips.slice(0, getMaxChiplistLength());
  }

  public disableDefaultChiplistEditing(element: T): boolean {
    return this.getChiplistValuesList(element).length > getMaxChiplistLength() && !this.showAllChips;
  }

  private onOpenChiplistExpandDialog(dialogData: ChiplistExpandDialogData<T>): void {
    const dialogRef = this.dialog.open(
      ChiplistExpandDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
        width: '90%',
        maxWidth: '100%',
        height: '950px',
      })
    );
    dialogRef.afterClosed().subscribe(() => {
      this.forceRowUpdateEvent.emit(this.element);
    });
  }

  public openChiplistExpandDialog(): void {
    const dialogData: ChiplistExpandDialogData<T> = {
      chiplistInput: this.chiplistInput,
      element: this.element,
      isChipRemovable: this.isChipRemovable,
      removeFromAllowedValues: this.removeFromAllowedValues,
    };
    if (!!this.chiplistInput.getCustomAllowedValuesFromApi) {
      const dialogRef = this.loadingDialog.open(
        ChiplistExpandLoadingDialogComponent,
        getDefaultDialogConfig({
          data: dialogData,
          width: '50%',
        })
      );
      dialogRef.afterClosed().subscribe((updatedData: ChiplistExpandDialogData<T>) => {
        this.onOpenChiplistExpandDialog(updatedData);
      });
    } else {
      this.onOpenChiplistExpandDialog(dialogData);
    }
  }

  public getNumberOfHiddenChips(): string {
    const numberOfHiddenChips = this.getChiplistValuesList(this.element).length - getMaxChiplistLength();
    return numberOfHiddenChips.toString();
  }

  public getHiddenChipsTooltipText(): string {
    return `Click to view/modify all ${pluralizeString(this.chiplistInput.displayName)}`;
  }

  public getChipTooltipText(element: T): string {
    if (this.disableDefaultChiplistEditing(this.element)) {
      return this.getHiddenChipsTooltipText();
    }
    return this.chiplistInput.getTooltip(element, this.chiplistInput);
  }

  public preventOpeningAutocompleteDropdownOnDialogConfigButtonClick(event: PointerEvent): void {
    event.stopPropagation();
  }
}
