import { AuthAudits, ListAuthAuditsResponse, ListUsersResponse } from '@agilicus/angular';
import { InputData } from './custom-chiplist-input/input-data';
import { sortDateChronologicallyByKey, sortDateReverseChronologicallyByKey } from './date-utils';
import { OptionalTableElement } from './optional-types';
import { getDefaultNestedDataProperties, getDefaultTableProperties } from './table-layout-utils';
import { Column, createReadonlyColumn, ReadonlyColumn } from './table-layout/column-definitions';
import { TableElement } from './table-layout/table-element';
import { groupByKey } from './utils';

export interface AuthAuditsPlusEmail extends AuthAudits {
  email?: string;
}
export interface AuthAuditData {
  request_id?: string;
  time?: Date;
  event_name?: string;
  result?: string;
  client_id?: string;
  source_ip?: string;
  session_audit_details?: Array<AuthAuditsPlusEmail>;
  email?: string;
  user_id?: string;
}

export interface AuthAuditElement extends AuthAuditData, TableElement {}

export interface SessionAuditDetailElement extends InputData, OptionalTableElement {
  parentId: string | number;
  request_id?: string;
  time?: Date;
  event_name?: string;
  result?: string;
  client_id?: string;
  source_ip?: string;
  token_id?: string;
  trace_id?: string;
  upstream_idp?: string;
  user_agent?: string;
  application_name?: string;
  email?: string;
  user_id?: string;
}

export function getAuthAuditsKeyList(): Array<string> {
  return ['time', 'email', 'event_name', 'result', 'client_id', 'source_ip', 'request_id', 'user_id'];
}

export function getAuthAuditsPlusEmailList(
  authAuditsResponse: ListAuthAuditsResponse,
  usersResponse: ListUsersResponse
): Array<AuthAuditsPlusEmail> {
  const records = !!authAuditsResponse?.auth_audits ? authAuditsResponse.auth_audits : [];
  const list: Array<AuthAuditsPlusEmail> = records.map((record) => {
    const targetUser = !!usersResponse?.users ? usersResponse?.users.find((user) => user.id === record.user_id) : undefined;
    return { ...record, email: !!targetUser?.email ? targetUser.email : record.user_id };
  });
  return list;
}

export function getAuditElements(
  authAuditsPlusEmailList: Array<AuthAuditsPlusEmail>,
  initializeNestedAuthAuditColumnDefs: (nestedColumnDefs: Map<string, Column<TableElement>>) => void
): Array<AuthAuditElement> | undefined {
  if (!authAuditsPlusEmailList) {
    return undefined;
  }
  const elementsList: Array<AuthAuditElement> = [];
  const auditRecordsGroupedByRequestId: Map<string, Array<AuthAuditsPlusEmail>> = groupByKey<AuthAuditsPlusEmail>(
    authAuditsPlusEmailList,
    'request_id'
  );
  for (let value of auditRecordsGroupedByRequestId.values()) {
    const sortedAuthAuditsPlusEmail = sortDateChronologicallyByKey(value, 'time');
    const keyList = getAuthAuditsKeyList();
    // Need to add "getDefaultTableProperties" due to type safety.
    // Just passing in 0 here since the index will be set below after re-sorting the list.
    let summaryRow: AuthAuditElement = { ...getDefaultTableProperties(0) };
    for (const item of sortedAuthAuditsPlusEmail) {
      for (const targetKey of keyList) {
        if (!summaryRow[targetKey] && !!item[targetKey]) {
          summaryRow[targetKey] = item[targetKey];
        }
      }
    }
    summaryRow.session_audit_details = sortedAuthAuditsPlusEmail;
    summaryRow.result = sortedAuthAuditsPlusEmail[sortedAuthAuditsPlusEmail.length - 1]?.result;
    elementsList.push(summaryRow);
  }
  const sortedElementsList: Array<AuthAuditElement> = sortDateReverseChronologicallyByKey(elementsList, 'time');
  for (let i = 0; i < sortedElementsList.length; i++) {
    sortedElementsList[i] = {
      ...sortedElementsList[i],
      ...getDefaultTableProperties(i),
    };
    setNestedTableData(sortedElementsList[i], initializeNestedAuthAuditColumnDefs.bind(this));
  }
  return sortedElementsList;
}

export function setNestedTableData(
  element: AuthAuditElement,
  initializeNestedAuthAuditColumnDefs: (nestedColumnDefs: Map<string, Column<TableElement>>) => void
): void {
  element.expandedData = {
    ...getDefaultNestedDataProperties(element),
    nestedRowObjectName: 'AUDIT DETAIL',
    hideNestedFilter: true,
  };
  initializeNestedAuthAuditColumnDefs(element.expandedData.nestedColumnDefs);
  for (let i = 0; i < element.session_audit_details.length; i++) {
    const sessionAuditDetail = element.session_audit_details[i];
    const nestedElement = createSessionAuditDetailElement(sessionAuditDetail, i, element);
    element.expandedData.nestedTableData.push(nestedElement);
  }
}

export function createSessionAuditDetailElement(
  item: AuthAuditsPlusEmail,
  index: number,
  parentElement: AuthAuditElement
): SessionAuditDetailElement {
  const data: SessionAuditDetailElement = {
    ...getDefaultTableProperties(index),
    ...item,
    parentId: parentElement.index,
  };
  return data;
}

/**
 * Parent & Nested Table Column
 */
export function getAuthAuditTimeColumn(): ReadonlyColumn<InputData> {
  const timeColumn = createReadonlyColumn('time');
  timeColumn.displayName = 'Timestamp';
  return timeColumn;
}

/**
 * Parent & Nested Table Column
 */
export function getAuthAuditEventNameColumn(): ReadonlyColumn<InputData> {
  const eventNameColumn = createReadonlyColumn('event_name');
  eventNameColumn.displayName = 'Event';
  return eventNameColumn;
}

/**
 * Parent & Nested Table Column
 */
export function getAuthAuditResultColumn(): ReadonlyColumn<InputData> {
  const resultColumn = createReadonlyColumn('result');
  resultColumn.displayName = 'Result';
  return resultColumn;
}

/**
 * Parent & Nested Table Column
 */
export function getAuthAuditClientIdColumn(): ReadonlyColumn<InputData> {
  const clientIdColumn = createReadonlyColumn('client_id');
  clientIdColumn.displayName = 'Client Id';
  return clientIdColumn;
}

/**
 * Parent & Nested Table Column
 */
export function getAuthAuditSourceIpColumn(): ReadonlyColumn<InputData> {
  const sourceIpColumn = createReadonlyColumn('source_ip');
  sourceIpColumn.displayName = 'Source IP';
  return sourceIpColumn;
}

/**
 * Nested Table Column
 */
export function getAuthAuditTokenIdColumn(): ReadonlyColumn<InputData> {
  const tokenIdColumn = createReadonlyColumn('token_id');
  return tokenIdColumn;
}

/**
 * Nested Table Column
 */
export function getAuthAuditTraceIdColumn(): ReadonlyColumn<InputData> {
  const traceIdColumn = createReadonlyColumn('trace_id');
  return traceIdColumn;
}

/**
 * Nested Table Column
 */
export function getAuthAuditUpstreamIdpColumn(): ReadonlyColumn<InputData> {
  const upstreamIdpColumn = createReadonlyColumn('upstream_idp');
  return upstreamIdpColumn;
}

/**
 * Nested Table Column
 */
export function getAuthAuditUserAgentColumn(): ReadonlyColumn<InputData> {
  const userAgentColumn = createReadonlyColumn('user_agent');
  userAgentColumn.displayName = 'Device/Browser Used';
  return userAgentColumn;
}

/**
 * Nested Table Column
 */
export function getAuthAuditApplicationNameColumn(): ReadonlyColumn<InputData> {
  const applicationNameColumn = createReadonlyColumn('application_name');
  applicationNameColumn.displayName = 'Application Name';
  return applicationNameColumn;
}
