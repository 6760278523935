import {
  PolicyTemplateInstance,
  MFAPolicyTemplate,
  PolicyTemplateInstanceSpec,
  SourceInfoPolicyTemplate,
  SimpleResourcePolicyTemplate,
  SimpleResourcePolicyTemplateStructure,
  SimpleResourcePolicyTemplateStructureNode,
  RuleScopeEnum,
  RuleConfig,
  Application,
} from '@agilicus/angular';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import { AppState } from '@app/core/core.state';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { PolicyTemplateInstanceState } from '@app/core/policy-template-instance-state/policy-template-instance.reducer';
import { PolicyTemplateWithLabels } from '@app/shared/components/policy-template-with-labels';
import { ResourceAndLabelsResponse } from '@app/shared/components/resource-overview/resource-overview.component';
import { ResourceWithLabels } from '@app/shared/components/resource-with-labels';
import { TableElement } from '@app/shared/components/table-layout/table-element';
import { capitalizeFirstLetter, generateRandomUuid, replaceUnderscoreWithHyphen } from '@app/shared/components/utils';
import { MemoizedSelector, select, Store } from '@ngrx/store';
import { combineLatest, concatMap, Observable, of } from 'rxjs';
import { PolicyTemplateType } from './policy-template-type';

export interface TemplateTypeOptionData {
  type: PolicyTemplateType;
  text: string;
  description: string;
}

export interface ResourceTableElement extends ResourceElementWithPolicies, TableElement {}

export interface ResourceElementWithPolicies {
  policies: Array<PolicyTemplateInstanceWithLabels>;
  previousPolicies: Array<PolicyTemplateInstanceWithLabels>;
  backingResourceWithLabels: ResourceWithLabels;
}

export interface PolicyTemplateInstanceSpecWithLabels extends PolicyTemplateInstanceSpec {
  template: PolicyTemplateWithLabels;
}

export interface PolicyTemplateInstanceWithLabels extends PolicyTemplateInstance {
  spec: PolicyTemplateInstanceSpecWithLabels;
}

export interface PolicyTemplateInstanceSpecResource extends PolicyTemplateInstanceSpec {
  template: SimpleResourcePolicyTemplate;
}

export interface PolicyTemplateInstanceResource extends PolicyTemplateInstance {
  spec: PolicyTemplateInstanceSpecResource;
}

export interface PolicyUpdateData {
  policies_to_add_label_to: Array<PolicyTemplateInstanceWithLabels>;
  policies_to_remove_from_resource: Array<PolicyTemplateInstanceWithLabels>;
}

export interface ResourceAndLabelsAndPolicyResponse extends ResourceAndLabelsResponse {
  policyUpdateData?: PolicyUpdateData;
}

export interface PolicyRuleCommonTableElement extends TableElement {
  scope?: RuleScopeEnum;
  comments?: string;
  priority?: number | string;
  name?: string;
  backingRule: RuleConfig;
}

export function getTemplateTypeOptionData(): Array<TemplateTypeOptionData> {
  return [
    {
      type: PolicyTemplateType.mfa,
      text: getTemplateTypeOptionValueText(PolicyTemplateType.mfa),
      description: `Configure the frequency of multi-factor authentication for a specified list of resources`,
    },
    {
      type: PolicyTemplateType.source_info,
      text: getTemplateTypeOptionValueText(PolicyTemplateType.source_info),
      description: `Configure the IP subnets and/or country codes that requests are allowed to come from for a specified list of resources`,
    },
    // TODO: add more template types when available in api:
  ];
}

export function getTemplateTypeOptionValueText(policyTemplateType: PolicyTemplateType): string {
  if (policyTemplateType === PolicyTemplateType.mfa) {
    return 'Multi-factor authentication';
  }
  if (policyTemplateType === PolicyTemplateType.source_info) {
    return 'Device information';
  }
  // TODO: add more template types when available in api:

  return capitalizeFirstLetter(policyTemplateType);
}

export function getTemplateDescriptiveText(templateType: string): string {
  for (const templateData of getTemplateTypeOptionData()) {
    if (templateData.type === templateType) {
      return templateData.description;
    }
  }
  return '';
}

export function getLabelsListAsString(template: PolicyTemplateWithLabels): string {
  return template.labels.join('; ');
}

export function getSourceSubnetsListAsString(template: SourceInfoPolicyTemplate): string {
  const sourceSubnetsList = template?.source_subnets;
  return !!sourceSubnetsList ? sourceSubnetsList.join('; ') : '';
}

export function getIsoCountryCodesListAsString(template: SourceInfoPolicyTemplate): string {
  const isoCountryCodesList = template?.iso_country_codes;
  return !!isoCountryCodesList ? isoCountryCodesList.join('; ') : '';
}

export function getDetailedMfaTemplateDefinition(policyTemplateInstance: PolicyTemplateInstance): string {
  const mfaPolicyTemplate = policyTemplateInstance.spec.template as MFAPolicyTemplate;
  const labelsString = getLabelsListAsString(mfaPolicyTemplate);
  let definitionText = `Requests multi-factor authentication every ${mfaPolicyTemplate.seconds_since_last_challenge} seconds`;
  if (!!labelsString) {
    definitionText = `${definitionText} for resources with label(s) "${labelsString}"`;
  }
  return definitionText;
}

export function getDetailedSourceInfoTemplateDefinition(policyTemplateInstance: PolicyTemplateInstance): string {
  const template = policyTemplateInstance.spec.template as SourceInfoPolicyTemplate;
  const labelsString = getLabelsListAsString(template);
  const ipSubnetsAsString = getSourceSubnetsListAsString(template);
  const countryCodesAsString = getIsoCountryCodesListAsString(template);
  const invertValue = template.invert !== undefined ? template.invert : false;
  let definitionText = ``;
  if (!ipSubnetsAsString && !countryCodesAsString) {
    if (!invertValue) {
      definitionText = `Will not deny requests from any IP subnet or country code`;
    } else {
      definitionText = `Will deny requests from all IP subnets and country codes`;
    }
  } else if (!!ipSubnetsAsString && !countryCodesAsString) {
    definitionText = `Will ${!invertValue ? 'deny' : 'only allow'} requests from IP subnet(s) "${ipSubnetsAsString}"`;
  } else if (!ipSubnetsAsString && !!countryCodesAsString) {
    definitionText = `Will ${!invertValue ? 'deny' : 'only allow'} requests from country code(s) "${countryCodesAsString}"`;
  } else {
    definitionText = `Will ${!invertValue ? 'deny' : 'only allow'} requests from IP subnet(s) "${ipSubnetsAsString}" and 
    country code(s) "${countryCodesAsString}"`;
  }
  if (!!labelsString) {
    definitionText = `${definitionText} for resources with label(s) "${labelsString}"`;
  }
  return definitionText;
}

export function getDetailedTemplateDefinitionBasedOnType(policyTemplateInstance: PolicyTemplateInstance): string {
  const templateType = policyTemplateInstance?.spec?.template?.template_type ? policyTemplateInstance?.spec?.template.template_type : '';
  if (templateType === PolicyTemplateType.mfa) {
    return getDetailedMfaTemplateDefinition(policyTemplateInstance);
  }
  if (templateType === PolicyTemplateType.source_info) {
    return getDetailedSourceInfoTemplateDefinition(policyTemplateInstance);
  }
  return getTemplateDescriptiveText(templateType);
}

export function getFormatedTemplateType(type: string): string | undefined {
  if (!!type) {
    if (type === PolicyTemplateType.mfa) {
      return 'MFA';
    }
    if (type === PolicyTemplateType.source_info) {
      return 'Device Info';
    }
    // TODO: add more template types when available in api:
    return capitalizeFirstLetter(type);
  }
  return undefined;
}

export function getTemplateTypeFromFormatedType(type: string): string | undefined {
  if (!!type) {
    if (type === 'MFA') {
      return PolicyTemplateType.mfa;
    }
    return type.toLowerCase();
  }
  return undefined;
}

export function getFormatedTemplateTypeFromInstance(policyTemplateInstance: PolicyTemplateInstance): string | undefined {
  const type = policyTemplateInstance?.spec?.template?.template_type;
  return getTemplateTypeOptionValueText(type as PolicyTemplateType);
}

/**
 * Will take in a Policy and output a string in the format of `resource_name(resource_type)`
 */
export function getPolicyNameAndTypeString(policyTemplateInstance: PolicyTemplateInstance | undefined): string {
  const policyName = policyTemplateInstance?.spec?.name;
  if (!policyName) {
    return '';
  }
  const policyType = policyTemplateInstance?.spec?.template?.template_type;
  if (!policyType) {
    return policyName;
  }
  return `${policyName}(${getFormatedTemplateType(policyType)})`;
}

export function getPolicyNameFromPolicyNameAndTypeString(policyNameAndTypeString: string): string {
  return policyNameAndTypeString.substring(0, policyNameAndTypeString.indexOf('('));
}

export function getPolicyTypeFromPolicyNameAndTypeString(policyNameAndTypeString: string): string {
  return policyNameAndTypeString.substring(policyNameAndTypeString.indexOf('('), policyNameAndTypeString.indexOf(')'));
}

export function getPolicyTemplateLabelNameFromPolicy(policy: PolicyTemplateInstance): string {
  return `tmpl-${getFormattedPolicyTemplateLabelNameText(policy.spec.template.template_type)}-${getFormattedPolicyTemplateLabelNameText(
    policy.spec.name
  )}`;
}

export function getFormattedPolicyTemplateLabelNameText(templateType: string): string {
  return replaceUnderscoreWithHyphen(templateType);
}

export function getPoliciesToAddAndRemove(
  currentPoliciesList: Array<PolicyTemplateInstanceWithLabels>,
  previousPoliciesList: Array<PolicyTemplateInstanceWithLabels>
): PolicyUpdateData {
  const addToPoliciesList: Array<PolicyTemplateInstanceWithLabels> = [];
  const removeFromPoliciesList: Array<PolicyTemplateInstanceWithLabels> = [];
  for (const policy of currentPoliciesList) {
    const previousPolicy = previousPoliciesList.find((previousPolicy) => previousPolicy.metadata.id === policy.metadata.id);
    if (!previousPolicy) {
      addToPoliciesList.push(policy);
    }
  }
  for (const previousPolicy of previousPoliciesList) {
    const currentPolicy = currentPoliciesList.find((policy) => policy.metadata.id === previousPolicy.metadata.id);
    if (!currentPolicy) {
      removeFromPoliciesList.push(previousPolicy);
    }
  }
  return {
    policies_to_add_label_to: addToPoliciesList,
    policies_to_remove_from_resource: removeFromPoliciesList,
  };
}

export function getTargetPolicyTemplateInstanceResource(
  policyTemplateInstanceResourceList: PolicyTemplateInstanceResource[],
  targetResourceId: string | undefined
): PolicyTemplateInstanceResource | undefined {
  return policyTemplateInstanceResourceList.find((policyTemplateResource) => policyTemplateResource.spec.object_id === targetResourceId);
}

/**
 * We need to load the policy rules first to check if the rule
 * exists as a policy rule, otherwise it will load the old rule version
 * first
 */
export function getPolicyDataBeforeApplicationInit$(
  store: Store<AppState>,
  selectPolicyTemplateInstanceResourcesList: MemoizedSelector<
    object,
    PolicyTemplateInstanceResource[],
    (s1: PolicyTemplateInstance[]) => PolicyTemplateInstanceResource[]
  >,
  selectPolicyTemplateInstanceRefreshDataValue: MemoizedSelector<object, number, (s1: PolicyTemplateInstanceState) => number>
): Observable<[PolicyTemplateInstanceResource[], number]> {
  store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
  const policyTemplateInstanceResourceListState$ = store.pipe(select(selectPolicyTemplateInstanceResourcesList));
  const refreshPolicyTemplateInstanceDataState$ = store.pipe(select(selectPolicyTemplateInstanceRefreshDataValue));
  return combineLatest([policyTemplateInstanceResourceListState$, refreshPolicyTemplateInstanceDataState$]).pipe(
    concatMap(([policyTemplateInstanceResourceListStateResp, refreshPolicyTemplateInstanceDataStateResp]) => {
      if (refreshPolicyTemplateInstanceDataStateResp !== 0) {
        store.dispatch(new ActionApiApplicationsInitApplications());
        return combineLatest([of(policyTemplateInstanceResourceListStateResp), of(refreshPolicyTemplateInstanceDataStateResp)]);
      }
      return combineLatest([of(undefined), of(undefined)]);
    })
  );
}

export function getRuleNegatedTooltipText(): string {
  return `Setting this will cause the rule to evaluate to true if its conditions do not match`;
}

export function getPolicyStructureFromTableData<T extends PolicyRuleCommonTableElement>(
  tableData: Array<T>
): Array<SimpleResourcePolicyTemplateStructure> {
  const policyStructureList: Array<SimpleResourcePolicyTemplateStructure> = [];
  for (const element of tableData) {
    const policyStructure: SimpleResourcePolicyTemplateStructure = {
      name: !!element.backingRule.name ? element.backingRule.name : element.name,
      root_node: {
        priority: parseInt(element.priority as string, 10),
        rule_name: !!element.backingRule.name ? element.backingRule.name : element.name,
        children: [],
      },
    };
    const nestedTableData: Array<T> = element.expandedData.nestedTableData;
    for (const nestedRuleElement of nestedTableData) {
      const nestedPolicyStructureNode: SimpleResourcePolicyTemplateStructureNode = {
        priority: parseInt(nestedRuleElement.priority as string, 10),
        rule_name: !!nestedRuleElement.backingRule.name ? nestedRuleElement.backingRule.name : nestedRuleElement.name,
        children: [],
      };
      policyStructure.root_node.children.push(nestedPolicyStructureNode);
    }
    policyStructureList.push(policyStructure);
  }
  return policyStructureList;
}

export function getNewApplicationEmptyPolicy(application: Application): PolicyTemplateInstance {
  const simpleResourcePolicyTemplate: SimpleResourcePolicyTemplate = {
    template_type: PolicyTemplateType.simple_resource,
    policy_structure: [],
    rules: [],
  };
  const newPolicyName = generateRandomUuid();
  const newPolicyTemplateInstance: PolicyTemplateInstance = {
    spec: {
      name: newPolicyName,
      object_id: application.id,
      object_type: 'application',
      org_id: application.org_id,
      template: simpleResourcePolicyTemplate,
    },
  };
  return newPolicyTemplateInstance;
}
