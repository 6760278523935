import { ResetUserIdentityRequestParams, User, UsersService } from '@agilicus/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationService } from '@app/core';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';
import { catchError, of, Subject, takeUntil, throwError } from 'rxjs';
import { UserElement } from '../user-admin/user-admin.component';

export interface UserIdentityDialogData {
  userElement: UserElement;
  orgId: string;
}

@Component({
  selector: 'portal-user-identity-dialog',
  templateUrl: './user-identity-dialog.component.html',
  styleUrls: ['./user-identity-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIdentityDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public userIdentityFormGroup: FormGroup;
  public errorMessage = '';
  public saveDisabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserIdentityDialogData,
    private dialogRef: MatDialogRef<UserIdentityDialogComponent>,
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private appErrorHandler: AppErrorHandler,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.userIdentityFormGroup = this.formBuilder.group({
      email: [this.data.userElement.email, [Validators.required]],
    });
  }

  private updateUserIdentity(newIdentifier: string): void {
    const resetUserIdentityRequestParams: ResetUserIdentityRequestParams = {
      user_id: this.data.userElement.id,
      ResetUserIdentityRequest: {
        org_id: this.data.orgId,
        new_identifier: newIdentifier,
      },
    };
    this.usersService
      .resetUserIdentity(resetUserIdentityRequestParams, 'body', getIgnoreErrorsHeader())
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.errorMessage = this.appErrorHandler.getErrorMessageForUserDisplay(err, `Failed to update the user's identity.`);
          return of(undefined);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: User | undefined) => {
        this.saveDisabled = false;
        if (!resp) {
          this.changeDetector.detectChanges();
          return;
        }
        this.errorMessage = '';
        this.data.userElement.email = resp.email;
        this.dialogRef.close(true);
        this.notificationService.success(`User "${resp.email}" was successfully updated`);
      });
  }

  public onSaveClick(): void {
    this.saveDisabled = true;
    const formValue = this.userIdentityFormGroup.get('email').value;
    this.updateUserIdentity(formValue.trim());
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }
}
