import { PatchErrorImpl } from '@agilicus/angular';
import { Action } from '@ngrx/store';
import { GuidToSavedStateMap, IDTypeBase } from './state-driven-crud';

export enum CrudActions {
  INIT_EMPTY = '[CRUD_INIT_EMPTY]',
  CREATE = '[CRUD_CREATE]',
  UPDATE = '[CRUD_UPDATE]',
  UPDATE_LIST = '[CRUD_UPDATE_LIST]',
  DELETE = '[CRUD_DELETE]',
  DELETE_LIST = '[CRUD_DELETE_LIST]',
  LIST = '[CRUD_LIST]',
  GET = '[CRUD_GET]',
  SET = '[CRUD_SET]',
  SAVE_FINISHED = '[CRUD_SAVE_FINISHED]',
  SAVE_LIST_HANDLER = '[CRUD_SAVE_LIST_HANDLER]',
  SAVE_LIST_FINISHED = '[CRUD_SAVE_LIST_FINISHED]',
  DELETE_FINISHED = '[CRUD_DELETE_FINISHED]',
  DELETE_LIST_HANDLER = '[CRUD_DELETE_LIST_HANDLER]',
  DELETE_LIST_FINISHED = '[CRUD_DELETE_LIST_FINISHED]',
  TRY_NEXT_SAVE = '[CRUD_TRY_NEXT_SAVE]',
  TRY_NEXT_SAVE_LIST = '[CRUD_TRY_NEXT_SAVE_LIST]',
  TRY_NEXT_SAVE_LIST_HANDLER = '[CRUD_TRY_NEXT_SAVE_LIST_HANDLER]',
  FAILED_SAVE = '[CRUD_FAILED_SAVE]',
  FAILED_SAVE_LIST = '[CRUD_FAILED_SAVE_LIST]',
  RETRY_SAVE = '[CRUD_RETRY_SAVE]',
  RETRY_SAVE_LIST = '[CRUD_RETRY_SAVE_LIST]',
  MAINTAIN_STATE = '[CRUD_MAINTAIN_STATE]',
  RESET_STATE = '[CRUD_RESET_STATE]',
  RESET_LIST_STATE = '[CRUD_RESET_LIST_STATE]',
  FAILED_DELETE = '[CRUD_FAILED_DELETE]',
  FAILED_DELETE_LIST = '[CRUD_FAILED_DELETE_LIST]',
  RETRY_DELETE = '[CRUD_RETRY_DELETE]',
  RETRY_DELETE_LIST = '[CRUD_RETRY_DELETE_LIST]',
}

export class InitEmptyCrudStateAction implements Action {
  public readonly type = CrudActions.INIT_EMPTY;
  constructor() {}
}

export class CreateAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly obj: DataType,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean = true
  ) {}
}

export class DeleteAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly obj: DataType
  ) {}
}

export class DeleteListAction<DataType> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly objs: Array<DataType>,
    public readonly notifyUser: boolean
  ) {}
}

export class UpdateAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly obj: DataType,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean = false,
    public readonly forceNextSave: boolean = false
  ) {}
}

export class UpdateListAction<DataType> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly objs: Array<DataType>,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean = false,
    public readonly forceNextSave: boolean = false
  ) {}
}

export class GetAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly orgId: string,
    public readonly blankSlate: boolean | undefined
  ) {}
}

export class ListAction implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly orgId: string,
    public readonly blankSlate: boolean | undefined
  ) {}
}

export class SetAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly obj: DataType
  ) {}
}

export class SaveFinishedAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly savedVersion: number,
    public readonly savedObj: DataType,
    public readonly guid: IDType,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class SaveListHandlerAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guidToSavedStateMap: GuidToSavedStateMap<IDType>,
    public readonly savedObjs: Array<DataType>,
    public readonly errors: Array<Error | PatchErrorImpl>,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class SaveListFinishedAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guidToSavedStateMap: GuidToSavedStateMap<IDType>,
    public readonly savedObjs: Array<DataType>,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class DeleteFinishedAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly version: number,
    public readonly obj: DataType,
    public readonly guid: IDType,
    public readonly notifyUser: boolean
  ) {}
}

export class DeleteListHandlerAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guidToSavedStateMap: GuidToSavedStateMap<IDType>,
    public readonly successIds: Array<IDType>,
    public readonly failedIds: Array<IDType>,
    public readonly notifyUser: boolean
  ) {}
}

export class DeleteListFinishedAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guidToSavedStateMap: GuidToSavedStateMap<IDType>,
    public readonly ids: Array<IDType>,
    public readonly notifyUser: boolean
  ) {}
}

export class TryNextSaveAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly version: number,
    public readonly guid: IDType,
    public readonly error: Error | PatchErrorImpl,
    public readonly errorMessage: string,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class TryNextSaveListAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guidToSavedStateMap: GuidToSavedStateMap<IDType>,
    readonly errors: Array<Error | PatchErrorImpl>,
    public readonly errorMessage: string,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class TryNextSaveListHandlerAction<DataType, IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guidToSavedStateMap: GuidToSavedStateMap<IDType>,
    public readonly objsToSave: Array<DataType>,
    public readonly failedSaveObjs: Array<DataType>,
    public readonly errorMessage: string,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class FailedSaveAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly error: Error | PatchErrorImpl,
    public readonly errorMessage: string,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class FailedSaveListAction<DataType> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly failedSaveObjs: Array<DataType>,
    public readonly errorMessage: string,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean
  ) {}
}

export class FailedDeleteAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly error: Error | PatchErrorImpl,
    public readonly errorMessage: string
  ) {}
}

export class FailedDeleteListAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly ids: Array<IDType>,
    public readonly errorMessage: string
  ) {}
}

export class RetrySaveAction<IDType extends IDTypeBase> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly guid: IDType,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean,
    public readonly forceNextSave: boolean = true
  ) {}
}

export class RetrySaveListAction<DataType> implements Action {
  constructor(
    public readonly type: string,
    public readonly crudRegistyName: string,
    public readonly objs: Array<DataType>,
    public readonly notifyUser: boolean,
    public readonly refreshData: boolean,
    public readonly forceNextSave: boolean = true
  ) {}
}

export class RetryDeleteAction<IDType extends IDTypeBase> implements Action {
  constructor(public readonly type: string, public readonly crudRegistyName: string, public readonly guid: IDType) {}
}

export class RetryDeleteListAction<IDType extends IDTypeBase> implements Action {
  constructor(public readonly type: string, public readonly crudRegistyName: string, public readonly ids: Array<IDType>) {}
}

export class ResetStateAction<IDType extends IDTypeBase> implements Action {
  constructor(public readonly type: string, public readonly crudRegistyName: string, public readonly guid: IDType) {}
}

export class ResetListStateAction<IDType extends IDTypeBase> implements Action {
  constructor(public readonly type: string, public readonly crudRegistyName: string, public readonly ids: Array<IDType>) {}
}

export class MaintainStateAction implements Action {
  constructor(public readonly type: string, public readonly crudRegistyName: string) {}
}

export type CrudAction<DataType, IDType extends IDTypeBase> =
  | InitEmptyCrudStateAction
  | CreateAction<DataType, IDType>
  | DeleteAction<DataType, IDType>
  | DeleteListAction<DataType>
  | UpdateAction<DataType, IDType>
  | UpdateListAction<DataType>
  | GetAction<IDType>
  | ListAction
  | SetAction<DataType, IDType>
  | SaveFinishedAction<DataType, IDType>
  | SaveListHandlerAction<DataType, IDType>
  | SaveListFinishedAction<DataType, IDType>
  | DeleteFinishedAction<DataType, IDType>
  | DeleteListHandlerAction<IDType>
  | DeleteListFinishedAction<IDType>
  | TryNextSaveAction<IDType>
  | TryNextSaveListAction<IDType>
  | TryNextSaveListHandlerAction<DataType, IDType>
  | FailedSaveAction<IDType>
  | FailedSaveListAction<DataType>
  | FailedDeleteAction<IDType>
  | FailedDeleteListAction<IDType>
  | RetrySaveAction<IDType>
  | RetrySaveListAction<DataType>
  | RetryDeleteAction<IDType>
  | RetryDeleteListAction<IDType>
  | ResetStateAction<IDType>
  | ResetListStateAction<IDType>
  | MaintainStateAction;

export function isCrudInitEmptyAction(action: Action): action is InitEmptyCrudStateAction {
  return action.type.includes(CrudActions.INIT_EMPTY);
}

export function isCrudGetAction<IDType>(action: Action): action is GetAction<IDType> {
  return action.type.includes(CrudActions.GET);
}

export function isCrudListAction(action: Action): action is ListAction {
  return action.type.includes(CrudActions.LIST);
}

export function isCrudCreateAction<DataType, IDType>(action: Action): action is CreateAction<DataType, IDType> {
  return action.type.includes(CrudActions.CREATE);
}

export function isCrudUpdateAction<DataType, IDType>(action: Action): action is UpdateAction<DataType, IDType> {
  return action.type.includes(CrudActions.UPDATE);
}

export function isCrudUpdateListAction<DataType>(action: Action): action is UpdateListAction<DataType> {
  return action.type.includes(CrudActions.UPDATE_LIST);
}

export function isCrudSaveListHandlerAction<DataType, IDType>(action: Action): action is SaveListHandlerAction<DataType, IDType> {
  return action.type.includes(CrudActions.SAVE_LIST_HANDLER);
}

export function isCrudDeleteAction<DataType, IDType>(action: Action): action is DeleteAction<DataType, IDType> {
  return action.type.includes(CrudActions.DELETE);
}

export function isCrudDeleteListAction<DataType>(action: Action): action is DeleteListAction<DataType> {
  return action.type.includes(CrudActions.DELETE_LIST);
}

export function isCrudDeleteListHandlerAction<IDType>(action: Action): action is DeleteListHandlerAction<IDType> {
  return action.type.includes(CrudActions.DELETE_LIST_HANDLER);
}

export function isCrudSaveFinishedAction<DataType, IDType>(action: Action): action is SaveFinishedAction<DataType, IDType> {
  return action.type.includes(CrudActions.SAVE_FINISHED);
}

export function isCrudSaveListFinishedAction<DataType, IDType>(action: Action): action is SaveListFinishedAction<DataType, IDType> {
  return action.type.includes(CrudActions.SAVE_LIST_FINISHED);
}

export function isCrudDeleteFinishedAction<DataType, IDType>(action: Action): action is DeleteFinishedAction<DataType, IDType> {
  return action.type.includes(CrudActions.DELETE_FINISHED);
}

export function isCrudDeleteListFinishedAction<IDType>(action: Action): action is DeleteListFinishedAction<IDType> {
  return action.type.includes(CrudActions.DELETE_LIST_FINISHED);
}

export function isCrudSetAction<DataType, IDType>(action: Action): action is SetAction<DataType, IDType> {
  return action.type.includes(CrudActions.SET);
}

export function isCrudTryNextSaveAction<IDType>(action: Action): action is TryNextSaveAction<IDType> {
  return action.type.includes(CrudActions.TRY_NEXT_SAVE);
}

export function isCrudTryNextSaveListAction<IDType>(action: Action): action is TryNextSaveListAction<IDType> {
  return action.type.includes(CrudActions.TRY_NEXT_SAVE_LIST);
}

export function isCrudTryNextSaveListHandlerAction<DataType, IDType>(
  action: Action
): action is TryNextSaveListHandlerAction<DataType, IDType> {
  return action.type.includes(CrudActions.TRY_NEXT_SAVE_LIST_HANDLER);
}

export function isCrudFailedSaveAction<IDType>(action: Action): action is FailedSaveAction<IDType> {
  return action.type.includes(CrudActions.FAILED_SAVE);
}

export function isCrudFailedSaveListAction<DataType>(action: Action): action is FailedSaveListAction<DataType> {
  return action.type.includes(CrudActions.FAILED_SAVE_LIST);
}

export function isCrudRetrySaveAction<IDType>(action: Action): action is RetrySaveAction<IDType> {
  return action.type.includes(CrudActions.RETRY_SAVE);
}

export function isCrudRetrySaveListAction<DataType>(action: Action): action is RetrySaveListAction<DataType> {
  return action.type.includes(CrudActions.RETRY_SAVE_LIST);
}

export function isCrudResetStateAction<IDType>(action: Action): action is ResetStateAction<IDType> {
  return action.type.includes(CrudActions.RESET_STATE);
}

export function isCrudResetListStateAction<IDType>(action: Action): action is ResetListStateAction<IDType> {
  return action.type.includes(CrudActions.RESET_LIST_STATE);
}

export function isCrudFailedDeleteAction<IDType>(action: Action): action is FailedDeleteAction<IDType> {
  return action.type.includes(CrudActions.FAILED_DELETE);
}

export function isCrudFailedDeleteListAction<DataType>(action: Action): action is FailedDeleteListAction<DataType> {
  return action.type.includes(CrudActions.FAILED_DELETE_LIST);
}

export function isCrudRetryDeleteAction<IDType>(action: Action): action is RetryDeleteAction<IDType> {
  return action.type.includes(CrudActions.RETRY_DELETE);
}

export function isCrudRetryDeleteListAction<IDType>(action: Action): action is RetryDeleteListAction<IDType> {
  return action.type.includes(CrudActions.RETRY_DELETE_LIST);
}
