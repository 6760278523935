<div class="dialog-container">
    <h2 class="title">Update End Date</h2>
    <p class="descriptive-text">This will configure a user to be automatically disabled at a specific point in time.</p>
    
    <div class="form-container">
      <form
        [formGroup]="userEndDateFormGroup"
        spellcheck="false"
        autocomplete="off"
      >
        <div>
          <p class="end-date-field">User End Time</p>
          <mat-form-field>
            <input
            matInput
            [min]="minDate"
            [owlDateTime]="dt2"
            [owlDateTimeTrigger]="dt2"
            placeholder="Choose end time"
            formControlName="dtTo"
            [owlDateTimeFilter]="getEndDateMinSetter(userEndDateFormGroup)"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            />
            <owl-date-time #dt2></owl-date-time>
            <mat-error>Enter valid date and time</mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>
  
    <div *ngIf="!!errorMessage" class="error-container">
      <h4>Warning: Failed to save</h4>
      <p>{{ errorMessage }}</p>
    </div>
    
    <div mat-dialog-actions align="end">
      <button mat-raised-button (click)="onCancelClick()">CANCEL</button>
      <button mat-raised-button color="primary" [disabled]="saveDisabled" (click)="onSaveClick()">SAVE</button>
    </div>
  </div>