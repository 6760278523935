import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Issuer } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadIssuers = createAction(
  '[Issuer/API] Load Issuers',
  props<{ objs: Issuer[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state.
 */
export const addIssuer = createAction('[Issuer/API] Add Issuer', props<{ obj: Issuer; org_id: string; blankSlate: boolean | undefined }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertIssuer = createAction('[Issuer/API] Upsert Issuer', props<{ obj: Issuer; refreshData: boolean }>());

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addIssuers = createAction('[Issuer/API] Add Issuers', props<{ objs: Issuer[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 * Not currently implemented since we only update a single issuer.
 */
export const upsertIssuers = createAction('[Issuer/API] Upsert Issuers', props<{ objs: Issuer[]; refreshData: boolean }>());

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateIssuer = createAction('[Issuer/API] Update Issuer', props<{ obj: Update<Issuer> }>());

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we only update a single issuer.
 */
export const updateIssuers = createAction('[Issuer/API] Update Issuers', props<{ objs: Update<Issuer>[] }>());

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteIssuer = createAction('[Issuer/API] Delete Issuer', props<{ id: string; obj: Issuer; refreshData?: boolean }>());

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteIssuers = createAction('[Issuer/API] Delete Issuers', props<{ ids: string[]; refreshData?: boolean }>());

/**
 * Will delete all data from the ngrx entity state
 */
export const clearIssuers = createAction('[Issuer/API] Clear Issuers');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initIssuer = createAction('[Issuer/API] Init Issuer', props<{ force: boolean; blankSlate: boolean }>());

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getIssuer = createAction('[Issuer/API] Get Issuer', props<{ org_id: string; blankSlate: boolean }>());

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingIssuer = createAction(
  '[Issuer/API] Saving Issuer',
  props<{ obj: Issuer; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingIssuers = createAction(
  '[Issuer/API] Saving Issuers',
  props<{ objs: Array<Issuer>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingIssuer = createAction(
  '[Issuer/API] Deleting Issuer',
  props<{ obj: Issuer; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of  data objects for the relevant data type
 */
export const deletingIssuers = createAction(
  '[Issuer/API] Deleting Issuers',
  props<{ objs: Array<Issuer>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshIssuer = createAction('[Issuer/API] Refresh Issuer');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainIssuer = createAction('[Issuer/API] Maintain Issuer');

/**
 * Will begin initializing the state data for the relevant data type and continue polling at set intervals
 */
export const initIssuerPolling = createAction('[Issuer/API] Init Issuer Polling', props<{ force: boolean; blankSlate: boolean }>());

/**
 * Will stop polling for the state data.
 */
export const stopIssuerPolling = createAction('[Issuer/API] Stop Issuer Polling');
