import { createEffect, ofType, Actions } from '@ngrx/effects';
import {
  ActionUserLoggedIn,
  UserActionTypes,
  ActionOrganisationSelected,
  ActionUserInit,
  ActionUserLoggedOut,
  ActionUserLogoutComplete,
  ActionUserLogout,
  ActionUserChosenOrgChanged,
  ActionUserSyncLogin,
  ActionUserNotLoggedIn,
  ActionUserOrgSwitched,
  ActionUserSetCurrentOrg,
  ActionUserRefreshMemberOrgs,
  ActionUserMemberOrgsUpdated,
  ActionUserRefreshPermissions,
  ActionUserCannotSeeCurrentOrg,
  ActionUserRefreshOrgDependentData,
  ActionUserUpdateAdminPortalMetadata,
  ActionUserUpdateGettingStartedData,
  ActionUserFailedGettingStartedDataUpdate,
  ActionUserMaintainState,
  ActionUserHidePaymentReminder,
} from './user.actions';
import { Injectable } from '@angular/core';
import { map, concatMap, withLatestFrom, mergeMap, filter, catchError } from 'rxjs/operators';
import { from, of, EMPTY } from 'rxjs';
import { AuthService } from '../services/auth-service.service';
import {
  OrganisationsService,
  User,
  UsersService,
  ListAllUserOrgsRequestParams,
  Organisation,
  ListAllUserRolesRequestParams,
  UserMetadata,
} from '@agilicus/angular';
import { AppState, NotificationService } from '@app/core';
import { Store } from '@ngrx/store';
import {
  selectUserSelector,
  selectUser,
  selectApiOrgId,
  selectAdminPortalUserMetadata,
  selectCurrentOrg,
  selectBaseOrgId,
  selectGettingStartedData,
} from './user.selectors';
import { UserState } from './user.models';
import { HttpErrorResponse } from '@angular/common/http';
import { selectCanReadOrgs } from './permissions/orgs.selectors';
import { OrgQualifiedPermission } from './permissions/permissions.selectors';
import { createUserMetadata, getUserMetadata, updateUserMetadata } from './user.utils';
import {
  getAdminPortalAppId,
  getAdminPortalDataFromUserMetadata,
  getNewUserAdminPortalUserMetadata,
} from './preferences/user-preference-utils';
import { cloneDeep } from 'lodash';
import { GettingStartedData } from './preferences/getting-started-data';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private orgsService: OrganisationsService,
    private users: UsersService,
    private store: Store<AppState>,
    private usersService: UsersService,
    private notificationService: NotificationService
  ) {}

  public init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.INIT),
      mergeMap((action: ActionUserInit) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectUserSelector)));
      }),
      concatMap(([_, userState]: [ActionUserInit, UserState]) => {
        if (!userState.desired_org_set) {
          return EMPTY;
        }

        return of(new ActionUserSyncLogin(userState.desired_org));
      })
    )
  );

  public syncLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.SYNC_LOGIN),
      concatMap((action: ActionUserSyncLogin) => {
        return this.authService
          .auth()
          .user$()
          .pipe(withLatestFrom(of(action)));
      }),
      concatMap(([user, action]: [User, ActionUserSyncLogin]) => {
        if (!user) {
          return of(new ActionUserNotLoggedIn());
        }
        // If the action has no org id, then none was requested. Default to the user's one.
        const org_id = action.org_id ? action.org_id : user.org_id;
        return of(new ActionUserLoggedIn(user, org_id));
      })
    )
  );

  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LOGGED_IN),
      map((action: ActionUserLoggedIn) => {
        // In case we've logged in to a different org than the default,
        // switch to it.
        return new ActionUserOrgSwitched(action.orgId);
      })
    )
  );

  public getAdminPortalUserMetaDataOnLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LOGGED_IN),
      concatMap((action: ActionUserLoggedIn) => {
        return getUserMetadata(this.usersService, action.user.id, action.user.org_id, getAdminPortalAppId()).pipe(
          concatMap((getUserMetadataResp: Array<UserMetadata>) => {
            const adminPortalUserMetadata = !!getUserMetadataResp && getUserMetadataResp.length !== 0 ? getUserMetadataResp[0] : undefined;
            if (!!adminPortalUserMetadata) {
              return of(new ActionUserUpdateAdminPortalMetadata(adminPortalUserMetadata));
            }
            // Need to create the admin portal user metadata
            const newUserAdminPortalUserMetadata = getNewUserAdminPortalUserMetadata(action.user);
            return createUserMetadata(this.usersService, newUserAdminPortalUserMetadata).pipe(
              concatMap((createUserMetadataResp: UserMetadata) => {
                return of(new ActionUserUpdateAdminPortalMetadata(createUserMetadataResp));
              })
            );
          })
        );
      })
    )
  );

  public onGettingStartedDataUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.UPDATE_GETTING_STARTED_DATA),
      mergeMap((action: ActionUserUpdateGettingStartedData) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectAdminPortalUserMetadata)));
      }),
      concatMap(([action, adminPortalUserMetadataState]: [ActionUserUpdateGettingStartedData, UserMetadata]) => {
        const adminPortalUserMetadataStateCopy = cloneDeep(adminPortalUserMetadataState);
        const adminPortalData = getAdminPortalDataFromUserMetadata(adminPortalUserMetadataStateCopy);
        adminPortalData.gettingStarted = action.updatedGettingStartedData;
        adminPortalUserMetadataStateCopy.spec.data = JSON.stringify(adminPortalData);
        return updateUserMetadata(this.usersService, adminPortalUserMetadataStateCopy).pipe(
          concatMap((updateUserMetadataResp: UserMetadata) => {
            return of(new ActionUserUpdateAdminPortalMetadata(updateUserMetadataResp));
          }),
          catchError((_) => {
            this.notificationService.error('Failed to update the user metadata. Please try again.');
            return of(new ActionUserFailedGettingStartedDataUpdate());
          })
        );
      })
    )
  );

  public onHidePaymentReminder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.HIDE_PAYMENT_REMINDER),
      mergeMap((action: ActionUserHidePaymentReminder) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectGettingStartedData)));
      }),
      map(([action, gettingStartedData]: [ActionUserHidePaymentReminder, GettingStartedData]) => {
        const gettingStartedDataCopy = cloneDeep(gettingStartedData);
        gettingStartedDataCopy.hide_payment_reminder = true;
        return new ActionUserUpdateGettingStartedData(gettingStartedDataCopy);
      })
    )
  );

  public logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LOG_OUT),
      map((action: ActionUserLogout) => {
        return new ActionUserLoggedOut(action.redirectURI, action.localOnly);
      })
    )
  );

  public loggedOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LOGGED_OUT),
      concatMap((action: ActionUserLoggedOut) => {
        // Note: we need to trigger the logout from within an effect to ensure that
        // we have time to reset the state via `ActionUserLogout`
        const logoutPromise = this.authService
          .auth()
          .logout(action.redirectURI, action.localOnly)
          .then(() => {
            return new ActionUserLogoutComplete(action.localOnly);
          });
        return from(logoutPromise);
      })
    )
  );

  public reloadIfNeededWhenLogoutComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LOG_OUT_COMPLETE),
      map((action: ActionUserLogoutComplete) => {
        // Clear the local browser state when logged out to ensure no stale state is present.
        window.localStorage.clear();
        if (action.reload) {
          window.location.reload();
        }
        return new ActionUserMaintainState();
      })
    )
  );

  public chooseOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.CHOSEN_ORG_CHANGED),
      mergeMap((action: ActionUserChosenOrgChanged) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectUserSelector)));
      }),
      concatMap(([action, userState]: [ActionUserChosenOrgChanged, UserState]) => {
        if (userState.logging_in) {
          // If we're logging in, then we paused the login process to wait for the org.
          // Kick off the login again
          return of(new ActionUserSyncLogin(action.org_id));
        }

        if (action.org_id && action.org_id !== userState.desired_org) {
          return of(new ActionUserOrgSwitched(action.org_id));
        }

        // we're neither logging in nor have a desired, valid org id. Do nothing.
        return EMPTY;
      })
    )
  );

  public refreshMemberOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.REFRESH_MEMBER_ORGS),
      concatMap((action: ActionUserRefreshMemberOrgs) => {
        return of(action).pipe(
          withLatestFrom(this.store.select(selectUser), this.store.select(selectCurrentOrg), this.store.select(selectBaseOrgId))
        );
      }),
      filter(
        // Unfortunately this can happen on logout. Prevent it.
        ([action, user, org, baseOrgId]) => !!user
      ),
      concatMap(([action, user, currentOrg, baseOrgId]: [ActionUserRefreshMemberOrgs, User, Organisation, string | undefined]) => {
        // Using the issuer and the org id, find all orgs the user
        // is a member of in this issuer hierarchy. If the user does not have permission to do this,
        // we may not have an org. Handle that case by just assuming they have no permissions, rather
        // than failing entirely.
        const params: ListAllUserOrgsRequestParams = {
          user_id: user.id,
          enabled: true,
        };

        if (baseOrgId) {
          params.org_id = baseOrgId;
        } else {
          params.issuer = !!action.organisation ? action.organisation.issuer : undefined;
          if (!params.issuer && !!currentOrg) {
            params.issuer = currentOrg.issuer;
          }
        }

        if (!params.issuer && !params.org_id) {
          return of([]);
        }
        const userOrgs$ = this.users.listAllUserOrgs(params).pipe(
          map((userOrgsResp) => {
            return userOrgsResp.orgs;
          })
        );
        return userOrgs$;
      }),
      map((orgs: Organisation[]) => {
        return new ActionUserMemberOrgsUpdated(orgs);
      })
    )
  );

  // This watches for changes to the member orgs. If the org we're currently on isn't
  // a member org, it means that the login defaulted to an org we aren't part of. Switch
  // to one we are so the UI doesn't mess up.
  public memberOrgsUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.MEMBER_ORGS_UPDATED),
      mergeMap((action: ActionUserMemberOrgsUpdated) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectApiOrgId)));
      }),
      concatMap(([action, orgId]: [ActionUserMemberOrgsUpdated, string]) => {
        const matchingOrg = action.orgs.find((org) => org.id === orgId);
        if (!matchingOrg && action.orgs.length > 0) {
          return of(new ActionUserOrgSwitched(action.orgs[0].id));
        }

        return EMPTY;
      })
    )
  );

  public switchOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.ORG_SWITCHED),
      concatMap((action: ActionUserOrgSwitched) => {
        const switcher = this.authService
          .auth()
          .switch_org(action.org_id)
          .then(() => {
            return new ActionUserRefreshPermissions(action.org_id);
          });
        return from(switcher);
      })
    )
  );

  public setCurrentOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.SET_CURRENT_ORG),
      mergeMap((action: ActionUserSetCurrentOrg) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectCanReadOrgs)));
      }),
      concatMap(([action, permissions]: [ActionUserSetCurrentOrg, OrgQualifiedPermission]) => {
        if (permissions.hasPermission) {
          return this.orgsService.getOrg({ org_id: action.orgId }).pipe(withLatestFrom(of(action.orgId)));
        }
        return of([undefined, action.orgId]);
      }),
      concatMap(([currentOrg, orgId]: [Organisation | undefined, string]) => {
        if (currentOrg) {
          return of(
            new ActionOrganisationSelected(currentOrg),
            new ActionUserRefreshMemberOrgs(currentOrg),
            new ActionUserRefreshOrgDependentData(orgId)
          );
        }
        return of(new ActionUserCannotSeeCurrentOrg(), new ActionUserRefreshMemberOrgs(), new ActionUserRefreshOrgDependentData(orgId));
      })
    )
  );

  public refreshPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.REFRESH_PERMISSIONS),
      mergeMap((action: ActionUserRefreshPermissions) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectUser)));
      }),
      concatMap(([action, user]: [ActionUserRefreshPermissions, User]) => {
        const reqPararms: ListAllUserRolesRequestParams = {
          user_id: user.id,
          org_id: action.org_id,
        };
        return this.users.listAllUserRoles(reqPararms).pipe(
          concatMap((permissions) => {
            const permMap: Map<string, string[]> = new Map();
            for (const [endpoint, roles] of Object.entries(permissions)) {
              permMap.set(endpoint, roles);
            }

            // update the permission map, then update the org since we now know if we can
            return of(new ActionUserSetCurrentOrg(permMap, action.org_id));
          }),
          catchError((error: HttpErrorResponse) => {
            // This will happen if the user has no permissions at all.
            if (error.status === 403) {
              return of(new ActionUserSetCurrentOrg(new Map(), undefined));
            }
            // otherwise rethrow
            throw error;
          })
        );
      })
    )
  );
}
