import {
  OIDCProxyHeaderMapping,
  OIDCProxyHeaderMatch,
  OIDCProxyHeaderName,
  OIDCProxyHeaderRewriteFilter,
  OIDCProxyHeaderUserConfig,
} from '@agilicus/angular';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { FilterManager } from '../filter/filter-manager';
import { OptionalOIDCProxyHeaderRewriteFilterElement } from '../optional-types';
import { buildTableData, getDefaultNewRowProperties, updateGenericTable } from '../table-layout-utils';
import {
  CheckBoxColumn,
  Column,
  createCheckBoxColumn,
  createInputColumn,
  createSelectRowColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { Observable } from 'rxjs';

export interface HeaderOverrideElement extends TableElement {
  /**
   * The name of the header that will be set.
   */
  name?: string;
  /**
   * The value of the header that will be set.
   */
  value?: string;
}

export interface OIDCProxyHeaderMatchElement extends OIDCProxyHeaderMatch, TableElement {}

export interface OIDCProxyHeaderRewriteFilterElement extends OIDCProxyHeaderRewriteFilter, TableElement {}

export interface TableConstructionData<T> {
  data: Array<T>;
  tableData: Array<T & TableElement>;
  replaceFunction: () => void;
}

@Component({
  selector: 'portal-header-overrides',
  templateUrl: './header-overrides.component.html',
  styleUrls: ['./header-overrides.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderOverridesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public curentOIDCProxyHeaderUserConfig: OIDCProxyHeaderUserConfig;
  @Input() public pageDescriptiveText: ``;
  @Input() public productGuideLink: `https://www.agilicus.com/anyx-guide/proxy/#h-http-response-header-overrides`;
  @Output() public updateHeaderOverrides = new EventEmitter<any>();
  public curentOIDCProxyHeaderUserConfigCopy: OIDCProxyHeaderUserConfig;
  public setHeaderTableData: Array<HeaderOverrideElement> = [];
  public setHeaderColumnDefs: Map<string, Column<HeaderOverrideElement>> = new Map();
  public addHeaderTableData: Array<HeaderOverrideElement> = [];
  public addHeaderColumnDefs: Map<string, Column<HeaderOverrideElement>> = new Map();
  public removeHeaderTableData: Array<HeaderOverrideElement> = [];
  public removeHeaderColumnDefs: Map<string, Column<HeaderOverrideElement>> = new Map();
  public removeMatchTableData: Array<OIDCProxyHeaderMatchElement> = [];
  public removeMatchColumnDefs: Map<string, Column<OIDCProxyHeaderMatchElement>> = new Map();
  public rewriteFilterTableData: Array<OIDCProxyHeaderRewriteFilterElement> = [];
  public rewriteFilterColumnDefs: Map<string, Column<OIDCProxyHeaderRewriteFilterElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public makeEmptyRemoveMatchTableElementFunc = this.makeEmptyRemoveMatchTableElement.bind(this);
  public makeEmptyRewriteFilterTableElementFunc = this.makeEmptyRewriteFilterTableElement.bind(this);
  private tableConstructionData: Array<
    TableConstructionData<OIDCProxyHeaderMapping | OIDCProxyHeaderName | OIDCProxyHeaderMatch | OIDCProxyHeaderRewriteFilter>
  >;
  public rewriteFilterTableDescriptiveText = `Some applications store internal-references inside of query string parameters embedded in headers.
This section allows you to specify the headers that have that property, as well as how they are encoded.
For example, your application may return a redirect that base64-encodes the domain name of the application in a query parameter of the location header.`;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeAllColumnDefs();
  }

  public ngOnChanges(): void {
    if (!this.curentOIDCProxyHeaderUserConfig) {
      this.curentOIDCProxyHeaderUserConfig = {};
      this.curentOIDCProxyHeaderUserConfig.set = [];
      this.curentOIDCProxyHeaderUserConfig.add = [];
      this.curentOIDCProxyHeaderUserConfig.remove = [];
      this.curentOIDCProxyHeaderUserConfig.remove_match = [];
      this.curentOIDCProxyHeaderUserConfig.filters = [];
      this.resetEmptyTables();
      //      return;
    }
    this.curentOIDCProxyHeaderUserConfigCopy = cloneDeep(this.curentOIDCProxyHeaderUserConfig);
    this.setTableConstructionData();
    this.updateTables();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private getDefaultInputColumn(
    name: string
  ): Column<HeaderOverrideElement | OIDCProxyHeaderMatchElement | OIDCProxyHeaderRewriteFilterElement> {
    const column = createInputColumn(name);
    column.isEditable = true;
    column.requiredField = () => true;
    return column;
  }

  private getNameColumn(): Column<HeaderOverrideElement> {
    const nameColumn = this.getDefaultInputColumn('name');
    return nameColumn;
  }

  private getValueColumn(): Column<HeaderOverrideElement> {
    const valueColumn = this.getDefaultInputColumn('value');
    return valueColumn;
  }

  private getNameExactColumn(): Column<OIDCProxyHeaderMatchElement> {
    const nameExactColumn = this.getDefaultInputColumn('name_exact');
    nameExactColumn.getHeaderTooltip = () => {
      return 'The header name that will be removed. The match is case insensitive.';
    };
    return nameExactColumn;
  }

  private getValueRegexColumn(): Column<OIDCProxyHeaderMatchElement> {
    const valueRegexColumn = this.getDefaultInputColumn('value_regex');
    valueRegexColumn.getHeaderTooltip = () => {
      return 'A regex describing the value to match';
    };
    return valueRegexColumn;
  }

  private getHeaderColumn(): Column<OIDCProxyHeaderRewriteFilterElement> {
    const headerColumn = this.getDefaultInputColumn('header');
    headerColumn.getHeaderTooltip = () => {
      return 'The header to rewrite. This is case insensitive.';
    };
    return headerColumn;
  }

  private getParameterColumn(): Column<OIDCProxyHeaderRewriteFilterElement> {
    const parameterColumn = this.getDefaultInputColumn('parameter');
    parameterColumn.getHeaderTooltip = () => {
      return 'The uri parameter to rewrite';
    };
    return parameterColumn;
  }

  private getExactValueColumn(): Column<OIDCProxyHeaderRewriteFilterElement> {
    const exactValueColumn = this.getDefaultInputColumn('exact_value');
    exactValueColumn.getHeaderTooltip = () => {
      return 'The value to find in plaintext';
    };
    return exactValueColumn;
  }

  private getExactRewriteValueColumn(): Column<OIDCProxyHeaderRewriteFilterElement> {
    const exactRewriteValueColumn = this.getDefaultInputColumn('exact_rewrite_value');
    exactRewriteValueColumn.getHeaderTooltip = () => {
      return 'The value to replace in plaintext';
    };
    return exactRewriteValueColumn;
  }

  private getBase64Column(): CheckBoxColumn<OptionalOIDCProxyHeaderRewriteFilterElement> {
    const base64Column = createCheckBoxColumn('base64');
    base64Column.displayName = 'Base64 encoded';
    base64Column.isEditable = true;
    base64Column.getHeaderTooltip = () => {
      return 'This setting will cause the filter to treat the parameter as base64 encoded and will cause the filter to decode before making the substitution and re-encode after the substitution';
    };
    return base64Column;
  }

  private getDeflateColumn(): CheckBoxColumn<OptionalOIDCProxyHeaderRewriteFilterElement> {
    const deflateColumn = createCheckBoxColumn('deflate');
    deflateColumn.isEditable = true;
    deflateColumn.getHeaderTooltip = () => {
      return 'This setting will cause the filter to treat the parameter as deflated. This will cause the filter to inflate the parameter before the substitution is made and deflate after the substitution.';
    };
    return deflateColumn;
  }

  private initializeAllColumnDefs(): void {
    this.initializeSetHeaderColumnDefs();
    this.initializeAddHeaderColumnDefs();
    this.initializeRemoveHeaderColumnDefs();
    this.initializeRemoveMatchColumnDefs();
    this.initializeRewriteFilterColumnDefs();
  }

  private initializeSetHeaderColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getNameColumn(), this.getValueColumn()], this.setHeaderColumnDefs);
  }

  private initializeAddHeaderColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getNameColumn(), this.getValueColumn()], this.addHeaderColumnDefs);
  }

  private initializeRemoveHeaderColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getNameColumn()], this.removeHeaderColumnDefs);
  }

  private initializeRemoveMatchColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getNameExactColumn(), this.getValueRegexColumn()], this.removeMatchColumnDefs);
  }

  private initializeRewriteFilterColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getHeaderColumn(),
        this.getParameterColumn(),
        this.getExactValueColumn(),
        this.getExactRewriteValueColumn(),
        this.getBase64Column(),
        this.getDeflateColumn(),
      ],
      this.rewriteFilterColumnDefs
    );
  }

  private setTableConstructionData(): void {
    const setHeaderData: TableConstructionData<OIDCProxyHeaderMapping> = {
      data: this.curentOIDCProxyHeaderUserConfigCopy.set,
      tableData: this.setHeaderTableData,
      replaceFunction: this.replaceSetHeaderTableWithCopy.bind(this),
    };
    const addHeaderData: TableConstructionData<OIDCProxyHeaderMapping> = {
      data: this.curentOIDCProxyHeaderUserConfigCopy.add,
      tableData: this.addHeaderTableData,
      replaceFunction: this.replaceAddHeaderTableWithCopy.bind(this),
    };
    const removeHeaderData: TableConstructionData<OIDCProxyHeaderName> = {
      data: this.curentOIDCProxyHeaderUserConfigCopy.remove,
      tableData: this.removeHeaderTableData,
      replaceFunction: this.replaceRemoveHeaderTableWithCopy.bind(this),
    };
    const removeMatchData: TableConstructionData<OIDCProxyHeaderMatch> = {
      data: this.curentOIDCProxyHeaderUserConfigCopy.remove_match,
      tableData: this.removeMatchTableData,
      replaceFunction: this.replaceRemoveMatchTableWithCopy.bind(this),
    };
    const rewriteFilterData: TableConstructionData<OIDCProxyHeaderRewriteFilter> = {
      data: this.curentOIDCProxyHeaderUserConfigCopy.filters,
      tableData: this.rewriteFilterTableData,
      replaceFunction: this.replaceRewriteFilterTableWithCopy.bind(this),
    };
    this.tableConstructionData = [setHeaderData, addHeaderData, removeHeaderData, removeMatchData, rewriteFilterData];
  }

  private updateTables(): void {
    for (const table of this.tableConstructionData) {
      updateGenericTable(table.data, table.tableData, buildTableData.bind(this), table.replaceFunction.bind(this));
    }
  }

  private resetEmptyTables(): void {
    this.setHeaderTableData = [];
    this.addHeaderTableData = [];
    this.removeHeaderTableData = [];
    this.removeMatchTableData = [];
    this.rewriteFilterTableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceSetHeaderTableWithCopy(): void {
    const tableDataCopy = [...this.setHeaderTableData];
    this.setHeaderTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private replaceAddHeaderTableWithCopy(): void {
    const tableDataCopy = [...this.addHeaderTableData];
    this.addHeaderTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private replaceRemoveHeaderTableWithCopy(): void {
    const tableDataCopy = [...this.removeHeaderTableData];
    this.removeHeaderTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private replaceRemoveMatchTableWithCopy(): void {
    const tableDataCopy = [...this.removeMatchTableData];
    this.removeMatchTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private replaceRewriteFilterTableWithCopy(): void {
    const tableDataCopy = [...this.rewriteFilterTableData];
    this.rewriteFilterTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public makeEmptyTableElement(): HeaderOverrideElement {
    return {
      name: '',
      value: '',
      ...getDefaultNewRowProperties(),
    };
  }

  public makeEmptyRemoveMatchTableElement(): OIDCProxyHeaderMatchElement {
    return {
      name_exact: '',
      value_regex: '',
      ...getDefaultNewRowProperties(),
    };
  }

  public makeEmptyRewriteFilterTableElement(): OIDCProxyHeaderRewriteFilterElement {
    return {
      rewrite_type: 'uriParameterRewrite',
      header: '',
      parameter: '',
      exact_value: '',
      exact_rewrite_value: '',
      base64: false,
      deflate: false,
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Updates the config when a change is made in the table.
   */
  public updateEvent(): void {
    this.updateHeaderOverridesFromTable();
  }

  private updateHeaderOverridesFromTable(): void {
    this.curentOIDCProxyHeaderUserConfigCopy.set = this.getHeaderOverridesFromTable(this.setHeaderTableData);
    this.curentOIDCProxyHeaderUserConfigCopy.add = this.getHeaderOverridesFromTable(this.addHeaderTableData);
    this.curentOIDCProxyHeaderUserConfigCopy.remove = this.getHeaderOverridesFromTable(this.removeHeaderTableData);
    this.curentOIDCProxyHeaderUserConfigCopy.remove_match = this.getRemoveMatchesFromTable();
    this.curentOIDCProxyHeaderUserConfigCopy.filters = this.getRewriteFiltersFromTable();
    this.updateHeaderOverrides.emit(this.curentOIDCProxyHeaderUserConfigCopy);
  }

  private getHeaderOverridesFromTable(tableData: Array<HeaderOverrideElement>): Array<OIDCProxyHeaderMapping> {
    const headerOverrides: Array<OIDCProxyHeaderMapping> = [];
    for (const element of tableData) {
      const newHeaderOverride: OIDCProxyHeaderMapping = {
        name: element.name,
      };
      if (!!element.value) {
        newHeaderOverride.value = element.value;
      }
      headerOverrides.push(newHeaderOverride);
    }
    return headerOverrides;
  }

  private getRemoveMatchesFromTable(): Array<OIDCProxyHeaderMatch> {
    const removeMatches: Array<OIDCProxyHeaderMatch> = [];
    for (const element of this.removeMatchTableData) {
      const newRemoveMatch: OIDCProxyHeaderMatch = {
        name_exact: element.name_exact,
        value_regex: element.value_regex,
      };
      removeMatches.push(newRemoveMatch);
    }
    return removeMatches;
  }

  private getRewriteFiltersFromTable(): Array<OIDCProxyHeaderRewriteFilter> {
    const rewriteFilters: Array<OIDCProxyHeaderRewriteFilter> = [];
    for (const element of this.rewriteFilterTableData) {
      const newRewriteFilter: OIDCProxyHeaderRewriteFilter = {
        rewrite_type: element.rewrite_type,
        header: element.header,
        parameter: element.parameter,
        exact_value: element.exact_value,
        exact_rewrite_value: element.exact_rewrite_value,
        base64: element.base64,
        deflate: element.deflate,
      };
      rewriteFilters.push(newRewriteFilter);
    }
    return rewriteFilters;
  }

  public deleteSelected(): void {
    this.removeElements();
    this.updateHeaderOverridesFromTable();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(
      this.setHeaderTableData
        .concat(this.addHeaderTableData)
        .concat(this.removeHeaderTableData)
        .concat(this.removeMatchTableData)
        .concat(this.rewriteFilterTableData),
      new Map([
        ...this.setHeaderColumnDefs,
        ...this.addHeaderColumnDefs,
        ...this.removeHeaderColumnDefs,
        ...this.removeMatchColumnDefs,
        ...this.rewriteFilterColumnDefs,
      ]),
      this.updateEvent.bind(this)
    );
  }

  private removeElements(): void {
    this.setHeaderTableData = this.setHeaderTableData.filter((element) => !element.isChecked);
    this.addHeaderTableData = this.addHeaderTableData.filter((element) => !element.isChecked);
    this.removeHeaderTableData = this.removeHeaderTableData.filter((element) => !element.isChecked);
    this.removeMatchTableData = this.removeMatchTableData.filter((element) => !element.isChecked);
    this.rewriteFilterTableData = this.rewriteFilterTableData.filter((element) => !element.isChecked);
  }
}
