import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input, OnChanges } from '@angular/core';

import { Application, IncludedRole, RoleV2 } from '@agilicus/angular';
import { FilterManager } from '../filter/filter-manager';
import { TableElement } from '../table-layout/table-element';
import {
  Column,
  createInputColumn,
  createChipListColumn,
  createSelectRowColumn,
  ChiplistColumn,
  InputColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, combineLatest } from 'rxjs';
import { AppState, NotificationService } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { updateTableElements, useValueIfNotInMap, getDefaultRoleNameFromApp } from '../utils';
import {
  ActionApiApplicationsInitApplications,
  ActionApiApplicationsSavingRole,
  ActionApiApplicationsDeletingRole,
  ActionApiApplicationsRefreshRoleState,
  ActionApiApplicationsModifyCurrentApp,
} from '@app/core/api-applications/api-applications.actions';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { selectApiApplicationsCurrentRolesList, selectApiCurrentApplication } from '@app/core/api-applications/api-applications.selectors';
import { OptionalIncludedRole, OptionalRoleElement } from '../optional-types';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { createDropdownSelector, DropdownSelector } from '../dropdown-selector/dropdown-selector-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { isValidRoleName } from '../validation-utils';
import { UserDefinedRoles } from '@app/core/models/application/application-model';

export interface RoleElement extends TableElement {
  name: string;
  included: Array<IncludedRole>;
  comments: string;
  backingRole: RoleV2;
}

@Component({
  selector: 'portal-application-roles',
  templateUrl: './application-roles.component.html',
  styleUrls: ['./application-roles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationRolesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  @Input() public appModelUserDefinedRoles: UserDefinedRoles | undefined = undefined;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<RoleElement>> = new Map();
  private orgId: string;
  public currentApplicationCopy: Application;
  public tableData: Array<RoleElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'ROLE';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private roleIdToNameMap: Map<string, string> = new Map();
  private roleNameToIdMap: Map<string, string> = new Map();
  private includedRolesList: Array<IncludedRole> = [];
  public removeDefaultRoleFromPublishedAppErrorMessage =
    'Public applications must have a default role. To remove this role, please uncheck the "Publish Application" option in the overview panel above.';
  public defaultRoleDropdownSelector: DropdownSelector;
  public hasBlankOption = true;
  public rolesDescriptiveText = `A role is an intrinsic grouping of security within your application, e.g. Owner, Administrator, Editor, Self, etc.`;
  public rolesProductGuideLink = `https://www.agilicus.com/anyx-guide/authorisation-rules/`;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getDefaultRoleNameFromApp = getDefaultRoleNameFromApp;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.store.dispatch(new ActionApiApplicationsInitApplications());
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const currentAppState$ = this.store.pipe(select(selectApiCurrentApplication));
    const currentRolesListState$ = this.store.pipe(select(selectApiApplicationsCurrentRolesList));
    combineLatest([orgId$, currentAppState$, currentRolesListState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, currentAppStateResp, currentRolesListStateResp]) => {
        this.orgId = orgIdResp;
        if (!currentAppStateResp || !currentRolesListStateResp) {
          this.resetEmptyTable();
          return;
        }
        // Need to make a copy since we cannot modify the readonly data from the store
        this.currentApplicationCopy = cloneDeep(currentAppStateResp);
        this.setRolesMaps(currentRolesListStateResp);
        this.includedRolesList = currentRolesListStateResp.map((role) => {
          return {
            role_id: role.metadata.id,
          };
        });
        if (!this.appModelUserDefinedRoles) {
          this.columnDefs.get('included').allowedValues = this.includedRolesList;
        }
        this.setEditableColumnDefs();
        if (!!this.appModelUserDefinedRoles) {
          this.updateTable(this.appModelUserDefinedRoles.roles);
        } else {
          this.updateTable(cloneDeep(currentRolesListStateResp));
        }
        this.defaultRoleDropdownSelector = this.createDefaultRoleDropdownSelector();
      });
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setRolesMaps(currentRoleList: Array<RoleV2>): void {
    this.roleIdToNameMap.clear();
    this.roleNameToIdMap.clear();
    for (const role of currentRoleList) {
      this.roleIdToNameMap.set(role.metadata.id, role.spec.name);
      this.roleNameToIdMap.set(role.spec.name, role.metadata.id);
    }
  }

  private updateTable(roleList: Array<RoleV2>): void {
    this.buildData(roleList);
    this.replaceTableWithCopy();
  }

  private buildData(roleList: Array<RoleV2>): void {
    const data: Array<RoleElement> = [];
    for (let i = 0; i < roleList.length; i++) {
      data.push(this.createRoleElement(roleList[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createRoleElement(role: RoleV2, index: number): RoleElement {
    const data: RoleElement = {
      name: role.spec.name,
      included: role.spec.included,
      comments: role.spec.comments,
      ...getDefaultTableProperties(index),
      backingRole: role,
    };
    return data;
  }

  private getNameColumn(): InputColumn<RoleElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.requiredField = () => true;
    nameColumn.isUnique = true;
    nameColumn.isValidEntry = (value: string) => {
      return isValidRoleName(value);
    };
    return nameColumn;
  }

  private getIncludedColumn(): ChiplistColumn<RoleElement> {
    const includedColumn = createChipListColumn('included');
    includedColumn.displayName = 'Included Roles';
    includedColumn.getDisplayValue = (includedRole: OptionalIncludedRole) => {
      return useValueIfNotInMap(includedRole.role_id, this.roleIdToNameMap);
    };
    includedColumn.getElementFromValue = (roleName: string): OptionalIncludedRole => {
      return {
        role_id: useValueIfNotInMap(roleName, this.roleNameToIdMap),
      };
    };
    includedColumn.isValidEntry = (entry: IncludedRole, element: OptionalRoleElement) => {
      const existingRoleIdsList = this.includedRolesList.map((role) => role.role_id);
      const isExistingOption = existingRoleIdsList.includes(entry.role_id);
      const isOwnRole = entry.role_id === useValueIfNotInMap(element.name, this.roleNameToIdMap);
      return isExistingOption && !isOwnRole;
    };
    return includedColumn;
  }

  private getCommentsColumn(): InputColumn<RoleElement> {
    return createInputColumn('comments');
  }

  private initializeColumnDefs(): void {
    const columns = [createSelectRowColumn(), this.getNameColumn()];
    if (!this.appModelUserDefinedRoles) {
      columns.push(this.getIncludedColumn());
    }
    columns.push(this.getCommentsColumn());
    setColumnDefs(columns, this.columnDefs);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const nameColumn = this.columnDefs.get('name');
    nameColumn.isEditable = !this.fixedTable;

    if (!this.appModelUserDefinedRoles) {
      const includedColumn: ChiplistColumn<RoleElement> = this.columnDefs.get('included');
      includedColumn.isRemovable = !this.fixedTable;
      includedColumn.getHeaderTooltip = () => {
        return 'Included roles allow you to include a previously defined role. This is useful when one role is a superset of another.';
      };
    }

    const commentsColumn = this.columnDefs.get('comments');
    commentsColumn.isEditable = !this.fixedTable;
  }

  public makeEmptyTableElement(): RoleElement {
    return {
      name: '',
      included: [],
      comments: '',
      ...getDefaultNewRowProperties(),
      backingRole: {
        spec: {
          app_id: !!this.appModelUserDefinedRoles ? '' : this.currentApplicationCopy.id,
          org_id: this.orgId,
          name: '',
          included: [],
          comments: '',
        },
      },
    };
  }

  /**
   * Receives a RoleElement from the table then updates and saves
   * the current roles list.
   */
  public updateEvent(updatedRole: RoleElement): void {
    if (!!this.appModelUserDefinedRoles) {
      const appRoles = [];
      for (const role of this.tableData) {
        appRoles.push(this.getRoleFromRoleElement(role));
      }
      this.appModelUserDefinedRoles.roles = [...appRoles];
      return;
    }
    this.saveRole(updatedRole);
  }

  private getRoleFromRoleElement(roleElement: RoleElement): RoleV2 {
    const result: RoleV2 = roleElement.backingRole
      ? cloneDeep(roleElement.backingRole)
      : {
          spec: {
            name: roleElement.name,
          },
        };
    result.spec.name = roleElement.name;
    result.spec.comments = roleElement.comments;
    result.spec.included = roleElement.included;
    return result;
  }

  private saveRole(updatedRole: RoleElement): void {
    const modifiedRole = this.getRoleFromRoleElement(updatedRole);
    this.store.dispatch(new ActionApiApplicationsSavingRole(modifiedRole));
  }

  public deleteSelected(rolesToDelete: Array<RoleElement>): void {
    if (!!this.appModelUserDefinedRoles) {
      const rolesToKeep = this.tableData.filter((role) => !role.isChecked).map((role) => this.getRoleFromRoleElement(role));
      const roleNamesToKeep = rolesToKeep.map((role) => role.spec.name);
      if (!roleNamesToKeep.includes(this.appModelUserDefinedRoles.default_role.spec.name)) {
        // Remove default role if it is deleted:
        this.appModelUserDefinedRoles.default_role = undefined;
      }
      this.appModelUserDefinedRoles.roles = [...rolesToKeep];
      this.updateTable(this.appModelUserDefinedRoles.roles);
      this.defaultRoleDropdownSelector = this.createDefaultRoleDropdownSelector();
      return;
    }
    this.deleteRoles(rolesToDelete);
  }

  private deleteRoles(rolesToDelete: Array<RoleElement>): void {
    if (rolesToDelete.length === 1 && !rolesToDelete[0].backingRole.metadata) {
      // If only deleting new, unsaved role, refreshing state will remove it.
      this.store.dispatch(new ActionApiApplicationsRefreshRoleState());
      return;
    }
    for (const roleToDelete of rolesToDelete) {
      this.store.dispatch(new ActionApiApplicationsDeletingRole(cloneDeep(roleToDelete.backingRole)));
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private modifyApplication(): void {
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  public updateAppDefaultRole(selectedRoleName: string): void {
    if (this.currentApplicationCopy.published === Application.PublishedEnum.public && selectedRoleName === '') {
      this.notificationService.error(this.removeDefaultRoleFromPublishedAppErrorMessage);
      return;
    }
    if (selectedRoleName === '') {
      this.currentApplicationCopy.default_role_id = '';
    } else {
      const defaultRoleId = this.roleNameToIdMap.get(selectedRoleName);
      this.currentApplicationCopy.default_role_id = defaultRoleId;
    }
    this.modifyApplication();
  }

  public updateAppModelDefaultRole(selectedRoleName: string): void {
    for (const role of this.appModelUserDefinedRoles.roles) {
      if (role.spec.name === selectedRoleName) {
        this.appModelUserDefinedRoles.default_role = role;
      }
    }
  }

  public getRoleNameFromElement(element: RoleElement): string {
    return element?.name;
  }

  public getRoleFromElement(element: RoleElement): RoleV2 {
    return element?.backingRole;
  }

  public getDefaultRoleNameFromAppModelUserDefinedRoles(appModelUserDefinedRoles: UserDefinedRoles): string {
    return appModelUserDefinedRoles?.default_role?.spec?.name;
  }

  public getDefaultRoleNameFromRole(role: RoleV2): string {
    return role?.spec?.name;
  }

  public createDefaultRoleDropdownSelector(): DropdownSelector {
    const dropdownSelector = createDropdownSelector();
    dropdownSelector.selectorLabel = 'Default Role';
    dropdownSelector.selectorTooltipText = `When a user is granted access to this application, this is the role they will inherit by default. You can always change this using the 'Permissions' screen.`;
    dropdownSelector.selectorData = this.tableData;
    dropdownSelector.getSelectorOptionDisplayValue = this.getRoleNameFromElement.bind(this);
    dropdownSelector.getSelectorOptionValue = this.getRoleNameFromElement.bind(this);
    if (!!this.appModelUserDefinedRoles) {
      dropdownSelector.selectorElement = this.appModelUserDefinedRoles;
      dropdownSelector.getSelectorValueFromElement = this.getDefaultRoleNameFromAppModelUserDefinedRoles.bind(this);
      dropdownSelector.onSelectionChange = this.updateAppModelDefaultRole.bind(this);
    } else {
      dropdownSelector.selectorElement = this.currentApplicationCopy;
      dropdownSelector.getSelectorValueFromElement = this.getDefaultRoleNameFromApp.bind(this);
      dropdownSelector.onSelectionChange = this.updateAppDefaultRole.bind(this);
    }
    return dropdownSelector;
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
