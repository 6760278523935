import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import {
  capitalizeFirstLetter,
  getValuesFromInputEventValue,
  createEnumChecker,
  getKeysFromMap,
  getDefaultReportURI,
  getDefaultCspSettings,
} from '../utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, combineLatest } from 'rxjs';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import {
  selectApiApplicationsCurrentApplicationContentSecurityPolicy,
  selectApiCurrentApplication,
} from '@app/core/api-applications/api-applications.selectors';
import { ActionApiApplicationsModifyCurrentApp } from '@app/core/api-applications/api-applications.actions';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { CspDirectiveEnum } from '@app/shared/components/csp-directive.enum';
import { CspSourceEnum } from '@app/shared/components/csp-source.enum';
import { FilterChipOptions } from '../filter-chip-options';
import { isValidCsp } from '../validation-utils';
import { Application, ApplicationConfig, CSPDirective, CSPSettings, Environment } from '@agilicus/angular';
import { cloneDeep } from 'lodash-es';
import { setCspConfigIfUnset } from '../application-configs-utils';

export interface CspDirective {
  name: CspDirectiveEnum;
  sources: Map<CspSourceEnum, boolean>;
  hosts: Array<string>;
  hostsFormControl: UntypedFormControl;
  isEnabled: boolean;
  isOpen: boolean;
  dirty: boolean;
}

export interface CspSource {
  source: CspSourceEnum;
  isChecked: boolean;
}

enum defaultType {
  noCSP,
  laxAngularJS,
  strictAngular,
}

@Component({
  selector: 'portal-application-content-security-policy',
  templateUrl: './application-content-security-policy.component.html',
  styleUrls: ['./application-content-security-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationContentSecurityPolicyComponent implements OnInit, OnDestroy {
  @Input() public fixedData = false;
  @Input() public cspDescriptiveText = `Configure the HTTP Content Security Policy.`;
  public currentApplicationCopy: Application;
  public defaultTypes = defaultType;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public currentContentSecurityPolicyString = '';
  public currentContentSecurityPolicy: CSPSettings;
  public cspDirectives: Array<CspDirective> = [];
  private cspAllDirectivesList: Array<CspDirectiveEnum> = [];
  public cspSourcesList: Array<CspSourceEnum> = [];
  private panelsStateMap: Map<CspDirectiveEnum, boolean> = new Map();
  public watchForPanelUpdates$: Subject<boolean> = new Subject();
  public newChipAdded = false;
  public savingCsp = false;
  private cspDirectiveNameToValuesMap: Map<string, Array<string>> = new Map();
  public cspForm: UntypedFormGroup;
  public cspProductGuideLink = `https://www.agilicus.com/anyx-guide/content-security-policy/`;

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public getKeysFromMap = getKeysFromMap;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.setCspAllDirectivesList();
    this.setCspSourcesList();
    this.setPanelsStateMapToClosed();
  }

  public ngOnInit(): void {
    this.initializeCspFormGroup();
    this.watchForPanelUpdates$
      .pipe(
        debounceTime(2000),
        takeUntil(this.unsubscribe$),
        map((saveToDatabase: boolean) => {
          if (!saveToDatabase) {
            return;
          }
          this.updateApplicationConfigOnChanges();
        })
      )
      .subscribe(() => {});

    const currentAppState$ = this.store.pipe(select(selectApiCurrentApplication));
    const currentCspState$ = this.store.pipe(select(selectApiApplicationsCurrentApplicationContentSecurityPolicy));
    combineLatest([currentAppState$, currentCspState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([currentAppStateResp, currentCspStateResp]) => {
        if (!currentAppStateResp || !currentAppStateResp.environments || currentAppStateResp.environments.length === 0) {
          return;
        }
        this.currentApplicationCopy = cloneDeep(currentAppStateResp);
        // We need to get the csp env var if it exists. This is for backwards compatibility only:
        const cspStringEnvVar = currentCspStateResp;
        this.setCurrentContentSecurityPolicy(cspStringEnvVar);
        this.initializeCspDirectives();
        this.setCspDirectiveNameToValuesMap();
        this.setCspDirectives();
        this.currentContentSecurityPolicyString = this.getCspStringFromData();
        this.initializeCspFormGroup();
        this.savingCsp = false;
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setCurrentContentSecurityPolicy(cspStringEnvVar: string): void {
    if (
      !this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http?.csp ||
      this.currentApplicationCopy?.environments[0]?.application_configs.security.http.csp.mode === CSPSettings.ModeEnum.disabled
    ) {
      if (!!cspStringEnvVar) {
        this.currentContentSecurityPolicy = this.getCSPSettingsDataFromString(cspStringEnvVar);
        this.currentContentSecurityPolicy.enabled = true;
      } else {
        this.currentContentSecurityPolicy = getDefaultCspSettings();
      }
    } else {
      this.currentContentSecurityPolicy = cloneDeep(this.currentApplicationCopy.environments[0].application_configs.security.http.csp);
    }
  }

  private getCspSettingsFromForm(): CSPSettings {
    return {
      enabled: this.cspForm.get('enabled').value,
      mode: this.cspForm.get('mode').value,
      directives: [],
    };
  }

  private getCSPSettingsDataFromString(cspString: string): CSPSettings {
    const cspSettings = this.getCspSettingsFromForm();
    const cspDirectiveStrings: Array<string> = cspString.split(';').map((dir) => dir.trim());
    for (const directiveString of cspDirectiveStrings) {
      const directiveStringArray = directiveString.split(' ').filter((val) => !val.match(/\s+/));
      const cspDirective: CSPDirective = {
        name: directiveStringArray[0],
        values: directiveStringArray.slice(1),
      };
      cspSettings.directives.push(cspDirective);
    }
    return cspSettings;
  }

  private setCspDirectiveNameToValuesMap(): void {
    this.cspDirectiveNameToValuesMap.clear();
    if (!this.currentContentSecurityPolicy.directives) {
      return;
    }
    for (const cspDirective of this.currentContentSecurityPolicy.directives) {
      this.cspDirectiveNameToValuesMap.set(cspDirective.name, cspDirective.values);
    }
  }

  private setCspAllDirectivesList(): void {
    for (const key of Object.keys(CspDirectiveEnum)) {
      if (CspDirectiveEnum[key] === CspDirectiveEnum.report_uri) {
        // report_uri is not configurable by the user.
        continue;
      }
      this.cspAllDirectivesList.push(CspDirectiveEnum[key]);
    }
  }

  private setCspSourcesList(): void {
    for (const key of Object.keys(CspSourceEnum)) {
      this.cspSourcesList.push(CspSourceEnum[key]);
    }
  }

  private setPanelsStateMapToClosed(): void {
    for (const directive of this.cspAllDirectivesList) {
      this.panelsStateMap.set(directive, false);
    }
  }

  private initializeCspDirectives(): void {
    this.cspDirectives.length = 0;
    for (const directive of this.cspAllDirectivesList) {
      this.cspDirectives.push({
        name: directive,
        sources: this.setCspSourcesMap(directive),
        hosts: [],
        hostsFormControl: new UntypedFormControl(),
        isEnabled: false,
        isOpen: false,
        dirty: false,
      });
    }
  }

  private setCspDirectives(): void {
    for (const directive of this.cspDirectives) {
      const header = this.cspDirectiveNameToValuesMap.get(directive.name);
      if (!!header) {
        directive.isEnabled = true;
        for (const option of header) {
          const isCspSourceEnum = createEnumChecker(CspSourceEnum);
          if (isCspSourceEnum(option)) {
            directive.sources.set(option, true);
          } else {
            directive.hosts.push(option);
          }
        }
      }
    }
  }

  private setCspSourcesMap(directive: CspDirectiveEnum): Map<CspSourceEnum, boolean> {
    const cspSourcesMap = new Map();
    for (const source of this.cspSourcesList) {
      if (directive === CspDirectiveEnum.frame_ancestors) {
        if (source !== CspSourceEnum.self && source !== CspSourceEnum.none) {
          continue;
        }
      }
      cspSourcesMap.set(source, false);
    }
    return cspSourcesMap;
  }

  public isOptionChecked(directiveName: CspDirectiveEnum, option: CspSourceEnum): boolean {
    for (const directive of this.cspDirectives) {
      if (directive.name === directiveName) {
        return directive.sources.get(option);
      }
    }
    return false;
  }

  private setDirectiveEnabledStatus(directive: CspDirective): void {
    if (this.hasAtLeastOneSourceChecked(directive) || directive.hosts.length !== 0) {
      directive.isEnabled = true;
    } else {
      directive.isEnabled = false;
    }
  }

  private hasAtLeastOneSourceChecked(directive: CspDirective): boolean {
    for (const source of this.cspSourcesList) {
      if (directive.sources.get(source)) {
        return true;
      }
    }
    return false;
  }

  private getLaxAngularJSCSPDirectives(): Array<CSPDirective> {
    return [
      { name: CspDirectiveEnum.default_src, values: [CspSourceEnum.self] },
      { name: CspDirectiveEnum.connect_src, values: ['*'] },
      { name: CspDirectiveEnum.font_src, values: [CspSourceEnum.self, 'https://fonts.gstatic.com'] },
      { name: CspDirectiveEnum.form_action, values: [CspSourceEnum.self] },
      { name: CspDirectiveEnum.frame_ancestors, values: [CspSourceEnum.self] },
      { name: CspDirectiveEnum.img_src, values: [CspSourceEnum.self, 'data:'] },
      { name: CspDirectiveEnum.script_src, values: [CspSourceEnum.self, CspSourceEnum.unsafe_inline, CspSourceEnum.unsafe_eval] },
      { name: CspDirectiveEnum.style_src, values: [CspSourceEnum.self, CspSourceEnum.unsafe_inline, 'https://fonts.googleapis.com'] },
      { name: CspDirectiveEnum.report_uri, values: [getDefaultReportURI()] },
    ];
  }

  private getStrictAngularCSPDirectives(): Array<CSPDirective> {
    return [
      { name: CspDirectiveEnum.default_src, values: [CspSourceEnum.self] },
      { name: CspDirectiveEnum.connect_src, values: ['*'] },
      { name: CspDirectiveEnum.font_src, values: [CspSourceEnum.self, 'https://fonts.gstatic.com'] },
      { name: CspDirectiveEnum.form_action, values: [CspSourceEnum.self] },
      { name: CspDirectiveEnum.frame_ancestors, values: [CspSourceEnum.self] },
      { name: CspDirectiveEnum.script_src, values: [CspSourceEnum.self] },
      { name: CspDirectiveEnum.style_src, values: [CspSourceEnum.self, CspSourceEnum.unsafe_inline, 'https://fonts.googleapis.com'] },
      { name: CspDirectiveEnum.report_uri, values: [getDefaultReportURI()] },
    ];
  }

  public applyDefaults(type: defaultType): void {
    const csp: CSPSettings = {
      enabled: true,
      mode: CSPSettings.ModeEnum.enforce,
      directives: [],
    };
    switch (type) {
      case defaultType.noCSP:
        csp.enabled = false;
        break;
      case defaultType.laxAngularJS:
        csp.directives = this.getLaxAngularJSCSPDirectives();
        break;
      case defaultType.strictAngular:
        csp.directives = this.getStrictAngularCSPDirectives();
        break;
    }
    this.currentContentSecurityPolicy = csp;
    this.setCspDirectives();
    this.updateAppConfigCspSettings(csp);
    this.modifyApplication(this.currentApplicationCopy);
  }

  public onCheckboxUpdate(targetDirective: CspDirective, option: CspSourceEnum, isBoxChecked: boolean): void {
    for (const directive of this.cspDirectives) {
      if (directive.name === targetDirective.name) {
        directive.sources.set(option, isBoxChecked);
        this.setDirectiveEnabledStatus(directive);
        this.updatePanelOnChanges(true);
      }
    }
  }

  /**
   * Adds a new chip to the chips input when the user enters a 'separatorKeysCode'.
   * @param event contains the values typed by the user
   */
  public addChipOnInputEvent(event: MatChipInputEvent, directive: CspDirective): void {
    const input = event.input;
    if (input.value === '') {
      this.newChipAdded = false;
      return;
    }
    const valuesArray = getValuesFromInputEventValue(event.value);
    if (!valuesArray) {
      return;
    }
    for (const item of valuesArray) {
      directive.hosts.push(item);
    }
    directive.isEnabled = true;
    this.newChipAdded = true;
    directive.dirty = true;
    // This is false below so that it will refresh the timer on the watcher
    // but will not save to the backend yet.
    this.updatePanelOnChanges(false);
    // Resets the input value so that the next chip to be entered starts as empty.
    if (input) {
      input.value = '';
    }
  }

  public removeChip(chipValue: string, directive: CspDirective): void {
    directive.hosts = directive.hosts.filter((host) => host !== chipValue);
    this.setDirectiveEnabledStatus(directive);
    this.updatePanelOnChanges(true);
  }

  public onFormFieldEdit(textValue: string): void {
    if (textValue === this.currentContentSecurityPolicyString) {
      return;
    }
    if (!isValidCsp(textValue, this.notificationService)) {
      return;
    }
    this.currentContentSecurityPolicyString = textValue;
    this.updateCspSettingsFromCspString(this.currentContentSecurityPolicyString);
    this.modifyApplication(this.currentApplicationCopy);
  }

  public getCspStringFromData(): string {
    let cspString = ``;
    for (const directive of this.cspDirectives) {
      if (!directive.isEnabled) {
        continue;
      }
      let headerString = `` + directive.name + ``;
      for (const source of this.cspSourcesList) {
        if (directive.sources.get(source)) {
          headerString += ` ` + source + ``;
        }
      }
      for (const host of directive.hosts) {
        headerString += ` ` + host;
      }
      headerString += `; `;
      cspString += headerString;
    }
    if (cspString.length) {
      cspString += `report-uri ` + getDefaultReportURI();
    }
    return cspString;
  }

  public onPanelOpen(panel: CspDirectiveEnum): void {
    this.panelsStateMap.set(panel, true);
  }

  public onPanelClose(panel: CspDirectiveEnum): void {
    this.panelsStateMap.set(panel, false);
  }

  public getPanelState(panel: CspDirectiveEnum): boolean {
    return this.panelsStateMap.get(panel);
  }

  private updatePanelOnChanges(saveToDatabase: boolean): void {
    this.watchForPanelUpdates$.next(saveToDatabase);
  }

  public saveOnInputBlur(directive: CspDirective): void {
    if (!directive.dirty) {
      // Do not trigger the api call if the value has not been changed,
      // i.e, if a user clicks in and out of the input field without changing anything.
      return;
    }
    this.newChipAdded = false;
    this.updatePanelOnChanges(true);
  }

  public getCspSettingsFromData(): CSPSettings {
    const cspSettings = this.getCspSettingsFromForm();
    for (const directive of this.cspDirectives) {
      if (!directive.isEnabled) {
        continue;
      }
      const targetDirective: CSPDirective = {
        name: directive.name,
        values: [],
      };
      for (const source of this.cspSourcesList) {
        if (directive.sources.get(source)) {
          targetDirective.values.push(source);
        }
      }
      for (const host of directive.hosts) {
        targetDirective.values.push(host);
      }
      cspSettings.directives.push(targetDirective);
    }
    cspSettings.directives.push({ name: 'report-uri', values: [getDefaultReportURI()] });
    return cspSettings;
  }

  private getUpdatedAppConfigsFromCspString(cspString: string): ApplicationConfig | undefined {
    const updatedCSPSettings = this.getCSPSettingsDataFromString(cspString);
    return this.getUpdatedAppConfigs(updatedCSPSettings);
  }

  private getUpdatedAppConfigs(cspSettings: CSPSettings): ApplicationConfig | undefined {
    if (!this.currentApplicationCopy?.environments[0]) {
      return undefined;
    }
    const currentEnvironmentCopy = cloneDeep(this.currentApplicationCopy.environments[0]);
    setCspConfigIfUnset(currentEnvironmentCopy);
    currentEnvironmentCopy.application_configs.security.http.csp = cspSettings;
    return currentEnvironmentCopy.application_configs;
  }

  private updateCspSettingsFromCspString(cspString: string): void {
    const updatedAppConfigs = this.getUpdatedAppConfigsFromCspString(cspString);
    this.updateApplicationOnAppConfigsChange(updatedAppConfigs);
  }

  private updateAppConfigCspSettings(cspSettings: CSPSettings): void {
    const updatedAppConfigs = this.getUpdatedAppConfigs(cspSettings);
    this.updateApplicationOnAppConfigsChange(updatedAppConfigs);
  }

  private updateApplicationOnAppConfigsChange(appConfigs: ApplicationConfig): void {
    if (!this.currentApplicationCopy) {
      return;
    }
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const env of copyOfCurrentApplicationCopy.environments) {
      env.application_configs = appConfigs;
    }
    this.currentApplicationCopy = copyOfCurrentApplicationCopy;
  }

  private modifyApplication(updatedApplication: Application): void {
    this.currentApplicationCopy = updatedApplication;
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  private updateApplicationConfigOnChanges(): void {
    this.currentContentSecurityPolicy = this.getCspSettingsFromData();
    this.updateAppConfigCspSettings(this.currentContentSecurityPolicy);
    this.modifyApplication(this.currentApplicationCopy);
    this.savingCsp = true;
  }

  public initializeCspFormGroup(): void {
    this.cspForm = this.formBuilder.group({
      enabled: this.currentContentSecurityPolicy?.enabled ? this.currentContentSecurityPolicy.enabled : false,
      mode: this.currentContentSecurityPolicy?.mode ? this.currentContentSecurityPolicy.mode : CSPSettings.ModeEnum.enforce,
    });
  }

  private setCspSettingsFromForm(): void {
    const enabledFormValue = this.cspForm.get('enabled').value;
    const copyOfCurrentContentSecurityPolicy = cloneDeep(this.currentContentSecurityPolicy);
    copyOfCurrentContentSecurityPolicy.enabled = enabledFormValue;
    const modeFormValue = this.cspForm.get('mode').value;
    copyOfCurrentContentSecurityPolicy.mode = modeFormValue;
    this.currentContentSecurityPolicy = copyOfCurrentContentSecurityPolicy;
  }

  public onCheckboxChange(): void {
    this.setFromFormAndSubmit();
  }

  private setFromFormAndSubmit(): void {
    this.setCspSettingsFromForm();
    this.updateAppConfigCspSettings(this.currentContentSecurityPolicy);
    this.modifyApplication(this.currentApplicationCopy);
  }

  public getModeValues(): Array<string> {
    return Object.keys(CSPSettings.ModeEnum).filter((val) => val !== CSPSettings.ModeEnum.disabled);
  }

  public getModeDisplayValue(value: CSPSettings.ModeEnum): string {
    if (value === CSPSettings.ModeEnum.reportonly) {
      return 'report only';
    }
    return value;
  }

  public updateSelectedMode(): void {
    this.setFromFormAndSubmit();
  }

  public getCspEnabledTooltipText(): string {
    return `Whether or not to apply Content Security Policy. 
    If disabled, the system will not set the Content-Security-Policy header. 
    Any CSP headers added by the application will be passed through unchanged.`;
  }

  public getCspModeTooltipText(): string {
    return `Mode configures how the Content Security Policy is enforced, if at all. 
    Its possible values mean the following: 
    - Enforce: Actively enforce the policy. Requests which fail the policy will be blocked. 
    If a report uri is configured for the policy, reports will be sent to it. 
    - Report only: Any requests failing the policy generate reports. They are not blocked.`;
  }

  /**
   * Remove the quotes for checkbox display only.
   */
  public getSourceDisplayValue(source: CspSourceEnum): string {
    return source.replace(/['\']+/g, '');
  }
}
