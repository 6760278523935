import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'portal-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadButtonComponent {
  @Input() public isUploading = false;
  @Input() public buttonDescription = '';
  @Input() public disableUpload = false;
  @Input() public preventUpload = false;
  @Input() public tooltipText = 'Click to select a file to upload';
  @Output() private readFile = new EventEmitter<any>();
  @Output() private uploadClick = new EventEmitter<any>();

  constructor() {}

  public onReadFile(event: any): void {
    this.readFile.emit(event);
  }

  public onUploadClickFunc(): void {
    this.uploadClick.emit();
  }
}
