import { Action, createAction, props } from '@ngrx/store';
import { IssuerClient, UpstreamAlias } from '@agilicus/angular';
import { IssuerClientElement } from '@app/shared/components/application-auth-clients/application-auth-clients.component';

export enum IssuerClientsActionTypes {
  INIT = '[Issuer Clients] Init',
  LOAD_ISSUER_CLIENTS = '[Issuer Clients] LoadIssuerClients',
  SET_ISSUER_CLIENT_LIST = '[Issuer Clients] SetIssuerClientList',
  SAVING_ISSUER_CLIENT = '[Issuer Clients] SavingIssuerClient',
  SUCCESSFUL_ISSUER_CLIENT_SAVE = '[Issuer Clients] SuccessfulIssuerClientSave',
  FAILED_ISSUER_CLIENT_SAVE = '[Issuer Clients] FailedIssuerClientSave',
  DELETING_ISSUER_CLIENT = '[Issuer Clients] DeletingIssuerClient',
  SUCCESSFUL_ISSUER_CLIENT_DELETION = '[Issuer Clients] SuccessfulIssuerClientDeletion',
  FAILED_ISSUER_CLIENT_DELETION = '[Issuer Clients] FailedIssuerClientDeletion',
  REFRESH_ISSUER_CLIENT_STATE = '[Issuer Clients] RefreshIssuerClientState',
  SAVING_UPSTREAM_ALIAS = '[Issuer Clients] SavingUpstreamAlias',
  SUCCESSFUL_UPSTREAM_ALIAS_UPDATE = '[Issuer Clients] SuccessfulUpstreamAliasUpdate',
  FAILED_UPSTREAM_ALIAS_UPDATE = '[Issuer Clients] FailedUpstreamAliasUpdate',
  CLEAR_STATE = '[Issuer Clients] ClearState',
  MAINTAIN_STATE = '[Issuer Clients] MaintainState',
}

// Ngrx entity action
export const loadIssuerClients = createAction(
  '[Issuer Clients/API] Load Issuer Clients',
  props<{ org_id: string; blankSlate: boolean | undefined }>()
);

// Ngrx entity action
export const clearIssuerClients = createAction('[Issuer Clients/API] Clear Issuer Clients');

// Ngrx entity action
export const maintainIssuerClients = createAction('[Issuer Clients/API] Maintain Issuer Clients');

export class ActionIssuerClientsInit implements Action {
  public readonly type = IssuerClientsActionTypes.INIT;
  constructor(public readonly force: boolean = false) {}
}

export class ActionIssuerClientsLoadIssuerClients implements Action {
  public readonly type = IssuerClientsActionTypes.LOAD_ISSUER_CLIENTS;
  constructor(readonly org_id: string) {
    this.org_id = org_id;
  }
}

export class ActionIssuerClientsSetIssuerClientList implements Action {
  public readonly type = IssuerClientsActionTypes.SET_ISSUER_CLIENT_LIST;
  constructor(readonly current_issuer_client_list: Array<IssuerClient>, readonly org_id?: string) {
    this.current_issuer_client_list = current_issuer_client_list;
    this.org_id = org_id;
  }
}

export class ActionIssuerClientsSavingIssuerClient implements Action {
  public readonly type = IssuerClientsActionTypes.SAVING_ISSUER_CLIENT;
  constructor(readonly current_issuer_client: IssuerClient, readonly upstreamAlias?: UpstreamAlias) {}
}

export class ActionIssuerClientsSuccessfulIssuerClientSave implements Action {
  public readonly type = IssuerClientsActionTypes.SUCCESSFUL_ISSUER_CLIENT_SAVE;
  constructor(readonly current_issuer_client: IssuerClient, readonly upstreamAlias?: UpstreamAlias) {}
}

export class ActionIssuerClientsFailedIssuerClientSave implements Action {
  public readonly type = IssuerClientsActionTypes.FAILED_ISSUER_CLIENT_SAVE;
  constructor() {}
}

export class ActionIssuerClientsDeletingIssuerClient implements Action {
  public readonly type = IssuerClientsActionTypes.DELETING_ISSUER_CLIENT;
  constructor(readonly issuer_client_to_delete: IssuerClientElement) {
    this.issuer_client_to_delete = issuer_client_to_delete;
  }
}

export class ActionIssuerClientsSuccessfulIssuerClientDeletion implements Action {
  public readonly type = IssuerClientsActionTypes.SUCCESSFUL_ISSUER_CLIENT_DELETION;
  constructor(readonly issuer_client_to_delete: IssuerClient) {
    this.issuer_client_to_delete = issuer_client_to_delete;
  }
}

export class ActionIssuerClientsFailedIssuerClientDeletion implements Action {
  public readonly type = IssuerClientsActionTypes.FAILED_ISSUER_CLIENT_DELETION;
  constructor() {}
}

export class ActionIssuerClientsRefreshIssuerClientState implements Action {
  public readonly type = IssuerClientsActionTypes.REFRESH_ISSUER_CLIENT_STATE;
  constructor() {}
}

export class ActionIssuerClientsSavingUpstreamAlias implements Action {
  public readonly type = IssuerClientsActionTypes.SAVING_UPSTREAM_ALIAS;
  constructor(readonly upstream_alias: UpstreamAlias) {}
}

export class ActionIssuerClientsSuccessfulUpstreamAliasUpdate implements Action {
  public readonly type = IssuerClientsActionTypes.SUCCESSFUL_UPSTREAM_ALIAS_UPDATE;
  constructor() {}
}

export class ActionIssuerClientsFailedUpstreamAliasUpdate implements Action {
  public readonly type = IssuerClientsActionTypes.FAILED_UPSTREAM_ALIAS_UPDATE;
  constructor() {}
}

export class ActionIssuerClientsClearState implements Action {
  public readonly type = IssuerClientsActionTypes.CLEAR_STATE;
  constructor() {}
}

export class ActionIssuerClientsMaintainState implements Action {
  public readonly type = IssuerClientsActionTypes.MAINTAIN_STATE;
  constructor() {}
}

export type IssuerClientsActions =
  | ActionIssuerClientsInit
  | ActionIssuerClientsLoadIssuerClients
  | ActionIssuerClientsSetIssuerClientList
  | ActionIssuerClientsSavingIssuerClient
  | ActionIssuerClientsSuccessfulIssuerClientSave
  | ActionIssuerClientsFailedIssuerClientSave
  | ActionIssuerClientsDeletingIssuerClient
  | ActionIssuerClientsSuccessfulIssuerClientDeletion
  | ActionIssuerClientsFailedIssuerClientDeletion
  | ActionIssuerClientsRefreshIssuerClientState
  | ActionIssuerClientsSavingUpstreamAlias
  | ActionIssuerClientsSuccessfulUpstreamAliasUpdate
  | ActionIssuerClientsFailedUpstreamAliasUpdate
  | ActionIssuerClientsClearState
  | ActionIssuerClientsMaintainState;
