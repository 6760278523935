import { createSelector } from '@ngrx/store';
import { selectSSHState } from '../core.state';
import { SSHResourceState, selectAll, selectEntities } from './ssh.reducer';

export const selectSSHResources = createSelector(selectSSHState, (state: SSHResourceState) => state);

export const selectSSHResourceList = createSelector(selectSSHResources, selectAll);

export const selectSSHResourceEntities = createSelector(selectSSHResources, selectEntities);

export const selectSSHResourceEntity = (props: { id: string }) =>
  createSelector(selectSSHResourceEntities, (entities) => {
    return entities[props.id];
  });

export const selectSSHResourceRefreshDataValue = createSelector(selectSSHResources, (state: SSHResourceState) => state.refresh_data);

export const selectSSHResourceShouldPopulateValue = createSelector(selectSSHResources, (state: SSHResourceState) => state.should_populate);

// Custom resource selectors:
export const selectSSHResourceIconStatus = createSelector(selectSSHResources, (state: SSHResourceState) => state.icon_status);
