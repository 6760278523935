<div
  class="action-button-container"
  matTooltip="{{ disabledButtonTooltipText }}"
  [matTooltipDisabled]="!disableButton"
  attr.aria-label="{{ disabledButtonAriaLabelText }}"
>
  <button
    type="button"
    mat-raised-button
    color="{{ buttonColor }}"
    class="control-button"
    (click)="onButtonClick()"
    matTooltip="{{ tooltipText }}"
    attr.aria-label="{{ ariaLabelText }}"
    [disabled]="disableButton"
    [matTooltipDisabled]="disableButton"
    [hidden]="hideButton"
  >
    {{ buttonName }}
  </button>
</div>