import { Application, ApplicationConfig, Rule } from '@agilicus/angular';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { setCorsConfigPropertyIfUnset } from '../application-configs-utils';
import { FilterManager } from '../filter/filter-manager';
import { getFilteredValues } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { OptionalCorsAllowMethodsElement } from '../optional-types';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { AutoInputColumn, Column, createAutoInputColumn, createSelectRowColumn } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { updateTableElements } from '../utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';

export interface CorsAllowMethodsElement extends TableElement {
  method: Rule.MethodEnum | string;
  methodFormControl: UntypedFormControl;
}

@Component({
  selector: 'portal-cors-allow-methods',
  templateUrl: './cors-allow-methods.component.html',
  styleUrls: ['./cors-allow-methods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorsAllowMethodsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public currentApplicationCopy: Application;
  @Output() public updateApplication = new EventEmitter<any>();
  public tableData: Array<CorsAllowMethodsElement> = [];
  public columnDefs: Map<string, Column<CorsAllowMethodsElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'METHOD';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
  }

  public ngOnChanges(): void {
    if (!this.currentApplicationCopy?.environments || this.currentApplicationCopy.environments.length === 0) {
      this.resetEmptyTable();
      return;
    }
    this.updateTable();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<CorsAllowMethodsElement> = [];
    const allowMethods = this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http?.cors?.allow_methods;
    if (!!allowMethods) {
      for (let i = 0; i < allowMethods.length; i++) {
        data.push(this.createAllowMethodElement(allowMethods[i], i));
      }
    }
    updateTableElements(this.tableData, data);
  }

  private createAllowMethodElement(allowMethod: string, index: number): CorsAllowMethodsElement {
    const data: CorsAllowMethodsElement = {
      method: allowMethod,
      ...getDefaultTableProperties(index),
      methodFormControl: new UntypedFormControl(),
    };
    data.methodFormControl.setValue(allowMethod);
    return data;
  }

  private getMethodColumnAllowedValues(): Array<{ method: string }> {
    return Object.values(Rule.MethodEnum).map((value) => {
      let val: string = value;
      if (val === 'all') {
        val = '*';
      }
      return { method: val.toUpperCase() };
    });
  }

  private getMethodColumn(): AutoInputColumn<CorsAllowMethodsElement> {
    const methodColumn = createAutoInputColumn('methodFormControl');
    methodColumn.displayName = 'Method';
    methodColumn.requiredField = () => true;
    methodColumn.isEditable = true;
    methodColumn.isCaseSensitive = true;
    methodColumn.getDisplayValue = (allowMethod: OptionalCorsAllowMethodsElement) => {
      return allowMethod.method;
    };
    methodColumn.getFilteredValues = (
      element: OptionalCorsAllowMethodsElement,
      column: AutoInputColumn<CorsAllowMethodsElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.methodFormControl, column);
    };
    methodColumn.allowedValues = this.getMethodColumnAllowedValues();
    return methodColumn;
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();
    const methodColumn = this.getMethodColumn();

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(methodColumn.name, methodColumn);
  }

  private getMethodsFromTable(): Array<string> {
    const methods: Array<string> = [];
    for (const element of this.tableData) {
      methods.push(element.method);
    }
    return methods;
  }

  /**
   * Saves the updated row.
   */
  public updateEvent(): void {
    this.updateAppConfigsAllowMethods();
  }

  public updateAutoInput(params: {
    optionValue: string;
    column: AutoInputColumn<CorsAllowMethodsElement>;
    element: CorsAllowMethodsElement;
  }): void {
    if (params.column.name !== 'methodFormControl') {
      return;
    }
    if (params.optionValue === params.element.method) {
      // The value has not been changed.
      return;
    }
    params.element.method = params.optionValue;
    params.element.dirty = true;
  }

  private updateAppConfigsAllowMethods(): void {
    const updatedAppConfigs = this.getUpdatedAppConfigs();
    this.updateApplication.emit(updatedAppConfigs);
  }

  private getUpdatedAppConfigs(): ApplicationConfig {
    const existingEnvironmentCopy = cloneDeep(this.currentApplicationCopy?.environments[0]);
    setCorsConfigPropertyIfUnset(existingEnvironmentCopy, 'allow_methods');
    const updatedAllowMethods = this.getMethodsFromTable();
    existingEnvironmentCopy.application_configs.security.http.cors.allow_methods = updatedAllowMethods;
    return existingEnvironmentCopy.application_configs;
  }

  private removeElements(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
  }

  public deleteSelected(): void {
    this.removeElements();
    this.updateAppConfigsAllowMethods();
  }

  public makeEmptyTableElement(): CorsAllowMethodsElement {
    return {
      method: '',
      ...getDefaultNewRowProperties(),
      methodFormControl: new UntypedFormControl(),
    };
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }
}
