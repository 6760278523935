export interface DropdownSelector {
  selectorLabel: string;
  selectorElement: any;
  selectorData: Array<any>;
  selectorTooltipText: string;
  getSelectorValueFromElement: (element: any) => string;
  getSelectorOptionValue: (option: any) => string;
  getSelectorOptionDisplayValue: (option: any) => string;
  onSelectionChange: (optionValue: string) => void;
}

export function createDropdownSelector(): DropdownSelector {
  return {
    selectorLabel: '',
    selectorElement: {},
    selectorData: [],
    selectorTooltipText: '',
    getSelectorValueFromElement: (element: any) => {
      return element;
    },
    getSelectorOptionValue: (option: any) => {
      return option;
    },
    getSelectorOptionDisplayValue: (option: any) => {
      return option;
    },
    onSelectionChange: (optionValue: string) => {},
  };
}
