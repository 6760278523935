import { EnvironmentState } from './environment.model';
import { EnvironmentActions, EnvironmentActionTypes } from './environment.actions';

export const environmentInitialState: EnvironmentState = {
  overrideDomain: undefined,
  overrideApiDomain: undefined,
  overrideWhoamiDomain: undefined,
  overrideClientId: undefined,
};

export function environmentReducer(state: EnvironmentState = environmentInitialState, action: EnvironmentActions): EnvironmentState {
  switch (action.type) {
    case EnvironmentActionTypes.ENVIRONMENT_LOADED:
      return {
        ...state,
        overrideDomain: action.current_environment.overrideDomain,
        overrideApiDomain: action.current_environment.overrideApiDomain,
        overrideWhoamiDomain: action.current_environment.overrideWhoamiDomain,
        overrideClientId: action.current_environment.overrideClientId,
      };

    default:
      return state;
  }
}
