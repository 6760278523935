<portal-table-layout 
  class="table-layout"
  [tableData]="tableData" 
  [columnDefs]="columnDefs" 
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [rowObjectName]="rowObjectName" 
  [buttonsToShow]="buttonsToShow"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  pageDescriptiveText="Mount an external filesystem (e.g. SMB) into your running container."
  productGuideLink="https://www.agilicus.com/anyx-guide/product-guide-applications/#h-instance-details"
  (updateEvent)="updateEvent($event)"
  (updateAutoInput)="updateAutoInput($event)"
  (updateSelection)="updateSelection($event)"
  (removeSelected)="deleteSelected($event)"
>
</portal-table-layout>