import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core';
import { GettingStartedTask } from '@app/core/user/preferences/getting-started-tasks.enum';
import { GettingStartedData } from '@app/core/user/preferences/getting-started-data';
import {
  getTaskCompletedStatus,
  getTaskCompleteToggleText,
  toggleGettingStartedTask,
} from '@app/core/user/preferences/user-preference-utils';
import { selectApiOrgId, selectGettingStartedData } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';

export enum UserAuditIssue {
  login = 'login',
  resourceAccess = 'resourceAccess',
  resourceRequest = 'resourceRequest',
  resourceError = 'resourceError',
  viewLogins = 'viewLogins',
}

export enum LoginIssue {
  mfa = 'mfa',
  other = 'other',
}

export enum RequestAccessType {
  application = 'application',
  resource = 'resource',
}

@Component({
  selector: 'portal-audit-user-guide',
  templateUrl: './audit-user-guide.component.html',
  styleUrls: ['./audit-user-guide.component.scss', '../getting-started-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditUserGuideComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public gettingStartedDataCopy: GettingStartedData;
  public pageDescriptiveText = `The platform provides a complete audit trail of who has accessed what, when, from where. 
  You can use this to diagnose user issues (including resetting their Multi-Factor Authentication requirements).`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/users/#h-audits`;
  public isTaskComplete = false;
  public targetTask = GettingStartedTask.completed_audit_user;
  public auditUserFormGroup: UntypedFormGroup;

  public getTaskCompletedStatus = getTaskCompletedStatus;
  public getTaskCompleteToggleText = getTaskCompleteToggleText;

  // This is required in order to reference the enums in the html template.
  public userAuditIssue = UserAuditIssue;
  public loginIssue = LoginIssue;
  public requestAccessType = RequestAccessType;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private routerHelperService: RouterHelperService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    combineLatest([orgId$, gettingStartedData$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, gettingStartedDataResp]) => {
        this.orgId = orgIdResp;
        this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
        this.isTaskComplete = getTaskCompletedStatus(this.targetTask, this.gettingStartedDataCopy);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.auditUserFormGroup = this.formBuilder.group({
      userIssue: ['', Validators.required],
      loginIssue: ['', Validators.required],
      requestAccessType: ['', Validators.required],
    });
  }

  public isFormGroupValid(): boolean {
    if (this.isLoginOptionSelected() && this.auditUserFormGroup.get('loginIssue').valid) {
      return true;
    }
    if (this.isRequestsOptionSelected() && this.auditUserFormGroup.get('requestAccessType').valid) {
      return true;
    }
    return this.auditUserFormGroup.get('userIssue').valid;
  }

  public getAuditUserDataFromForm(formField: string): string {
    return this.auditUserFormGroup.get(formField).value;
  }

  public isLoginOptionSelected(): boolean {
    return this.getAuditUserDataFromForm('userIssue') === UserAuditIssue.login;
  }

  public showMfaResetRecommendation(): boolean {
    return this.isLoginOptionSelected() && this.getAuditUserDataFromForm('loginIssue') === LoginIssue.mfa;
  }

  public showPermissionsAndSessionAuditRecommendation(): boolean {
    return (
      (this.isLoginOptionSelected() && this.getAuditUserDataFromForm('loginIssue') === LoginIssue.other) ||
      this.getAuditUserDataFromForm('userIssue') === UserAuditIssue.resourceAccess
    );
  }

  public isRequestsOptionSelected(): boolean {
    return this.getAuditUserDataFromForm('userIssue') === UserAuditIssue.resourceRequest;
  }

  public showApplicationRequestsRecommendation(): boolean {
    return this.isRequestsOptionSelected() && this.getAuditUserDataFromForm('requestAccessType') === RequestAccessType.application;
  }

  public showResourceRequestsRecommendation(): boolean {
    return this.isRequestsOptionSelected() && this.getAuditUserDataFromForm('requestAccessType') === RequestAccessType.resource;
  }

  public showLogsRecommendation(): boolean {
    return this.getAuditUserDataFromForm('userIssue') === UserAuditIssue.resourceError;
  }

  public showSessionAuditRecommendation(): boolean {
    return this.getAuditUserDataFromForm('userIssue') === UserAuditIssue.viewLogins;
  }

  public onImageClick(routerLink: string): void {
    this.routerHelperService.routeToScreen(routerLink, this.orgId);
  }

  public onCompleteToggle(isChecked: boolean): void {
    toggleGettingStartedTask(isChecked, this.targetTask, this.gettingStartedDataCopy, this.store);
  }
}
