import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as LauncherActions from './launcher.actions';
import { Launcher } from '@agilicus/angular';
import { BasicCollectionState, ResourceBasicCollectionState } from '@app/core/basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import {
  getDefaultBasicCollectionState,
  getDefaultIconStatus,
  getDefaultResourceBasicCollectionState,
} from '../helpers/entity-state-utils';

export const launcersFeatureKey = 'launcher';

export type LauncherState = ResourceBasicCollectionState<Launcher>;

export const adapter: EntityAdapter<Launcher> = createEntityAdapter<Launcher>({
  sortComparer: sortListByCreatedDate,
  selectId: (launcher) => launcher.metadata.id,
});

export const initialState: LauncherState = adapter.getInitialState(getDefaultResourceBasicCollectionState<Launcher>());

export const launcherReducer = createReducer(
  initialState,
  on(LauncherActions.addLauncher, (state, action) => adapter.addOne(action.obj, state)),
  on(LauncherActions.upsertLauncher, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(LauncherActions.addLaunchers, (state, action) => adapter.addMany(action.objs, state)),
  on(LauncherActions.upsertLaunchers, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(LauncherActions.updateLauncher, (state, action) => adapter.updateOne(action.obj, state)),
  on(LauncherActions.updateLaunchers, (state, action) => adapter.updateMany(action.objs, state)),
  on(LauncherActions.deleteLauncher, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(LauncherActions.deleteLaunchers, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(LauncherActions.loadLaunchers, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(LauncherActions.clearLaunchers, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(LauncherActions.savingLauncher, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(LauncherActions.savingLaunchers, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(LauncherActions.initLaunchers, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(LauncherActions.refreshLaunchers, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  }),
  // custom resource reducers:
  on(LauncherActions.savingIconLauncher, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: true,
        icon_file_save_success: false,
        icon_file_save_fail: false,
      },
    };
  }),
  on(LauncherActions.successfulIconSaveLauncher, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: true,
        icon_file_save_fail: false,
      },
    };
  }),
  on(LauncherActions.failedIconSaveLauncher, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: false,
        icon_file_save_fail: true,
      },
    };
  }),
  on(LauncherActions.resetIconStatusLauncher, (state, action) => {
    return { ...state, icon_status: getDefaultIconStatus() };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
