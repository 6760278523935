import { User, UserMetadata, UserMetadataSpec } from '@agilicus/angular';
import { AppState } from '@app/core';
import { getJSONFromString } from '@app/shared/components/file-utils';
import { Store } from '@ngrx/store';
import { ActionUserUpdateGettingStartedData } from '../user.actions';
import { AdminPortalData } from './admin-portal-data';
import { GettingStartedTask } from './getting-started-tasks.enum';
import { GettingStartedData } from './getting-started-data';

/**
 * The admin portal user metadata for a user that has just logged in for the first time
 */
export function getNewUserAdminPortalUserMetadata(user: User): UserMetadata {
  return {
    spec: getNewUserAdminPortalUserMetadataSpec(user),
  };
}

/**
 * We need this check since the user object we get from the token
 * does not have the created date information. This is to be added in the near future.
 */
export function getGettingStartedSignupDate(user: User): Date {
  if (!user.created) {
    return new Date();
  }
  return new Date(user.created);
}

/**
 * The admin portal user metadata for a user that has just logged in for the first time
 */
export function getNewUserAdminPortalUserMetadataSpec(user: User): UserMetadataSpec {
  const userSignupDate = getGettingStartedSignupDate(user);
  const adminPortalData: AdminPortalData = {
    gettingStarted: getNewUserGettingStartedData(userSignupDate),
  };
  return {
    user_id: user.id,
    org_id: user.org_id,
    app_id: getAdminPortalAppId(),
    data_type: UserMetadataSpec.DataTypeEnum.json,
    data: JSON.stringify(adminPortalData),
  };
}

/**
 * The getting started data for a user that has just logged in for the first time
 */
export function getNewUserGettingStartedData(userSignupDate: Date): GettingStartedData {
  return {
    signup_date: userSignupDate,
    hide_getting_started: false,
    messages: [],
    tasks: {
      completed_identify_users: false,
      completed_connect_to_sites: false,
      completed_expose_resource: false,
      completed_assign_permissions: false,
      completed_audit_user: false,
      completed_setup_payment: false,
    },
    hide_payment_reminder: false,
  };
}

export function getAdminPortalAppId(): string {
  return 'urn-agilicus-builtin-app-admin-portal';
}

export function getAdminPortalDataFromUserMetadata(adminPortalUserMetadata: UserMetadata): AdminPortalData | undefined {
  if (!adminPortalUserMetadata?.spec?.data) {
    return undefined;
  }
  const dataAsString = adminPortalUserMetadata.spec.data;
  const adminPortalData: AdminPortalData = getJSONFromString(dataAsString);
  return adminPortalData;
}

export function getGettingStartedDataFromAdminPortalUserMetadata(adminPortalUserMetadata: UserMetadata): GettingStartedData | undefined {
  const adminPortalData = getAdminPortalDataFromUserMetadata(adminPortalUserMetadata);
  return adminPortalData?.gettingStarted;
}

export function getTaskCompletedStatus(task: GettingStartedTask, gettingStartedData: GettingStartedData): boolean {
  if (!gettingStartedData?.tasks) {
    return false;
  }
  return gettingStartedData.tasks[task];
}

export function toggleGettingStartedTask(
  isChecked: boolean,
  task: GettingStartedTask,
  gettingStartedData: GettingStartedData,
  store: Store<AppState>
): void {
  gettingStartedData.tasks[task] = isChecked;
  store.dispatch(new ActionUserUpdateGettingStartedData(gettingStartedData));
}

export function getTaskCompleteToggleText(task: GettingStartedTask, gettingStartedData: GettingStartedData): string {
  const isTaskCompleted = getTaskCompletedStatus(task, gettingStartedData);
  if (isTaskCompleted) {
    return 'Task has been completed';
  }
  return 'Mark task as complete';
}
