import { createSelector } from '@ngrx/store';
import { selectFileShareServiceState } from '../core.state';
import { FileShareServiceState, selectAll, selectEntities } from './file-share-service.reducer';

export const selectFileShareServices = createSelector(selectFileShareServiceState, (state: FileShareServiceState) => state);

export const selectFileShareServiceList = createSelector(selectFileShareServices, selectAll);

export const selectFileShareServiceEntities = createSelector(selectFileShareServices, selectEntities);

export const selectFileShareServiceEntity = (props: { id: string }) =>
  createSelector(selectFileShareServiceEntities, (entities) => {
    return entities[props.id];
  });

export const selectFileShareServiceRefreshDataValue = createSelector(
  selectFileShareServices,
  (state: FileShareServiceState) => state.refresh_data
);

export const selectFileShareServiceShouldPopulateValue = createSelector(
  selectFileShareServices,
  (state: FileShareServiceState) => state.should_populate
);

// Custom resource selectors:
export const selectFileShareServiceIconStatus = createSelector(
  selectFileShareServices,
  (state: FileShareServiceState) => state.icon_status
);
