import { createSelector } from '@ngrx/store';
import { selectBillingState } from '../core.state';
import { BillingAccountFull } from '../models/billing/billing-account-full';
import { BillingAccountFullState, selectAll, selectEntities } from './billing-account-full.reducer';

export interface TrialPeriodData {
  percentageRemaining: number | null;
  daysRemaining: number | null;
}

export const selectBillingAccountFull = createSelector(selectBillingState, (state: BillingAccountFullState) => state);

export const selectBillingAccountFullsList = createSelector(selectBillingAccountFull, selectAll);

/**
 * Only one billing account will exist in the list, so we select the first list entry.
 */
export const selectCurrentBillingAccountFull = createSelector(selectBillingAccountFullsList, (list: Array<BillingAccountFull>) =>
  !!list && list.length !== 0 ? list[0] : undefined
);

export const selectBillingAccountFullEntities = createSelector(selectBillingAccountFull, selectEntities);

export const selectBillingAccountFullEntity = (props: { id: string }) =>
  createSelector(selectBillingAccountFullEntities, (entities) => {
    return entities[props.id];
  });

export const selectSavingBillingAccountFull = createSelector(selectBillingAccountFull, (state: BillingAccountFullState) => {
  if (!state) {
    return undefined;
  }
  return state.saving_state;
});

export const selectBillingAccountFullRefreshDataValue = createSelector(
  selectBillingAccountFull,
  (state: BillingAccountFullState) => state.refresh_data
);

export const selectBillingAccountFullShouldPopulateValue = createSelector(
  selectBillingState,
  (state: BillingAccountFullState) => state.should_populate
);

// Custom Selectors:
export const selectBillingAccountFullDefaultPaymentMethod = createSelector(
  selectBillingState,
  (state: BillingAccountFullState) => state.default_payment_method
);

export const selectBillingAccountFullIsStarterPlan = createSelector(
  selectBillingState,
  (state: BillingAccountFullState) => state.is_starter_plan
);
