import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Connector } from '@agilicus/angular';
import { AppState } from '..';
import { selectCanAdminApps } from '../user/permissions/app.selectors';
import * as ConnectorActions from './connector.actions';
import { selectConnectors, selectConnectorEntity, selectConnectorList, selectConnectorShouldPopulateValue } from './connector.selectors';
import { ConnectorState } from './connector.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
} from '../helpers/effect-factories';
import { ConnectorStateService } from '../state-services/connector-state.service';
import { mergeMap, of, switchMap, takeUntil, timer, withLatestFrom } from 'rxjs';

@Injectable()
export class ConnectorEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private connectorStateService: ConnectorStateService) {}

  public initState$ = createInitStateEffect<ConnectorState>(
    this.store,
    this.actions$,
    ConnectorActions.initConnectors,
    ConnectorActions.getConnectors,
    ConnectorActions.maintainConnectors,
    selectConnectors
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    ConnectorActions.getConnectors,
    ConnectorActions.maintainConnectors,
    selectConnectorShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<Connector, string>(
    this.store,
    this.actions$,
    ConnectorActions.getConnectors,
    ConnectorActions.clearConnectors,
    ConnectorActions.maintainConnectors,
    this.connectorStateService,
    selectCanAdminApps
  );

  public setCrudState$ = createSetCrudStateEffect<Connector, string>(
    this.store,
    this.actions$,
    ConnectorActions.loadConnectors,
    ConnectorActions.maintainConnectors,
    this.connectorStateService,
    selectConnectorList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<Connector, string>(
    this.store,
    this.actions$,
    ConnectorActions.savingConnector,
    ConnectorActions.maintainConnectors,
    this.connectorStateService,
    selectConnectorEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<Connector, string>(
    this.store,
    this.actions$,
    ConnectorActions.savingConnectors,
    ConnectorActions.maintainConnectors,
    this.connectorStateService,
    selectConnectors
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<Connector, string>(
    this.actions$,
    ConnectorActions.deletingConnector,
    ConnectorActions.refreshConnectors,
    ConnectorActions.maintainConnectors,
    this.connectorStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<Connector, string>(
    this.actions$,
    ConnectorActions.deletingConnectors,
    ConnectorActions.refreshConnectors,
    ConnectorActions.maintainConnectors,
    this.connectorStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<ConnectorState>(
    this.store,
    this.actions$,
    ConnectorActions.getConnectors,
    ConnectorActions.maintainConnectors,
    selectConnectors
  );

  // Custom Effects:
  public initStatePolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectorActions.initConnectorsPolling),
      switchMap((action) => {
        // Poll for data every 10 seconds:
        return timer(0, 10000).pipe(
          mergeMap(() => {
            return of(ConnectorActions.initConnectors({ force: action.force, blankSlate: action.blankSlate }));
          }),
          takeUntil(this.actions$.pipe(ofType(ConnectorActions.stopConnectorsPolling)))
        );
      })
    )
  );
}
