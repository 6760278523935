import { ApplicationModel } from '@app/core/models/application/application-model';
import {
  Application,
  Environment,
  EnvironmentConfigVarList,
  ApplicationFileConfigElement,
  EnvironmentConfig,
  RuleV2,
  RoleToRuleEntry,
  RoleV2,
  FileSummary,
  FileShareService,
  DesktopResource,
  SSHResource,
  ApplicationService,
  Launcher,
  RuleConfig,
} from '@agilicus/angular';
import { AppBundle } from '../api/applications/app-bundle';
import { FileShareModel } from '../models/file-share/file-share-model';
import { DesktopModel } from '../models/desktop/desktop-model';
import { SSHModel } from '../models/ssh/ssh-model';
import { getNewCrudStateObjGuid } from '../api/state-driven-crud/state-driven-crud';
import { NetworkModel } from '../models/network/network-model';
import { LauncherModel } from '../models/launcher/launcher-model';

export interface ApiApplicationsState {
  /**
   * The list of applications retrieved from the http request.
   */
  applications: Array<Application>;

  /**
   * The currently selected application to be viewed/edited by the user.
   */
  current_application: Application;

  /**
   * The previously selected application.
   */
  previous_application: Application;

  /**
   * The currently selected environment to be viewed/edited by the user.
   */
  current_environment: Environment;

  /**
   * The previously selected environment.
   */
  previous_environment: Environment;

  /**
   * The currently selected rule to be viewed/edited by the user.
   */
  current_rule: RuleV2 | RuleConfig;

  /**
   * The previously selected rule.
   */
  previous_rule: RuleV2 | RuleConfig;

  /**
   * The list of environment config vars retrieved from the http request.
   */
  current_environment_config_var_list: EnvironmentConfigVarList;

  /**
   * The previously selected list of environment config vars.
   */
  previous_environment_config_var_list: EnvironmentConfigVarList;

  /**
   * The list of environment file configs retrieved from the http request.
   */
  current_environment_file_config_list: Array<ApplicationFileConfigElement>;

  /**
   * The previously selected list of environment file configs.
   */
  previous_environment_file_config_list: Array<ApplicationFileConfigElement>;

  /**
   * The list of external mounts retrieved from the http request.
   */
  current_external_mounts_list: Array<EnvironmentConfig>;

  /**
   * The previously selected list of external_mounts.
   */
  previous_external_mounts_list: Array<EnvironmentConfig>;

  /**
   * The list of application bundles retrieved from the http request.
   * An application bundle is a file.
   */
  current_application_bundles_list: Array<AppBundle>;

  /**
   * The previously selected list of application bundles.
   * An application bundle is a file.
   */
  previous_application_bundles_list: Array<AppBundle>;

  /**
   * The list of roles retrieved from the http request.
   */
  current_roles_list: Array<RoleV2>;

  /**
   * The previously selected list of roles.
   */
  previous_roles_list: Array<RoleV2>;

  /**
   * A flag that determines whether the application should be published and the
   * default role set. Published applications must have a default role. If a published
   * application is being created or an existing application without a default role is being published,
   * we must first create the role before we can update the application with this information.
   */
  set_default_role_and_publish_application: boolean;

  /**
   * The list of rules retrieved from the http request.
   */
  current_rules_list: Array<RuleV2>;

  /**
   * The previously selected list of rules.
   */
  previous_rules_list: Array<RuleV2>;

  /**
   * The list of role to rule entries retrieved from the http request.
   */
  current_role_to_rule_entries_list: Array<RoleToRuleEntry>;

  /**
   * The previously selected list of roles_to_rule_entries.
   */
  previous_role_to_rule_entries_list: Array<RoleToRuleEntry>;

  /**
   * The content security policy value that will be applied to instances of the application.
   */
  current_application_content_security_policy: string;

  /**
   * The content security policy value that will be applied to instances of the application.
   */
  previous_application_content_security_policy: string;

  /**
   * Is initially false on login. Will become true when a user triggers an
   * ActionApiApplicationsModifyCurrentApp action. Will become false when
   * either a ActionApiApplicationsAppSaveFinished action is triggered.
   */
  saving_app: boolean;

  /**
   * Is initially true on login. Will become false when the user triggers an
   * ActionApiApplicationsModifyCurrentApp action. Will become true when an
   * ActionApiApplicationsAppSaveFinished action is triggered.
   * Will become true when the user triggers an
   * ActionApiApplicationsCreatingNewAppCanceled action.
   */
  app_saved: boolean;

  /**
   * Is initially false on login. Will become true when a user triggers a
   * ActionApiApplicationsCreatingNewApp action. Will become false when
   * a ActionApiApplicationsAppSaveFinished action is triggered or the user
   * triggers an ActionApiApplicationsCreatingNewAppCanceled action.
   */
  creating_new_app: boolean;

  /**
   * Organisation identifier used to retrieve the applications list
   */
  applications_org_id: string;

  /**
   * The application id currently set by the user.
   */
  application_id: string;

  /**
   * The environment name currently set by the user.
   */
  environment_name: string;

  /**
   * The rule id currently set by the user.
   */
  rule_id: string;

  /**
   * Is set to true when a new file has been added to the
   * current_environment_file_config_list.
   */
  new_file_added: boolean;

  /**
   * Is set to true when the current_environment is changed,
   * which triggers the call to retrieve the env_file_config list.
   */
  loading_env_file_config: boolean;

  /**
   * Whether the state should be populated with the latest api data.
   */
  should_populate: boolean;

  /**
   * Is set to true when a new app bundle has been added to the
   * current_application_bundles_list.
   */
  new_app_bundle_added: boolean;

  /**
   * Is initially false on login. Will become true when a user triggers an
   * ActionApiApplicationsSavingAppIconFile action. Will become false when
   * either an ActionApiApplicationsSuccessfulAppIconFileSave or
   * ActionApiApplicationsFailedAppIconFileSave action is triggered.
   */
  saving_app_icon_file: boolean;

  /**
   * Is initially false on login. Will become true when a user triggers an
   * ActionApiApplicationsSuccessfulAppIconFileSave action. Will become false when
   * a ActionApiApplicationsResetAppIconFileUploadStatus action is triggered.
   */
  app_icon_file_save_success: boolean;

  /**
   * Is initially false on login. Will become true when a user triggers an
   * ActionApiApplicationsFailedAppIconFileSave action. Will become false when
   * an ActionApiApplicationsResetAppIconFileUploadStatus action is triggered.
   */
  app_icon_file_save_fail: boolean;

  /**
   * Is initially false on login. Will become true when a user triggers an
   * ActionApiApplicationsDeletingAppIconUrl action. Will become false when
   * either a ActionApiApplicationsSuccessfulAppIconFileDeletion action is triggered.
   */
  deleting_current_app_icon_file: boolean;

  current_icon_files_list: Array<FileSummary>;

  /**
   * The data model that representing the desired state of an application.
   */
  application_model: ApplicationModel;

  application_model_status: ApplicationModelStatus;

  /**
   * The data model that representing the desired state of a file share.
   */
  file_share_model: FileShareModel;

  file_share_model_status: ApplicationModelStatus;

  /**
   * The data model that representing the desired state of a desktop resource.
   */
  desktop_model: DesktopModel;

  desktop_model_status: ApplicationModelStatus;

  /**
   * The data model that representing the desired state of a ssh resource.
   */
  ssh_model: SSHModel;

  ssh_model_status: ApplicationModelStatus;

  /**
   * The data model that representing the desired state of a network resource.
   */
  network_model: NetworkModel;

  network_model_status: ApplicationModelStatus;

  /**
   * The data model that representing the desired state of a launcher resource.
   */
  launcher_model: LauncherModel;

  launcher_model_status: ApplicationModelStatus;

  /**
   * We increment this number by 1 when we want to refresh the data on a page with the latest form the api
   */
  refresh_data: number;

  saving_rule: boolean;

  demo_status: ApplicationModelStatus;

  /**
   * Determines whether to retrieve the env config vars, file config, files, roles, rules,
   * role to rule entries, etc for the current application
   */
  get_all_current_app_config_from_api: boolean;

  use_policy_rules: boolean;
}

// TODO: change name to ResourceModelStatus
export interface ApplicationModelStatus {
  saving: boolean;
  save_success: boolean;
  save_fail: boolean;
  complete: boolean;
  deleting?: boolean;
  delete_success?: boolean;
  delete_failed?: boolean;
  share?: FileShareService;
  desktopResource?: DesktopResource;
  sshResource?: SSHResource;
  network?: ApplicationService;
  launcher?: Launcher;
}

export function createBlankApplication(): Application {
  return {
    assigned: false,
    assignments: [],
    category: '',
    definitions: [],
    environments: [],
    maintained: false,
    name: '',
    org_id: '',
    owned: true,
    roles: [],
    // Setting to 5000 by default.
    port: 5000,
    id: getNewCrudStateObjGuid(),
  };
}
