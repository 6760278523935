import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild, Renderer2 } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { EventsService } from '@app/core/services/events.service';
import { select, Store } from '@ngrx/store';
import {
  AgentConnector,
  Connector,
  ConnectorSpec,
  ConnectorsService,
  IpsecConnection,
  IpsecConnectionIpv4Block,
  IpsecConnectionSpec,
  IpsecConnector,
  Organisation,
} from '@agilicus/angular';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { FilterManager } from '../filter/filter-manager';
import { capitalizeFirstLetter } from '../utils';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { getAgentConfigFileName } from '@app/core/models/application/application-model-api-agent-utils';
import { HttpClient } from '@angular/common/http';
import {
  ConnectorDownloadDialogComponent,
  ConnectorDownloadDialogData,
} from '../connector-download-dialog/connector-download-dialog.component';
import { selectCurrentOrg } from '@app/core/user/user.selectors';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OperatingSystemEnum } from '../operatingSystem.enum';
import { getDefaultDialogConfig } from '../dialog-utils';
import { getValidEspCipherOptions, getValidIkeCipherOptions, setEspCipherValues, setIkeCipherValues } from '../connector-utlis';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { agentDownloadURLs } from '../connector-download-instructions/connector-download-instructions.component';
import { construct_issuer } from '@app/core/services/auth-service.service';
import { createAgentConnector, getAgentConnectorToCreate, getConnectorById } from '@app/core/api/connectors/connectors-api-utils';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { getFirstConnectorDemoTourStep } from '../tour.utils';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorList, selectConnectorRefreshDataValue } from '@app/core/connector-state/connector.selectors';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { initBillingAccountFull } from '@app/core/billing-state/billing-account-full.actions';
import {
  selectBillingAccountFullDefaultPaymentMethod,
  selectBillingAccountFullIsStarterPlan,
  selectBillingAccountFullRefreshDataValue,
} from '@app/core/billing-state/billing-account-full.selectors';
import {
  doNotNeedPaymentNag,
  getPaymentDialogEndMessage,
  getStarterPaymentDialogMessageBeginning,
  getStarterPlanMaxConnectors,
  getSubscriptionButtonText,
  isStarterPlanWithoutPayment,
} from '@app/core/billing-state/billing-api-utils';
import { getDemoAgentConnectorName } from '@app/core/api/demo-api-utils';
import { PaymentDialogComponent, PaymentDialogData } from '../payment-dialog/payment-dialog.component';
import { getTrimmedResourceName } from '../resource-utils';

@Component({
  selector: 'portal-connector-stepper',
  templateUrl: './connector-stepper.component.html',
  styleUrls: ['./connector-stepper.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public filterManager: FilterManager = new FilterManager();
  private currentOrg: Organisation;
  public connectors: Array<Connector>;
  private issuer: string;
  public allForms: UntypedFormGroup;
  public connectorTypeForm: UntypedFormGroup;
  public connectorSpecForm: UntypedFormGroup;
  public vpnConnectorForm: UntypedFormGroup;
  public connectorCreated = false;
  public installWithConfigCmd: string;
  private newlyCreatedConnector: Connector;
  public connectorId: string;
  public configFileName: string;
  public hasConnectorPermissions: boolean;
  public ikeCipherOptions = getValidIkeCipherOptions();
  public espCipherOptions = getValidEspCipherOptions();
  public connectorTypeChanged = false;
  public operatingSystemEnum = OperatingSystemEnum;
  public connectorTypeEnum = ConnectorSpec.ConnectorTypeEnum;
  public capitalizeFirstLetter = capitalizeFirstLetter;
  public agentDownloadURLs = agentDownloadURLs;
  public pageDescriptiveText = `A connector is a means of getting, securely, from one domain of connectivity to another.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/agilicus-connector/`;
  public showDeleteDemoButton = false;
  public tourAnchorId = 'connector-stepper-demo';
  private tourSteps: Array<IMdStepOption> = [getFirstConnectorDemoTourStep(this.tourAnchorId)];
  public paymentMethod = undefined;
  public isStarterPlan = undefined;
  private dialogOpen = false;
  private checkedPaymentStatus = false;
  private ignorePaymentCheck = false;
  public blockDemoTour = false;
  private refreshBillingDataStateValue = 0;
  private refreshConnectorDataStateValue = 0;
  private formsInitialized = false;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  @ViewChild('stepper') public stepper: MatStepper;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private connectorsService: ConnectorsService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    public http: HttpClient,
    public renderer: Renderer2,
    private dialog: MatDialog,
    private customValidatorsService: CustomValidatorsService,
    private eventsService: EventsService,
    private tourService: TourService,
    private router: Router,
    public paymentDialog: MatDialog,
    public route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    this.store.dispatch(initBillingAccountFull({ force: true, blankSlate: false }));
    const hasPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrg));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const defaultPaymentMethod$ = this.store.pipe(select(selectBillingAccountFullDefaultPaymentMethod));
    const isStarterPlan$ = this.store.pipe(select(selectBillingAccountFullIsStarterPlan));
    const refreshConnectorDataState$ = this.store.pipe(select(selectConnectorRefreshDataValue));
    const refreshBillingDataState$ = this.store.pipe(select(selectBillingAccountFullRefreshDataValue));
    combineLatest([
      this.route.queryParamMap,
      hasPermissions$,
      currentOrg$,
      connectorListState$,
      defaultPaymentMethod$,
      isStarterPlan$,
      refreshConnectorDataState$,
      refreshBillingDataState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          queryParamMapResp,
          hasPermissionsResp,
          currentOrgResp,
          connectorListStateResp,
          defaultPaymentMethodResp,
          isStarterPlanResp,
          refreshConnectorDataStateResp,
          refreshBillingDataStateResp,
        ]) => {
          this.orgId = hasPermissionsResp?.orgId;
          this.hasConnectorPermissions = hasPermissionsResp?.hasPermission;
          this.currentOrg = currentOrgResp;
          this.connectors = connectorListStateResp;
          this.issuer = construct_issuer();
          this.paymentMethod = defaultPaymentMethodResp;
          this.isStarterPlan = isStarterPlanResp;
          this.refreshBillingDataStateValue = refreshBillingDataStateResp;
          this.refreshConnectorDataStateValue = refreshConnectorDataStateResp;
          this.doPaymentChecks(queryParamMapResp);
          this.initializeFormGroups();
          // set default connector type to agent
          this.connectorTypeForm.get('type').setValue(ConnectorSpec.ConnectorTypeEnum.agent);
          if (this.enableStepperNavigation()) {
            this.connectorSpecForm.get('name').enable();
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private doPaymentChecks(queryParamMapResp: ParamMap): void {
    if (this.ignorePaymentCheck) {
      this.checkedPaymentStatus = true;
      return;
    }
    if (!this.currentOrg) {
      // Do not have required data for checks.
      return;
    }
    if (doNotNeedPaymentNag(this.paymentMethod, this.isStarterPlan, this.currentOrg, this.refreshBillingDataStateValue)) {
      this.checkedPaymentStatus = true;
      return;
    }
    if (!queryParamMapResp || this.isStarterPlan === undefined || this.refreshConnectorDataStateValue === 0) {
      // Do not have required data for check.
      return;
    }
    // Have retrieved the required data.
    const refreshBillingParam = queryParamMapResp.get('refresh_billing');
    if (!!refreshBillingParam && this.refreshBillingDataStateValue === 0) {
      // Need to get new data from the api before doing the check.
      return;
    }
    const hasDemoConnector = this.hasDemoConnector();
    const numberOfConnectors = !!this.connectors ? this.connectors.length : 0;
    if (this.shouldShowPaymentDialog() && !this.dialogOpen) {
      this.openPaymentNotificationDialog(hasDemoConnector, numberOfConnectors);
    }
    this.checkedPaymentStatus = true;
  }

  private hasDemoConnector(): boolean {
    if (!this.connectors || this.connectors.length === 0) {
      return false;
    }
    const demoConnector = this.connectors.find((connector) => connector.spec.name === getDemoAgentConnectorName());
    return !!demoConnector;
  }

  public initializeFormGroups(): void {
    if (this.formsInitialized) {
      return;
    }
    this.formsInitialized = true;
    this.connectorTypeForm = this.formBuilder.group({
      type: [null, [Validators.required]],
    });

    this.connectorSpecForm = this.formBuilder.group({
      name: [{ value: '', disabled: true }, [Validators.required, Validators.maxLength(100)]],
    });
    this.vpnConnectorForm = this.formBuilder.group({
      ike_chain_of_trust_certificates: ['', [Validators.required]],
      ike_remote_identity: ['', [Validators.required, Validators.maxLength(1024)]],
      local_ipv4_block: ['', [Validators.required, this.customValidatorsService.subnetValidator()]],
      remote_ipv4_ranges: ['', [Validators.required, this.customValidatorsService.subnetValidator()]],
      remote_ipv4_address: ['', [Validators.required, this.customValidatorsService.hostnameOrIP4Validator()]],
      ike_cipher: ['', [Validators.required]],
      esp_cipher: ['', [Validators.required]],
    });
    this.allForms = this.formBuilder.group({
      connectorTypeForm: this.connectorTypeForm,
      connectorSpecForm: this.connectorSpecForm,
      vpnConnectorForm: this.vpnConnectorForm,
    });
  }

  public openDownloadDialog(): void {
    this.eventsService.SendEvent(
      'pre_connector_download',
      localStorage.email,
      `openDownloadDialog(s): ${this.connectorId}`,
      window.location.href
    );
    // open dialog to display download options
    const dialogData: ConnectorDownloadDialogData = {
      connector: this.newlyCreatedConnector,
      orgId: this.orgId,
      issuer: this.issuer,
    };
    const dialogRef = this.dialog.open(
      ConnectorDownloadDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.router.navigate(['/connector-overview'], {
          queryParams: { org_id: this.orgId },
        });
      }
    });
  }

  public isConnectorTypeStepValid(): boolean {
    if (!this.connectorTypeForm.get('type') || !this.connectorTypeForm.get('type').value) {
      return false;
    }
    if (this.connectorTypeForm.get('type').value === ConnectorSpec.ConnectorTypeEnum.agent) {
      // If the user selects agent, the step is valid since there is no further validation necessary.
      return true;
    }
    if (this.connectorTypeForm.get('type').value === ConnectorSpec.ConnectorTypeEnum.ipsec) {
      // If the user selects vpn, we need to check that the vpnConnectorForm is valid.
      if (this.vpnConnectorForm.valid) {
        return true;
      }
    }
    return false;
  }

  private getAgentConnectorFromForm(): AgentConnector {
    return getAgentConnectorToCreate(this.getConnectorNameFromForm(), this.currentOrg);
  }

  private createAgentConnectorFromForm(): Observable<AgentConnector> {
    const agentConnector = this.getAgentConnectorFromForm();
    this.configFileName = getAgentConfigFileName(agentConnector.spec.name);
    this.installWithConfigCmd = './agilicus-agent client --cfg-file "./' + this.configFileName + '.json"';
    return createAgentConnector(this.connectorsService, agentConnector);
  }

  private getIpsecConnectionFromForm(): IpsecConnection {
    const ipsecConnectionIpv4Block: IpsecConnectionIpv4Block = {
      ipv4_address_block: this.vpnConnectorForm.get('remote_ipv4_ranges').value,
    };
    const ipsecConnection: IpsecConnection = {
      name: 'base',
      spec: {
        ike_version: IpsecConnectionSpec.IkeVersionEnum.ikev1,
        ike_authentication_type: IpsecConnectionSpec.IkeAuthenticationTypeEnum.certificate,
        ike_chain_of_trust_certificates: this.vpnConnectorForm.get('ike_chain_of_trust_certificates').value,
        ike_remote_identity: this.vpnConnectorForm.get('ike_remote_identity').value,
        local_ipv4_block: this.vpnConnectorForm.get('local_ipv4_block').value,
        remote_ipv4_ranges: [ipsecConnectionIpv4Block],
        remote_ipv4_address: this.vpnConnectorForm.get('remote_ipv4_address').value,
      },
    };
    setIkeCipherValues(ipsecConnection, this.vpnConnectorForm.get('ike_cipher').value);
    setEspCipherValues(ipsecConnection, this.vpnConnectorForm.get('esp_cipher').value);
    return ipsecConnection;
  }

  private getIpsecConnectorFromForm(): IpsecConnector {
    return {
      spec: {
        name: this.getConnectorNameFromForm(),
        org_id: this.orgId,
        connections: [this.getIpsecConnectionFromForm()],
      },
    };
  }

  private createVpnConnector(): Observable<IpsecConnector> {
    return this.connectorsService.createIpsecConnector({ IpsecConnector: this.getIpsecConnectorFromForm() });
  }

  private getCreateConnectorObservable(): Observable<AgentConnector | IpsecConnector | undefined> {
    let createConnector$: Observable<AgentConnector | IpsecConnector | undefined> = of(undefined);
    const connectorType = this.getConnectorType();
    if (connectorType === ConnectorSpec.ConnectorTypeEnum.agent) {
      createConnector$ = this.createAgentConnectorFromForm();
    }
    if (connectorType === ConnectorSpec.ConnectorTypeEnum.ipsec) {
      createConnector$ = this.createVpnConnector();
    }
    return createConnector$;
  }

  private getCreatedConnector$(): Observable<Connector | undefined> {
    const createConnector$ = this.getCreateConnectorObservable();
    return createConnector$.pipe(
      concatMap((resp) => {
        if (!resp) {
          return of(undefined);
        }
        return getConnectorById(this.connectorsService, resp.metadata.id, this.orgId);
      })
    );
  }

  private advanceToDoneStep(): void {
    this.stepper.selectedIndex = 1;
  }

  public createConnector(): void {
    this.allForms.disable();
    this.getCreatedConnector$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (!resp) {
            return;
          }
          this.notificationService.success(`Connector "${resp.spec.name}" was successfully created`);
          this.newlyCreatedConnector = resp;
          this.connectorId = resp.metadata.id;
          this.connectorCreated = true; // enable last step
          this.allForms.enable();
          this.changeDetector.detectChanges();
          this.advanceToDoneStep();
          if (this.shouldShowPaymentDialogOnConnectorCreate()) {
            this.delayAndOpenPaymentReminderDialogOnConnectorCreate();
          }
        },
        (err) => {
          this.allForms.enable();
          if (err.status === 409) {
            this.connectorSpecForm.get('name').setErrors({ notUnique: true });
            this.notificationService.error('A connector with this name already exists. Please choose a different name and try again.');
          } else {
            this.notificationService.error(`Unable to create ${this.getConnectorType()} connector. Please try again.`);
          }
        },
        () => {
          this.changeDetector.detectChanges();
        }
      );
  }

  public getConnectorType(): ConnectorSpec.ConnectorTypeEnum {
    return this.connectorTypeForm?.get('type')?.value;
  }

  public resetConnectorTypeChanged(): void {
    this.connectorTypeChanged = false;
  }

  public onConnectorTypeChange(): void {
    // Workaround for angular stepper bug:
    // See: https://github.com/angular/components/issues/20923
    this.connectorTypeChanged = true;
  }

  public getConnectorNameFromForm(): string {
    return getTrimmedResourceName(this.connectorSpecForm.value.name);
  }

  public startDemoTour() {
    setTimeout(() => {
      this.tourService.initialize(this.tourSteps);
      this.tourService.start();
    }, 500);
  }

  public enableStepperNavigation(): boolean {
    return this.checkedPaymentStatus && !this.shouldShowPaymentDialog();
  }

  public disableStepperNavigation(): boolean {
    return !this.enableStepperNavigation();
  }

  private getPaymentDialogMessage(hasDemoConnector: boolean, numberOfConnectors: number): string {
    let message = getStarterPaymentDialogMessageBeginning('connector');
    if (hasDemoConnector) {
      message += ` We notice you have created a demo connector.`;
      if (numberOfConnectors === getStarterPlanMaxConnectors()) {
        message += ` We suggest deleting the demo so that you may create a new connector.`;
      } else {
        message += ` We suggest deleting the demo. You may still need to delete additional connectors if you wish to create a new connector.`;
      }
      message += ` Alternatively, if `;
    } else {
      message += ` If`;
    }
    message += ` you would like to create additional connectors, please click the "${getSubscriptionButtonText()}" button to update your subscription and add a payment method.`;
    message += getPaymentDialogEndMessage();
    return message;
  }

  private openPaymentNotificationDialog(hasDemoConnector: boolean, numberOfConnectors: number): void {
    this.eventsService.SendEvent(
      'payment_nag_connector',
      localStorage.email,
      `openPaymentNotificationDialog: ${this.orgId}`,
      window.location.href
    );
    this.dialogOpen = true;
    this.blockDemoTour = true;
    const messagePrefix = `No Payment On File`;
    const message = this.getPaymentDialogMessage(hasDemoConnector, numberOfConnectors);
    const dialogData: PaymentDialogData = {
      messagePrefix,
      message,
      showDemoDeleteButton: hasDemoConnector,
      orgId: this.orgId,
    };
    const dialogRef = this.paymentDialog.open(PaymentDialogComponent, {
      data: dialogData,
    });
  }

  private shouldShowPaymentDialog(): boolean {
    if (doNotNeedPaymentNag(this.paymentMethod, this.isStarterPlan, this.currentOrg, this.refreshBillingDataStateValue)) {
      // Do not show dialog if user does not have access to billing data
      return false;
    }
    return isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod) && this.connectors.length >= getStarterPlanMaxConnectors();
  }

  private shouldShowPaymentDialogOnConnectorCreate(): boolean {
    if (doNotNeedPaymentNag(this.paymentMethod, this.isStarterPlan, this.currentOrg, this.refreshBillingDataStateValue)) {
      return false;
    }
    return (
      isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod) && this.connectors.length + 1 >= getStarterPlanMaxConnectors()
    );
  }

  private delayAndOpenPaymentReminderDialogOnConnectorCreate(): void {
    setTimeout(() => {
      this.openPaymentReminderDialogOnConnectorCreate();
    }, 2000);
  }

  private openPaymentReminderDialogOnConnectorCreate(): void {
    this.eventsService.SendEvent(
      'payment_nag_connector',
      localStorage.email,
      `openPaymentReminderDialogOnConnectorCreate: ${this.orgId}`,
      window.location.href
    );
    this.dialogOpen = true;
    const messagePrefix = `Reminder`;
    let message = `${getStarterPaymentDialogMessageBeginning('connector')} 
    If you would like to create additional connectors, please click the "${getSubscriptionButtonText()}" button to update your subscription and add a payment method.`;
    message += getPaymentDialogEndMessage();
    const dialogData: PaymentDialogData = {
      messagePrefix,
      message,
      orgId: this.orgId,
    };
    const dialogRef = this.paymentDialog.open(PaymentDialogComponent, {
      data: dialogData,
    });
  }

  public onDemoCreationStart(): void {
    // Do not want to notify user about payment if they just created the demo
    this.ignorePaymentCheck = true;
  }
}
