import { Observable, of } from 'rxjs';
import {
  MessagesService,
  ListInboxItemsRequestParams,
  InboxItem,
  GetInboxItemRequestParams,
  ReplaceInboxItemRequestParams,
  User,
} from '@agilicus/angular';
import { map, switchMap } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth-service.service';

function getAdminPortalMessageClass(): string {
  return 'agilicus-admin-portal';
}

export function getInboxItemsList(messagesService: MessagesService, userId: string, orgId: string): Observable<Array<InboxItem>> {
  const listInboxItemsRequestParams: ListInboxItemsRequestParams = {
    user_id: userId,
    org_id: orgId,
    message_class: getAdminPortalMessageClass(),
    expired: false,
  };
  return messagesService.listInboxItems(listInboxItemsRequestParams).pipe(
    map((resp) => {
      return resp.inbox_items;
    })
  );
}

export function getInboxItemsListUsingAuthService(
  messagesService: MessagesService,
  orgId: string,
  authService: AuthService
): Observable<Array<InboxItem>> {
  return authService
    .auth()
    .user$()
    .pipe(switchMap((userResp: User) => getInboxItemsList(messagesService, userResp.id, orgId)));
}

export function getUnreadInboxItemsCount(
  messagesService: MessagesService,
  userId: string,
  orgId: string | undefined
): Observable<number | undefined> {
  if (!orgId) {
    return of(undefined);
  }
  const listInboxItemsRequestParams: ListInboxItemsRequestParams = {
    user_id: userId,
    org_id: orgId,
    limit: 0,
    message_class: getAdminPortalMessageClass(),
    expired: false,
  };
  return messagesService.listInboxItems(listInboxItemsRequestParams).pipe(
    map((resp) => {
      return resp.summary.count_unread;
    })
  );
}

export function deleteInboxItem(messagesService: MessagesService, userId: string, inboxItemId: string): Observable<void> {
  return messagesService.deleteInboxItem({
    user_id: userId,
    inbox_item_id: inboxItemId,
  });
}

export function deleteInboxItemUsingAuthService(
  messagesService: MessagesService,
  authService: AuthService,
  inboxItemId: string
): Observable<void> {
  return authService
    .auth()
    .user$()
    .pipe(switchMap((userResp: User) => deleteInboxItem(messagesService, userResp.id, inboxItemId)));
}

export function getInboxItemById(
  messagesService: MessagesService,
  userId: string,
  inbox_item_id: string | undefined
): Observable<InboxItem | undefined> {
  const getInboxItemRequestParams: GetInboxItemRequestParams = {
    user_id: userId,
    inbox_item_id: inbox_item_id,
  };
  return messagesService.getInboxItem(getInboxItemRequestParams);
}

export function getInboxItemByIdUsingAuthService(
  messagesService: MessagesService,
  authService: AuthService,
  inboxItemId: string
): Observable<InboxItem> {
  return authService
    .auth()
    .user$()
    .pipe(switchMap((userResp: User) => getInboxItemById(messagesService, userResp.id, inboxItemId)));
}

export function replaceInboxItem(
  messagesService: MessagesService,
  userId: string,
  inboxItemId: string | undefined,
  inboxItem: InboxItem
): Observable<InboxItem | undefined> {
  const replaceInboxItemRequestParams: ReplaceInboxItemRequestParams = {
    user_id: userId,
    inbox_item_id: inboxItemId,
    InboxItem: inboxItem,
  };
  return messagesService.replaceInboxItem(replaceInboxItemRequestParams);
}

export function replaceInboxItemUsingAuthService(
  messagesService: MessagesService,
  authService: AuthService,
  inboxItemId: string | undefined,
  inboxItem: InboxItem
): Observable<InboxItem | undefined> {
  return authService
    .auth()
    .user$()
    .pipe(switchMap((userResp: User) => replaceInboxItem(messagesService, userResp.id, inboxItemId, inboxItem)));
}
