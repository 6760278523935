import { AgentConnector, ConnectorsService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import * as AgentConnectorActions from '../agent-connector-state/agent-connector.actions';
import {
  createAgentConnector,
  deleteExistingAgentConnector,
  getAgentConnectorById,
  getAgentConnectorsList,
  updateExistingAgentConnector,
} from '../api/connectors/connectors-api-utils';

@Injectable({
  providedIn: 'root',
})
export class AgentConnectorStateService extends SimpleCRUDInterface<AgentConnector, string> {
  public crudRegistryName = CrudStateCollection.agent_connector;

  constructor(
    public store: Store<AppState>,
    private connectorsService: ConnectorsService,
    private crudRegistryService: CrudRegistryService<AgentConnector, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<AgentConnector, string> = {
        create: (id: string, obj: AgentConnector, orgId: string) => createAgentConnector(this.connectorsService, obj),
        update: (id: string, obj: AgentConnector, orgId: string) => updateExistingAgentConnector(this.connectorsService, obj),
        get: (id: string, orgId: string) => getAgentConnectorById(this.connectorsService, id, orgId),
        list: (orgId: string) => getAgentConnectorsList(this.connectorsService, orgId),
        delete: (id: string, orgId: string) => deleteExistingAgentConnector(this.connectorsService, id, orgId),
      };
      const crudRegistry: CrudRegistry<AgentConnector, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: AgentConnector) => obj.spec.name,
        doneSaveAction: AgentConnectorActions.upsertAgentConnector,
        doneSaveListAction: AgentConnectorActions.upsertAgentConnectors,
        doneDeleteAction: AgentConnectorActions.deleteAgentConnector,
        doneDeleteListAction: AgentConnectorActions.deleteAgentConnectors,
        setListAction: AgentConnectorActions.loadAgentConnectors,
        refreshStateAction: AgentConnectorActions.refreshAgentConnectors,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
