import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { User } from '@agilicus/angular';
import { Subject, Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth-service.service';

@Component({
  selector: 'portal-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, OnDestroy {
  public user = new UntypedFormControl('');
  private unsubscribe$: Subject<void> = new Subject<void>();
  public user$: Observable<User>;

  constructor(private authService: AuthService) {}

  public ngOnInit() {
    this.user$ = this.authService.auth().user$().pipe(takeUntil(this.unsubscribe$));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
