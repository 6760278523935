<h2>Add Identity Provider</h2>
<div mat-dialog-content>
  <mat-vertical-stepper linear="true" #stepper>
    <mat-step [stepControl]="providerForm">
      <form [formGroup]="providerForm">
        <ng-template matStepLabel>Choose Provider</ng-template>
        <body>
          What kind of provider would you like to add?
          <mat-radio-group
            formControlName="provider"
            aria-labelledby="provider radio buttons"
            class="column-radio-group"
          >
            <mat-radio-button
              *ngFor="let provider of providerOptions"
              [value]="provider"
            >
              {{ provider }}
            </mat-radio-button>
          </mat-radio-group>
          <div class="nav-buttons">
            <button
              *ngIf="getProviderOptionFromForm() !== upstreamProviderOption.other && getProviderOptionFromForm()"
              mat-button
              matStepperNext
              (click)="onProviderSelection()"
            >
              Next
            </button>
            <button 
              *ngIf="getProviderOptionFromForm() === upstreamProviderOption.other"
              mat-raised-button
              color="primary"
              matTooltip="Click to edit the new provider"
              aria-label="A button to close the dialog and create a new row in the table"
              (click)="onAddClick()"
            >
            Add
            </button>
          </div>
        </body>
      </form>
    </mat-step>
    <div *ngIf="getProviderOptionFromForm() === upstreamProviderOption.azure">
      <mat-step>
        <ng-template matStepLabel>Register Azure Application</ng-template>
        <body>
          To begin, you will need to register a new app in the Azure portal to establish a relying party trust between Agilicus and your Active Directory.<br><br>
          Select the Azure Active Directory service in the Microsoft Azure portal.
          <img
            class="screenshot-small bordered-img"
            src="assets/img/upstream-provider-setup/1-azure-portal-active-directory.png"
          >
          <br>
          Go to App registrations under Manage and click New registration.
          <img
            class="screenshot-small bordered-img"
            src="assets/img/upstream-provider-setup/2-app-registrations.png"
          >
          <br>
          Fill out the form. For Redirect URI, use:
          <div class="copy-text-container">
            <mat-form-field style=" margin-bottom: -0.75em">
              <input
                matInput
                type="text"
                value="{{ data.issuer.upstream_redirect_uri }}"
                readonly
                #redirectURI
              >
              <button
                matSuffix
                mat-raised-button
                color="primary"
                class="copy-button"
                matTooltip="Copy redirect URI"
                [ngxClipboard]="redirectURI"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <img
            class="screenshot-large bordered-img"
            src="assets/img/upstream-provider-setup/3-redirect-uri.png"
            width=100%
          >
          <br>
        </body>
        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
      <mat-step [stepControl]="azureAppForm" errorMessage="Invalid app information">
        <form [formGroup]="azureAppForm" autocomplete="off">
          <ng-template matStepLabel>Azure App Information</ng-template>
          <body>
            After registering your Azure application, you will be redirected to the Overview pane.
            From this Overview pane in the Azure Active Directory, please fill in the boxes below using the fields under Essentials.
            <img
              class="screenshot-small bordered-img"
              src="assets/img/upstream-provider-setup/4-app-info.png"
            >
            <br>
            <mat-form-field>
              <input
                matInput 
                type="text"
                placeholder="Name" 
                formControlName="name"
                (blur)="isDuplicateName()"
                required
              >
            </mat-form-field>
            <mat-form-field>
              <input
                matInput 
                type="text"
                placeholder="Client ID" 
                formControlName="clientId"
                spellcheck="false"
                required
              >
            </mat-form-field>
            <mat-form-field>
              <input
                matInput 
                type="text"
                placeholder="Tenant ID" 
                formControlName="tenantId"
                spellcheck="false"
                required
              >
              <mat-error *ngIf="azureAppForm.get('tenantId').errors?.invalidTenantID">Unable to fetch tenant information. Is your tenant ID correct?</mat-error>
            </mat-form-field>
          </body>
        </form>
        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
      <mat-step [stepControl]="clientSecretForm">
        <form [formGroup]="clientSecretForm" autocomplete="off" spellcheck="false">
          <ng-template matStepLabel>Client Secret</ng-template>
          <body>
            Under the same application in the Azure portal, click on Certificates &amp; secrets on the left navigation.
            <img
              class="screenshot-small bordered-img"
              src="assets/img/upstream-provider-setup/5-certificates-and-secrets.png"
            >
            Create a new client secret and paste the value in the box below:
            <img
              class="screenshot-large bordered-img"
              src="assets/img/upstream-provider-setup/6-client-secret.png"
            >
            <mat-form-field>
              <input
                matInput 
                type="text"
                placeholder="Client Secret" 
                formControlName="clientSecret" 
              >
            </mat-form-field>
          </body>
        </form>
        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
    </div>

    <div *ngIf="getProviderOptionFromForm() !== upstreamProviderOption.azure">
      <mat-step [stepControl]="providerDetailsForm">
        <form
          [formGroup]="providerDetailsForm"
          class="stepper-form"
          spellcheck="false"
          autocomplete="off"
        >
          <ng-template matStepLabel>Define Provider</ng-template>
          <portal-stepper-form-field
            placeholder="Name"
            [formCtrl]="providerDetailsForm.get('name')"
            required="true"
            tooltip="This is the display name users will see when selecting which identity provider to login from"
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            placeholder="Icon"
            [formCtrl]="providerDetailsForm.get('icon')"
            tooltip="File to use for the icon"
            required = "true"
          >
          </portal-stepper-form-field>

          <div
            *ngIf="getProviderOptionFromForm() === upstreamProviderOption.application"
            class="application-selection-container"
          >
            <mat-form-field>
              <mat-label>Select an application:</mat-label>
              <mat-select 
                #selectApp1
                formControlName="application_name"
                (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectApp1)"
              >
                <mat-option value=""></mat-option>
                <mat-option
                  *ngFor="let app of data.applications"
                  value="{{ app.name }}"
                  >{{ app.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-icon
              class="help-icon"
              [matTooltip]="getApplicationSelectionTooltip()"
            >
              help_outline
            </mat-icon>
          </div>

          <portal-stepper-form-field
            *ngIf="getProviderOptionFromForm() === upstreamProviderOption.application"
            placeholder="Login Path"
            [formCtrl]="providerDetailsForm.get('path')"
            required="true"
            tooltip="The path the login endpoint exists at for the application"
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            *ngIf="getProviderOptionFromForm() === upstreamProviderOption.application"
            placeholder="Username Field"
            [formCtrl]="providerDetailsForm.get('username_field')"
            required="true"
            tooltip="The name of the username field in the login form's html. This can be determined by inspecting the login page html using a tool like Chrome's Dev Tools."
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            *ngIf="getProviderOptionFromForm() === upstreamProviderOption.application"
            placeholder="Password Field"
            [formCtrl]="providerDetailsForm.get('password_field')"
            required="true"
            tooltip="The name of the password field in the login form's html. This can be determined by inspecting the login page html using a tool like Chrome's Dev Tools."
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            *ngIf="getProviderOptionFromForm() === upstreamProviderOption.application"
            placeholder="Successful Response Code"
            [formCtrl]="providerDetailsForm.get('successful_response_code')"
            required="true"
            tooltip="The expected http response code the application returns on a successful login"
          >
          </portal-stepper-form-field>

          <div
            *ngIf="getProviderOptionFromForm() === upstreamProviderOption.application"
            class="expected-cookies-container"
          >
            <mat-form-field>
              <mat-chip-list #expectedCookiesChipList>
                <mat-chip
                  *ngFor="let cookie of data.uniqueUpstreamProviderData.validation.expected_cookies"
                  [selectable]="filterChipOptions.selectable"
                  [removable]="filterChipOptions.removable"
                  (removed)="removeChip(cookie, data.uniqueUpstreamProviderData.validation.expected_cookies)"
                >
                  {{ cookie }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  #expectedCookieInput
                  matInput
                  placeholder="Expected Cookies"
                  formControlName="expected_cookies"
                  [matChipInputFor]="expectedCookiesChipList"
                  [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
                  [matChipInputAddOnBlur]="filterChipOptions.addOnBlur"
                  (matChipInputTokenEnd)="addChipOnInputEvent($event, directive)"
                  matTooltip="Separate multiple entries by a semicolon"
                  (keyup.enter)="keyTabManager.keyTabChipList($event.target.value, $event.target.id, undefined, newChipAdded)"
                />
              </mat-chip-list>
            </mat-form-field>
            <mat-icon
              class="help-icon"
              matTooltip="The names of the various cookies the application sets on a successful login"
            >
              help_outline
            </mat-icon>
          </div>

          <portal-connector-selection
            *ngIf="getProviderOptionFromForm() === upstreamProviderOption.connector"
            [connectorFormGroup]="providerDetailsForm"
            [connectors]="data.connectors"
            (updateModel)="updateConnector($event)"
          >
          </portal-connector-selection>

          <mat-form-field>
            <mat-label>Auto-create Status</mat-label>
            <mat-select 
              #select1
              placeholder="Auto-create Status" 
              formControlName="auto_create_status"
            >
              <mat-option [value]="autoCreateStatus.default">
                {{ capitalizeFirstLetter(autoCreateStatus.default) }}
              </mat-option>
              <mat-option [value]="autoCreateStatus.active">
                {{ capitalizeFirstLetter(autoCreateStatus.active) }}
              </mat-option>
              <mat-option [value]="autoCreateStatus.pending">
                {{ capitalizeFirstLetter(autoCreateStatus.pending) }}
              </mat-option>
              <mat-option [value]="autoCreateStatus.disabled">
                {{ capitalizeFirstLetter(autoCreateStatus.disabled) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </form>

        <portal-stepper-form-field
          *ngIf="getProviderOptionFromForm() === upstreamProviderOption.connector"
          placeholder="Upstream Domain Name"
          [formCtrl]="providerDetailsForm.get('upstream_domain_name')"
          [tooltip]="getUpstreamDomainNameTooltipText()"
        >
        </portal-stepper-form-field>

        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button matStepperNext>Next</button>
        </div>
      </mat-step>
    </div>

    <div *ngIf="getProviderOptionFromForm() !== upstreamProviderOption.other">
      <mat-step [completed]="!!advanceToDoneStep">
        <ng-template matStepLabel>Apply</ng-template>
        <body>
          Click "Add" to save your new identity provider. You will be able to edit the provider's properties in the Identity Providers
          tables at any time.
          <div class="nav-buttons">
            <button mat-button matStepperPrevious>Back</button>
            <button
              mat-raised-button
              color="primary"
              matTooltip="Click to save the identity provider"
              aria-label="A button to save the identity provider"
              [disabled]="addDisabled"
              (click)="saveIssuer()"
            >
            Add
            </button>
          </div>
        </body>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <body>
          <p>You can configure aliases for this identity provider by navigating to the Authentication Clients screen and selecting a client to edit or by creating a new client.</p>
          <p>Click the button below to configure identity provider aliases for a client:</p>

          <div class="configure-aliases-button-container">
            <button
              type="button"
              mat-raised-button
              color="primary"
              aria-label="A button to configure identity provider aliases"
              (click)="onConfigureAliasesClick()"
            >
              CONFIGURE IDENTITY PROVIDER ALIASES
            </button>
          </div>
        </body>
      </mat-step>
  </div>
  </mat-vertical-stepper>
  <mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()">CANCEL</button>
  </mat-dialog-actions>
</div>
