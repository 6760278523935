<portal-page-info
  pageTitle="Connect To Sites"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
  [helpImageFilePath]="helpImageFilePath"
>
</portal-page-info>

<div class="main-content-container">
  <mat-slide-toggle
    class="mark-complete-slider"
    [checked]="isTaskComplete"
    (change)="onCompleteToggle($event.checked)"
  >
    {{ getTaskCompleteToggleText(targetTask, gettingStartedDataCopy) }}
  </mat-slide-toggle>

  <form
    [formGroup]="connectToSitesFormGroup"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="parent-radio-group">
      <mat-radio-group
        formControlName="exposeResources"
        aria-labelledby="exposes resources option radio buttons"
        class="radio-group"
      >
        <mat-radio-button
          class="radio-button full-line-radio-button"
          value="yes"
        >
          I have resources that need to be exposed
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          value="no"
        >
          I do not have resources that need to be exposed
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </form>

  <div class="recommendations-container">
    <div *ngIf="needToCreateAgentConnector()" class="create-agent-connector">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you create a new Agilicus Connector."
        imageSourcePath="assets/img/connect-to-sites-uses-agent-connector.png"
        (imageClick)="onImageClick()"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="doNotNeedToCreateAgentConnector()" class="do-not-create-agent-connector">
      <p>
        No action required. Please proceed to the <span class="link-to-route" (click)="routeToNextTask()">next</span> "Getting Started" task.
      </p>
    </div>
  </div>
</div>
