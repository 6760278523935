import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core';
import { cloneDeep, isEqual } from 'lodash-es';
import { ColumnTypes } from '../column-types.enum';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { IconColor } from '../icon-color.enum';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { getColumnDefs } from '../table-layout-utils';
import { CheckBoxColumn, Column, SelectColumn } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { capitalizeFirstLetter } from '../utils';

@Component({
  selector: 'portal-form-layout',
  templateUrl: './form-layout.component.html',
  styleUrls: ['./form-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLayoutComponent<T extends TableElement> implements OnInit, OnChanges {
  @Input() public columnDefs: Map<string, Column<T>> = new Map();
  // For setting enter key to change input focus.
  @Input() public keyTabManager: KeyTabManager = new KeyTabManager();
  @Input() public element: T;
  @Input() public hidden = false;
  @Output() public updateSelection = new EventEmitter<any>();
  @Output() public updateMultipleSelection = new EventEmitter<any>();
  private localElementCopy: T;
  public formGroup: UntypedFormGroup;
  public tableLayout = new TableLayoutComponent(this.notificationService, this.changeDetector);

  public getColumnDefs = getColumnDefs;
  public capitalizeFirstLetter = capitalizeFirstLetter;

  // This is required in order to reference the enums in the html template.
  public columnTypes = ColumnTypes;
  public inputSize = InputSize;
  public iconColor = IconColor;

  constructor(private notificationService: NotificationService, private changeDetector: ChangeDetectorRef) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  public ngOnInit(): void {}

  public ngOnChanges(): void {
    this.tableLayout.columnDefs = this.columnDefs;
    if (!this.localElementCopy && !!this.element) {
      this.localElementCopy = cloneDeep(this.element);
    }
    this.setFormGroup();
    this.changeDetector.detectChanges();
  }

  private setFormGroup(): void {
    if (!this.columnDefs) {
      return;
    }
    if (!!this.localElementCopy && !isEqual(this.element, this.localElementCopy)) {
      // We have changed the element since the last update, so don't reset the form
      return;
    }
    if (!!this.formGroup) {
      this.setFormValues();
      return;
    }
    this.initializeFormGroup();
  }

  private initializeFormGroup(): void {
    const group: any = {};
    const columnsArray = Array.from(this.columnDefs.values());
    columnsArray.forEach((column) => {
      const formControl = column.requiredField(this.element)
        ? new FormControl(this.getFormControlValueFromColumn(column) || '', Validators.required)
        : new FormControl(this.getFormControlValueFromColumn(column) || '');
      if (!column.isEditable || !!column.disableField(this.element)) {
        formControl.disable();
      }
      group[column.name] = formControl;
    });
    this.formGroup = new FormGroup(group);
  }

  private setFormValues(): void {
    const columnsArray = Array.from(this.columnDefs.values());
    columnsArray.forEach((column) => {
      const formControl = this.formGroup.get(column.name);
      formControl.setValue(this.getFormControlValueFromColumn(column));
      if (!column.isEditable || !!column.disableField(this.element)) {
        formControl.disable();
      } else {
        formControl.enable();
      }
    });
  }

  private getFormControlValueFromColumn(column: Column<T>): any {
    switch (column.type) {
      case ColumnTypes.INPUT:
      case ColumnTypes.READONLY:
        return column.getDisplayValue(this.element);
      case ColumnTypes.CHECK:
        const checkColumn = column as CheckBoxColumn<T>;
        return checkColumn.isChecked(this.element);
      case ColumnTypes.SELECT:
        return this.tableLayout.getSelectionDisplayValue(column, this.element);
      default:
        return this.element[column.name];
    }
  }

  public onCheckboxUpdate(column: CheckBoxColumn<T>, isBoxChecked: boolean): void {
    this.tableLayout.onCheckboxUpdate(column, this.element, isBoxChecked);
    this.localElementCopy = cloneDeep(this.element);
    // We need to add the focus back to the checkbox element since it will be
    // automatically placed on the html body after clicking.
    this.setFormValues();
  }

  public onSelection(params: { value: string | Array<string>; column: SelectColumn<T>; element: T }): void {
    params.element.dirty = true;
    if (params.column.multiple) {
      this.updateMultipleSelectionEventFunc(params);
      return;
    }
    this.updateSelectionEventFunc(params);
  }

  public updateSelectionEventFunc(params: { value: string | Array<string>; column: SelectColumn<T>; element: T }): void {
    this.updateSelection.emit(params);
  }

  public updateMultipleSelectionEventFunc(params: { value: string | Array<string>; column: SelectColumn<T>; element: T }): void {
    this.updateMultipleSelection.emit(params);
  }

  public triggerChangeDetectionFromParentComponent(): void {
    this.changeDetector.detectChanges();
  }

  public triggerFormResetFromParentComponent(): void {
    this.setFormValues();
    this.initializeFormGroup();
    this.changeDetector.detectChanges();
  }
}
