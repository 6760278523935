import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FilesService, Launcher } from '@agilicus/angular';
import { AppState, NotificationService } from '..';
import { selectCanAdminApps } from '../user/permissions/app.selectors';
import * as LauncherActions from './launcher.actions';
import { selectLaunchers, selectLauncherEntity, selectLauncherList, selectLauncherShouldPopulateValue } from './launcher.selectors';
import { LauncherState } from './launcher.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSavingResourceIconFileEffect,
  createSetCrudStateEffect,
  createUpdateResourceWithIconFileEffect,
} from '../helpers/effect-factories';
import { LauncherStateService } from '../state-services/launcher-state.service';

@Injectable()
export class LauncherEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private launcherStateService: LauncherStateService,
    private filesService: FilesService,
    private notificationService: NotificationService
  ) {}

  public initState$ = createInitStateEffect<LauncherState>(
    this.store,
    this.actions$,
    LauncherActions.initLaunchers,
    LauncherActions.getLaunchers,
    LauncherActions.maintainLaunchers,
    selectLaunchers
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    LauncherActions.getLaunchers,
    LauncherActions.maintainLaunchers,
    selectLauncherShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<Launcher, string>(
    this.store,
    this.actions$,
    LauncherActions.getLaunchers,
    LauncherActions.clearLaunchers,
    LauncherActions.maintainLaunchers,
    this.launcherStateService,
    selectCanAdminApps
  );

  public setCrudState$ = createSetCrudStateEffect<Launcher, string>(
    this.store,
    this.actions$,
    LauncherActions.loadLaunchers,
    LauncherActions.maintainLaunchers,
    this.launcherStateService,
    selectLauncherList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<Launcher, string>(
    this.store,
    this.actions$,
    LauncherActions.savingLauncher,
    LauncherActions.maintainLaunchers,
    this.launcherStateService,
    selectLauncherEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<Launcher, string>(
    this.store,
    this.actions$,
    LauncherActions.savingLaunchers,
    LauncherActions.maintainLaunchers,
    this.launcherStateService,
    selectLaunchers
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<Launcher, string>(
    this.actions$,
    LauncherActions.deletingLauncher,
    LauncherActions.refreshLaunchers,
    LauncherActions.maintainLaunchers,
    this.launcherStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<Launcher, string>(
    this.actions$,
    LauncherActions.deletingLaunchers,
    LauncherActions.refreshLaunchers,
    LauncherActions.maintainLaunchers,
    this.launcherStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<LauncherState>(
    this.store,
    this.actions$,
    LauncherActions.getLaunchers,
    LauncherActions.maintainLaunchers,
    selectLaunchers
  );

  // Custom Effects:

  public savingIconFile$ = createSavingResourceIconFileEffect<Launcher>(
    this.store,
    this.actions$,
    LauncherActions.savingIconLauncher,
    LauncherActions.successfulIconSaveLauncher,
    LauncherActions.failedIconSaveLauncher,
    this.filesService,
    this.notificationService
  );

  public updateResourceWithIconFile$ = createUpdateResourceWithIconFileEffect<Launcher>(
    this.actions$,
    LauncherActions.successfulIconSaveLauncher,
    LauncherActions.savingLauncher
  );
}
