import { LauncherConfig, ResourceMember } from '@agilicus/angular';
import { ResourceModel } from '../resource/resource-model';

export interface LauncherModel extends ResourceModel {
  name: string;
  config?: LauncherConfig;
  resource_members?: Array<ResourceMember>;
  applications?: Array<string>;
}

export function defaultLauncherModel(): LauncherModel {
  return {
    id: undefined,
    name: '',
    config: {
      command_path: '',
      command_arguments: '',
      start_in: '',
      do_intercept: false,
      hide_console: false,
      run_as_admin: false,
      end_existing_if_running: false,
      interceptor_config: {
        fork_then_attach: false,
      },
    },
    resource_members: [],
    applications: [],
  };
}
