import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';

import { environment as env } from '@env/environment';

@Injectable()
export class TitleService {
  constructor(private title: Title) {}

  public setTitle(snapshot: ActivatedRouteSnapshot): void {
    let lastChild = snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    const { title } = lastChild.data;
    if (title) {
      this.title.setTitle(title);
    } else {
      this.title.setTitle(env.appName);
    }
  }
}
