import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private eventURL = 'https://api.agilicus.com/v1/events/mtc';

  constructor(private http: HttpClient) {}

  public SendEvent(event: string, email: string, referrer: string, page_url: string) {
    // No retries or error handling, its best-effort
    const body = {
      email: email,
      referrer: referrer,
      page_url: page_url,
      page_title: event,
    };
    const headers = { 'Content-type': 'application/json' };
    this.http.post<any>(this.eventURL, body, { headers }).subscribe((data) => {});
  }
}
