<form
  *ngIf="!!authFlowFormGroup"
  [formGroup]="authFlowFormGroup"
  class="stepper-form"
  spellcheck="false"
  autocomplete="off"
>
  <mat-vertical-stepper 
    linear
    #authFlowStepper
    portalMatVerticalStepperScroller
    class="sub-stepper"
  >
    <div *ngIf="getAuthenticationOptionValue(currentApplicationModel.authentication) === authFlowOption.own">
      <mat-step [stepControl]="authFlowFormGroup.get('selectedAuthMethod')">
        <ng-template matStepLabel>Authentication method</ng-template>
        <label
          id="auth-method-radio-group-label"
          class="radio-button-group-label"
        >
          My application authenticates using:
        </label>
        <mat-radio-group
          aria-labelledby="authentication method selection radio buttons"
          class="auth-method-radio-group"
          formControlName="selectedAuthMethod"
        >
          <mat-radio-button
            class="auth-method-radio-button"
            *ngFor="let authMethod of authMethodOptionData"
            [value]="authMethod.value"
            (change)="onAuthMethodChange(authMethod.value)"
          >
            {{ authMethod.displayValue }}
          </mat-radio-button>
        </mat-radio-group>
      </mat-step>

      <mat-step
        *ngIf="getAuthenticationMethodOptionValue(currentApplicationModel.authentication) === authMethodOption.saml"
        [stepControl]="authFlowFormGroup.get('saml_metadata_file')"
      >
        <ng-template matStepLabel>Enter saml sp metadata</ng-template>
        <mat-form-field style="width: 95%;">
          <mat-label>SAML SP Metadata</mat-label>
          <textarea 
            matInput
            style="height: 100px;"
            [value]="currentApplicationModel.authentication?.saml?.saml_metadata_file"
            placeholder="SAML SP Metadata"
            formControlName="saml_metadata_file"
            (blur)="onFormBlur(authFlowFormGroup, 'saml_metadata_file', currentApplicationModel.authentication.saml)"
            (keyup.control.enter)="keyTabManager.keyTab($event.target.id)"
          >
          </textarea>
          <button
            *ngIf="!!currentApplicationModel.authentication && !!currentApplicationModel.authentication.saml && !!currentApplicationModel.authentication.saml.saml_metadata_file"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearMetadata()"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="more-actions" style="display:inline-block; width: 5%">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button 
              mat-menu-item
              class="menu-button"
              (click)="metadataUpload.click()"
            >
              Upload File
            </button>
            <input
              hidden
              type="file"
              #metadataUpload
              name="upload-file"
              id="upload-file"
              (change)="uploadMetadata($event)"
              matTooltip="Upload SAML SP metadata file"
              title=" "
              aria-label="Input that uploads a file"
              portalDisableKeyTab
            >
          </mat-menu>
        </div>
      </mat-step>

      <mat-step
        *ngIf="getAuthenticationMethodOptionValue(currentApplicationModel.authentication) === authMethodOption.oidc"
        [stepControl]="authFlowFormGroup.get('redirect_uri')"
      >
        <ng-template matStepLabel>Enter redirect uri</ng-template>
        <portal-stepper-form-field
          placeholder="Enter the redirect uri"
          [formCtrl]="authFlowFormGroup.get('redirect_uri')"
          (formBlur)="onFormBlur(authFlowFormGroup, 'redirect_uri', currentApplicationModel.authentication.oidc)"
        >
        </portal-stepper-form-field>
      </mat-step>
    </div>

    <div *ngIf="canConfigureAuthUsers(currentApplicationModel)" class="auth-users-section">
      <mat-step [stepControl]="authFlowFormGroup.get('selectedUserAuth')">
        <ng-template matStepLabel>Authenticate users</ng-template>
        <label
          id="user-auth-radio-group-label"
          class="radio-button-group-label"
        >
          My application:
        </label>
        <mat-radio-group
          formControlName="selectedUserAuth"
          aria-labelledby="user authentication selection radio buttons"
          class="user-auth-radio-group"
        >
          <mat-radio-button
            class="user-auth-radio-button full-line-radio-button"
            *ngFor="let userAuth of userAuthOptionData"
            [value]="userAuth.value"
            (change)="onUserAuthChange(userAuth.value)"
          >
            {{userAuth.displayValue}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-step>
      
      <div *ngIf="canConfigureRolesAndRules(currentApplicationModel)">
        <mat-step>
          <ng-template matStepLabel>Enter roles</ng-template>
          <portal-application-roles
            [appModelUserDefinedRoles]="currentApplicationModel?.authorization?.user_defined_roles"
          >
          </portal-application-roles>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel>Enter rules</ng-template>
          <p class="descriptive-text">After configuring this application, you can configure the rules using the "Define" sub-menu under the "Applications" tab on the left.</p>
        </mat-step>
      </div>
    </div>
  </mat-vertical-stepper>
</form>