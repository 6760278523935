<div class="main-container">
  <div class="title-container">
    <h1>Agilicus AnyX Frequently Asked Questions</h1>
  </div>

  <div class="content-container">
    <div class="search-faq-container">
      <form 
        class="faq-search-form" 
        [formGroup]="faqSearchForm" 
        spellcheck="false"
        autocomplete="off"
      >
        <mat-form-field 
          class="faq-search-input" 
          appearance="outline"
          matTooltip="Search the FAQ list"
        >
          <input
            matInput
            type="text"
            placeholder="Search FAQ"
            formControlName="faq_search"
            (keyup)="getFaqDataOnAction()"
          />
          <mat-icon
            matPrefix
            class="search-faq-icon-container"
          >
            search
          </mat-icon>
        </mat-form-field>
      </form>
    </div>

    <portal-loading-data *ngIf="isLoading"></portal-loading-data>

    <div class="search-results-container">
      <div *ngFor="let category of getCategoryNamesListAsArray()">
        <span *ngIf="!!getFaqItemsByCategory(category) && getFaqItemsByCategory(category).length !== 0">
          <h2>{{ capitalizeFirstLetter(getFormattedCategoryName(category)) }}</h2>
          <ul class="objectives-list">
            <li *ngFor="let item of getFaqItemsByCategory(category)">
              <span
                class="faq-item-title-container clickable"
                (click)="onFaqItemToggle(item)"
              >
                <mat-icon
                  *ngIf="!isFaqItemOpen(item)"
                  class="faq-item-title-icon"
                >
                  add
                </mat-icon>
                <mat-icon
                  *ngIf="isFaqItemOpen(item)"
                  class="faq-item-title-icon"
                >
                  remove
                </mat-icon>
                <h3 class="faq-item-title">{{ getFormattedFaqTitle(item) }}</h3>
              </span>
              <a
                *ngIf="isFaqItemOpen(item)"
                href="{{ item.link }}"
                target="_blank"
                class="faq-item-link"
              >
                <p class="faq-item-excerpt">{{ getFormattedFaqExcerpt(item) }} <mat-icon class="link-open-icon">open_in_new</mat-icon></p>
              </a>
            </li>
          </ul>
        </span>
      </div>
    </div>
  </div>
</div>