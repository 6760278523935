import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { DesktopResource, FileSummary } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadDesktopResources = createAction(
  '[DesktopResource/API] Load DesktopResources',
  props<{ objs: DesktopResource[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addDesktopResource = createAction('[DesktopResource/API] Add DesktopResource', props<{ obj: DesktopResource }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertDesktopResource = createAction(
  '[DesktopResource/API] Upsert DesktopResource',
  props<{ obj: DesktopResource; refreshData: boolean }>()
);

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addDesktopResources = createAction('[DesktopResource/API] Add DesktopResources', props<{ objs: DesktopResource[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertDesktopResources = createAction(
  '[DesktopResource/API] Upsert DesktopResources',
  props<{ objs: DesktopResource[]; refreshData: boolean }>()
);

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateDesktopResource = createAction(
  '[DesktopResource/API] Update DesktopResource',
  props<{ obj: Update<DesktopResource> }>()
);

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateDesktopResources = createAction(
  '[DesktopResource/API] Update DesktopResources',
  props<{ objs: Update<DesktopResource>[] }>()
);

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteDesktopResource = createAction(
  '[DesktopResource/API] Delete DesktopResource',
  props<{ id: string; obj: DesktopResource; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteDesktopResources = createAction(
  '[DesktopResource/API] Delete DesktopResources',
  props<{ ids: string[]; refreshData?: boolean }>()
);

/**
 * Will delete all data from the ngrx entity state
 */
export const clearDesktopResources = createAction('[DesktopResource/API] Clear DesktopResources');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initDesktopResources = createAction(
  '[DesktopResource/API] Init DesktopResources',
  props<{ force: boolean; blankSlate: boolean }>()
);

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getDesktopResources = createAction(
  '[DesktopResource/API] Get DesktopResources',
  props<{ org_id: string; blankSlate: boolean }>()
);

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingDesktopResource = createAction(
  '[DesktopResource/API] Saving DesktopResource',
  props<{ obj: DesktopResource; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingDesktopResources = createAction(
  '[DesktopResource/API] Saving DesktopResources',
  props<{ objs: Array<DesktopResource>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingDesktopResource = createAction(
  '[DesktopResource/API] Deleting DesktopResource',
  props<{ obj: DesktopResource; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingDesktopResources = createAction(
  '[DesktopResource/API] Deleting DesktopResources',
  props<{ objs: Array<DesktopResource>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshDesktopResources = createAction('[DesktopResource/API] Refresh DesktopResources');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainDesktopResources = createAction('[DesktopResource/API] Maintain DesktopResources');

// Custom resource actions:

/**
 * Will add an icon file to the relevant data type
 */
export const savingIconDesktopResources = createAction(
  '[DesktopResource/API] Saving Icon DesktopResources',
  props<{ file: File; obj: DesktopResource }>()
);

export const successfulIconSaveDesktopResources = createAction(
  '[DesktopResource/API] Successful Icon Save DesktopResources',
  props<{ fileSummary: FileSummary; obj: DesktopResource }>()
);

export const failedIconSaveDesktopResources = createAction(
  '[DesktopResource/API] Failed Icon Save DesktopResources',
  props<{ obj: DesktopResource }>()
);

export const resetIconStatusDesktopResources = createAction('[DesktopResource/API] Reset Icon Status DesktopResources');
