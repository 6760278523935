import { NavMenuItem } from '@app/shared/components/submenu/submenu.component';
import { Action } from '@ngrx/store';
import { AlertsState, ExpansionPanelsState, TabsState } from '../models/ui/ui-model';

export enum UIActionTypes {
  UPDATE_EXPANSION_PANELS_STATE = '[UI] UpdateExpansionPanelsState',
  UPDATE_TABS_STATE = '[UI] UpdateTabsState',
  INIT_APP_DEFINE_UI_STATE = '[UI] InitAppDefineUIState',
  INIT_CONFIGURE_INSTANCE_UI_STATE = '[UI] InitConfigureInstanceUIState',
  INIT_CONNECTOR_INSTALL_UI_STATE = '[UI] InitConnectorInstallUIState',
  LOAD_SIDE_NAV_MENU_UI_STATE = '[UI] LoadSideNavMenuUIState',
  SET_SIDE_NAV_MENU_UI_STATE = '[UI] SetSideNavMenuUIState',
  UPDATE_SIDE_NAV_MENU_UI_STATE = '[UI] UpdateSideNavMenuUIState',
  UPDATE_SIDE_NAV_MENU_ITEM = '[UI] UpdateSideNavMenuItem',
  UPDATE_SIDE_NAV_MENU_ITEM_LIST = '[UI] UpdateSideNavMenuItemList',
  RESET_SIDE_NAV_MENU_UI_STATE = '[UI] ResetSideNavMenuUIState',
  REFRESH_UI_STATE = '[UI] RefreshUIState',
  MAINTAIN_UI_STATE = '[UI] MaintainUIState',
  OPEN_SIDE_NAV_MENU_TARGET_ROUTE = '[UI] OpenSideNavMenuTargetRoute',
  SET_VERSION = '[UI] SetVersion',
  UPDATE_ALERTS_STATE = '[UI] UpdateAlertsState',
  UPDATE_ALERTS_BALANCE_END_DATE_STATE = '[UI] UpdateAlertsBalanceEndDateState',
  UPDATE_ALERTS_CURRENT_USAGE_STATE = '[UI] UpdateAlertsCurrentUsageState',
}

export class ActionUIUpdateExpansionPanelsState implements Action {
  public readonly type = UIActionTypes.UPDATE_EXPANSION_PANELS_STATE;
  constructor(readonly expansionPanelsState: ExpansionPanelsState) {}
}

export class ActionUIUpdateTabsState implements Action {
  public readonly type = UIActionTypes.UPDATE_TABS_STATE;
  constructor(readonly tabsState: TabsState, readonly refreshData: boolean = false) {}
}

export class ActionUIInitAppDefineUIState implements Action {
  public readonly type = UIActionTypes.INIT_APP_DEFINE_UI_STATE;
  constructor() {}
}

export class ActionUIInitConfigureInstanceUIState implements Action {
  public readonly type = UIActionTypes.INIT_CONFIGURE_INSTANCE_UI_STATE;
  constructor() {}
}

export class ActionUIInitConnectorInstallUIState implements Action {
  public readonly type = UIActionTypes.INIT_CONNECTOR_INSTALL_UI_STATE;
  constructor() {}
}

export class ActionUILoadSideNavMenuUIState implements Action {
  public readonly type = UIActionTypes.LOAD_SIDE_NAV_MENU_UI_STATE;
  constructor() {}
}

export class ActionUISetSideNavMenuUIState implements Action {
  public readonly type = UIActionTypes.SET_SIDE_NAV_MENU_UI_STATE;
  constructor() {}
}

export class ActionUIResetSideNavMenuUIState implements Action {
  public readonly type = UIActionTypes.RESET_SIDE_NAV_MENU_UI_STATE;
  constructor() {}
}

export class ActionUIUpdateSideNavMenuUIState implements Action {
  public readonly type = UIActionTypes.UPDATE_SIDE_NAV_MENU_UI_STATE;
  constructor() {}
}

export class ActionUIUpdateSideNavMenuItemList implements Action {
  public readonly type = UIActionTypes.UPDATE_SIDE_NAV_MENU_ITEM_LIST;
  constructor(readonly navMenuItemList: Array<NavMenuItem>) {}
}

export class ActionUIUpdateSideNavMenuItem implements Action {
  public readonly type = UIActionTypes.UPDATE_SIDE_NAV_MENU_ITEM;
  constructor(readonly navMenuItem: NavMenuItem) {}
}

export class ActionUIOpenSideNavMenuTargetRoute implements Action {
  public readonly type = UIActionTypes.OPEN_SIDE_NAV_MENU_TARGET_ROUTE;
  constructor(readonly targetRoute: string) {}
}

export class ActionUISetVersion implements Action {
  public readonly type = UIActionTypes.SET_VERSION;
  constructor(readonly version: string) {}
}

export class ActionUIRefreshState implements Action {
  public readonly type = UIActionTypes.REFRESH_UI_STATE;
  constructor() {}
}

export class ActionUIUpdateAlertsState implements Action {
  public readonly type = UIActionTypes.UPDATE_ALERTS_STATE;
  constructor(readonly alertsState: AlertsState) {}
}

export class ActionUIUpdateAlertsBalanceEndDateState implements Action {
  public readonly type = UIActionTypes.UPDATE_ALERTS_BALANCE_END_DATE_STATE;
  constructor(readonly alerted: boolean) {}
}

export class ActionUIUpdateAlertsCurrentUsageState implements Action {
  public readonly type = UIActionTypes.UPDATE_ALERTS_CURRENT_USAGE_STATE;
  constructor(readonly alerted: boolean) {}
}

/**
 * The state is not changed
 */
export class ActionUIMaintainState implements Action {
  public readonly type = UIActionTypes.MAINTAIN_UI_STATE;
  constructor() {}
}
export type UIActions =
  | ActionUIUpdateExpansionPanelsState
  | ActionUIUpdateTabsState
  | ActionUIInitAppDefineUIState
  | ActionUIInitConfigureInstanceUIState
  | ActionUIInitConnectorInstallUIState
  | ActionUILoadSideNavMenuUIState
  | ActionUISetSideNavMenuUIState
  | ActionUIUpdateSideNavMenuUIState
  | ActionUIUpdateSideNavMenuItem
  | ActionUIUpdateSideNavMenuItemList
  | ActionUIResetSideNavMenuUIState
  | ActionUIRefreshState
  | ActionUIMaintainState
  | ActionUIOpenSideNavMenuTargetRoute
  | ActionUISetVersion
  | ActionUIUpdateAlertsState
  | ActionUIUpdateAlertsBalanceEndDateState
  | ActionUIUpdateAlertsCurrentUsageState;
