import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { FileSummary, Launcher } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadLaunchers = createAction(
  '[Launcher/API] Load Launchers',
  props<{ objs: Launcher[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addLauncher = createAction('[Launcher/API] Add Launcher', props<{ obj: Launcher }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertLauncher = createAction('[Launcher/API] Upsert Launcher', props<{ obj: Launcher; refreshData: boolean }>());

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addLaunchers = createAction('[Launcher/API] Add Launchers', props<{ objs: Launcher[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertLaunchers = createAction('[Launcher/API] Upsert Launchers', props<{ objs: Launcher[]; refreshData: boolean }>());

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateLauncher = createAction('[Launcher/API] Update Launcher', props<{ obj: Update<Launcher> }>());

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateLaunchers = createAction('[Launcher/API] Update Launchers', props<{ objs: Update<Launcher>[] }>());

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteLauncher = createAction('[Launcher/API] Delete Launcher', props<{ id: string; obj: Launcher; refreshData?: boolean }>());

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteLaunchers = createAction('[Launcher/API] Delete Launchers', props<{ ids: string[]; refreshData?: boolean }>());

/**
 * Will delete all data from the ngrx entity state
 */
export const clearLaunchers = createAction('[Launcher/API] Clear Launchers');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initLaunchers = createAction('[Launcher/API] Init Launchers', props<{ force: boolean; blankSlate: boolean }>());

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getLaunchers = createAction('[Launcher/API] Get Launchers', props<{ org_id: string; blankSlate: boolean }>());

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingLauncher = createAction(
  '[Launcher/API] Saving Launcher',
  props<{ obj: Launcher; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingLaunchers = createAction(
  '[Launcher/API] Saving Launchers',
  props<{ objs: Array<Launcher>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingLauncher = createAction(
  '[Launcher/API] Deleting Launcher',
  props<{ obj: Launcher; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingLaunchers = createAction(
  '[Launcher/API] Deleting Launchers',
  props<{ objs: Array<Launcher>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshLaunchers = createAction('[Launcher/API] Refresh Launchers');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainLaunchers = createAction('[Launcher/API] Maintain Launchers');

// Custom resource actions:

/**
 * Will add an icon file to the relevant data type
 */
export const savingIconLauncher = createAction('[Launcher/API] Saving Icon Launcher', props<{ file: File; obj: Launcher }>());

export const successfulIconSaveLauncher = createAction(
  '[Launcher/API] Successful Icon Save Launcher',
  props<{ fileSummary: FileSummary; obj: Launcher }>()
);

export const failedIconSaveLauncher = createAction('[Launcher/API] Failed Icon Save Launcher', props<{ obj: Launcher }>());

export const resetIconStatusLauncher = createAction('[Launcher/API] Reset Icon Status Launcher');
