import {
  AdminStatus,
  BaseUpstream,
  GetUpstreamsRequestParams,
  Issuer,
  IssuersService,
  PatchErrorImpl,
  patch_via_put,
  UpstreamGroupMapping,
} from '@agilicus/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Column } from '@app/shared/components/table-layout/column-definitions';
import { TableElement } from '@app/shared/components/table-layout/table-element';
import { IconColor } from '@app/shared/components/icon-color.enum';
import { capitalizeFirstLetter } from '@app/shared/components/utils';
import { cloneDeep } from 'lodash-es';
import { catchError, concatMap, interval, map, mergeMap, Observable, of, startWith, throwError } from 'rxjs';
import { defaultSanitiseObjectConflicts } from '../api/state-driven-crud/state-driven-crud';
import { getIgnoreErrorsHeader } from '../http-interceptors/http-interceptor-utils';

export function getIssuerById(issuersService: IssuersService, issuerId: string, orgId: string): Observable<Issuer | undefined> {
  if (!issuerId) {
    return of(undefined);
  }
  return issuersService.getIssuer({
    issuer_id: issuerId,
    org_id: orgId,
  });
}

export function getListOfIssuers(issuersService: IssuersService, orgId: string): Observable<Array<Issuer>> {
  return issuersService
    .listIssuers({
      org_id: orgId,
    })
    .pipe(
      map((issuerListResp) => {
        return issuerListResp.issuer_extensions;
      })
    );
}

export function createNewIssuer(issuersService: IssuersService, newIssuer: Issuer): Observable<Issuer | undefined> {
  return issuersService.createIssuer({
    Issuer: newIssuer,
  });
}

export function updateExistingIssuer(issuersService: IssuersService, currentIssuer: Issuer): Observable<Issuer> {
  const getter = (issuer: Issuer) => {
    return issuersService.getIssuer({
      issuer_id: issuer.id,
      org_id: issuer.org_id,
    });
  };
  const putter = (issuer: Issuer) => {
    return issuersService.replaceIssuer({
      issuer_id: issuer.id,
      Issuer: issuer,
    });
  };
  return patch_via_put(currentIssuer, getter, putter).pipe(
    catchError((err: HttpErrorResponse | Error | PatchErrorImpl) => {
      const customError = getUpdateIssuerError(err, '');
      return throwError(() => customError);
    })
  );
}

export function deleteIssuer(issuersService: IssuersService, issuerId: string, orgId: string): Observable<void> {
  return issuersService.deleteRoot({ issuer_id: issuerId, org_id: orgId });
}

export function getUpdateIssuerError(
  error: Error | HttpErrorResponse | PatchErrorImpl,
  baseMessage: string
): Error | HttpErrorResponse | PatchErrorImpl {
  let customError: HttpErrorResponse = undefined;
  if (error instanceof HttpErrorResponse && error.status === 400) {
    if (error.error?.error_code === 'UPSTREAM_IDP_STILL_HAS_USERS') {
      baseMessage +=
        ' You tried to delete an identity provider which still has users associated with it. Consider removing those users prior to deleting the identity provider.';
    }
    if (error.error?.error_code === 'UPSTREAM_IDP_FAILED_USERS_FETCH') {
      baseMessage += ` ${capitalizeFirstLetter(error.error.error_message)}.`;
    }
    customError = new HttpErrorResponse({ ...error, error: { error_code: error.error.error_code, error_message: baseMessage } });
  }
  if (!!customError) {
    return customError;
  }
  return error;
}

export function updateIssuerThemeId(newThemeId: string, currentIssuer: Issuer): Issuer {
  const currentIssuerCopy: Issuer = cloneDeep(currentIssuer);
  currentIssuerCopy.theme_file_id = newThemeId;
  return currentIssuerCopy;
}

export function updateExistingUpstreamGroupMapping(
  issuersService: IssuersService,
  updatedUpstreamGroupMapping: UpstreamGroupMapping,
  issuerId: string,
  orgId: string
): Observable<UpstreamGroupMapping> {
  const getter = (upstreamGroupMapping: UpstreamGroupMapping) => {
    return issuersService.getUpstreamGroupMapping({
      issuer_id: issuerId,
      upstream_group_mapping_id: upstreamGroupMapping.metadata.id,
      org_id: orgId,
    });
  };
  const putter = (upstreamGroupMapping: UpstreamGroupMapping) => {
    return issuersService.replaceUpstreamGroupMapping({
      issuer_id: issuerId,
      upstream_group_mapping_id: upstreamGroupMapping.metadata.id,
      UpstreamGroupMapping: upstreamGroupMapping,
    });
  };
  return patch_via_put(updatedUpstreamGroupMapping, getter, putter);
}

/**
 * This will ensure the data being sent to the api for update has the
 * most recent values for "_builtin_original" & "updated" to avoid conflict errors.
 */
export function sanitiseAndUpdateExistingUpstreamGroupMapping(
  issuersService: IssuersService,
  updatedUpstreamGroupMapping: UpstreamGroupMapping,
  issuerId: string,
  orgId: string
): Observable<UpstreamGroupMapping> {
  return issuersService
    .getUpstreamGroupMapping({
      issuer_id: issuerId,
      upstream_group_mapping_id: updatedUpstreamGroupMapping.metadata.id,
      org_id: orgId,
    })
    .pipe(
      concatMap((latestFromApi) => {
        const sanitisedObj = defaultSanitiseObjectConflicts<any, string>(updatedUpstreamGroupMapping, latestFromApi);
        return updateExistingUpstreamGroupMapping(issuersService, sanitisedObj, issuerId, orgId);
      })
    );
}

export function getSubdomainFromIssuer(issuer: string): string {
  if (issuer) {
    const targetIndex = issuer.indexOf('.') + 1;
    const subStr = issuer.substring(targetIndex);
    const indexOfSlash = subStr.indexOf('/');
    if (indexOfSlash !== -1) {
      return subStr.substring(0, indexOfSlash);
    }
    return subStr;
  }
  return null;
}

/**
 * Creates an instance of an Issuer to be used for testing purposes only.
 */
export function createBasicDefaultIssuer(props: { id: string; issuer: string }): Issuer {
  return {
    id: props.id,
    issuer: props.issuer,
  };
}

export function getUpstreamValidationErrorMessage(errCode: string, upstreamIssuer: string): string {
  switch (errCode) {
    case 'MISSING_ISSUER_UPSTREAM_HOST':
      return `The issuer URL provided, "${upstreamIssuer}", does not have a host field`;
    case 'INVALID_ISSUER_SCHEME':
      return `The issuer URL scheme must be https`;
    case 'UPSTREAM_CONNECTION_FAILED':
      return `Could not establish a connection to issuer "${upstreamIssuer}". Please ensure that the issuer is running.`;
    case 'UPSTREAM_INVALID':
      return `The response from the upstream is not valid JSON`;
    case 'ISSUER_MISSING_ISSUER':
      return `The response from the upstream does not look like a valid OpenID Connect metadata document`;
    default:
      return `Validation check was not successfully completed. Please try again.`;
  }
}

export function isValidUpstream$<T extends TableElement>(
  issuersService: IssuersService,
  upstreamIssuer: string,
  orgId: string,
  column: Column<T>
): Observable<boolean> {
  if (upstreamIssuer.length > 100) {
    return of(false);
  }
  return issuersService
    .validateUpstream(
      {
        org_id: orgId,
        issuer_upstream_url: upstreamIssuer,
      },
      'body',
      getIgnoreErrorsHeader()
    )
    .pipe(
      map((resp) => {
        column.getCustomValidationErrorMessage = undefined;
        return true;
      }),
      catchError((err) => {
        column.getCustomValidationErrorMessage = () => getUpstreamValidationErrorMessage(err?.error?.error_code, upstreamIssuer);
        return of(false);
      })
    );
}

export function getUpstreamIdentityProviderIssuerCustomValidationErrorMessage(str: string): string {
  return `Row was not saved. Could not establish a connection to issuer "${str}". Please ensure that the issuer is running.`;
}

export enum UpstreamIdentityProviderOperationalStatusEnum {
  good = 'good',
  down = 'down',
  degraded = 'degraded',
  testing = 'testing',
  deleted = 'deleted',
}

export enum UpstreamIdentityProviderTypeEnum {
  oidc = 'oidc',
  local_auth = 'local_auth',
  application = 'application',
  kerberos = 'kerberos',
  managed = 'managed',
}

export function getUpstreamIdentityProviderTypeIcon(status: UpstreamIdentityProviderTypeEnum | string): string {
  switch (status) {
    case UpstreamIdentityProviderTypeEnum.oidc:
      return 'folder_special';
    case UpstreamIdentityProviderTypeEnum.local_auth:
      return 'cloud';
    case UpstreamIdentityProviderTypeEnum.application:
      return 'apps';
    case UpstreamIdentityProviderTypeEnum.managed:
      return 'folder_shared';
    case UpstreamIdentityProviderTypeEnum.kerberos:
      return 'account_tree';
    default:
      return '';
  }
}

export function getUpstreamIdentityProviderOperationalStatusIcon(status: UpstreamIdentityProviderOperationalStatusEnum | string): string {
  switch (status) {
    case UpstreamIdentityProviderOperationalStatusEnum.good:
      return 'check_circle';
    case UpstreamIdentityProviderOperationalStatusEnum.down:
      return 'error';
    case UpstreamIdentityProviderOperationalStatusEnum.degraded:
      return 'trending_down';
    case UpstreamIdentityProviderOperationalStatusEnum.testing:
      return 'fact_check';
    case UpstreamIdentityProviderOperationalStatusEnum.deleted:
      return 'delete';
    default:
      return '';
  }
}

export function getUpstreamIdentityProviderOperationalStatusIconColor(
  status: UpstreamIdentityProviderOperationalStatusEnum | string
): string {
  switch (status) {
    case UpstreamIdentityProviderOperationalStatusEnum.good:
      return IconColor.success;
    case UpstreamIdentityProviderOperationalStatusEnum.down:
      return IconColor.warn;
    case UpstreamIdentityProviderOperationalStatusEnum.deleted:
      return IconColor.warn;
    case UpstreamIdentityProviderOperationalStatusEnum.degraded:
      return IconColor.intermediate;
    case UpstreamIdentityProviderOperationalStatusEnum.testing:
      return IconColor.intermediate;
    default:
      return IconColor.none;
  }
}

export function getUpstreamIdentityProviderAdminStatusIcon(status: AdminStatus): string {
  switch (status) {
    case AdminStatus.active:
      return 'check_circle';
    case AdminStatus.disabled:
      return 'error';
    case AdminStatus.testing:
      return 'fact_check';
    case AdminStatus.deleted:
      return 'delete';
    default:
      return '';
  }
}

export function getUpstreamIdentityProviderAdminStatusIconColor(status: AdminStatus): string {
  switch (status) {
    case AdminStatus.active:
      return IconColor.success;
    case AdminStatus.disabled:
      return IconColor.warn;
    case AdminStatus.deleted:
      return IconColor.warn;
    case AdminStatus.testing:
      return IconColor.intermediate;
    default:
      return IconColor.none;
  }
}

export function getIssuerUpstreamIdentityProviderKeyFromType(type: UpstreamIdentityProviderTypeEnum): string {
  return `${type}_upstreams`;
}

export function getListOfUpstreamIdentityProviders$(
  issuersService: IssuersService,
  issuerId: string,
  orgId: string
): Observable<Array<BaseUpstream>> {
  const getUpstreamsRequestParams: GetUpstreamsRequestParams = {
    issuer_id: issuerId,
    org_id: orgId,
  };
  return issuersService.getUpstreams(getUpstreamsRequestParams).pipe(map((resp) => resp.upstreams));
}

/**
 * Will continue to poll every 15 seconds
 */
export function getListOfUpstreamIdentityProvidersPoll$(
  issuersService: IssuersService,
  issuerId: string,
  orgId: string
): Observable<Array<BaseUpstream>> {
  return interval(15000) // 15 seconds
    .pipe(startWith(0))
    .pipe(
      mergeMap(() => {
        return getListOfUpstreamIdentityProviders$(issuersService, issuerId, orgId);
      })
    );
}

export function getUpstreamDomainNameTooltipText(): string {
  return 'If the domain this local upstream provides does not match the desired domain, you can override it using this field';
}
