<div class="dialog-container">
    <mat-dialog-content>
        <h2 class="message-prefix">{{ messagePrefix }}</h2>
        <hr class="separator-line">
        <form
          [formGroup]="presetValuesGroup"
          spellcheck="false"
          autocomplete="off"
        >
            <div class="initial-input-fields">
                <mat-form-field class="extended-field first">
                    <input
                    matInput
                    type="text"
                    placeholder="Multi factor required time interval (time in seconds)"
                    formControlName="multiFactorRequiredTime"
                    />
                </mat-form-field>
    
                <mat-form-field class="extended-field second">
                    <input
                    matInput
                    type="text"
                    placeholder="Sessions valid for (time in seconds)"
                    formControlName="sessionsValidTime"
                    />
                </mat-form-field>
            </div>

            <div>
                <mat-checkbox
                    formControlName="sharedSessionsBetweenApps"
                    aria-label="Checkbox that toggles whether the security header option is enabled"
                >
                    Allow shared sessions between applications
                </mat-checkbox>
            </div>

            <div formGroupName="noMfaFromProviders">
                <mat-checkbox
                    formControlName="checked"
                    aria-label="Checkbox that toggles whether the security header option is enabled"
                >
                    Does not require multi factor authentication from providers
                </mat-checkbox>

                <mat-form-field>
                    <mat-chip-list #noMfaFromProvidersChipList aria-label="Provider selection">
                    <mat-chip
                        *ngFor="let upstream of selectedUpstreams"
                        (removed)="removeChipList(upstream, selectedUpstreams)">
                        {{upstream}}
                        <mat-icon matChipRemove *ngIf="filterChipOptions.removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Select Providers..."
                        #upstreamInput
                        [formControl]="upstreamCtrl"
                        [matAutocomplete]="upstreamAuto"
                        [matChipInputFor]="noMfaFromProvidersChipList"
                        [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
                        (matChipInputTokenEnd)="addChipList($event, selectedUpstreams)">
                    </mat-chip-list>
                    <mat-autocomplete #upstreamAuto="matAutocomplete" (optionSelected)="selectedChipList($event, selectedUpstreams)">
                    <span *ngFor="let upstream of filteredUpstreams | async">
                    <mat-option [value]="upstream" *ngIf="!isOptionAlreadySelected(upstream, selectedUpstreams)">
                        {{upstream}}
                    </mat-option>
                    </span>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            
            <div formGroupName="requireMfaFromApps">
                <mat-checkbox
                    formControlName="checked"
                    aria-label="Checkbox that toggles whether the security header option is enabled"
                >
                    Always require multi factor authentication for applications
                </mat-checkbox>

                <mat-form-field>
                    <mat-chip-list #requireMfaFromAppsChipList aria-label="Application selection">
                    <mat-chip
                        *ngFor="let app of selectedApplications"
                        (removed)="removeChipList(app, selectedApplications)">
                        {{app}}
                        <mat-icon matChipRemove *ngIf="filterChipOptions.removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Select Applications..."
                        #appInput
                        [formControl]="appCtrl"
                        [matAutocomplete]="appAuto"
                        [matChipInputFor]="requireMfaFromAppsChipList"
                        [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
                        (matChipInputTokenEnd)="addChipList($event, selectedApplications)">
                    </mat-chip-list>
                    <mat-autocomplete #appAuto="matAutocomplete" (optionSelected)="selectedChipList($event, selectedApplications)">
                    <span *ngFor="let app of filteredApplications | async">
                    <mat-option [value]="app" *ngIf="!isOptionAlreadySelected(app, selectedApplications)">
                        {{app}}
                    </mat-option>
                    </span>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            
            <div formGroupName="requireMfaFromGroups">
                <mat-checkbox
                    formControlName="checked"
                    aria-label="Checkbox that toggles whether the security header option is enabled"
                >
                    Always require multi factor authentication for groups
                </mat-checkbox>

                <mat-form-field>
                    <mat-chip-list #requireMfaFromGroupsChipList aria-label="Group selection">
                    <mat-chip
                        *ngFor="let group of selectedGroups"
                        (removed)="removeChipList(group, selectedGroups)">
                        {{getGroupNameFromId(group)}}
                        <mat-icon matChipRemove *ngIf="filterChipOptions.removable">cancel</mat-icon>
                    </mat-chip>
                    <input placeholder="Select Groups..."
                        #groupInput
                        [formControl]="groupCtrl"
                        [matAutocomplete]="groupAuto"
                        [matChipInputFor]="requireMfaFromGroupsChipList"
                        [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
                        (matChipInputTokenEnd)="addChipList($event, selectedGroups)">
                    </mat-chip-list>
                    <mat-autocomplete #groupAuto="matAutocomplete" (optionSelected)="selectedChipList($event, selectedGroups)">
                    <span *ngFor="let group of filteredGroups | async">
                    <mat-option [value]="group" *ngIf="!isOptionAlreadySelected(group, selectedGroups)">
                        {{getGroupNameFromId(group)}}
                    </mat-option>
                    </span>
                    </mat-autocomplete>
                </mat-form-field>
            </div>

        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{ confirmButtonText }}</button>
        <button mat-raised-button mat-dialog-close tabindex="-1" *ngIf="cancelButtonText.length > 0">{{ cancelButtonText }}</button>
    </mat-dialog-actions>
</div>
