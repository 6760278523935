import { createPermissionSelector } from './utils';
import { createSelector } from '@ngrx/store';
import { selectUserState } from '@app/core/core.state';
import { UserState } from '../user.models';

export const selectCanReadUsers = createPermissionSelector('usersRoles', ['owner', 'reader']);
export const selectCanAdminUsers = createPermissionSelector('usersRoles', ['owner']);

export const selectCanAdminOrReadUsers = selectCanReadUsers;

export const selectRootOrgId = createSelector(selectUserState, (state: UserState) => {
  if (!state || !state.current_org) {
    return undefined;
  }
  return state.current_org.root_org_id;
});
