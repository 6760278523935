<div class="dialog-container">
  <mat-dialog-content>
    <h2 class="message-prefix">{{ messagePrefix }}</h2>
    <span *ngIf="!informationDialog">
      <div class="icon-container">
        <mat-icon class="warning-icon" [ngClass]="{ 'notify': icon !== 'warning' }">{{ icon }}</mat-icon>
      </div>
      <div class="message-container">
        <p [innerHTML]="message"></p>
      </div>
    </span>
    <div *ngIf="informationDialog" style="width: 100%;" class="message-container" [innerHTML]="message"></div>
    
    <span *ngIf="!!productGuideData">
      <portal-page-info
        [pageTitle]="productGuideData.pageTitle"
        [pageDescriptiveText]="productGuideData.pageDescriptiveText"
        [pageSubDescriptiveList]="productGuideData.pageSubDescriptiveList"
        [productGuidePreText]="productGuideData.productGuidePreText"
        [productGuideLink]="productGuideData.productGuideLink"
        [productGuideLinkText]="productGuideData.productGuideLinkText"
        [productGuidePostText]="productGuideData.productGuidePostText"
        [pageInfoWidth]="productGuideData.pageInfoWidth"
        [helpImageFilePath]="productGuideData.helpImageFilePath"
        [pageDescriptiveTextWithImageWrap]="productGuideData.pageDescriptiveTextWithImageWrap"
        [pageDescriptiveHelpImageWithTextWrap]="productGuideData.pageDescriptiveHelpImageWithTextWrap"
        [titleSize]="productGuideData.titleSize"
      >
      </portal-page-info>
    </span>
    
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      *ngIf="showDoNotShowButton"
      mat-raised-button
      color="primary"
      class="action-button"
      (click)="onDoNotShowClick()"
    >
      DO NOT SHOW AGAIN
    </button>
    <button
      *ngIf="confirmButtonText.length > 0"
      mat-raised-button
      color="primary"
      class="action-button"
      (click)="onConfirmClick()"
      tabindex="1"
    >
      {{ confirmButtonText }}
    </button>
    <button
      *ngIf="cancelButtonText.length > 0"
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
      class="action-button"
    >
      {{ cancelButtonText }}
    </button>
  </mat-dialog-actions>
</div>