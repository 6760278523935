import { NetworkServiceConfig } from '@agilicus/angular';
import { getDefaultNetworkServiceConfig } from '../network-service-config/network-service-config-model-utils';
import { ResourceModel } from '../resource/resource-model';

export interface NetworkModel extends ResourceModel {
  name: string;
  hostname: string;
  connector_name: string;
  config: NetworkServiceConfig | undefined;
  override_ip?: string;
  tls_enabled?: boolean;
  tls_verify?: boolean;
  expose_as_hostname?: boolean;
  source_port_override: string;
  source_address_override: string;
}

export function defaultNetworkModel(): NetworkModel {
  return {
    id: undefined,
    name: '',
    hostname: '',
    connector_name: '',
    config: getDefaultNetworkServiceConfig(),
    override_ip: '',
    tls_enabled: false,
    tls_verify: false,
    expose_as_hostname: false,
    source_port_override: '',
    source_address_override: '',
  };
}
