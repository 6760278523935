import { Application, Environment } from '@agilicus/angular';
import { cloneDeep } from 'lodash-es';

export function sanitiseEnvironment(env: Environment): Environment {
  const result: Environment = cloneDeep(env);
  delete result.status;
  if (result._builtin_original) {
    delete result._builtin_original.status;
  }

  return result;
}

// Work around the annoying diff problems with items nested in lists
// by removing these unnecessary read-only variables.
export function sanitiseAppEnvironments(app: Application): Application {
  const result: Application = cloneDeep(app);
  result.environments = app.environments.map((value) => sanitiseEnvironment(value));
  if (result._builtin_original) {
    result._builtin_original.environments = app._builtin_original.environments.map((value) => sanitiseEnvironment(value));
  }
  return result;
}

export function sanitiseApplication(app: Application): Application {
  const result: Application = { ...app };
  // remove empty strings that cannot be empty
  if (!result.image_username) {
    delete result.image_username;
  }

  if (!result.image_password) {
    delete result.image_password;
  }

  // enums have no other way of being unset
  if (!result.image_credentials_type) {
    delete result.image_credentials_type;
  }

  // this needs to be a valid email
  if (!result.contact_email) {
    delete result.contact_email;
  }

  // Remove the older version of nested roles
  if (result.roles) {
    delete result.roles;
  }

  return result;
}
