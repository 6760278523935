<portal-page-info
  pageTitle="Applications"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasPermissions !== undefined && !hasPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasPermissions" 
  class="application-overview-container"
>
  <portal-table-layout
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [rowObjectName]="rowObjectName"
    [buttonsToShow]="buttonsToShow"
    [customButtons]="customButtons"
    [warnOnNOperations]="warnOnNOperations"
    (updateEvent)="updateEvent($event)"
    (removeSelected)="deleteSelected($event)"
    (enableSelected)="enableSelected($event)"
    (disableSelected)="disableSelected($event)"
  >
  </portal-table-layout>
</div>
