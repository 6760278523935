import {
  ApplicationService,
  ApplicationServicesService,
  Connector,
  ConnectorsService,
  ServiceForwarder,
  ServiceForwarderSpec,
} from '@agilicus/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import { getConnectors } from '@app/core/api/connectors/connectors-api-utils';
import {
  createNewServiceForwarder,
  getServiceForwarders,
  updateExistingServiceForwarder,
} from '@app/core/application-service-state/application-services-utils';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FilterManager } from '../filter/filter-manager';
import { OptionalServiceForwarderElement } from '../optional-types';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { Column, createInputColumn, createSelectColumn, createSelectRowColumn, setColumnDefs } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { capitalizeFirstLetter, updateTableElements } from '../utils';
import { isValidHostnameOrIp4, isValidPort } from '../validation-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { selectApplicationServicesList } from '@app/core/application-service-state/application-service.selectors';
import { initApplicationServices } from '@app/core/application-service-state/application-service.actions';

export interface ServiceForwarderElement extends TableElement, ServiceForwarderSpec {
  destination_ip: string;
  destination_port: number;
  backingServiceForwarder: ServiceForwarder;
  backingNetwork: ApplicationService;
}

@Component({
  selector: 'portal-forwarding-service',
  templateUrl: './forwarding-service.component.html',
  styleUrls: ['./forwarding-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForwardingServiceComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<ServiceForwarderElement>> = new Map();
  public tableData: Array<ServiceForwarderElement> = [];
  public rowObjectName = 'FORWARDING SERVICE';
  public filterManager: FilterManager = new FilterManager();
  private hasAppsPermissions$: Observable<OrgQualifiedPermission>;
  public hasAppsPermissions: boolean;
  private orgId: string;
  public connectors: Array<Connector>;
  public connectorIdToConnectorMap: Map<string, Connector> = new Map();
  public connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public applicationServices: Array<ApplicationService>;
  public networkIdToNetworkMap: Map<string, ApplicationService> = new Map();
  public applicationServiceNameToApplicationServiceMap: Map<string, ApplicationService> = new Map();
  public serviceForwarders: Array<ServiceForwarder>;
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public pageDescriptiveText = `A Network Resource is a native TCP service available within the same network as a Connector. 
  Sometimes it is required to make a network resource available to another user on another site. 
  In this case, add a forwarding rule.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/forwarding/`;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private applicationServicesService: ApplicationServicesService,
    private connectorsService: ConnectorsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initApplicationServices({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    this.hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    this.hasAppsPermissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((hasAppsPermissionsResp) => {
      this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
      this.orgId = hasAppsPermissionsResp.orgId;
      if (this.hasAppsPermissions) {
        this.updateTable();
      }
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateTable(): void {
    const appServiceListState$ = this.store.pipe(select(selectApplicationServicesList));
    const connectors$ = getConnectors(this.connectorsService, this.orgId);
    const serviceForwarders$ = getServiceForwarders(this.applicationServicesService, this.orgId);
    combineLatest([appServiceListState$, connectors$, serviceForwarders$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([appServiceListStateResp, connectorsResp, serviceForwardersResp]) => {
          this.connectors = connectorsResp;
          this.serviceForwarders = serviceForwardersResp;
          this.applicationServices = appServiceListStateResp;
          /* We want to filter, but cannot due to it breaking existing configurations which may have been done via cli.
          // Application services with multiple ports cannot be assigned to a forwarding service, so we filter them out:
          this.applicationServices = appServicesStateResp.application_services.filter((appService) => !!appService.port);
          */
          this.setAllMaps();
          if (serviceForwardersResp === undefined) {
            this.resetEmptyTable();
            return;
          }
          this.setColumnsAllowedValues();
          this.buildData();
          this.replaceTableWithCopy();
        },
        (error) => {
          this.resetEmptyTable();
        }
      );
  }

  private setColumnsAllowedValues(): void {
    const sourceConnectorColumn = this.columnDefs.get('connector_id');
    sourceConnectorColumn.allowedValues = ['', ...this.connectors.map((connector) => connector.spec.name)];
    const destinationServiceColumn = this.columnDefs.get('application_service_id');
    destinationServiceColumn.allowedValues = ['', ...this.applicationServices.map((appService) => appService.name)];
  }

  private setAllMaps(): void {
    this.setConnectorMaps();
    this.setApplicationServiceMaps();
  }

  private setConnectorMaps(): void {
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of this.connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  private setApplicationServiceMaps(): void {
    this.networkIdToNetworkMap.clear();
    this.applicationServiceNameToApplicationServiceMap.clear();
    for (const appService of this.applicationServices) {
      this.networkIdToNetworkMap.set(appService.id, appService);
      this.applicationServiceNameToApplicationServiceMap.set(appService.name, appService);
    }
  }

  private buildData(): void {
    const data: Array<ServiceForwarderElement> = [];
    for (let i = 0; i < this.serviceForwarders.length; i++) {
      data.push(this.createServiceForwarderElement(this.serviceForwarders[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createServiceForwarderElement(serviceForwarder: ServiceForwarder, index: number): ServiceForwarderElement {
    const backingNetwork = this.getBackingNetworkFromId(serviceForwarder.spec.application_service_id);
    const data: ServiceForwarderElement = {
      destination_ip: !!backingNetwork?.hostname ? backingNetwork.hostname : '',
      destination_port: !!backingNetwork?.port ? backingNetwork.port : null,
      backingServiceForwarder: serviceForwarder,
      backingNetwork,
      ...getDefaultTableProperties(index),
      ...serviceForwarder.spec,
    };
    return data;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private getSourceConnectorColumn(): Column<ServiceForwarderElement> {
    const sourceConnectorColumn = createSelectColumn('connector_id');
    sourceConnectorColumn.displayName = 'Source connector';
    sourceConnectorColumn.getHeaderTooltip = () => {
      return `This is a connector installed on the remote site that wants access to the network resource. 
        You would have previously installed it using the "New" sub-menu under the "Connectors" tab on the left.`;
    };
    sourceConnectorColumn.getDisplayValue = (element: OptionalServiceForwarderElement) => {
      const targetConnector = this.connectorIdToConnectorMap.get(element.connector_id);
      if (!!targetConnector) {
        return targetConnector.spec.name;
      }
      return '';
    };
    return sourceConnectorColumn;
  }

  private getDestinationServiceColumn(): Column<ServiceForwarderElement> {
    const destinationServiceColumn = createSelectColumn('application_service_id');
    destinationServiceColumn.displayName = 'Destination Network';
    destinationServiceColumn.getHeaderTooltip = () => {
      return `This network resource provides access and authorisation to the site you are trying to reach. 
      It models your database or other network service. You would have previously configured it using the "Services" sub-menu under the "Organisation" tab on the left.`;
    };
    destinationServiceColumn.getDisplayValue = (element: OptionalServiceForwarderElement) => {
      const targetAppService = element.backingNetwork;
      if (!!targetAppService) {
        return targetAppService.name;
      }
      return '';
    };
    return destinationServiceColumn;
  }

  /**
   * This is the hostname of the destination service. It is readonly.
   */
  private getDestinationIpColumn(): Column<ServiceForwarderElement> {
    const destinationIpColumn = createInputColumn('destination_ip');
    destinationIpColumn.displayName = 'Destination IP/hostname';
    destinationIpColumn.getDisplayValue = (element: OptionalServiceForwarderElement) => {
      return element.backingNetwork?.hostname ? element.backingNetwork.hostname : null;
    };
    destinationIpColumn.getHeaderTooltip = () => {
      return 'This was previously set when you created the network resource and is for informational purposes only.';
    };
    destinationIpColumn.isReadOnly = () => true;
    destinationIpColumn.getTooltip = () => {
      return 'readonly';
    };

    return destinationIpColumn;
  }

  /**
   * This is the port of the destination service. It is readonly.
   */
  private getDestinationPortColumn(): Column<ServiceForwarderElement> {
    const destinationPortColumn = createInputColumn('destination_port');
    destinationPortColumn.displayName = 'Destination port';
    destinationPortColumn.getDisplayValue = (element: OptionalServiceForwarderElement) => {
      const port = element.backingNetwork?.port ? element.backingNetwork.port.toString() : null;
      return port;
    };
    destinationPortColumn.getHeaderTooltip = () => {
      return 'This was previously set when you created the network resource and is for informational purposes only.';
    };
    destinationPortColumn.getTooltip = () => {
      return 'readonly';
    };
    destinationPortColumn.isReadOnly = () => true;
    return destinationPortColumn;
  }

  private getSourceIpColumn(): Column<ServiceForwarderElement> {
    const sourceIpColumn = createInputColumn('bind_address');
    sourceIpColumn.displayName = 'Source IP/hostname';
    sourceIpColumn.isEditable = true;
    sourceIpColumn.isValidEntry = isValidHostnameOrIp4;
    sourceIpColumn.getHeaderTooltip = () => {
      return `If you require the network resource to be available to any device in the same site as this connector, enter "0.0.0.0". 
      If you wish it available only on the same machine as the agent runs on, enter "localhost".`;
    };
    return sourceIpColumn;
  }

  private getSourcePortColumn(): Column<ServiceForwarderElement> {
    const sourcePortColumn = createInputColumn('port');
    sourcePortColumn.displayName = 'Source port';
    sourcePortColumn.isEditable = true;
    sourcePortColumn.isValidEntry = isValidPort;
    sourcePortColumn.getHeaderTooltip = () => {
      return `Typically, you will leave this as the default, which is the same as the destination. 
      In some cases you may have a conflict, for example, when you forward to 2 different network resources from the same host. 
      In this case you may select a different local port.`;
    };
    return sourcePortColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getSourceConnectorColumn(),
        this.getDestinationServiceColumn(),
        this.getDestinationIpColumn(),
        this.getDestinationPortColumn(),
        this.getSourceIpColumn(),
        this.getSourcePortColumn(),
      ],
      this.columnDefs
    );
  }

  private getBackingNetworkFromId(networkId: string): ApplicationService | undefined {
    return this.networkIdToNetworkMap.get(networkId);
  }

  public makeEmptyTableElement(): ServiceForwarderElement {
    const backingServiceForwarder: ServiceForwarder = {
      spec: {
        name: '',
        org_id: this.orgId,
        bind_address: 'localhost',
        port: null,
        protocol: ServiceForwarderSpec.ProtocolEnum.tcp,
        application_service_id: '',
        connector_id: '',
      },
    };
    return {
      name: backingServiceForwarder.spec.name,
      org_id: backingServiceForwarder.spec.org_id,
      bind_address: backingServiceForwarder.spec.bind_address,
      port: backingServiceForwarder.spec.port,
      protocol: backingServiceForwarder.spec.protocol,
      application_service_id: backingServiceForwarder.spec.application_service_id,
      connector_id: backingServiceForwarder.spec.connector_id,
      destination_ip: '',
      destination_port: null,
      backingServiceForwarder,
      backingNetwork: {
        name: '',
        org_id: backingServiceForwarder.spec.org_id,
        hostname: '',
        port: null,
      },
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Receives a ServiceForwarderElement from the table then updates and saves
   * the ServiceForwarder.
   */
  public updateEvent(updatedServiceForwarderElement: ServiceForwarderElement): void {
    if (!this.isValidServiceForwarder(updatedServiceForwarderElement)) {
      return;
    }
    this.saveServiceForwarder(updatedServiceForwarderElement);
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<ServiceForwarderElement>; element: ServiceForwarderElement }): void {
    if (params.column.name !== 'connector_id' && params.column.name !== 'application_service_id') {
      return;
    }
    if (params.column.name === 'connector_id') {
      this.handleConnectorSelection(params.value, params.element);
    }
    if (params.column.name === 'application_service_id') {
      this.handleApplicationServiceSelection(params.value, params.element);
    }
  }

  private handleConnectorSelection(value: string, element: ServiceForwarderElement): void {
    const connectorId = this.connectorNameToConnectorMap.get(value)?.metadata.id;
    element.connector_id = !!connectorId ? connectorId : '';
  }

  private handleApplicationServiceSelection(value: string, element: ServiceForwarderElement): void {
    const targetAppService = this.applicationServiceNameToApplicationServiceMap.get(value);
    const appServiceId = targetAppService?.id;
    element.application_service_id = !!appServiceId ? appServiceId : '';
    if (!targetAppService) {
      this.clearPropertiesBasedOnNoMatchingAppService(element);
      return;
    }
    this.setRelatedPropertiesBasedOnChosenAppService(element, targetAppService);
  }

  /**
   * We need to automatically set these values based on the chosen application service.
   */
  private setRelatedPropertiesBasedOnChosenAppService(element: ServiceForwarderElement, targetAppService: ApplicationService): void {
    element.bind_address = 'localhost';
    element.port = targetAppService.port;
    element.destination_ip = targetAppService.hostname;
    element.destination_port = targetAppService.port;
  }

  private clearPropertiesBasedOnNoMatchingAppService(element: ServiceForwarderElement): void {
    element.bind_address = '';
    element.port = null;
    element.destination_ip = '';
    element.destination_port = null;
    element.name = '';
  }

  private isValidServiceForwarder(element: ServiceForwarderElement): boolean {
    if (!!element.connector_id && !!element.application_service_id) {
      return true;
    }
    return false;
  }

  /**
   * The value we get from the input is a string. We need to convert this to a number
   * before submitting to the api. Due to type safety restrictions we first
   * "convert it" to a string.
   */
  private convertPortValueToNumber(element: ServiceForwarder): void {
    if (!element.spec.port) {
      return;
    }
    const updatedPort = element.spec.port.toString();
    element.spec.port = parseInt(updatedPort, 10);
  }

  private getServiceForwarderFromElement(element: ServiceForwarderElement): ServiceForwarder {
    element.backingServiceForwarder.spec.application_service_id = element.application_service_id;
    element.backingServiceForwarder.spec.bind_address = element.bind_address;
    element.backingServiceForwarder.spec.connector_id = element.connector_id;
    element.backingServiceForwarder.spec.name = element.name;
    element.backingServiceForwarder.spec.port = element.port;
    this.convertPortValueToNumber(element.backingServiceForwarder);
    return element.backingServiceForwarder;
  }

  private setServiceForwarderName(serviceForwarderElement: ServiceForwarderElement): void {
    const targetAppService = this.networkIdToNetworkMap.get(serviceForwarderElement.application_service_id);
    const targetConnector = this.connectorIdToConnectorMap.get(serviceForwarderElement.connector_id);
    serviceForwarderElement.name = `${targetAppService.name}_${targetConnector.spec.name}`;
  }

  private saveServiceForwarder(serviceForwarderElement: ServiceForwarderElement): void {
    this.setServiceForwarderName(serviceForwarderElement);
    if (!serviceForwarderElement.backingServiceForwarder.metadata) {
      this.createNewServiceForwarder(serviceForwarderElement);
    } else {
      this.replaceServiceForwarder(serviceForwarderElement);
    }
  }

  private handleUpdateServiceForwarderError(error: Error, baseMessage: string): void {
    if (error instanceof HttpErrorResponse && error.status === 400) {
      if (error.error?.error_code === 'INVALID_REQUEST') {
        baseMessage +=
          '. A forwarding service cannot be assigned to a network resource with more than one port. Please select a network resource with a single port.';
      } else if (!!error?.error?.error_message) {
        baseMessage += `. ${capitalizeFirstLetter(error.error.error_message)}.`;
      }
    }
    this.notificationService.error(baseMessage);
  }

  private createNewServiceForwarder(newServiceForwarderElement: ServiceForwarderElement): void {
    const newServiceForwarder = this.getServiceForwarderFromElement(newServiceForwarderElement);
    createNewServiceForwarder(this.applicationServicesService, newServiceForwarder)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success(`Forwarding service "${resp.spec.name}" was successfully created`);
        },
        (err) => {
          const baseMessage = `Failed to create forwarding service "${newServiceForwarderElement.name}"`;
          this.handleUpdateServiceForwarderError(err, baseMessage);
        },
        () => {
          this.updateTable();
        }
      );
  }

  private replaceServiceForwarder(updatedServiceForwarderElement: ServiceForwarderElement): void {
    const updatedServiceForwarder = this.getServiceForwarderFromElement(updatedServiceForwarderElement);
    updateExistingServiceForwarder(this.applicationServicesService, updatedServiceForwarder)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success(`Forwarding service "${resp.spec.name}" was successfully updated`);
        },
        (err) => {
          const baseMessage = `Failed to update forwarding service "${updatedServiceForwarderElement.name}"`;
          this.handleUpdateServiceForwarderError(err, baseMessage);
        },
        () => {
          this.updateTable();
        }
      );
  }

  public deleteSelected(serviceForwardersToDelete: Array<ServiceForwarderElement>): void {
    const observablesArray: Array<Observable<object>> = [];
    for (const serviceForwarder of serviceForwardersToDelete) {
      if (serviceForwarder.index === -1) {
        continue;
      }
      observablesArray.push(
        this.applicationServicesService.deleteServiceForwarder({
          service_forwarder_id: serviceForwarder.backingServiceForwarder.metadata.id,
          org_id: this.orgId,
        })
      );
    }
    if (observablesArray.length === 0) {
      this.notificationService.success('Unsaved forwarding services were removed');
      this.updateTable();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Forwarding services were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected forwarding services');
        },
        () => {
          this.updateTable();
        }
      );
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
