import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core';
import { GettingStartedTask } from '@app/core/user/preferences/getting-started-tasks.enum';
import { GettingStartedData } from '@app/core/user/preferences/getting-started-data';
import {
  getTaskCompletedStatus,
  getTaskCompleteToggleText,
  toggleGettingStartedTask,
} from '@app/core/user/preferences/user-preference-utils';
import { selectApiOrgId, selectGettingStartedData } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';

export enum NumberOfUserOptions {
  all = 'all',
  some = 'some',
  one = 'one',
}

@Component({
  selector: 'portal-assign-permissions-guide',
  templateUrl: './assign-permissions-guide.component.html',
  styleUrls: ['./assign-permissions-guide.component.scss', '../getting-started-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignPermissionsGuideComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public gettingStartedDataCopy: GettingStartedData;
  public pageDescriptiveText = `Each resource has an innate set of roles. For Web Applications these can be quite complex, or as simple as allow/deny. 
  For a Share these include Owner (add/modify/delete), Editor (modify), Viewer (read). 
  For a Desktop, there is only one role. 
  First we link Users. Users are linked by their email address to their upstream identity provider. 
  Then we create Groups. Groups can be nested. You might consider e.g. 'HR Admins' as a group name. 
  Then we assign permissions, typically to a Group, but we can also assign to a User.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/permissions/`;
  public isTaskComplete = false;
  public targetTask = GettingStartedTask.completed_assign_permissions;
  public assignPermissionsFormGroup: UntypedFormGroup;

  public getTaskCompletedStatus = getTaskCompletedStatus;
  public getTaskCompleteToggleText = getTaskCompleteToggleText;

  // This is required in order to reference the enums in the html template.
  public numberOfUserOptions = NumberOfUserOptions;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private routerHelperService: RouterHelperService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    combineLatest([orgId$, gettingStartedData$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, gettingStartedDataResp]) => {
        this.orgId = orgIdResp;
        this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
        this.isTaskComplete = getTaskCompletedStatus(this.targetTask, this.gettingStartedDataCopy);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.assignPermissionsFormGroup = this.formBuilder.group({
      isWebApp: ['', Validators.required],
      numberOfUsers: ['', Validators.required],
    });
  }

  public getAssignPermissionsDataFromForm(formField: string): string {
    return this.assignPermissionsFormGroup.get(formField).value;
  }

  public isAWebApplication(): boolean {
    return this.getAssignPermissionsDataFromForm('isWebApp') === 'yes';
  }

  public isNotAWebApplication(): boolean {
    return this.getAssignPermissionsDataFromForm('isWebApp') === 'no';
  }

  public isFirstOptionSelected(): boolean {
    return this.isAWebApplication() || this.isNotAWebApplication();
  }

  public showApplicationAllUsersRecommendation(): boolean {
    return this.isAWebApplication() && this.getAssignPermissionsDataFromForm('numberOfUsers') === NumberOfUserOptions.all;
  }

  public showApplicationSomeUsersRecommendation(): boolean {
    return this.isAWebApplication() && this.getAssignPermissionsDataFromForm('numberOfUsers') === NumberOfUserOptions.some;
  }

  public showApplicationOneUserRecommendation(): boolean {
    return this.isAWebApplication() && this.getAssignPermissionsDataFromForm('numberOfUsers') === NumberOfUserOptions.one;
  }

  public showResourceAllUsersRecommendation(): boolean {
    return this.isNotAWebApplication() && this.getAssignPermissionsDataFromForm('numberOfUsers') === NumberOfUserOptions.all;
  }

  public showResourceSomeUsersRecommendation(): boolean {
    return this.isNotAWebApplication() && this.getAssignPermissionsDataFromForm('numberOfUsers') === NumberOfUserOptions.some;
  }

  public showResourceOneUserRecommendation(): boolean {
    return this.isNotAWebApplication() && this.getAssignPermissionsDataFromForm('numberOfUsers') === NumberOfUserOptions.one;
  }

  public onImageClick(routerLink: string): void {
    this.routerHelperService.routeToScreen(routerLink, this.orgId);
  }

  public onCompleteToggle(isChecked: boolean): void {
    toggleGettingStartedTask(isChecked, this.targetTask, this.gettingStartedDataCopy, this.store);
  }
}
