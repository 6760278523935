import { InboxItem, MessagesService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import {
  deleteInboxItemUsingAuthService,
  getInboxItemByIdUsingAuthService,
  getInboxItemsListUsingAuthService,
  replaceInboxItemUsingAuthService,
} from '../api/inbox-items/inbox-items-api-utils';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';

import * as InboxItemActions from '../inbox-item-state/inbox-item.actions';
import { AuthService } from '../services/auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class InboxItemStateService extends SimpleCRUDInterface<InboxItem, string> {
  public crudRegistryName = CrudStateCollection.inbox_item;

  constructor(
    public store: Store<AppState>,
    private messagesService: MessagesService,
    private crudRegistryService: CrudRegistryService<InboxItem, string>,
    private authService: AuthService
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<InboxItem, string> = {
        create: undefined,
        update: (id: string, obj: InboxItem, orgId: string) =>
          replaceInboxItemUsingAuthService(this.messagesService, this.authService, id, obj),
        get: (id: string, orgId: string) => getInboxItemByIdUsingAuthService(this.messagesService, this.authService, id),
        list: (orgId: string) => getInboxItemsListUsingAuthService(this.messagesService, orgId, this.authService),
        delete: (id: string, orgId: string) => deleteInboxItemUsingAuthService(this.messagesService, this.authService, id),
      };
      const crudRegistry: CrudRegistry<InboxItem, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: InboxItem) => 'message',
        doneSaveAction: InboxItemActions.upsertInboxItem,
        doneSaveListAction: InboxItemActions.upsertInboxItems,
        doneDeleteAction: InboxItemActions.deleteInboxItem,
        doneDeleteListAction: InboxItemActions.deleteInboxItems,
        setListAction: InboxItemActions.loadInboxItems,
        refreshStateAction: InboxItemActions.refreshInboxItems,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
