import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { switchMap, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { of, Observable, forkJoin } from 'rxjs';
import { NotificationService } from '../notifications/notification.service';
import { Application, ApplicationService } from '@agilicus/angular';
import { ActionApiApplicationsReloadApp, ActionApiApplicationsLoadingEnvFileConfigs } from '../api-applications/api-applications.actions';
import { ApplicationsService } from '@agilicus/angular';
import { AppState } from '..';
import { selectCanReadApps } from '../user/permissions/app.selectors';
import { ApplicationServiceStateService } from '../state-services/application-service-state.service';
import * as ApplicationServiceActions from './application-service.actions';
import {
  selectApplicationServices,
  selectApplicationServicesEntity,
  selectApplicationServicesList,
  selectApplicationServicesShouldPopulateValue,
} from './application-service.selectors';
import { ApplicationServicesState } from './application-service.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
} from '../helpers/effect-factories';
import { selectApiOrgId } from '../user/user.selectors';

@Injectable()
export class ApplicationServicesEffects {
  constructor(
    private actions$: Actions,
    private applicationsService: ApplicationsService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private applicationServiceStateService: ApplicationServiceStateService
  ) {}

  public initState$ = createInitStateEffect<ApplicationServicesState>(
    this.store,
    this.actions$,
    ApplicationServiceActions.initApplicationServices,
    ApplicationServiceActions.getApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    selectApplicationServices
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    ApplicationServiceActions.getApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    selectApplicationServicesShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<ApplicationService, string>(
    this.store,
    this.actions$,
    ApplicationServiceActions.getApplicationServices,
    ApplicationServiceActions.clearApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    this.applicationServiceStateService,
    selectCanReadApps
  );

  public setCrudState$ = createSetCrudStateEffect<ApplicationService, string>(
    this.store,
    this.actions$,
    ApplicationServiceActions.loadApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    this.applicationServiceStateService,
    selectApplicationServicesList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<ApplicationService, string>(
    this.store,
    this.actions$,
    ApplicationServiceActions.savingApplicationService,
    ApplicationServiceActions.maintainApplicationServices,
    this.applicationServiceStateService,
    selectApplicationServicesEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<ApplicationService, string>(
    this.store,
    this.actions$,
    ApplicationServiceActions.savingApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    this.applicationServiceStateService,
    selectApplicationServices
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<ApplicationService, string>(
    this.actions$,
    ApplicationServiceActions.deletingApplicationService,
    ApplicationServiceActions.refreshApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    this.applicationServiceStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<ApplicationService, string>(
    this.actions$,
    ApplicationServiceActions.deletingApplicationServices,
    ApplicationServiceActions.refreshApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    this.applicationServiceStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<ApplicationServicesState>(
    this.store,
    this.actions$,
    ApplicationServiceActions.getApplicationServices,
    ApplicationServiceActions.maintainApplicationServices,
    selectApplicationServices
  );

  /**
   * Custom side effect
   */
  public updateApplicationsOnNetworkUpdatesSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationServiceActions.upsertApplicationService),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.select(selectApiOrgId), this.store.pipe(select(selectApplicationServices))))
      ),
      concatMap(([action, orgId, appServicesState]) => {
        if (!appServicesState.trigger_update_side_effects) {
          return of(ApplicationServiceActions.maintainApplicationServices());
        }
        let updatedAppsArray$: Array<Observable<Application | undefined>> = [of(undefined)];
        if (!!action.obj.assignments && action.obj.assignments.length !== 0) {
          updatedAppsArray$ = [];
          for (const assignment of action.obj.assignments) {
            updatedAppsArray$.push(
              this.applicationsService.getApplication({
                app_id: assignment.app_id,
                org_id: assignment.org_id,
              })
            );
          }
        }
        return forkJoin(updatedAppsArray$).pipe(
          switchMap((appsResp: Array<Application | undefined>) => {
            if (appsResp[0] === undefined) {
              return [
                ApplicationServiceActions.resetAppListRefreshFlagApplicationServices(),
                new ActionApiApplicationsLoadingEnvFileConfigs(),
              ];
            }
            return [
              ApplicationServiceActions.resetAppListRefreshFlagApplicationServices(),
              new ActionApiApplicationsLoadingEnvFileConfigs(),
              ...appsResp.map((app) => new ActionApiApplicationsReloadApp(app, orgId)),
            ];
          }),
          catchError((error) => {
            this.notificationService.error('Failed to retrieve the updated application information');
            return of(ApplicationServiceActions.resetAppListRefreshFlagApplicationServices());
          })
        );
      })
    )
  );

  /**
   * Custom side effect
   */
  public refreshApiDataOnNetworkUpdateSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationServiceActions.upsertApplicationServices || ApplicationServiceActions.upsertApplicationService),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectApplicationServices))))),
      concatMap(([action, appServicesState]) => {
        if (!appServicesState.refresh_api_data) {
          return of(ApplicationServiceActions.maintainApplicationServices());
        }
        return [
          ApplicationServiceActions.resetApiDataRefreshFlagApplicationServices(),
          ApplicationServiceActions.initApplicationServices({ force: true, blankSlate: false }),
        ];
      })
    )
  );
}
