<mat-form-field>
  <mat-label *ngIf="!!label">{{ label }}</mat-label>
  <input
    matInput
    [placeholder]="placeholder"
    [formControl]="formCtrl"
    [required]=required
    (blur)="onFormBlurEvent()"
    (keyup.enter)="keyTabManager.keyTab($event.target.id)"
    portalPreventStepperAdvance
  />
</mat-form-field>
<mat-icon
  *ngIf="!!tooltip"
  class="help-icon"
  [matTooltip]="tooltip"
>
  help_outline
</mat-icon>
