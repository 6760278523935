import { CorsTemplateType } from '../cors-template-type';

export function getCorsTemplateTooltipText(template: CorsTemplateType): string {
  if (template === CorsTemplateType.NONE) {
    return 'No CORS policy will be applied. Any settings returned by the application will be passed through.';
  }
  if (template === CorsTemplateType.API) {
    return 'Your application is an API. This option opens up the CORS policy so that any origin may make requests to it.';
  }
  if (template === CorsTemplateType.SELF) {
    return 'Your application does not expect any request origins other than itself. This option locks down the CORS policy so that only it may make requests to itself.';
  }
  return '';
}

export function getCorsTemplateTypes(): Array<CorsTemplateType> {
  return Object.values(CorsTemplateType);
}
