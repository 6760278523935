import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { InboxItem } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadInboxItems = createAction(
  '[InboxItem/API] Load InboxItems',
  props<{ objs: InboxItem[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addInboxItem = createAction('[InboxItem/API] Add InboxItem', props<{ obj: InboxItem }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertInboxItem = createAction('[InboxItem/API] Upsert InboxItem', props<{ obj: InboxItem; refreshData: boolean }>());

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addInboxItems = createAction('[InboxItem/API] Add InboxItems', props<{ objs: InboxItem[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertInboxItems = createAction('[InboxItem/API] Upsert InboxItems', props<{ objs: InboxItem[]; refreshData: boolean }>());

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateInboxItem = createAction('[InboxItem/API] Update InboxItem', props<{ obj: Update<InboxItem> }>());

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateInboxItems = createAction('[InboxItem/API] Update InboxItems', props<{ objs: Update<InboxItem>[] }>());

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteInboxItem = createAction(
  '[InboxItem/API] Delete InboxItem',
  props<{ id: string; obj: InboxItem; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteInboxItems = createAction('[InboxItem/API] Delete InboxItems', props<{ ids: string[]; refreshData?: boolean }>());

/**
 * Will delete all data from the ngrx entity state
 */
export const clearInboxItems = createAction('[InboxItem/API] Clear InboxItems');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initInboxItems = createAction('[InboxItem/API] Init InboxItems', props<{ force: boolean; blankSlate: boolean }>());

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getInboxItems = createAction('[InboxItem/API] Get InboxItems', props<{ org_id: string; blankSlate: boolean }>());

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingInboxItem = createAction(
  '[InboxItem/API] Saving InboxItem',
  props<{ obj: InboxItem; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingInboxItems = createAction(
  '[InboxItem/API] Saving InboxItems',
  props<{ objs: Array<InboxItem>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingInboxItem = createAction(
  '[InboxItem/API] Deleting InboxItem',
  props<{ obj: InboxItem; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingInboxItems = createAction(
  '[InboxItem/API] Deleting InboxItems',
  props<{ objs: Array<InboxItem>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshInboxItems = createAction('[InboxItem/API] Refresh InboxItems');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainInboxItems = createAction('[InboxItem/API] Maintain InboxItems');

// special case actions:
export const setUnreadInboxItemCount = createAction(
  '[InboxItem/API] Set Unread InboxItem Count',
  props<{ unreadInboxItemCount: number }>()
);
