import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OperatingSystemEnum } from '../operatingSystem.enum';

export const basePath = 'https://www.agilicus.com/www/releases/secure-agent/stable';

export const agentDownloadURLs: { [os: string]: string } = {
  [OperatingSystemEnum.windows]: `${basePath}/agilicus-agent.exe`,
  [OperatingSystemEnum.linux]: `${basePath}/agilicus-agent`,
  [OperatingSystemEnum.linux_arm]: `${basePath}/agilicus-agent-arm`,
  [OperatingSystemEnum.freebsd_arm]: `${basePath}/agilicus-agent-freebsd-arm`,
  [OperatingSystemEnum.linux_mipsle]: `${basePath}/agilicus-agent-mipsle`,
  [OperatingSystemEnum.linux_mipsbe]: `${basePath}/agilicus-agent-mipsbe`,
};

@Component({
  selector: 'portal-connector-download-instructions',
  templateUrl: './connector-download-instructions.component.html',
  styleUrls: ['./connector-download-instructions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorDownloadInstructionsComponent implements OnChanges {
  @Input() public operatingSystem: OperatingSystemEnum;
  @Input() public installCmd = '';
  @Input() public rootCertCmd = '';
  @Input() public hasShares: boolean;
  @Input() public failed?: boolean;
  @Input() public joinClusterFormGroup: FormGroup;
  public agentDownloadURL: string;
  public joinClusterOptions: Array<{ name: string; value: boolean }> = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  public operatingSystemEnum = OperatingSystemEnum;

  constructor() {}

  public ngOnChanges(): void {
    this.agentDownloadURL = agentDownloadURLs[this.operatingSystem];
  }

  public getInstallCmd(installCmd: string): string {
    if (!!this.failed) {
      return 'Failed to create the download link. Please close the dialog and try again.';
    }
    const includeJoinCluster: boolean = this.joinClusterFormGroup.get('join_cluster').value;
    if (!!includeJoinCluster) {
      return installCmd;
    }
    if (this.operatingSystem === OperatingSystemEnum.linux) {
      return installCmd.replace('-- --join-cluster', '');
    }
    return installCmd.replace('--join-cluster', '');
  }

  public getRootCertAndInstallCmd(installCmd: string): string {
    if (!this.rootCertCmd) {
      return this.getInstallCmd(installCmd);
    }
    return `${this.rootCertCmd}\n${this.getInstallCmd(installCmd)}`;
  }
}
