export enum ProgressBarMode {
  determinate = 'determinate',
  indeterminate = 'indeterminate',
  buffer = 'buffer',
  query = 'query',
}

export interface ProgressBar {
  progressBarValue: number;
  progressBarBufferValue: number;
  progressBarMode: ProgressBarMode;
  isHidden: boolean;
  hideProgressBarDelay: number;
}

export class ProgressBarController implements ProgressBar {
  public progressBarValue = 0;
  public progressBarBufferValue = 0;
  public progressBarMode = ProgressBarMode.determinate;
  public isHidden = true;
  public hideProgressBarDelay = 2000;

  constructor(progressBar?: ProgressBar) {
    if (progressBar === undefined) {
      return;
    }
    this.progressBarValue = progressBar.progressBarValue;
    this.progressBarBufferValue = progressBar.progressBarBufferValue;
    this.progressBarMode = progressBar.progressBarMode;
    this.isHidden = progressBar.isHidden;
    this.hideProgressBarDelay = progressBar.hideProgressBarDelay;
  }

  public initializeProgressBar(): ProgressBarController {
    return new ProgressBarController({
      progressBarValue: 0,
      progressBarBufferValue: 0,
      progressBarMode: ProgressBarMode.buffer,
      isHidden: false,
      hideProgressBarDelay: this.hideProgressBarDelay,
    });
  }

  public resetProgressBar(): ProgressBarController {
    return new ProgressBarController({
      progressBarValue: 0,
      progressBarBufferValue: 0,
      progressBarMode: ProgressBarMode.determinate,
      isHidden: true,
      hideProgressBarDelay: this.hideProgressBarDelay,
    });
  }

  public updateProgressBarValue(totalUsersToUpload: number, uploadsComplete: number): ProgressBarController {
    let value: number;
    if (totalUsersToUpload === 0) {
      value = 0;
    } else {
      value = (uploadsComplete / totalUsersToUpload) * 100;
    }
    return new ProgressBarController({
      progressBarValue: value,
      progressBarBufferValue: 0,
      progressBarMode: ProgressBarMode.buffer,
      isHidden: false,
      hideProgressBarDelay: this.hideProgressBarDelay,
    });
  }

  public onFailedUpload(): ProgressBarController {
    return new ProgressBarController({
      progressBarValue: this.progressBarValue,
      progressBarBufferValue: 100,
      progressBarMode: this.progressBarMode,
      isHidden: false,
      hideProgressBarDelay: this.hideProgressBarDelay,
    });
  }
}
