import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { Issuer } from '@agilicus/angular';
import { AppState } from '..';
import * as IssuerActions from './issuer.actions';
import { IssuerState } from './issuer.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
} from '../helpers/effect-factories';
import { IssuerStateService } from '../state-services/issuer-state.service';
import { selectIssuer, selectIssuerEntity, selectIssuerShouldPopulateValue, selectIssuersList } from './issuer.selectors';
import { selectIssuerId } from '../user/user.selectors';
import { selectCanReadIssuers } from '../user/permissions/issuers.selectors';
import { of, timer } from 'rxjs';

@Injectable()
export class IssuerEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private issuerStateService: IssuerStateService) {}

  public initState$ = createInitStateEffect<IssuerState>(
    this.store,
    this.actions$,
    IssuerActions.initIssuer,
    IssuerActions.getIssuer,
    IssuerActions.maintainIssuer,
    selectIssuer
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    IssuerActions.getIssuer,
    IssuerActions.maintainIssuer,
    selectIssuerShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<Issuer, string>(
    this.store,
    this.actions$,
    IssuerActions.getIssuer,
    IssuerActions.clearIssuers,
    IssuerActions.maintainIssuer,
    this.issuerStateService,
    selectCanReadIssuers,
    false, // checkParentOrg
    true, // checkOrgSubdomain
    selectIssuerId
  );

  public setCrudState$ = createSetCrudStateEffect<Issuer, string>(
    this.store,
    this.actions$,
    IssuerActions.loadIssuers,
    IssuerActions.maintainIssuer,
    this.issuerStateService,
    selectIssuersList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<Issuer, string>(
    this.store,
    this.actions$,
    IssuerActions.savingIssuer,
    IssuerActions.maintainIssuer,
    this.issuerStateService,
    selectIssuerEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<Issuer, string>(
    this.store,
    this.actions$,
    IssuerActions.savingIssuers,
    IssuerActions.maintainIssuer,
    this.issuerStateService,
    selectIssuer
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<Issuer, string>(
    this.actions$,
    IssuerActions.deletingIssuer,
    IssuerActions.refreshIssuer,
    IssuerActions.maintainIssuer,
    this.issuerStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<Issuer, string>(
    this.actions$,
    IssuerActions.deletingIssuers,
    IssuerActions.refreshIssuer,
    IssuerActions.maintainIssuer,
    this.issuerStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<IssuerState>(
    this.store,
    this.actions$,
    IssuerActions.getIssuer,
    IssuerActions.maintainIssuer,
    selectIssuer
  );

  // Custom Effects:

  /**
   * Custom side effect. We want to refresh the issuer when the
   * trigger_update_side_effects is set to true
   */
  public refreshIssuerSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerActions.savingIssuer),
      map((action) => {
        if (action.trigger_update_side_effects) {
          return IssuerActions.refreshIssuer();
        }
        return IssuerActions.maintainIssuer();
      })
    )
  );

  public initStatePolling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerActions.initIssuerPolling),
      switchMap((action) => {
        // Poll for data every 10 seconds:
        return timer(0, 10000).pipe(
          mergeMap(() => {
            return of(IssuerActions.initIssuer({ force: action.force, blankSlate: action.blankSlate }));
          }),
          takeUntil(this.actions$.pipe(ofType(IssuerActions.stopIssuerPolling)))
        );
      })
    )
  );
}
