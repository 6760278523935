import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ProgressBarController } from './progress-bar-controller';

@Component({
  selector: 'portal-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input()
  public progressBarController: ProgressBarController = new ProgressBarController();
  @Input() public progressBarInfoText = '';

  constructor() {}
}
