<portal-page-info
  pageTitle="Identify Users"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div class="main-content-container">
  <mat-slide-toggle
    class="mark-complete-slider"
    [checked]="isTaskComplete"
    (change)="onCompleteToggle($event.checked)"
  >
    {{ getTaskCompleteToggleText(targetTask, gettingStartedDataCopy) }}
  </mat-slide-toggle>

  <form
    [formGroup]="identifyUsersFormGroup"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="parent-radio-group">
      <mat-radio-group
        formControlName="identityProviderType"
        aria-labelledby="uses microsoft identity provider option radio buttons"
        class="radio-group"
      >
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="identityProviderType.microsoft"
        >
          I use Microsoft to identify my users
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="identityProviderType.other"
        >
          I use Google, Apple or Linkedin to identify my users
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="identityProviderType.own"
        >
          I use my own internal identity provider
        </mat-radio-button>
      </mat-radio-group>
    </div>
    
    <div *ngIf="getIdentityProviderDataFromForm('identityProviderType') === identityProviderType.microsoft">
      <div class="child-radio-group nest-level-1">
        <mat-radio-group
          formControlName="microsoftAccountType"
          aria-labelledby="microsoft account type option radio buttons"
          class="radio-group nested-radio-group"
        >
          <mat-radio-button
            class="radio-button full-line-radio-button"
            value="yes"
          >
            My users currently have an Office365 or Azure active directory account
          </mat-radio-button>
          <mat-radio-button
            class="radio-button full-line-radio-button"
            value="no"
          >
            My users do not have an Office365 or Azure active directory account
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="child-radio-group nest-level-2">
        <mat-radio-group
          *ngIf="getIdentityProviderDataFromForm('microsoftAccountType') === 'yes'"
          formControlName="hasPermissionsOrGroups"
          aria-labelledby="has permissions or groups option radio buttons"
          class="radio-group nested-radio-group"
        >
          <mat-radio-button
            class="radio-button full-line-radio-button"
            value="yes"
          >
            I require on premise cloud permissions or active directory groups
          </mat-radio-button>
          <mat-radio-button
            class="radio-button full-line-radio-button"
            value="no"
          >
            I do not require on premise cloud permissions or active directory groups
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>

  <div class="recommendations-container">
    <div *ngIf="useAgilicusMicrosoftProvider()" class="use-agilicus-microsoft-provider">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you use the Agilicus managed Microsoft identity provider."
        imageSourcePath="assets/img/identify-users-uses-agilicus-microsoft.png"
        (imageClick)="onImageClick('shared-identity')"
      >
      </portal-getting-started-recommendations>
    </div>
    <div *ngIf="useAgilicusOtherProvider()" class="use-agilicus-other-provider">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you use one or more of the Google, Apple or Linkedin Agilicus managed identity providers."
        imageSourcePath="assets/img/identify-users-uses-agilicus-other.png"
        (imageClick)="onImageClick('shared-identity')"
      >
      </portal-getting-started-recommendations>
    </div>
    <div *ngIf="useOwnProvider()" class="use-own-provider">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you create your own identity provider."
        imageSourcePath="assets/img/identify-users-uses-own-provider.png"
        (imageClick)="onImageClick('custom-identity')"
      >
      </portal-getting-started-recommendations>
    </div>
  </div>
</div>