import { Action } from '@ngrx/store';
import { Organisation } from '@agilicus/angular';

export enum OrganisationsActionTypes {
  SET_ORG_STATE = '[Organisations] SetOrgState',
  LOAD_ORGS = '[Organisations] LoadOrgs',
  SWITCH = '[Organisations] Switch',
  CURRENT_ORG_CHANGED = '[Organisations] CurrentOrgChanged',
  SET_CURRENT_ORG = '[Organisations] SetCurrentOrg',
}

export class ActionOrganisationsSetState implements Action {
  public readonly type = OrganisationsActionTypes.SET_ORG_STATE;
  public readonly current_organisation: Organisation;
  public readonly sub_organisations: Array<Organisation>;
  constructor(current_organisation: Organisation, sub_organisations: Array<Organisation>) {
    this.current_organisation = current_organisation;
    this.sub_organisations = sub_organisations;
  }
}
export class ActionOrganisationsLoad implements Action {
  public readonly type = OrganisationsActionTypes.LOAD_ORGS;
  public readonly org_id: string;
  constructor(org_id: string) {
    this.org_id = org_id;
  }
}
export class ActionOrganisationsSetCurrentOrg implements Action {
  public readonly type = OrganisationsActionTypes.SET_CURRENT_ORG;
  public readonly current_organisation: Organisation;
  constructor(current_organisation: Organisation) {
    this.current_organisation = current_organisation;
  }
}
export type OrganisationsActions = ActionOrganisationsLoad | ActionOrganisationsSetState | ActionOrganisationsSetCurrentOrg;
