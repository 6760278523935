<portal-page-info
  pageTitle="Theming"
  [productGuideLink]="productGuideLink"
  [pageDescriptiveTextWithImageWrap]="pageDescriptiveTextWithImageWrap"
  [pageDescriptiveHelpImageWithTextWrap]="pageDescriptiveHelpImageWithTextWrap"
>
</portal-page-info>

<div class="authentication-group">

  <div *ngIf="hasIssuersPermissions !== undefined && !hasIssuersPermissions">
    <portal-no-permissions></portal-no-permissions>
  </div>

  <div *ngIf="hasIssuersPermissions">
    
    <div
      *ngIf="currentOrgIssuer === undefined && issuerCopy === undefined"
      class="no-info">
      <h2>No issuer information available.</h2>
    </div>

    <div
      *ngIf="currentOrgIssuer !== undefined"
      class="issuer-url title">
      <h5>Issuer: {{ currentOrgIssuer }}</h5>
    </div>

    <div *ngIf="issuerCopy !== undefined" class="issuer-container">

      <div class="theme section">
        (See <a href="https://agilicus.storage.googleapis.com/api/auth-theme.tar.gz">default</a> theme to customise)
        
        <div class="drop-area"
          portalDroppable (handleOnDrop)="onFileDrop($event)" padding="10px">

          <h3>Current Theme</h3>
          <form
            *ngIf="theme !== undefined"
            class="theme-form"
            [formGroup]="themeForm"
            spellcheck="false"
            autocomplete="off"
          >
            <div class="form-field-container">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Name"
                  formControlName="name"
                  readonly="true"
                  matTooltip="The file name is readonly"
                >
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Size"
                  formControlName="size"
                  readonly="true"
                  matTooltip="The file size is readonly"
                >
              </mat-form-field>

              <mat-form-field>
                <input
                  matInput
                  type="text"
                  placeholder="Last Updated"
                  formControlName="updated"
                  readonly="true"
                  matTooltip="The last updated date is readonly"
                >
              </mat-form-field>

            </div>
          </form>
        </div>

        <portal-upload-button
          class="upload-button-container"
          [isUploading]="isUploading"
          [buttonDescription]="buttonDescription"
          tooltipText="Click to upload a new theme"
          (readFile)="onReadFile($event)"
        >
        </portal-upload-button>

        <portal-download-button
          class="download-button-container"
          [buttonDescription]="buttonDescription"
          tooltipText="Click to download the current theme"
          (downloadFile)="downloadFile()"
          [disableButton]= "deletingCurrentTheme || !theme"
        >
        </portal-download-button>

        <div class="delete-button-wrapper">
          <button
            mat-raised-button
            color="warn"
            class="delete-button delete-theme-button"
            (click)="deleteTheme()"
            matTooltip="Click to delete the current theme"
            attr.aria-label="A button to delete the current theme"
            [disabled]="deletingCurrentTheme || !theme"
          >
            DELETE THEME
          </button>
        </div>

        <portal-progress-bar
          [progressBarController]="progressBarController"
        >
        </portal-progress-bar>

      </div>
    </div>
  </div>
</div>