/**
 * The main tasks that a user can complete to get started with using the admin portal
 */
export enum GettingStartedTask {
  completed_identify_users = 'completed_identify_users',
  completed_connect_to_sites = 'completed_connect_to_sites',
  completed_expose_resource = 'completed_expose_resource',
  completed_assign_permissions = 'completed_assign_permissions',
  completed_audit_user = 'completed_audit_user',
  completed_setup_payment = 'completed_setup_payment',
}
