import { DesktopResource, ApplicationServicesService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import {
  updateExistingDesktopResource,
  createNewDesktopResource,
  getDesktopResourceById,
  getDesktopResourcesList,
  deleteExistingDesktopResource,
} from '../application-service-state/application-services-utils';
import * as DesktopResourceActions from '../desktop-state/desktop.actions';

@Injectable({
  providedIn: 'root',
})
export class DesktopResourceStateService extends SimpleCRUDInterface<DesktopResource, string> {
  public crudRegistryName = CrudStateCollection.desktop;

  constructor(
    public store: Store<AppState>,
    private applicationsService: ApplicationServicesService,
    private crudRegistryService: CrudRegistryService<DesktopResource, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<DesktopResource, string> = {
        create: (id: string, obj: DesktopResource, orgId: string) => createNewDesktopResource(this.applicationsService, obj),
        update: (id: string, obj: DesktopResource, orgId: string) => updateExistingDesktopResource(this.applicationsService, obj),
        get: (id: string, orgId: string) => getDesktopResourceById(this.applicationsService, id, orgId),
        list: (orgId: string) => getDesktopResourcesList(this.applicationsService, orgId),
        delete: (id: string, orgId: string) => deleteExistingDesktopResource(this.applicationsService, id, orgId),
      };
      const crudRegistry: CrudRegistry<DesktopResource, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: DesktopResource) => obj.spec.name,
        doneSaveAction: DesktopResourceActions.upsertDesktopResource,
        doneSaveListAction: DesktopResourceActions.upsertDesktopResources,
        doneDeleteAction: DesktopResourceActions.deleteDesktopResource,
        doneDeleteListAction: DesktopResourceActions.deleteDesktopResources,
        setListAction: DesktopResourceActions.loadDesktopResources,
        refreshStateAction: DesktopResourceActions.refreshDesktopResources,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
