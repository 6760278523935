import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { DropdownSelector, createDropdownSelector } from './dropdown-selector-utils';

@Component({
  selector: 'portal-dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownSelectorComponent {
  @Input() public dropdownSelector: DropdownSelector = createDropdownSelector();
  @Input() public hasBlankOption = false;
  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  constructor() {}
}
