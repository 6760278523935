<form
  *ngIf="formGroup"
  [formGroup]="formGroup"
  spellcheck="false"
  autocomplete="off"
  [hidden]="hidden"
>
  <ng-container
    *ngFor="let column of getColumnDefs(columnDefs)"
  >
    <!-- INPUTS -->
    <span
      *ngIf="column.type === columnTypes.INPUT || column.type === columnTypes.READONLY"
      [ngClass]="{ 
        'no-tooltip-space': !tableLayout.getHeaderTooltipText(column, element),
        'start-new-line': column.startNewLine
      }"
    >
      <mat-form-field>
        <input
          matInput
          [type]="column.inputType"
          [placeholder]="column.displayName"
          [formControlName]="column.name"
          (blur)="tableLayout.onFormFieldEdit($event.target, element, column)"
          (keydown.enter)="tableLayout.preventDefaultKeyDownEvent($event)"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          #inputTarget
        />
        <span>
          <mat-icon
            *ngIf="column.inputType && column.inputType === 'password'"
            class="visibility-icon"
            [matTooltip]="tooltip"
            (click) = "tableLayout.changePasswordVisibility(column)"
          >
            visibility
          </mat-icon>

          <mat-icon
            *ngIf="column.inputType && column.inputType === 'text'"
            class="visibility-icon"
            [matTooltip]="tooltip"
            (click) = "tableLayout.changePasswordVisibility(column)"
          >
            visibility_off
          </mat-icon>
        </span>
        <span *ngIf="column.hasIconPrefix" matPrefix>
          <mat-icon 
            [ngClass]="{ 
              'success-color': column.getIconColor(element) === iconColor.success,
              'intermediate-color': column.getIconColor(element) === iconColor.intermediate,
              'warn-color': column.getIconColor(element) === iconColor.warn,
              'disabled-color': column.getIconColor(element) === iconColor.disabled
            }"
          >
            {{ column.getIconPrefix(element) }}
          </mat-icon>
        </span>
        <button
          *ngIf="column.copyToClipboard && column.getDisplayValue(element)"
          matSuffix
          mat-raised-button
          color="primary"
          class="copy-button"
          matTooltip="Copy '{{ inputTarget.value }}'"
          [ngxClipboard]="inputTarget"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
      <mat-icon
        *ngIf="!!tableLayout.getHeaderTooltipText(column, element)"
        [matTooltip]="tableLayout.getHeaderTooltipText(column, element)"
        class="column-tooltip-icon help-icon"
      >
        help_outline
      </mat-icon>
    </span>

    <!-- CHECKBOXES -->
    <span
      *ngIf="column.type === columnTypes.CHECK"
      [ngClass]="{ 
        'no-tooltip-space': !tableLayout.getHeaderTooltipText(column, element),
        'start-new-line': column.startNewLine
      }"
    >
      <mat-checkbox
        [formControlName]="column.name"
        (click)="$event.stopPropagation()"
        (change)="onCheckboxUpdate(column, $event.checked)"
        [checked]="column.isChecked(element)"
        [indeterminate]="column.isIndeterminate(element)"
        class="check-column-checkbox"
        [ngClass]="{ 'start-new-line': column.startNewLine }"
        (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        [matTooltip]="column.getTooltip(element, column)"
        [matTooltipDisabled]="!column.getTooltip(element, column)"
      >
        {{ column.displayName }}
      </mat-checkbox>
      <mat-icon
        *ngIf="!!tableLayout.getHeaderTooltipText(column, element)"
        [matTooltip]="tableLayout.getHeaderTooltipText(column, element)"
        class="column-tooltip-icon help-icon"
      >
        help_outline
      </mat-icon>
    </span>

    <!-- SELECTION DROPDOWNS -->
    <span
      *ngIf="column.type === columnTypes.SELECT"
      [ngClass]="{ 
        'no-tooltip-space': !tableLayout.getHeaderTooltipText(column, element),
        'start-new-line': column.startNewLine
       }"
    >
      <mat-form-field
        *ngIf="!column.hideOptions(element, column)"
      >
        <mat-label>{{ column.displayName }}</mat-label>
        <mat-select
          #select
          [formControlName]="column.name"
          (selectionChange)="
            onSelection({
              value: $event.value,
              column: column,
              element: element
            })
          "
          (openedChange)="tableLayout.onSelectionToggle($event, column, element)"
          (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select)"
          [matTooltip]="column.getTooltip(element)"
          [matTooltipDisabled]="column.getTooltip(element) === ''"
          [multiple] = "column.multiple"
        >
          <mat-option
            *ngFor="let value of tableLayout.getAllowedValues(column, element)"
            [value]="column.getOptionValue(value, element)"
            [disabled]="column.disableOption(element, column, value)"
          >
            {{ capitalizeFirstLetter(column.getOptionDisplayValue(value, element)) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon
        *ngIf="!!tableLayout.getHeaderTooltipText(column, element)"
        [matTooltip]="tableLayout.getHeaderTooltipText(column, element)"
        class="column-tooltip-icon help-icon"
      >
        help_outline
      </mat-icon>
    </span>
  </ng-container>
</form>
