const packageJson = require('../../package.json');

export const environment = {
  appName: 'Agilicus Portal',
  envName: 'production',
  production: true,
  test: false,
  overrideClientId: 'agilicus-builtin-portal',
  i18nPrefix: '',
  billing_accounts: [{ name: 'agilicus', billing_account_id: 'extkwTgfZQhQzmcwEMdTn9' }],
  tawk_key: 'f798a2dfc6b846a7a84df42c87c1f944cce3f82f',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free-webfonts'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress'],
  },
};
