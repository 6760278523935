export enum CspDirectiveEnum {
  default_src = 'default-src',
  base_uri = 'base-uri',
  child_src = 'child-src',
  connect_src = 'connect-src',
  font_src = 'font-src',
  form_action = 'form-action',
  frame_ancestors = 'frame-ancestors',
  frame_src = 'frame-src',
  img_src = 'img-src',
  manifest_src = 'manifest-src',
  media_src = 'media-src',
  navigate_to = 'navigate-to',
  object_src = 'object-src',
  prefetch_src = 'prefetch-src',
  script_src = 'script-src',
  script_src_attr = 'script-src-attr',
  script_src_elem = 'script-src-elem',
  style_src = 'style-src',
  style_src_attr = 'style-src-attr',
  style_src_elem = 'style-src-elem',
  worker_src = 'worker-src',
  // report_uri is not configurable by the user.
  report_uri = 'report-uri',
}
