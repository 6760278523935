import { UpstreamGroupMapping } from '@agilicus/angular';

export function getEmptyGroupMapping(upstreamName: string, orgId: string): UpstreamGroupMapping {
  return {
    spec: {
      upstream_issuer: upstreamName,
      org_id: orgId,
      group_mappings: [],
      excluded_groups: [],
    },
  };
}

export function getUpstreamGroupMappingIfUnset(
  upstreamGroupMapping: UpstreamGroupMapping,
  upstreamName: string,
  orgId: string
): UpstreamGroupMapping {
  if (!upstreamGroupMapping) {
    return getEmptyGroupMapping(upstreamName, orgId);
  }
  return upstreamGroupMapping;
}

export function getUpstreamGroupMappingPropertyIfUnset(
  upstreamGroupMapping: UpstreamGroupMapping,
  property: string,
  upstreamName: string,
  orgId: string
): UpstreamGroupMapping {
  const verifiedUpstreamGroupMapping = getUpstreamGroupMappingIfUnset(upstreamGroupMapping, upstreamName, orgId);
  if (!verifiedUpstreamGroupMapping.spec[property]) {
    return (verifiedUpstreamGroupMapping.spec[property] = getEmptyGroupMapping(upstreamName, orgId).spec[property]);
  }
  return verifiedUpstreamGroupMapping;
}

export function getMapAllGroupsUpstreamGroupName(): string {
  return '(.*)';
}

export function getMapAllGroupsAgilicusGroupName(): string {
  return '{0}';
}
