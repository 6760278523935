import {
  BillingCheckoutSession,
  CreateCheckoutSessionRequestParams,
  Feature,
  ListEmailDomainsRequestParams,
  Organisation,
  OrganisationsService,
  OrganisationStatus,
  ReconcileSubOrgIssuerRequestParams,
} from '@agilicus/angular';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, delay, expand, map, mergeMap, reduce } from 'rxjs/operators';

export enum OrgFeatures {
  lua_waf = 'lua_waf',
  hosted_applications = 'hosted_applications',
  managed_billing = 'managed_billing',
  policy_rules = 'policy_rules',
}

export function getEmailDomains$(organisationsService: OrganisationsService, orgId: string): Observable<Array<string>> {
  const params: ListEmailDomainsRequestParams = {
    org_id: orgId,
  };
  return organisationsService.listEmailDomains(params).pipe(
    map((resp) => {
      return resp.domains;
    }),
    catchError((_) => {
      return of([]);
    })
  );
}

export function updateSubOrgUniqueIssuer$(
  organisationsService: OrganisationsService,
  orgId: string,
  subOrgId: string,
  isUnique: boolean
): Observable<Organisation> {
  const params: ReconcileSubOrgIssuerRequestParams = {
    org_id: orgId,
    sub_org_id: subOrgId,
    ReconcileSubOrgIssuerRequest: {
      own_issuer: isUnique,
    },
  };
  return organisationsService.reconcileSubOrgIssuer(params);
}

export function getOrgFeatures$(organisationsService: OrganisationsService, orgId: string): Observable<Array<Feature>> {
  return organisationsService
    .getOrgFeatures({
      org_id: orgId,
    })
    .pipe(map((featureResp) => featureResp.features));
}

export function getOrgFeatureFlagMap$(organisationsService: OrganisationsService, orgId: string): Observable<Map<string, boolean>> {
  return getOrgFeatures$(organisationsService, orgId).pipe(
    map((featuresListResp) => {
      const featureKeyToEnabledMap = new Map();
      for (const feature of featuresListResp) {
        featureKeyToEnabledMap.set(feature.spec.key, feature.spec.value.enabled);
      }
      return featureKeyToEnabledMap;
    })
  );
}

export function pollForCompletion$(organisationsService: OrganisationsService, orgId: string): Observable<any> {
  // poll for completion /orgs/new_org/status
  return combineLatest([organisationsService.getOrgStatus({ org_id: orgId }), of(0)]).pipe(
    catchError(() => of([undefined, 0])), // continue to poll if there is an error
    expand(([status, count]: [OrganisationStatus, number]) => {
      if (!status?.all_up) {
        return organisationsService.getOrgStatus({ org_id: orgId }).pipe(
          delay(1000),
          mergeMap((resp) => {
            return of([resp, count]);
          }),
          catchError(() => {
            // exponentially increase delay and add a random number of milliseconds to prevent clients from sending
            // requests in synchronized waves
            return of([undefined, count + 1]).pipe(delay(1000 * (Math.pow(2, count) - 1) + Math.floor(Math.random() * 1000) + 1));
          })
        );
      }
      return EMPTY;
    }),
    reduce((_) => _)
  );
}

export function initiateBillingCheckoutSession$(
  organisationsService: OrganisationsService,
  orgId: string,
  returnUrl: string
): Observable<BillingCheckoutSession> {
  const params: CreateCheckoutSessionRequestParams = {
    org_id: orgId,
    CreateBillingCheckoutSession: {
      ui_mode: 'embedded',
      return_url: returnUrl,
    },
  };
  return organisationsService.createCheckoutSession(params);
}
