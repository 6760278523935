import { createSelector } from '@ngrx/store';
import { selectDesktopState } from '../core.state';
import { DesktopResourceState, selectAll, selectEntities } from './desktop.reducer';

export const selectDesktopResources = createSelector(selectDesktopState, (state: DesktopResourceState) => state);

export const selectDesktopResourceList = createSelector(selectDesktopResources, selectAll);

export const selectDesktopResourceEntities = createSelector(selectDesktopResources, selectEntities);

export const selectDesktopResourceEntity = (props: { id: string }) =>
  createSelector(selectDesktopResourceEntities, (entities) => {
    return entities[props.id];
  });

export const selectDesktopResourceRefreshDataValue = createSelector(
  selectDesktopResources,
  (state: DesktopResourceState) => state.refresh_data
);

export const selectDesktopResourceShouldPopulateValue = createSelector(
  selectDesktopResources,
  (state: DesktopResourceState) => state.should_populate
);

// Custom resource selectors:
export const selectDesktopResourceIconStatus = createSelector(selectDesktopResources, (state: DesktopResourceState) => state.icon_status);
