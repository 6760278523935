<div class="rule-body-container">
  <div class="title">
    <h2>Body</h2>

    <mat-form-field>
      <mat-select 
        #select
        (selectionChange)="currentBodyConstraintOptionSelection($event.option.value)" 
        value="{{ currentBodyConstraintOption }}"
        class="body-constraint-option-selector"
        (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select)"
        [disabled]="fixedTable"
      >
        <mat-option 
          *ngFor="let option of bodyConstraintOptions" 
          value="{{ option }}"
        >
          {{ capitalizeFirstLetter(option) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <portal-table-layout 
    [tableData]="tableData" 
    [columnDefs]="columnDefs" 
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyTableElement"
    [rowObjectName]="rowObjectName" 
    (updateEvent)="updateEvent($event)"
    (removeSelected)="deleteSelected($event)"
  >
  </portal-table-layout>
</div>
