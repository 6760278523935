<div *ngIf="showDemoAll" class="main-container">
  <div
    [ngClass]="{ 
      'demo-button-container': !showButtonsOnly,
      'demo-button-table-container': !!showButtonsOnly
     }"
  >
    <span *ngIf="!showButtonsOnly"><hr></span>
    <button
      *ngIf="showDemoCreateButton()"
      mat-raised-button
      color="primary"
      class="create-demo-button"
      matTooltip="Set up a demo environment"
      aria-label="A button to create demo environment"
      (click)="createDemo()"
      [disabled]="!enableDemoCreateButton()"
      [tourAnchor]="tourAnchorId"
    >
      {{ getCreateDemoButtonName() }}
    </button>

    <button
      *ngIf="showDemoDeleteButton()"
      mat-raised-button
      color="warn"
      matTooltip="Destroy the demo environment"
      aria-label="A button to destroy the demo environment"
      (click)="deleteDemo()"
      [disabled]="!enableDemoDeleteButton()"
    >
      {{ getDeleteDemoButtonName() }}
    </button>
    <span *ngIf="!showButtonsOnly">
      <br> <br>
      <p>
        For more information on the pre-canned demo, see <a href="https://www.agilicus.com/example/agilicus-anyx-demo/" target="_blank">Product Guide</a>
      </p>
    </span>
  </div>

  <portal-apply-app-model
    [appModelSubmissionProgressBarController]="createDemoProgressBarController"
    [appModelStatus]="demoStatus"
    [hideButton]="hideApplyButton"
    (resetModelStatus)="resetModelStatus()"
  >
  </portal-apply-app-model>
</div>