import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Connector } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadConnectors = createAction(
  '[Connector/API] Load Connectors',
  props<{ objs: Connector[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addConnector = createAction('[Connector/API] Add Connector', props<{ obj: Connector }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertConnector = createAction('[Connector/API] Upsert Connector', props<{ obj: Connector; refreshData: boolean }>());

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addConnectors = createAction('[Connector/API] Add Connectors', props<{ objs: Connector[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertConnectors = createAction('[Connector/API] Upsert Connectors', props<{ objs: Connector[]; refreshData: boolean }>());

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateConnector = createAction('[Connector/API] Update Connector', props<{ obj: Update<Connector> }>());

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateConnectors = createAction('[Connector/API] Update Connectors', props<{ objs: Update<Connector>[] }>());

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteConnector = createAction(
  '[Connector/API] Delete Connector',
  props<{ id: string; obj: Connector; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteConnectors = createAction('[Connector/API] Delete Connectors', props<{ ids: string[]; refreshData?: boolean }>());

/**
 * Will delete all data from the ngrx entity state
 */
export const clearConnectors = createAction('[Connector/API] Clear Connectors');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initConnectors = createAction('[Connector/API] Init Connectors', props<{ force: boolean; blankSlate: boolean }>());

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getConnectors = createAction('[Connector/API] Get Connector', props<{ org_id: string; blankSlate: boolean }>());

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingConnector = createAction(
  '[Connector/API] Saving Connector',
  props<{ obj: Connector; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingConnectors = createAction(
  '[Connector/API] Saving Connectors',
  props<{ objs: Array<Connector>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingConnector = createAction(
  '[Connector/API] Deleting Connector',
  props<{ obj: Connector; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingConnectors = createAction(
  '[Connector/API] Deleting Connectors',
  props<{ objs: Array<Connector>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshConnectors = createAction('[Connector/API] Refresh Connectors');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainConnectors = createAction('[Connector/API] Maintain Connectors');

/**
 * Will begin initializing the state data for the relevant data type and continue polling at set intervals
 */
export const initConnectorsPolling = createAction(
  '[Connector/API] Init Connectors Polling',
  props<{ force: boolean; blankSlate: boolean }>()
);

/**
 * Will stop polling for the state data.
 */
export const stopConnectorsPolling = createAction('[Connector/API] Stop Connectors Polling');
