import { DesktopResourceSpec, NetworkPortRange, NetworkServiceConfig } from '@agilicus/angular';

export function getDefaultNetworkServiceConfig(desktopType?: DesktopResourceSpec.DesktopTypeEnum): NetworkServiceConfig {
  let port = '22';
  if (desktopType === DesktopResourceSpec.DesktopTypeEnum.rdp) {
    port = '3389';
  }
  if (desktopType === DesktopResourceSpec.DesktopTypeEnum.vnc) {
    port = '5900';
  }
  return {
    ports: [
      {
        port,
        protocol: NetworkPortRange.ProtocolEnum.tcp,
      },
    ],
    dynamic_source_port_override: false,
  };
}
