<div class="proxy-location-container">
  <label
    id="proxy-location-radio-group-label"
    class="radio-button-group-label"
  >
    {{ label }}
  </label>
  <mat-radio-group
    [formControl]="formCtrl"
    aria-labelledby="proxy location selection radio buttons"
    class="proxy-location-radio-group"
  >
    <div
      *ngFor="let location of proxyLocationOptionData"
      class="full-line-radio-button"
    >
      <mat-radio-button
        class="proxy-location-radio-button"
        [value]="location.value"
        (change)="onProxyLocationOptionChange(location.value)"
      >
        {{location.displayValue}}
      </mat-radio-button>
      <mat-icon 
        class="help-icon" 
        [matTooltip]="location.tooltip"
      >
        help_outline
      </mat-icon>
    </div>
  </mat-radio-group>
</div>
