import { AccessOption } from './access-option.enum';
import {
  AccessOptionData,
  ApplicationModel,
  ApplicationModelAuthentication,
  ApplicationModelAuthorization,
  ApplicationModelHosting,
  ApplicationModelFqdnAliases,
  AuthFlowOptionData,
  AuthMethodOptionData,
  FqdnAliasOptionData,
  RuntimeOptionData,
  StartOptionData,
  UserAuthOptionData,
  VersionedApplicationModel,
  ProxyLocationOptionData,
} from '@app/core/models/application/application-model';
import { AuthFlowOption } from './auth-flow-option.enum';
import { AuthMethodOption } from './auth-method-option.enum';
import { RuntimeOption } from './runtime-option.enum';
import { UserAuthOption } from './user-auth-option.enum';
import {
  isAuthenticated,
  isAccessedFromCloud,
  isAccessedViaAgent,
  isAccessedViaVpn,
  isAuthenticatedViaProxy,
  isAuthenticatedViaSaml,
  isAuthenticatedViaOidc,
  isUserDefinedRolesEnabled,
  isUsingOwnRuntime,
  isUsingAgilicusRuntime,
  isHosting,
} from '../../core/models/application/application-model-utils';
import { MatStepper } from '@angular/material/stepper';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import { StartOption } from './start-option.enum';
import { DefaultStepperState } from './default-stepper-state';
import { FqdnAliasOption } from './fqdn-alias-option.enum';
import { Environment } from '@agilicus/angular';
import { StepperType } from './stepper-type.enum';

export function getStartOptionData(stepperType: StepperType, hasTemplates: boolean): Array<StartOptionData> {
  const startOptionData = [];
  if (stepperType === StepperType.desktop) {
    startOptionData.unshift(
      {
        value: StartOption.rdp,
        displayValue: `a new microsoft (RDP) remote ${stepperType}`,
      },
      {
        value: StartOption.vnc,
        displayValue: `a new VNC remote ${stepperType}`,
      }
    );
  } else {
    startOptionData.unshift({
      value: StartOption.new,
      displayValue: `a new ${stepperType}`,
    });
  }
  if (hasTemplates) {
    startOptionData.unshift({
      value: StartOption.template,
      displayValue: `the ${stepperType} from a template`,
    });
  }
  return startOptionData;
}

export function getFqdnAliasManualOptionDisplayValue(): string {
  return 'My application is accessed via an alternate hostname that I have setup manually';
}

export function getFqdnAliasOptionData(externalName: string): Array<FqdnAliasOptionData> {
  const fqdnAliasOptionData = [
    {
      value: FqdnAliasOption.auto,
      displayValue: `My application is accessed via ${externalName}`,
    },
    {
      value: FqdnAliasOption.manual,
      displayValue: getFqdnAliasManualOptionDisplayValue(),
    },
  ];
  return fqdnAliasOptionData;
}

export function getAccessOptionData(): Array<AccessOptionData> {
  return [
    {
      value: AccessOption.agent,
      displayValue: 'from my site via an onsite connector (on-prem)',
    },
    {
      value: AccessOption.internet,
      displayValue: 'over the public Internet (e.g. 3rd-party SaaS)',
    },
    {
      value: AccessOption.vpn,
      displayValue: 'from my site via a point-to-point VPN',
    },
    {
      value: AccessOption.cloud,
      displayValue: 'from the Agilicus Secure Cloud',
    },
  ];
}

export function getAuthFlowOptionData(): Array<AuthFlowOptionData> {
  return [
    {
      value: AuthFlowOption.none,
      displayValue: 'does not require authentication',
    },
    {
      value: AuthFlowOption.own,
      displayValue: 'participates in authentication',
    },
    {
      value: AuthFlowOption.proxy,
      displayValue: 'is authenticated by a proxy',
    },
  ];
}

export function getAuthMethodOptionData(): Array<AuthMethodOptionData> {
  return [
    {
      value: AuthMethodOption.saml,
      displayValue: 'SAML',
    },
    {
      value: AuthMethodOption.oidc,
      displayValue: 'OpenID Connect',
    },
  ];
}

export function getUserAuthOptionData(): Array<UserAuthOptionData> {
  return [
    {
      value: UserAuthOption.all_users,
      displayValue: 'has no named users (anyone on public internet)',
    },
    {
      value: UserAuthOption.all_org_users,
      displayValue: 'is used by all users in my organisation',
    },
    {
      value: UserAuthOption.single_role_users,
      displayValue: 'has named users with a single role (I will assign users later)',
    },
    {
      value: UserAuthOption.distinct_role_users,
      displayValue: 'has named users with distinct roles (I will assign users later)',
    },
    {
      value: UserAuthOption.all_org_users_plus_roles,
      displayValue: 'is used by all users in my organisation, but some users have distinct roles',
    },
  ];
}

export function getRuntimeOptionData(): Array<RuntimeOptionData> {
  return [
    {
      value: RuntimeOption.own,
      displayValue: 'own container',
    },
    {
      value: RuntimeOption.agilicus,
      displayValue: 'Agilicus container',
    },
  ];
}

export function getProxyLocationOptionData(): Array<ProxyLocationOptionData> {
  return [
    {
      value: Environment.ProxyLocationEnum.on_site,
      displayValue: 'End to End Transparent TLS',
      tooltip:
        'This mode enables a web application firewall that runs inside the secure connector which holds the TLS private key. Encryption is a single session from the user to the agent without the possibility of interception.',
    },
    {
      value: Environment.ProxyLocationEnum.in_cloud,
      displayValue: 'Fine Grained Access Logs, Enhanced Web Application Firewall',
      tooltip:
        'This mode enables a web application firewall with per http transaction diagnostic logging and OWASP top-10 path processing.',
    },
  ];
}

export function getAccessOptionDataFromAccessOption(targetAccessOption: AccessOption): AccessOptionData {
  return getAccessOptionData().find((accessOption) => accessOption.value === targetAccessOption);
}

export function getAuthFlowOptionDataFromAuthFlowOption(targetAuthFlowOption: AuthFlowOption): AuthFlowOptionData {
  return getAuthFlowOptionData().find((authFlowOption) => authFlowOption.value === targetAuthFlowOption);
}

export function getAuthMethodOptionDataFromAuthMethodOption(targetAuthMethodOption: AuthMethodOption): AuthMethodOptionData {
  return getAuthMethodOptionData().find((authMethodOption) => authMethodOption.value === targetAuthMethodOption);
}

export function getUserAuthOptionDataFromUserAuthOption(targetUserAuthOption: UserAuthOption): UserAuthOptionData {
  return getUserAuthOptionData().find((userAuthOption) => userAuthOption.value === targetUserAuthOption);
}

export function canConfigureRemapRules(appModel: ApplicationModel): boolean {
  // TODO: remove this when we implement the remap rules.
  // Hiding remap rules step until this functionality is implemented.
  return false;
  const selectedAccess = getAccessOptionValue(appModel.hosting);
  return !!selectedAccess && selectedAccess !== AccessOption.internet && selectedAccess !== AccessOption.cloud;
}

export function canConfigureRuntime(appModel: ApplicationModel): boolean {
  const selectedAccess = getAccessOptionValue(appModel.hosting);
  return selectedAccess === AccessOption.cloud;
}

export function canConfigureAuthUsers(appModel: ApplicationModel): boolean {
  const selectedAuthFlow = getAuthenticationOptionValue(appModel.authentication);
  return !!selectedAuthFlow && selectedAuthFlow !== AuthFlowOption.none;
}

export function canConfigureRolesAndRules(appModel: ApplicationModel): boolean {
  const selectedUserAuth = getAuthorizationOptionValue(appModel.authorization);
  return selectedUserAuth === UserAuthOption.distinct_role_users || selectedUserAuth === UserAuthOption.all_org_users_plus_roles;
}

export function getFqdnAliasOptionValue(appModelFqdnAliases: ApplicationModelFqdnAliases): FqdnAliasOption {
  if (!appModelFqdnAliases) {
    return undefined;
  }
  if (appModelFqdnAliases.enabled) {
    return FqdnAliasOption.manual;
  }
  return FqdnAliasOption.auto;
}

export function getAccessOptionValue(appModelHosting: ApplicationModelHosting): AccessOption {
  if (!appModelHosting) {
    return undefined;
  }
  if (isAccessedFromCloud(appModelHosting)) {
    return AccessOption.cloud;
  }
  if (isAccessedViaAgent(appModelHosting)) {
    return AccessOption.agent;
  }
  if (isAccessedViaVpn(appModelHosting)) {
    return AccessOption.vpn;
  }
  return AccessOption.internet;
}

export function getAuthenticationOptionValue(appModelAuthentication: ApplicationModelAuthentication): AuthFlowOption {
  // We default to proxy if everything is enabled.
  if (isAuthenticatedViaProxy(appModelAuthentication)) {
    return AuthFlowOption.proxy;
  }
  if (isAuthenticated(appModelAuthentication)) {
    return AuthFlowOption.own;
  }
  return AuthFlowOption.none;
}

export function getAuthenticationMethodOptionValue(appModelAuthentication: ApplicationModelAuthentication): AuthMethodOption | undefined {
  // We default to saml if everything is enabled.
  if (isAuthenticatedViaSaml(appModelAuthentication)) {
    return AuthMethodOption.saml;
  }
  if (isAuthenticatedViaOidc(appModelAuthentication)) {
    return AuthMethodOption.oidc;
  }
  return undefined;
}

export function getAuthorizationOptionValue(appModelAuthorization: ApplicationModelAuthorization): UserAuthOption | undefined {
  if (!appModelAuthorization) {
    return undefined;
  }
  if (isUserDefinedRolesEnabled(appModelAuthorization)) {
    if (appModelAuthorization.all_org_users) {
      return UserAuthOption.all_org_users_plus_roles;
    }
    return UserAuthOption.distinct_role_users;
  }
  if (appModelAuthorization.all_org_users) {
    return UserAuthOption.all_org_users;
  }
  if (appModelAuthorization.single_role_users) {
    return UserAuthOption.single_role_users;
  }
  if (appModelAuthorization.all_users) {
    return UserAuthOption.all_users;
  }
  return undefined;
}

export function getRuntimeOptionValue(appModelHosting: ApplicationModelHosting): RuntimeOption | undefined {
  if (!appModelHosting) {
    return undefined;
  }
  if (isUsingOwnRuntime(appModelHosting)) {
    return RuntimeOption.own;
  }
  if (isUsingAgilicusRuntime(appModelHosting)) {
    return RuntimeOption.agilicus;
  }
  return undefined;
}

export function disableProxyAuthFlowOption(appModelHosting: ApplicationModelHosting): boolean {
  return !isHosting(appModelHosting) || isUsingOwnRuntime(appModelHosting);
}

export function getVersionedApplicationModel(template: any): VersionedApplicationModel | undefined {
  const templateCopy = template as VersionedApplicationModel;
  if (!!templateCopy.version) {
    return templateCopy;
  }
  return undefined;
}

export function getUnversionedApplicationModel(template: any): ApplicationModel {
  return template as ApplicationModel;
}

export function delayStepperAdvanceOnSuccessfulApply(stepper: MatStepper, appModelStatus: ApplicationModelStatus): void {
  if (!stepper) {
    return;
  }
  // TODO: make changes to appModelStatus state so these checks are not required
  if (appModelStatus.complete && appModelStatus.save_success) {
    setTimeout(() => {
      stepper.next();
    }, 1000);
  }
}

/**
 * We need to set the delay so that the form controls are updated before we call next.
 */
export function delayStepperAdvanceToInProgressStep(stepper: MatStepper): void {
  setTimeout(() => {
    if (stepper && stepper._steps) {
      for (const {} of stepper._steps) {
        // This is will stop advancing at the first incomplete step.
        stepper.next();
      }
    }
  }, 500);
}

/**
 * This will reset the selectedStartOption when the user selects the first step.
 * This will reset the model of the stepper if the user selects the first step
 * and the current state of the model is 'complete'.
 */
export function handleStateOnFirstStepSelection<T extends DefaultStepperState>(
  selectedIndex: number,
  appModelStatus: ApplicationModelStatus,
  stepperState: T,
  resetModel: () => void,
  updateStepperState: () => void
): void {
  if (selectedIndex !== 0) {
    return;
  }
  if (!!stepperState) {
    stepperState.selectedStartOption = undefined;
  }
  if (appModelStatus.complete) {
    resetModel();
    return;
  }
  if (!!updateStepperState) {
    updateStepperState();
  }
}
