import { BasicCollectionState, IconStatus, ResourceBasicCollectionState } from '../basic-collection-state';

export function getDefaultBasicCollectionState<T>(params?: BasicCollectionState<T>): BasicCollectionState<T> {
  return {
    saving_state: false,
    should_populate: false,
    trigger_update_side_effects: false,
    refresh_data: 0,
    creating_new: false,
    ...params,
  };
}

export function getDefaultResourceBasicCollectionState<T>(params?: BasicCollectionState<T>): ResourceBasicCollectionState<T> {
  return {
    ...getDefaultBasicCollectionState(params),
    icon_status: getDefaultIconStatus(),
  };
}

export function getDefaultIconStatus(): IconStatus {
  return {
    saving_icon_file: false,
    icon_file_save_success: false,
    icon_file_save_fail: false,
  };
}
