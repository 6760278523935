import { Application, ApplicationConfig, CORSOrigin } from '@agilicus/angular';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { setCorsConfigPropertyIfUnset } from '../application-configs-utils';
import { FilterManager } from '../filter/filter-manager';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { Column, createInputColumn, createSelectRowColumn } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { updateTableElements } from '../utils';
import { Observable } from 'rxjs';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';

export interface CorsAllowOriginsElement extends CORSOrigin, TableElement {}

@Component({
  selector: 'portal-cors-allow-origins',
  templateUrl: './cors-allow-origins.component.html',
  styleUrls: ['./cors-allow-origins.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorsAllowOriginsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public currentApplicationCopy: Application;
  @Output() public updateApplication = new EventEmitter<any>();
  public tableData: Array<CorsAllowOriginsElement> = [];
  public columnDefs: Map<string, Column<CorsAllowOriginsElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'ALLOW ORIGIN';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
  }

  public ngOnChanges(): void {
    if (!this.currentApplicationCopy?.environments || this.currentApplicationCopy.environments.length === 0) {
      this.resetEmptyTable();
      return;
    }
    this.updateTable();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<CorsAllowOriginsElement> = [];
    const corsOrigins = this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http?.cors?.allow_origins;
    if (!!corsOrigins) {
      for (let i = 0; i < corsOrigins.length; i++) {
        data.push(this.createCorsAllowOriginsElement(corsOrigins[i], i));
      }
    }
    updateTableElements(this.tableData, data);
  }

  private createCorsAllowOriginsElement(corsOrigin: CORSOrigin, index: number): CorsAllowOriginsElement {
    const data: CorsAllowOriginsElement = {
      exact: '',
      ...getDefaultTableProperties(index),
      ...corsOrigin,
    };
    return data;
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const exactColumn = createInputColumn('exact');
    exactColumn.isEditable = true;
    exactColumn.requiredField = () => true;
    exactColumn.isValidEntry = (value: string) => {
      return value.length <= 512;
    };

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(exactColumn.name, exactColumn);
  }

  public makeEmptyTableElement(): CorsAllowOriginsElement {
    return {
      exact: '',
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Updates the config when a change is made in the table.
   */
  public updateEvent(): void {
    this.updateCorsAllowOrigins();
  }

  private removeElements(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
  }

  public deleteSelected(): void {
    this.removeElements();
    this.updateCorsAllowOrigins();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private updateCorsAllowOrigins(): void {
    const updatedAppConfigs = this.getUpdatedAppConfigs();
    if (!updatedAppConfigs) {
      return;
    }
    this.updateApplication.emit(updatedAppConfigs);
  }

  private getUpdatedAppConfigs(): ApplicationConfig | undefined {
    if (!this.currentApplicationCopy?.environments[0]) {
      return undefined;
    }
    const existingEnvironmentCopy = cloneDeep(this.currentApplicationCopy.environments[0]);
    setCorsConfigPropertyIfUnset(existingEnvironmentCopy, 'allow_origins');
    const updatedCorsAllowOrigins = this.getCorsAllowOriginsFromTable();
    existingEnvironmentCopy.application_configs.security.http.cors.allow_origins = updatedCorsAllowOrigins;
    return existingEnvironmentCopy.application_configs;
  }

  private getCorsAllowOriginsFromTable(): Array<CORSOrigin> {
    const corsAllowOrigins: Array<CORSOrigin> = [];
    for (const element of this.tableData) {
      corsAllowOrigins.push({ exact: element.exact });
    }
    return corsAllowOrigins;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }
}
