<label
  id="start-radio-group-label"
  class="radio-button-group-label"
>
  I will configure:
</label>
<div [formGroup]="startOptionFormGroup">
  <mat-radio-group
    formControlName="selectedStartOption"
    aria-labelledby="start option radio buttons"
    class="start-radio-group"
  >
    <mat-radio-button
      *ngFor="let option of startOptionData"
      class="start-radio-button full-line-radio-button"
      [value]="option.value"
      (change)="onStartOptionChange(option.value)"
      [disabled]="disableStartOption(option.value)"
    >
      {{option.displayValue}}
    </mat-radio-button>
  </mat-radio-group>
</div>