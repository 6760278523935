import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { getFile } from '../file-utils';

@Component({
  selector: 'portal-upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadAreaComponent {
  @Input() public iconUrl = '';
  @Input() public defaultText = '';
  @Input() public isUploading = false;
  @Input() public tooltipText = 'Click to select a file to upload';
  @Input() public missingImagePath = '../../assets/img/image-missing-warn.png';
  @Output() public uploadFile = new EventEmitter<any>();
  @Output() public handleFileDropError = new EventEmitter<any>();

  constructor() {}

  public onReadFile(event: any): void {
    const file = getFile(event);
    if (!file) {
      return;
    }
    this.uploadFile.emit(file);
  }

  public onFileDrop(event: any): void {
    const dt = event.dataTransfer;
    const files = dt.files;
    if (!files || files.length === 0) {
      return;
    }
    if (files.length !== 1) {
      this.handleFileDropError.emit();
      return;
    }
    this.uploadFile.emit(files[0]);
  }

  public getImageContainerStyle(): string {
    if (this.iconUrl === undefined) {
      return `background-image: url(${this.missingImagePath}); background-repeat:no-repeat; background-size:contain;`;
    }
    if (!!this.iconUrl) {
      return `background-image: url(${this.iconUrl}); background-repeat:no-repeat; background-size:contain;`;
    }
    return '';
  }
}
