import {
  Issuer,
  OIDCUpstreamIdentityProvider,
  AutoCreateStatus,
  LocalAuthUpstreamIdentityProvider,
  UpstreamGroupMapping,
  ApplicationUpstreamIdentityProvider,
} from '@agilicus/angular';
import {
  SharedUpstreamProviderProperties,
  UniqueUpstreamProviderProperties,
} from './upstream-provider-setup-dialog/upstream-provider-setup-dialog.component';
import { Column, createInputColumn, createCheckBoxColumn, CheckBoxColumn } from './table-layout/column-definitions';
import { TableElement } from './table-layout/table-element';
import { AppState } from '@app/core';
import { OptionalOIDCUpstreamIdentityProviderElement } from './optional-types';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ActionPolicyRefreshIssuer } from '@app/core/issuer/issuer.actions';

export interface ApplicationUpstreamIdentityProviderElement extends TableElement, ApplicationUpstreamIdentityProvider {
  backingObject: ApplicationUpstreamIdentityProvider;
  successful_response_code?: number;
  expected_cookies?: Array<string>;
  username_field?: string;
  password_field?: string;
}
export interface LocalAuthUpstreamIdentityProviderElement extends TableElement, LocalAuthUpstreamIdentityProvider {
  backingObject: LocalAuthUpstreamIdentityProvider;
}

export interface OIDCUpstreamIdentityProviderElement extends TableElement, OIDCUpstreamIdentityProvider {
  backingObject: OIDCUpstreamIdentityProvider;
  upstream_redirect_uri: string;
  iconFormControl?: UntypedFormControl;
}

export function getGroupMappingFromUpstreamIssuer(upstreamProviderName: string, issuerCopy: Issuer): UpstreamGroupMapping | undefined {
  for (const groupMapping of issuerCopy.upstream_group_mappings) {
    if (groupMapping.spec.upstream_issuer === upstreamProviderName) {
      return groupMapping;
    }
  }
  return undefined;
}

export function getGroupMappingFromUpstreamIdentityProvider(
  upstreamIdentityProvider: LocalAuthUpstreamIdentityProvider | OIDCUpstreamIdentityProvider,
  issuerCopy: Issuer
): UpstreamGroupMapping | undefined {
  const upstreamProviderName = upstreamIdentityProvider.name;
  return getGroupMappingFromUpstreamIssuer(upstreamProviderName, issuerCopy);
}

export function getUpstreamProviderUpdateSuccessMessage(upstreamProvider: string): string {
  return `Upstream provider "${upstreamProvider}" was successfully updated`;
}

export function getUpstreamProviderUpdateFailMessage(upstreamProvider: string): string {
  return `Failed to update upstream provider "${upstreamProvider}"`;
}

export function getDefaultUniqueUpstreamProviderProperties(): UniqueUpstreamProviderProperties {
  return {
    client_id: '',
    client_secret: '',
    issuer_external_host: '',
    username_key: '',
    email_key: '',
    user_id_key: '',
    email_verification_required: false,
    request_user_info: false,
    upstream_id: '',
    upstream_domain_name: '',
    validation: {
      successful_response_code: null,
      expected_cookies: [],
    },
    form_info: {
      username_field: '',
      password_field: '',
    },
  };
}

export function getDefaultSharedUpstreamProviderProperties(): SharedUpstreamProviderProperties {
  return {
    name: '',
    issuer: '',
    icon: '',
    auto_create_status: AutoCreateStatus.default,
    upstream_type: undefined,
  };
}

export function getAutoCreateStatusColumn(): CheckBoxColumn<
  ApplicationUpstreamIdentityProviderElement | OptionalOIDCUpstreamIdentityProviderElement | LocalAuthUpstreamIdentityProviderElement
> {
  const autoCreateStatusColumn = createCheckBoxColumn('auto_create_status');
  autoCreateStatusColumn.displayName = 'Auto Create';
  autoCreateStatusColumn.isEditable = true;
  autoCreateStatusColumn.isChecked = (element: OptionalOIDCUpstreamIdentityProviderElement) => {
    if (element.auto_create_status === AutoCreateStatus.active) {
      return true;
    }
    return false;
  };
  autoCreateStatusColumn.isIndeterminate = (element: OptionalOIDCUpstreamIdentityProviderElement) => {
    if (!element.auto_create_status) {
      return false;
    }
    if (element.auto_create_status !== AutoCreateStatus.active && element.auto_create_status !== AutoCreateStatus.default) {
      return true;
    }
    return false;
  };
  autoCreateStatusColumn.setElementFromCheckbox = (element: OptionalOIDCUpstreamIdentityProviderElement, isBoxChecked: boolean): any => {
    if (isBoxChecked) {
      element.auto_create_status = AutoCreateStatus.active;
    } else {
      element.auto_create_status = AutoCreateStatus.default;
    }
  };
  return autoCreateStatusColumn;
}

export function createEditableInputColumn(
  columnName: string
): Column<OIDCUpstreamIdentityProviderElement | LocalAuthUpstreamIdentityProviderElement | ApplicationUpstreamIdentityProviderElement> {
  const inputColumn = createInputColumn(columnName);
  inputColumn.requiredField = () => true;
  inputColumn.isEditable = true;
  return inputColumn;
}

export function refreshIssuer(store: Store<AppState>, issuerCopy: Issuer): void {
  store.dispatch(new ActionPolicyRefreshIssuer(issuerCopy));
}
