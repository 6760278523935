import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceType } from '@app/shared/components/resource-type.enum';
import { getResourceTypeIcon } from '@app/shared/components/resource-utils';
import { scrollToTop } from '@app/shared/components/utils';

export interface TaskBoardData {
  title: string;
  icon: string;
  objectives: Array<string>;
  productLink: string;
  route: string;
}

@Component({
  selector: 'portal-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskBoardComponent implements OnInit {
  @Input() public orgId: string;
  public productGuideLink = 'https://www.agilicus.com/anyx-guide/';
  public dataSource: Array<TaskBoardData> = [
    {
      title: 'Connect to a private network',
      icon: 'dns',
      objectives: [
        'Expose services over a point to point VPN',
        'Expose services using an outbound connector',
        'Understand which services are exposed and by what',
      ],
      productLink: 'https://www.agilicus.com/anyx-guide/agilicus-connector/',
      route: 'connector-new',
    },
    {
      title: 'Share a directory',
      icon: getResourceTypeIcon(ResourceType.fileshare),
      objectives: [
        'Share to users on other networks',
        'Share to users on other identity providers',
        'Share to users in a browser',
        'Share to users on mac/pc desktops',
      ],
      productLink: 'https://www.agilicus.com/anyx-guide/product-guide-shares/',
      route: 'shares-new',
    },
    {
      title: 'Share a desktop',
      icon: getResourceTypeIcon(ResourceType.desktop),
      objectives: [
        'Share to users in other networks',
        'Share to users on other identity providers',
        'Share to users on different platforms',
      ],
      productLink: 'https://www.agilicus.com/anyx-guide/zero-trust-desktop-access/',
      route: 'desktop-new',
    },
    {
      title: 'Share a web application',
      icon: getResourceTypeIcon(ResourceType.application),
      objectives: ['Share to users in other networks', 'Secure using best practices', 'Protect with a web application firewall'],
      productLink: 'https://www.agilicus.com/anyx-guide/product-guide-applications/',
      route: 'application-new',
    },
    {
      title: 'Host a web application',
      icon: getResourceTypeIcon(ResourceType.application),
      objectives: ['Share to users in other networks', 'Secure using best practices', 'Define which roles can access what'],
      productLink: 'https://www.agilicus.com/anyx-guide/product-guide-applications/',
      route: 'application-define',
    },

    {
      title: 'Diagnose a user',
      icon: 'person_search',
      objectives: ['Audit a user', `Troubleshoot a user's access`, `Troubleshoot a user's login`],
      productLink: 'https://www.agilicus.com/anyx-guide/users/',
      route: 'user-audits',
    },
    {
      title: 'Trust Identity Provider',
      icon: 'enhanced_encryption',
      objectives: [
        'Sign in with Google',
        'Sign in with Apple',
        'Sign in with Microsoft',
        'Sign in with Active Directory',
        'Sign in with Yahoo',
      ],
      productLink: 'https://www.agilicus.com/anyx-guide/identity-and-authentication/',
      route: 'shared-identity',
    },
    {
      title: 'Assign permissions to a user or group',
      icon: 'perm_identity',
      objectives: ['Control user access', 'Understand who has access'],
      productLink: 'https://www.agilicus.com/anyx-guide/permissions/',
      route: 'resource-permissions-admin',
    },
    {
      title: 'Grant access to a user request',
      icon: 'verified_user',
      objectives: ['See who wants access to resources', 'Respond to requests for access to resources'],
      productLink: 'https://www.agilicus.com/anyx-guide/application-request-access/',
      route: 'resource-access-requests',
    },
  ];

  constructor(private router: Router) {}

  public ngOnInit(): void {
    this.scrollToTop();
  }

  public onCardHeaderClick(data: TaskBoardData): void {
    this.routeToInternalLink(data.route);
  }

  private routeToInternalLink(route: string): void {
    this.router.navigate([route], {
      queryParams: { org_id: this.orgId },
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Scrolls to the top of the page.
   */
  public scrollToTop(): void {
    scrollToTop();
  }
}
