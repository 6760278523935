import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ConnectorActions from './connector.actions';
import { Connector } from '@agilicus/angular';
import { BasicCollectionState } from '../basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';

export const connectorsFeatureKey = 'connector';

export type ConnectorState = BasicCollectionState<Connector>;

export const adapter: EntityAdapter<Connector> = createEntityAdapter<Connector>({
  sortComparer: sortListByCreatedDate,
  selectId: (connector) => connector.metadata.id,
});

export const initialState: ConnectorState = adapter.getInitialState(getDefaultBasicCollectionState<Connector>());

export const connectorReducer = createReducer(
  initialState,
  on(ConnectorActions.addConnector, (state, action) => adapter.addOne(action.obj, state)),
  on(ConnectorActions.upsertConnector, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(ConnectorActions.addConnectors, (state, action) => adapter.addMany(action.objs, state)),
  on(ConnectorActions.upsertConnectors, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(ConnectorActions.updateConnector, (state, action) => adapter.updateOne(action.obj, state)),
  on(ConnectorActions.updateConnectors, (state, action) => adapter.updateMany(action.objs, state)),
  on(ConnectorActions.deleteConnector, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(ConnectorActions.deleteConnectors, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(ConnectorActions.loadConnectors, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(ConnectorActions.clearConnectors, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(ConnectorActions.savingConnector, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(ConnectorActions.savingConnectors, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(ConnectorActions.initConnectors, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(ConnectorActions.refreshConnectors, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
