<div class="table-controls">
  <portal-upload-button
    [isUploading]="isUploading"
    [buttonDescription]="buttonDescription"
    [tooltipText]="uploadButtonTooltipText"
    (readFile)="onReadGroups($event)"
  >
  </portal-upload-button>

  <portal-download-button
    [buttonDescription]="buttonDescription"
    tooltipText="Click to download groups"
    (downloadFile)="downloadGroups()"
  >
  </portal-download-button>
  
  <div class="flex-div"></div>
</div>

<portal-progress-bar 
  [progressBarController]="progressBarController"
>
</portal-progress-bar>
