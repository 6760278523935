import { Injectable, Renderer2 } from '@angular/core';
import { FileSummary, GetDownloadRequestParams, FilesService, DesktopServerConfiguration } from '@agilicus/angular';
import { b64toBlob, downloadBlob } from '@app/shared/components/file-utils';

@Injectable({
  providedIn: 'root',
})
export class FileHelperService {
  constructor(private filesService: FilesService) {}

  public downloadToBrowser(file: FileSummary, orgId: string, renderer: Renderer2): void {
    const link = renderer.createElement('a');
    const args: GetDownloadRequestParams = { file_id: file.id, org_id: orgId };
    const downloadedBlob$ = this.filesService.getDownload(args);
    downloadedBlob$.subscribe((blobResp) => {
      link.href = window.URL.createObjectURL(blobResp);
      link.download = file.name;
      link.click();
    });
  }

  public downloadDesktopConfigToBrowser(config: DesktopServerConfiguration, desktopName: string, renderer: Renderer2): void {
    const blob = b64toBlob(config.generated_config.configuration_file, config.generated_config.configuration_file_media_type);
    downloadBlob(blob, renderer, 'reg', `${desktopName}-server-config`);
  }
}
