import { createSelector } from '@ngrx/store';
import { selectInboxItemState } from '../core.state';
import { InboxItemState, selectAll, selectEntities } from './inbox-item.reducer';

export const selectInboxItems = createSelector(selectInboxItemState, (state: InboxItemState) => state);

export const selectInboxItemList = createSelector(selectInboxItems, selectAll);

export const selectInboxItemEntities = createSelector(selectInboxItems, selectEntities);

export const selectInboxItemEntity = (props: { id: string }) =>
  createSelector(selectInboxItemEntities, (entities) => {
    return entities[props.id];
  });

export const selectInboxItemRefreshDataValue = createSelector(selectInboxItems, (state: InboxItemState) => state.refresh_data);

export const selectInboxItemShouldPopulateValue = createSelector(selectInboxItems, (state: InboxItemState) => state.should_populate);

// special case selectors:

export const selectInboxItemUnreadCount = createSelector(selectInboxItems, (state: InboxItemState) => state.unreadInboxItemCount);
