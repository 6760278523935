import { NgModule } from '@angular/core';
import { TableButtonComponent } from './table-button/table-button.component';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { UploadButtonComponent } from './upload-button/upload-button.component';
import { TableControlButtonsComponent } from '../table-control-buttons/table-control-buttons.component';
import { SharedModule } from '../..';

@NgModule({
  imports: [SharedModule],
  declarations: [DownloadButtonComponent, UploadButtonComponent, TableButtonComponent, TableControlButtonsComponent],
  exports: [DownloadButtonComponent, UploadButtonComponent, TableButtonComponent, TableControlButtonsComponent],
})
export class ButtonsModule {}
