<div class="upload-button-wrapper">
  <button
    mat-raised-button
    color="primary"
    class="upload-button"
    [disabled]="isUploading || disableUpload"
    (click)="onUploadClickFunc()"
  >
    UPLOAD {{ buttonDescription }} <mat-icon class="upload-icon">cloud_upload</mat-icon>
  </button>
  <input
    *ngIf="!preventUpload && !disableUpload"
    type="file"
    name="upload-file"
    id="input-file"
    title=" "
    (change)="onReadFile($event)"
    [matTooltip]="tooltipText"
    aria-label="Input that uploads a file"
    [disabled]="isUploading"
    portalDisableKeyTab
  />
</div>
