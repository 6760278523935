import { getSideNavMenu } from '@app/shared/components/submenu/side-nav-menu-definitions';
import { getDefaultUIModelState } from '../models/ui/ui-model.utils';
import { UIActions, UIActionTypes } from './ui.actions';
import { AppDefineExpansionPanel, ExpansionPanelType, TabGroup, UIState } from './ui.models';

export const uiInitialState: UIState = getDefaultUIModelState();

export function uiReducer(state: UIState = uiInitialState, action: UIActions): UIState {
  switch (action.type) {
    case UIActionTypes.UPDATE_EXPANSION_PANELS_STATE:
      return {
        ...state,
        expansionPanelsState: action.expansionPanelsState,
      };
    case UIActionTypes.UPDATE_TABS_STATE:
      return {
        ...state,
        tabsState: action.tabsState,
        refresh_data: !!action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      };
    case UIActionTypes.INIT_APP_DEFINE_UI_STATE:
      return {
        ...state,
        expansionPanelsState: {
          ...state.expansionPanelsState,
          panels: {
            ...state.expansionPanelsState.panels,
            [AppDefineExpansionPanel.appDefineInstancesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineInstancesPanel,
              state,
              true
            ),
            [AppDefineExpansionPanel.appDefineBundlesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineBundlesPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineApplicationRolesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineApplicationRolesPanel,
              state,
              true
            ),
            [AppDefineExpansionPanel.appDefineFirewallRulesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineFirewallRulesPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineWebApplicationSecurityPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineWebApplicationSecurityPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineHttpRewritesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineHttpRewritesPanel,
              state,
              true
            ),
            [AppDefineExpansionPanel.appDefineProxiedServiceConfigPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineProxiedServiceConfigPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineRewriteMediaTypesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineRewriteMediaTypesPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineRewriteRulesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineRewriteRulesPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineResponseHeaderOverridesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineResponseHeaderOverridesPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineRequestHeaderOverridesPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineRequestHeaderOverridesPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineAuthenticationPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineAuthenticationPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineSecurityHeadersPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineSecurityHeadersPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineContentSecurityPolicyPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineContentSecurityPolicyPanel,
              state,
              false
            ),
            [AppDefineExpansionPanel.appDefineCorsPanel]: getExpansionPanelInitState(
              AppDefineExpansionPanel.appDefineCorsPanel,
              state,
              false
            ),
          },
        },
        tabsState: {
          ...state.tabsState,
          tabs: {
            ...state.tabsState.tabs,
            [TabGroup.appDefineTabGroup]: getTabInitState(TabGroup.appDefineTabGroup, state),
          },
        },
      };
    case UIActionTypes.INIT_CONFIGURE_INSTANCE_UI_STATE:
      return {
        ...state,
        tabsState: {
          ...state.tabsState,
          tabs: {
            ...state.tabsState.tabs,
            [TabGroup.appConfigureInstanceTabGroup]: getTabInitState(TabGroup.appConfigureInstanceTabGroup, state),
          },
        },
      };
    case UIActionTypes.INIT_CONNECTOR_INSTALL_UI_STATE:
      return {
        ...state,
        tabsState: {
          ...state.tabsState,
          tabs: {
            ...state.tabsState.tabs,
            [TabGroup.connectorInstallTabGroup]: getTabInitState(TabGroup.connectorInstallTabGroup, state),
          },
        },
      };
    case UIActionTypes.SET_SIDE_NAV_MENU_UI_STATE:
      return {
        ...state,
        navMenuState: {
          ...state.navMenuState,
          navMenuItemsList: getSideNavMenu(),
          isSideNavLoaded: true,
        },
      };
    case UIActionTypes.RESET_SIDE_NAV_MENU_UI_STATE:
      return {
        ...state,
        navMenuState: {
          ...getDefaultUIModelState().navMenuState,
        },
      };
    case UIActionTypes.UPDATE_SIDE_NAV_MENU_ITEM_LIST:
      return {
        ...state,
        navMenuState: {
          ...state.navMenuState,
          navMenuItemsList: action.navMenuItemList,
        },
      };
    case UIActionTypes.SET_VERSION:
      return {
        ...state,
        version: action.version,
        refresh_data: state.refresh_data + 1,
      };
    case UIActionTypes.UPDATE_ALERTS_STATE:
      return {
        ...state,
        alertsState: action.alertsState,
      };
    case UIActionTypes.UPDATE_ALERTS_BALANCE_END_DATE_STATE:
      return {
        ...state,
        alertsState: {
          ...state.alertsState,
          balanceEndDate: !!action.alerted,
        },
      };
    case UIActionTypes.UPDATE_ALERTS_CURRENT_USAGE_STATE:
      return {
        ...state,
        alertsState: {
          ...state.alertsState,
          currentUsage: !!action.alerted,
        },
      };
    case UIActionTypes.REFRESH_UI_STATE:
      return {
        ...state,
        refresh_data: state.refresh_data + 1,
      };
    // The following action types fall to the default:
    // LOAD_SIDE_NAV_MENU_UI_STATE
    // UPDATE_SIDE_NAV_MENU_UI_STATE
    // UPDATE_SIDE_NAV_MENU_ITEM
    // OPEN_SIDE_NAV_MENU_TARGET_ROUTE
    // MAINTAIN_UI_STATE
    default:
      return state;
  }
}

/**
 * If the expansion panel state has not been set then initialise it, otherwise do not change
 */
function getExpansionPanelInitState(expansionPanelType: ExpansionPanelType, state: UIState, openOnInit: boolean): boolean {
  return state.expansionPanelsState.panels[expansionPanelType] !== undefined
    ? state.expansionPanelsState.panels[expansionPanelType]
    : openOnInit;
}

/**
 * If the tab state has not been set then initialise it to index 0, otherwise do not change
 */
function getTabInitState(tabGroup: TabGroup, state: UIState): number {
  return state.tabsState.tabs[tabGroup] !== undefined ? state.tabsState.tabs[tabGroup] : 0;
}
