import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ButtonType } from '../button-type.enum';
import { pluralizeString } from '../utils';

@Component({
  selector: 'portal-table-control-buttons',
  templateUrl: './table-control-buttons.component.html',
  styleUrls: ['./table-control-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableControlButtonsComponent {
  @Input() public rowObjectName = '';
  @Input() public isAtLeastOneSelected = false;
  @Input() public doesNewRowExist = false;
  @Input() public disableAddNewRow = false;
  @Input() public buttonsToShow: Array<ButtonType> = [ButtonType.ADD, ButtonType.DELETE];
  @Input() public isUploading = false;
  @Input() public resetButtonTooltipText = '';
  @Input() public doNotPluralizeRowObjectName = false;
  @Output() private uploadFile = new EventEmitter<any>();
  @Output() private addNewRowToTable = new EventEmitter<any>();
  @Output() private addNewEntryFocus = new EventEmitter<any>();
  @Output() private deleteRows = new EventEmitter<any>();
  @Output() private enableRows = new EventEmitter<any>();
  @Output() private disableRows = new EventEmitter<any>();
  @Output() private resetRows = new EventEmitter<any>();
  @Output() private approveRows = new EventEmitter<any>();
  @Output() private rejectRows = new EventEmitter<any>();

  // This is required in order to reference the enum in the html template.
  public buttonType = ButtonType;

  constructor() {}

  public uploadFileEvent(event: any): void {
    this.uploadFile.emit(event);
  }

  public addNewRowToTableEvent(): void {
    this.addNewRowToTable.emit();
  }

  public addNewEntryFocusEvent(): void {
    this.addNewEntryFocus.emit();
  }

  public deleteRowsEvent(): void {
    this.deleteRows.emit();
  }

  public enableRowsEvent(): void {
    this.enableRows.emit();
  }

  public disableRowsEvent(): void {
    this.disableRows.emit();
  }

  public resetRowsEvent(): void {
    this.resetRows.emit();
  }

  public approveRowsEvent(): void {
    this.approveRows.emit();
  }

  public rejectRowsEvent(): void {
    this.rejectRows.emit();
  }

  public showButton(buttonType: ButtonType): boolean {
    if (!this.buttonsToShow) {
      return false;
    }
    for (const type of this.buttonsToShow) {
      if (type === buttonType) {
        return true;
      }
    }
    return false;
  }

  public pluralizeRowObjectName(): string {
    if (this.doNotPluralizeRowObjectName) {
      return this.rowObjectName;
    }
    return pluralizeString(this.rowObjectName);
  }

  public getResetButtonTooltipText(): string {
    if (!!this.resetButtonTooltipText) {
      return this.resetButtonTooltipText;
    }
    return 'Reset ' + this.pluralizeRowObjectName().toLowerCase();
  }
}
