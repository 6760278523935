export enum UsersFilterLabel {
  NOLABEL = '',
  USER = 'User Status',
  MFAON = 'User Multi-Factor Enrollment On',
  MFAOFF = 'User Multi-Factor Enrollment Off',
  AUTOCREATED = 'Auto-Created Status',
  EMAIL_DOMAIN = 'Email Domain',
  EMAIL = 'Email',
  LAST_NAME = 'Last Name',
  FIRST_NAME = 'First Name',
  GROUP_NAME = 'Group Name',
}
