import { BillingProduct, BillingSubscriptionUsageOverrideItem, Organisation, OrganisationsService, UsageMetric } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppState } from '@app/core';
import { initBillingAccountFull } from '@app/core/billing-state/billing-account-full.actions';
import { selectCurrentBillingAccountFull } from '@app/core/billing-state/billing-account-full.selectors';
import { BillingAccountFull } from '@app/core/models/billing/billing-account-full';
import {
  canOrgFetchDataFromApi,
  convertCentsToReadableDollarsString,
  getCurrencyDisplayValue,
  getEmptyBillingAccountFull,
  getFormatedBillingDateString,
  getSubscriptionButtonText,
  noPaymentOnFile,
  shouldWarnOnCurrentUsageData,
  warnOnEstimateBalanceEndDate,
} from '@app/core/billing-state/billing-api-utils';
import { BillingPaymentStatus } from '@app/core/models/billing/billing-payment-status.enum';
import { BillingProductFull } from '@app/core/models/billing/billing-product-full';
import { selectCanAdminOrReadUsers, selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { concatMap, switchMap, takeUntil } from 'rxjs/operators';
import { convertDateToReadableFormat, convertDateToShortReadableFormat } from '../date-utils';
import { capitalizeFirstLetter, replaceCharacterWithSpace } from '../utils';
import { Column, createExpandColumn, createReadonlyColumn, ReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import { InputData } from '../custom-chiplist-input/input-data';
import { CheckboxOption, FilterManager } from '../filter/filter-manager';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { BillingSubscriptionStripe } from '@app/core/models/billing/billing-subscription-full';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { TableElement } from '../table-layout/table-element';
import { getDefaultNestedDataProperties, getDefaultTableProperties } from '../table-layout-utils';
import { OptionalTableElement } from '../optional-types';
import { MapObject, UnparseData } from 'ngx-papaparse/lib/interfaces/unparse-data';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { BillingUpcomingInvoice } from '@app/core/models/billing/billing-upcoming-invoice';
import { BillingSubscriptionFull } from '@app/core/models/billing/billing-subscription-full';
import { getOrgFeatureFlagMap$, OrgFeatures } from '@app/core/api/organisations/organisations-api.utils';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { FilterType } from '../filter-type.enum';
import { selectCurrentOrg } from '@app/core/user/user.selectors';
import { PaymentService } from '@app/core/payment-service/payment.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StripeCheckoutDialogComponent, StripeCheckoutDialogData } from '../stripe-checkout-dialog/stripe-checkout-dialog.component';
import { getDefaultDialogConfig } from '../dialog-utils';
import { MatDialogRef } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';

export interface SubscriptionDisplayData {
  product_name: string;
  start_date: number;
  last_invoice_date: number;
  next_invoice_date: number;
  trial_active: boolean;
  trial_end_date?: number | null;
  payment_status: BillingPaymentStatus;
  products_overview: string;
  orgs: Array<Organisation>;
  usage_override: Array<BillingSubscriptionUsageOverrideItem>;
  usage_metrics: Array<UsageMetric>;
  subscription_balance: number;
  estimate_balance_end_date: Date;
  amount_due: number;
  total: number;
  currency: string;
}

export interface SubscriptionDisplayElement extends SubscriptionDisplayData, TableElement {}

export interface UsageMetricElement extends InputData, OptionalTableElement {
  parentId: string | number;
  usageMetric: UsageMetric | undefined;
  usageOverride: BillingSubscriptionUsageOverrideItem | undefined;
}

@Component({
  selector: 'portal-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasUsersReadPermissions: boolean;
  public hasUsersOwnerPermissions: boolean;
  private orgId: string;
  public billingInfoForm: UntypedFormGroup;
  public billingAccountInfo: BillingAccountFull | undefined;
  public subscriptionButtonText = getSubscriptionButtonText();
  private productIdToNameMap: Map<string, string> = new Map();
  /**
   * This is also the tableData
   */
  public subscriptionDisplayData: Array<SubscriptionDisplayElement> = [];
  public columnDefs: Map<string, Column<SubscriptionDisplayElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'status',
      {
        name: 'status',
        displayName: 'Payment Status',
        icon: 'payment',
        type: FilterMenuOptionType.checkbox,
      },
    ],
  ]);
  public hideFilter = false;
  private orgFeatureKeyToEnabledMap: Map<string, boolean> = new Map();
  private showCancelledSubsName = 'Show cancelled subscriptions';
  private showCancelledSubscriptions = false;

  public convertDateToReadableFormat = convertDateToReadableFormat;
  public capitalizeFirstLetter = capitalizeFirstLetter;
  public getFormatedBillingDateString = getFormatedBillingDateString;
  public convertCentsToReadableDollarsString = convertCentsToReadableDollarsString;
  public convertDateToShortReadableFormat = convertDateToShortReadableFormat;
  public getCurrencyDisplayValue = getCurrencyDisplayValue;
  public warnOnEstimateBalanceEndDate = warnOnEstimateBalanceEndDate;
  public noPaymentOnFile = noPaymentOnFile;

  public productGuideLink = `https://www.agilicus.com/anyx-guide/billing/`;

  currentDialog: MatDialogRef<any> | undefined = undefined;
  private dialogOpenedByUser = false;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private organisationsService: OrganisationsService,
    private renderer: Renderer2,
    private papa: Papa,
    private paymentService: PaymentService,
    public dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.filterManager.addCheckboxFilterOption({
      name: this.showCancelledSubsName,
      displayName: this.showCancelledSubsName,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleCancelledSubscriptions.bind(this),
    });
  }

  public getPageDescriptiveText(): string {
    if (!this.billingAccountInfo) {
      return `See usage metrics contributing towards the invoice`;
    }
    return `Configure payment information, invoices, and see usage metrics contributing towards the invoice`;
  }

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.store.dispatch(initBillingAccountFull({ force: true, blankSlate: false }));
    this.initializeFormGroup();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPermissionsAndOrgData$(): Observable<[OrgQualifiedPermission, OrgQualifiedPermission, Map<string, boolean>]> {
    const hasUsersReadPermissions$ = this.store.pipe(select(selectCanAdminOrReadUsers));
    const hasUsersOwnerPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrg));
    return combineLatest([hasUsersReadPermissions$, hasUsersOwnerPermissions$, currentOrg$]).pipe(
      concatMap(([hasUsersReadPermissionsResp, hasUsersOwnerPermissionsResp, currentOrgResp]) => {
        if (!this.billingAccountInfo && canOrgFetchDataFromApi(currentOrgResp)) {
          // Set to empty so the screen does not remain blank before we receive the billing data from the api:
          this.billingAccountInfo = getEmptyBillingAccountFull();
        }
        let features$: Observable<Map<string, boolean> | undefined> = of(undefined);
        if (hasUsersReadPermissionsResp?.orgId) {
          features$ = getOrgFeatureFlagMap$(this.organisationsService, hasUsersReadPermissionsResp.orgId);
        }
        return combineLatest([of(hasUsersReadPermissionsResp), of(hasUsersOwnerPermissionsResp), features$]);
      })
    );
  }

  private getAllData$(): Observable<[[OrgQualifiedPermission, OrgQualifiedPermission, Map<string, boolean>], BillingAccountFull]> {
    const permissionsAndOrgData$ = this.getPermissionsAndOrgData$();
    const currentBillingAccountFull$ = this.store.pipe(select(selectCurrentBillingAccountFull));
    return combineLatest([permissionsAndOrgData$, currentBillingAccountFull$]);
  }

  private getAndSetAllData(): void {
    this.unsubscribe$.next();
    this.getAllData$()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(([permissionsAndOrgDataResp, currentBillingAccountFullResp]) => {
          this.orgId = permissionsAndOrgDataResp[0].orgId;
          this.hasUsersReadPermissions = permissionsAndOrgDataResp[0].hasPermission;
          this.hasUsersOwnerPermissions = permissionsAndOrgDataResp[1].hasPermission;
          this.orgFeatureKeyToEnabledMap = !!permissionsAndOrgDataResp[2] ? permissionsAndOrgDataResp[2] : new Map();
          if (!this.orgId) {
            return [];
          }
          if (!!currentBillingAccountFullResp) {
            this.billingAccountInfo = currentBillingAccountFullResp;
          }
          this.setupData();

          this.changeDetector.detectChanges();

          return this.activatedRoute.queryParams;
        })
      )
      .subscribe((params) => {
        const orgId = params['org_id'];
        if (params['checkoutdialog'] === 'true' && orgId && this.hasUsersReadPermissions && !this.dialogOpenedByUser) {
          this.openStripeCheckoutDialog(false, orgId);
        }
      });
  }

  private setProductIdToNameMap(): void {
    this.productIdToNameMap.clear();
    if (!this.billingAccountInfo?.status?.products) {
      return;
    }
    for (const product of this.billingAccountInfo.status.products) {
      if (!product) {
        // A value of null is currently possible
        continue;
      }
      const productFull = product as BillingProductFull;
      this.productIdToNameMap.set(productFull.id, productFull.name);
    }
  }

  private setupData(): void {
    if (!this.billingAccountInfo) {
      return;
    }
    this.setProductIdToNameMap();
    this.subscriptionDisplayData = this.getSubscriptionDisplayData();
    this.setFormValues();
  }

  private initializeFormGroup(): void {
    this.billingInfoForm = this.formBuilder.group({
      contact_email: '',
    });
    this.changeDetector.detectChanges();
  }

  private setFormValues(): void {
    this.billingInfoForm
      .get('contact_email')
      .setValue(this.billingAccountInfo?.status?.customer?.email ? this.billingAccountInfo.status.customer.email : '');
  }

  public onSetupPaymentClick(): void {
    this.paymentService.handleSetupPaymentClick(this.orgId, this.unsubscribe$);
  }

  public onBuyProductClick(): void {
    this.openStripeCheckoutDialog();
  }

  public getProductNames(): Array<string> {
    if (!this.billingAccountInfo?.status?.products) {
      return [];
    }
    return this.billingAccountInfo.status.products.map((product) => product.name);
  }

  public getProducts(): Array<BillingProduct> {
    if (!this.billingAccountInfo?.status?.products) {
      return [];
    }
    return this.billingAccountInfo.status.products;
  }

  public getContactEmailTooltipText(): string {
    let tooltipText = 'This is where the invoice will be sent';
    if (this.hasUsersOwnerPermissions) {
      tooltipText += `. It can be configured by clicking the "${this.subscriptionButtonText}" button below.`;
    }
    return tooltipText;
  }

  public canAccessPaymentInfo(): boolean {
    return (
      !!this.hasUsersOwnerPermissions &&
      !!this.billingAccountInfo?.status?.subscriptions &&
      this.billingAccountInfo.status.subscriptions.length !== 0
    );
  }

  private getFilteredSubscriptions(subs: Array<BillingSubscriptionFull>): Array<BillingSubscriptionFull> {
    const filteredSubs: Array<BillingSubscriptionFull> = [];
    for (const sub of subs) {
      if (!this.showCancelledSubscriptions && sub.stripe_subscription.status === BillingPaymentStatus.canceled) {
        continue;
      }
      filteredSubs.push(sub);
    }
    return filteredSubs;
  }

  public getSubscriptionDisplayData(): Array<SubscriptionDisplayElement> {
    if (!this.billingAccountInfo?.status?.subscriptions) {
      return [];
    }
    const filteredSubscriptions = this.getFilteredSubscriptions(this.billingAccountInfo.status.subscriptions);
    const subscriptionDisplayData: Array<SubscriptionDisplayElement> = [];
    for (let i = 0; i < filteredSubscriptions.length; i++) {
      const subscription = filteredSubscriptions[i];
      const upcomingInvoice = subscription.agilicus_subscription?.status?.balance?.upcoming_invoice as BillingUpcomingInvoice;
      const data: SubscriptionDisplayElement = {
        product_name: subscription.agilicus_subscription?.status?.product?.spec?.name,
        start_date: subscription.stripe_subscription.start_date,
        last_invoice_date: subscription.stripe_subscription.current_period_start,
        next_invoice_date: subscription.stripe_subscription.current_period_end,
        trial_active: this.isTrialActive(subscription),
        trial_end_date: !!subscription.stripe_subscription.trial_end ? subscription.stripe_subscription.trial_end : null,
        payment_status: subscription.stripe_subscription.status,
        products_overview: this.getSubscriptionProductsOverview(subscription.stripe_subscription),
        orgs: subscription.agilicus_subscription?.status?.orgs ? subscription.agilicus_subscription.status.orgs : [],
        usage_override: !!subscription.agilicus_subscription?.spec?.usage_override
          ? subscription.agilicus_subscription.spec.usage_override
          : [],
        usage_metrics: !!subscription.agilicus_subscription?.status?.usage_metrics?.metrics
          ? subscription.agilicus_subscription.status.usage_metrics.metrics
          : [],
        subscription_balance: subscription.agilicus_subscription?.status?.balance?.subscription_balance,
        estimate_balance_end_date: subscription.agilicus_subscription?.status?.balance?.estimate_balance_end_date,
        amount_due: upcomingInvoice?.amount_due,
        total: upcomingInvoice?.total,
        currency: upcomingInvoice?.currency,
        ...getDefaultTableProperties(i),
      };
      data.expandedData = {
        ...getDefaultNestedDataProperties(data),
        hideNestedFilter: true,
      };
      this.initializeNestedColumnDefs(data.expandedData.nestedColumnDefs);
      for (let i = 0; i < data.usage_metrics.length; i++) {
        const usageMetric = data.usage_metrics[i];
        const nestedElement = this.createUsageMetricElement(usageMetric, i, data);
        data.expandedData.nestedTableData.push(nestedElement);
      }
      subscriptionDisplayData.push(data);
    }
    return subscriptionDisplayData;
  }

  private getUsageOverrideTypeFromMetric(metricType: string): string {
    let str = `active_${metricType}`;
    if (str[str.length - 1] !== 's') {
      str += 's';
    }
    return str;
  }

  private createUsageMetricElement(metric: UsageMetric, index: number, parentElement: SubscriptionDisplayElement): UsageMetricElement {
    const targetUsageOverride = parentElement.usage_override.find(
      (override) => override.metric === this.getUsageOverrideTypeFromMetric(metric.type)
    );
    const data: UsageMetricElement = {
      ...getDefaultTableProperties(index),
      parentId: parentElement.index,
      usageMetric: metric,
      usageOverride: targetUsageOverride,
    };
    return data;
  }

  private getSubscriptionProductsOverview(sub: BillingSubscriptionStripe): string {
    if (sub.items?.total_count === 0 || !sub.items?.data || sub.items.data.length === 0) {
      return '';
    }
    const targetProductId = sub.items.data[0].plan.product;
    const product = !!this.billingAccountInfo?.status?.products
      ? this.billingAccountInfo.status.products.find((product) => product && product.id === targetProductId)
      : undefined;
    const productName = !!product ? product.name : '';
    if (!productName) {
      return '';
    }
    const additionalProducts = sub.items.total_count - 1;
    if (additionalProducts === 0) {
      return productName;
    }
    return `${productName} and ${additionalProducts} more...`;
  }

  public getFormatedBillingPaymentStatus(billingPaymentStatus: BillingPaymentStatus): string {
    return capitalizeFirstLetter(replaceCharacterWithSpace(billingPaymentStatus, '_'));
  }

  public getSubscriptionButtonDisabledTooltipText(): string {
    if (!this.hasUsersOwnerPermissions) {
      return 'You do not have sufficient permissions to access this information';
    }
    if (!this.billingAccountInfo?.status?.subscriptions || this.billingAccountInfo.status.subscriptions.length === 0) {
      return 'Your payment subscription is not yet set up';
    }
    return '';
  }

  public canAccessStartSubscriptionButton(): boolean {
    return this.canAccessPaymentInfo() && noPaymentOnFile(this.billingAccountInfo);
  }

  public getStartSubscriptionTooltipText(): string {
    if (this.canAccessStartSubscriptionButton()) {
      return 'Click to start a subscription';
    }
    if (noPaymentOnFile(this.billingAccountInfo) === false) {
      return 'Subscription has already been started. To change your subscription information, click "VIEW/UPDATE PAYMENT INFORMATION" below.';
    }
    if (noPaymentOnFile(this.billingAccountInfo) === undefined) {
      return 'This option is not currently available';
    }
    if (!this.canAccessPaymentInfo()) {
      return 'You do not have sufficient permissions to access this information';
    }
    return '';
  }

  /**
   * Parent Table Column
   */
  private getProductNameColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('product_name');
    column.displayName = 'Product';
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return this.getProductNameDisplayValue(elem.product_name);
    };
    return column;
  }

  public getProductNameDisplayValue(productName: string): string {
    return !!productName ? productName : `UNKNOWN`;
  }

  /**
   * Parent Table Column
   */
  private getOrgsColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('orgs');
    column.displayName = 'Organisations';
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return this.getFormattedOrgsString(elem.orgs);
    };
    column.inputSize = InputSize.MEDIUM;
    return column;
  }

  private getFormattedOrgsString(orgs: Array<Organisation>) {
    const orgsString = orgs.map((org) => org.organisation).join('; ');
    return !!orgsString ? orgsString : 'NONE';
  }

  /**
   * Parent Table Column
   */
  private getStartDateColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('start_date');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return getFormatedBillingDateString(elem.start_date);
    };
    column.inputSize = InputSize.DATE;
    column.showColumn = false;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getLastInvoiceDateColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('last_invoice_date');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return getFormatedBillingDateString(elem.last_invoice_date);
    };
    column.inputSize = InputSize.DATE;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getNextInvoiceDateColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('next_invoice_date');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return getFormatedBillingDateString(elem.next_invoice_date);
    };
    column.inputSize = InputSize.DATE;
    column.showColumn = false;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getPaymentStatusColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('payment_status');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return this.getFormatedBillingPaymentStatus(elem.payment_status);
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getTrialActiveColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('trial_active');
    column.displayName = 'Trial';
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return this.getTrialActiveDisplayValue(elem);
    };
    column.showColumn = false;
    return column;
  }

  private isTrialActive(sub: BillingSubscriptionFull): boolean {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    return sub.stripe_subscription.trial_end > currentTime;
  }

  private getTrialActiveDisplayValue(elem: SubscriptionDisplayElement): string {
    return !!elem.trial_active ? 'Active' : 'Not Active';
  }

  /**
   * Parent Table Column
   */
  private getTrialEndDateColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('trial_end_date');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      if (!elem.trial_active) {
        // Do not show anything if the subscription is not currently in trial:
        return '';
      }
      return !!elem.trial_end_date ? getFormatedBillingDateString(elem.trial_end_date) : 'N/A';
    };
    column.inputSize = InputSize.DATE;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getEstimateBalanceEndDateColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('estimate_balance_end_date');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return convertDateToShortReadableFormat(elem.estimate_balance_end_date);
    };
    column.inputSize = InputSize.DATE;
    column.warnValue = (elem: SubscriptionDisplayElement) => {
      return warnOnEstimateBalanceEndDate(elem.estimate_balance_end_date);
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getSubscriptionBalanceColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('subscription_balance');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return this.getSubscriptionBalanceDisplayValue(elem.subscription_balance, elem.currency);
    };
    column.getHeaderTooltip = () => {
      return `A (credit) amount can be applied to an upcoming invoice`;
    };
    return column;
  }

  public getSubscriptionBalanceDisplayValue(balance: number, currency: string): string {
    if (balance < 0) {
      return `(${convertCentsToReadableDollarsString(balance * -1, currency)})`;
    }
    return convertCentsToReadableDollarsString(balance, currency);
  }

  /**
   * Parent Table Column
   */
  private getAmountDueColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('amount_due');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return convertCentsToReadableDollarsString(elem.amount_due, elem.currency);
    };
    column.displayName = 'Upcoming invoice amount due';
    column.showColumn = false;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getTotalColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('total');
    column.displayName = 'Upcoming invoice total';
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return convertCentsToReadableDollarsString(elem.total, elem.currency);
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getCurrencyColumn(): ReadonlyColumn<SubscriptionDisplayElement> {
    const column = createReadonlyColumn('currency');
    column.getDisplayValue = (elem: SubscriptionDisplayElement) => {
      return getCurrencyDisplayValue(elem.currency);
    };
    column.showColumn = false;
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        this.getProductNameColumn(),
        this.getOrgsColumn(),
        this.getStartDateColumn(),
        this.getEstimateBalanceEndDateColumn(),
        this.getSubscriptionBalanceColumn(),
        this.getLastInvoiceDateColumn(),
        this.getNextInvoiceDateColumn(),
        this.getTotalColumn(),
        this.getAmountDueColumn(),
        this.getPaymentStatusColumn(),
        this.getCurrencyColumn(),
        this.getTrialActiveColumn(),
        this.getTrialEndDateColumn(),
        createExpandColumn(),
      ],
      this.columnDefs
    );
  }

  /**
   * Nested Table Column
   */
  private getUsageMetricTypeColumn(): ReadonlyColumn<InputData> {
    const column = createReadonlyColumn('type');
    column.getDisplayValue = (element: UsageMetricElement) => {
      return element.usageMetric?.type ? capitalizeFirstLetter(replaceCharacterWithSpace(element.usageMetric.type, '_')) : '';
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getUsageMetricMinQuantityColumn(): ReadonlyColumn<InputData> {
    const column = createReadonlyColumn('min_quantity');
    column.displayName = 'Minimum usage';
    column.getDisplayValue = (element: UsageMetricElement) => {
      return element.usageOverride?.min_quantity ? element.usageOverride.min_quantity.toString() : '';
    };
    column.getHeaderTooltip = () => {
      return `The minimum committed-usage`;
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getUsageMetricMaxQuantityColumn(): ReadonlyColumn<InputData> {
    const column = createReadonlyColumn('max_quantity');
    column.displayName = 'Maximum usage';
    column.getDisplayValue = (element: UsageMetricElement) => {
      return element.usageOverride?.max_quantity ? element.usageOverride.max_quantity.toString() : '';
    };
    column.getHeaderTooltip = () => {
      return `The maximum usage not to be exceeded`;
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getUsageMetricCurrentUsageColumn(): ReadonlyColumn<InputData> {
    const column = createReadonlyColumn('current_usage');
    column.getDisplayValue = (element: UsageMetricElement) => {
      return this.getCurrentUsageString(element);
    };
    column.getHeaderTooltip = () => {
      return `The current amount that is being used`;
    };
    column.warnValue = (elem: UsageMetricElement) => {
      const currentUsage = elem.usageMetric?.active?.current;
      const minUsage = elem.usageOverride?.min_quantity;
      return shouldWarnOnCurrentUsageData(currentUsage, minUsage);
    };
    return column;
  }

  private getCurrentUsageString(element: UsageMetricElement): string {
    const currentUsage = element.usageMetric?.active?.current;
    return currentUsage !== undefined ? currentUsage.toString() : '';
  }

  /**
   * Nested Table Column
   */
  private getUsageMetricStepSizeColumn(): ReadonlyColumn<InputData> {
    const column = createReadonlyColumn('step_size');
    column.getHeaderTooltip = () => {
      return `If set, the usage is stepped by this amount (e.g. rounded up to a multiple of this bounder).`;
    };
    return column;
  }

  /**
   * Nested Table Columns
   */
  private initializeNestedColumnDefs(nestedColumnDefs: Map<string, Column<TableElement>>): void {
    setColumnDefs(
      [
        this.getUsageMetricTypeColumn(),
        this.getUsageMetricCurrentUsageColumn(),
        this.getUsageMetricMinQuantityColumn(),
        this.getUsageMetricMaxQuantityColumn(),
        this.getUsageMetricStepSizeColumn(),
      ],
      nestedColumnDefs
    );
  }

  public showSubscriptionsInTable(): boolean {
    if (!this.subscriptionDisplayData) {
      return false;
    }
    return this.subscriptionDisplayData.length > 1;
  }

  public getSingleSubscriptionUsageMetricTableData(subscriptionDisplayData: Array<SubscriptionDisplayElement>): Array<UsageMetricElement> {
    const subscriptionDisplayElement = !!subscriptionDisplayData ? subscriptionDisplayData[0] : undefined;
    return !!subscriptionDisplayElement ? subscriptionDisplayElement.expandedData?.nestedTableData : [];
  }

  public getSingleSubscriptionUsageMetricColumnDefs(
    subscriptionDisplayData: Array<SubscriptionDisplayElement>
  ): Map<string, Column<TableElement>> {
    const subscriptionDisplayElement = !!subscriptionDisplayData ? subscriptionDisplayData[0] : undefined;
    return !!subscriptionDisplayElement ? subscriptionDisplayElement.expandedData?.nestedColumnDefs : new Map();
  }

  private getSubscriptionsToDownload(data: Array<SubscriptionDisplayElement>): UnparseData {
    const downloadedData: Array<Array<string>> = [];
    const fields: string[] = [
      'product_name',
      'start_date',
      'estimate_balance_end_date',
      'subscription_balance',
      'last_invoice_date',
      'next_invoice_date',
      'total',
      'amount_due',
      'payment_status',
      'currency',
      'trial_active',
      'trial_end_date',
      'organisations',
      'usage_metrics',
    ];
    for (const sub of data) {
      const targetSub = [
        this.getProductNameDisplayValue(sub.product_name),
        getFormatedBillingDateString(sub.start_date),
        convertDateToShortReadableFormat(sub.estimate_balance_end_date),
        convertCentsToReadableDollarsString(sub.subscription_balance, sub.currency),
        getFormatedBillingDateString(sub.last_invoice_date),
        getFormatedBillingDateString(sub.next_invoice_date),
        convertCentsToReadableDollarsString(sub.total, sub.currency),
        convertCentsToReadableDollarsString(sub.amount_due, sub.currency),
        !!sub.payment_status ? sub.payment_status.toString() : '',
        getCurrencyDisplayValue(sub.currency),
        this.getTrialActiveDisplayValue(sub),
        getFormatedBillingDateString(sub.trial_end_date),
        this.getFormattedOrgsString(sub.orgs),
        this.getUsageMetricStringForCsv(sub),
      ];
      downloadedData.push(targetSub);
    }
    const mapObject: MapObject = {
      fields: fields,
      data: downloadedData,
    };
    return mapObject;
  }

  public unparseDataToCsv(data: Array<SubscriptionDisplayElement>): string {
    const downloadedGroups = this.getSubscriptionsToDownload(data);
    const options: UnparseConfig = {
      quotes: true,
      header: true,
      newline: '\n',
    };
    return this.papa.unparse(downloadedGroups, options);
  }

  public downloadSubscriptions(): void {
    const csv = this.unparseDataToCsv(this.subscriptionDisplayData);
    const link = this.renderer.createElement('a');
    const blob = new Blob([csv], { type: 'text/csv' });
    link.href = window.URL.createObjectURL(blob);
    link.download = 'subscriptions_data.csv';
    link.click();
  }

  private getUsageMetricStringForCsv(sub: SubscriptionDisplayElement): string {
    const stringArray = [];
    const uageMetricElements: Array<UsageMetricElement> = sub.expandedData.nestedTableData;
    for (const usageMetricElem of uageMetricElements) {
      const metricStr = `Type: ${this.getOptionalItemCsvString(usageMetricElem.usageMetric?.type)}`;
      const currentUsageStr = `Current usage: ${this.getOptionalItemCsvString(this.getCurrentUsageString(usageMetricElem))}`;
      const minQuantityStr = `Minimum usage: ${this.getOptionalItemCsvString(usageMetricElem.usageOverride?.min_quantity)}`;
      const maxQuantityStr = `Maximum usage: ${this.getOptionalItemCsvString(usageMetricElem.usageOverride?.max_quantity)}`;
      const stepSizeStr = `Step size: ${this.getOptionalItemCsvString(usageMetricElem.usageOverride?.step_size)}`;
      const fullStr = `(${metricStr}, ${currentUsageStr}, ${minQuantityStr}, ${maxQuantityStr}, ${stepSizeStr})`;
      stringArray.push(fullStr);
    }
    return stringArray.join('; ');
  }

  private getOptionalItemCsvString(value: string | number | undefined): string {
    if (value === undefined || value === '') {
      return 'NONE';
    }
    return value.toString();
  }

  public isManagedBillingEnabled(): boolean {
    const result = this.orgFeatureKeyToEnabledMap.get(OrgFeatures.managed_billing);
    if (result === undefined) {
      return true;
    }
    return result;
  }

  public toggleCancelledSubscriptions(checkboxOption: CheckboxOption): void {
    this.showCancelledSubscriptions = checkboxOption.isChecked;
    this.setupData();
  }

  private openStripeCheckoutDialog(updateUrl: boolean = true, orgId?: string): void {
    if (this.currentDialog) {
      this.currentDialog.close();
      this.currentDialog = undefined;
    }

    const dialogData: StripeCheckoutDialogData = {
      orgId: orgId || this.orgId,
    };
    const dialogRef = this.dialog.open(
      StripeCheckoutDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
    this.currentDialog = dialogRef;

    this.dialogOpenedByUser = true;

    if (updateUrl) {
      this.updateUrlWithoutNavigation('true');
    }

    dialogRef.afterClosed().subscribe(() => {
      this.currentDialog = undefined;
      this.updateUrlWithoutNavigation(null);
      this.dialogOpenedByUser = false;
    });
  }

  private updateUrlWithoutNavigation(checkoutdialog: string | null): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { checkoutdialog },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
}
