import { NgModule, Optional, Self } from '@angular/core';
import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { MergedRouterStateSerializer } from './merged-route-serializer';
import { Router } from '@angular/router';

export const routerStateConfig = {
  stateKey: 'router',
};

@NgModule({
  imports: [StoreModule.forFeature(routerStateConfig.stateKey, routerReducer), StoreRouterConnectingModule.forRoot(routerStateConfig)],
  exports: [StoreModule, StoreRouterConnectingModule],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    },
  ],
})
export class NgrxRouterStoreModule {
  constructor(@Self() @Optional() router: Router) {}
}
