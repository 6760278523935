<h2>Add Multiple Networks Via CSV</h2>

<div *ngIf="!!allForms" mat-dialog-content>
  <mat-vertical-stepper linear="true" #stepper>
    <mat-step [stepControl]="connectorFormGroup">
      <ng-template matStepLabel>Select connector</ng-template>
      <portal-connector-selection
        [connectorFormGroup]="connectorFormGroup"
        [connectors]="connectors"
        required="true"
        (updateModel)="updateConnector($event)"
      >
      </portal-connector-selection>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="resourceGroupFormGroup">
      <ng-template matStepLabel>Select resource group</ng-template>
      <form
        [formGroup]="resourceGroupFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <p class="descriptive-text">Select from the list of existing resource groups or create a new resource group</p>
        <mat-form-field>
          <mat-label>Provide a resource group name:</mat-label>
          <input
            matInput
            type="text"
            placeholder="Select existing or enter new resource group name..."
            formControlName="resource_group_name"
            [matAutocomplete]="autoResourceGroup"
            #trigger="matAutocompleteTrigger"
            (keyup.enter)="
              keyTabManager.keyTabAutoInput($event.target.id, trigger)
            "
          />
          <span matSuffix><mat-icon>arrow_drop_down</mat-icon></span>
          <mat-autocomplete #autoResourceGroup="matAutocomplete">
            <mat-option
              *ngFor="let resourceGroup of resourceGroups"
              [value]="resourceGroup.spec.name"
            >
              {{ resourceGroup.spec.name }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Resource group is required</mat-error>
        </mat-form-field>

        <div class="radio-group-container">
          <label
            class="radio-button-group-label"
          >
            My network list will:
          </label>
          <mat-radio-group
            aria-labelledby="resource group replace radio buttons"
            formControlName="replace_all_in_group"
          >
            <mat-radio-button
              [value]="replaceMembersFalseValue"
            >
              be added to the existing members of the above resource group
            </mat-radio-button>
            <mat-radio-button
              [value]="replaceMembersTrueValue"
            >
              replace the existing members of the above resource group
            </mat-radio-button>
          </mat-radio-group>
        </div>

      </form>

      <div class="nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Upload CSV</ng-template>
      <p>{{ uploadButtonTooltipText }} to create your group of Networks</p>
      <br>
      <div class="summary-container">
        <p>Here is a summary of the information you have provided. If this information is not correct, return to previous steps to make changes.</p>
        <br>
        <p><b>Connector:</b> {{ getConnectorNameFromForm() }}</p>
        <p><b>Resource Group:</b> {{ getResourceGroupNameFromForm() }}</p>
        <p><b>My network list will:</b> {{ getRelaceMembersTextFromValue() }}</p>
      </div>

      <div class="nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <portal-upload-button
          [isUploading]="isUploading"
          [buttonDescription]="buttonDescription"
          [tooltipText]="uploadButtonTooltipText"
          (readFile)="onReadNetworks($event)"
        >
        </portal-upload-button>
      </div>

      <portal-progress-bar 
        [progressBarController]="progressBarController"
      >
      </portal-progress-bar>
      
    </mat-step>

  </mat-vertical-stepper>
</div>
