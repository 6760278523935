import {
  Issuer,
  IssuerClient,
  User,
  UserImpl,
  IssuerClientImpl,
  IssuerImpl,
  MFAChallengeMethodSpecImpl,
  MFAChallengeMethodSpec,
  LoginSession,
  LoginSessionImpl,
} from '@agilicus/angular';

export const issuerClientKeys: Array<keyof IssuerClient> = [
  'application',
  'id',
  'issuer_id',
  'mfa_challenge',
  'single_sign_on',
  'name',
  'org_id',
  'organisation_scope',
  'secret',
];
export const issuerKeys: Array<keyof Issuer> = ['id', 'issuer', 'org_id', 'upstream_redirect_uri'];
export const userKeys: Array<keyof User> = [
  'id',
  'external_id',
  'enabled',
  'status',
  'first_name',
  'last_name',
  'email',
  'provider',
  'org_id',
  'type',
  'created',
  'updated',
  'auto_created',
];
export const loginSessionKeys: Array<keyof LoginSession> = [
  'number_of_logins',
  'number_of_failed_multi_factor_challenges',
  'single_sign_on_time',
  'user_is_authenticated',
  'user_is_authenticated_by_upstream',
  'user_is_authenticated_by_cache',
];

export const mfaChallengeKeys: Array<keyof MFAChallengeMethodSpec> = ['challenge_type'];

export function getClientPaths(): Array<string> {
  return issuerClientKeys.map((property) => 'clients.' + property);
}

export function getLoginSessionPaths(): Array<string> {
  return loginSessionKeys.map((property) => 'login_session.' + property);
}

export function getIssuerPaths(): Array<string> {
  return issuerKeys.map((property) => 'issuers.' + property);
}

export function getUserPaths(): Array<string> {
  return userKeys.map((property) => 'user.' + property);
}

export function getUserMemberOfPaths(): Array<string> {
  return userKeys.map((property) => 'user_member_of.' + property);
}

export function getMFAChallengePaths(): Array<string> {
  return mfaChallengeKeys.map((property) => 'user_mfa_preferences.spec.' + property);
}

export function getAllPaths(): Array<string> {
  return [
    ...getClientPaths(),
    ...getUserPaths(),
    ...getUserMemberOfPaths(),
    ...getIssuerPaths(),
    ...getMFAChallengePaths(),
    ...getLoginSessionPaths(),
  ];
}

export function getFieldType(fields: Array<string>): string {
  const objectType = fields.shift();
  switch (objectType) {
    case 'user': {
      return getTypeFromUserObj(fields);
    }
    case 'user_member_of': {
      return getTypeFromUserObj(fields);
    }
    case 'clients': {
      return getTypeFromClientObj(fields);
    }
    case 'issuers': {
      return getTypeFromIssuerObj(fields);
    }
    case 'user_mfa_preferences': {
      return getTypeFromMFAChallengeObj(fields);
    }
    case 'login_session': {
      return getTypeFromLoginSession(fields);
    }
    default: {
      return 'string';
    }
  }
}

function _getTypeFromObj(objectFields: Array<string>, obj: any): string {
  // Something has gone wrong return a sane default
  if (objectFields.length === 0) {
    return 'string';
  }

  if (objectFields.length === 1) {
    return typeof obj[objectFields[0]];
  }
  // This case handles nested objects (ie issuers.oidc_upstreams.name)
  // The shift in getFieldType ensures that we will eventually terminate
  return getFieldType(objectFields);
}

function getTypeFromUserObj(objectFields: Array<string>): string {
  const user: UserImpl = {
    member_of: [],
    id: '',
    external_id: '',
    first_name: '',
    last_name: '',
    full_name: undefined,
    display_name: undefined,
    email: '',
    provider: '',
    roles: {},
    enabled: false,
    cascade: false,
    status: undefined,
    org_id: '',
    type: undefined,
    created: undefined,
    updated: undefined,
    auto_created: false,
    upstream_user_identities: [],
    configured_attributes: undefined,
    attributes: undefined,
    inheritable_config: undefined,
    inheritable_status: undefined,
    _remove_builtin_extensions: undefined,
    disabled_at_time: undefined,
  };
  return _getTypeFromObj(objectFields, user);
}

function getTypeFromClientObj(objectFields: Array<string>): string {
  const obj: IssuerClientImpl = {
    id: '',
    issuer_id: '',
    name: '',
    secret: '',
    application: '',
    org_id: '',
    restricted_organisations: [],
    saml_metadata_file: '',
    organisation_scope: undefined,
    redirects: [],
    mfa_challenge: undefined,
    single_sign_on: undefined,
    attributes: undefined,
    _remove_builtin_extensions: undefined,
    id_mapping: undefined,
    saml_scopes: undefined,
  };
  return _getTypeFromObj(objectFields, obj);
}

function getTypeFromIssuerObj(objectFields: Array<string>): string {
  const obj: IssuerImpl = {
    id: '',
    issuer: '',
    enabled: false,
    org_id: '',
    theme_file_id: '',
    name_slug: '',
    upstream_redirect_uri: undefined,
    oidc_upstreams: undefined,
    managed_upstreams: undefined,
    clients: [],
    _remove_builtin_extensions: undefined,
    local_auth_upstreams: undefined,
    upstream_group_mappings: undefined,
    saml_state_encryption_key: undefined,
    application_upstreams: undefined,
    service_account_id: undefined,
    service_account_user_id: undefined,
    verified_domains: undefined,
    kerberos_upstreams: undefined,
    admin_status: undefined,
    trap_disabled: undefined,
    operational_status: undefined,
    parent_issuer: undefined,
    status: undefined,
  };
  return _getTypeFromObj(objectFields, obj);
}

function getTypeFromMFAChallengeObj(objectFields: Array<string>): string {
  const obj: MFAChallengeMethodSpecImpl = {
    origin: '',
    priority: 0,
    challenge_type: '',
    endpoint: '',
    nickname: '',
    enabled: false,
    _remove_builtin_extensions: undefined,
  };
  return _getTypeFromObj(objectFields, obj);
}

function getTypeFromLoginSession(objectFields: Array<string>): string {
  const obj: LoginSessionImpl = {
    session_id: '',
    number_of_logins: 0,
    number_of_failed_multi_factor_challenges: 0,
    single_sign_on_time: undefined,
    user_is_authenticated: false,
    user_is_authenticated_by_upstream: false,
    user_is_authenticated_by_cache: false,
    _remove_builtin_extensions: undefined,
  };

  // OPA handles datetimes differently and actually wants a value in seconds here
  if (objectFields.length === 1 && objectFields[0] === 'single_sign_on_time') {
    return 'number';
  }

  return _getTypeFromObj(objectFields, obj);
}
