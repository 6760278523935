<div
  *ngIf="!hideFilter"
  class="filter-container"
>
  <button
    mat-icon-button
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="main_menu"
    aria-label="Icon-button that displays a table filter options menu"
    matTooltip="Click to view table filter options"
    [disabled]="getFilterMenuOptions().length === 0"
    >
    <mat-icon class="filter-list-icon">filter_list</mat-icon>
  </button>
  <mat-menu #main_menu="matMenu">
    <button mat-menu-item disabled>
      <span>Filter by:</span>
    </button>
    <ng-container *ngFor="let menuOption of getFilterMenuOptions()">
      <button mat-menu-item [matMenuTriggerFor]="sub_menu">
        <mat-icon>{{ menuOption.icon }}</mat-icon>
        <span>{{ menuOption.displayName }}</span>
      </button>
      <mat-menu #sub_menu="matMenu">
        <button
          *ngFor="
            let option of filterManager.getCheckboxOptions(
                menuOption.displayName
            )
          "
          mat-menu-item
          (keydown.arrowleft)="$event.stopPropagation()"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            [checked]="option.isChecked"
            (change)="filterManager.onCheckBoxToggle(option, dataSource)"
          >
            {{ capitalizeFirstLetter(option.displayName) }}
          </mat-checkbox>
        </button>
        <div
          *ngFor="
            let option of getParamSpecificSearchTextOptions(
                menuOption
            )
          "
          mat-menu-item
        >
          <mat-form-field (click)="$event.stopPropagation()">
            <input
              #filterInput
              matInput
              type="text"
              value=""
              (blur)="addParamSpecificFilterOptionWithValueToFilterBarOnBlur($event.target.value, option, filterInput)"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
              (keydown.arrowleft)="$event.stopPropagation()"
              portalKeyInputDelay
              (handleOnKeyUp)="handleSearchFilterOnKeyUp(filterInput.value, option)"
              spellcheck="false"
              autocomplete="off"
              [placeholder]="!!menuOption.placeholder ? menuOption.placeholder : ''"
            >
          </mat-form-field>
        </div>
        <div
          *ngFor="
            let option of getParamSpecificSearchDropdownOptions(
                menuOption
            )
          "
          mat-menu-item
        >
          <mat-form-field (click)="$event.stopPropagation()">
            <mat-select
              #select
              (selectionChange)="addParamSpecificFilterOptionWithValueToFilterBarOnBlur($event.value, option, select)" 
              value=""
              (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select)"
              (keydown.arrowleft)="$event.stopPropagation()"
            >
              <mat-option 
                *ngFor="let item of menuOption.allowedValues()"
                value="{{ item }}"
              >
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-menu>
    </ng-container>
  </mat-menu>
  <mat-form-field class="filter-field">
    <mat-icon
      matPrefix
      class="search-icon"
    >
      search
    </mat-icon>
    <mat-chip-list #chipList aria-label="Current filter options">
      <mat-chip
        *ngFor="let option of filterManager.getActiveFilterOptions()"
        [selectable]="filterChipOptions.selectable"
        [removable]="filterChipOptions.removable"
        (removed)="removeInputChipFilterOptionOnBlur(option, dataSource)"
      >
        {{ getFilterDisplayName(option) }}
        <mat-icon matChipRemove *ngIf="filterChipOptions.removable">cancel</mat-icon>
      </mat-chip>
      <input
        #tableSearchInput
        matInput
        class="filter-input"
        [placeholder]="placeholder"
        [matTooltip]="tooltip"
        aria-label="Input for a case insensitive table filter"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
        [matChipInputAddOnBlur]="filterChipOptions.addOnBlur"
        (matChipInputTokenEnd)="addInputChipFilterOptionOnBlur($event, dataSource)"
        (keyup.enter)="keyTabManager.keyTabFilterInput($event.target.id, filterManager.newInputOptionAdded)"
        spellcheck="false"
        autocomplete="off"
      >
    </mat-chip-list>
  </mat-form-field>
</div>