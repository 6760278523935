import { ObjectCredential, ObjectCredentialSecrets, SSHResource } from '@agilicus/angular';
import { ResourceType } from '@app/shared/components/resource-type.enum';
import { getEmptyStringIfUnset } from '@app/shared/components/utils';

export function getCredentialPrivateKeyTooltipText(): string {
  return `The private key, in "pem" or "OpenSSH" format, to present to the server`;
}

export function getCredentialPrivateKeyPassphraseTooltipText(): string {
  return `Used to decrypt the private key if it has a passphrase`;
}

export function getCredentialPasswordTooltipText(): string {
  return `The password to present to the server`;
}

export function getCredentialUsernameTooltipText(): string {
  return `If the SSH uses a single well known user account, specify this here. 
  If not specified, the user account would be provided by the user initiating the SSH command.`;
}

export function getDefaultNewCredential(sshResource: SSHResource, credentialSecrets: ObjectCredentialSecrets): ObjectCredential {
  const newCredential: ObjectCredential = {
    spec: {
      object_id: sshResource.metadata.id,
      object_type: ResourceType.ssh,
      org_id: sshResource.spec.org_id,
      purpose: 'stuffing',
      priority: 0,
      secrets: {
        private_key: getEmptyStringIfUnset(credentialSecrets.private_key),
        private_key_passphrase: getEmptyStringIfUnset(credentialSecrets.private_key_passphrase),
        password: getEmptyStringIfUnset(credentialSecrets.password),
        username: getEmptyStringIfUnset(credentialSecrets.username),
      },
      description: 'Created by Agilicus Admin UI',
    },
  };
  return newCredential;
}
