import { Injectable } from '@angular/core';
import { DynamicEnvironmentService } from './dynamic-environment.init';
import { PortalVersion } from '@app/shared/components/portal-version.enum';

const priorityMap = {
  [PortalVersion.alpha]: 0,
  [PortalVersion.beta]: 1,
  [PortalVersion.stable]: 2,
};

@Injectable({
  providedIn: 'root',
})
export class FeatureGateService {
  constructor(private envService: DynamicEnvironmentService) {}

  public checkGate(gate: PortalVersion): boolean {
    const checkPriority = priorityMap[gate];
    if (checkPriority == undefined) {
      return false;
    }

    const portalVersion = this.envService.getPortalGate();
    const gatedPriority = priorityMap[portalVersion];
    if (gatedPriority == undefined) {
      return false;
    }

    return gatedPriority <= checkPriority;
  }

  public shouldEnablePolicyTemplates(): boolean {
    // Now enabled by default. Used to be: this.checkGate(PortalVersion.beta);
    return true;
  }
}
