import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[portalDroppable]',
})
export class DroppableDirective {
  @Input() public padding: string;
  @Output() private handleOnDrop = new EventEmitter();
  private highlightColor = '#a3ff8a';
  private highlightBorderColor = '#1d8a00';

  constructor(private el: ElementRef) {}

  @HostListener('dragenter', ['$event']) private onDragEnter(event): void {
    this.preventDefaults(event);
    this.highlight();
  }

  @HostListener('dragover', ['$event']) private onDragOver(event): void {
    this.preventDefaults(event);
    this.highlight();
  }

  @HostListener('dragleave', ['$event']) private onDragleave(event): void {
    this.preventDefaults(event);
    this.unhighlight();
  }

  @HostListener('drop', ['$event']) private onDrop(event): void {
    this.preventDefaults(event);
    this.unhighlight();
    this.handleDropEvent(event);
  }

  private preventDefaults(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private highlight(): void {
    this.el.nativeElement.style.backgroundColor = this.highlightColor;
    this.el.nativeElement.style.border = '2px solid ' + this.highlightBorderColor;
    this.el.nativeElement.style.opacity = '0.5';
    if (this.padding) {
      this.el.nativeElement.style.padding = this.padding;
    }
  }

  private unhighlight(): void {
    this.el.nativeElement.style.backgroundColor = null;
    this.el.nativeElement.style.border = null;
    this.el.nativeElement.style.padding = null;
    this.el.nativeElement.style.opacity = null;
  }

  private handleDropEvent(event: any): void {
    this.handleOnDrop.emit(event);
  }
}
