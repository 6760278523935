<portal-table-layout 
  class="table-layout"
  [tableData]="tableData" 
  [columnDefs]="columnDefs" 
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [rowObjectName]="rowObjectName" 
  [buttonsToShow]="buttonsToShow"
  [dragAndDropRows]="dragAndDropRows"
  pageDescriptiveText="Upload a single file and have it placed in the running image at a given location."
  productGuideLink="https://www.agilicus.com/anyx-guide/product-guide-applications/#h-instance-details"
  (updateEvent)="updateEvent($event)"
  (uploadFile)="readFile($event)"
  (removeSelected)="deleteSelected($event)"
  (addFiles)="addFiles($event)"
  (replaceFile)="replaceFile($event)"
>
</portal-table-layout>