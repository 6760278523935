import {
  Application,
  HttpRule,
  HttpRuleCondition,
  RoleV2,
  RuleAction,
  RuleConfig,
  RuleScopeEnum,
  SimpleResourcePolicyTemplate,
  SimpleResourcePolicyTemplateStructure,
} from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/core';
import { getHttpConditionFromRule } from '@app/core/api-applications/api-applications-utils';
import { selectApiApplicationsCurrentRolesList, selectApiCurrentApplication } from '@app/core/api-applications/api-applications.selectors';
import {
  getPolicyDataBeforeApplicationInit$,
  getPolicyStructureFromTableData,
  getRuleNegatedTooltipText,
  getTargetPolicyTemplateInstanceResource,
  PolicyRuleCommonTableElement,
  PolicyTemplateInstanceResource,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import {
  initPolicyTemplateInstances,
  savingPolicyTemplateInstance,
} from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  selectPolicyTemplateInstanceRefreshDataValue,
  selectPolicyTemplateInstanceResourcesList,
} from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminRules } from '@app/core/user/permissions/rules.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, concatMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { ButtonType } from '../button-type.enum';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { FilterManager } from '../filter/filter-manager';
import { RuleConfigConditionType } from '../rule-config-condition-type.enum';
import { RuleType } from '../rule-type.enum';
import { getDefaultNestedDataProperties, getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import {
  ActionMenuOptions,
  ChiplistColumn,
  Column,
  createActionsColumn,
  createCheckBoxColumn,
  createChipListColumn,
  createExpandColumn,
  createInputColumn,
  createSelectColumn,
  createSelectRowColumn,
  InputColumn,
  SelectColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { ExpandedTableData } from '../table-layout/expanded-table-data';
import { TableElement } from '../table-layout/table-element';
import { createEnumChecker, generateUniqueUuid, replaceCharacterWithSpace, updateTableElements } from '../utils';
import {
  getPolicyRulePriorityErrorMessage,
  getPriorityNotWithinRangeErrorMessage,
  isValidPolicyRulePriority,
  isWithinValidPriorityRange,
} from '../validation-utils';

export interface PolicyRuleElement extends PolicyRuleCommonTableElement {
  rule_type: string;
  methods?: Array<HttpRule.MethodsEnum>;
  path_regex: string;
  roles?: Array<string>;
  negated?: boolean;
  rule_actions: Array<RuleAction>;
}

export interface NestedPolicyRuleElement extends PolicyRuleElement {
  parentId: string | number;
}

@Component({
  selector: 'portal-application-policy-rules',
  templateUrl: './application-policy-rules.component.html',
  styleUrls: ['./application-policy-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationPolicyRulesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<PolicyRuleElement>> = new Map();
  public tableData: Array<PolicyRuleElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'RULE';
  public hasRulesPermissions: boolean;
  private orgId: string;
  public currentApplication: Application;
  private localApplicationId: string;
  private currentRolesList: Array<RoleV2> = [];
  public makeEmptyParentTableElementFunc = this.makeEmptyParentTableElement.bind(this);
  private policyTemplateInstanceResourceCopy: PolicyTemplateInstanceResource;
  private policyTemplate: SimpleResourcePolicyTemplate;
  private allPolicyRuleConfigsList: Array<RuleConfig> = [];
  private httpPolicyRuleConfigsList: Array<RuleConfig> = [];
  private nonHttpPolicyRuleConfigsList: Array<RuleConfig> = [];
  private httpPolicyStructuresList: Array<SimpleResourcePolicyTemplateStructure> = [];
  private nonHttpPolicyStructuresList: Array<SimpleResourcePolicyTemplateStructure> = [];
  private policyRuleNameToRuleConfigMap: Map<string, RuleConfig> = new Map();
  private parentRuleNameToChildRulesMap: Map<string, Array<RuleConfig>> = new Map();
  public rulesProductGuideLink = `https://www.agilicus.com/anyx-guide/authorisation-rules/`;
  public rulesDescriptiveText = `Apply a set of allow/deny rules by HTTP method, path, body, and user identity + role.`;
  private localPolicyTemplateInstanceRefreshDataValue = 0;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef, private router: Router) {}

  public ngOnInit(): void {
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeColumnDefs(this.columnDefs, true);
    this.getAndSetAllData();
  }

  public ngOnChanges(): void {
    if (this.currentApplication === undefined) {
      return;
    }
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAllData$(): Observable<[OrgQualifiedPermission, [PolicyTemplateInstanceResource[], number], Application, RoleV2[]]> {
    const hasRulesPermissions$ = this.store.pipe(select(selectCanAdminRules));
    const policyDataBeforeApplicationInit$ = getPolicyDataBeforeApplicationInit$(
      this.store,
      selectPolicyTemplateInstanceResourcesList,
      selectPolicyTemplateInstanceRefreshDataValue
    );
    const currentAppState$ = this.store.pipe(select(selectApiCurrentApplication));
    const currentRolesListState$ = this.store.pipe(select(selectApiApplicationsCurrentRolesList));
    return combineLatest([hasRulesPermissions$, policyDataBeforeApplicationInit$, currentAppState$, currentRolesListState$]).pipe(
      concatMap(([hasRulesPermissionsResp, policyDataBeforeApplicationInitResp, currentAppStateResp, currentRolesListStateResp]) => {
        return combineLatest([
          of(hasRulesPermissionsResp),
          of(policyDataBeforeApplicationInitResp),
          of(currentAppStateResp),
          of(currentRolesListStateResp),
        ]);
      })
    );
  }

  private getAndSetAllData(): void {
    this.getAllData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([hasRulesPermissionsResp, policyDataBeforeApplicationInitResp, currentAppStateResp, currentRolesListStateResp]) => {
        const policyTemplateInstanceResourceListStateResp = policyDataBeforeApplicationInitResp[0];
        const refreshPolicyTemplateInstanceDataStateResp = policyDataBeforeApplicationInitResp[1];
        this.orgId = hasRulesPermissionsResp?.orgId;
        this.hasRulesPermissions = hasRulesPermissionsResp?.hasPermission;
        this.currentApplication = currentAppStateResp;
        this.currentRolesList = !!currentRolesListStateResp ? currentRolesListStateResp : [];
        if (
          !this.hasRulesPermissions ||
          !policyTemplateInstanceResourceListStateResp ||
          policyTemplateInstanceResourceListStateResp.length === 0 ||
          !currentRolesListStateResp
        ) {
          this.resetEmptyTable();
          return;
        }
        this.setAllRoleColumnAllowedValues();
        const targetPolicyTemplateInstanceResource = !!policyTemplateInstanceResourceListStateResp
          ? getTargetPolicyTemplateInstanceResource(policyTemplateInstanceResourceListStateResp, this.currentApplication?.id)
          : undefined;
        this.policyTemplateInstanceResourceCopy = cloneDeep(targetPolicyTemplateInstanceResource);
        this.policyTemplate = this.policyTemplateInstanceResourceCopy?.spec?.template;
        this.allPolicyRuleConfigsList = this.policyTemplate?.rules ? this.policyTemplate.rules : [];
        this.setRuleConfigArrays();
        this.setRulesMapsAndPolicyStructureArray();
        if (
          this.tableData.length === 0 ||
          this.localPolicyTemplateInstanceRefreshDataValue !== refreshPolicyTemplateInstanceDataStateResp ||
          this.currentApplication.id !== this.localApplicationId
        ) {
          this.localPolicyTemplateInstanceRefreshDataValue = refreshPolicyTemplateInstanceDataStateResp;
          if (this.localPolicyTemplateInstanceRefreshDataValue !== 0 || this.currentApplication.id !== this.localApplicationId) {
            this.localApplicationId = this.currentApplication.id;
            // Only render the table data once all fresh data is retrieved from the ngrx state.
            // Once each state is updated the local refresh values will have incremented by at least 1.
            this.updateTable();
          }
        }
        this.changeDetector.detectChanges();
      });
  }

  private setRoleColumnAllowedValues(columnDefs: Map<string, Column<PolicyRuleElement | NestedPolicyRuleElement>>): void {
    columnDefs.get('roles').allowedValues = this.currentRolesList.map((role) => role.spec.name);
  }

  private setAllRoleColumnAllowedValues(): void {
    this.setRoleColumnAllowedValues(this.columnDefs);
    for (const element of this.tableData) {
      this.setRoleColumnAllowedValues(element.expandedData.nestedColumnDefs);
    }
  }

  private setRuleConfigArrays(): void {
    this.httpPolicyRuleConfigsList.length = 0;
    this.nonHttpPolicyRuleConfigsList.length = 0;
    for (const policyRuleConfig of this.allPolicyRuleConfigsList) {
      if (policyRuleConfig.extended_condition.condition.condition_type === RuleConfigConditionType.http_rule_condition) {
        this.httpPolicyRuleConfigsList.push(policyRuleConfig);
      } else {
        this.nonHttpPolicyRuleConfigsList.push(policyRuleConfig);
      }
    }
  }

  private setRulesMapsAndPolicyStructureArray(): void {
    this.policyRuleNameToRuleConfigMap.clear();
    this.parentRuleNameToChildRulesMap.clear();
    this.httpPolicyStructuresList.length = 0;
    this.nonHttpPolicyStructuresList.length = 0;
    for (const rule of this.allPolicyRuleConfigsList) {
      this.policyRuleNameToRuleConfigMap.set(rule.name, rule);
    }
    if (!this.policyTemplate?.policy_structure) {
      return;
    }
    for (const node of this.policyTemplate.policy_structure) {
      const targetRule = this.policyRuleNameToRuleConfigMap.get(node.name);
      if (targetRule.extended_condition.condition.condition_type === RuleConfigConditionType.http_rule_condition) {
        this.httpPolicyStructuresList.push(node);
      } else {
        this.nonHttpPolicyStructuresList.push(node);
      }
      const childRules: Array<RuleConfig> = [];
      for (const child of node.root_node.children) {
        childRules.push(this.policyRuleNameToRuleConfigMap.get(child.rule_name));
      }
      this.parentRuleNameToChildRulesMap.set(node.name, childRules);
    }
  }

  private getHttpParentRulesListFromPolicyStructure(): Array<RuleConfig> {
    const parentRulesList: Array<RuleConfig> = [];
    for (const policyStructure of this.httpPolicyStructuresList) {
      const targetRule = this.policyRuleNameToRuleConfigMap.get(policyStructure.name);
      parentRulesList.push(targetRule);
    }
    return parentRulesList;
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const httpParentPolicyHttpRulesList = this.getHttpParentRulesListFromPolicyStructure();
    const dataForTable: Array<PolicyRuleElement> = [];
    for (let i = 0; i < httpParentPolicyHttpRulesList.length; i++) {
      dataForTable.push(this.createPolicyRuleElement(httpParentPolicyHttpRulesList[i], i));
    }
    this.setAllNestedTableData(dataForTable);
    updateTableElements(this.tableData, dataForTable);
  }

  private createPolicyRuleElement(ruleConfig: RuleConfig, index: number): PolicyRuleElement {
    const httpRuleCondition: HttpRuleCondition = ruleConfig.extended_condition.condition as HttpRuleCondition;
    const data: PolicyRuleElement = {
      scope: ruleConfig.scope,
      comments: ruleConfig.comments,
      rule_type: httpRuleCondition.rule_type,
      methods: httpRuleCondition.methods,
      path_regex: httpRuleCondition.path_regex,
      roles: !!ruleConfig.roles ? ruleConfig.roles : [],
      priority: ruleConfig.priority,
      negated: ruleConfig.extended_condition?.negated,
      rule_actions: ruleConfig.actions ? ruleConfig.actions : [],
      ...getDefaultTableProperties(index),
      backingRule: ruleConfig,
    };
    return data;
  }

  private createNestedPolicyRuleElement(
    ruleConfig: RuleConfig,
    index: number,
    parentElement: PolicyRuleElement | undefined
  ): NestedPolicyRuleElement {
    const httpRuleCondition = getHttpConditionFromRule(ruleConfig);
    const data: NestedPolicyRuleElement = {
      scope: ruleConfig.scope,
      comments: ruleConfig.comments,
      rule_type: httpRuleCondition.rule_type,
      methods: httpRuleCondition.methods,
      path_regex: httpRuleCondition.path_regex,
      roles: !!ruleConfig.roles ? ruleConfig.roles : [],
      priority: ruleConfig.priority,
      negated: ruleConfig.extended_condition?.negated,
      rule_actions: ruleConfig.actions ? ruleConfig.actions : [],
      ...getDefaultTableProperties(index),
      backingRule: ruleConfig,
      parentId: parentElement.index,
    };
    return data;
  }

  public getBaseEmptyPolicyRuleElement(): PolicyRuleElement | NestedPolicyRuleElement {
    const newRuleName = generateUniqueUuid(this.tableData, 'name');
    return {
      scope: RuleScopeEnum.assigned_to_user,
      comments: '',
      rule_type: RuleType.HttpRule,
      methods: [],
      path_regex: '',
      roles: [],
      priority: 0,
      negated: false,
      rule_actions: [{ action: RuleAction.ActionEnum.allow }],
      ...getDefaultNewRowProperties(),
      backingRule: {
        name: newRuleName,
        scope: RuleScopeEnum.assigned_to_user,
        comments: '',
        extended_condition: {
          condition: {
            rule_type: RuleType.HttpRule,
            methods: [],
            path_regex: '',
            condition_type: 'http_rule_condition',
          },
          negated: false,
        },
        excluded_roles: [],
      },
    };
  }

  private getParentIdFromExpandedTableData(expandedTableData: ExpandedTableData<TableElement>): string | number {
    return expandedTableData.parentId;
  }

  private makeEmptyNestedTableElement(element: PolicyRuleElement): NestedPolicyRuleElement {
    const baseEmptyTableElement = this.getBaseEmptyPolicyRuleElement();
    const baseEmptyTableElementAsNestedPolicyRuleElement = baseEmptyTableElement as NestedPolicyRuleElement;
    baseEmptyTableElementAsNestedPolicyRuleElement.parentId = this.getParentIdFromExpandedTableData(element.expandedData);
    return baseEmptyTableElementAsNestedPolicyRuleElement;
  }

  private setNestedTableData(element: PolicyRuleElement): void {
    element.expandedData = {
      ...getDefaultNestedDataProperties(element),
      nestedRowObjectName: 'NESTED RULE',
      nestedButtonsToShow: [ButtonType.ADD, ButtonType.DELETE],
      makeEmptyNestedTableElement: (): NestedPolicyRuleElement => {
        return this.makeEmptyNestedTableElement(element);
      },
    };
    this.initializeColumnDefs(element.expandedData.nestedColumnDefs, false);
    this.setRoleColumnAllowedValues(element.expandedData.nestedColumnDefs);
    const childRulesList = this.parentRuleNameToChildRulesMap.get(element.backingRule.name);
    if (!childRulesList || childRulesList.length === 0) {
      return;
    }
    for (let i = 0; i < childRulesList?.length; i++) {
      const childRule = childRulesList[i];
      const nestedElement = this.createNestedPolicyRuleElement(childRule, i, element);
      element.expandedData.nestedTableData.push(nestedElement);
    }
  }

  private setAllNestedTableData(data: Array<PolicyRuleElement>): void {
    for (const element of data) {
      this.setNestedTableData(element);
    }
  }

  public showNoPermissionsText(): boolean {
    return this.hasRulesPermissions !== undefined && !this.hasRulesPermissions;
  }

  private newUnsavedRuleHasUniquePriority(priorityValue: string, element: PolicyRuleElement | NestedPolicyRuleElement): boolean {
    const targetElementAsNestedPolicyRuleElement = element as NestedPolicyRuleElement;
    if (targetElementAsNestedPolicyRuleElement.parentId === undefined || targetElementAsNestedPolicyRuleElement.parentId === null) {
      // Is a parent element and, therefore, does not need a unique priority
      return true;
    }
    const parentElement = this.getParentElementFromParentId(targetElementAsNestedPolicyRuleElement);
    const nestedTableElements: Array<NestedPolicyRuleElement> = parentElement.expandedData.nestedTableData;
    for (const nestedElement of nestedTableElements) {
      if (
        nestedElement.backingRule.name !== targetElementAsNestedPolicyRuleElement.backingRule.name &&
        nestedElement.priority.toString() === priorityValue.toString()
      ) {
        return false;
      }
    }
    return true;
  }

  /**
   * Parent & Nested Table Column
   */
  private getPriorityColumn(): InputColumn<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createInputColumn<PolicyRuleElement>('priority');
    column.isEditable = true;
    column.requiredField = () => true;
    column.inputSize = InputSize.XSMALL;
    column.isValidEntry = (priorityValue: string, element: PolicyRuleElement | NestedPolicyRuleElement) => {
      if (element.index === -1) {
        // Is a newly added unsaved policy rule
        return this.newUnsavedRuleHasUniquePriority(priorityValue, element);
      }
      return isValidPolicyRulePriority(priorityValue, element.backingRule, this.policyTemplateInstanceResourceCopy);
    };
    column.getCustomValidationErrorMessage = (priorityValue: string, element: PolicyRuleElement | NestedPolicyRuleElement) => {
      if (element.index === -1) {
        const priorityValueAsNumber = parseInt(priorityValue, 10);
        if (!isWithinValidPriorityRange(priorityValueAsNumber)) {
          return getPriorityNotWithinRangeErrorMessage();
        }
        if (!this.newUnsavedRuleHasUniquePriority(priorityValue, element)) {
          return `Priority of nested rules must be unique`;
        }
      }
      return getPolicyRulePriorityErrorMessage(priorityValue, element.backingRule, this.policyTemplateInstanceResourceCopy);
    };
    return column;
  }

  /**
   * Parent & Nested Table Column
   */
  private getPathRegexColumn(): InputColumn<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createInputColumn<PolicyRuleElement>('path_regex');
    column.displayName = 'Path';
    column.requiredField = () => true;
    column.isEditable = true;
    return column;
  }

  /**
   * Parent & Nested Table Column
   */
  private getMethodsColumn(): ChiplistColumn<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createChipListColumn<PolicyRuleElement>('methods');
    column.requiredField = () => true;
    column.allowedValues = Object.keys(HttpRule.MethodsEnum);
    column.isValidEntry = (entry: HttpRule.MethodsEnum) => {
      const isMethodEnum = createEnumChecker(HttpRule.MethodsEnum);
      return isMethodEnum(entry);
    };
    column.getCustomWidthValue = () => '150px';
    return column;
  }

  /**
   * Parent & Nested Table Column
   */
  private getScopeColumn(): SelectColumn<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createSelectColumn<PolicyRuleElement>('scope');
    column.requiredField = () => true;
    column.inputSize = InputSize.LARGE;
    column.allowedValues = Object.keys(RuleScopeEnum);
    column.getDisplayValue = (value: string | RuleConfig) => {
      const valueAsRuleConfig = value as RuleConfig;
      if (valueAsRuleConfig.scope !== undefined) {
        return valueAsRuleConfig.scope;
      }
      return replaceCharacterWithSpace(value as string, '_');
    };
    column.getOptionDisplayValue = (value: string | RuleConfig) => {
      const valueAsRuleConfig = value as RuleConfig;
      if (valueAsRuleConfig.scope !== undefined) {
        return valueAsRuleConfig.scope;
      }
      return replaceCharacterWithSpace(value as string, '_');
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getRuleActionsColumn(): ChiplistColumn<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createChipListColumn<PolicyRuleElement | NestedPolicyRuleElement>('rule_actions');
    column.displayName = 'Actions';
    column.requiredField = () => true;
    column.isEditable = true;
    // For now, we are restricting this list to allow, deny and none until we implement more complex configuration
    column.allowedValues = [
      { action: RuleAction.ActionEnum.allow },
      { action: RuleAction.ActionEnum.deny },
      { action: RuleAction.ActionEnum.none },
    ];
    column.getDisplayValue = (value: RuleAction) => {
      return value?.action;
    };
    column.getElementFromValue = (value) => {
      return { action: value };
    };
    column.getCustomWidthValue = () => '170px';
    return column;
  }

  /**
   * Parent & Nested Table Column
   */
  private getRolesColumn(): ChiplistColumn<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createChipListColumn<PolicyRuleElement>('roles');
    column.displayName = 'Application Roles';
    column.getCustomWidthValue = () => '150px';
    return column;
  }

  /**
   * Parent & Nested Table Column
   */
  private getCommentsColumn(): InputColumn<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createInputColumn<PolicyRuleElement>('comments');
    column.isEditable = true;
    column.isValidEntry = (comment: string): boolean => {
      return comment.length < 2047;
    };
    return column;
  }

  /**
   * Parent & Nested Table Column
   */
  private getNegatedColumn(): Column<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createCheckBoxColumn('negated');
    column.isEditable = true;
    column.getHeaderTooltip = () => {
      return getRuleNegatedTooltipText();
    };
    return column;
  }

  private getActionsColumn(): Column<PolicyRuleElement | NestedPolicyRuleElement> {
    const column = createActionsColumn<PolicyRuleElement>('actions');
    column.displayName = '';
    const menuOptions: Array<ActionMenuOptions<PolicyRuleElement>> = [
      {
        displayName: 'Configure Firewall Rule',
        icon: 'open_in_browser',
        tooltip: 'Click to view/modify this rule',
        onClick: (element: PolicyRuleElement) => {
          this.router.navigate([window.location.pathname + '/rule', element.backingRule.name], {
            queryParams: { org_id: this.orgId },
          });
        },
      },
    ];
    column.allowedValues = menuOptions;
    return column;
  }

  private initializeColumnDefs(colDefs: Map<string, Column<PolicyRuleElement | NestedPolicyRuleElement>>, isParentTable: boolean): void {
    const columns = [
      createSelectRowColumn(),
      this.getPriorityColumn(),
      this.getPathRegexColumn(),
      this.getMethodsColumn(),
      this.getScopeColumn(),
      this.getRuleActionsColumn(),
      this.getRolesColumn(),
      this.getNegatedColumn(),
      this.getCommentsColumn(),
      this.getActionsColumn(),
    ];
    if (isParentTable) {
      columns.push(createExpandColumn<PolicyRuleElement>());
    }
    setColumnDefs(columns, colDefs);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const pathRegexColumn = this.columnDefs.get('path_regex');
    pathRegexColumn.isEditable = !this.fixedTable;

    const methodsColumn: ChiplistColumn<PolicyRuleElement | NestedPolicyRuleElement> = this.columnDefs.get('methods');
    methodsColumn.isEditable = !this.fixedTable;
    methodsColumn.isRemovable = !this.fixedTable;

    const scopeColumn = this.columnDefs.get('scope');
    scopeColumn.isEditable = !this.fixedTable;

    const rolesColumn: ChiplistColumn<PolicyRuleElement | NestedPolicyRuleElement> = this.columnDefs.get('roles');
    rolesColumn.isEditable = !this.fixedTable;
    rolesColumn.isRemovable = !this.fixedTable;

    const ruleActionsColumn: ChiplistColumn<PolicyRuleElement | NestedPolicyRuleElement> = this.columnDefs.get('rule_actions');
    ruleActionsColumn.isEditable = !this.fixedTable;
    ruleActionsColumn.isRemovable = !this.fixedTable;

    const commentsColumn = this.columnDefs.get('comments');
    commentsColumn.isEditable = !this.fixedTable;

    const actionsColumn = this.columnDefs.get('actions');
    actionsColumn.showColumn = !!this.currentApplication.id;
  }

  public makeEmptyParentTableElement(): PolicyRuleElement {
    const baseEmptyTableElement = this.getBaseEmptyPolicyRuleElement();
    this.setNestedTableData(baseEmptyTableElement);
    return baseEmptyTableElement;
  }

  public updateEvent(updatedRule: PolicyRuleElement | NestedPolicyRuleElement): void {
    const updatedRuleAsNestedPolicyRuleElement = updatedRule as NestedPolicyRuleElement;
    // In order to trigger enabling the expansion of a newly added row,
    // we need to reset the indicies of the table to remove the -1.
    // We only need to do this for parent rows, not nested rows,
    // since nested rows are not expandable.
    const resetTableIndicesOnSave =
      updatedRuleAsNestedPolicyRuleElement.index === -1 && updatedRuleAsNestedPolicyRuleElement.parentId === undefined;
    this.savePolicyRules(resetTableIndicesOnSave);
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<PolicyRuleElement>; element: PolicyRuleElement }): void {
    if (params.column.name !== 'scope') {
      return;
    }
    const isRuleScopeEnumEnum = createEnumChecker(RuleScopeEnum);
    if (isRuleScopeEnumEnum(params.value)) {
      params.element.scope = params.value;
    }
  }

  private getRuleConfigFromTableElement(element: PolicyRuleElement): RuleConfig {
    const ruleCopy = cloneDeep(element.backingRule);
    const httpRuleCondition: HttpRuleCondition = ruleCopy.extended_condition.condition as HttpRuleCondition;
    httpRuleCondition.path_regex = element.path_regex;
    httpRuleCondition.methods = element.methods;
    httpRuleCondition.rule_type = element.rule_type;
    ruleCopy.scope = element.scope;
    ruleCopy.roles = element.roles;
    ruleCopy.actions = element.rule_actions;
    ruleCopy.comments = element.comments;
    ruleCopy.priority = parseInt(element.priority as string, 10);
    ruleCopy.extended_condition.negated = element.negated;
    return ruleCopy;
  }

  private getRuleConfigListFromTableData(): Array<RuleConfig> {
    const ruleConfigList: Array<RuleConfig> = [];
    for (const element of this.tableData) {
      const ruleCopy = this.getRuleConfigFromTableElement(element);
      ruleConfigList.push(ruleCopy);
      const nestedTableData: Array<NestedPolicyRuleElement> = element.expandedData.nestedTableData;
      for (const nestedRuleElement of nestedTableData) {
        const nestedRuleCopy = this.getRuleConfigFromTableElement(nestedRuleElement);
        ruleConfigList.push(nestedRuleCopy);
      }
    }
    return ruleConfigList;
  }

  private savePolicyRules(resetTableIndicesOnSave: boolean): void {
    const httpPolicyRuleConfigsList = this.getRuleConfigListFromTableData();
    const allPolicyRuleConfigsList = [...httpPolicyRuleConfigsList, ...this.nonHttpPolicyRuleConfigsList];
    this.policyTemplate.rules = allPolicyRuleConfigsList;
    const httpRulesPolicyStructureList = getPolicyStructureFromTableData(this.tableData);
    const allRulesPolicyStructureList = [...httpRulesPolicyStructureList, ...this.nonHttpPolicyStructuresList];
    this.policyTemplate.policy_structure = allRulesPolicyStructureList;
    this.policyTemplateInstanceResourceCopy.spec.template = this.policyTemplate;
    this.store.dispatch(
      savingPolicyTemplateInstance({
        obj: this.policyTemplateInstanceResourceCopy,
        trigger_update_side_effects: false,
        notifyUser: true,
      })
    );
    if (resetTableIndicesOnSave) {
      // We do not want to refresh the entire table/api data since that would
      // reorder the table rows. Therefore, we just need to reset the indicies
      // to enable the expansion of a newly added row without reordering the rows.
      this.resetTableIndicesAfterNewRowAdded();
    }
  }

  private getParentElementFromParentId(nestedElement: NestedPolicyRuleElement): PolicyRuleElement | undefined {
    return this.tableData[nestedElement.parentId];
  }

  private deleteFromParentTable(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
  }

  private deleteFromNestedTable(nestedElement: NestedPolicyRuleElement): void {
    const parentElement = this.getParentElementFromParentId(nestedElement);
    parentElement.expandedData.nestedTableData = parentElement.expandedData.nestedTableData.filter((element) => !element.isChecked);
  }

  public deleteSelected(itemsToDelete: Array<PolicyRuleElement>): void {
    const elementAsNestedPolicyRuleElement = itemsToDelete[0] as NestedPolicyRuleElement;
    if (elementAsNestedPolicyRuleElement.parentId !== undefined && elementAsNestedPolicyRuleElement.parentId !== null) {
      this.deleteFromNestedTable(elementAsNestedPolicyRuleElement);
    } else {
      this.deleteFromParentTable();
    }
    this.savePolicyRules(false);
  }

  private resetTableIndicesAfterNewRowAdded(): void {
    for (const element of this.tableData) {
      const newIndex = element.index + 1;
      element.index = newIndex;
      element.expandedData.parentId = newIndex;
      for (const nestedElement of element.expandedData.nestedTableData) {
        const nestedElementAsNestedPolicyRuleElement = nestedElement as NestedPolicyRuleElement;
        nestedElementAsNestedPolicyRuleElement.parentId = newIndex;
      }
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
