<portal-page-info
  pageTitle="Authentication Overview"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasPermissions" 
  class="main-container"
>
  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [buttonsToShow]="buttonsToShow"
    (updateEvent)="updateEvent($event)"
    (updateSelection)="updateSelection($event)"
    (triggerRowDirtyEvent)="triggerRowDirtyEvent($event)"
    (triggerRowCheckedEvent)="triggerRowCheckedEvent()"
  >
  </portal-table-layout>
</div>
