<portal-table-layout
  [tableData]="tableData"
  [columnDefs]="columnDefs"
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName"
  (updateEvent)="updateEvent()"
  (removeSelected)="deleteSelected()"
>
</portal-table-layout>