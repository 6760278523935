<div *ngIf="dropdownSelector" class="selector-container">
  <mat-form-field>
    <mat-label>{{ dropdownSelector.selectorLabel }}</mat-label>
    <mat-select 
      #select
      [value]="dropdownSelector.getSelectorValueFromElement(dropdownSelector.selectorElement)"
      (selectionChange)="dropdownSelector.onSelectionChange($event.value)"
      (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select)"
    >
      <mat-option *ngIf="hasBlankOption" value=""></mat-option>
      <mat-option 
        *ngFor="let option of dropdownSelector.selectorData"
        value="{{ dropdownSelector.getSelectorOptionValue(option) }}"
      >
        {{ dropdownSelector.getSelectorOptionDisplayValue(option) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-icon
    *ngIf="dropdownSelector.selectorTooltipText !== ''"
    class="help-icon"
    [matTooltip]="dropdownSelector.selectorTooltipText"
  >
    help_outline
  </mat-icon>
</div>