import { concat, forkJoin, interval, Observable, of, timer } from 'rxjs';
import {
  AgentConnectorDynamicStats,
  ConfigureConnectorStatsPublishingRequest,
  Connector,
  ConnectorsService,
  ConnectorStaticStats,
  ListConnectorStatsRequestParams,
} from '@agilicus/angular';
import { catchError, map, mergeMap, startWith, take } from 'rxjs/operators';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';

export interface AgentConnectorDynamicStatsWithConnectorId {
  stats: AgentConnectorDynamicStats;
  connector_id: string;
  in_error: boolean;
}

/**
 * Will publish the connector stats every 5 seconds for a 1 minute duration
 */
export function connectorConfigureUpstreamNetworkPublishingInitialFrequent$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  return connectorsService.createConfigurePublishingRequest({
    ConfigureConnectorStatsPublishingRequest: {
      org_id: orgId,
      connector_ids: connectors.map((connector) => connector.metadata.id),
      stats_publishing_config: {
        upstream_network_publishing: {
          summary_duration_seconds: 60, // 1 minute
        },
        upstream_http_publishing: {},
        publish_period_seconds: 5,
      },
    },
  });
}

/**
 * Will publish the connector stats every 30 seconds for a 10 minute duration
 */
export function connectorConfigureUpstreamNetworkPublishingDefault$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  return connectorsService.createConfigurePublishingRequest({
    ConfigureConnectorStatsPublishingRequest: {
      org_id: orgId,
      connector_ids: connectors.map((connector) => connector.metadata.id),
      stats_publishing_config: {
        upstream_network_publishing: {
          summary_duration_seconds: 600, // 10 minutes
        },
        upstream_http_publishing: {},
        publish_period_seconds: 30,
      },
    },
  });
}

/**
 * Will continue to publish the connector stats every 7.5 minutes for
 * 30 seconds and a 10 minute duration
 */
export function startDefaultConnectorStatsPublishInterval$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  return interval(7.5 * 60 * 1000) // 7.5 minutes
    .pipe(startWith(0))
    .pipe(mergeMap(() => connectorConfigureUpstreamNetworkPublishingDefault$(connectorsService, orgId, connectors)));
}

/**
 * Will initially publish the connector stats every 5 seconds for a 1 minute duration,
 * then will continue to publish the connector stats every 7.5 minutes for
 * 30 seconds and a 10 minute duration
 */
export function startFullConnectorStatsPublish$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  const connectorStatsPublishInitialFrequentInterval$ = connectorConfigureUpstreamNetworkPublishingInitialFrequent$(
    connectorsService,
    orgId,
    connectors
  );
  // Wait 30 seconds after first publish, then start the next publish interval:
  const connectorStatsPublishRegularInterval$ = timer(30000).pipe(
    mergeMap((_) => startDefaultConnectorStatsPublishInterval$(connectorsService, orgId, connectors))
  );
  return concat(connectorStatsPublishInitialFrequentInterval$, connectorStatsPublishRegularInterval$);
}

/**
 * Will publish the detailed connector stats every 5 seconds for a 1 minute duration
 */
export function connectorConfigureDetailedUpstreamNetworkPublishingInitialFrequent$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  return connectorsService.createConfigurePublishingRequest({
    ConfigureConnectorStatsPublishingRequest: {
      org_id: orgId,
      connector_ids: !connectors ? [] : connectors.map((connector) => connector?.metadata.id),
      stats_publishing_config: {
        upstream_network_publishing: {
          summary_duration_seconds: 60, // 1 minute
          detailed_duration_seconds: 60, // 1 minute
        },
        upstream_http_publishing: {
          summary_duration_seconds: 60, // 1 minute
          detailed_duration_seconds: 60, // 1 minute
        },
        upstream_share_publishing: {
          summary_duration_seconds: 60, // 1 minute
          detailed_duration_seconds: 60, // 1 minute
        },
        publish_period_seconds: 5,
      },
    },
  });
}

/**
 * Will publish the detailed connector stats every 30 seconds for a 10 minute duration
 */
export function connectorConfigureDetailedUpstreamNetworkPublishingDefault$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  return connectorsService.createConfigurePublishingRequest({
    ConfigureConnectorStatsPublishingRequest: {
      org_id: orgId,
      connector_ids: connectors.map((connector) => connector.metadata.id),
      stats_publishing_config: {
        upstream_network_publishing: {
          summary_duration_seconds: 600, // 10 minutes
          detailed_duration_seconds: 600, // 10 minutes
        },
        upstream_http_publishing: {
          summary_duration_seconds: 600, // 10 minutes
          detailed_duration_seconds: 600, // 10 minutes
        },
        upstream_share_publishing: {
          summary_duration_seconds: 600, // 10 minutes
          detailed_duration_seconds: 600, // 10 minutes
        },
        publish_period_seconds: 30,
      },
    },
  });
}

/**
 * Will continue to publish the detailed connector stats every 7.5 minutes for
 * 30 seconds and a 10 minute duration
 */
export function startDetailedDefaultConnectorStatsPublishInterval$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  return interval(7.5 * 60 * 1000) // 7.5 minutes
    .pipe(startWith(0))
    .pipe(mergeMap(() => connectorConfigureDetailedUpstreamNetworkPublishingDefault$(connectorsService, orgId, connectors)));
}

/**
 * Will initially publish the detailed connector stats every 5 seconds for a 1 minute duration,
 * then will continue to publish the detailed connector stats every 7.5 minutes for
 * 30 seconds and a 10 minute duration
 */
export function startFullDetailedConnectorStatsPublish$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>
): Observable<ConfigureConnectorStatsPublishingRequest> {
  const connectorStatsPublishInitialFrequentInterval$ = connectorConfigureDetailedUpstreamNetworkPublishingInitialFrequent$(
    connectorsService,
    orgId,
    connectors
  );
  // Wait 30 seconds after first publish, then start the next publish interval:
  const connectorStatsPublishRegularInterval$ = timer(30000).pipe(
    mergeMap((_) => startDetailedDefaultConnectorStatsPublishInterval$(connectorsService, orgId, connectors))
  );
  return concat(connectorStatsPublishInitialFrequentInterval$, connectorStatsPublishRegularInterval$);
}

export function getFailedDynamicStats(connectorId: string): AgentConnectorDynamicStatsWithConnectorId {
  const emptyStat: AgentConnectorDynamicStats = {
    metadata: {
      collection_time: undefined,
    },
    upstream_totals: undefined,
  };
  return {
    stats: emptyStat,
    connector_id: connectorId,
    in_error: true,
  };
}

export function getSingleConnectorDynamicStatsWithId$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectorId: string,
  collectedSinceDate: string
): Observable<AgentConnectorDynamicStatsWithConnectorId> {
  return connectorsService
    .getAgentConnectorDynamicStats(
      {
        org_id: orgId,
        connector_id: connectorId,
        collected_since: collectedSinceDate as any,
      },
      'body',
      getIgnoreErrorsHeader()
    )
    .pipe(
      map((resp) => {
        const statsWithId: AgentConnectorDynamicStatsWithConnectorId = {
          stats: resp,
          connector_id: connectorId,
          in_error: false,
        };
        return statsWithId;
      }),
      catchError((_) => {
        return of(getFailedDynamicStats(connectorId));
      })
    );
}

export function getConnectorDynamicStatsObservablesList$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>,
  collectedSinceDate: string
): Observable<Array<AgentConnectorDynamicStatsWithConnectorId> | undefined> {
  const connectorDynamicStatsObservablesArray$: Array<Observable<AgentConnectorDynamicStatsWithConnectorId | undefined>> = [];
  for (const connector of connectors) {
    connectorDynamicStatsObservablesArray$.push(
      getSingleConnectorDynamicStatsWithId$(connectorsService, orgId, connector.metadata.id, collectedSinceDate)
    );
  }
  return forkJoin(connectorDynamicStatsObservablesArray$);
}

/**
 * Will poll for the connector dynamic stats every 5 seconds for 1 minute
 */
export function getConnectorDynamicStatsListQuickPoll$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>,
  collectedSinceDate: string
): Observable<Array<AgentConnectorDynamicStatsWithConnectorId>> {
  return interval(5000) // 5 seconds
    .pipe(startWith(0))
    .pipe(take(12))
    .pipe(
      mergeMap(() => {
        return getConnectorDynamicStatsObservablesList$(connectorsService, orgId, connectors, collectedSinceDate);
      })
    );
}

/**
 * Will continue to poll for the connector dynamic stats every 15 seconds
 */
export function getConnectorDynamicStatsListDefaultPoll$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>,
  collectedSinceDate: string
): Observable<Array<AgentConnectorDynamicStatsWithConnectorId>> {
  return interval(15000) // 15 seconds
    .pipe(startWith(0))
    .pipe(
      mergeMap(() => {
        return getConnectorDynamicStatsObservablesList$(connectorsService, orgId, connectors, collectedSinceDate);
      })
    );
}

/**
 * Will first poll for the connector dynamic stats every 5 seconds for 1 minute,
 * then will continue to poll for the connector dynamic stats every 15 seconds
 */
export function getFullConnectorDynamicStatsList$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  connectors: Array<Connector>,
  collectedSinceDate: string
): Observable<Array<AgentConnectorDynamicStatsWithConnectorId>> {
  const connectorDynamicStatsListQuickPoll$ = getConnectorDynamicStatsListQuickPoll$(
    connectorsService,
    orgId,
    connectors,
    collectedSinceDate
  );

  // Wait 15 seconds after first interval completes, then start the next interval:
  const connectorDynamicStatsListDefaultPoll$ = timer(15000).pipe(
    mergeMap((_) => getConnectorDynamicStatsListDefaultPoll$(connectorsService, orgId, connectors, collectedSinceDate))
  );
  return concat(connectorDynamicStatsListQuickPoll$, connectorDynamicStatsListDefaultPoll$);
}

export function getConnectorElementDynamicStatsDisplayValue(
  statsData: AgentConnectorDynamicStatsWithConnectorId | undefined,
  targetResult: number | undefined,
  fetchCount: number
): string {
  if (targetResult === undefined && fetchCount < 5) {
    return 'Fetching data';
  }
  if (targetResult === undefined || statsData?.in_error) {
    return 'Not Available';
  }
  return targetResult.toString();
}

export function getConnectorElementStaticStatsDisplayValue(targetResult: string | undefined, fetchCount: number): string {
  if (targetResult === undefined && fetchCount < 5) {
    return 'Fetching data';
  }
  if (targetResult === undefined) {
    return 'Not Available';
  }
  return targetResult.toString();
}

export function getConnectorStaticStats$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  collectedSinceDate: string,
  connectorIds?: Array<string> | undefined
): Observable<Array<ConnectorStaticStats>> {
  const params: ListConnectorStatsRequestParams = {
    org_id: orgId,
    show_static: true,
    collected_since: collectedSinceDate as any,
  };
  if (!!connectorIds && connectorIds.length !== 0) {
    params.connector_id_list = connectorIds;
  }
  return connectorsService.listConnectorStats(params).pipe(map((resp) => resp.static_stats));
}

export function getConnectorDynamicStats$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  collectedSinceDate: string,
  connectorIds?: Array<string> | undefined
): Observable<Array<AgentConnectorDynamicStats>> {
  const params: ListConnectorStatsRequestParams = {
    org_id: orgId,
    show_dynamic: true,
    collected_since: collectedSinceDate as any,
  };
  if (!!connectorIds && connectorIds.length !== 0) {
    params.connector_id_list = connectorIds;
  }
  return connectorsService.listConnectorStats(params).pipe(map((resp) => resp.dynamic_stats));
}
