import { SignupState } from './signup.models';
import { SignupActions, SignupActionTypes } from './signup.actions';

export const signupInitialState: SignupState = {
  is_signing_up: false,
  initial_auth_complete: false,
  create_complete: false,
  signup_bounce: false,
  final_auth: false,
  signup_org_id: undefined,
  signup_complete: false,
  selected_index: 0,
  redirect_uri: undefined,
  is_redirecting: false,
  billing_account_id: undefined,
  product_label_override: undefined,
};

export function signupReducer(state: SignupState = signupInitialState, action: SignupActions): SignupState {
  switch (action.type) {
    case SignupActionTypes.SIGNUP_SET_SELECTED_INDEX:
      return {
        ...state,
        selected_index: action.selected_index,
      };
    case SignupActionTypes.SIGNUP_SET_BILLING_ACCOUNT_ID:
      return {
        ...state,
        billing_account_id: action.billing_account_id,
      };
    case SignupActionTypes.SIGNUP_SET_PRODUCT_LABEL_OVERRIDE:
      return {
        ...state,
        product_label_override: action.product_label_override,
      };
    case SignupActionTypes.INIT:
      return initState(state);
    case SignupActionTypes.SIGNUP_AUTH_COMPLETE:
      return {
        ...state,
        initial_auth_complete: true,
        signup_org_id: action.org_id,
        is_redirecting: false,
      };
    case SignupActionTypes.SIGNUP_CREATE_COMPLETE:
      return {
        ...state,
        create_complete: true,
        redirect_uri: action.redirectUri,
        is_redirecting: false,
      };
    case SignupActionTypes.SIGNUP_BOUNCE:
      return {
        ...state,
        signup_bounce: true,
        // signup bounce triggers the redirect to sign into the new org
        // so we need to set 'is_redirecting' to true here
        is_redirecting: true,
      };
    case SignupActionTypes.SIGNUP_RE_AUTH:
      return {
        ...state,
        selected_index: 3,
        is_signing_up: true,
        initial_auth_complete: true,
        create_complete: true,
        final_auth: true,
        signup_complete: false,
      };
    case SignupActionTypes.SIGNUP_COMPLETE:
      return {
        ...state,
        selected_index: 3,
        is_signing_up: false,
        initial_auth_complete: true,
        create_complete: true,
        final_auth: true,
        signup_complete: true,
        is_redirecting: false,
      };
    case SignupActionTypes.CANCEL_SIGNUP:
      return {
        ...state,
        is_signing_up: false,
        initial_auth_complete: false,
        create_complete: false,
        signup_bounce: false,
        final_auth: false,
        signup_complete: false,
        selected_index: 0,
        redirect_uri: undefined,
      };
    case SignupActionTypes.SIGNUP_REDIRECTING:
      return {
        ...state,
        is_redirecting: true,
      };
    case SignupActionTypes.SIGNUP_BEGIN:
      return {
        ...state,
        is_signing_up: true,
      };
    default:
      return state;
  }
}

function initState(state: SignupState): SignupState {
  if (state.is_signing_up) {
    // We're in the middle of signup. Return the existing state.
    return state;
  }
  // New signup. Start from scratch.
  return {
    ...state,
    selected_index: 0,
    is_signing_up: false,
    initial_auth_complete: false,
    create_complete: false,
    signup_complete: false,
    is_redirecting: false,
  };
}
