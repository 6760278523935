import { Issuer, IssuersService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { createNewIssuer, deleteIssuer, getIssuerById, getListOfIssuers, updateExistingIssuer } from '../issuer-state/issuer.utils';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import * as IssuerActions from '../issuer-state/issuer.actions';

@Injectable({
  providedIn: 'root',
})
export class IssuerStateService extends SimpleCRUDInterface<Issuer, string> {
  public crudRegistryName = CrudStateCollection.issuer;

  constructor(
    public store: Store<AppState>,
    private issuersService: IssuersService,
    private crudRegistryService: CrudRegistryService<Issuer, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<Issuer, string> = {
        create: (id: string, obj: Issuer) => createNewIssuer(this.issuersService, obj),
        update: (id: string, obj: Issuer) => updateExistingIssuer(this.issuersService, obj),
        get: (id: string, orgId: string) => getIssuerById(this.issuersService, id, orgId),
        list: (orgId: string) => getListOfIssuers(this.issuersService, orgId),
        delete: (id: string, orgId: string) => deleteIssuer(this.issuersService, id, orgId),
      };
      const crudRegistry: CrudRegistry<Issuer, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: Issuer) => obj.issuer,
        doneSaveAction: IssuerActions.upsertIssuer,
        setListAction: IssuerActions.loadIssuers,
        refreshStateAction: IssuerActions.refreshIssuer,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
