import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { PolicyTemplateInstance } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadPolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Load PolicyTemplateInstances',
  props<{ objs: PolicyTemplateInstance[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addPolicyTemplateInstance = createAction(
  '[PolicyTemplateInstance/API] Add PolicyTemplateInstance',
  props<{ obj: PolicyTemplateInstance }>()
);

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertPolicyTemplateInstance = createAction(
  '[PolicyTemplateInstance/API] Upsert PolicyTemplateInstance',
  props<{ obj: PolicyTemplateInstance; refreshData: boolean }>()
);

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addPolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Add PolicyTemplateInstances',
  props<{ objs: PolicyTemplateInstance[] }>()
);

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertPolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Upsert PolicyTemplateInstances',
  props<{ objs: PolicyTemplateInstance[]; refreshData: boolean }>()
);

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updatePolicyTemplateInstance = createAction(
  '[PolicyTemplateInstance/API] Update PolicyTemplateInstance',
  props<{ obj: Update<PolicyTemplateInstance> }>()
);

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updatePolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Update PolicyTemplateInstances',
  props<{ objs: Update<PolicyTemplateInstance>[] }>()
);

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deletePolicyTemplateInstance = createAction(
  '[PolicyTemplateInstance/API] Delete PolicyTemplateInstance',
  props<{ id: string; obj: PolicyTemplateInstance; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deletePolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Delete PolicyTemplateInstances',
  props<{ ids: string[]; refreshData?: boolean }>()
);

/**
 * Will delete all data from the ngrx entity state
 */
export const clearPolicyTemplateInstances = createAction('[PolicyTemplateInstance/API] Clear PolicyTemplateInstances');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initPolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Init PolicyTemplateInstances',
  props<{ force: boolean; blankSlate: boolean }>()
);

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getPolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Get PolicyTemplateInstance',
  props<{ org_id: string; blankSlate: boolean }>()
);

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingPolicyTemplateInstance = createAction(
  '[PolicyTemplateInstance/API] Saving PolicyTemplateInstance',
  props<{ obj: PolicyTemplateInstance; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingPolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Saving PolicyTemplateInstances',
  props<{ objs: Array<PolicyTemplateInstance>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingPolicyTemplateInstance = createAction(
  '[PolicyTemplateInstance/API] Deleting PolicyTemplateInstance',
  props<{ obj: PolicyTemplateInstance; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingPolicyTemplateInstances = createAction(
  '[PolicyTemplateInstance/API] Deleting PolicyTemplateInstances',
  props<{ objs: Array<PolicyTemplateInstance>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshPolicyTemplateInstances = createAction('[PolicyTemplateInstance/API] Refresh PolicyTemplateInstances');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainPolicyTemplateInstances = createAction('[PolicyTemplateInstance/API] Maintain PolicyTemplateInstances');
