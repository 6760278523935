import { FileShareService, ApplicationServicesService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import {
  createFileShareService,
  deleteExistingFileShareService,
  getFileShareServicesById,
  getFileShareServicesList,
  updateExistingFileShareService,
} from '../application-service-state/application-services-utils';
import * as FileShareServiceActions from '../file-share-state/file-share-service.actions';

@Injectable({
  providedIn: 'root',
})
export class FileShareServiceStateService extends SimpleCRUDInterface<FileShareService, string> {
  public crudRegistryName = CrudStateCollection.file_share_service;

  constructor(
    public store: Store<AppState>,
    private applicationServicesService: ApplicationServicesService,
    private crudRegistryService: CrudRegistryService<FileShareService, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<FileShareService, string> = {
        create: (id: string, obj: FileShareService, orgId: string) => createFileShareService(this.applicationServicesService, obj),
        update: (id: string, obj: FileShareService, orgId: string) => updateExistingFileShareService(this.applicationServicesService, obj),
        get: (id: string, orgId: string) => getFileShareServicesById(this.applicationServicesService, id, orgId),
        list: (orgId: string) => getFileShareServicesList(this.applicationServicesService, orgId),
        delete: (id: string, orgId: string) => deleteExistingFileShareService(this.applicationServicesService, id, orgId),
      };
      const crudRegistry: CrudRegistry<FileShareService, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: FileShareService) => obj.spec.name,
        doneSaveAction: FileShareServiceActions.upsertFileShareService,
        doneSaveListAction: FileShareServiceActions.upsertFileShareServices,
        doneDeleteAction: FileShareServiceActions.deleteFileShareService,
        doneDeleteListAction: FileShareServiceActions.deleteFileShareServices,
        setListAction: FileShareServiceActions.loadFileShareServices,
        refreshStateAction: FileShareServiceActions.refreshFileShareServices,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
