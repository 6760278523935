<portal-page-info
  *ngIf="showPageInfo"
  pageTitle="Application Permissions"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="permissionsDefined() && !hasPermissions()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="permissionsDefined() && hasPermissions()"
  class="permissions-admin-container"
>
  <portal-table-layout
    #tableLayoutComp
    [columnDefs]="columnDefs"
    [filterMenuOptions]="filterMenuOptions"
    [filterManager]="filterManager"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    [rowObjectName]="rowObjectName"
    [buttonsToShow]="buttonsToShow"
    [paginatorConfig]="paginatorConfig"
    [linkDataSource] = "linkDataSource"
    (updateEvent)="updateEvent($event)"
    (updateAutoInput)="updateAutoInput($event)"
    (removeSelected)="removeSelected($event)"
    (updateSelection)="updateRoles($event)"
  >
  </portal-table-layout>
</div>
