import { NavMenuItem } from './submenu.component';
import { MergedRoute } from '@app/core/merged-route';
import { cloneDeep } from 'lodash-es';

/**
 * Will open the side menu of the active link and all of its parent menus.
 * Will return the nav menu item of the target route or undefined if it does not exist.
 */
export function getAndOpenTargetRouteMenu(menuItems: Array<NavMenuItem>, targetRoute: string): NavMenuItem | undefined {
  if (!targetRoute) {
    return undefined;
  }
  for (const item of menuItems) {
    if (!!item.items) {
      const selectedMenuItem = getAndOpenTargetRouteMenu(item.items, targetRoute);
      if (!!selectedMenuItem) {
        item.showMenu = true;
        return item;
      }
    }
    if (targetRoute.startsWith('/' + item.routerLink)) {
      item.showMenu = true;
      return item;
    }
  }
  return undefined;
}

/**
 * Will open the side menu of the active link and all of its parent menus.
 * Will return the nav menu item of the active route or undefined if it does not exist.
 */
export function getAndOpenActiveLink(menuItems: Array<NavMenuItem>, mergedRoute: MergedRoute): NavMenuItem {
  return getAndOpenTargetRouteMenu(menuItems, mergedRoute?.url);
}

export function getSideNavMenuWithTargetRouteOpen(menuItems: Array<NavMenuItem>, targetRoute: string): Array<NavMenuItem> | undefined {
  const copyOfMenuItems = cloneDeep(menuItems);
  const targetMenuItem = getAndOpenTargetRouteMenu(copyOfMenuItems, `/${targetRoute}`);
  if (!targetMenuItem) {
    return undefined;
  }
  return copyOfMenuItems;
}

export function getSideNavMenuWithActiveLinkOpen(menuItems: Array<NavMenuItem>, mergedRoute: MergedRoute): Array<NavMenuItem> {
  const copyOfMenuItems = cloneDeep(menuItems);
  const activeMenuItem = getAndOpenActiveLink(copyOfMenuItems, mergedRoute);
  if (!activeMenuItem) {
    return undefined;
  }
  return copyOfMenuItems;
}

export function findTargetMenuItem(targetNavMenuItem: NavMenuItem, menuItems: Array<NavMenuItem>): NavMenuItem | undefined {
  for (const item of menuItems) {
    if (item.id === targetNavMenuItem.id) {
      return item;
    }
    if (!!item.items) {
      const selectedMenuItem = findTargetMenuItem(targetNavMenuItem, item.items);
      if (!!selectedMenuItem) {
        return selectedMenuItem;
      }
    }
    return undefined;
  }
  return undefined;
}
