import { createSelector } from '@ngrx/store';
import { selectApplicationServicesState } from '../core.state';
import { ApplicationServicesState, selectAll, selectEntities } from './application-service.reducer';

export const selectApplicationServices = createSelector(selectApplicationServicesState, (state: ApplicationServicesState) => state);

export const selectApplicationServicesList = createSelector(selectApplicationServices, selectAll);

export const selectApplicationServicesEntities = createSelector(selectApplicationServices, selectEntities);

export const selectApplicationServicesEntity = (props: { id: string }) =>
  createSelector(selectApplicationServicesEntities, (entities) => {
    return entities[props.id];
  });

export const selectApplicationServicesRefreshDataValue = createSelector(
  selectApplicationServices,
  (state: ApplicationServicesState) => state.refresh_data
);

export const selectApplicationServicesShouldPopulateValue = createSelector(
  selectApplicationServices,
  (state: ApplicationServicesState) => state.should_populate
);
