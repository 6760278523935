import { IssuerClientsState } from './issuer-clients.models';
import { IssuerClientsActions, IssuerClientsActionTypes } from './issuer-clients.actions';
import { IssuerClient } from '@agilicus/angular';
import { cloneDeep } from 'lodash-es';

export const issuerClientsInitialState: IssuerClientsState = {
  current_issuer_client_list: [],
  saving_issuer_client: false,
  loaded_org_id: undefined,
  successful_issuer_client_save: false,
  saving_upstream_alias: false,
  should_populate: false,
  refresh_data: 0,
};

export function issuerClientsReducer(
  state: IssuerClientsState = issuerClientsInitialState,
  action: IssuerClientsActions
): IssuerClientsState {
  switch (action.type) {
    case IssuerClientsActionTypes.INIT:
      return {
        ...state,
        should_populate: true,
      };

    case IssuerClientsActionTypes.SET_ISSUER_CLIENT_LIST:
      return {
        ...state,
        current_issuer_client_list: action.current_issuer_client_list,
        saving_issuer_client: false,
        loaded_org_id: action.org_id,
        successful_issuer_client_save: false,
        refresh_data: state.refresh_data + 1,
      };

    case IssuerClientsActionTypes.LOAD_ISSUER_CLIENTS:
      return {
        ...state,
        loaded_org_id: action.org_id,
      };

    case IssuerClientsActionTypes.SAVING_ISSUER_CLIENT:
      return {
        ...state,
        saving_issuer_client: true,
        successful_issuer_client_save: false,
      };

    case IssuerClientsActionTypes.SUCCESSFUL_ISSUER_CLIENT_SAVE:
      return {
        ...state,
        current_issuer_client_list: getUpdatedIssuerClients(action.current_issuer_client, state.current_issuer_client_list),
        saving_issuer_client: false,
        successful_issuer_client_save: true,
        refresh_data: state.refresh_data + 1,
      };

    case IssuerClientsActionTypes.FAILED_ISSUER_CLIENT_SAVE:
      return {
        ...state,
        saving_issuer_client: false,
        successful_issuer_client_save: false,
      };

    case IssuerClientsActionTypes.SUCCESSFUL_ISSUER_CLIENT_DELETION:
      return {
        ...state,
        current_issuer_client_list: deleteIssuerClient(action.issuer_client_to_delete, state.current_issuer_client_list),
        refresh_data: state.refresh_data + 1,
      };

    case IssuerClientsActionTypes.REFRESH_ISSUER_CLIENT_STATE:
      return {
        ...state,
      };

    case IssuerClientsActionTypes.SAVING_UPSTREAM_ALIAS:
      return {
        ...state,
        saving_upstream_alias: true,
      };

    case IssuerClientsActionTypes.SUCCESSFUL_UPSTREAM_ALIAS_UPDATE:
      return {
        ...state,
        saving_upstream_alias: false,
      };

    case IssuerClientsActionTypes.FAILED_UPSTREAM_ALIAS_UPDATE:
      return {
        ...state,
        saving_upstream_alias: false,
      };

    case IssuerClientsActionTypes.CLEAR_STATE:
      return {
        ...state,
        ...issuerClientsInitialState,
        should_populate: state.should_populate,
        loaded_org_id: state.loaded_org_id,
        refresh_data: state.refresh_data + 1,
      };

    // The following action types fall to the default:
    // DELETING_ISSUER_CLIENT
    // FAILED_ISSUER_CLIENT_DELETION
    default:
      return state;
  }
}

export function getUpdatedIssuerClients(
  updatedIssuerClient: IssuerClient,
  currentIssuerClientList: Array<IssuerClient>
): Array<IssuerClient> {
  const currentIssuerClientsCopy: Array<IssuerClient> = currentIssuerClientList.map((issuerClient) => cloneDeep(issuerClient));
  const targetIndex = currentIssuerClientsCopy.findIndex((issuerClient: IssuerClient) => issuerClient.id === updatedIssuerClient.id);
  if (targetIndex !== -1) {
    currentIssuerClientsCopy[targetIndex] = updatedIssuerClient;
  } else {
    currentIssuerClientsCopy.unshift(updatedIssuerClient);
  }
  return currentIssuerClientsCopy;
}

export function deleteIssuerClient(issuerClientToDelete: IssuerClient, currentIssuerClientList: Array<IssuerClient>): Array<IssuerClient> {
  const currentIssuerClientsCopy: Array<IssuerClient> = cloneDeep(currentIssuerClientList);
  const targetIndex = currentIssuerClientsCopy.findIndex((issuerClient: IssuerClient) => issuerClient.id === issuerClientToDelete.id);
  currentIssuerClientsCopy.splice(targetIndex, 1);
  return currentIssuerClientsCopy;
}
