import {
  AuthenticationDocument,
  BulkSessionOperationResponse,
  BulkTokenRevokeResponse,
  CreateAuthenticationDocumentRequestParams,
  CreateBulkRevokeSessionTaskRequestParams,
  Organisation,
  TokensService,
} from '@agilicus/angular';
import { ButtonColor, RowScopedButton, TableButton } from '@app/shared/components/buttons/table-button/table-button.component';
import { TableElement } from '@app/shared/components/table-layout/table-element';
import { pluralizeString } from '@app/shared/components/utils';
import { concatMap, Observable } from 'rxjs';

export function getAuthenticationDocument(userId: string, organisation: Organisation): AuthenticationDocument {
  return {
    spec: {
      user_id: userId,
      org_id: organisation.id,
      auth_issuer_url: organisation.issuer,
    },
  };
}

export function createAuthenticationDocument(
  tokensService: TokensService,
  userId: string,
  organisation: Organisation
): Observable<AuthenticationDocument> {
  const createAuthenticationDocumentRequestParams: CreateAuthenticationDocumentRequestParams = {
    AuthenticationDocument: getAuthenticationDocument(userId, organisation),
  };
  return tokensService.createAuthenticationDocument(createAuthenticationDocumentRequestParams);
}

export function bulkRevokeUserSession$(
  tokensService: TokensService,
  userId: string,
  orgId: string
): Observable<BulkSessionOperationResponse> {
  const params: CreateBulkRevokeSessionTaskRequestParams = {
    UserSessionIdentifiers: {
      user_id: userId,
      org_id: orgId,
    },
  };
  return tokensService.createBulkRevokeSessionTask(params);
}

export function bulkRevokeUserSessionAndTokens$(
  tokensService: TokensService,
  userId: string,
  orgId: string
): Observable<BulkTokenRevokeResponse> {
  return bulkRevokeUserSession$(tokensService, userId, orgId).pipe(
    concatMap((sessionRevokeResp) => {
      return tokensService.createBulkRevokeTokenTask({
        BulkTokenRevoke: {
          user_id: userId,
          org_id: orgId,
        },
      });
    })
  );
}

export function getRevokeSessionsButton<T extends TableElement>(
  userType: string,
  revokeSelected: (usersToRevoke: Array<T>) => void
): TableButton {
  return new RowScopedButton(
    'REVOKE SESSIONS',
    ButtonColor.WARN,
    `Click to revoke all sessions for the ${pluralizeString(userType)}`,
    `Revoke all sessions for the selected ${pluralizeString(userType)}`,
    () => `Please select the ${pluralizeString(userType)} who will have their sessions revoked`,
    `Button container that displays a tooltip when the revoke sessions button is disabled`,
    (usersToRevoke: Array<T>) => {
      revokeSelected(usersToRevoke);
    }
  );
}
