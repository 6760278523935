<portal-page-info
  pageTitle="Agilicus Managed Upstream Providers"
  [productGuideLink]="productGuideLink"
  [pageDescriptiveTextWithImageWrap]="pageDescriptiveTextWithImageWrap"
  [pageDescriptiveHelpImageWithTextWrap]="pageDescriptiveHelpImageWithTextWrap"
>
</portal-page-info>

<div class="authentication-group">

  <div *ngIf="hasIssuersPermissions !== undefined && !hasIssuersPermissions">
    <portal-no-permissions></portal-no-permissions>
  </div>
  
  <div *ngIf="hasIssuersPermissions">
    <div *ngIf="currentOrgIssuer === undefined && issuerCopy === undefined" class="no-info">
      <h2>No issuer information available.</h2>
    </div>
  
    <div *ngIf="currentOrgIssuer !== undefined"class="issuer-url title">
      <h5>Issuer: {{ currentOrgIssuer }}</h5>
    </div>
  
    <div *ngIf="issuerCopy !== undefined" class="issuer-container">
      <div *ngIf="issuerCopy?.managed_upstreams !== undefined" class="managed-upstreams section">
          <mat-checkbox
            class="checkbox-content-wrapper"
            *ngFor="let upstream of getManagedUpstreamsListWithoutMicrosoft(issuerCopy)"
            [checked]="upstream.enabled"
            (change)="managedUpstreamProviderChanged(upstream)"
            aria-label="Checkbox that toggles whether the managed upstream provider is selected"
          >
            {{ formatManagedUpstreamDisplayValue(upstream.name) }}
          </mat-checkbox>

          <div *ngIf="!!getMicrosoftManagedUpstream(issuerCopy)" class="microsoft-upstream-container">
            <mat-checkbox
              class="checkbox-content-wrapper"
              [checked]="getMicrosoftManagedUpstream(issuerCopy).enabled"
              (change)="managedUpstreamProviderChanged(getMicrosoftManagedUpstream(issuerCopy))"
              aria-label="Checkbox that toggles whether the managed upstream provider is selected"
            >
              {{ formatManagedUpstreamDisplayValue(getMicrosoftManagedUpstream(issuerCopy).name) }}
            </mat-checkbox>

            <div class="prompt-select-account-container">
              <mat-checkbox
                class="checkbox-content-wrapper"
                [checked]="getMicrosoftPromptSelectAccountValue(issuerCopy)"
                (change)="onMicrosoftPromptSelectAccountValueChange()"
                aria-label="Checkbox that toggles whether the microsoft prompt select account option is selected"
                [disabled]="!getMicrosoftManagedUpstream(issuerCopy).enabled"
              >
                For users with multiple microsoft accounts, force them to select a microsoft account explicitly
              </mat-checkbox>
            </div>
          </div>
      </div>
    </div>
  </div>
  
</div>