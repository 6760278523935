<h2>{{ data.upstreamName }}</h2>
<div class="dialog-container">
  <mat-dialog-content>
    <portal-upstream-group-mappings
      [upstreamGroupMapping]="data.upstreamGroupMapping"
      [upstreamName]="data.upstreamName"
      [orgId]="data.orgId"
      (updateIssuerGroupMapping)="updateIssuerGroupMapping($event)"
    >
    </portal-upstream-group-mappings>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="onConfirmClick()" tabindex="1">{{ confirmButtonText }}</button>
  </mat-dialog-actions>
</div>