import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as AgentConnectorActions from './agent-connector.actions';
import { AgentConnector } from '@agilicus/angular';
import { BasicCollectionState } from '@app/core/basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';

export const agentConnectorsFeatureKey = 'agentConnector';

export type AgentConnectorState = BasicCollectionState<AgentConnector>;

export const adapter: EntityAdapter<AgentConnector> = createEntityAdapter<AgentConnector>({
  sortComparer: sortListByCreatedDate,
  selectId: (connector) => connector.metadata.id,
});

export const initialState: AgentConnectorState = adapter.getInitialState(getDefaultBasicCollectionState<AgentConnector>());

export const agentConnectorReducer = createReducer(
  initialState,
  on(AgentConnectorActions.addAgentConnector, (state, action) => adapter.addOne(action.obj, state)),
  on(AgentConnectorActions.upsertAgentConnector, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(AgentConnectorActions.addAgentConnectors, (state, action) => adapter.addMany(action.objs, state)),
  on(AgentConnectorActions.upsertAgentConnectors, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(AgentConnectorActions.updateAgentConnector, (state, action) => adapter.updateOne(action.obj, state)),
  on(AgentConnectorActions.updateAgentConnectors, (state, action) => adapter.updateMany(action.objs, state)),
  on(AgentConnectorActions.deleteAgentConnector, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(AgentConnectorActions.deleteAgentConnectors, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(AgentConnectorActions.loadAgentConnectors, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(AgentConnectorActions.clearAgentConnectors, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(AgentConnectorActions.savingAgentConnector, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(AgentConnectorActions.savingAgentConnectors, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(AgentConnectorActions.initAgentConnectors, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(AgentConnectorActions.refreshAgentConnectors, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
