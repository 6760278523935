import { Injectable } from '@angular/core';
import { Auth, TokensService } from '@agilicus/angular';
import { DynamicEnvironmentService } from '@app/core/services/dynamic-environment.init';
import { LoginArgs, LoginCallbackArgs, OptionalAuthArgs } from '@agilicus/angular/src/auth';
import { Store } from '@ngrx/store';
import { AppState, selectSignupState } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ActionUserLogout } from '../user/user.actions';

/* Please keep with list in sync with the one in sdk/py/agilicus/main.py */
export const default_scopes: Array<string> = [
  /* Introspect permissions */
  'urn:agilicus:api:users:self?',
  'urn:agilicus:api:challenges:self?',
  /* Viewer Permissions */
  'urn:agilicus:api:applications:viewer?',
  'urn:agilicus:api:metrics:viewer?',
  'urn:agilicus:api:users:viewer?',
  /* Legacy Reader Permissions */
  'urn:agilicus:api:applications:reader?',
  'urn:agilicus:api:sysgroups:reader?',
  /* Owner Permissions */
  'urn:agilicus:api:applications:owner?',
  'urn:agilicus:api:audits:owner?',
  'urn:agilicus:api:diagnostics:owner?',
  'urn:agilicus:api:files:owner?',
  'urn:agilicus:api:issuers:owner?',
  'urn:agilicus:api:orgs:owner?',
  'urn:agilicus:api:sysgroups:owner?',
  'urn:agilicus:api:traffic-tokens:owner?',
  'urn:agilicus:api:traffic-tokens:user?',
  'urn:agilicus:api:users:owner?',
  'urn:agilicus:api:resources:*?',
  'urn:agilicus:api:audit-config:*?',
  'urn:agilicus:api:messages:self?',
  'urn:agilicus:token_payload:multiorg:true',
  'urn:agilicus:api:labels:owner?',
  'urn:agilicus:api:credentials:*?',
  'urn:agilicus:api:rules:owner?',
];

export function construct_scopes(scope_list: string[]): string {
  return scope_list.join(' ');
}

export function construct_issuer(overrideDomain?: string): string {
  const hostname = window.location.hostname;
  let path = hostname.substr(hostname.indexOf('.') + 1);
  if (overrideDomain) {
    path = overrideDomain;
  }
  return 'https://auth.' + path;
}

const lastLoginKey = 'lastLoginAttempt';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public localAuth?: Auth;
  private _numberOrgs: Number;
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    private env: DynamicEnvironmentService,
    private tokens: TokensService,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private router: Router
  ) {
    this.store
      .select(selectSignupState)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((signupState) => {
        this.localAuth = this.auth();
      });
  }

  private buildAuth(args?: OptionalAuthArgs): Auth {
    const issuer = construct_issuer(this.env.environment.overrideDomain);
    const scopes = construct_scopes(default_scopes);
    if (!args) {
      args = {};
    }
    args.explicitLogin = true;
    args.postLoginCallback = this.loginCallback.bind(this);
    return new Auth(this.env.environment.overrideClientId, issuer, this.tokens, scopes, args);
  }

  public auth(args?: OptionalAuthArgs): Auth {
    if (!this.localAuth) {
      // We dynamically build the auth to ensure that we have the proper overrides from
      // the environment: they are not available until just before the components
      // are initialized. Here we assume that this will not be invoked until that point
      // since it usually requires user interaction.
      this.localAuth = this.buildAuth(args);
    }
    return this.localAuth;
  }
  get numberOrgs(): Number {
    return this._numberOrgs;
  }
  set numberOrgs(nOrgs: Number) {
    this._numberOrgs = nOrgs;
  }

  /**
   * This gets added to the cookie when a user had previously selected the
   * "Keep me signed in on this device?" option.
   */
  private getRememberMeCookieKey(): string {
    return 'Agilicus-SSO-rememberme-Cookie';
  }

  public async login(redirectURL?: string): Promise<void> {
    sessionStorage.setItem(lastLoginKey, new Date().toISOString());
    let args: LoginArgs | undefined;
    if (redirectURL) {
      args = {
        redirectURI: redirectURL,
      };
    }
    await this.auth().login(args);
  }

  public lastLoginAttempt(): Date | undefined {
    try {
      const strDate = sessionStorage.getItem(lastLoginKey);
      if (!strDate) {
        return undefined;
      }

      return new Date(strDate);
    } catch (e) {
      return undefined;
    }
  }

  public isRememberMeCookieSet(): boolean {
    const rememberMeCookieValAsString = this.cookieService.get(this.getRememberMeCookieKey());
    if (!rememberMeCookieValAsString) {
      return false;
    }
    try {
      const parsedRememberMeCookieVal: { rememberme: boolean } = JSON.parse(rememberMeCookieValAsString);
      return parsedRememberMeCookieVal.rememberme;
    } catch (e) {
      return false;
    }
  }

  private loginCallback(callbackArgs: LoginCallbackArgs) {
    this.router.navigateByUrl(callbackArgs.defaultRedirect);
  }

  public triggerLogin(): void {
    // Clear the local browser state when a user logs in to ensure no stale state is present.
    window.localStorage.clear();
    sessionStorage.setItem('loginRedirectURI', this.router.url);
    this.login();
  }

  public async triggerLogout(): Promise<void> {
    const url = new URL(window.location.href);
    url.pathname = 'logout-bounce';
    this.store.dispatch(new ActionUserLogout(url.toString()));
  }
}
