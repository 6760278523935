import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ApplicationService } from '@agilicus/angular';
import * as ApplicationServiceActions from './application-service.actions';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { BasicCollectionState } from '../basic-collection-state';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';

export const applicationServicesFeatureKey = 'applicationServices';

export type ApplicationServicesState = BasicCollectionState<ApplicationService>;

export const adapter: EntityAdapter<ApplicationService> = createEntityAdapter<ApplicationService>({
  sortComparer: sortListByCreatedDate,
});

export const initialState: ApplicationServicesState = adapter.getInitialState(getDefaultBasicCollectionState<ApplicationService>());

export const applicationServicesReducer = createReducer(
  initialState,
  on(ApplicationServiceActions.addApplicationService, (state, action) => adapter.addOne(action.obj, state)),
  on(ApplicationServiceActions.upsertApplicationService, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(ApplicationServiceActions.addApplicationServices, (state, action) => adapter.addMany(action.objs, state)),
  on(ApplicationServiceActions.upsertApplicationServices, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(ApplicationServiceActions.updateApplicationService, (state, action) => adapter.updateOne(action.obj, state)),
  on(ApplicationServiceActions.updateApplicationServices, (state, action) => adapter.updateMany(action.objs, state)),
  on(ApplicationServiceActions.deleteApplicationService, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(ApplicationServiceActions.deleteApplicationServices, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(ApplicationServiceActions.loadApplicationServices, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(ApplicationServiceActions.clearApplicationServices, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(ApplicationServiceActions.savingApplicationService, (state, action) => {
    return {
      ...state,
      saving_state: true,
      trigger_update_side_effects: action.trigger_update_side_effects,
      refresh_api_data: action.refreshApiData,
    };
  }),
  on(ApplicationServiceActions.savingApplicationServices, (state, action) => {
    return {
      ...state,
      saving_state: true,
      trigger_update_side_effects: action.trigger_update_side_effects,
      refresh_api_data: action.refreshApiData,
    };
  }),
  on(ApplicationServiceActions.initApplicationServices, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(ApplicationServiceActions.refreshApplicationServices, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  }),
  on(ApplicationServiceActions.resetAppListRefreshFlagApplicationServices, (state, action) => {
    return { ...state, trigger_update_side_effects: false };
  }),
  on(ApplicationServiceActions.resetApiDataRefreshFlagApplicationServices, (state, action) => {
    return { ...state, refresh_api_data: false };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
