<portal-page-info
  [pageTitle]="getOrganisationPageTitle()"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasUsersPermissions !== undefined && !hasUsersPermissions">  
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasUsersPermissions" 
  class="org-admin"
>
  <form 
    *ngIf="organisation !== undefined" 
    class="org-form" 
    [formGroup]="updateOrgForm" 
    (ngSubmit)="onSubmit()"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="form-field-container">
      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Contact Email" 
          formControlName="contact_email" 
          [matAutocomplete]="auto"
          (blur)="submitFormOnBlur($event.target.value, 'contact_email')"
          #trigger="matAutocompleteTrigger"
          (keyup.enter)="keyTabManager.keyTabAutoInput($event.target.id, trigger)"
        >
        <mat-autocomplete 
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of filteredUserOptions$ | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>A valid contact email is required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="External ID" 
          formControlName="external_id" 
          (blur)="submitFormOnBlur($event.target.value, 'external_id')"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        >
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Subdomain" 
          formControlName="subdomain" 
          (blur)="submitFormOnBlur($event.target.value, 'subdomain')"
          [readonly]="organisation.parent_id !== null"
          matTooltip="This field is readonly"
          [matTooltipDisabled]="organisation.parent_id === null"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        >
        <span matPrefix class="prefix">APP.</span>
        <mat-error>A valid subdomain is required</mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          type="number"
          min="0"
          placeholder="Days Until Multi-Factor Enrollment Expiry"
          formControlName="trust_on_first_use_duration"
          (blur)="submitFormOnBlur($event.target.value, 'trust_on_first_use_duration')"
          matTooltip="Users must set up multi-factor authentication within the specified number of days"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        >
        <mat-error>A valid number of days is required</mat-error>
      </mat-form-field>

      <div>
        <mat-checkbox class="right-margin" formControlName="auto_create_users"
          (change)="checkboxChanged()"
          aria-label="Checkbox that toggles whether users are auto created"
        >
          Auto Create Users
        </mat-checkbox>

        <mat-checkbox formControlName="disable_user_requests"
          (change)="checkboxChanged()"
          aria-label="Checkbox that toggles whether users in the organization can see requests"
        >
          Disable User Requests
        </mat-checkbox>
      </div>

      <button
        type="button"
        mat-raised-button
        class="delete-button"
        color="warn"
        matTooltip="Delete this organisation"
        aria-label="Button to delete the current organisation"
        (click)="deleteOrganisation()"
      >
        DELETE ORGANISATION
      </button>
    </div>
  </form>
</div>


<div 
  *ngIf="hasUsersPermissions"
  class="group-table"
>
  <portal-group-admin
    [fixedTable]="fixedTable"
    [selectable]="selectable"
    [groupType]="groupType"
    [title]="title"
    [pageDescriptiveText]="permissionsTableDescriptiveText"
    [hideGroupsList]="hideGroupsList"
    [getCustomTooltip]="getCustomTooltip"
  >
  </portal-group-admin>
</div>
