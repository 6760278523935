import { OrganisationsState } from './organisations.models';
import { OrganisationsActions, OrganisationsActionTypes } from './organisations.actions';
import { Organisation } from '@agilicus/angular';
import { createJsonFromObject } from '@app/shared/components/utils';

export const organisationsInitialState: OrganisationsState = {
  current_organisation: undefined,
  sub_organisations: [],
  all_organisations: [],
  org_id_to_org_name_map: undefined,
  org_name_to_org_id_map: undefined,
};

export function organisationsReducer(
  state: OrganisationsState = organisationsInitialState,
  action: OrganisationsActions
): OrganisationsState {
  switch (action.type) {
    case OrganisationsActionTypes.SET_ORG_STATE:
      return {
        ...state,
        current_organisation: action.current_organisation,
        sub_organisations: action.sub_organisations,
        all_organisations: getAllOrganisations(action.current_organisation, action.sub_organisations),
        org_id_to_org_name_map: createJsonFromObject(
          getAllOrganisations(action.current_organisation, action.sub_organisations),
          'id',
          'organisation'
        ),
        org_name_to_org_id_map: createJsonFromObject(
          getAllOrganisations(action.current_organisation, action.sub_organisations),
          'organisation',
          'id'
        ),
      };
    case OrganisationsActionTypes.SET_CURRENT_ORG:
      return {
        ...state,
        current_organisation: action.current_organisation,
      };

    default:
      return state;
  }
}

export function getAllOrganisations(currentOrg: Organisation, subOrgs: Array<Organisation>): Array<Organisation> {
  if (currentOrg === undefined && subOrgs === undefined) {
    return undefined;
  }
  if (currentOrg === undefined) {
    return [...subOrgs];
  }
  if (subOrgs === undefined) {
    return [currentOrg];
  }
  return [currentOrg, ...subOrgs];
}
