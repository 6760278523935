import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  InputColumn,
  createChipListColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { Issuer, AutoCreateStatus, Application, Organisation, ApplicationUpstreamIdentityProvider } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { FilterManager } from '../filter/filter-manager';
import { cloneDeep } from 'lodash-es';
import { updateTableElements, getEmptyStringIfUnset } from '../utils';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCurrentOrganisation, selectCurrentOrgIssuer } from '@app/core/organisations/organisations.selectors';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  UpstreamProviderDialogData,
  UpstreamProviderSetupDialogComponent,
} from '../upstream-provider-setup-dialog/upstream-provider-setup-dialog.component';
import { ButtonType } from '../button-type.enum';
import { getDefaultDialogConfig } from '../dialog-utils';
import { getDefaultTableProperties } from '../table-layout-utils';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { isANumber, isValidIconValue } from '../validation-utils';
import {
  getDefaultUniqueUpstreamProviderProperties,
  getDefaultSharedUpstreamProviderProperties,
  getAutoCreateStatusColumn,
  createEditableInputColumn,
  ApplicationUpstreamIdentityProviderElement,
} from '../authentication-utils';
import { ComponentName } from '../component-type.enum';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { selectCanAdminOrReadApps } from '@app/core/user/permissions/app.selectors';
import { initIssuer, savingIssuer } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer, selectIssuerRefreshDataValue, selectSavingIssuer } from '@app/core/issuer-state/issuer.selectors';

@Component({
  selector: 'portal-application-identity',
  templateUrl: './application-identity.component.html',
  styleUrls: ['./application-identity.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationIdentityComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasPermissions: boolean;
  private orgId: string;
  private currentOrgIssuer$: Observable<string>;
  public currentOrgIssuer: string;
  public issuerCopy: Issuer;
  public applicationColumnDefs: Map<string, Column<ApplicationUpstreamIdentityProviderElement>> = new Map();
  public applicationTableData: Array<ApplicationUpstreamIdentityProviderElement> = [];
  public rowObjectName = 'PROVIDER';
  public filterManager: FilterManager = new FilterManager();
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      'ADD PROVIDER',
      ButtonColor.PRIMARY,
      'Add a new upstream provider',
      'Button that adds a new upstream provider',
      () => {
        this.openAddDialog();
      }
    ),
  ];
  private appState$: Observable<ApiApplicationsState>;
  public applications: Array<Application>;
  public currentOrg: Organisation;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/identity-and-authentication/`;
  public pageDescriptiveHelpImageWithTextWrap = 'assets/img/sign-in-screen.png';
  public pageDescriptiveTextWithImageWrap = '';
  private localRefreshDataValue = 0;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef, public dialog: MatDialog) {}

  public ngOnInit(): void {
    this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, false, false));
    this.initializeColumnDefs();
    const currentIssuer$ = this.store.pipe(select(selectCurrentIssuer));
    const savingIssuerState$ = this.store.pipe(select(selectSavingIssuer));
    const refreshDataState$ = this.store.pipe(select(selectIssuerRefreshDataValue));
    this.appState$ = this.store.pipe(select(selectApiApplications));
    this.currentOrgIssuer$ = this.store.pipe(select(selectCurrentOrgIssuer));
    const hasIssuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const hasApplicationPermissions$ = this.store.pipe(select(selectCanAdminOrReadApps));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    combineLatest([
      currentIssuer$,
      savingIssuerState$,
      refreshDataState$,
      this.currentOrgIssuer$,
      this.appState$,
      currentOrg$,
      hasIssuersPermissions$,
      hasApplicationPermissions$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          currentIssuerResp,
          savingIssuerStateResp,
          refreshDataStateResp,
          currentOrgIssuerResp,
          appStateResp,
          currentOrgResp,
          hasIssuersPermissionsResp,
          hasApplicationPermissionsResp,
        ]: [Issuer, boolean, number, string, ApiApplicationsState, Organisation, OrgQualifiedPermission, OrgQualifiedPermission]) => {
          this.hasPermissions = hasIssuersPermissionsResp.hasPermission && hasApplicationPermissionsResp.hasPermission;
          this.orgId = hasIssuersPermissionsResp.orgId;
          if (!this.hasPermissions || savingIssuerStateResp) {
            // Need this in order for the "No Permissions" text to be displayed when the page first loads.
            this.changeDetector.detectChanges();
            return;
          }
          this.applications = appStateResp?.applications;
          this.currentOrg = currentOrgResp;
          this.currentOrgIssuer = currentOrgIssuerResp;
          this.pageDescriptiveTextWithImageWrap = this.getPageDescriptiveTextWithImageWrap();
          if (!currentIssuerResp) {
            this.issuerCopy = undefined;
            this.changeDetector.detectChanges();
            return;
          }
          if (!this.issuerCopy || this.localRefreshDataValue !== refreshDataStateResp) {
            this.localRefreshDataValue = refreshDataStateResp;
            this.issuerCopy = cloneDeep(currentIssuerResp);
            if (this.issuerCopy?.application_upstreams === undefined) {
              this.resetEmptyApplicationUpstreamTable();
            } else {
              this.updateApplicationUpstreamTable();
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPageDescriptiveTextWithImageWrap(): string {
    return `In some cases a preexisting (web) application has local users and you may wish to use it generically as an identity provider.\n\nThis is e.g. your wiki, your ERP, etc, and will allow these local identities to be provided at your URL of ${this.currentOrgIssuer} during the sign-in process.`;
  }

  private getNameColumn(): InputColumn<ApplicationUpstreamIdentityProviderElement> {
    const nameColumn = createEditableInputColumn('name');
    nameColumn.isCaseSensitive = true;
    nameColumn.isUnique = true;
    nameColumn.isValidEntry = (str: string): boolean => {
      return str.length > 0 && str.length < 101;
    };
    return nameColumn;
  }

  private getIssuerColumn(): Column<ApplicationUpstreamIdentityProviderElement> {
    const column = createEditableInputColumn('issuer');
    column.isCaseSensitive = true;
    column.isValidEntry = (str: string): boolean => {
      return str.length < 101;
    };
    return column;
  }

  private initializeColumnDefs(): void {
    this.initializeApplicationColumnDefs();
  }

  private saveIssuer(issuer: Issuer): void {
    this.issuerCopy = issuer;
    this.store.dispatch(savingIssuer({ obj: this.issuerCopy, trigger_update_side_effects: false, notifyUser: true }));
  }

  public openAddDialog(): void {
    const dialogData: UpstreamProviderDialogData = {
      sharedUpstreamProviderData: getDefaultSharedUpstreamProviderProperties(),
      uniqueUpstreamProviderData: getDefaultUniqueUpstreamProviderProperties(),
      issuer: this.issuerCopy,
      store: this.store,
      connectors: [],
      applications: this.applications,
      currentOrg: this.currentOrg,
      componentName: ComponentName.application_identity,
    };
    const dialogRef = this.dialog.open(
      UpstreamProviderSetupDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
  }

  private getIconColumn(): InputColumn<ApplicationUpstreamIdentityProviderElement> {
    const iconColumn = createInputColumn('icon');
    iconColumn.isEditable = true;
    iconColumn.isCaseSensitive = true;
    iconColumn.getHeaderTooltip = (): string => {
      return 'File to use for the icon';
    };
    iconColumn.isValidEntry = (str: string): boolean => {
      if (str === '') {
        return true;
      }
      return isValidIconValue(str);
    };
    return iconColumn;
  }

  private getUsernameFieldColumn(): Column<ApplicationUpstreamIdentityProviderElement> {
    const usernameFieldColumn = createEditableInputColumn('username_field');
    return usernameFieldColumn;
  }

  private getPasswordFieldColumn(): Column<ApplicationUpstreamIdentityProviderElement> {
    const passwordFieldColumn = createEditableInputColumn('password_field');
    return passwordFieldColumn;
  }

  private getSuccessfulResponseCodeColumn(): Column<ApplicationUpstreamIdentityProviderElement> {
    const successfulResponseCodeColumn = createEditableInputColumn('successful_response_code');
    successfulResponseCodeColumn.isValidEntry = (val: string | number): boolean => {
      const valueAsString = val.toString();
      return isANumber(valueAsString) && parseInt(valueAsString, 10) >= 100 && parseInt(valueAsString, 10) <= 600;
    };
    return successfulResponseCodeColumn;
  }

  private getExpectedCookiesColumn(): Column<ApplicationUpstreamIdentityProviderElement> {
    const expectedCookiesColumn = createChipListColumn('expected_cookies');
    expectedCookiesColumn.hasAutocomplete = false;
    expectedCookiesColumn.isUnique = true;
    expectedCookiesColumn.isFreeform = true;
    return expectedCookiesColumn;
  }

  private initializeApplicationColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getNameColumn(),
        this.getIssuerColumn(),
        this.getIconColumn(),
        getAutoCreateStatusColumn(),
        this.getUsernameFieldColumn(),
        this.getPasswordFieldColumn(),
        this.getSuccessfulResponseCodeColumn(),
        this.getExpectedCookiesColumn(),
      ],
      this.applicationColumnDefs
    );
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyApplicationUpstreamTable(): void {
    this.applicationTableData.length = 0;
  }

  private updateApplicationUpstreamTable(): void {
    this.buildApplicationUpstreamTableData();
    this.replaceApplicationUpstreamTableWithCopy();
  }

  private buildApplicationUpstreamTableData(): void {
    const data: Array<ApplicationUpstreamIdentityProviderElement> = [];
    for (let i = 0; i < this.issuerCopy?.application_upstreams.length; i++) {
      data.push(this.createApplictionUpstreamElement(this.issuerCopy?.application_upstreams[i], i));
    }
    updateTableElements(this.applicationTableData, data);
  }

  private createApplictionUpstreamElement(
    applicationUpstream: ApplicationUpstreamIdentityProvider,
    index: number
  ): ApplicationUpstreamIdentityProviderElement {
    const data: ApplicationUpstreamIdentityProviderElement = {
      name: '',
      issuer: '',
      icon: '',
      auto_create_status: AutoCreateStatus.default,
      username_field: '',
      password_field: '',
      validation: undefined,
      backingObject: applicationUpstream,
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(applicationUpstream)) {
      data[key] = getEmptyStringIfUnset(applicationUpstream[key]);
    }
    data.successful_response_code = applicationUpstream.validation.successful_response_code;
    data.expected_cookies = !!applicationUpstream.validation.expected_cookies ? applicationUpstream.validation.expected_cookies : [];
    data.username_field = applicationUpstream.form_info.username_field;
    data.password_field = applicationUpstream.form_info.password_field;
    return data;
  }

  private replaceApplicationUpstreamTableWithCopy(): void {
    const tableDataCopy = [...this.applicationTableData];
    this.applicationTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private getApplicationUpstreamsFromTable(): Array<ApplicationUpstreamIdentityProvider> {
    return this.applicationTableData.map((entry: ApplicationUpstreamIdentityProviderElement) => {
      const applicationUpstream: ApplicationUpstreamIdentityProvider = {
        ...entry.backingObject,
        name: entry.name,
        issuer: entry.issuer,
        upstream_type: entry.upstream_type,
        icon: entry.icon,
        auto_create_status: entry.auto_create_status,
        validation: {
          // Need to first convert to string to avoid type safety errors.
          successful_response_code: parseInt(entry.successful_response_code.toString(), 10),
          expected_cookies: entry.expected_cookies,
        },
        form_info: {
          username_field: entry.username_field,
          password_field: entry.password_field,
        },
      };
      return applicationUpstream;
    });
  }

  public updateApplicationUpstreamEvent(): void {
    const updatedApplicationUpstreamList = this.getApplicationUpstreamsFromTable();
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    copyOfIssuerCopy.application_upstreams = updatedApplicationUpstreamList;
    this.saveIssuer(copyOfIssuerCopy);
  }

  private removeApplicationUpstreams(): void {
    this.applicationTableData = this.applicationTableData.filter((applicationUpstream) => !applicationUpstream.isChecked);
  }

  public deleteSelectedApplicationUpstreams(): void {
    this.removeApplicationUpstreams();
    const updatedApplicationUpstreamList = this.getApplicationUpstreamsFromTable();
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    copyOfIssuerCopy.application_upstreams = updatedApplicationUpstreamList;
    this.saveIssuer(copyOfIssuerCopy);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.applicationTableData, this.applicationColumnDefs, this.updateApplicationUpstreamEvent.bind(this));
  }
}
