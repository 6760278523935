import { createSelector, MemoizedSelector } from '@ngrx/store';
import { selectUserSelector } from '../user.selectors';
import { UserState, UserPermissions } from '../user.models';
import { AppState } from '@app/core';

export interface OrgQualifiedPermissions extends UserPermissions {
  orgId: string;
}

export interface OrgQualifiedPermission {
  hasPermission: boolean;
  orgId: string;
}

export const selectRawPermissionsSelector = createSelector(selectUserSelector, (state: UserState) => {
  const result: OrgQualifiedPermissions = {
    ...state.permissions,
    orgId: state.current_org_id,
  };
  return result;
});

export const selectPermissionsSelector = createSelector(selectUserSelector, (state: UserState) => {
  return state.permissions;
});

export function createCombinedPermissionsSelector(
  lhs: MemoizedSelector<AppState, OrgQualifiedPermission>,
  rhs: MemoizedSelector<AppState, OrgQualifiedPermission>
): MemoizedSelector<AppState, OrgQualifiedPermission> {
  return createSelector(lhs, rhs, (lhsState: OrgQualifiedPermission, rhsState: OrgQualifiedPermission) => {
    const retVal: OrgQualifiedPermission = {
      hasPermission: lhsState.hasPermission && rhsState.hasPermission,
      // The org id will always be the same, because these selectors will be getting it from the same
      // source
      orgId: lhsState.orgId,
    };
    return retVal;
  });
}
