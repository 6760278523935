import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, from } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// import { selectUserState } from './auth.selectors';
import { AppState } from '../core.state';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class AuthGuardService implements CanActivate, CanDeactivate<CanComponentDeactivate> {
  constructor(private store: Store<AppState>) {}

  public canActivate(): Observable<boolean> {
    return from([true]);
  }

  public canDeactivate(
    component: CanComponentDeactivate | undefined,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component?.canDeactivate ? component.canDeactivate() : true;
  }
}
