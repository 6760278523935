import { createSelector } from '@ngrx/store';
import { selectRawPermissionsSelector } from './permissions.selectors';
import { OrgQualifiedPermissions } from './permissions.selectors';
import { makePermission } from './utils';

export const selectCanReadFiles = createSelector(selectRawPermissionsSelector, (state: OrgQualifiedPermissions) =>
  makePermission(state.orgId, state.filesRoles, ['owner', 'reader', 'viewer'])
);

export const selectCanAdminFiles = createSelector(selectRawPermissionsSelector, (state: OrgQualifiedPermissions) =>
  makePermission(state.orgId, state.filesRoles, ['owner'])
);

export const selectCanAdminOrReadFiles = selectCanReadFiles;
