import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import * as BillingAccountFullActions from '../billing-state/billing-account-full.actions';
import { BillingAccountFull } from '../models/billing/billing-account-full';
import { BillingService } from '@agilicus/angular';
import { getBillingAccountFull, getListOfBillingAccountFullAsList } from '../billing-state/billing-api-utils';

@Injectable({
  providedIn: 'root',
})
export class BillingAccountFullStateService extends SimpleCRUDInterface<BillingAccountFull, string> {
  public crudRegistryName = CrudStateCollection.billing;

  constructor(
    public store: Store<AppState>,
    private billingService: BillingService,
    private crudRegistryService: CrudRegistryService<BillingAccountFull, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<BillingAccountFull, string> = {
        create: null,
        update: null,
        get: (id: string, orgId: string) => getBillingAccountFull(this.billingService, orgId),
        list: (orgId: string) => getListOfBillingAccountFullAsList(this.billingService, orgId),
        delete: null,
      };
      const crudRegistry: CrudRegistry<BillingAccountFull, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: BillingAccountFull) => obj.status.product.spec.name,
        doneSaveAction: BillingAccountFullActions.upsertBillingAccountFull,
        setListAction: BillingAccountFullActions.loadBillingAccountFulls,
        refreshStateAction: BillingAccountFullActions.refreshBillingAccountFull,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
