import { createSelector } from '@ngrx/store';
import { selectUIState } from '..';
import { NavMenuState } from '../models/ui/ui-model';
import { UIState } from './ui.models';

export const selectUI = createSelector(selectUIState, (state: UIState) => state);

export const selectExpansionPanelsState = createSelector(selectUIState, (state: UIState) => state.expansionPanelsState);

export const selectTabsState = createSelector(selectUIState, (state: UIState) => state.tabsState);

export const selectNavMenuState = createSelector(selectUIState, (state: UIState) => state.navMenuState);

export const selectNavMenuItemsListState = createSelector(selectNavMenuState, (state: NavMenuState) => {
  return state.navMenuItemsList;
});

export const selectUIStateShouldPopulateValue = createSelector(selectNavMenuState, (state: NavMenuState) => state.should_populate);

export const selectUIStateIsSideNavLoaded = createSelector(selectNavMenuState, (state: NavMenuState) => state.isSideNavLoaded);

export const selectUIStateRefreshDataValue = createSelector(selectUIState, (state: UIState) => state.refresh_data);

export const selectUIStateVersion = createSelector(selectUIState, (state: UIState) => state.version);

export const selectAlertsState = createSelector(selectUIState, (state: UIState) => state.alertsState);
