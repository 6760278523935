import { CredentialsService, ObjectCredential, ObjectCredentialSecrets, SSHResource } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { ResourceType } from '../resource-type.enum';
import { catchError, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { getEmptyStringIfUnset } from '../utils';
import {
  getSshCredentialPasswordMaxLength,
  getSshCredentialPrivateKeyMaxLength,
  getSshCredentialPrivateKeyPassphraseMaxLength,
} from '../validation-utils';
import { AppState, NotificationService } from '@app/core';
import {
  createNewSshCredential$,
  deleteExistingSshCredential$,
  getSshCredentialsList$,
  updateExistingSshCredential$,
} from '@app/core/api/credentials-api-utils';
import {
  getCredentialPasswordTooltipText,
  getCredentialPrivateKeyPassphraseTooltipText,
  getCredentialPrivateKeyTooltipText,
  getCredentialUsernameTooltipText,
  getDefaultNewCredential,
} from '@app/core/credentials-utils';
import { downloadPemFileData, getFile, getPemFileErrorMessage, isValidPemFile, uploadDataFromFile } from '../file-utils';
import { Store } from '@ngrx/store';
import { savingSSHResource } from '@app/core/ssh-state/ssh.actions';
import { cloneDeep } from 'lodash-es';

export interface SshCredentialsDialogData {
  ssh: SSHResource;
}

@Component({
  selector: 'portal-ssh-credentials-dialog',
  templateUrl: './ssh-credentials-dialog.component.html',
  styleUrls: ['./ssh-credentials-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SshCredentialsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public credentialsFormGroup: FormGroup;
  public keyTabManager: KeyTabManager = new KeyTabManager();
  private credential: ObjectCredential;

  public getSshCredentialPrivateKeyMaxLength = getSshCredentialPrivateKeyMaxLength;
  public getSshCredentialPrivateKeyPassphraseMaxLength = getSshCredentialPrivateKeyPassphraseMaxLength;
  public getSshCredentialPasswordMaxLength = getSshCredentialPasswordMaxLength;
  public getCredentialPrivateKeyTooltipText = getCredentialPrivateKeyTooltipText;
  public getCredentialPrivateKeyPassphraseTooltipText = getCredentialPrivateKeyPassphraseTooltipText;
  public getCredentialPasswordTooltipText = getCredentialPasswordTooltipText;
  public getCredentialUsernameTooltipText = getCredentialUsernameTooltipText;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SshCredentialsDialogData,
    private dialogRef: MatDialogRef<SshCredentialsDialogComponent>,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private credentialsService: CredentialsService,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    public renderer: Renderer2,
    private store: Store<AppState>
  ) {}

  public ngOnInit(): void {
    this.initializeCredentialsFormGroup();
    this.getSshCredentialWithPriorityZero$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        this.credential = resp;
        this.setFormValuesFromApiData();
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getSshCredentialWithPriorityZero$(): Observable<ObjectCredential | undefined> {
    return getSshCredentialsList$(this.credentialsService, this.data.ssh.metadata.id, ResourceType.ssh, this.data.ssh.spec.org_id).pipe(
      map((resp) => {
        for (const credential of resp) {
          if (credential.spec.priority === 0) {
            return credential;
          }
        }
        return undefined;
      }),
      catchError((_) => {
        return of(undefined);
      })
    );
  }

  private updateExistingSshCredentialFromForm(credentialToUpdate: ObjectCredential): void {
    const formPrivateKeyValue = this.credentialsFormGroup.get('private_key').value;
    const formPrivateKeyPassphraseValue = this.credentialsFormGroup.get('private_key_passphrase').value;
    const formPasswordValue = this.credentialsFormGroup.get('password').value;
    const usernameValue = this.credentialsFormGroup.get('username').value;
    if (!credentialToUpdate.spec.secrets) {
      credentialToUpdate.spec.secrets = {};
    }
    if (!!formPrivateKeyValue) {
      credentialToUpdate.spec.secrets.private_key = formPrivateKeyValue;
    }
    if (!!formPrivateKeyPassphraseValue) {
      credentialToUpdate.spec.secrets.private_key_passphrase = formPrivateKeyPassphraseValue;
    }
    if (!!formPasswordValue) {
      credentialToUpdate.spec.secrets.password = formPasswordValue;
    }
    credentialToUpdate.spec.secrets.username = usernameValue;
  }

  private updateAndReplaceExistingSshCredential$(credentialToUpdate: ObjectCredential): Observable<ObjectCredential> {
    this.updateExistingSshCredentialFromForm(credentialToUpdate);
    return updateExistingSshCredential$(this.credentialsService, credentialToUpdate);
  }

  private initializeCredentialsFormGroup(): void {
    this.credentialsFormGroup = this.formBuilder.group({
      private_key: ['', [this.customValidatorsService.sshCredentialPrivateKeyValidator()]],
      private_key_passphrase: ['', [this.customValidatorsService.sshCredentialPrivateKeyPassphraseValidator()]],
      password: ['', [this.customValidatorsService.sshCredentialPasswordValidator()]],
      username: '',
    });
  }

  public getPrivateKeyValueFromCredential(): string {
    return getEmptyStringIfUnset(this.credential?.spec?.secrets?.private_key);
  }

  public getPrivateKeyValueFromForm(): string {
    return this.credentialsFormGroup.value.private_key;
  }

  private setFormValuesFromApiData(): void {
    this.credentialsFormGroup.get('private_key').setValue(getEmptyStringIfUnset(this.credential?.spec?.secrets?.private_key));
    this.credentialsFormGroup
      .get('private_key_passphrase')
      .setValue(getEmptyStringIfUnset(this.credential?.spec?.secrets?.private_key_passphrase));
    this.credentialsFormGroup.get('password').setValue(getEmptyStringIfUnset(this.credential?.spec?.secrets?.password));
    this.credentialsFormGroup.get('username').setValue(getEmptyStringIfUnset(this.getUsernameValue()));
    this.changeDetector.detectChanges();
  }

  private getUsernameValue(): string | undefined {
    if (!!this.credential?.status?.username) {
      return this.credential.status.username;
    }
    if (this.data?.ssh?.spec?.username) {
      return this.data.ssh.spec.username;
    }
    return undefined;
  }

  private getNewCredentialFromForm(): ObjectCredential {
    const formPrivateKeyValue = this.credentialsFormGroup.get('private_key').value;
    const formPrivateKeyPassphraseValue = this.credentialsFormGroup.get('private_key_passphrase').value;
    const formPasswordValue = this.credentialsFormGroup.get('password').value;
    const usernameValue = this.credentialsFormGroup.get('username').value;
    const credentialSecrets: ObjectCredentialSecrets = {};
    if (!!formPrivateKeyValue) {
      credentialSecrets.private_key = formPrivateKeyValue;
    }
    if (!!formPrivateKeyPassphraseValue) {
      credentialSecrets.private_key_passphrase = formPrivateKeyPassphraseValue;
    }
    if (!!formPasswordValue) {
      credentialSecrets.password = formPasswordValue;
    }
    if (!!usernameValue) {
      credentialSecrets.username = usernameValue;
    }
    const newCredential = getDefaultNewCredential(this.data.ssh, credentialSecrets);
    return newCredential;
  }

  private updateSshUsernameOnCredentialSave(updatedUsername: string): void {
    if (this.data?.ssh?.spec?.username !== updatedUsername) {
      // If credential username has been changed then update the ssh username:
      const sshResourceCopy = cloneDeep(this.data.ssh);
      sshResourceCopy.spec.username = updatedUsername;
      this.store.dispatch(
        savingSSHResource({ obj: sshResourceCopy, trigger_update_side_effects: false, notifyUser: true, refreshData: true })
      );
    }
    this.dialogRef.close();
  }

  private handleCreateNewCredential(): void {
    const newCredential = this.getNewCredentialFromForm();
    createNewSshCredential$(this.credentialsService, newCredential)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success(`Credentials were successfully created for SSH "${this.data.ssh.spec.name}"`);
          this.updateSshUsernameOnCredentialSave(resp.status.username);
        },
        (error) => {
          this.notificationService.error(`Failed to create new credentials for SSH "${this.data.ssh.spec.name}"`);
        }
      );
  }

  private handleDeleteExistingCredential(): void {
    deleteExistingSshCredential$(this.credentialsService, this.credential)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (_) => {
          this.notificationService.success(`Credentials were successfully deleted for SSH "${this.data.ssh.spec.name}"`);
          this.dialogRef.close();
        },
        (error) => {
          this.notificationService.error(`Failed to delete credentials for SSH "${this.data.ssh.spec.name}"`);
        }
      );
  }

  private handleReplaceExistingCredential(): void {
    this.updateAndReplaceExistingSshCredential$(this.credential)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success(`Credentials were successfully updated for SSH "${this.data.ssh.spec.name}"`);
          this.updateSshUsernameOnCredentialSave(resp.status.username);
        },
        (error) => {
          this.notificationService.error(`Failed to update credentials for SSH "${this.data.ssh.spec.name}"`);
        }
      );
  }

  private handleSaveClick(): void {
    const formPrivateKeyValue = this.credentialsFormGroup.get('private_key').value;
    const formPrivateKeyPassphraseValue = this.credentialsFormGroup.get('private_key_passphrase').value;
    const formPasswordValue = this.credentialsFormGroup.get('password').value;
    const usernameValue = this.credentialsFormGroup.get('username').value;
    if (!formPrivateKeyValue && !formPrivateKeyPassphraseValue && !formPasswordValue) {
      // No new values provided for "private_key", "private_key_passphrase" or "password":
      if (!usernameValue) {
        // Username is blank:
        if (!this.credential?.status?.username && !this.data?.ssh?.spec?.username) {
          // No new data provided by user and username not previously set, so close dialog and do nothing
          this.dialogRef.close();
          return;
        }
        if (!!this.credential?.status?.username) {
          // Need to clear the username in the credential and SSH resource:
          this.handleReplaceExistingCredential();
          return;
        }
        if (!!this.data?.ssh?.spec?.username) {
          // Need to clear the username in the SSH resource only:
          this.updateSshUsernameOnCredentialSave(usernameValue);
          return;
        }
      }
      if (!!usernameValue && !this.credential) {
        // If only the username is set and no credential has been created, only update the SSH resource username:
        this.updateSshUsernameOnCredentialSave(usernameValue);
        return;
      }
    }
    if (!this.credential) {
      this.handleCreateNewCredential();
      return;
    }
    this.handleReplaceExistingCredential();
    return;
  }

  public onConfirmClick(): void {
    this.handleSaveClick();
  }

  public uploadPrivateKeyData(event: any): void {
    const file = getFile(event);
    if (!isValidPemFile(file)) {
      this.notificationService.error(getPemFileErrorMessage(file));
      return;
    }
    uploadDataFromFile(file, this.onReadPrivateKeyFile.bind(this));
  }

  private onReadPrivateKeyFile(reader: FileReader): void {
    this.credentialsFormGroup.get('private_key').setValue(reader.result.toString());
  }

  public clearPrivateKey(): void {
    this.credentialsFormGroup.value.private_key = '';
  }

  public downloadPrivateKey(): void {
    downloadPemFileData(this.getPrivateKeyValueFromForm(), this.renderer, `${this.data.ssh.spec.name}-ssh-credential-private-key`);
  }

  public disableDownload(): boolean {
    const privateKey = this.getPrivateKeyValueFromCredential();
    if (!privateKey) {
      return true;
    }
    return privateKey.length === 0;
  }

  public onDeleteClick(): void {
    this.handleDeleteExistingCredential();
  }

  public disableSaveButton(): boolean {
    if (this.credentialsFormGroup.invalid) {
      return true;
    }
    const formPrivateKeyValue = this.credentialsFormGroup.get('private_key').value;
    const formPrivateKeyPassphraseValue = this.credentialsFormGroup.get('private_key_passphrase').value;
    const formPasswordValue = this.credentialsFormGroup.get('password').value;
    const usernameValue = this.credentialsFormGroup.get('username').value;
    if (
      !formPrivateKeyValue &&
      !formPrivateKeyPassphraseValue &&
      !formPasswordValue &&
      (usernameValue === this.credential?.status?.username || (!usernameValue && !this.credential?.status?.username))
    ) {
      return true;
    }
    return false;
  }
}
