import { ExpansionPanelType, UIState } from '@app/core/ui/ui.models';

export function getDefaultUIModelState(): UIState {
  return {
    expansionPanelsState: { panels: {} },
    tabsState: { tabs: {} },
    navMenuState: {
      navMenuItemsList: [],
      isSideNavLoaded: false,
      should_populate: false,
    },
    refresh_data: 0,
    version: undefined,
    alertsState: {
      balanceEndDate: false,
      currentUsage: false,
    },
  };
}

export function isExpansionPanelOpen(uiState: UIState, panelId: ExpansionPanelType): boolean {
  return !!uiState?.expansionPanelsState?.panels[panelId];
}
