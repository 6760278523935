<div class="dialog-container">
  <mat-dialog-content>
    <h2 class="message-prefix">{{ messagePrefix }}</h2>
    <span *ngIf="!informationDialog">
      <div class="icon-container">
        <mat-icon class="warning-icon" [ngClass]="{ 'notify': icon !== 'warning' }">{{ icon }}</mat-icon>
      </div>
      <div class="message-container">
        <p [innerHTML]="message"></p>
      </div>
    </span>
    <div *ngIf="informationDialog" style="width: 100%;" class="message-container" [innerHTML]="message"></div>
    
    <span *ngIf="!!productGuideData">
      <portal-page-info
        [pageTitle]="productGuideData.pageTitle"
        [pageDescriptiveText]="productGuideData.pageDescriptiveText"
        [pageSubDescriptiveList]="productGuideData.pageSubDescriptiveList"
        [productGuidePreText]="productGuideData.productGuidePreText"
        [productGuideLink]="productGuideData.productGuideLink"
        [productGuideLinkText]="productGuideData.productGuideLinkText"
        [productGuidePostText]="productGuideData.productGuidePostText"
        [pageInfoWidth]="productGuideData.pageInfoWidth"
        [helpImageFilePath]="productGuideData.helpImageFilePath"
        [pageDescriptiveTextWithImageWrap]="productGuideData.pageDescriptiveTextWithImageWrap"
        [pageDescriptiveHelpImageWithTextWrap]="productGuideData.pageDescriptiveHelpImageWithTextWrap"
        [titleSize]="productGuideData.titleSize"
      >
      </portal-page-info>
    </span>
    
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      *ngIf="showDemoDeleteButton"
      mat-raised-button
      color="warn"
      matTooltip="Destroy the demo environment"
      aria-label="A button to destroy the demo environment"
      class="action-button delete-demo-button"
      (click)="deleteDemo()"
      [disabled]="!enableDemoDeleteButton()"
    >
      {{ getDeleteDemoButtonName() }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="action-button"
      (click)="onSetupPaymentClick()"
      matTooltip="Click to setup/modify your payment subscription"
    >
      {{ subscriptionButtonText }}
    </button>
    <button
      *ngIf="showDoNotShowButton"
      mat-raised-button
      color="primary"
      class="action-button"
      (click)="onDoNotShowClick()"
    >
      DO NOT SHOW AGAIN
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
      class="action-button"
    >
      CLOSE
    </button>
  </mat-dialog-actions>

  <div class="progress-bar-container">
    <portal-apply-app-model
      *ngIf="showDemoDeleteButton"
      [appModelSubmissionProgressBarController]="createDemoProgressBarController"
      [appModelStatus]="demoStatus"
      [hideButton]="hideApplyButton"
      (resetModelStatus)="resetModelStatus()"
    >
    </portal-apply-app-model>
  </div>
</div>