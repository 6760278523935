import {
  UsersService,
  ServiceAccount,
  CreateServiceAccountRequestParams,
  ListServiceAccountsRequestParams,
  UserMetadata,
  ReplaceUserMetadataRequestParams,
  ListUserMetadataRequestParams,
  CreateUserMetadataRequestParams,
  User,
  patch_via_put,
} from '@agilicus/angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function updateExistingUser$(usersService: UsersService, userToUpdate: User): Observable<User> {
  const putter = (user: User) => {
    return usersService.replaceUser({ user_id: user.id, User: user });
  };
  const getter = (user: User) => {
    return usersService.getUser({
      user_id: user.id,
      org_id: user.org_id,
    });
  };
  return patch_via_put(userToUpdate, getter, putter);
}

export function createNewServiceAccount(
  usersService: UsersService,
  newServiceAccount: ServiceAccount
): Observable<ServiceAccount | undefined> {
  const args: CreateServiceAccountRequestParams = {
    ServiceAccount: newServiceAccount,
  };
  return usersService.createServiceAccount(args);
}

export function getServiceAccounts(usersService: UsersService, orgId: string | undefined): Observable<Array<ServiceAccount> | undefined> {
  let serviceAccounts$: Observable<Array<ServiceAccount>> = of([]);
  if (!!orgId) {
    const listServiceAccountsRequestParams: ListServiceAccountsRequestParams = {
      org_id: orgId,
    };
    serviceAccounts$ = usersService.listServiceAccounts(listServiceAccountsRequestParams).pipe(
      map((resp) => {
        return resp.service_accounts;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return serviceAccounts$;
}

export function createUserMetadata(usersService: UsersService, userMetadata: UserMetadata): Observable<UserMetadata> {
  const createUserMetadataRequestParams: CreateUserMetadataRequestParams = {
    UserMetadata: userMetadata,
  };
  return usersService.createUserMetadata(createUserMetadataRequestParams);
}

export function getUserMetadata(
  usersService: UsersService,
  userId: string | undefined,
  orgId: string | undefined,
  appId: string | undefined
): Observable<Array<UserMetadata> | undefined> {
  let userMetadata$: Observable<Array<UserMetadata>> = of(undefined);
  if (!!userId && !!orgId && !!appId) {
    const listUserMetadataRequestParams: ListUserMetadataRequestParams = {
      user_id: userId,
      org_id: orgId,
      app_id: appId,
    };
    userMetadata$ = usersService.listUserMetadata(listUserMetadataRequestParams).pipe(
      map((resp) => {
        return resp.user_metadata;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return userMetadata$;
}

export function updateUserMetadata(usersService: UsersService, updatedUserMetadata: UserMetadata): Observable<UserMetadata> {
  const replaceUserMetadataRequestParams: ReplaceUserMetadataRequestParams = {
    metadata_id: updatedUserMetadata.metadata.id,
    UserMetadata: updatedUserMetadata,
  };
  return usersService.replaceUserMetadata(replaceUserMetadataRequestParams);
}
