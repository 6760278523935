import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject, combineLatest } from 'rxjs';

import { Issuer, ManagedUpstreamIdentityProvider } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { removeFromBeginningOfString, capitalizeFirstLetter } from '../utils';
import { takeUntil } from 'rxjs/operators';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { cloneDeep } from 'lodash-es';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCurrentOrgIssuer } from '@app/core/organisations/organisations.selectors';
import { initIssuer, savingIssuer } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer, selectIssuerRefreshDataValue, selectSavingIssuer } from '@app/core/issuer-state/issuer.selectors';

@Component({
  selector: 'portal-shared-identity',
  templateUrl: './shared-identity.component.html',
  styleUrls: ['./shared-identity.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedIdentityComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public currentOrgIssuer: string;
  public issuerCopy: Issuer;
  public hasIssuersPermissions: boolean;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/identity-and-authentication/`;
  public pageDescriptiveHelpImageWithTextWrap = 'assets/img/sign-in-screen.png';
  public pageDescriptiveTextWithImageWrap = '';
  private localRefreshDataValue = 0;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    const currentIssuer$ = this.store.pipe(select(selectCurrentIssuer));
    const savingIssuerState$ = this.store.pipe(select(selectSavingIssuer));
    const refreshDataState$ = this.store.pipe(select(selectIssuerRefreshDataValue));
    const currentOrgIssuer$ = this.store.pipe(select(selectCurrentOrgIssuer));
    const issuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    combineLatest([currentIssuer$, savingIssuerState$, refreshDataState$, currentOrgIssuer$, issuersPermissions$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([currentIssuerResp, savingIssuerStateResp, refreshDataStateResp, currentOrgIssuerResp, issuerPermissions]: [
          Issuer,
          boolean,
          number,
          string,
          OrgQualifiedPermission
        ]) => {
          this.hasIssuersPermissions = issuerPermissions.hasPermission;
          if (!this.hasIssuersPermissions || savingIssuerStateResp) {
            // Need this in order for the "No Permissions" text to be displayed when the page first loads.
            this.changeDetector.detectChanges();
            return;
          }
          this.currentOrgIssuer = currentOrgIssuerResp;
          this.pageDescriptiveTextWithImageWrap = this.getPageDescriptiveTextWithImageWrap();
          if (!currentIssuerResp) {
            this.issuerCopy = undefined;
            this.changeDetector.detectChanges();
            return;
          }
          if (!this.issuerCopy || this.localRefreshDataValue !== refreshDataStateResp) {
            this.localRefreshDataValue = refreshDataStateResp;
            this.issuerCopy = cloneDeep(currentIssuerResp);
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public managedUpstreamProviderChanged(upstreamProvider: ManagedUpstreamIdentityProvider): void {
    // We need to make a copy here in order to prevent errors as a result of the component freezing the object
    const copyOfUpstreamProvider = cloneDeep(upstreamProvider);
    copyOfUpstreamProvider.enabled = !upstreamProvider.enabled;
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    for (let i = 0; i < copyOfIssuerCopy.managed_upstreams.length; i++) {
      if (copyOfIssuerCopy.managed_upstreams[i].name === copyOfUpstreamProvider.name) {
        copyOfIssuerCopy.managed_upstreams[i] = copyOfUpstreamProvider;
      }
    }
    this.saveIssuer(copyOfIssuerCopy);
  }

  public formatManagedUpstreamDisplayValue(value: string): string {
    const editedValue = capitalizeFirstLetter(removeFromBeginningOfString(value, 'agilicus-'));
    if (editedValue === 'Apple') {
      return 'Sign in with Apple - Enable your users to trust AppleID as an Identity Provider';
    }
    if (editedValue === 'Google') {
      return 'Sign in with Google - Enable your users to sign in with Corporate (Google Workplace) or Personal (Gmail)';
    }
    if (editedValue === 'Linkedin') {
      return 'Sign in with Linkedin - Enable your users to sign in with their Linkedin Account';
    }
    if (editedValue === 'Microsoft') {
      return 'Sign in with Microsoft - Enable your users to sign in with Corporate (Office 365, Azure) or Personal (e.g. Hotmail) Microsoft accounts';
    }
    if (editedValue === 'Yahoo') {
      return 'Sign in with Yahoo - Enable your users to sign in with their Yahoo Account';
    }
    return editedValue;
  }

  private getPageDescriptiveTextWithImageWrap(): string {
    return `Allow your users to sign in with various public identity providers with no additional configuration. You will enable their Authorization under the Access section, this setup merely makes it possible to provide these identifiers.\n\nYour users will see ${this.currentOrgIssuer} as the identity provider, federating these managed upstream providers.`;
  }

  private saveIssuer(issuer: Issuer): void {
    this.issuerCopy = issuer;
    this.store.dispatch(savingIssuer({ obj: this.issuerCopy, trigger_update_side_effects: false, notifyUser: true }));
  }

  public getManagedUpstreamsListWithoutMicrosoft(issuer: Issuer): Array<ManagedUpstreamIdentityProvider> {
    const fullList = this.issuerCopy.managed_upstreams;
    const microsoftUpstreamIndex = this.getMicrosoftManagedUpstreamIndex(issuer);
    if (microsoftUpstreamIndex === -1) {
      return fullList;
    }
    return [...fullList.slice(0, microsoftUpstreamIndex), ...fullList.slice(microsoftUpstreamIndex + 1)];
  }

  public getMicrosoftManagedUpstream(issuer: Issuer): ManagedUpstreamIdentityProvider | undefined {
    return issuer.managed_upstreams.find((upstream) => upstream.name === 'agilicus-microsoft');
  }

  private getMicrosoftManagedUpstreamIndex(issuer: Issuer): number {
    for (let i = 0; i < issuer.managed_upstreams.length; i++) {
      const upstream = issuer.managed_upstreams[i];
      if (upstream.name === 'agilicus-microsoft') {
        return i;
      }
    }
    return -1;
  }

  public getMicrosoftPromptSelectAccountValue(issuer: Issuer): boolean {
    const microsoftManagedUpstream = this.getMicrosoftManagedUpstream(issuer);
    return !!microsoftManagedUpstream ? microsoftManagedUpstream.prompt_select_account : false;
  }

  public onMicrosoftPromptSelectAccountValueChange(): void {
    const microsoftManagedUpstream = this.getMicrosoftManagedUpstream(this.issuerCopy);
    // We need to make copies here in order to prevent errors as a result of the component freezing the object
    const copyOfMicrosoftUpstreamProvider = cloneDeep(microsoftManagedUpstream);
    copyOfMicrosoftUpstreamProvider.prompt_select_account = !microsoftManagedUpstream.prompt_select_account;
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    this.getMicrosoftManagedUpstream(copyOfIssuerCopy).prompt_select_account = copyOfMicrosoftUpstreamProvider.prompt_select_account;
    this.saveIssuer(copyOfIssuerCopy);
  }
}
