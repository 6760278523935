<portal-page-info
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
  pageInfoWidth="full"
>
</portal-page-info>

<div class="header-override-table-container bottom-spacing">
  <portal-table-layout
    [tableData]="setHeaderTableData"
    [columnDefs]="setHeaderColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    rowObjectName="SET HEADER"
    pageTitle="Set Header"
    pageDescriptiveText="This allows setting an arbitrary header to an arbitrary value."
    productGuideLink="https://www.agilicus.com/anyx-guide/proxy/#h-set-header"
    pageInfoWidth="full"
    titleSize="small"
    (updateEvent)="updateEvent()"
    (removeSelected)="deleteSelected()"
  >
  </portal-table-layout>
</div>

<div class="header-override-table-container bottom-spacing">
  <portal-table-layout
    [tableData]="addHeaderTableData"
    [columnDefs]="addHeaderColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    rowObjectName="APPEND HEADER"
    pageTitle="Append Header"
    pageDescriptiveText="This allows appending a value to an existing response header."
    productGuideLink="https://www.agilicus.com/anyx-guide/proxy/#append-header"
    pageInfoWidth="full"
    titleSize="small"
    (updateEvent)="updateEvent()"
    (removeSelected)="deleteSelected()"
  >
  </portal-table-layout>
</div>

<div class="header-override-table-container bottom-spacing">
  <portal-table-layout
    [tableData]="removeHeaderTableData"
    [columnDefs]="removeHeaderColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    rowObjectName="REMOVE HEADER"
    pageTitle="Remove Header"
    pageDescriptiveText="This will remove a header from the response. It may be used to e.g. remove private internal information or version leakage."
    productGuideLink="https://www.agilicus.com/anyx-guide/proxy/#remove-header"
    pageInfoWidth="full"
    titleSize="small"
    (updateEvent)="updateEvent()"
    (removeSelected)="deleteSelected()"
  >
  </portal-table-layout>
</div>

<div class="header-override-table-container bottom-spacing">
  <portal-table-layout
    [tableData]="removeMatchTableData"
    [columnDefs]="removeMatchColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyRemoveMatchTableElementFunc"
    rowObjectName="REMOVE MATCH"
    pageTitle="Remove Match"
    pageDescriptiveText="This feature allows removing entire header lines matching some criteria."
    productGuideLink="https://www.agilicus.com/anyx-guide/proxy/#remove-match"
    pageInfoWidth="full"
    titleSize="small"
    (updateEvent)="updateEvent()"
    (removeSelected)="deleteSelected()"
  >
  </portal-table-layout>
</div>

<div class="header-override-table-container">
  <portal-table-layout
    [tableData]="rewriteFilterTableData"
    [columnDefs]="rewriteFilterColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyRewriteFilterTableElementFunc"
    rowObjectName="REWRITE FILTER"
    pageTitle="Rewrite Filter"
    [pageDescriptiveText]="rewriteFilterTableDescriptiveText"
    productGuideLink="https://www.agilicus.com/anyx-guide/proxy/#parameter-rewrite-filter"
    pageInfoWidth="full"
    titleSize="small"
    (updateEvent)="updateEvent()"
    (removeSelected)="deleteSelected()"
  >
  </portal-table-layout>
</div>