import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject, Observable, combineLatest, of } from 'rxjs';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  createSelectColumn,
  InputColumn,
  SelectColumn,
  createActionsColumn,
  ActionMenuOptions,
  setColumnDefs,
} from '../table-layout/column-definitions';
import {
  Issuer,
  AutoCreateStatus,
  LocalAuthUpstreamIdentityProvider,
  Connector,
  ConnectorsService,
  ConnectorSpec,
  UpstreamGroupMapping,
  IssuersService,
  Organisation,
} from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { concatMap, takeUntil } from 'rxjs/operators';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { FilterManager } from '../filter/filter-manager';
import { cloneDeep } from 'lodash-es';
import { updateTableElements, getEmptyStringIfUnset } from '../utils';
import { createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { selectCurrentOrganisation, selectCurrentOrgIssuer } from '@app/core/organisations/organisations.selectors';
import { OptionalLocalAuthUpstreamIdentityProvider } from '../optional-types';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  UpstreamProviderDialogData,
  UpstreamProviderSetupDialogComponent,
} from '../upstream-provider-setup-dialog/upstream-provider-setup-dialog.component';
import { ButtonType } from '../button-type.enum';
import { getDefaultDialogConfig } from '../dialog-utils';
import { enableConnectorLocalAuthIfNotEnabled, getConnectors } from '@app/core/api/connectors/connectors-api-utils';
import {
  UpstreamGroupMappingsDialogComponent,
  GroupMappingsDialogData,
} from '../upstream-group-mappings-dialog/upstream-group-mappings-dialog.component';
import { getDefaultTableProperties } from '../table-layout-utils';
import {
  getGroupMappingFromUpstreamIdentityProvider,
  getUpstreamProviderUpdateSuccessMessage,
  getUpstreamProviderUpdateFailMessage,
  getDefaultUniqueUpstreamProviderProperties,
  getDefaultSharedUpstreamProviderProperties,
  getAutoCreateStatusColumn,
  createEditableInputColumn,
  refreshIssuer,
  OIDCUpstreamIdentityProviderElement,
  LocalAuthUpstreamIdentityProviderElement,
} from '../authentication-utils';
import { ComponentName } from '../component-type.enum';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { isValidIconValue, isValidUpstreamDomainName } from '../validation-utils';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { initIssuer, savingIssuer } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer, selectIssuerRefreshDataValue, selectSavingIssuer } from '@app/core/issuer-state/issuer.selectors';
import { getUpstreamDomainNameTooltipText, sanitiseAndUpdateExistingUpstreamGroupMapping } from '@app/core/issuer-state/issuer.utils';

@Component({
  selector: 'portal-onsite-identity',
  templateUrl: './onsite-identity.component.html',
  styleUrls: ['./onsite-identity.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnsiteIdentityComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  private currentOrgIssuer$: Observable<string>;
  public currentOrgIssuer: string;
  public issuerCopy: Issuer;
  public hasPermissions: boolean;
  public columnDefs: Map<string, Column<OIDCUpstreamIdentityProviderElement>> = new Map();
  public localAuthColumnDefs: Map<string, Column<LocalAuthUpstreamIdentityProviderElement>> = new Map();
  public localAuthTableData: Array<LocalAuthUpstreamIdentityProviderElement> = [];
  public rowObjectName = 'PROVIDER';
  public filterManager: FilterManager = new FilterManager();
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      'ADD PROVIDER',
      ButtonColor.PRIMARY,
      'Add a new upstream provider',
      'Button that adds a new upstream provider',
      () => {
        this.openAddDialog();
      }
    ),
  ];
  public connectors: Array<Connector> = [];
  public connectorIdToConnectorMap: Map<string, Connector> = new Map();
  public connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public currentOrg: Organisation;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/onsite-identity/`;
  public pageDescriptiveHelpImageWithTextWrap = 'assets/img/sign-in-screen.png';
  public pageDescriptiveTextWithImageWrap = '';
  private localRefreshDataValue = 0;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private connectorsService: ConnectorsService,
    private issuersService: IssuersService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    const currentIssuer$ = this.store.pipe(select(selectCurrentIssuer));
    const savingIssuerState$ = this.store.pipe(select(selectSavingIssuer));
    const refreshDataState$ = this.store.pipe(select(selectIssuerRefreshDataValue));
    this.currentOrgIssuer$ = this.store.pipe(select(selectCurrentOrgIssuer));
    const permissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanAdminIssuers, selectCanAdminApps)));
    const connectorsFromPermissions$ = permissions$.pipe(
      concatMap((permissionsResp) => {
        this.orgId = permissionsResp.orgId;
        this.hasPermissions = permissionsResp.hasPermission;
        let connectors$: Observable<Array<Connector>> = of(undefined);
        if (this.hasPermissions) {
          connectors$ = getConnectors(this.connectorsService, this.orgId, ConnectorSpec.ConnectorTypeEnum.agent);
        }
        return connectors$;
      })
    );
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    combineLatest([currentIssuer$, savingIssuerState$, refreshDataState$, this.currentOrgIssuer$, connectorsFromPermissions$, currentOrg$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([currentIssuerResp, savingIssuerStateResp, refreshDataStateResp, currentOrgIssuerResp, connectorsResp, currentOrgResp]: [
          Issuer,
          boolean,
          number,
          string,
          Array<Connector>,
          Organisation
        ]) => {
          if (!this.hasPermissions || savingIssuerStateResp) {
            // Need this in order for the "No Permissions" text to be displayed when the page first loads.
            this.changeDetector.detectChanges();
            return;
          }
          if (!!connectorsResp) {
            this.connectors = connectorsResp;
          }
          this.currentOrg = currentOrgResp;
          this.setConnectorMaps();
          this.currentOrgIssuer = currentOrgIssuerResp;
          this.pageDescriptiveTextWithImageWrap = this.getPageDescriptiveTextWithImageWrap();
          if (!currentIssuerResp) {
            this.issuerCopy = undefined;
            this.changeDetector.detectChanges();
            return;
          }
          if (!this.issuerCopy || this.localRefreshDataValue !== refreshDataStateResp) {
            this.localRefreshDataValue = refreshDataStateResp;
            this.issuerCopy = cloneDeep(currentIssuerResp);
            if (this.issuerCopy.local_auth_upstreams === undefined) {
              this.resetEmptyLocalAuthTable();
            } else {
              this.updateLocalAuthTable();
            }
          }
          const connectorColumn = this.localAuthColumnDefs.get('upstream_id');
          connectorColumn.allowedValues = this.connectors.map((connector) => connector.spec.name);
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPageDescriptiveTextWithImageWrap(): string {
    return `If you have installed a connector on a local device which also provides authentication services, you may expose those to your users at the authentication page at ${this.currentOrgIssuer}.`;
  }

  private getNameColumn(): InputColumn<OIDCUpstreamIdentityProviderElement | LocalAuthUpstreamIdentityProviderElement> {
    const nameColumn = createEditableInputColumn('name');
    nameColumn.isCaseSensitive = true;
    nameColumn.isUnique = true;
    nameColumn.isValidEntry = (str: string): boolean => {
      return str.length > 0 && str.length < 101;
    };
    return nameColumn;
  }

  private initializeColumnDefs(): void {
    this.initializeLocalAuthColumnDefs();
  }

  private saveIssuer(issuer: Issuer): void {
    this.issuerCopy = issuer;
    this.store.dispatch(savingIssuer({ obj: this.issuerCopy, trigger_update_side_effects: false, notifyUser: true }));
  }

  public openAddDialog(): void {
    const dialogData: UpstreamProviderDialogData = {
      sharedUpstreamProviderData: getDefaultSharedUpstreamProviderProperties(),
      uniqueUpstreamProviderData: getDefaultUniqueUpstreamProviderProperties(),
      issuer: this.issuerCopy,
      store: this.store,
      connectors: this.connectors,
      applications: [],
      currentOrg: this.currentOrg,
      componentName: ComponentName.onsite_identity,
    };
    const dialogRef = this.dialog.open(
      UpstreamProviderSetupDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
  }

  private setConnectorMaps(): void {
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of this.connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  private getIconColumn(): InputColumn<LocalAuthUpstreamIdentityProviderElement> {
    const iconColumn = createInputColumn('icon');
    iconColumn.isEditable = true;
    iconColumn.isCaseSensitive = true;
    iconColumn.getHeaderTooltip = (): string => {
      return 'File to use for the icon';
    };
    iconColumn.isValidEntry = (str: string): boolean => {
      if (str === '') {
        return true;
      }
      return isValidIconValue(str);
    };
    return iconColumn;
  }

  private getUpstreamDomainNameColumn(): InputColumn<LocalAuthUpstreamIdentityProviderElement> {
    const iconColumn = createInputColumn('upstream_domain_name');
    iconColumn.isEditable = true;
    iconColumn.getHeaderTooltip = (): string => {
      return getUpstreamDomainNameTooltipText();
    };
    iconColumn.isValidEntry = (str: string): boolean => {
      return isValidUpstreamDomainName(str);
    };
    return iconColumn;
  }

  private getConnectorColumn(): SelectColumn<LocalAuthUpstreamIdentityProviderElement> {
    const connectorColumn = createSelectColumn('upstream_id');
    connectorColumn.displayName = 'Connector';
    connectorColumn.isEditable = true;
    connectorColumn.getDisplayValue = (element: OptionalLocalAuthUpstreamIdentityProvider) => {
      return this.connectorIdToConnectorMap.get(element.upstream_id)?.spec?.name
        ? this.connectorIdToConnectorMap.get(element.upstream_id).spec.name
        : '';
    };
    return connectorColumn;
  }

  private getActionsColumn(): Column<LocalAuthUpstreamIdentityProviderElement | OIDCUpstreamIdentityProviderElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<LocalAuthUpstreamIdentityProviderElement>> = [
      {
        displayName: 'Configure Group Mappings',
        icon: 'open_in_browser',
        tooltip: `Click to view/modify this upstreams group mappings. 
          An upstream group mapping uses your upstream group information to populate groups in the agilicus system.`,
        onClick: (element: OptionalLocalAuthUpstreamIdentityProvider) => {
          const groupMapping = getGroupMappingFromUpstreamIdentityProvider(element as LocalAuthUpstreamIdentityProvider, this.issuerCopy);
          this.openUpstreamGroupMappingDialog(element.name, groupMapping);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeLocalAuthColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getNameColumn(),
        this.getIconColumn(),
        this.getUpstreamDomainNameColumn(),
        getAutoCreateStatusColumn(),
        this.getConnectorColumn(),
        this.getActionsColumn(),
      ],
      this.localAuthColumnDefs
    );
  }

  private updateLocalAuthTable(): void {
    this.buildLocalAuthTableData();
    this.replaceLocalAuthTableWithCopy();
  }

  private buildLocalAuthTableData(): void {
    const data: Array<LocalAuthUpstreamIdentityProviderElement> = [];
    for (let i = 0; i < this.issuerCopy.local_auth_upstreams.length; i++) {
      data.push(this.createLocalAuthUpstreamElement(this.issuerCopy.local_auth_upstreams[i], i));
    }
    updateTableElements(this.localAuthTableData, data);
  }

  private createLocalAuthUpstreamElement(
    localAuthUpstream: LocalAuthUpstreamIdentityProvider,
    index: number
  ): LocalAuthUpstreamIdentityProviderElement {
    const data: LocalAuthUpstreamIdentityProviderElement = {
      name: '',
      issuer: '',
      icon: '',
      auto_create_status: AutoCreateStatus.default,
      upstream_id: '',
      backingObject: localAuthUpstream,
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(localAuthUpstream)) {
      data[key] = getEmptyStringIfUnset(localAuthUpstream[key]);
    }
    return data;
  }

  private replaceLocalAuthTableWithCopy(): void {
    const tableDataCopy = [...this.localAuthTableData];
    this.localAuthTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private getLocalAuthUpstreamsFromTable(): Array<LocalAuthUpstreamIdentityProvider> {
    return this.localAuthTableData.map((entry: LocalAuthUpstreamIdentityProviderElement) => {
      const localAuthUpstream: LocalAuthUpstreamIdentityProvider = {
        ...entry.backingObject,
        name: entry.name,
        issuer: entry.issuer,
        upstream_type: entry.upstream_type,
        icon: entry.icon,
        auto_create_status: entry.auto_create_status,
        upstream_id: entry.upstream_id,
        upstream_domain_name: entry.upstream_domain_name,
      };
      return localAuthUpstream;
    });
  }

  public updateLocalAuthEvent(): void {
    const updatedLocalAuthUpstreamList = this.getLocalAuthUpstreamsFromTable();
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    copyOfIssuerCopy.local_auth_upstreams = updatedLocalAuthUpstreamList;
    this.saveIssuer(copyOfIssuerCopy);
  }

  private removeLocalAuthUpstreams(): void {
    this.localAuthTableData = this.localAuthTableData.filter((localAuthUpstream) => !localAuthUpstream.isChecked);
  }

  public deleteSelectedLocalAuthUpstreams(): void {
    this.removeLocalAuthUpstreams();
    const updatedLocalAuthUpstreamList = this.getLocalAuthUpstreamsFromTable();
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    copyOfIssuerCopy.local_auth_upstreams = updatedLocalAuthUpstreamList;
    this.saveIssuer(copyOfIssuerCopy);
  }

  public updateLocalAuthSelection(params: {
    value: string;
    column: Column<LocalAuthUpstreamIdentityProviderElement>;
    element: LocalAuthUpstreamIdentityProviderElement;
  }): void {
    const targetConnector = this.connectorNameToConnectorMap.get(params.value);
    params.element.upstream_id = targetConnector?.metadata.id;
    enableConnectorLocalAuthIfNotEnabled(this.connectorsService, this.notificationService, targetConnector);
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyLocalAuthTable(): void {
    this.localAuthTableData.length = 0;
  }

  private openUpstreamGroupMappingDialog(upstreamName: string, upstreamGroupMapping: UpstreamGroupMapping): void {
    const dialogData: GroupMappingsDialogData = {
      upstreamName,
      upstreamGroupMapping,
      orgId: this.orgId,
      onUpdate: this.saveGroupMappings.bind(this),
    };
    const dialogRef = this.dialog.open(
      UpstreamGroupMappingsDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
        maxWidth: '950px',
      })
    );
  }

  private createNewUpstreamGroupMapping(upstreamProvider: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    this.issuersService
      .createUpstreamGroupMapping({
        issuer_id: this.issuerCopy.id,
        UpstreamGroupMapping: updatedUpstreamGroupMapping,
      })
      .subscribe(
        (resp) => {
          this.updateIssuerGroupMappingList(upstreamProvider, resp);
          this.notificationService.success(getUpstreamProviderUpdateSuccessMessage(upstreamProvider));
        },
        (err) => {
          this.notificationService.error(getUpstreamProviderUpdateFailMessage(upstreamProvider));
        }
      );
  }

  private updateUpstreamGroupMapping(upstreamProvider: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    sanitiseAndUpdateExistingUpstreamGroupMapping(
      this.issuersService,
      updatedUpstreamGroupMapping,
      this.issuerCopy.id,
      this.orgId
    ).subscribe(
      (resp) => {
        this.updateIssuerGroupMappingList(upstreamProvider, resp);
        this.notificationService.success(getUpstreamProviderUpdateSuccessMessage(upstreamProvider));
      },
      (err) => {
        this.notificationService.error(getUpstreamProviderUpdateFailMessage(upstreamProvider));
      }
    );
  }

  public saveGroupMappings(upstreamProvider: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    if (!updatedUpstreamGroupMapping.metadata) {
      this.createNewUpstreamGroupMapping(upstreamProvider, updatedUpstreamGroupMapping);
    } else {
      this.updateUpstreamGroupMapping(upstreamProvider, updatedUpstreamGroupMapping);
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.localAuthTableData, this.columnDefs, this.updateLocalAuthEvent.bind(this));
  }

  private updateIssuerGroupMappingList(upstreamIssuer: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    // We need to make a copy here in order to prevent errors as a result of the component freezing the object
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    for (let i = 0; i < copyOfIssuerCopy.upstream_group_mappings.length; i++) {
      if (copyOfIssuerCopy.upstream_group_mappings[i].spec.upstream_issuer === upstreamIssuer) {
        copyOfIssuerCopy.upstream_group_mappings[i] = updatedUpstreamGroupMapping;
        this.issuerCopy = copyOfIssuerCopy;
        refreshIssuer(this.store, this.issuerCopy);
        return;
      }
    }
    copyOfIssuerCopy.upstream_group_mappings.push(updatedUpstreamGroupMapping);
    this.issuerCopy = copyOfIssuerCopy;
    refreshIssuer(this.store, this.issuerCopy);
  }
}
