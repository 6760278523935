import { MetadataWithId, ResourceConfig } from '@agilicus/angular';
import { AppState } from '@app/core';
import { convertTimeToMilliseconds } from '@app/shared/components/date-utils';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CrudStateCollection } from './crud-management-state-definitions';
import { CrudRegistry } from './crud-registry-service.service';
import {
  CreateAction,
  CrudActions,
  DeleteAction,
  DeleteListAction,
  GetAction,
  ListAction,
  SetAction,
  UpdateAction,
  UpdateListAction,
} from './state-driven-crud.actions';

/**
 * We need to do the conversion here since id type is "string" in MetadataWithId
 */
export interface MetadataWithIdConverted<IDType> extends Omit<MetadataWithId, 'id'> {
  /**
   * Unique identifier
   */
  readonly id?: IDType;
}

export interface DataTypeObject<IDType> {
  metadata?: MetadataWithIdConverted<IDType>;
  /**
   * Unique identifier
   */
  readonly id?: IDType;
  /**
   * Creation time
   */
  readonly created?: Date;
  /**
   * Updated time
   */
  updated?: Date;
  _builtin_original?: DataTypeObject<IDType>;
  status?: any;
}

export interface ResourceObject<IDType> extends DataTypeObject<IDType> {
  spec?: {
    resource_config?: ResourceConfig;
  };
}

export interface CRUD<DataType, IDType> {
  create(id: IDType, obj: DataType, orgId: string): Observable<DataType>;
  update(id: IDType, obj: DataType, orgId: string): Observable<DataType>;
  get(id: IDType, orgId: string): Observable<DataType>;
  list(orgId: string): Observable<Array<DataType>>;
  delete(id: IDType, orgId: string): Observable<void>;
}

export interface ObjectStateStore<DataType> {
  [key: string]: ObjectState<DataType>;
}

export interface CrudState<DataType> {
  desiredState: ObjectStateStore<DataType>;
  lastAppliedState: ObjectStateStore<DataType>;
}

export interface IDTypeBase {
  toString(): string;
}

export interface SavedState {
  version: number;
  saved: boolean;
}

export type GuidToSavedStateMap<IDType> = Map<IDType, SavedState>;

export class ObjectState<DataType> {
  constructor(readonly version: number, readonly obj: DataType) {}
}

export function getCrudActionTypeName(crudAction: CrudActions, crudRegistyName: string): string {
  return `${crudAction} ${crudRegistyName}`;
}

export function getNewCrudStateObjGuid(): string {
  return 'new';
}

export function getGuidFromObject<DataType extends DataTypeObject<IDType>, IDType>(obj: DataType): IDType {
  if (!!obj.metadata) {
    return obj.metadata.id;
  }
  return obj.id;
}

export function getCreatedDateFromObject<DataType extends DataTypeObject<IDType>, IDType>(obj: DataType): Date {
  if (!!obj.metadata) {
    return obj.metadata.created;
  }
  return obj.created;
}

export function sortListByCreatedDate<DataType extends DataTypeObject<IDType>, IDType>(e1: DataType, e2: DataType): number {
  if (getCreatedDateFromObject<DataType, IDType>(e1) === null && getCreatedDateFromObject<DataType, IDType>(e2) === null) {
    return 0;
  }
  if (getCreatedDateFromObject<DataType, IDType>(e1) === null) {
    return -1;
  }
  if (getCreatedDateFromObject<DataType, IDType>(e2) === null) {
    return 1;
  }
  return (
    convertTimeToMilliseconds(new Date(getCreatedDateFromObject<DataType, IDType>(e2))) -
    convertTimeToMilliseconds(new Date(getCreatedDateFromObject<DataType, IDType>(e1)))
  );
}

/**
 * We need to replace the updated and _builtin_original values with the latest from the api,
 * otherwise the patch_via_put will fail with a conflict error.
 */
export function defaultSanitiseObjectConflicts<DataType extends DataTypeObject<IDType>, IDType>(
  obj: DataType,
  latestObjFromApi: DataType
): DataType {
  let sanitisedObj = {
    ...obj,
  };
  if (!!obj.metadata) {
    sanitisedObj = {
      ...sanitisedObj,
      metadata: latestObjFromApi.metadata ? latestObjFromApi.metadata : undefined,
    };
  }
  if (!!obj.status) {
    sanitisedObj = {
      ...sanitisedObj,
      status: latestObjFromApi.status ? latestObjFromApi.status : undefined,
    };
  }
  if (!!obj.updated) {
    sanitisedObj = {
      ...sanitisedObj,
      updated: latestObjFromApi.updated ? latestObjFromApi.updated : undefined,
    };
  }
  if (!!obj._builtin_original) {
    sanitisedObj = {
      ...sanitisedObj,
      _builtin_original: latestObjFromApi._builtin_original ? latestObjFromApi._builtin_original : undefined,
    };
  }
  return sanitisedObj;
}

export function getSanitisedObject<DataType extends DataTypeObject<IDType>, IDType>(
  crudRegistry: CrudRegistry<DataType, IDType>,
  obj: DataType,
  latestObjFromApi: DataType
): DataType {
  if (!!crudRegistry.customSanitiseObjectConflictsFunc) {
    return crudRegistry.customSanitiseObjectConflictsFunc(obj, latestObjFromApi);
  }
  return defaultSanitiseObjectConflicts(obj, latestObjFromApi);
}

export class SimpleCRUDInterface<DataType, IDType> {
  public store: Store<AppState>;
  public crudRegistryName: CrudStateCollection;
  constructor() {}

  public create(id: IDType, obj: DataType, notifyUser: boolean, refreshData: boolean = true): void {
    this.store.dispatch(
      new CreateAction<DataType, IDType>(
        getCrudActionTypeName(CrudActions.CREATE, this.crudRegistryName),
        this.crudRegistryName,
        id,
        obj,
        notifyUser,
        refreshData
      )
    );
  }

  public update(id: IDType, obj: DataType, notifyUser: boolean, refreshData: boolean = false): void {
    this.store.dispatch(
      new UpdateAction<DataType, IDType>(
        getCrudActionTypeName(CrudActions.UPDATE, this.crudRegistryName),
        this.crudRegistryName,
        id,
        obj,
        notifyUser,
        refreshData
      )
    );
  }

  public updateList(objs: Array<DataType>, notifyUser: boolean, refreshData: boolean = false): void {
    this.store.dispatch(
      new UpdateListAction<DataType>(
        getCrudActionTypeName(CrudActions.UPDATE_LIST, this.crudRegistryName),
        this.crudRegistryName,
        objs,
        notifyUser,
        refreshData
      )
    );
  }

  public get(id: IDType, orgId: string, blankSlate: boolean): void {
    this.store.dispatch(
      new GetAction(getCrudActionTypeName(CrudActions.GET, this.crudRegistryName), this.crudRegistryName, id, orgId, blankSlate)
    );
  }

  public list(orgId: string, blankSlate: boolean): void {
    this.store.dispatch(
      new ListAction(getCrudActionTypeName(CrudActions.LIST, this.crudRegistryName), this.crudRegistryName, orgId, blankSlate)
    );
  }

  public delete(id: IDType, obj: DataType): void {
    this.store.dispatch(
      new DeleteAction<DataType, IDType>(getCrudActionTypeName(CrudActions.DELETE, this.crudRegistryName), this.crudRegistryName, id, obj)
    );
  }

  public deleteList(objs: Array<DataType>, notifyUser: boolean): void {
    this.store.dispatch(
      new DeleteListAction<DataType>(
        getCrudActionTypeName(CrudActions.DELETE_LIST, this.crudRegistryName),
        this.crudRegistryName,
        objs,
        notifyUser
      )
    );
  }

  public set(id: IDType, obj: DataType): void {
    this.store.dispatch(
      new SetAction<DataType, IDType>(getCrudActionTypeName(CrudActions.SET, this.crudRegistryName), this.crudRegistryName, id, obj)
    );
  }
}
