<portal-page-info
  pageTitle="Setup Payment"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
  [pageSubDescriptiveList]="pageSubDescriptiveList"
>
</portal-page-info>

<div class="main-content-container">
  <mat-slide-toggle
    class="mark-complete-slider"
    [checked]="isTaskComplete"
    (change)="onCompleteToggle($event.checked)"
  >
    {{ getTaskCompleteToggleText(targetTask, gettingStartedDataCopy) }}
  </mat-slide-toggle>

  <div class="recommendations-container">
    <portal-getting-started-recommendations
      recommendationCustomText="We recommend that you setup your payment subscription."
      imageSourcePath="assets/img/setup-payment.png"
      (imageClick)="onImageClick()"
    >
    </portal-getting-started-recommendations>
  </div>
</div>