import { ApplicationModel } from '@app/core/models/application/application-model';
import { getAuthenticationMethodOptionValue } from '@app/shared/components/application-template-utils';
import { AuthMethodOption } from '@app/shared/components/auth-method-option.enum';
import { generateRandomUuid } from '@app/shared/components/utils';
import { Issuer, IssuerClient, IssuersService, Organisation, patch_via_put } from '@agilicus/angular';
import { isAuthenticatedViaProxy } from '../models/application/application-model-utils';
import { getApplicationUrl } from '../models/application/application-model-api-utils';
import { concatMap, map, Observable, of, throwError } from 'rxjs';
import { getMoreThanOneResultError } from '../api/api-utils';

export function createNewIssuerClient(issuersService: IssuersService, newIssuerClient: IssuerClient): Observable<IssuerClient | undefined> {
  return issuersService.createClient({
    IssuerClient: newIssuerClient,
  });
}

export function getProxyDefaultRedirectUri(appModel: ApplicationModel, domain: string): string {
  return `${getApplicationUrl(appModel?.name, domain)}/.well-known/redirect`;
}

export function getFqdnAliasRedirectUri(fqdnAlias: string): string {
  return `https://${fqdnAlias}/.well-known/redirect`;
}

export function setIssuerClientRedirectsFromModel(
  appModel: ApplicationModel,
  newIssuerClient: IssuerClient,
  currentOrg: Organisation
): void {
  let newIssuerClientRedirects = [];
  const selectedAuthMethod = getAuthenticationMethodOptionValue(appModel.authentication);
  if (selectedAuthMethod === AuthMethodOption.oidc && !!appModel.authentication.oidc.redirect_uri) {
    newIssuerClientRedirects.push(appModel.authentication.oidc.redirect_uri);
  }
  const proxyRedirects = getProxyRedirects(appModel, currentOrg);
  newIssuerClientRedirects = [...newIssuerClientRedirects, ...proxyRedirects];
  if (newIssuerClientRedirects.length !== 0) {
    newIssuerClient.redirects = newIssuerClientRedirects;
  }
}

export function getProxyRedirects(appModel: ApplicationModel, currentOrg: Organisation): Array<string> {
  const proxyRedirects = [];
  if (!isAuthenticatedViaProxy(appModel.authentication)) {
    return proxyRedirects;
  }
  proxyRedirects.push(getProxyDefaultRedirectUri(appModel, currentOrg.subdomain));
  if (!!appModel.hosting.fqdnAliases.fqdnAliasList) {
    for (const fqdnAlias of appModel.hosting.fqdnAliases.fqdnAliasList) {
      proxyRedirects.push(getFqdnAliasRedirectUri(fqdnAlias));
    }
  }
  return proxyRedirects;
}

export function getIssuerClientFromApplicationModel(
  appModel: ApplicationModel,
  orgId: string,
  currentIssuer: Issuer,
  currentOrg: Organisation
): IssuerClient {
  const newIssuerClient: IssuerClient = {
    name: appModel.name,
    secret: generateRandomUuid(),
    application: appModel.name,
    mfa_challenge: IssuerClient.MfaChallengeEnum.user_preference,
    org_id: orgId,
    issuer_id: currentIssuer.id,
    organisation_scope: IssuerClient.OrganisationScopeEnum.here_and_down,
    single_sign_on: IssuerClient.SingleSignOnEnum.user_preference,
  };
  const selectedAuthMethod = getAuthenticationMethodOptionValue(appModel.authentication);
  if (selectedAuthMethod === AuthMethodOption.saml) {
    newIssuerClient.saml_metadata_file = appModel.authentication.saml.saml_metadata_file;
  }
  setIssuerClientRedirectsFromModel(appModel, newIssuerClient, currentOrg);
  return newIssuerClient;
}

export function updateExistingIssuerClient(issuersService: IssuersService, issuerClientToUpdate: IssuerClient): Observable<IssuerClient> {
  const putter = (client: IssuerClient) => {
    return issuersService.replaceClient({
      client_id: client.id,
      IssuerClient: client,
    });
  };
  const getter = (client: IssuerClient) => {
    return issuersService.getClient({
      client_id: client.id,
      org_id: client.org_id,
    });
  };
  return patch_via_put(issuerClientToUpdate, getter, putter);
}

export function deleteIssuerClient$(issuersService: IssuersService, issuerClient: IssuerClient): Observable<any> {
  return issuersService.deleteClient({
    client_id: issuerClient.id,
    org_id: issuerClient.org_id,
  });
}

/**
 * Since the Issuer Client name is modified in the back-end to include the guid,
 * we need to find it based on the matching application name.
 */
export function getIssuerClientByApplicationName$(
  issuersService: IssuersService,
  appName: string,
  orgId: string
): Observable<IssuerClient | undefined> {
  return issuersService
    .listClients({
      org_id: orgId,
    })
    .pipe(
      concatMap((resp) => {
        const filteredIssuerClientsList = resp.clients.filter((client) => client.application === appName);
        const moreThanOneResultError = getMoreThanOneResultError(filteredIssuerClientsList);
        if (!!moreThanOneResultError) {
          return throwError(() => moreThanOneResultError);
        }
        return of(filteredIssuerClientsList.length === 0 ? undefined : filteredIssuerClientsList[0]);
      })
    );
}

export function deleteIssuerClientByApplicationName$(
  issuersService: IssuersService,
  appName: string,
  orgId: string
): Observable<IssuerClient> {
  return getIssuerClientByApplicationName$(issuersService, appName, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp) {
        return of(undefined);
      }
      return deleteIssuerClient$(issuersService, getResp).pipe(map((deleteResp) => getResp));
    })
  );
}
