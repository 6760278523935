import { SSHResource, ApplicationServicesService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import {
  updateExistingSSHResource,
  createNewSSHResource,
  getSSHResourceById,
  getSSHResourcesList,
  deleteExistingSSHResource,
} from '../application-service-state/application-services-utils';
import * as SSHResourceActions from '../ssh-state/ssh.actions';

@Injectable({
  providedIn: 'root',
})
export class SSHResourceStateService extends SimpleCRUDInterface<SSHResource, string> {
  public crudRegistryName = CrudStateCollection.ssh;

  constructor(
    public store: Store<AppState>,
    private applicationsService: ApplicationServicesService,
    private crudRegistryService: CrudRegistryService<SSHResource, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<SSHResource, string> = {
        create: (id: string, obj: SSHResource, orgId: string) => createNewSSHResource(this.applicationsService, obj),
        update: (id: string, obj: SSHResource, orgId: string) => updateExistingSSHResource(this.applicationsService, obj),
        get: (id: string, orgId: string) => getSSHResourceById(this.applicationsService, id, orgId),
        list: (orgId: string) => getSSHResourcesList(this.applicationsService, orgId),
        delete: (id: string, orgId: string) => deleteExistingSSHResource(this.applicationsService, id, orgId),
      };
      const crudRegistry: CrudRegistry<SSHResource, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: SSHResource) => obj.spec.name,
        doneSaveAction: SSHResourceActions.upsertSSHResource,
        doneSaveListAction: SSHResourceActions.upsertSSHResources,
        doneDeleteAction: SSHResourceActions.deleteSSHResource,
        doneDeleteListAction: SSHResourceActions.deleteSSHResources,
        setListAction: SSHResourceActions.loadSSHResources,
        refreshStateAction: SSHResourceActions.refreshSSHResources,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
