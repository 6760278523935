import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AppState, selectSignupState } from '@app/core';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { SignupState } from '@app/core/signup/signup.models';
import { select, Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/core/services/auth-service.service';
import { Router } from '@angular/router';
import { IsLoggedInService } from '@app/core/services/is-logged-in.service';

@Component({
  selector: 'portal-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public orgId: string;
  public productGuideLink = `https://www.agilicus.com/product-guide`;
  public signupState: SignupState;
  public isSigningUp = false;
  private isLoggedIn: boolean;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService,
    public router: Router,
    private isLoggedInService: IsLoggedInService
  ) {}

  public ngOnInit(): void {
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    const signupState$ = this.store.pipe(select(selectSignupState));
    const isLoggedIn$ = this.isLoggedInService.getIsLoggedIn();
    combineLatest([currentOrg$, signupState$, isLoggedIn$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([currentOrgResp, signupStateResp, isLoggedInResp]) => {
        this.isLoggedIn = isLoggedInResp !== undefined ? isLoggedInResp : false;
        this.orgId = currentOrgResp?.id;
        this.signupState = signupStateResp;
        this.isSigningUp = signupStateResp.is_signing_up;
        this.changeDetector.detectChanges();
      });
  }

  public onLoginClick(): void {
    // Clear the local browser state when a user logs in to ensure no stale state is present.
    window.localStorage.clear();
    this.authService.login(this.router.url);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /*
   * This is a fairly simple 'hack' to prevent users from unwittingly
   * coming to our endpoint rather than their own.
   */
  public showUnwelcomeMessage(): boolean {
    const unwelcome =
      !this.isLoggedIn && !(document.location.hostname !== 'admin.agilicus.com' && document.location.hostname !== 'admin.agilicus.cloud');
    return unwelcome;
  }

  public showWelcomeMessage(): boolean {
    return !this.isLoggedIn;
  }

  public showTaskBoard(): boolean {
    return !!this.isLoggedIn;
  }
  public redirectSignin(): void {
    document.location.href = 'https://admin.agilicus.cloud/signup?flush=1';
  }
}
