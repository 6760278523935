import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnChanges,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModeOptions } from '@app/core/models/security/mode-options.enum';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { GenericSecurityDefinition, GenericSecurityHeader } from '../security-headers/security-headers.component';
import { getFormatedMode } from '../security-utils';
import { getEmptyStringIfUnset } from '../utils';

@Component({
  selector: 'portal-security-header-generic-form',
  templateUrl: './security-header-generic-form.component.html',
  styleUrls: ['./security-header-generic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityHeaderGenericFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public genericSecurityHeaderSettings: GenericSecurityDefinition;
  @Output() public genericSecurityHeaderChange = new EventEmitter<any>();
  public genericHeaderForm: UntypedFormGroup;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getFormatedMode = getFormatedMode;

  constructor(private formBuilder: UntypedFormBuilder, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeGenericHeaderFormGroup();
  }

  public ngOnChanges(): void {
    if (!this.genericSecurityHeaderSettings) {
      return;
    }
    this.setGenericHeaderFormGroupValues();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private initializeGenericHeaderFormGroup(): void {
    if (!!this.genericHeaderForm) {
      return;
    }
    this.genericHeaderForm = this.formBuilder.group({
      enabled: [false, [Validators.required]],
      mode: ['clear', [Validators.required]],
      override: [{ value: '', disabled: !this.isOverrideEnabled() }],
    });
    this.changeDetector.detectChanges();
  }

  private setGenericHeaderFormGroupValues(): void {
    this.initializeGenericHeaderFormGroup();
    this.genericHeaderForm
      .get('enabled')
      .setValue(
        this.genericSecurityHeaderSettings?.settings?.enabled !== undefined ? this.genericSecurityHeaderSettings.settings.enabled : false
      );
    this.genericHeaderForm
      .get('mode')
      .setValue(!!this.genericSecurityHeaderSettings.settings?.mode ? this.genericSecurityHeaderSettings.settings?.mode : 'clear');
    this.genericHeaderForm.get('override').setValue(getEmptyStringIfUnset(this.genericSecurityHeaderSettings.settings?.override));
    this.changeDetector.detectChanges();
  }

  private getGenericHeaderFromForm(): GenericSecurityHeader {
    return {
      enabled: this.genericHeaderForm.get('enabled').value,
      mode: this.genericHeaderForm.get('mode').value,
      override: this.genericHeaderForm.get('override').value,
    };
  }

  private emitChanges(): void {
    this.genericSecurityHeaderChange.emit({
      type: this.genericSecurityHeaderSettings.type,
      updatedGenericSecurityHeader: this.getGenericHeaderFromForm(),
    });
  }

  public onFormBlur(formField: string): void {
    if (this.genericHeaderForm.controls[formField].invalid) {
      return;
    }
    // Do not proceed if the value has not been changed.
    if (!this.genericHeaderForm.controls[formField].dirty) {
      return;
    }
    this.emitChanges();
  }

  public onCheckboxChange(): void {
    this.emitChanges();
  }

  public onModeOptionChange(value: string): void {
    const modeFormControl = this.genericHeaderForm.get('mode');
    modeFormControl.setValue(value);
    const isModeFormControlValid = modeFormControl.valid;
    if (!isModeFormControlValid) {
      return;
    }
    this.emitChanges();
  }

  public showModeRadioButtons(): boolean {
    return this.genericSecurityHeaderSettings?.modeOptions.length <= 4;
  }

  public onModeSelectionChange(): void {
    const modeFormControl = this.genericHeaderForm.get('mode');
    const isModeFormControlValid = modeFormControl.valid;
    if (!isModeFormControlValid) {
      return;
    }
    this.emitChanges();
  }

  public isOverrideEnabled(): boolean {
    return this.genericSecurityHeaderSettings?.settings?.mode === ModeOptions.override;
  }

  public getOverrideInputTooltipText(): string {
    return `Set a specific value for the header. Select the "Override" option above to configure this value.`;
  }
}
