<div class="dialog-container">
  <h2 class="title">Update User Identity</h2>
  <p class="descriptive-text">This will reset the identity of "{{ data.userElement.email }}" by changing the identifying information to the value you provide below. In addition, the user's multi-factor preferences will be reset.</p>
  
  <div class="form-container">
    <form
      [formGroup]="userIdentityFormGroup"
      spellcheck="false"
      autocomplete="off"
    >
      <portal-stepper-form-field
        placeholder="Enter the identity of the user"
        label="The value used to identify this user is..."
        [formCtrl]="userIdentityFormGroup.get('email')"
        required="true"
      >
      </portal-stepper-form-field>
    </form>
  </div>

  <div *ngIf="!!errorMessage" class="error-container">
    <h4>Warning: Failed to save</h4>
    <p>{{ errorMessage }}</p>
  </div>
  
  <div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onCancelClick()">CANCEL</button>
    <button mat-raised-button color="primary" [disabled]="saveDisabled" (click)="onSaveClick()">SAVE</button>
  </div>
</div>