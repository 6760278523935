export enum PermissionsDefinition {
  hasUsersReadOrAdminPermissions = 'hasUsersReadOrAdminPermissions',
  hasUsersAdminPermissions = 'hasUsersAdminPermissions',
  hasApplicationReadOrAdminPermissions = 'hasApplicationReadOrAdminPermissions',
  hasApplicationAdminPermissions = 'hasApplicationAdminPermissions',
  hasResourceReadOrAdminPermissions = 'hasResourceReadOrAdminPermissions',
  hasResourceAdminPermissions = 'hasResourceAdminPermissions',
  hasIssuersAdminPermissions = 'hasIssuersAdminPermissions',
  hasAuditPermissions = 'hasAuditPermissions',
  hasAuditDestinationAdminPermissions = 'hasAuditDestinationAdminPermissions',
  hasDiagnosticsReadOrAdminPermissions = 'hasDiagnosticsReadOrAdminPermissions',
  hasMetricsReadonlyPermissions = 'hasMetricsReadonlyPermissions',
  hasLabelAdminPermissions = 'hasLabelAdminPermissions',
  hasRulesAdminPermissions = 'hasRulesAdminPermissions',
  requestFlowEnabled = 'requestFlowEnabled',
}
