import {
  ListResourceRolesRequestParams,
  ListResourcesRequestParams,
  PermissionsService,
  Resource,
  ResourceRole,
  ResourceTypeEnum,
  ResourcesService,
  patch_via_put,
  AddResourceRequestParams,
  ReconcileDefaultPolicyRequestParams,
  GetResourceRequestParams,
} from '@agilicus/angular';
import { ResourceType } from '@app/shared/components/resource-type.enum';
import { Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

export function getResouces(
  resourcesService: ResourcesService,
  orgId: string,
  resourceType?: ResourceTypeEnum,
  excludeResourceTypeList?: Array<ResourceTypeEnum>
): Observable<Array<Resource>> {
  const listResourcesRequestParams: ListResourcesRequestParams = {
    org_id: orgId,
  };
  if (!!resourceType) {
    listResourcesRequestParams.resource_type = resourceType;
  }
  if (!!excludeResourceTypeList && excludeResourceTypeList.length !== 0) {
    listResourcesRequestParams.exclude_resource_type = excludeResourceTypeList;
  }
  return resourcesService.listResources(listResourcesRequestParams).pipe(
    map((resp) => {
      return resp.resources;
    })
  );
}

export function createNewResource$(resourcesService: ResourcesService, resourceToCreate: Resource): Observable<Resource> {
  const addResourceRequestParams: AddResourceRequestParams = {
    Resource: resourceToCreate,
  };
  return resourcesService.addResource(addResourceRequestParams);
}

export function updateExistingResource$(resourcesService: ResourcesService, resourceToUpdate: Resource): Observable<Resource> {
  const putter = (resource: Resource) => {
    return resourcesService.replaceResource({
      resource_id: resource.metadata.id,
      Resource: resource,
    });
  };
  const getter = (resource: Resource) => {
    return resourcesService.getResource({
      resource_id: resource.metadata.id,
      org_id: resource.spec.org_id,
    });
  };
  // strip off the status which we cannot allow to interfere
  // with the diff
  return patch_via_put(resourceToUpdate, getter, putter);
}

export function getResourceRoles(
  permissionsService: PermissionsService,
  orgId: string,
  resourceType?: ResourceType
): Observable<Array<ResourceRole>> {
  const listResourceRolesRequestParams: ListResourceRolesRequestParams = {
    org_id: orgId,
  };
  if (!!resourceType) {
    const convertedResourceType = resourceType as ResourceTypeEnum;
    listResourceRolesRequestParams.resource_type = convertedResourceType;
  }
  return permissionsService.listResourceRoles(listResourceRolesRequestParams).pipe(
    map((resp) => {
      return resp.resource_roles;
    })
  );
}

export function getResourceAndReconcileDefaultPolicy$(
  resourcesService: ResourcesService,
  resourceId: string,
  orgId: string
): Observable<Resource> {
  const getResourceRequestParams: GetResourceRequestParams = {
    resource_id: resourceId,
    org_id: orgId,
  };
  return resourcesService.getResource(getResourceRequestParams).pipe(
    concatMap((resource) => {
      const reconcileDefaultPolicyRequestParams: ReconcileDefaultPolicyRequestParams = {
        resource_id: resource.metadata.id,
        Resource: resource,
      };
      return resourcesService.reconcileDefaultPolicy(reconcileDefaultPolicyRequestParams);
    })
  );
}
