import {
  Application,
  RoleV2,
  AddRoleRequestParams,
  ApplicationsService,
  patch_via_put,
  RoleToRuleEntry,
  AddRoleToRuleEntryRequestParams,
  RuleV2,
  AddRuleRequestParams,
  Connector,
  ListConnectorRequestParams,
  ConnectorsService,
  EnvironmentConfigVarList,
  DeleteRoleToRuleEntryRequestParams,
  DeleteRuleRequestParams,
  RuleConfig,
  HttpRuleCondition,
  RuleScopeEnum,
  HttpRule,
  SimpleResourcePolicyTemplate,
  SimpleResourcePolicyTemplateStructure,
} from '@agilicus/angular';
import { removeElementFromListIfId, unshiftElementToListIfId, updateElementInListIfId } from '@app/shared/components/utils';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { sanitiseAppEnvironments, sanitiseApplication } from '../../shared/utilities/model-helpers/applications';
import { getMoreThanOneResultError } from '../api/api-utils';
import { EnvironmentConfigService } from '../services/environment-config/environment-config.service';

export function getApplicationById(
  applicationsService: ApplicationsService,
  appId: string,
  orgId: string
): Observable<Application | undefined> {
  // As a workaround we need to get all applications and find the correct one by id.
  // This is because calling "GET" on a single application does not include the
  // "owned", "maintained" or "assigned" properties in the response.
  return applicationsService
    .listApplications({
      org_id: orgId,
    })
    .pipe(
      concatMap((appsListResp) => {
        for (const app of appsListResp.applications) {
          if (app.id === appId) {
            return of(app);
          }
        }
        return of(undefined);
      })
    );
}

export function getApplicationByName$(
  applicationsService: ApplicationsService,
  appName: string,
  orgId: string
): Observable<Application | undefined> {
  // As a workaround we need to get all applications and find the correct one by id.
  // This is because calling "GET" on a single application does not include the
  // "owned", "maintained" or "assigned" properties in the response.
  return applicationsService
    .listApplications({
      org_id: orgId,
    })
    .pipe(
      concatMap((resp) => {
        const filteredAppList = resp.applications.filter((app) => app.name === appName);
        const moreThanOneResultError = getMoreThanOneResultError(filteredAppList);
        if (!!moreThanOneResultError) {
          return throwError(() => moreThanOneResultError);
        }
        return of(filteredAppList.length === 0 ? undefined : filteredAppList[0]);
      })
    );
}

export function getListOfOwnedApplications(
  applicationsService: ApplicationsService,
  orgId: string
): Observable<Array<Application> | undefined> {
  return applicationsService
    .listApplications({
      org_id: orgId,
      owned: true,
      assigned: true,
      show_status: true,
      application_type: ['user_defined'],
    })
    .pipe(
      map((appsListResp) => {
        return appsListResp.applications;
      })
    );
}

export function createNewApplication(
  applicationsService: ApplicationsService,
  newApp: Application,
  orgId: string
): Observable<Application | undefined> {
  return applicationsService
    .createApplication({
      Application: sanitiseApplication(newApp),
    })
    .pipe(
      concatMap((postResp) => {
        const pr: Application = postResp as Application;
        // Currently, response does not return whether the app is
        // assigned, maintained or owned. We must set owned to true
        // so that the user that creates the app can also
        // modify the app after creation.
        pr.owned = true;
        pr.maintained = isAppMaintained(postResp, orgId);
        return of(pr);
      })
    );
}

export function updateExistingApplication(applicationsService: ApplicationsService, appToUpdate: Application): Observable<Application> {
  const getter = (app: Application) => {
    return applicationsService
      .getApplication({
        app_id: app.id,
        org_id: app.org_id,
      })
      .pipe(
        map((result) => {
          // strip off the status which we cannot allow to interfere
          // with the diff
          const toReturn = sanitiseAppEnvironments(result);
          return toReturn;
        }),
        catchError((error) => {
          throw error;
        })
      );
  };
  const putter = (app: Application) => {
    return applicationsService.replaceApplication({
      app_id: app.id,
      Application: sanitiseApplication(app),
    });
  };
  // strip off the status which we cannot allow to interfere
  // with the diff
  const toPatch = sanitiseAppEnvironments(appToUpdate);
  return patch_via_put(toPatch, getter, putter);
}

export function deleteApplication(applicationsService: ApplicationsService, appId: string, orgId: string): Observable<void> {
  return applicationsService.deleteApplication({ app_id: appId, org_id: orgId });
}

export function deleteApplicationByName$(
  applicationsService: ApplicationsService,
  appName: string,
  orgId: string
): Observable<Application> {
  return getApplicationByName$(applicationsService, appName, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp) {
        return of(undefined);
      }
      return applicationsService.deleteApplication({ app_id: getResp.id, org_id: orgId }).pipe(map((deleteResp) => getResp));
    })
  );
}

export function createNewRole(
  applicationsService: ApplicationsService,
  roleToCreate: RoleV2,
  targetApp: Application
): Observable<RoleV2 | undefined> {
  const args: AddRoleRequestParams = {
    app_id: targetApp.id,
    RoleV2: roleToCreate,
  };
  return applicationsService.addRole(args);
}

export function createNewRule(applicationsService: ApplicationsService, ruleToCreate: RuleV2): Observable<RuleV2 | undefined> {
  const args: AddRuleRequestParams = {
    app_id: ruleToCreate.spec.app_id,
    RuleV2: ruleToCreate,
  };
  return applicationsService.addRule(args);
}

export function isAppMaintained(app: Application, orgId: string): boolean {
  if (!app.environments) {
    return false;
  }
  return app.environments.some((env) => env.maintenance_org_id === orgId);
}

export function createNewRoletoRuleEntry(
  applicationsService: ApplicationsService,
  roleToRuleEntryToCreate: RoleToRuleEntry,
  targetApp: Application
): Observable<RoleToRuleEntry | undefined> {
  const args: AddRoleToRuleEntryRequestParams = {
    app_id: targetApp.id,
    RoleToRuleEntry: roleToRuleEntryToCreate,
  };
  return applicationsService.addRoleToRuleEntry(args).pipe(
    map((roleToRuleEntryResp: RoleToRuleEntry) => {
      return roleToRuleEntryResp;
    }),
    catchError(() => {
      return of(undefined);
    })
  );
}

export function getConnectorByName(
  connectorsService: ConnectorsService,
  connectorName: string,
  orgId: string
): Observable<Connector | undefined> {
  const listConnectorRequestParams: ListConnectorRequestParams = {
    org_id: orgId,
    name: connectorName,
  };
  return connectorsService.listConnector(listConnectorRequestParams).pipe(
    map((resp) => {
      if (resp.connectors.length !== 0) {
        return resp.connectors[0];
      }
      return undefined;
    })
  );
}

export function getDefaultApplicationRoleName(): string {
  return 'self';
}

export function getDefaultRoleForPublishedApplication(currentApplication: Application, orgId: string): RoleV2 {
  const defaultRole: RoleV2 = {
    spec: {
      app_id: currentApplication.id,
      name: getDefaultApplicationRoleName(),
      comments: 'Default role created when publishing the application',
      org_id: orgId,
    },
  };
  return defaultRole;
}

export function getEnvConfigVarsList(
  environmentConfigService: EnvironmentConfigService,
  appId: string,
  envName: string,
  orgId: string
): Observable<EnvironmentConfigVarList> {
  return from(
    environmentConfigService.getEnvConfig().list({
      app_id: appId,
      org_id: orgId,
      env_name: envName,
    })
  );
}

/**
 * 403 error may be expected for the following api call
 * if the current environments "maintenance_org_id" does not
 * match the current org the user is logged in under.
 */
export function createOrUpdateEnvConfigVarsList(
  environmentConfigService: EnvironmentConfigService,
  appId: string,
  envName: string,
  orgId: string,
  envConfigVarsToUpdate: EnvironmentConfigVarList
): Observable<EnvironmentConfigVarList> {
  return from(
    environmentConfigService.getEnvConfig().patch_all({
      app_id: appId,
      org_id: orgId,
      env_name: envName,
      varList: envConfigVarsToUpdate,
    })
  );
}

export function deleteRule(applicationsService: ApplicationsService, ruleToDelete: RuleV2, appId: string, orgId: string): Observable<void> {
  const args: DeleteRuleRequestParams = {
    app_id: appId,
    rule_id: ruleToDelete.metadata.id,
    org_id: orgId,
  };
  return applicationsService.deleteRule(args);
}

export function createRoleToRuleEntry(
  applicationsService: ApplicationsService,
  roleToRuleEntryToCreate: RoleToRuleEntry,
  appId: string
): Observable<RoleToRuleEntry> {
  const args: AddRoleToRuleEntryRequestParams = {
    app_id: appId,
    RoleToRuleEntry: roleToRuleEntryToCreate,
  };
  return applicationsService.addRoleToRuleEntry(args);
}

export function updateExistingRoleToRuleEntry(
  applicationsService: ApplicationsService,
  roleToRuleEntryToUpdate: RoleToRuleEntry,
  appId: string,
  orgId: string
): Observable<RoleToRuleEntry> {
  const putter = (roleToRuleEntry: RoleToRuleEntry) => {
    return applicationsService.replaceRoleToRuleEntry({
      app_id: appId,
      role_to_rule_entry_id: roleToRuleEntry.metadata.id,
      RoleToRuleEntry: roleToRuleEntry,
    });
  };
  const getter = (roleToRuleEntry: RoleToRuleEntry) => {
    return applicationsService.getRoleToRuleEntry({
      app_id: appId,
      role_to_rule_entry_id: roleToRuleEntry.metadata.id,
      org_id: orgId,
    });
  };
  return patch_via_put(roleToRuleEntryToUpdate, getter, putter);
}

export function deleteRoleToRuleEntry(
  applicationsService: ApplicationsService,
  roleToRuleEntryToDelete: RoleToRuleEntry,
  appId: string,
  orgId: string
): Observable<void> {
  const args: DeleteRoleToRuleEntryRequestParams = {
    app_id: appId,
    role_to_rule_entry_id: roleToRuleEntryToDelete.metadata.id,
    org_id: orgId,
  };
  return applicationsService.deleteRoleToRuleEntry(args);
}

export function updateRoleToRuleEntriesList(
  roleToRuleEntriesToUpdate: Array<RoleToRuleEntry>,
  currentRoleToRuleEntriesList: Array<RoleToRuleEntry>
): Array<RoleToRuleEntry> {
  let updatedRoleToRuleEntriesList = [...currentRoleToRuleEntriesList];
  for (const roleToRuleEntry of roleToRuleEntriesToUpdate) {
    const targetItem = currentRoleToRuleEntriesList.find((item) => item.metadata.id === roleToRuleEntry.metadata.id);
    if (!!targetItem) {
      updatedRoleToRuleEntriesList = updateElementInListIfId(roleToRuleEntry, updatedRoleToRuleEntriesList);
    } else {
      updatedRoleToRuleEntriesList = unshiftElementToListIfId(roleToRuleEntry, updatedRoleToRuleEntriesList);
    }
  }
  return updatedRoleToRuleEntriesList;
}

export function removeRoleToRuleEntries(
  roleToRuleEntriesToRemove: Array<RoleToRuleEntry>,
  currentRoleToRuleEntriesList: Array<RoleToRuleEntry>
): Array<RoleToRuleEntry> {
  let updatedRoleToRuleEntriesList = [...currentRoleToRuleEntriesList];
  for (const roleToRuleEntry of roleToRuleEntriesToRemove) {
    updatedRoleToRuleEntriesList = removeElementFromListIfId(roleToRuleEntry, updatedRoleToRuleEntriesList);
  }
  return updatedRoleToRuleEntriesList;
}

export function removeRulesFromList(rulesToRemove: Array<RuleV2>, currentRulesList: Array<RuleV2>): Array<RoleToRuleEntry> {
  let updatedRulesList = [...currentRulesList];
  for (const rule of rulesToRemove) {
    updatedRulesList = removeElementFromListIfId(rule, updatedRulesList);
  }
  return updatedRulesList;
}

export function makeEnvConfigVarGuid(appId: string, envName: string): string {
  return `${appId}_${envName}`;
}

export function getRedirectSubpathTooltipText(): string {
  return 'The subpath to redirect to if the application is set in a subdirectory instead of the root directory';
}

export const commonPathPrefixRuleName = 'agilicus-admin-prefix-default-redirect';

export function getDefaultCommonPathPrefixRule(subPath: string): RuleConfig {
  let commonPathPrefixValue = subPath;
  if (commonPathPrefixValue[0] !== '/') {
    // Add '/' to beginning if not already there:
    commonPathPrefixValue = `/${commonPathPrefixValue}`;
  }
  return {
    actions: [
      {
        action: 'redirect',
        path: commonPathPrefixValue,
      },
    ],
    comments: 'Added automatically by the Agilicus Admin UI',
    extended_condition: {
      condition: {
        condition_type: 'http_rule_condition',
        methods: [HttpRuleCondition.MethodsEnum.all],
        path_regex: '/',
        rule_type: 'HttpRule',
      },
      negated: false,
    },
    name: commonPathPrefixRuleName,
    priority: -1,
    scope: RuleScopeEnum.any_known_user,
  };
}

export function addCommonPathPrefixRuleToApplication(commonPathPrefixRulePathValue: string, appToUpdate: Application): void {
  const commonPathPrefixRule = getDefaultCommonPathPrefixRule(commonPathPrefixRulePathValue);
  if (!appToUpdate.rules_config) {
    appToUpdate.rules_config = {
      rules: [commonPathPrefixRule],
    };
  } else if (!appToUpdate.rules_config.rules) {
    appToUpdate.rules_config.rules = [commonPathPrefixRule];
  } else {
    appToUpdate.rules_config.rules.push(commonPathPrefixRule);
  }
}

export function addCommonPathPrefixRuleToPolicyTemplate(
  commonPathPrefixRulePathValue: string,
  policyTemplateToUpdate: SimpleResourcePolicyTemplate
): void {
  const commonPathPrefixRule = getDefaultCommonPathPrefixRule(commonPathPrefixRulePathValue);
  const commonPathPrefixPolicyTemplateStructure: SimpleResourcePolicyTemplateStructure = {
    name: commonPathPrefixRule.name,
    root_node: {
      priority: commonPathPrefixRule.priority,
      rule_name: commonPathPrefixRule.name,
      children: [],
    },
  };
  if (!policyTemplateToUpdate.policy_structure) {
    policyTemplateToUpdate.policy_structure = [commonPathPrefixPolicyTemplateStructure];
  } else {
    policyTemplateToUpdate.policy_structure.push(commonPathPrefixPolicyTemplateStructure);
  }
  if (!policyTemplateToUpdate.rules) {
    policyTemplateToUpdate.rules = [commonPathPrefixRule];
  } else {
    policyTemplateToUpdate.rules.push(commonPathPrefixRule);
  }
}

export function getCommonPathPrefixRuleIndexFromRuleConfigList(ruleConfigList: Array<RuleConfig>): number {
  if (!ruleConfigList) {
    return -1;
  }
  for (let i = 0; i < ruleConfigList.length; i++) {
    const rule = ruleConfigList[i];
    if (rule.name === commonPathPrefixRuleName) {
      return i;
    }
  }
  return -1;
}

export function getCommonPathPrefixRuleFromRuleConfigList(ruleConfigList: Array<RuleConfig>): RuleConfig | undefined {
  const index = getCommonPathPrefixRuleIndexFromRuleConfigList(ruleConfigList);
  if (index < 0) {
    return undefined;
  }
  return ruleConfigList[index];
}

export function getCommonPathPrefixRuleFromApp(app: Application): RuleConfig | undefined {
  return getCommonPathPrefixRuleFromRuleConfigList(app?.rules_config?.rules);
}

export function getCommonPathPrefixRuleFromPolicyTemplate(policyTemplate: SimpleResourcePolicyTemplate): RuleConfig | undefined {
  return getCommonPathPrefixRuleFromRuleConfigList(policyTemplate?.rules);
}

export function getHttpConditionFromRule(rule: RuleV2 | RuleConfig): HttpRule | HttpRuleCondition | undefined {
  const ruleAsRuleV2 = rule as RuleV2;
  let condition: HttpRule | undefined = undefined;
  if (!ruleAsRuleV2.metadata) {
    // Is a RuleConfig
    const ruleAsRuleConfig = rule as RuleConfig;
    const httpRuleCondition: HttpRuleCondition = ruleAsRuleConfig?.extended_condition?.condition as HttpRuleCondition;
    condition = httpRuleCondition;
  } else {
    // Is a RuleV2
    condition = ruleAsRuleV2?.spec?.condition;
  }
  return condition;
}
