import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import { ApplicationsService, ApplicationStateSelector, Organisation, OrganisationsService, RuntimeStatus } from '@agilicus/angular';
import { Application, LabelledObject, PolicyTemplateInstance, Resource } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, forkJoin, combineLatest, of } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';
import { FilterManager } from '../filter/filter-manager';
import { getApplicationStatus, getStatusIconColor, getStatusIcon, capitalizeFirstLetter, updateTableElements } from '../utils';
import {
  Column,
  createIconColumn,
  createInputColumn,
  createInputLinkColumn,
  createSelectRowColumn,
  createActionsColumn,
  ActionMenuOptions,
  setColumnDefs,
  IconColumn,
  ChiplistColumn,
} from '../table-layout/column-definitions';
import { Router } from '@angular/router';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { OptionalApplicationElement } from '../optional-types';
import { ButtonType } from '../button-type.enum';
import { getDefaultTableProperties } from '../table-layout-utils';
import { UpdateAction } from '../user-admin/user-admin.component';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import { selectApiApplicationsList, selectApiApplicationsRefreshData } from '@app/core/api-applications/api-applications.selectors';
import { cloneDeep } from 'lodash-es';
import { ApplicationStateService } from '@app/core/state-services/application-state.service';
import { getApplicationUrl } from '@app/core/models/application/application-model-api-utils';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { AuditRoute } from '../audit-subsystem/audit-subsystem.component';
import { getOrgFeatureFlagMap$, OrgFeatures } from '@app/core/api/organisations/organisations-api.utils';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  PolicyTemplateInstanceWithLabels,
  ResourceTableElement,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { PolicyResourceLinkService } from '@app/core/policy-resource-link-service/policy-resource-link.service';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { ObjectWithId } from '../object-with-id';
import * as ApplicationActions from '../../../core/api-applications/api-applications.actions';

export interface ApplicationElement extends ResourceTableElement, Application {
  overallStatus: RuntimeStatus.OverallStatusEnum;
  backingApp: Application;
}

@Component({
  selector: 'portal-application-overview',
  templateUrl: './application-main.component.html',
  styleUrls: ['./application-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public currentOrg: Organisation;
  private applicationsListCopy: Array<Application> = [];
  public tableData: Array<ApplicationElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public columnDefs: Map<string, Column<ApplicationElement>> = new Map();
  public hasPermissions: boolean;
  public rowObjectName = 'APPLICATION';
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE, ButtonType.DISABLE, ButtonType.ENABLE];
  public customButtons: Array<TableButton> = [this.getDefineNewApplicationButton()];
  public warnOnNOperations = 1;
  public pageDescriptiveText = `See a list of current web applications, their status, and navigate to their definition`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-overview`;
  private orgFeatureKeyToEnabledMap: Map<string, boolean> = new Map();
  private localApplicationRefreshDataValue = 0;
  // For policy column:
  private policyTemplateInstanceList: Array<PolicyTemplateInstanceWithLabels> = [];
  // For policy column:
  private resources: Array<Resource> = [];
  // For policy column:
  private labelledObjects: Array<LabelledObject> = [];
  // For policy column:
  private policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstance> = new Map();
  // For policy column:
  private localPoliciesRefreshDataValue = 0;

  private getDefineNewApplicationButton(): TableScopedButton {
    const button = new TableScopedButton(
      'DEFINE NEW APPLICATION',
      ButtonColor.PRIMARY,
      'Create a new application using the Define Application screen',
      'Button that allows creating a new application using the Define Application screen',
      () => {
        this.defineNewApplication();
      }
    );
    button.isHidden = () => {
      return !this.isHostedApplicationsEnabled();
    };
    return button;
  }

  constructor(
    private applicationsService: ApplicationsService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private notificationService: NotificationService,
    private routerHelperService: RouterHelperService,
    private applicationStateService: ApplicationStateService,
    private organisationsService: OrganisationsService,
    private policyResourceLinkService: PolicyResourceLinkService<ApplicationElement>,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  private getPermissionsAndOrgFeatureFlagMap$(): Observable<[OrgQualifiedPermission, Map<string, boolean>]> {
    const permissions$ = this.store.pipe(select(selectCanAdminApps));
    return permissions$.pipe(
      concatMap((permissionsResp) => {
        let features$: Observable<Map<string, boolean> | undefined> = of(undefined);
        if (!!permissionsResp?.orgId) {
          features$ = getOrgFeatureFlagMap$(this.organisationsService, permissionsResp.orgId);
        }
        return combineLatest([of(permissionsResp), features$]);
      })
    );
  }

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.store.dispatch(new ActionApiApplicationsInitApplications(true));
    // For policy column:
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    const applicationsListState$ = this.store.pipe(select(selectApiApplicationsList));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    const refreshApplicationDataState$ = this.store.pipe(select(selectApiApplicationsRefreshData));
    combineLatest([
      this.getPermissionsAndOrgFeatureFlagMap$(),
      this.policyResourceLinkService.getPolicyAndResourceAndLabelDataWithPermissions$(),
      applicationsListState$,
      currentOrg$,
      refreshApplicationDataState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          permissionsAndOrgFeatureFlagMapResp,
          getPermissionsAndResourceAndLabelDataResp,
          applicationsListStateResp,
          currentOrgResp,
          refreshApplicationDataStateResp,
        ]) => {
          const permissionsResp = permissionsAndOrgFeatureFlagMapResp[0];
          const orgFeatureFlagMapResp = permissionsAndOrgFeatureFlagMapResp[1];
          this.orgFeatureKeyToEnabledMap = !!orgFeatureFlagMapResp ? orgFeatureFlagMapResp : new Map();
          this.orgId = permissionsResp?.orgId;
          this.hasPermissions = permissionsResp?.hasPermission;
          this.applicationsListCopy = !!applicationsListStateResp ? cloneDeep(applicationsListStateResp) : [];
          this.currentOrg = currentOrgResp;
          // For policy column:
          this.policyTemplateInstanceList = getPermissionsAndResourceAndLabelDataResp.policyTemplateInstanceList;
          // For policy column:
          this.resources = getPermissionsAndResourceAndLabelDataResp.resourcesList;
          // For policy column:
          this.labelledObjects = getPermissionsAndResourceAndLabelDataResp.labelledObjects;
          if (!this.hasPermissions || !applicationsListStateResp || applicationsListStateResp.length === 0) {
            this.resetEmptyTable();
            return;
          }
          // For policy column:
          this.policyResourceLinkService.setPolicyNameAndTypeStringMap(
            this.policyNameAndTypeStringToPolicyMap,
            this.policyTemplateInstanceList
          );
          // For policy column:
          this.policyResourceLinkService.setResourceTablePoliciesColumnAllowedValuesList(this.columnDefs, this.policyTemplateInstanceList);
          if (
            this.tableData.length === 0 ||
            this.localPoliciesRefreshDataValue !== getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue ||
            this.localApplicationRefreshDataValue !== refreshApplicationDataStateResp
          ) {
            this.localPoliciesRefreshDataValue = getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue;
            this.localApplicationRefreshDataValue = refreshApplicationDataStateResp;
            if (this.localPoliciesRefreshDataValue !== 0 && this.localApplicationRefreshDataValue !== 0) {
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.updateTable();
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Parent Table Column
   */
  private getOwnedColumn(): Column<ApplicationElement> {
    const ownedColumn = createIconColumn('owned');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    ownedColumn.getDisplayValue = (element: any) => {
      if (element.owned) {
        return 'person';
      }
      return '';
    };
    ownedColumn.getTooltip = (element: any) => {
      if (element.owned) {
        return 'Application is owned';
      }
      return '';
    };
    return ownedColumn;
  }

  /**
   * Parent Table Column
   */
  private getAssignedColumn(): Column<ApplicationElement> {
    const assignedColumn = createIconColumn('assigned');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    assignedColumn.getDisplayValue = (element: any) => {
      if (element.assigned) {
        return 'people';
      }
      return '';
    };
    assignedColumn.getTooltip = (element: any) => {
      if (element.assigned) {
        return 'Application is assigned';
      }
      return '';
    };
    return assignedColumn;
  }

  /**
   * Parent Table Column
   */
  private getLaunchColumn(): IconColumn<ApplicationElement> {
    const launchColumn = createIconColumn('launch');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    launchColumn.getDisplayValue = () => {
      return 'launch';
    };
    launchColumn.getTooltip = (element: ApplicationElement) => {
      return getApplicationUrl(element.name, this.currentOrg?.subdomain);
    };
    launchColumn.getLink = (element: ApplicationElement) => {
      return getApplicationUrl(element.name, this.currentOrg?.subdomain);
    };
    return launchColumn;
  }

  /**
   * Parent Table Column
   */
  private getNameColumn(): Column<ApplicationElement> {
    const nameColumn = createInputLinkColumn('name');
    nameColumn.isUnique = true;
    nameColumn.isReadOnly = () => true;
    nameColumn.onClick = (element: OptionalApplicationElement) => {
      this.router.navigate(['/application-define', element.id], {
        queryParams: { org_id: this.orgId },
      });
    };
    return nameColumn;
  }

  /**
   * Parent Table Column
   */
  private getDescriptionColumn(): Column<ApplicationElement> {
    const descriptionColumn = createInputColumn('description');
    descriptionColumn.isReadOnly = () => true;
    return descriptionColumn;
  }

  /**
   * Parent Table Column
   */
  private getImageColumn(): Column<ApplicationElement> {
    const imageColumn = createInputColumn('image');
    imageColumn.isReadOnly = () => true;
    return imageColumn;
  }

  /**
   * Parent Table Column
   */
  private getEnvironmentsColumn(): Column<ApplicationElement> {
    const environmentsColumn = createInputColumn('environments');
    environmentsColumn.displayName = 'Image Tag';
    environmentsColumn.isReadOnly = () => true;
    environmentsColumn.getDisplayValue = this.getVersionTag.bind(this);
    return environmentsColumn;
  }

  /**
   * Parent Table Column
   */
  private getAdminStateColumn(): Column<ApplicationElement> {
    const adminStateColumn = createInputColumn('admin_state');
    adminStateColumn.displayName = 'Admin State';
    adminStateColumn.isReadOnly = () => true;
    return adminStateColumn;
  }

  /**
   * Parent Table Column
   */
  private getOverallStatusColumn(): Column<ApplicationElement> {
    const overallStatusColumn = createInputColumn('overallStatus');
    overallStatusColumn.displayName = 'Status';
    overallStatusColumn.hasIconPrefix = true;
    overallStatusColumn.getDisplayValue = (element: OptionalApplicationElement): any => {
      if (!element.overallStatus) {
        return '';
      }
      return capitalizeFirstLetter(element.overallStatus);
    };
    overallStatusColumn.getIconPrefix = this.getStatusIconFromElement.bind(this);
    overallStatusColumn.getIconColor = this.getStatusIconColorFromElement.bind(this);
    overallStatusColumn.isReadOnly = () => true;
    return overallStatusColumn;
  }

  /**
   * Parent Table Column
   */
  private getPoliciesColumn(): ChiplistColumn<ApplicationElement> {
    return this.policyResourceLinkService.getResourceTablePoliciesColumn(this.policyNameAndTypeStringToPolicyMap);
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<ApplicationElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<ApplicationElement>> = [
      {
        displayName: 'Configure Application',
        icon: 'open_in_browser',
        tooltip: 'Click to view/modify this application',
        onClick: (element: OptionalApplicationElement) => {
          this.router.navigate(['/application-define', element.id], {
            queryParams: { org_id: this.orgId },
          });
        },
      },
      {
        displayName: 'Search in Audits',
        icon: 'search',
        tooltip: 'Click to filter audits',
        onClick: (element: OptionalApplicationElement) => {
          const auditRouteData: AuditRoute = {
            org_id: element.org_id,
            target_resource_id: element.id,
            target_resource_name: element.name,
          };
          this.routerHelperService.redirect('audit-subsystem/', auditRouteData);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    if (this.shouldGateAccess()) {
      // Do not include policies column:
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getOwnedColumn(),
          this.getAssignedColumn(),
          this.getLaunchColumn(),
          this.getNameColumn(),
          this.getDescriptionColumn(),
          this.getImageColumn(),
          this.getEnvironmentsColumn(),
          this.getAdminStateColumn(),
          this.getOverallStatusColumn(),
          this.getActionsColumn(),
        ],
        this.columnDefs
      );
    } else {
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getOwnedColumn(),
          this.getAssignedColumn(),
          this.getLaunchColumn(),
          this.getNameColumn(),
          this.getDescriptionColumn(),
          this.getImageColumn(),
          this.getEnvironmentsColumn(),
          this.getAdminStateColumn(),
          this.getOverallStatusColumn(),
          this.getPoliciesColumn(),
          this.getActionsColumn(),
        ],
        this.columnDefs
      );
    }
  }

  private getStatusIconFromElement(element: ApplicationElement): string {
    return getStatusIcon(element.overallStatus);
  }

  private getStatusIconColorFromElement(element: ApplicationElement): string {
    return getStatusIconColor(element.overallStatus);
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const dataForTable: Array<ApplicationElement> = [];
    for (let i = 0; i < this.applicationsListCopy.length; i++) {
      const item = this.applicationsListCopy[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    const copyOfTableData = cloneDeep(this.tableData);
    updateTableElements(copyOfTableData, dataForTable);
    this.tableData = copyOfTableData;
  }

  private createTableElement(item: Application, index: number): ApplicationElement {
    const appWithMetadata: ObjectWithId = {
      ...item,
      metadata: {
        id: item.id,
      },
    };
    const data: ApplicationElement = {
      category: item.category,
      name: item.name,
      overallStatus: getApplicationStatus(item),
      org_id: item.org_id,
      backingApp: item,
      ...getDefaultTableProperties(index),
      ...this.policyResourceLinkService.getResourceElementWithPolicies(
        appWithMetadata,
        this.resources,
        this.labelledObjects,
        this.policyTemplateInstanceList
      ),
    };
    for (const key of Object.keys(item)) {
      data[key] = item[key];
    }
    return data;
  }

  private getVersionTag(element: ApplicationElement): string {
    let envName: string;
    const sortedAssignments = element.assignments.sort((a, b) =>
      a.environment_name.localeCompare(b.environment_name, undefined, { sensitivity: 'base' })
    );
    for (const assignment of sortedAssignments) {
      if (assignment.org_id === this.orgId) {
        envName = assignment.environment_name;
        for (const env of element.environments) {
          if (env.name === envName) {
            return env.version_tag;
          }
        }
      }
    }
    if (envName === undefined) {
      return '';
    }
    return '';
  }

  public deleteSelected(appsToDelete: Array<ApplicationElement>): void {
    const appIdsToDelete = appsToDelete.map((app) => app.id);
    this.store.dispatch(ApplicationActions.deleteApplications({ ids: appIdsToDelete, refreshData: true }));
  }

  private populateAdminStateObservablesArray(
    apps: Array<ApplicationElement>,
    adminState: ApplicationStateSelector
  ): Array<Observable<Application>> {
    const observablesArray: Array<Observable<Application>> = [];
    for (const app of apps) {
      app.backingApp.admin_state = adminState;
      observablesArray.push(this.applicationsService.replaceApplication({ app_id: app.id, Application: app.backingApp }));
    }
    return observablesArray;
  }

  public disableSelected(appsToDisable: Array<ApplicationElement>): void {
    this.updateApps(this.populateAdminStateObservablesArray(appsToDisable, ApplicationStateSelector.disabled), UpdateAction.DISABLE);
  }

  public enableSelected(appsToEnable: Array<ApplicationElement>): void {
    this.updateApps(this.populateAdminStateObservablesArray(appsToEnable, ApplicationStateSelector.active), UpdateAction.ENABLE);
  }

  private updateApps(observablesArray: Array<Observable<Application>>, updateAction: UpdateAction): void {
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Applications were successfully ' + updateAction + 'd');
        },
        (errorResp) => {
          this.notificationService.error('Failed to ' + updateAction + ' all selected applications');
        },
        () => {
          this.updateTable();
        }
      );
  }

  /**
   * Receives a ApplicationElement from the table then updates and saves
   * the resource.
   */
  public updateEvent(updatedElement: ApplicationElement): void {
    this.saveItem(updatedElement);
  }

  private saveItem(updatedTableElement: ApplicationElement): void {
    this.policyResourceLinkService
      .submitPoliciesResult$(updatedTableElement, this.orgId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelsAndPolicyResponse) => {
        this.policyResourceLinkService.onSubmitPoliciesFinish(updatedTableElement, resourceAndLabelsAndPolicyResponse);
        // We do not need to update the Application object here since this table only allows editing policy data
        this.changeDetector.detectChanges();
      });
  }

  public defineNewApplication(): void {
    this.routerHelperService.routeToAppView('new', {
      org_id: this.orgId,
    });
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public isHostedApplicationsEnabled(): boolean {
    const result = this.orgFeatureKeyToEnabledMap.get(OrgFeatures.hosted_applications);
    if (result === undefined) {
      return true;
    }
    return result;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }
}
