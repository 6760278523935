import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { RssService } from '../../../core/services/rss.service';
import { RssItem } from '../../../core/models/rss.model';

@Component({
  selector: 'portal-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsComponent {
  public rssItems: Observable<RssItem[]> = this.rssService.getItems();

  constructor(private rssService: RssService) {}

  public goToLink(url: string): void {
    window.open(url, '_blank');
  }
}
