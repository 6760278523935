import { Issuer } from '@agilicus/angular';
import { createSelector } from '@ngrx/store';
import { selectIssuerState } from '../core.state';
import { IssuerState, selectAll, selectEntities } from './issuer.reducer';

export const selectIssuer = createSelector(selectIssuerState, (state: IssuerState) => state);

export const selectIssuersList = createSelector(selectIssuer, selectAll);

/**
 * Only one issuer will exist in the list, so we select the first list entry.
 */
export const selectCurrentIssuer = createSelector(selectIssuersList, (issuersList: Array<Issuer>) => issuersList[0]);

export const selectIssuerEntities = createSelector(selectIssuer, selectEntities);

export const selectIssuerEntity = (props: { id: string }) =>
  createSelector(selectIssuerEntities, (entities) => {
    return entities[props.id];
  });

export const selectSavingIssuer = createSelector(selectIssuerState, (state: IssuerState) => {
  if (!state) {
    return undefined;
  }
  return state.saving_state;
});

export const selectIssuerRefreshDataValue = createSelector(selectIssuer, (state: IssuerState) => state.refresh_data);

export const selectIssuerShouldPopulateValue = createSelector(selectIssuerState, (state: IssuerState) => state.should_populate);
