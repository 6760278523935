import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[portalKeyInputDelay]',
})
export class KeyInputDelayDirective {
  private time;
  @Output() private handleOnKeyUp = new EventEmitter();

  constructor() {}

  @HostListener('keyup', ['$event'])
  private resetTimer(event: KeyboardEvent): void {
    clearTimeout(this.time);
    this.time = setTimeout(() => {
      this.handleOnKeyUpEvent();
    }, 500);
  }

  private handleOnKeyUpEvent(): void {
    this.handleOnKeyUp.emit();
  }
}
