import { NetworkServiceConfig, ObjectCredentialSecrets } from '@agilicus/angular';
import { getDefaultNetworkServiceConfig } from '../network-service-config/network-service-config-model-utils';
import { ResourceModel } from '../resource/resource-model';

export interface SSHModel extends ResourceModel {
  name: string;
  address: string;
  connector_name: string;
  config: NetworkServiceConfig | undefined;
  username?: string | null;
  secrets?: ObjectCredentialSecrets;
}

export function defaultSSHModel(): SSHModel {
  return {
    id: undefined,
    name: '',
    address: '',
    connector_name: '',
    config: getDefaultNetworkServiceConfig(),
    username: '',
    secrets: {
      private_key: '',
      private_key_passphrase: '',
      password: '',
      username: '',
    },
  };
}
