<div class="profile">
  <H1>User Profile</H1>

  Your profile is owned by the upstream identity provider, and as such is read-only. Our system augments it with an ID (in
  base57) which is used as the unique-key throughout.

  <hr>
  <form *ngIf="user$ | async as user" class="user-form">
    <mat-form-field class="user-form-full-width">
      <input matInput [readonly]="true" placeholder="First Name" value="{{ user.first_name }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <input matInput [readonly]="true" placeholder="Last Name" value="{{ user.last_name }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <input matInput [readonly]="true" placeholder="ID Provider" value="{{ user.provider }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <input matInput [readonly]="true" placeholder="Email" value="{{ user.email }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <input matInput [readonly]="true" placeholder="id" value="{{ user.id }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <input matInput [readonly]="true" placeholder="Date Created" value="{{ user.created }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <input matInput [readonly]="true" placeholder="Date Updated" value="{{ user.updated }}">
    </mat-form-field>

  </form>
</div>