import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subject, Observable, combineLatest, lastValueFrom } from 'rxjs';
import {
  AutoInputColumn,
  Column,
  createSelectRowColumn,
  createInputColumn,
  createCheckBoxColumn,
  createAutoInputColumn,
  createSelectColumn,
  InputColumn,
  CheckBoxColumn,
  SelectColumn,
  createActionsColumn,
  ActionMenuOptions,
  setColumnDefs,
} from '../table-layout/column-definitions';
import {
  Issuer,
  OIDCUpstreamIdentityProvider,
  AutoCreateStatus,
  LocalAuthUpstreamIdentityProvider,
  UpstreamGroupMapping,
  IssuersService,
  Organisation,
} from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { FilterManager } from '../filter/filter-manager';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import {
  updateTableElements,
  getEmptyStringIfUnset,
  capitalizeFirstLetter,
  setDropdownEnableStateForTableAutoInputs,
  addNewEntryFocus,
  createEnumChecker,
  replaceCharacterWithSpace,
} from '../utils';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCurrentOrganisation, selectCurrentOrgIssuer } from '@app/core/organisations/organisations.selectors';
import { OptionalOIDCUpstreamIdentityProviderElement } from '../optional-types';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  UpstreamProviderDialogData,
  UpstreamProviderSetupDialogComponent,
} from '../upstream-provider-setup-dialog/upstream-provider-setup-dialog.component';
import { ButtonType } from '../button-type.enum';
import { getDefaultDialogConfig } from '../dialog-utils';
import {
  UpstreamGroupMappingsDialogComponent,
  GroupMappingsDialogData,
} from '../upstream-group-mappings-dialog/upstream-group-mappings-dialog.component';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { getFilteredValues } from '../custom-chiplist-input/custom-chiplist-input.utils';
import {
  getGroupMappingFromUpstreamIdentityProvider,
  getUpstreamProviderUpdateSuccessMessage,
  getUpstreamProviderUpdateFailMessage,
  getDefaultUniqueUpstreamProviderProperties,
  getDefaultSharedUpstreamProviderProperties,
  getAutoCreateStatusColumn,
  createEditableInputColumn,
  refreshIssuer,
  OIDCUpstreamIdentityProviderElement,
} from '../authentication-utils';
import { ComponentName } from '../component-type.enum';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { isValidIconValue } from '../validation-utils';
import { initIssuer, savingIssuer } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer, selectIssuerRefreshDataValue, selectSavingIssuer } from '@app/core/issuer-state/issuer.selectors';
import { isValidUpstream$ } from '@app/core/issuer-state/issuer.utils';

@Component({
  selector: 'portal-custom-identity',
  templateUrl: './custom-identity.component.html',
  styleUrls: ['./custom-identity.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomIdentityComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  private currentOrgIssuer$: Observable<string>;
  public currentOrgIssuer: string;
  public issuerCopy: Issuer;
  private issuersPermissions$: Observable<OrgQualifiedPermission>;
  public hasIssuersPermissions: boolean;
  public columnDefs: Map<string, Column<OIDCUpstreamIdentityProviderElement>> = new Map();
  public tableData: Array<OIDCUpstreamIdentityProviderElement> = [];
  public rowObjectName = 'PROVIDER';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public filterManager: FilterManager = new FilterManager();
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      'ADD PROVIDER',
      ButtonColor.PRIMARY,
      'Add a new upstream provider',
      'Button that adds a new upstream provider',
      () => {
        this.openAddDialog();
      }
    ),
  ];
  public currentOrg: Organisation;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/identity-and-authentication/`;
  public pageDescriptiveHelpImageWithTextWrap = 'assets/img/sign-in-screen.png';
  public pageDescriptiveTextWithImageWrap = '';
  private localRefreshDataValue = 0;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private issuersService: IssuersService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    const currentIssuerState$ = this.store.pipe(select(selectCurrentIssuer));
    const savingIssuerState$ = this.store.pipe(select(selectSavingIssuer));
    const refreshDataState$ = this.store.pipe(select(selectIssuerRefreshDataValue));
    this.currentOrgIssuer$ = this.store.pipe(select(selectCurrentOrgIssuer));
    this.issuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    combineLatest([
      currentIssuerState$,
      savingIssuerState$,
      refreshDataState$,
      this.currentOrgIssuer$,
      currentOrg$,
      this.issuersPermissions$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([currentIssuerStateResp, savingIssuerStateResp, refreshDataStateResp, currentOrgIssuerResp, currentOrgResp, issuerPermissions]: [
          Issuer,
          boolean,
          number,
          string,
          Organisation,
          OrgQualifiedPermission
        ]) => {
          this.orgId = issuerPermissions.orgId;
          this.hasIssuersPermissions = issuerPermissions.hasPermission;
          if (!this.hasIssuersPermissions || savingIssuerStateResp) {
            // Need this in order for the "No Permissions" text to be displayed when the page first loads.
            this.changeDetector.detectChanges();
            return;
          }
          this.currentOrg = currentOrgResp;
          this.currentOrgIssuer = currentOrgIssuerResp;
          this.pageDescriptiveTextWithImageWrap = this.getPageDescriptiveTextWithImageWrap();
          if (currentIssuerStateResp === undefined) {
            this.issuerCopy = undefined;
            this.changeDetector.detectChanges();
            return;
          }
          if (!this.issuerCopy || this.localRefreshDataValue !== refreshDataStateResp) {
            this.localRefreshDataValue = refreshDataStateResp;
            this.issuerCopy = cloneDeep(currentIssuerStateResp);
            if (this.issuerCopy.oidc_upstreams === undefined) {
              this.resetEmptyTable();
            } else {
              this.updateTable();
            }
          }
          const iconColumn = this.columnDefs.get('iconFormControl');
          setDropdownEnableStateForTableAutoInputs('iconFormControl', iconColumn, this.tableData);
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPageDescriptiveTextWithImageWrap(): string {
    return `An identity provider acts to attest to a users identity, using protocals such as OpenID Connect. 
    Your documentation may refer to this as Single-Sign-On, OpenID Connect, SAML.\n\nHere you may configure Google, Microsoft Azure, Microsoft ADFS, etc. identity methods for your users to sign-in. 
    Each will appear on your authentication issuer page at ${this.currentOrgIssuer}.`;
  }

  private getNameColumn(): InputColumn<OIDCUpstreamIdentityProviderElement> {
    const nameColumn = createEditableInputColumn('name');
    nameColumn.isCaseSensitive = true;
    nameColumn.isUnique = true;
    nameColumn.isValidEntry = (str: string): boolean => {
      return str.length > 0 && str.length < 101;
    };
    return nameColumn;
  }

  private getIssuerColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const column = createEditableInputColumn('issuer');
    column.isCaseSensitive = true;
    column.isValidEntry = (
      str: string,
      element: OIDCUpstreamIdentityProviderElement,
      column: Column<OIDCUpstreamIdentityProviderElement>
    ): Promise<boolean> => {
      return lastValueFrom(isValidUpstream$(this.issuersService, str, this.orgId, column));
    };
    return column;
  }

  private getOidcIconColumn(): AutoInputColumn<OIDCUpstreamIdentityProviderElement> {
    const iconColumn = createAutoInputColumn('iconFormControl');
    iconColumn.displayName = 'Icon';
    iconColumn.requiredField = () => true;
    iconColumn.isEditable = true;
    iconColumn.isCaseSensitive = true;
    iconColumn.getHeaderTooltip = (): string => {
      return 'File to use for the icon';
    };

    iconColumn.isValidEntry = (str: string): boolean => {
      return isValidIconValue(str);
    };

    // really recommended values
    iconColumn.allowedValues = ['google', 'microsoft'];

    iconColumn.getFilteredValues = (
      element: OptionalOIDCUpstreamIdentityProviderElement,
      column: AutoInputColumn<OIDCUpstreamIdentityProviderElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.iconFormControl, column);
    };
    return iconColumn;
  }

  private getClientIdColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const clientIdColumn = createEditableInputColumn('client_id');
    clientIdColumn.displayName = 'Client Id';
    clientIdColumn.isCaseSensitive = true;
    clientIdColumn.isValidEntry = (str: string): boolean => {
      return str.length < 101;
    };
    return clientIdColumn;
  }

  private getClientSecretColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const clientSecretColumn = createInputColumn('client_secret');
    clientSecretColumn.displayName = 'Secret';
    clientSecretColumn.isEditable = true;
    clientSecretColumn.isCaseSensitive = true;
    clientSecretColumn.copyToClipboard = true;
    clientSecretColumn.isValidEntry = (str: string): boolean => {
      return str.length < 256;
    };
    return clientSecretColumn;
  }

  private getIssuerExternalHostColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const issuerExternalHostColumn = createInputColumn('issuer_external_host');
    issuerExternalHostColumn.displayName = 'Issuer External Host';
    issuerExternalHostColumn.isEditable = true;
    issuerExternalHostColumn.isCaseSensitive = true;
    issuerExternalHostColumn.isValidEntry = (str: string): boolean => {
      return str.length < 256;
    };
    return issuerExternalHostColumn;
  }

  private getUserameKeyColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const usernameKeyColumn = createInputColumn('username_key');
    usernameKeyColumn.displayName = 'Username Key';
    usernameKeyColumn.isEditable = true;
    usernameKeyColumn.isCaseSensitive = true;
    usernameKeyColumn.isValidEntry = (str: string): boolean => {
      return str.length < 256;
    };
    return usernameKeyColumn;
  }

  private getUserIDKeyColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const userIDKeyColumn = createInputColumn('user_id_key');
    userIDKeyColumn.displayName = 'User Id Key';
    userIDKeyColumn.isEditable = true;
    userIDKeyColumn.isCaseSensitive = true;
    userIDKeyColumn.getHeaderTooltip = (): string => {
      return 'ID Token field used to determine the identity of users';
    };
    userIDKeyColumn.isValidEntry = (str: string): boolean => {
      return str.length < 256;
    };
    return userIDKeyColumn;
  }

  private getEmailKeyColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const emailKeyColumn = createInputColumn('email_key');
    emailKeyColumn.displayName = 'Email Key';
    emailKeyColumn.isEditable = true;
    emailKeyColumn.isCaseSensitive = true;
    emailKeyColumn.isValidEntry = (str: string): boolean => {
      return str.length < 256;
    };
    return emailKeyColumn;
  }

  private getEmailVerificationRequiredColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const emailVerificationRequiredColumn = createCheckBoxColumn('email_verification_required');
    emailVerificationRequiredColumn.displayName = 'Verifies Email';
    emailVerificationRequiredColumn.isEditable = true;
    return emailVerificationRequiredColumn;
  }

  /**
   * upstream_redirect_uri is readonly.
   */
  private getUpstreamRedirectUriColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const upstreamRedirectUriColumn = createInputColumn('upstream_redirect_uri');
    upstreamRedirectUriColumn.displayName = 'Redirect URI';
    return upstreamRedirectUriColumn;
  }

  private getPromptModeColumn(): CheckBoxColumn<OptionalOIDCUpstreamIdentityProviderElement> {
    const promptModeColumn = createCheckBoxColumn('prompt_mode');
    promptModeColumn.displayName = 'Offline Consent';
    promptModeColumn.isEditable = true;
    promptModeColumn.isChecked = (element: OptionalOIDCUpstreamIdentityProviderElement) => {
      if (element.prompt_mode === OIDCUpstreamIdentityProvider.PromptModeEnum.auto) {
        return true;
      }
      return false;
    };
    promptModeColumn.setElementFromCheckbox = (element: OptionalOIDCUpstreamIdentityProviderElement, isBoxChecked: boolean): any => {
      if (isBoxChecked) {
        element.prompt_mode = OIDCUpstreamIdentityProvider.PromptModeEnum.auto;
      } else {
        element.prompt_mode = OIDCUpstreamIdentityProvider.PromptModeEnum.disabled;
      }
    };
    promptModeColumn.getHeaderTooltip = () => {
      return 'Controls whether to ask for consent from this identity provider when requesting offline access. This should typically be set. However, some providers may not interact well with the consent prompt, in which case it can be disabled.';
    };
    return promptModeColumn;
  }

  private getRequestUserInfoColumn(): CheckBoxColumn<OptionalOIDCUpstreamIdentityProviderElement> {
    const requestUserInfoColumn = createCheckBoxColumn('request_user_info');
    requestUserInfoColumn.isEditable = true;
    requestUserInfoColumn.getHeaderTooltip = () => {
      return `Controls whether the system will retrieve extra information about the user from the provider's "user_info" endpoint. 
      This can be useful if the initial OIDC response does not contain sufficient information to determine the email address or user's name. Setting this value to true will cause extra requests to be generated to the upstream every time a user logs in to it.`;
    };
    return requestUserInfoColumn;
  }

  private getOidcFlavorColumn(): SelectColumn<OIDCUpstreamIdentityProviderElement> {
    const oidcFlavorColumn = createSelectColumn('oidc_flavor');
    oidcFlavorColumn.displayName = 'Type';
    oidcFlavorColumn.isEditable = true;
    oidcFlavorColumn.allowedValues = Object.values(OIDCUpstreamIdentityProvider.OidcFlavorEnum);
    oidcFlavorColumn.getOptionDisplayValue = (option: any) => {
      const isOidcFlavorEnum = createEnumChecker(OIDCUpstreamIdentityProvider.OidcFlavorEnum);
      if (isOidcFlavorEnum(option)) {
        return this.formatOidcFlavorForDisplay(option);
      }
      return '';
    };
    oidcFlavorColumn.getHeaderTooltip = () => {
      return `Some Identity Providers have extended functionality. 
      Choose Generic for standard OpenID Connect providers. 
      Choose Microsoft for Azure-based Identity providers.`;
    };
    return oidcFlavorColumn;
  }

  private formatOidcFlavorForDisplay(oidcFlavor: OIDCUpstreamIdentityProvider.OidcFlavorEnum): string {
    if (oidcFlavor === OIDCUpstreamIdentityProvider.OidcFlavorEnum.oidc) {
      return 'Generic';
    }
    if (oidcFlavor === OIDCUpstreamIdentityProvider.OidcFlavorEnum.microsoft) {
      return 'Microsoft';
    }
    return capitalizeFirstLetter(replaceCharacterWithSpace(oidcFlavor, '_'));
  }

  private initializeColumnDefs(): void {
    this.initializeOidcProviderColumnDefs();
  }

  private initializeOidcProviderColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getNameColumn(),
        this.getIssuerColumn(),
        this.getOidcIconColumn(),
        this.getClientIdColumn(),
        this.getClientSecretColumn(),
        getAutoCreateStatusColumn(),
        this.getOidcFlavorColumn(),
        this.getPromptModeColumn(),
        this.getRequestUserInfoColumn(),
        this.getIssuerExternalHostColumn(),
        this.getUserameKeyColumn(),
        this.getUserIDKeyColumn(),
        this.getEmailKeyColumn(),
        this.getEmailVerificationRequiredColumn(),
        this.getUpstreamRedirectUriColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<OIDCUpstreamIdentityProviderElement> = [];
    for (let i = 0; i < this.issuerCopy.oidc_upstreams.length; i++) {
      data.push(this.createIssuerOidcUpstreamsElement(this.issuerCopy.oidc_upstreams[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createIssuerOidcUpstreamsElement(
    issuerOidcUpstream: OIDCUpstreamIdentityProvider,
    index: number
  ): OIDCUpstreamIdentityProviderElement {
    const data: OIDCUpstreamIdentityProviderElement = {
      name: '',
      issuer: '',
      icon: '',
      client_id: '',
      client_secret: '',
      auto_create_status: AutoCreateStatus.default,
      oidc_flavor: null,
      prompt_mode: OIDCUpstreamIdentityProvider.PromptModeEnum.disabled,
      issuer_external_host: '',
      username_key: '',
      email_key: '',
      user_id_key: '',
      email_verification_required: false,
      request_user_info: false,
      upstream_redirect_uri: this.issuerCopy.upstream_redirect_uri,
      iconFormControl: new UntypedFormControl(),
      backingObject: issuerOidcUpstream,
      ...getDefaultTableProperties(index),
    };
    data.iconFormControl.setValue(issuerOidcUpstream.icon);
    for (const key of Object.keys(issuerOidcUpstream)) {
      data[key] = getEmptyStringIfUnset(issuerOidcUpstream[key]);
    }
    return data;
  }

  public makeEmptyTableElement(): OIDCUpstreamIdentityProviderElement {
    return {
      name: '',
      issuer: '',
      icon: '',
      client_id: '',
      client_secret: '',
      auto_create_status: AutoCreateStatus.default,
      oidc_flavor: OIDCUpstreamIdentityProvider.OidcFlavorEnum.oidc,
      prompt_mode: OIDCUpstreamIdentityProvider.PromptModeEnum.disabled,
      issuer_external_host: '',
      username_key: '',
      email_key: '',
      user_id_key: '',
      email_verification_required: false,
      request_user_info: false,
      upstream_redirect_uri: this.issuerCopy.upstream_redirect_uri,
      iconFormControl: new UntypedFormControl(),
      backingObject: undefined,
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private getIssuerOidcUpstreamsFromTable(): Array<OIDCUpstreamIdentityProvider> {
    return this.tableData.map((entry: OIDCUpstreamIdentityProviderElement) => {
      const issuerOidcUpstream: OIDCUpstreamIdentityProvider = {
        ...entry.backingObject,
        name: entry.name,
        issuer: entry.issuer,
        icon: entry.iconFormControl.value,
        client_id: entry.client_id,
        client_secret: entry.client_secret,
        auto_create_status: entry.auto_create_status,
        oidc_flavor: entry.oidc_flavor,
        issuer_external_host: entry.issuer_external_host,
        username_key: entry.username_key,
        email_key: entry.email_key,
        user_id_key: entry.user_id_key,
        email_verification_required: entry.email_verification_required,
        request_user_info: entry.request_user_info,
        prompt_mode: entry.prompt_mode,
      };
      return issuerOidcUpstream;
    });
  }

  /**
   * Receives an IssuerOidcUpstreamsElement from the table then updates and saves
   * the current issuer.
   */
  public updateEvent(): void {
    const updatedIssuerOidcUpstreamList = this.getIssuerOidcUpstreamsFromTable();
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    copyOfIssuerCopy.oidc_upstreams = updatedIssuerOidcUpstreamList;
    this.saveIssuer(copyOfIssuerCopy);
  }

  private removeIssuerOidcUpstreams(): void {
    this.tableData = this.tableData.filter((issuerOidcUpstream) => !issuerOidcUpstream.isChecked);
  }

  public deleteSelected(): void {
    this.removeIssuerOidcUpstreams();
    const updatedIssuerOidcUpstreamList = this.getIssuerOidcUpstreamsFromTable();
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    copyOfIssuerCopy.oidc_upstreams = updatedIssuerOidcUpstreamList;
    this.saveIssuer(copyOfIssuerCopy);
  }

  private saveIssuer(issuer: Issuer): void {
    this.issuerCopy = issuer;
    this.store.dispatch(savingIssuer({ obj: this.issuerCopy, trigger_update_side_effects: false, notifyUser: true }));
  }

  public updateAutoInput(params: {
    optionValue: string;
    column: AutoInputColumn<OIDCUpstreamIdentityProviderElement>;
    element: OIDCUpstreamIdentityProviderElement;
  }): void {
    if (params.column.name !== 'iconFormControl') {
      return;
    }
    if (params.optionValue === params.element.icon) {
      // The value has not been changed.
      return;
    }
    params.element.icon = params.element.iconFormControl.value;
    params.element.dirty = true;
  }

  public openAddDialog(): void {
    const dialogData: UpstreamProviderDialogData = {
      sharedUpstreamProviderData: getDefaultSharedUpstreamProviderProperties(),
      uniqueUpstreamProviderData: getDefaultUniqueUpstreamProviderProperties(),
      issuer: this.issuerCopy,
      store: this.store,
      connectors: [],
      applications: [],
      currentOrg: this.currentOrg,
      componentName: ComponentName.custom_identity,
    };
    const dialogRef = this.dialog.open(
      UpstreamProviderSetupDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );

    dialogRef.afterClosed().subscribe((addNewElement) => {
      if (!addNewElement) {
        return;
      }
      const dataElement = this.makeEmptyTableElement();
      // create new table row
      this.tableData.unshift(dataElement);
      addNewEntryFocus(this.rowObjectName);
      this.replaceTableWithCopy();
    });
  }

  private getActionsColumn(): Column<OIDCUpstreamIdentityProviderElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<OIDCUpstreamIdentityProviderElement>> = [
      {
        displayName: 'Configure Group Mappings',
        icon: 'open_in_browser',
        tooltip: `Click to view/modify this upstreams group mappings. 
          An upstream group mapping uses your upstream group information to populate groups in the agilicus system.`,
        onClick: (element: OptionalOIDCUpstreamIdentityProviderElement) => {
          const groupMapping = getGroupMappingFromUpstreamIdentityProvider(element as OIDCUpstreamIdentityProvider, this.issuerCopy);
          this.openUpstreamGroupMappingDialog(element.name, groupMapping);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private openUpstreamGroupMappingDialog(upstreamName: string, upstreamGroupMapping: UpstreamGroupMapping): void {
    const dialogData: GroupMappingsDialogData = {
      upstreamName,
      upstreamGroupMapping,
      orgId: this.orgId,
      onUpdate: this.saveGroupMappings.bind(this),
    };
    const dialogRef = this.dialog.open(
      UpstreamGroupMappingsDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
        maxWidth: '950px',
      })
    );
  }

  private createNewUpstreamGroupMapping(upstreamProvider: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    this.issuersService
      .createUpstreamGroupMapping({
        issuer_id: this.issuerCopy.id,
        UpstreamGroupMapping: updatedUpstreamGroupMapping,
      })
      .subscribe(
        (resp) => {
          this.updateIssuerGroupMappingList(upstreamProvider, resp);
          this.notificationService.success(getUpstreamProviderUpdateSuccessMessage(upstreamProvider));
        },
        (err) => {
          this.notificationService.error(getUpstreamProviderUpdateFailMessage(upstreamProvider));
        }
      );
  }

  private updateExistingUpstreamGroupMapping(upstreamProvider: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    this.issuersService
      .replaceUpstreamGroupMapping({
        issuer_id: this.issuerCopy.id,
        upstream_group_mapping_id: updatedUpstreamGroupMapping.metadata.id,
        UpstreamGroupMapping: updatedUpstreamGroupMapping,
      })
      .subscribe(
        (resp) => {
          this.updateIssuerGroupMappingList(upstreamProvider, resp);
          this.notificationService.success(getUpstreamProviderUpdateSuccessMessage(upstreamProvider));
        },
        (err) => {
          this.notificationService.error(getUpstreamProviderUpdateFailMessage(upstreamProvider));
        }
      );
  }

  public saveGroupMappings(upstreamProvider: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    if (!updatedUpstreamGroupMapping.metadata) {
      this.createNewUpstreamGroupMapping(upstreamProvider, updatedUpstreamGroupMapping);
    } else {
      this.updateExistingUpstreamGroupMapping(upstreamProvider, updatedUpstreamGroupMapping);
    }
  }

  private updateIssuerGroupMappingList(upstreamIssuer: string, updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    // We need to make a copy here in order to prevent errors as a result of the component freezing the object
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    for (let i = 0; i < copyOfIssuerCopy.upstream_group_mappings.length; i++) {
      if (copyOfIssuerCopy.upstream_group_mappings[i].spec.upstream_issuer === upstreamIssuer) {
        copyOfIssuerCopy.upstream_group_mappings[i] = updatedUpstreamGroupMapping;
        refreshIssuer(this.store, copyOfIssuerCopy);
        return;
      }
    }
    copyOfIssuerCopy.upstream_group_mappings.push(updatedUpstreamGroupMapping);
    this.issuerCopy = copyOfIssuerCopy;
    refreshIssuer(this.store, this.issuerCopy);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this), [
      'auto_create_status',
      'oidc_flavor',
      'prompt_mode',
      'upstream_redirect_uri',
    ]);
  }
}
