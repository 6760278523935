import { ActionReducerMap, ActionReducer, MetaReducer, createFeatureSelector, Action } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

import { ApiApplicationsState } from './api-applications/api-applications.models';
import { apiApplicationsReducer } from './api-applications/api-applications.reducer';
import { RouterStateUrl } from './router/router.state';

import { localStorageSync } from 'ngrx-store-localstorage';
import { OrganisationsState } from './organisations/organisations.models';
import { organisationsReducer } from './organisations/organisations.reducer';
import { environmentReducer } from './environment/environment.reducer';
import { EnvironmentState } from './environment/environment.model';
import { UserState } from './user/user.models';
import { userReducer } from './user/user.reducer';
import { UserActionTypes } from './user/user.actions';
import { issuerClientsReducer } from './issuer-clients/issuer-clients.reducer';
import { IssuerClientsState } from './issuer-clients/issuer-clients.models';
import { SignupState } from './signup/signup.models';
import { signupReducer } from './signup/signup.reducer';
import { UIState } from './ui/ui.models';
import { uiReducer } from './ui/ui.reducer';
import { crudManagementReducer } from './api/state-driven-crud/state-driven-crud.reducer';
import { CrudManagementState } from './api/state-driven-crud/crud-management-state-definitions';
import { PolicyState } from './issuer/issuer.models';
import { policyReducer } from './issuer/issuer.reducer';
import * as fromIssuer from '@app/core/issuer-state/issuer.reducer';
import * as fromApplicationService from '@app/core/application-service-state/application-service.reducer';
import * as fromConnector from '@app/core/connector-state/connector.reducer';
import * as fromAgentConnector from '@app/core/agent-connector-state/agent-connector.reducer';
import * as fromFileShareService from '@app/core/file-share-state/file-share-service.reducer';
import * as fromDesktop from '@app/core/desktop-state/desktop.reducer';
import * as fromLauncher from '@app/core/launcher-state/launcher.reducer';
import * as fromSSH from '@app/core/ssh-state/ssh.reducer';
import * as fromBillingAccountFull from '@app/core/billing-state/billing-account-full.reducer';
import * as fromMessage from '@app/core/inbox-item-state/inbox-item.reducer';
import * as fromPolicyTemplateInstance from '@app/core/policy-template-instance-state/policy-template-instance.reducer';
import { SignupActionTypes } from './signup/signup.actions';

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  user: userReducer,
  apiApplications: apiApplicationsReducer,
  organisations: organisationsReducer,
  environment: environmentReducer,
  issuerClients: issuerClientsReducer,
  policy: policyReducer,
  signup: signupReducer,
  ui: uiReducer,
  crudManagement: crudManagementReducer,
  issuer: fromIssuer.issuerReducer,
  applicationServices: fromApplicationService.applicationServicesReducer,
  connector: fromConnector.connectorReducer,
  agentConnector: fromAgentConnector.agentConnectorReducer,
  fileShareService: fromFileShareService.fileShareServiceReducer,
  desktop: fromDesktop.desktopReducer,
  launcher: fromLauncher.launcherReducer,
  ssh: fromSSH.sshReducer,
  billing: fromBillingAccountFull.billingAccountFullReducer,
  inboxItem: fromMessage.inboxItemReducer,
  policyTemplateInstance: fromPolicyTemplateInstance.policyTemplateInstanceReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({
    keys: [
      'auth',
      'apiAuth',
      'organisations',
      'environment',
      'ui',
      { [fromBillingAccountFull.billingAccountFullsFeatureKey]: ['default_payment_method', 'is_starter_plan'] },
    ],
    rehydrate: true,
    restoreDates: false,
  })(reducer);
}

export function sessionStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return localStorageSync({
    keys: ['signup'],
    rehydrate: true,
    restoreDates: false,
    storage: sessionStorage,
  })(reducer);
}

export function logoutReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action) => {
    if (action.type === UserActionTypes.LOG_OUT) {
      // This lets us reset the application state in one spot without
      // needing to register handlers for this action in every reducer.
      state = undefined;
    }

    return reducer(state, action);
  };
}

export function nukeStateReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action) => {
    if (action.type === SignupActionTypes.SIGNUP_NUKE_STATE) {
      // This lets us reset the application state in one spot without
      // needing to register handlers for this action in every reducer.
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
  sessionStorageSyncReducer,
  logoutReducer,
  nukeStateReducer,
];

export const selectApiApplicationsState = createFeatureSelector<ApiApplicationsState>('apiApplications');

// the key 'organisations' here must also be in localStorageSyncReducer function above
export const selectOrganisationsState = createFeatureSelector<OrganisationsState>('organisations');

// the key 'environment' here must also be in localStorageSyncReducer function above
export const selectEnvironmentState = createFeatureSelector<EnvironmentState>('environment');

export const selectRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>('router');

export const selectIssuerClientsState = createFeatureSelector<IssuerClientsState>('issuerClients');

export const selectPolicyState = createFeatureSelector<PolicyState>('policy');

export const selectUserState = createFeatureSelector<UserState>('user');

// the key 'signup' here must also be in localStorageSyncReducer function above
export const selectSignupState = createFeatureSelector<SignupState>('signup');

// the key 'ui' here must also be in localStorageSyncReducer function above
export const selectUIState = createFeatureSelector<UIState>('ui');

export const selectCrudManagementState = createFeatureSelector<CrudManagementState>('crudManagement');

export const selectIssuerState = createFeatureSelector<fromIssuer.IssuerState>(fromIssuer.issuersFeatureKey);

export const selectApplicationServicesState = createFeatureSelector<fromApplicationService.ApplicationServicesState>(
  fromApplicationService.applicationServicesFeatureKey
);

export const selectConnectorState = createFeatureSelector<fromConnector.ConnectorState>(fromConnector.connectorsFeatureKey);

export const selectAgentConnectorState = createFeatureSelector<fromAgentConnector.AgentConnectorState>(
  fromAgentConnector.agentConnectorsFeatureKey
);

export const selectFileShareServiceState = createFeatureSelector<fromFileShareService.FileShareServiceState>(
  fromFileShareService.fileShareServicesFeatureKey
);

export const selectDesktopState = createFeatureSelector<fromDesktop.DesktopResourceState>(fromDesktop.desktopsFeatureKey);

export const selectLauncherState = createFeatureSelector<fromLauncher.LauncherState>(fromLauncher.launcersFeatureKey);

export const selectSSHState = createFeatureSelector<fromSSH.SSHResourceState>(fromSSH.sshsFeatureKey);

export const selectInboxItemState = createFeatureSelector<fromMessage.InboxItemState>(fromMessage.inboxItemFeatureKey);

// the key 'fromBillingAccountFull.billingAccountFullsFeatureKey' here must also be in localStorageSyncReducer function above
export const selectBillingState = createFeatureSelector<fromBillingAccountFull.BillingAccountFullState>(
  fromBillingAccountFull.billingAccountFullsFeatureKey
);

export const selectPolicyTemplateInstanceState = createFeatureSelector<fromPolicyTemplateInstance.PolicyTemplateInstanceState>(
  fromPolicyTemplateInstance.policyTemplateInstancesFeatureKey
);

export interface AppState {
  user: UserState;
  router: RouterReducerState<RouterStateUrl>;
  apiApplications: ApiApplicationsState;
  organisations: OrganisationsState;
  environment: EnvironmentState;
  issuerClients: IssuerClientsState;
  policy: PolicyState;
  signup: SignupState;
  ui: UIState;
  crudManagement?: CrudManagementState;
  issuer: fromIssuer.IssuerState;
  applicationServices: fromApplicationService.ApplicationServicesState;
  connector: fromConnector.ConnectorState;
  agentConnector: fromAgentConnector.AgentConnectorState;
  fileShareService: fromFileShareService.FileShareServiceState;
  desktop: fromDesktop.DesktopResourceState;
  launcher: fromLauncher.LauncherState;
  ssh: fromSSH.SSHResourceState;
  billing: fromBillingAccountFull.BillingAccountFullState;
  inboxItem: fromMessage.InboxItemState;
  policyTemplateInstance: fromPolicyTemplateInstance.PolicyTemplateInstanceState;
}
