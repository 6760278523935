import { UpstreamGroupExcludedEntry, UpstreamGroupMapping, UpstreamGroupMappingEntry } from '@agilicus/angular';
import { Component, ChangeDetectionStrategy, OnChanges, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { FilterManager } from '../filter/filter-manager';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { Column, createInputColumn, createSelectRowColumn } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import {
  getMapAllGroupsAgilicusGroupName,
  getMapAllGroupsUpstreamGroupName,
  getUpstreamGroupMappingPropertyIfUnset,
} from '../upstream-group-mapping-utils';
import { getEmptyStringIfUnset, updateTableElements } from '../utils';
import { isANumber } from '../validation-utils';

export interface UpstreamGroupMappingEntryElement extends TableElement, UpstreamGroupMappingEntry {}

export interface UpstreamGroupExcludedEntryElement extends TableElement, UpstreamGroupExcludedEntry {}

@Component({
  selector: 'portal-upstream-group-mappings',
  templateUrl: './upstream-group-mappings.component.html',
  styleUrls: ['./upstream-group-mappings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpstreamGroupMappingsComponent implements OnChanges {
  @Input() public upstreamGroupMapping: UpstreamGroupMapping;
  @Input() public upstreamName: string;
  @Input() public orgId: string;
  @Output() public updateIssuerGroupMapping = new EventEmitter<any>();
  public upstreamGroupMappingEntries?: Array<UpstreamGroupMappingEntry> = [];
  public groupMappingEntryTableData: Array<UpstreamGroupMappingEntryElement> = [];
  public groupMappingsColumnDefs: Map<string, Column<UpstreamGroupMappingEntryElement>> = new Map();
  public makeEmptyGroupMappingTableElementFunc = this.makeEmptyGroupMappingTableElement.bind(this);
  public filterManager: FilterManager = new FilterManager();
  public upstreamGroupExcludedEntries?: Array<UpstreamGroupExcludedEntry> = [];
  public excludedGroupTableData: Array<UpstreamGroupExcludedEntryElement> = [];
  public excludedGroupColumnDefs: Map<string, Column<UpstreamGroupExcludedEntryElement>> = new Map();
  public makeEmptyExcludedGroupsTableElementFunc = this.makeEmptyExcludedGroupTableElement.bind(this);
  public customButtons: Array<TableButton> = [this.getMapAllGroupsCustomButton()];
  public pageDescriptiveText = `For some Identity Providers, groups are automatically retrieved as users sign in. 
  These group names can be imported as-is or mapped to new names in the Agilicus Platform. 
  NOTE: For Microsoft Azure, please ensure the Type is set to "Microsoft".`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/identity-group-mapping/`;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.initializeColumnDefs();
    if (!this.upstreamGroupMapping?.spec?.group_mappings) {
      this.resetEmptyGroupMappingsTable();
    } else {
      this.upstreamGroupMappingEntries = this.upstreamGroupMapping.spec.group_mappings;
      this.updateGroupMappingsTable();
    }
    if (!this.upstreamGroupMapping?.spec?.excluded_groups) {
      this.resetEmptyExcludedGroupsTable();
    } else {
      this.upstreamGroupExcludedEntries = this.upstreamGroupMapping.spec.excluded_groups;
      this.updateExcludedGroupsTable();
    }
  }

  private initializeColumnDefs(): void {
    this.initializeGroupMappingsColumnDefs();
    this.initializeExcludedGroupsColumnDefs();
  }

  private updateGroupMappingsTable(): void {
    this.buildGroupMappingsTableData();
    this.replaceGroupMappingsTableWithCopy();
  }

  private buildGroupMappingsTableData(): void {
    const data: Array<UpstreamGroupMappingEntryElement> = [];
    for (let i = 0; i < this.upstreamGroupMappingEntries.length; i++) {
      data.push(this.createUpstreamGroupMappingElement(this.upstreamGroupMappingEntries[i], i));
    }
    updateTableElements(this.groupMappingEntryTableData, data);
  }

  private createUpstreamGroupMappingElement(
    upstreamGroupMappingEntry: UpstreamGroupMappingEntry,
    index: number
  ): UpstreamGroupMappingEntryElement {
    const data: UpstreamGroupMappingEntryElement = {
      upstream_group_name: '',
      agilicus_group_name: '',
      priority: 0,
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(upstreamGroupMappingEntry)) {
      data[key] = getEmptyStringIfUnset(upstreamGroupMappingEntry[key]);
    }
    return data;
  }

  private replaceGroupMappingsTableWithCopy(): void {
    const tableDataCopy = [...this.groupMappingEntryTableData];
    this.groupMappingEntryTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyGroupMappingsTable(): void {
    this.groupMappingEntryTableData.length = 0;
  }

  private getUpstreamGroupNameColumn(): Column<UpstreamGroupMappingEntryElement | UpstreamGroupExcludedEntryElement> {
    const upstreamGroupNameColumn = createInputColumn('upstream_group_name');
    upstreamGroupNameColumn.displayName = 'Input group';
    upstreamGroupNameColumn.requiredField = () => true;
    upstreamGroupNameColumn.isEditable = true;
    upstreamGroupNameColumn.isCaseSensitive = true;
    upstreamGroupNameColumn.isValidEntry = (str: string): boolean => {
      return str.length > 0 && str.length < 101;
    };
    upstreamGroupNameColumn.getHeaderTooltip = () => {
      return `The name of the group in your upstream identity provider that you want to map. 
      This can be in the form of a regular expression capture group. 
      The value of the capture group can be used in the output group with a format specifier {N} 
      where N is the index of the capture group.`;
    };

    return upstreamGroupNameColumn;
  }

  private getAgilicusGroupNameColumn(): Column<UpstreamGroupMappingEntryElement> {
    const agilicusGroupNameColumn = createInputColumn('agilicus_group_name');
    agilicusGroupNameColumn.displayName = 'Output group';
    agilicusGroupNameColumn.requiredField = () => true;
    agilicusGroupNameColumn.isEditable = true;
    agilicusGroupNameColumn.isCaseSensitive = true;
    agilicusGroupNameColumn.isValidEntry = (str: string): boolean => {
      return str.length > 0 && str.length < 101;
    };
    agilicusGroupNameColumn.getHeaderTooltip = () => {
      return `The name of the group in the Agilicus system that you want to map to. 
      If the input group is a capture group this field can contain those captured values. 
      The match groups are specified by a "{" followed by the match number followed by a closing "}" ie "{0}".`;
    };
    return agilicusGroupNameColumn;
  }

  private getPriorityColumn(): Column<UpstreamGroupMappingEntryElement> {
    const priorityColumn = createInputColumn('priority');
    priorityColumn.isEditable = true;
    priorityColumn.isValidEntry = (str: string): boolean => {
      return isANumber(str);
    };
    priorityColumn.getHeaderTooltip = () => {
      return `The priority of the mapping entry. 
      A lower number indicates that group mapping will take priority if multiple matches occur.`;
    };
    return priorityColumn;
  }

  private initializeGroupMappingsColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();
    const upstreamGroupNameColumn = this.getUpstreamGroupNameColumn();
    const agilicusGroupNameColumn = this.getAgilicusGroupNameColumn();
    const priorityColumn = this.getPriorityColumn();

    // Set the key/values for the column definitions map
    this.groupMappingsColumnDefs.set(selectRowColumn.name, selectRowColumn);
    this.groupMappingsColumnDefs.set(upstreamGroupNameColumn.name, upstreamGroupNameColumn);
    this.groupMappingsColumnDefs.set(agilicusGroupNameColumn.name, agilicusGroupNameColumn);
    this.groupMappingsColumnDefs.set(priorityColumn.name, priorityColumn);
  }

  public makeEmptyGroupMappingTableElement(): UpstreamGroupMappingEntryElement {
    return {
      upstream_group_name: '',
      agilicus_group_name: '',
      priority: this.upstreamGroupMappingEntries.length + 1,
      ...getDefaultNewRowProperties(),
    };
  }

  public updateGroupMappingEvent(): void {
    this.updateUpstreamGroupMapping();
  }

  private getGroupMappingEntriesFromTable(): Array<UpstreamGroupMappingEntry> {
    return this.groupMappingEntryTableData.map((entry: UpstreamGroupMappingEntryElement) => {
      const upstreamGroupMappingEntry: UpstreamGroupMappingEntry = {
        priority: parseInt(entry.priority.toString(), 10),
        upstream_group_name: entry.upstream_group_name,
        agilicus_group_name: entry.agilicus_group_name,
        group_org_id: entry.group_org_id,
      };
      if (entry.upstream_name_is_a_guid !== undefined) {
        upstreamGroupMappingEntry.upstream_name_is_a_guid = entry.upstream_name_is_a_guid;
      }
      return upstreamGroupMappingEntry;
    });
  }

  private updateUpstreamGroupMapping(): void {
    const updatedUpstreamGroupMapping = this.getUpdatedUpstreamGroupMapping();
    this.updateIssuerGroupMapping.emit(updatedUpstreamGroupMapping);
  }

  private getUpdatedUpstreamGroupMapping(): UpstreamGroupMapping {
    const existingUpstreamGroupMappingCopy = cloneDeep(this.upstreamGroupMapping);
    const verifiedUpstreamGroupMapping = getUpstreamGroupMappingPropertyIfUnset(
      existingUpstreamGroupMappingCopy,
      'group_mappings',
      this.upstreamName,
      this.orgId
    );
    const updatedUpstreamGroupMappingEntries = this.getGroupMappingEntriesFromTable();
    const updatedUpstreamGroupExcludedEntries = this.getExcludedGroupEntriesFromTable();
    verifiedUpstreamGroupMapping.spec.group_mappings = updatedUpstreamGroupMappingEntries;
    verifiedUpstreamGroupMapping.spec.excluded_groups = updatedUpstreamGroupExcludedEntries;
    return verifiedUpstreamGroupMapping;
  }

  private removeGroupMappingEntries(): void {
    this.groupMappingEntryTableData = this.groupMappingEntryTableData.filter((element) => !element.isChecked);
  }

  public deleteSelectedGroupMappingEntries(): void {
    this.removeGroupMappingEntries();
    this.updateUpstreamGroupMapping();
  }

  private initializeExcludedGroupsColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const upstreamGroupNameColumn = this.getUpstreamGroupNameColumn();
    upstreamGroupNameColumn.getHeaderTooltip = () => {
      return `The name of the group in your upstream identity provider that you want to exclude from mapping. 
      This can be in the form of a regular expression.`;
    };

    // Set the key/values for the column definitions map
    this.excludedGroupColumnDefs.set(selectRowColumn.name, selectRowColumn);
    this.excludedGroupColumnDefs.set(upstreamGroupNameColumn.name, upstreamGroupNameColumn);
  }

  public makeEmptyExcludedGroupTableElement(): UpstreamGroupExcludedEntryElement {
    return {
      upstream_group_name: '',
      ...getDefaultNewRowProperties(),
    };
  }

  private updateExcludedGroupsTable(): void {
    this.buildExcludedGroupsTableData();
    this.replaceExcludedGroupsTableWithCopy();
  }

  private buildExcludedGroupsTableData(): void {
    const data: Array<UpstreamGroupExcludedEntryElement> = [];
    for (let i = 0; i < this.upstreamGroupExcludedEntries.length; i++) {
      data.push(this.createUpstreamGroupExcludedEntryElement(this.upstreamGroupExcludedEntries[i], i));
    }
    updateTableElements(this.excludedGroupTableData, data);
  }

  private createUpstreamGroupExcludedEntryElement(
    upstreamGroupExcludedEntry: UpstreamGroupExcludedEntry,
    index: number
  ): UpstreamGroupExcludedEntryElement {
    const data: UpstreamGroupExcludedEntryElement = {
      upstream_group_name: '',
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(upstreamGroupExcludedEntry)) {
      data[key] = getEmptyStringIfUnset(upstreamGroupExcludedEntry[key]);
    }
    return data;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyExcludedGroupsTable(): void {
    this.excludedGroupTableData.length = 0;
  }

  private replaceExcludedGroupsTableWithCopy(): void {
    const tableDataCopy = [...this.excludedGroupTableData];
    this.excludedGroupTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private getExcludedGroupEntriesFromTable(): Array<UpstreamGroupExcludedEntry> {
    return this.excludedGroupTableData.map((entry: UpstreamGroupExcludedEntryElement) => {
      const upstreamGroupExcludedEntry: UpstreamGroupExcludedEntry = {
        upstream_group_name: entry.upstream_group_name,
      };
      if (entry.upstream_name_is_a_guid !== undefined) {
        upstreamGroupExcludedEntry.upstream_name_is_a_guid = entry.upstream_name_is_a_guid;
      }
      return upstreamGroupExcludedEntry;
    });
  }

  private removeExcludedGroupEntries(): void {
    this.excludedGroupTableData = this.excludedGroupTableData.filter((element) => !element.isChecked);
  }

  public deleteSelectedExcludedGroupEntries(): void {
    this.removeExcludedGroupEntries();
    this.updateUpstreamGroupMapping();
  }

  private isMapAllGroupsAlreadySet(): boolean {
    for (const groupMapping of this.upstreamGroupMappingEntries) {
      if (
        groupMapping.upstream_group_name === getMapAllGroupsUpstreamGroupName() &&
        groupMapping.agilicus_group_name === getMapAllGroupsAgilicusGroupName()
      ) {
        return true;
      }
    }
    return false;
  }

  private getMapAllGroupsCustomButton(): TableScopedButton {
    const mapAllGroupsButton = new TableScopedButton(
      'MAP ALL GROUPS',
      ButtonColor.PRIMARY,
      'Map all groups directly into Agilicus. Groups listed in the excluded groups table will still be excluded.',
      'Button that maps all groups directly into Agilicus',
      () => {
        this.addMapAllGroupsToTable();
      }
    );
    mapAllGroupsButton.isDisabled = () => {
      return this.isMapAllGroupsAlreadySet();
    };
    mapAllGroupsButton.disabledButtonTooltipText = () => 'All groups have already been mapped';
    return mapAllGroupsButton;
  }

  private addMapAllGroupsToTable(): void {
    const mapAllGroupsRule: UpstreamGroupMappingEntryElement = {
      upstream_group_name: getMapAllGroupsUpstreamGroupName(),
      agilicus_group_name: getMapAllGroupsAgilicusGroupName(),
      group_org_id: this.orgId,
      priority: this.upstreamGroupMappingEntries.length + 1,
      ...getDefaultTableProperties(-1),
    };
    this.groupMappingEntryTableData.unshift(mapAllGroupsRule);
    this.updateGroupMappingEvent();
  }
}
