import { FeatureFlag, Organisation } from '@agilicus/angular';

export function getSubOrgPageDescriptiveText(): string {
  return `An organisation (Tenant, Project in other cloud systems) is a unique scope of billing and control. 
  A sub-organisation allows you to make your tenant multi-tenant.`;
}

export function getOrganisationProductGuideLink(): string {
  return 'https://www.agilicus.com/anyx-guide/organisation/';
}

export function getOrgFeatureFlagsMap(org: Organisation | undefined): Map<string, FeatureFlag> {
  const featureFlagsMap = new Map();
  const featureFlags = !!org?.feature_flags ? org.feature_flags : [];
  for (const featureFlag of featureFlags) {
    featureFlagsMap.set(featureFlag.feature, featureFlag);
  }
  return featureFlagsMap;
}

export function canOrgCreateNewCustomerSubOrg(org: Organisation | undefined): boolean {
  const featureFlagsMap = getOrgFeatureFlagsMap(org);
  const selfCreatesSuborgFeatureFlag = featureFlagsMap.get('self_creates_suborg');
  return !!selfCreatesSuborgFeatureFlag?.enabled;
}

export function canOrgCreateNewIssuer(org: Organisation | undefined): boolean {
  const featureFlagsMap = getOrgFeatureFlagsMap(org);
  const suborgCreatesIssuerFeatureFlag = featureFlagsMap.get('suborg_creates_issuer');
  return !!suborgCreatesIssuerFeatureFlag?.enabled;
}
