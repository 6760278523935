import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as SSHResourceActions from './ssh.actions';
import { SSHResource } from '@agilicus/angular';
import { ResourceBasicCollectionState } from '@app/core/basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultIconStatus, getDefaultResourceBasicCollectionState } from '../helpers/entity-state-utils';

export const sshsFeatureKey = 'ssh';

export type SSHResourceState = ResourceBasicCollectionState<SSHResource>;

export const adapter: EntityAdapter<SSHResource> = createEntityAdapter<SSHResource>({
  sortComparer: sortListByCreatedDate,
  selectId: (ssh) => ssh.metadata.id,
});

export const initialState: SSHResourceState = adapter.getInitialState(getDefaultResourceBasicCollectionState<SSHResource>());

export const sshReducer = createReducer(
  initialState,
  on(SSHResourceActions.addSSHResource, (state, action) => adapter.addOne(action.obj, state)),
  on(SSHResourceActions.upsertSSHResource, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(SSHResourceActions.addSSHResources, (state, action) => adapter.addMany(action.objs, state)),
  on(SSHResourceActions.upsertSSHResources, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(SSHResourceActions.updateSSHResource, (state, action) => adapter.updateOne(action.obj, state)),
  on(SSHResourceActions.updateSSHResources, (state, action) => adapter.updateMany(action.objs, state)),
  on(SSHResourceActions.deleteSSHResource, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(SSHResourceActions.deleteSSHResources, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(SSHResourceActions.loadSSHResources, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(SSHResourceActions.clearSSHResources, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(SSHResourceActions.savingSSHResource, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(SSHResourceActions.savingSSHResources, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(SSHResourceActions.initSSHResources, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(SSHResourceActions.refreshSSHResources, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  }),
  // custom resource reducers:
  on(SSHResourceActions.savingIconSSHResources, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: true,
        icon_file_save_success: false,
        icon_file_save_fail: false,
      },
    };
  }),
  on(SSHResourceActions.successfulIconSaveSSHResources, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: true,
        icon_file_save_fail: false,
      },
    };
  }),
  on(SSHResourceActions.failedIconSaveSSHResources, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: false,
        icon_file_save_fail: true,
      },
    };
  }),
  on(SSHResourceActions.resetIconStatusSSHResources, (state, action) => {
    return { ...state, icon_status: getDefaultIconStatus() };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
