<portal-page-info
  pageTitle="Top Users"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasPermissions !== undefined && !hasPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasPermissions && org_id" class="top-metrics-container">
  <form
    class="metrics-form"
    [formGroup]="topMetricsForm"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="date-filters-container">
      <p class="metrics-filter-field">Start Time</p>

      <mat-form-field class="metrics-filter-field">
        <input
          matInput
          [max]="maxDate"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          placeholder="Choose start time"
          formControlName="dtFrom"
          [owlDateTimeFilter]="getStartDateMaxSetter(topMetricsForm)"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <owl-date-time #dt1></owl-date-time>
        <mat-error>Enter valid date and time</mat-error>
      </mat-form-field>


      <p class="metrics-filter-field">End Time</p>

      <mat-form-field class="metrics-filter-field">
        <input
          matInput
          [owlDateTime]="dt2"
          [owlDateTimeTrigger]="dt2"
          placeholder="Choose end time"
          formControlName="dtTo"
          [owlDateTimeFilter]="getEndDateMinSetter(topMetricsForm)"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <owl-date-time #dt2></owl-date-time>
        <mat-error>Enter valid date and time</mat-error>
      </mat-form-field>
    </div>

    <div class="resource-filters-container">
      <mat-form-field class='filter-field'>
        <input
          matInput
          placeholder="Resource name"
          formControlName="resource_name"
          [matAutocomplete]="autoResourceNames"
          #resourceNamesTrigger="matAutocompleteTrigger"
          (keyup.enter)="keyTabManager.keyTabAutoInput($event.target.id, resourceNamesTrigger)"
        />
        <mat-autocomplete #autoResourceNames="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredResourceOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="filter-field">
        <mat-label>Resource type</mat-label>
        <mat-select 
          #selectResourceType
          formControlName="resource_type"
          (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectResourceType)"
        >
          <mat-option
            *ngFor="let type of resourceTypesList"
            value="{{ type }}"
            >{{ type }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="button-container">
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="!topMetricsForm.valid"
        (click)="listTopUsers()"
      >
        VIEW METRICS <fa-icon [icon]="['fas', 'search']"></fa-icon>
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="!topMetricsForm.valid"
        (click)="listTopUsersAndDownload()"
      >
        DOWNLOAD <fa-icon [icon]="['fas', 'download']"></fa-icon>
      </button>
    </div>
  </form>

  <div class="graph-container">
    <b>Number of Sessions for Top 500 Users</b>
    <ngx-charts-bar-vertical
      [results]="graphDataSource"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [noBarWhenZero]="false"
      [roundEdges]="false"
      [legend]="false"
    >
    </ngx-charts-bar-vertical>
  </div>

  <div class="table-container mat-elevation-z8">
    <table mat-table #metricsTableSort="matSort" [dataSource]="tableDataSource" matSort>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width ="30%"># Sessions</th>
        <td mat-cell *matCellDef="let element">{{ element.count }}</td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedMetricsColumns; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedMetricsColumns"></tr>
    </table>
    <mat-paginator
      [pageSize]="25"
      [pageSizeOptions]="useSharedPageSizeOptions()"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
