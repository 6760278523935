import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { Issuer, FileSummary } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService, selectPolicyState } from '@app/core';
import {
  ActionPolicySavingTheme,
  ActionPolicyResetThemeUploadStatus,
  ActionPolicyDeletingIssuerThemeId,
} from '@app/core/issuer/issuer.actions';
import { selectCurrentTheme } from '@app/core/issuer/issuer.selectors';
import { takeUntil } from 'rxjs/operators';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { formatBytes, getFile, getTarFileMimeTypesList, getZipFileMimeTypesList, isZipFileMimeType } from '../file-utils';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCurrentOrgIssuer } from '@app/core/organisations/organisations.selectors';
import { FileHelperService } from '@app/core/services/file-helper/file-helper.service';
import { initIssuer } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer, selectIssuerRefreshDataValue, selectSavingIssuer } from '@app/core/issuer-state/issuer.selectors';
import { PolicyState } from '@app/core/issuer/issuer.models';
import * as JSZip from 'jszip';

@Component({
  selector: 'portal-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemingComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  private currentOrgIssuer$: Observable<string>;
  public currentOrgIssuer: string;
  public issuerCopy: Issuer;
  private theme$: Observable<FileSummary>;
  public theme: FileSummary;
  public deletingCurrentTheme = false;
  private issuersPermissions$: Observable<OrgQualifiedPermission>;
  public hasIssuersPermissions: boolean;
  public buttonDescription = 'THEME';
  public isUploading = false;
  public themeForm: UntypedFormGroup;
  public progressBarController: ProgressBarController = new ProgressBarController();
  public productGuideLink = `https://www.agilicus.com/anyx-guide/sign-in-theming/`;
  public pageDescriptiveHelpImageWithTextWrap = 'assets/img/sign-in-screen.png';
  public pageDescriptiveTextWithImageWrap = '';
  private localRefreshDataValue = 0;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private fileHelperService: FileHelperService,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    const policyState$ = this.store.pipe(select(selectPolicyState));
    const currentIssuer$ = this.store.pipe(select(selectCurrentIssuer));
    const savingIssuerState$ = this.store.pipe(select(selectSavingIssuer));
    const refreshDataState$ = this.store.pipe(select(selectIssuerRefreshDataValue));
    this.currentOrgIssuer$ = this.store.pipe(select(selectCurrentOrgIssuer));
    this.theme$ = this.store.pipe(select(selectCurrentTheme));
    this.issuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    combineLatest([
      policyState$,
      currentIssuer$,
      savingIssuerState$,
      refreshDataState$,
      this.currentOrgIssuer$,
      this.theme$,
      this.issuersPermissions$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          policyStateResp,
          currentIssuerResp,
          savingIssuerStateResp,
          refreshDataStateResp,
          currentOrgIssuerResp,
          themeResp,
          issuerPermissions,
        ]: [PolicyState, Issuer, boolean, number, string, FileSummary, OrgQualifiedPermission]) => {
          this.orgId = issuerPermissions.orgId;
          this.hasIssuersPermissions = issuerPermissions.hasPermission;
          if (!this.hasIssuersPermissions || savingIssuerStateResp) {
            // Need this in order for the "No Permissions" text to be displayed when the page first loads.
            this.changeDetector.detectChanges();
            return;
          }
          this.theme = themeResp;
          if (themeResp !== undefined) {
            this.initializeFormGroup();
          }
          this.currentOrgIssuer = currentOrgIssuerResp;
          this.pageDescriptiveTextWithImageWrap = this.getPageDescriptiveTextWithImageWrap();
          if (policyStateResp === undefined || currentIssuerResp === undefined) {
            this.issuerCopy = undefined;
            this.changeDetector.detectChanges();
            return;
          }
          this.deletingCurrentTheme = policyStateResp.deleting_current_theme;
          if (!this.issuerCopy || this.localRefreshDataValue !== refreshDataStateResp) {
            this.localRefreshDataValue = refreshDataStateResp;
            this.issuerCopy = cloneDeep(currentIssuerResp);
          }
          this.handleProgressBarState(policyStateResp);
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPageDescriptiveTextWithImageWrap(): string {
    return `Your users will sign in to ${this.currentOrgIssuer}. This identity provider in turn uses a set of upstream identity providers you have configured.\n\nYou can modify the theming of this sign-in screen here.`;
  }

  private getDisplayDate(): string | Date {
    if (!this.theme.updated && !this.theme.created) {
      return '';
    }
    if (!this.theme.updated) {
      return this.theme.created;
    }
    return this.theme.updated;
  }

  private initializeFormGroup(): void {
    this.themeForm = this.formBuilder.group({
      name: this.theme.name,
      size: formatBytes(this.theme.size),
      updated: this.getDisplayDate(),
    });
  }

  private getSupportedThemeMimeTypesList(): Array<string> {
    return [...getZipFileMimeTypesList(), ...getTarFileMimeTypesList()];
  }

  private isSupportedThemeMimeType(file: File): boolean {
    return this.getSupportedThemeMimeTypesList().includes(file.type);
  }

  private async isValidDirectoryStructure(file: File): Promise<boolean> {
    if (!isZipFileMimeType(file)) {
      // We have no way to check the contents of tar files in the browser.
      return true;
    }
    let isValid = false;
    const zip = await JSZip.loadAsync(file);
    Object.keys(zip.files).forEach((filename) => {
      if (filename === 'theme/styles.css') {
        isValid = true;
      }
    });
    return isValid;
  }

  private async checkIfValidThemeFile(file: File): Promise<boolean> {
    if (!this.isSupportedThemeMimeType(file)) {
      this.notificationService.error('Not a valid file type. Please ensure the file is either a zip or tar file.');
      return false;
    }
    const isValidDirectoryStructureResult = await this.isValidDirectoryStructure(file);
    if (isValidDirectoryStructureResult) {
      return true;
    }
    this.notificationService.error('Not a valid directory structure. Please ensure the stylesheet path matches "theme/styles.css"');
    return false;
  }

  public async onReadFile(event: any): Promise<void> {
    const file = getFile(event);
    if (!file) {
      return;
    }
    await this.uploadFile(file);
  }

  public async onFileDrop(event: any): Promise<void> {
    const dt = event.dataTransfer;
    const files = dt.files;
    if (!files || files.length === 0) {
      return;
    }
    if (files.length !== 1) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.notificationService.error('Cannot upload multiple files. Please select a single file for upload.');
      return;
    }
    await this.uploadFile(files[0]);
  }

  private async uploadFile(file: File): Promise<void> {
    const isValidThemeFileResult = await this.checkIfValidThemeFile(file);
    if (!isValidThemeFileResult) {
      return;
    }
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.initializeProgressBar();
    this.isUploading = true;
    this.store.dispatch(new ActionPolicySavingTheme(file));
  }

  public downloadFile(): void {
    if (!this.theme) {
      return;
    }
    this.fileHelperService.downloadToBrowser(this.theme, this.orgId, this.renderer);
  }

  public deleteTheme(): void {
    this.store.dispatch(new ActionPolicyDeletingIssuerThemeId());
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideProgressBar(): void {
    setTimeout(() => {
      // Need to re-assign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }

  private handleProgressBarState(issuerStateResp: PolicyState): void {
    if (issuerStateResp.saving_theme) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.initializeProgressBar();
      this.isUploading = true;
    }
    if (!issuerStateResp.theme_save_success && !issuerStateResp.theme_save_fail) {
      return;
    }
    if (issuerStateResp.theme_save_success) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.updateProgressBarValue(1, 1);
      this.delayHideProgressBar();
    }
    if (issuerStateResp.theme_save_fail) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.onFailedUpload();
    }
    this.store.dispatch(new ActionPolicyResetThemeUploadStatus());
    this.isUploading = false;
  }
}
