import { Application, ApplicationsService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  createNewApplication,
  deleteApplication,
  getApplicationById,
  getListOfOwnedApplications,
  updateExistingApplication,
} from '../api-applications/api-applications-utils';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import { AppState } from '../core.state';
import * as ApplicationActions from '../api-applications/api-applications.actions';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStateService extends SimpleCRUDInterface<Application, string> {
  public crudRegistryName = CrudStateCollection.applications;

  constructor(
    public store: Store<AppState>,
    private applicationsService: ApplicationsService,
    private crudRegistryService: CrudRegistryService<Application, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<Application, string> = {
        create: (id: string, obj: Application, orgId: string) => createNewApplication(this.applicationsService, obj, orgId),
        update: (id: string, obj: Application, orgId: string) => updateExistingApplication(this.applicationsService, obj),
        get: (id: string, orgId: string) => getApplicationById(this.applicationsService, id, orgId),
        list: (orgId: string) => getListOfOwnedApplications(this.applicationsService, orgId),
        delete: (id: string, orgId: string) => deleteApplication(this.applicationsService, id, orgId),
      };
      const crudRegistry: CrudRegistry<Application, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: Application) => obj.name,
        doneSaveAction: ApplicationActions.upsertApplication,
        doneSaveListAction: ApplicationActions.upsertApplications,
        doneDeleteAction: ApplicationActions.deleteApplication,
        doneDeleteListAction: ApplicationActions.deleteApplications,
        setListAction: ApplicationActions.loadApplications,
        refreshStateAction: ApplicationActions.refreshApplications,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
