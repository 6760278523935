import { createSelector } from '@ngrx/store';
import { selectIssuerClientsState } from '../core.state';
import { IssuerClientsState } from './issuer-clients.models';

export const selectIssuerClients = createSelector(selectIssuerClientsState, (state: IssuerClientsState) => state);

export const selectIssuerClientsList = createSelector(
  selectIssuerClientsState,
  (state: IssuerClientsState) => state.current_issuer_client_list
);

export const selectIssuerClientsShouldPopulateValue = createSelector(
  selectIssuerClientsState,
  (state: IssuerClientsState) => state.should_populate
);

export const selectIssuerClientsRefreshData = createSelector(selectIssuerClientsState, (state: IssuerClientsState) => state?.refresh_data);
