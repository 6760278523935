import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppState } from '@app/core';
import { ActionApiApplicationsDeleteDemo, ActionApiApplicationsResetDemoStatus } from '@app/core/api-applications/api-applications.actions';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import { getDefaultInitialModelStatus } from '@app/core/api-applications/api-applications.reducer';
import { selectApiApplicationsDemoStatus } from '@app/core/api-applications/api-applications.selectors';
import { getDeleteDemoButtonName } from '@app/core/api/demo-api-utils';
import { getSubscriptionButtonText } from '@app/core/billing-state/billing-api-utils';
import { PaymentService } from '@app/core/payment-service/payment.service';
import { EventsService } from '@app/core/services/events.service';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { PageInfoData } from '../page-info/page-info.component';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';

export interface PaymentDialogData {
  messagePrefix?: string;
  message?: string;
  informationDialog?: boolean;
  orgId?: string;
  icon?: string;
  productGuideData?: PageInfoData;
  showDemoDeleteButton?: boolean;
  showDoNotShowButton?: boolean;
}

@Component({
  selector: 'portal-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrls: ['./payment-dialog.component.scss'],
})
export class PaymentDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public messagePrefix = 'Reminder';
  public message = 'Do you want to continue?';
  public confirmButtonText = 'Yes';
  public cancelButtonText = 'Cancel';
  public informationDialog = false;
  public icon = 'notification_important';
  public productGuideData: PageInfoData;
  public showRequestPayment = false;
  public showDemoDeleteButton = false;
  public showDoNotShowButton = false;
  public orgId: string;
  public subscriptionButtonText = getSubscriptionButtonText();
  public demoStatus: ApplicationModelStatus = getDefaultInitialModelStatus();
  public createDemoProgressBarController: ProgressBarController = new ProgressBarController();
  public hideApplyButton = true;

  public getDeleteDemoButtonName = getDeleteDemoButtonName;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PaymentDialogData,
    private dialogRef: MatDialogRef<PaymentDialogComponent>,
    private eventsService: EventsService,
    private store: Store<AppState>,
    private paymentService: PaymentService
  ) {
    if (data) {
      this.messagePrefix = data.messagePrefix || this.messagePrefix;
      this.message = data.message || this.message;
      this.icon = !!data.icon ? data.icon : this.icon;
      this.productGuideData = data.productGuideData;
      this.showDemoDeleteButton = !!data.showDemoDeleteButton;
      this.showDoNotShowButton = !!data.showDoNotShowButton;
      this.orgId = data.orgId;
      if (data.informationDialog) {
        this.informationDialog = true;
      }
    }
  }

  public ngOnInit(): void {
    const demoStatusState$ = this.store.pipe(select(selectApiApplicationsDemoStatus));
    demoStatusState$.subscribe((demoStatusStateResp) => {
      this.demoStatus = demoStatusStateResp;
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onSetupPaymentClick(): void {
    this.paymentService.handleSetupPaymentClick(this.orgId, this.unsubscribe$, true);
  }

  public onDoNotShowClick(): void {
    this.dialogRef.close(false);
  }

  public deleteDemo(): void {
    this.eventsService.SendEvent('demo_delete', localStorage.email, this.orgId, window.location.href);
    this.store.dispatch(new ActionApiApplicationsDeleteDemo());
  }

  public resetModelStatus(): void {
    this.store.dispatch(new ActionApiApplicationsResetDemoStatus());
  }

  public enableDemoDeleteButton(): boolean {
    return (!this.demoStatus.saving && !this.demoStatus.deleting && !this.demoStatus.delete_success) || !!this.demoStatus.delete_failed;
  }
}
