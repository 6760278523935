<portal-page-info
  pageTitle="Audit User"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div class="main-content-container">
  <mat-slide-toggle
    class="mark-complete-slider"
    [checked]="isTaskComplete"
    (change)="onCompleteToggle($event.checked)"
  >
    {{ getTaskCompleteToggleText(targetTask, gettingStartedDataCopy) }}
  </mat-slide-toggle>

  <form
    [formGroup]="auditUserFormGroup"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="parent-radio-group">
      <mat-radio-group
        formControlName="userIssue"
        aria-labelledby="user issue option radio buttons"
        class="radio-group"
      >
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="userAuditIssue.login"
        >
          My user cannot log in
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="userAuditIssue.resourceAccess"
        >
          My user is having problems accessing a resource
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="userAuditIssue.resourceRequest"
        >
          My user has requested access to a resource
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="userAuditIssue.resourceError"
        >
          My user has reported a problem with a resource
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="userAuditIssue.viewLogins"
        >
          I want to see when my user has logged in
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="isLoginOptionSelected()">
      <div class="child-radio-group nest-level-1">
        <mat-radio-group
          formControlName="loginIssue"
          aria-labelledby="login issue option radio buttons"
          class="radio-group nested-radio-group"
        >
          <mat-radio-button
            class="radio-button full-line-radio-button"
            [value]="loginIssue.mfa"
          >
            My user has lost/forgotten their multi-factor method
          </mat-radio-button>
          <mat-radio-button
            class="radio-button full-line-radio-button"
            [value]="loginIssue.other"
          >
            My user has other problems logging in
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div *ngIf="isRequestsOptionSelected()">
      <div class="child-radio-group nest-level-1">
        <mat-radio-group
          formControlName="requestAccessType"
          aria-labelledby="request access type option radio buttons"
          class="radio-group nested-radio-group"
        >
          <mat-radio-button
            class="radio-button full-line-radio-button"
            [value]="requestAccessType.application"
          >
            My user has requested access to an application
          </mat-radio-button>
          <mat-radio-button
            class="radio-button full-line-radio-button"
            [value]="requestAccessType.resource"
          >
            My user has requested access to another resource type, such as a share or desktop
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>

  <div class="recommendations-container">
    <div *ngIf="showMfaResetRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you reset the user's multi-factor authentication enrollment deadline to the organisation's default."
        imageSourcePath="assets/img/audit-user-mfa-reset.png"
        (imageClick)="onImageClick('user-audits')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showPermissionsAndSessionAuditRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you check the user's permissions and session audit records."
        imageSourcePath="assets/img/audit-user-permissions-session-audit.png"
        (imageClick)="onImageClick('user-audits')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showResourceRequestsRecommendation() || showApplicationRequestsRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you check the user's resource access requests and grant access if applicable."
        imageSourcePath="assets/img/audit-user-resource-requests.png"
        (imageClick)="onImageClick('resource-access-requests')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showLogsRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you check the user's log records."
        imageSourcePath="assets/img/audit-user-logs.png"
        (imageClick)="onImageClick('user-audits')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showSessionAuditRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you check the user's session audit records."
        imageSourcePath="assets/img/audit-user-session-audit.png"
        (imageClick)="onImageClick('user-audits')"
      >
      </portal-getting-started-recommendations>
    </div>
  </div>
</div>