import { Application, ApplicationConfig } from '@agilicus/angular';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { setCorsConfigPropertyIfUnset } from '../application-configs-utils';
import { CorsHeadersElement } from '../cors-headers-element';
import { FilterManager } from '../filter/filter-manager';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { Column, createInputColumn, createSelectRowColumn } from '../table-layout/column-definitions';
import { updateTableElements } from '../utils';
import { Observable } from 'rxjs';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';

@Component({
  selector: 'portal-cors-allow-headers',
  templateUrl: './cors-allow-headers.component.html',
  styleUrls: ['./cors-allow-headers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorsAllowHeadersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public currentApplicationCopy: Application;
  @Output() public updateApplication = new EventEmitter<any>();
  public tableData: Array<CorsHeadersElement> = [];
  public columnDefs: Map<string, Column<CorsHeadersElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'HEADER';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
  }

  public ngOnChanges(): void {
    if (!this.currentApplicationCopy?.environments || this.currentApplicationCopy.environments.length === 0) {
      this.resetEmptyTable();
      return;
    }
    this.updateTable();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<CorsHeadersElement> = [];
    const corsAllowHeaders = this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http?.cors?.allow_headers;
    if (!!corsAllowHeaders) {
      for (let i = 0; i < corsAllowHeaders.length; i++) {
        data.push(this.createCorsHeadersElement(corsAllowHeaders[i], i));
      }
    }
    updateTableElements(this.tableData, data);
  }

  private createCorsHeadersElement(corsHeader: string, index: number): CorsHeadersElement {
    const data: CorsHeadersElement = {
      header: corsHeader,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const headerColumn = createInputColumn('header');
    headerColumn.isEditable = true;
    headerColumn.requiredField = () => true;
    headerColumn.isValidEntry = (header: string) => {
      const headerPattern = /[^:]+/;
      return header.match(headerPattern) !== null;
    };

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(headerColumn.name, headerColumn);
  }

  public makeEmptyTableElement(): CorsHeadersElement {
    return {
      header: '',
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Updates the config when a change is made in the table.
   */
  public updateEvent(): void {
    this.updateCorsAllowHeaders();
  }

  private removeElements(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
  }

  public deleteSelected(): void {
    this.removeElements();
    this.updateCorsAllowHeaders();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private updateCorsAllowHeaders(): void {
    const updatedAppConfigs = this.getUpdatedAppConfigs();
    this.updateApplication.emit(updatedAppConfigs);
  }

  private getUpdatedAppConfigs(): ApplicationConfig {
    const existingEnvironmentCopy = cloneDeep(this.currentApplicationCopy?.environments[0]);
    setCorsConfigPropertyIfUnset(existingEnvironmentCopy, 'allow_headers');
    const updatedCorsAllowHeaders = this.getCorsAllowHeadersFromTable();
    existingEnvironmentCopy.application_configs.security.http.cors.allow_headers = updatedCorsAllowHeaders;
    return existingEnvironmentCopy.application_configs;
  }

  private getCorsAllowHeadersFromTable(): Array<string> {
    const corsAllowHeaders: Array<string> = [];
    for (const element of this.tableData) {
      corsAllowHeaders.push(element.header);
    }
    return corsAllowHeaders;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }
}
