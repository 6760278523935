import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as IssuerActions from './issuer.actions';
import { Issuer } from '@agilicus/angular';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { BasicCollectionState } from '../basic-collection-state';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';

export const issuersFeatureKey = 'issuer';

export type IssuerState = BasicCollectionState<Issuer>;

export const adapter: EntityAdapter<Issuer> = createEntityAdapter<Issuer>({
  sortComparer: sortListByCreatedDate,
});

export const initialState: IssuerState = adapter.getInitialState({
  ...getDefaultBasicCollectionState<Issuer>(),
  // additional entity state properties
  state_save_success: false,
});

export const issuerReducer = createReducer(
  initialState,
  on(IssuerActions.addIssuer, (state, action) =>
    adapter.addOne(action.obj, { ...state, saving_state: false, loaded_org_id: action.org_id })
  ),
  on(IssuerActions.upsertIssuer, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      state_save_success: true,
    })
  ),
  on(IssuerActions.addIssuers, (state, action) => adapter.addMany(action.objs, state)),
  on(IssuerActions.upsertIssuers, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(IssuerActions.updateIssuer, (state, action) => adapter.updateOne(action.obj, state)),
  on(IssuerActions.updateIssuers, (state, action) => adapter.updateMany(action.objs, state)),
  on(IssuerActions.deleteIssuer, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(IssuerActions.deleteIssuers, (state, action) => adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })),
  on(IssuerActions.loadIssuers, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(IssuerActions.clearIssuers, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(IssuerActions.savingIssuer, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects, state_save_success: false };
  }),
  on(IssuerActions.savingIssuers, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects, state_save_success: false };
  }),
  on(IssuerActions.initIssuer, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(IssuerActions.refreshIssuer, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
