import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Connector } from '@agilicus/angular';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';

@Component({
  selector: 'portal-connector-selection',
  templateUrl: './connector-selection.component.html',
  styleUrls: ['./connector-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorSelectionComponent {
  @Input() public connectorFormGroup: UntypedFormGroup;
  @Input() public connectors: Array<Connector> = [];
  @Input() public required = false;
  @Input()
  public descriptiveText = `Select your previously configured connector from the list below. If you would like to configure a new connector, you can do so now using the "New" sub-menu under the "Connectors" tab on the left. After configuring your connector you can return to this step and select that connector.`;
  @Output() public updateModel = new EventEmitter<string>();
  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  constructor() {}

  public updateSelectedConnector(form: UntypedFormGroup, value: string): void {
    if (form.controls.connector_name.invalid) {
      return;
    }

    this.updateApplicationModel(value);
  }

  private updateApplicationModel(value: string): void {
    this.updateModel.emit(value);
  }
}
