import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Rss, RssItem } from '../models/rss.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RssService {
  private serviceUrl = 'https://www.agilicus.com/feed/json?utm_source=portal';

  constructor(private http: HttpClient) {}

  public getItems(): Observable<RssItem[]> {
    return this.http.get<Rss>(this.serviceUrl).pipe(
      map((res) => {
        const items = res.items;
        return items;
      })
    );
  }
}
