<p class="descriptive-text">
  The headers which may be sent in a request to resources from this application. 
  These correspond to the <code class="inline-code">Access-Control-Allow-Headers</code> header, into which they are joined by commas. 
  Set a value to <code class="inline-code">\'*\'</code> to wildcard.
</p>

<portal-table-layout
  [tableData]="tableData"
  [columnDefs]="columnDefs"
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName"
  (updateEvent)="updateEvent()"
  (removeSelected)="deleteSelected()"
>
</portal-table-layout>