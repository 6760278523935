import { ApplicationConfig, Environment } from '@agilicus/angular';
import {
  getEmptyOidcConfig,
  getDefaultSecurityConfig,
  getDefaultApplicationAdditionalContext,
  getDefaultApplicationConfig,
} from '@app/core/models/application/application-model-api-utils';
import { SecuritySettings } from '@app/core/models/security/security-settings.enum';

export function setOidcConfigIfUnset(env: Environment, issuer: string): void {
  setAppConfigIfUnset(env);
  if (!env.application_configs.oidc_config) {
    env.application_configs.oidc_config = getEmptyOidcConfig(issuer);
  }
}

export function setDomainMappingConfigIfUnset(env: Environment, issuer: string): void {
  setOidcConfigIfUnset(env, issuer);
  if (!env.application_configs.oidc_config.domain_mapping) {
    env.application_configs.oidc_config.domain_mapping = getEmptyOidcConfig(issuer).domain_mapping;
  }
}

export function setAdditionalContextConfigIfUnset(env: Environment): void {
  setAppConfigIfUnset(env);
  if (!env.application_configs.additional_context) {
    env.application_configs.additional_context = getDefaultApplicationAdditionalContext();
  }
}

export function setOidcMediaTypesIfUnset(appConfigs: ApplicationConfig, issuer: string): void {
  if (!appConfigs.oidc_config) {
    appConfigs.oidc_config = getEmptyOidcConfig(issuer);
    if (!appConfigs.oidc_config.content_manipulation) {
      appConfigs.oidc_config.content_manipulation = getEmptyOidcConfig().content_manipulation;
    }
  }
}

export function setOidcAuthConfigIfUnset(env: Environment, issuer: string): void {
  setOidcConfigIfUnset(env, issuer);
  if (!env.application_configs.oidc_config.auth) {
    env.application_configs.oidc_config.auth = getEmptyOidcConfig(issuer).auth;
  }
}

export function setUpstreamConfigIfUnset(env: Environment, issuer: string): void {
  setOidcConfigIfUnset(env, issuer);
  if (!env.application_configs.oidc_config.upstream_config) {
    env.application_configs.oidc_config.upstream_config = getEmptyOidcConfig(issuer).upstream_config;
  }
}

export function setAuthenticationUpstreamIfUnset(env: Environment): void {
  if (!env.application_configs.authentication_config) {
    env.application_configs.authentication_config = {
      upstream: {
        ntlm: {
          ntlm_passthrough: false,
        },
      },
    };
    return;
  }
  if (!env.application_configs.authentication_config?.upstream) {
    env.application_configs.authentication_config.upstream = {
      ntlm: {
        ntlm_passthrough: false,
      },
    };
  }
}

export function setSecurityConfigIfUnset(env: Environment): void {
  setAppConfigIfUnset(env);
  if (!env.application_configs.security) {
    env.application_configs.security = getDefaultSecurityConfig();
  }
  if (!env.application_configs.security.http) {
    env.application_configs.security.http = getDefaultSecurityConfig().http;
  }
}

export function setCorsConfigIfUnset(env: Environment): void {
  setSecurityConfigIfUnset(env);
  if (!env.application_configs.security.http.cors) {
    env.application_configs.security.http.cors = getDefaultSecurityConfig().http.cors;
  }
}

export function setCorsConfigPropertyIfUnset(env: Environment, property: string): void {
  setCorsConfigIfUnset(env);
  if (!env.application_configs.security.http.cors[property]) {
    env.application_configs.security.http.cors[property] = getDefaultSecurityConfig().http.cors[property];
  }
}

export function setCspConfigIfUnset(env: Environment): void {
  setSecurityConfigIfUnset(env);
  if (!env.application_configs.security.http.csp) {
    env.application_configs.security.http.csp = getDefaultSecurityConfig().http.csp;
  }
}

export function setHstsConfigIfUnset(env: Environment): void {
  setSecurityConfigIfUnset(env);
  if (!env.application_configs.security.http.hsts) {
    env.application_configs.security.http.hsts = getDefaultSecurityConfig().http.hsts;
  }
}

export function setAllSecurityConfigPropertiesIfUnset(env: Environment): void {
  setSecurityConfigIfUnset(env);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.csp);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.cors);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.hsts);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.xss_protection);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.certificate_transparency);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.frame_options);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.content_type_options);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.permitted_cross_domain_policies);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.coep);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.coop);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.corp);
  setSecurityConfigPropertyIfUnset(env, SecuritySettings.referrer_policy);
}

export function setSecurityConfigPropertyIfUnset(env: Environment, property: string): void {
  setSecurityConfigIfUnset(env);
  if (!env.application_configs.security.http[property]) {
    env.application_configs.security.http[property] = getDefaultSecurityConfig().http[property];
  }
}

export function setAppConfigIfUnset(env: Environment): void {
  if (!env) {
    return;
  }
  if (!env.application_configs) {
    env.application_configs = getDefaultApplicationConfig();
  }
}

export function setContentManipulationConfigIfUnset(env: Environment, issuer: string): void {
  setOidcConfigIfUnset(env, issuer);
  if (!env.application_configs.oidc_config.content_manipulation) {
    env.application_configs.oidc_config.content_manipulation = getEmptyOidcConfig(issuer).content_manipulation;
  }
}

export function setOidcHeadersConfigIfUnset(env: Environment, issuer: string): void {
  setOidcConfigIfUnset(env, issuer);
  if (!env.application_configs.oidc_config.headers) {
    env.application_configs.oidc_config.headers = getEmptyOidcConfig(issuer).headers;
  }
}

export function setOidcHeaderOverridesConfigIfUnset(env: Environment, issuer: string): void {
  setOidcHeadersConfigIfUnset(env, issuer);
  if (!env.application_configs.oidc_config.headers.header_overrides) {
    env.application_configs.oidc_config.headers.header_overrides = getEmptyOidcConfig(issuer).headers.header_overrides;
  }
}
