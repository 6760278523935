<portal-page-info
  pageTitle="Users"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasUsersPermissions !== undefined && !hasUsersPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasUsersPermissions"
  class="user-admin-container"
>
  <portal-users-csv
    [firstNameToGroupMap]="firstNameToGroupMap"
    [emailToUserMap]="emailToUserMap"
    [preventUpload]="preventAddUser"
    [disableUpload]="!allowAddNewUserClick()"
    [userLimit]="getUserLimit()"
    (updateEvent)="reloadFirstPageAndRefreshUsageMetrics()"
    (uploadClick)="onPreventAddNewRow()"
    (notifyUser)="notifyUserOnCsvUpload()"
  >
  </portal-users-csv> 

  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [paramSpecificFilterManager]="paramSpecificFilterManager"
    [filterMenuOptions]="filterMenuOptions"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    [rowObjectName]="rowObjectName"
    [buttonsToShow]="buttonsToShow"
    [customButtons]="customButtons"
    [paginatorConfig]="paginatorConfig"
    [linkDataSource] = "linkDataSource"
    [useBackendFilter]="useBackendFilter"
    [preventAddNewRow]="preventAddUser"
    [disableAddNewRow]="!allowAddNewUserClick()"
    (updateEvent)="updateEvent($event)"
    (removeSelected)="deleteSelected($event)"
    (enableSelected)="enableSelected($event)"
    (disableSelected)="disableSelected($event)"
    (filterSearchDelay)="paramSpecificFilterManager.filterSearchDelay($event)"
    (filterBySearchParam)="filterBySearchParam()"
    (preventAddNewRowFunc)="onPreventAddNewRow()"
    >
  </portal-table-layout>
</div>
