import { Application, ApplicationConfig, OIDCContentType } from '@agilicus/angular';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import { Observable } from 'rxjs';
import { setOidcMediaTypesIfUnset } from '../application-configs-utils';
import { FilterManager } from '../filter/filter-manager';
import { getFilteredValues } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { OptionalOIDCContentTypeElement } from '../optional-types';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { AutoInputColumn, Column, createAutoInputColumn, createSelectRowColumn } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { updateTableElements } from '../utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';

export interface OIDCContentTypeElement extends OIDCContentType, TableElement {
  nameFormControl: UntypedFormControl;
}

@Component({
  selector: 'portal-media-types',
  templateUrl: './media-types.component.html',
  styleUrls: ['./media-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaTypesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public currentApplicationCopy: Application;
  @Input() public currentOrgIssuer: string;
  @Output() public updateApplication = new EventEmitter<any>();
  public tableData: Array<OIDCContentTypeElement> = [];
  public columnDefs: Map<string, Column<OIDCContentTypeElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'MEDIA TYPE';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
  }

  public ngOnChanges(): void {
    if (!this.currentApplicationCopy?.environments || this.currentApplicationCopy.environments.length === 0) {
      this.resetEmptyTable();
      return;
    }
    this.updateTable();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<OIDCContentTypeElement> = [];
    const mediaTypes = this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.content_manipulation?.media_types;
    if (!!mediaTypes) {
      for (let i = 0; i < mediaTypes.length; i++) {
        data.push(this.createOIDCContentTypeElement(mediaTypes[i], i));
      }
    }
    updateTableElements(this.tableData, data);
  }

  private createOIDCContentTypeElement(mediaType: OIDCContentType, index: number): OIDCContentTypeElement {
    const data: OIDCContentTypeElement = {
      name: '',
      nameFormControl: new UntypedFormControl(),
      ...getDefaultTableProperties(index),
      ...mediaType,
    };
    data.nameFormControl.setValue(mediaType.name);
    return data;
  }

  private getNameColumnAllowedValues(): Array<OIDCContentType> {
    return [
      { name: 'application/epub+zip' },
      { name: 'application/gzip' },
      { name: 'application/java-archive' },
      { name: 'application/json' },
      { name: 'application/ld+json' },
      { name: 'application/msword' },
      { name: 'application/octet-stream' },
      { name: 'application/ogg' },
      { name: 'application/pdf' },
      { name: 'application/rtf' },
      { name: 'application/vnd.amazon.ebook' },
      { name: 'application/vnd.apple.installer+xml' },
      { name: 'application/vnd.mozilla.xul+xml' },
      { name: 'application/vnd.ms-excel' },
      { name: 'application/vnd.ms-fontobject' },
      { name: 'application/vnd.ms-powerpoint' },
      { name: 'application/vnd.oasis.opendocument.presentation' },
      { name: 'application/vnd.oasis.opendocument.spreadsheet' },
      { name: 'application/vnd.oasis.opendocument.text' },
      { name: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
      { name: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      { name: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
      { name: 'application/vnd.rar' },
      { name: 'application/vnd.visio' },
      { name: 'application/xhtml+xml' },
      { name: 'application/xml' },
      { name: 'application/x-abiword' },
      { name: 'application/x-bzip' },
      { name: 'application/x-bzip2' },
      { name: 'application/x-cdf' },
      { name: 'application/x-csh' },
      { name: 'application/x-httpd-php' },
      { name: 'application/x-sh' },
      { name: 'application/x-shockwave-flash' },
      { name: 'application/x-tar' },
      { name: 'application/x-7z-compressed' },
      { name: 'application/zip' },
      { name: 'audio/aac' },
      { name: 'audio/midi' },
      { name: 'audio/mpeg' },
      { name: 'audio/ogg' },
      { name: 'audio/opus' },
      { name: 'audio/wav' },
      { name: 'audio/webm' },
      { name: 'audio/x-midi' },
      { name: 'audio/3gpp' },
      { name: 'audio/3gpp2' },
      { name: 'font/otf' },
      { name: 'font/ttf' },
      { name: 'font/woff' },
      { name: 'font/woff2' },
      { name: 'image/bmp' },
      { name: 'image/gif' },
      { name: 'image/jpeg' },
      { name: 'image/png' },
      { name: 'image/svg+xml' },
      { name: 'image/tiff' },
      { name: 'image/vnd.microsoft.icon' },
      { name: 'image/webp' },
      { name: 'text/calendar' },
      { name: 'text/css' },
      { name: 'text/csv' },
      { name: 'text/html' },
      { name: 'text/javascript' },
      { name: 'text/plain' },
      { name: 'text/xml' },
      { name: 'video/mpeg' },
      { name: 'video/mp2t' },
      { name: 'video/mp4' },
      { name: 'video/ogg' },
      { name: 'video/webm' },
      { name: 'video/x-msvideo' },
      { name: 'video/3gpp' },
      { name: 'video/3gpp2' },
    ];
  }

  private getNameColumn(): AutoInputColumn<OIDCContentTypeElement> {
    const nameColumn = createAutoInputColumn('nameFormControl');
    nameColumn.displayName = 'Name';
    nameColumn.requiredField = () => true;
    nameColumn.isEditable = true;
    nameColumn.isCaseSensitive = true;
    nameColumn.getDisplayValue = (mediaType: OptionalOIDCContentTypeElement) => {
      return mediaType.name;
    };
    nameColumn.getFilteredValues = (
      element: OptionalOIDCContentTypeElement,
      column: AutoInputColumn<OIDCContentTypeElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.nameFormControl, column);
    };
    nameColumn.allowedValues = this.getNameColumnAllowedValues();
    return nameColumn;
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();
    const nameColumn = this.getNameColumn();

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(nameColumn.name, nameColumn);
  }

  private getMediaTypesFromTable(): Array<OIDCContentType> {
    const mediaTypes: Array<OIDCContentType> = [];
    for (const element of this.tableData) {
      mediaTypes.push({ name: element.name });
    }
    return mediaTypes;
  }

  /**
   * Saves the updated row.
   */
  public updateEvent(): void {
    this.updateAppConfigsMediaTypes();
  }

  public updateAutoInput(params: {
    optionValue: string;
    column: AutoInputColumn<OIDCContentTypeElement>;
    element: OIDCContentTypeElement;
  }): void {
    if (params.column.name !== 'nameFormControl') {
      return;
    }
    if (params.optionValue === params.element.name) {
      // The value has not been changed.
      return;
    }
    params.element.name = params.optionValue;
    params.element.dirty = true;
  }

  private getUpdatedAppConfigs(): ApplicationConfig {
    const updatedMediaTypes = this.getMediaTypesFromTable();
    const existingApplicationConfigCopy = cloneDeep(this.currentApplicationCopy.environments[0].application_configs);
    existingApplicationConfigCopy.oidc_config.content_manipulation.media_types = updatedMediaTypes;
    return existingApplicationConfigCopy;
  }

  private updateAppConfigsMediaTypes(): void {
    const updatedAppConfigs = this.getUpdatedAppConfigs();
    setOidcMediaTypesIfUnset(updatedAppConfigs, this.currentOrgIssuer);
    this.updateApplication.emit(updatedAppConfigs);
  }

  private removeElements(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
  }

  public deleteSelected(): void {
    this.removeElements();
    this.updateAppConfigsMediaTypes();
  }

  public makeEmptyTableElement(): OIDCContentTypeElement {
    return {
      name: '',
      nameFormControl: new UntypedFormControl(),
      ...getDefaultNewRowProperties(),
    };
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }
}
