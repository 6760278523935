import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Renderer2, OnDestroy } from '@angular/core';
import { Subject, Observable, of, combineLatest, iif } from 'rxjs';
import { Column, createExpandColumn, createIconColumn, createReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import {
  TimeIntervalOption,
  getDefaultTimeIntervalOptions,
  getStartDateMaxSetter,
  getEndDateMinSetter,
  setStartAndEndDatesForFilter,
  convertDateToReadableFormat,
  getCurrentDate,
} from '../date-utils';
import { createEnumChecker, getMfaEnrollmentExpiryDateString, getUserNameFromUser, removeStringFromList } from '../utils';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { Papa } from 'ngx-papaparse';
import { createCombinedPermissionsSelector, OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import {
  UsersService,
  ListCombinedUserDetailsRequestParams,
  ListUserApplicationAccessInfoRequestParams,
  ListUserApplicationAccessInfoResponse,
  AuditsService,
  ListAuthRecordsRequestParams,
  ListAuthAuditsResponse,
  User,
  AuthAudits,
  ResetMFAChallengeMethod,
  ResetUserMfaChallengeMethodsRequestParams,
  DiagnosticsService,
  ListLogsRequestParams,
  ListLogsResponse,
  CombinedUserDetail,
  ListUserMetadataRequestParams,
  ListUserMetadataResponse,
  ListCombinedUserDetailsResponse,
  UserMetadataSpec,
  Organisation,
  UserMetadata,
  ReplaceUserMetadataRequestParams,
  CreateUserMetadataRequestParams,
  IssuersService,
  ListWellknownIssuerInfoRequestParams,
  UserStatusEnum,
  ListUserResourceAccessInfoResponse,
  ListUserResourceAccessInfoRequestParams,
  ListUsersResponse,
} from '@agilicus/angular';
import {
  concatMap,
  map,
  catchError,
  switchMap,
  startWith,
  mergeMap,
  takeUntil,
  debounceTime,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import { selectCanReadUsers } from '@app/core/user/permissions/users.selectors';
import { selectCanReadDiagnostics } from '@app/core/user/permissions/diagnostics.selectors';
import { capitalizeFirstLetter, getMfaEnrollmentExpiryFromUserMetadata, getUniqueNamesList } from '../utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectOrganisations } from '@app/core/organisations/organisations.selectors';
import { OrganisationsState } from '@app/core/organisations/organisations.models';
import { downloadDataToCsv } from '../file-utils';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import { OptionalMFAChallengeMethodElement, OptionalResourceAccessElement } from '../optional-types';
import { IconColor } from '../icon-color.enum';
import { IconState } from '../icon-types';
import { selectCanReadResources } from '@app/core/user/permissions/resources.selectors';
import { ResourceType } from '../resource-type.enum';
import { CheckboxOption, FilterManager } from '../filter/filter-manager';
import { FilterType } from '../filter-type.enum';
import { InputData } from '../custom-chiplist-input/input-data';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { convertResourceTypeToReadableString, getResourceTypeIcon, getResourceTypeTooltip } from '../resource-utils';
import { selectCanReadApps } from '@app/core/user/permissions/app.selectors';
import { TableElement } from '../table-layout/table-element';
import {
  AuthAuditElement,
  getAuditElements,
  getAuthAuditApplicationNameColumn,
  getAuthAuditClientIdColumn,
  getAuthAuditEventNameColumn,
  getAuthAuditResultColumn,
  getAuthAuditSourceIpColumn,
  getAuthAuditsPlusEmailList,
  getAuthAuditTimeColumn,
  getAuthAuditTokenIdColumn,
  getAuthAuditTraceIdColumn,
  getAuthAuditUpstreamIdpColumn,
  getAuthAuditUserAgentColumn,
} from '../audit-utils';

export interface ResourceAccessElement extends InputData {
  resource_name: string;
  resource_type: ResourceType;
}

export interface MFAChallengeMethodElement extends InputData {
  challenge_type?: string;
  nickname?: string;
  enabled?: boolean;
  created?: Date;
  updated?: Date;
  supported_method: boolean;
}

export interface LogElement extends InputData {
  timestamp?: string;
  log?: string;
}

export interface AuditData {
  raw_audit_data: Array<AuthAudits>;
  audits_formatted: Array<AuthAuditElement>;
}

export interface UserAuditState {
  user: User | undefined;
  mfa_challenge_methods: Array<MFAChallengeMethodElement>;
  audits: AuditData;
  application_access: Array<ResourceAccessElement>;
  resource_access: Array<ResourceAccessElement>;
  logs: Array<LogElement>;
  userMfaEnrollmentMetadata: UserMetadata;
  current_org: Organisation;
  error_message: string | undefined;
}

export enum UserAuditPanel {
  APP_ACCESS_OVERVIEW = 'APP_ACCESS_OVERVIEW',
  RESOURCE_ACCESS_OVERVIEW = 'RESOURCE_ACCESS_OVERVIEW',
}

function getUserResourceRolesAsString(roles: Array<string>): string {
  if (!roles || roles.length === 0) {
    return '';
  }
  return roles.join(', ');
}

function createResourceAccessElement(
  resourceName: string,
  resourceType: ResourceType,
  orgName: string,
  roles: Array<string>
): {
  [key: string]: string;
} {
  const resourceAccessElement: {
    [key: string]: string;
  } = {
    resource_name: resourceName,
    resource_type: resourceType,
  };
  resourceAccessElement[orgName] = getUserResourceRolesAsString(roles);
  return resourceAccessElement;
}

export function getAppAccessElements(appAccessInfo: ListUserApplicationAccessInfoResponse): Array<ResourceAccessElement> | undefined {
  if (!appAccessInfo) {
    return undefined;
  }
  const appAccessElements = new Map<
    string,
    {
      [key: string]: string;
    }
  >();
  for (const appInfo of appAccessInfo.user_application_access_info) {
    const info = appInfo.status;
    if (appAccessElements.has(info.application_name)) {
      const elem = appAccessElements.get(info.application_name);
      elem[info.org_name] = getUserResourceRolesAsString(info.roles);
    } else {
      appAccessElements.set(
        info.application_name,
        createResourceAccessElement(info.application_name, ResourceType.application, info.org_name, info.roles)
      );
    }
  }
  const retval = [];
  for (const value of appAccessElements.values()) {
    retval.push(value);
  }

  return retval;
}

export function getResourceAccessElements(
  resourceAccessInfo: ListUserResourceAccessInfoResponse
): Array<ResourceAccessElement> | undefined {
  if (!resourceAccessInfo) {
    return undefined;
  }
  const resourceAccessElements = new Map<
    string,
    {
      [key: string]: string;
    }
  >();
  for (const resourceInfo of resourceAccessInfo.user_resource_access_info) {
    const info = resourceInfo.status;
    if (resourceAccessElements.has(info.resource_name)) {
      const elem = resourceAccessElements.get(info.resource_name);
      elem[info.org_name] = getUserResourceRolesAsString(info.roles);
    } else {
      const isResourceType = createEnumChecker(ResourceType);
      if (isResourceType(info.resource_type)) {
        resourceAccessElements.set(
          info.resource_name,
          createResourceAccessElement(info.resource_name, info.resource_type, info.org_name, info.roles)
        );
      }
    }
  }
  const retval = [];
  for (const value of resourceAccessElements.values()) {
    retval.push(value);
  }

  return retval;
}

@Component({
  selector: 'portal-user-diagnose',
  templateUrl: './user-diagnose.component.html',
  styleUrls: ['./user-diagnose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDiagnoseComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgState$: Observable<OrganisationsState>;
  private permissionState$: Observable<OrgQualifiedPermission>;
  private orgId: string;
  public componentContext$: Observable<{
    state: UserAuditState;
  }>;
  public state$: Subject<UserAuditState> = new Subject();
  public hasPermissions: boolean;
  public auditTableColumnDefs: Map<string, Column<AuthAuditElement>> = new Map();
  public mfaTableColumnDefs: Map<string, Column<MFAChallengeMethodElement>> = new Map();
  public appAccessTableColumnDefs: Map<string, Column<ResourceAccessElement>> = new Map();
  public resourceAccessTableColumnDefs: Map<string, Column<ResourceAccessElement>> = new Map();
  public logTableColumnDefs: Map<string, Column<LogElement>> = new Map();
  public timeIntervalOptions: Array<TimeIntervalOption> = getDefaultTimeIntervalOptions();
  public maxDate: Date = getCurrentDate();
  public userEmail = '';
  public userAuditFilterForm: UntypedFormGroup;
  public getStartDateMaxSetter = getStartDateMaxSetter;
  public getEndDateMinSetter = getEndDateMinSetter;
  public userSearchForm: UntypedFormGroup;
  public filteredUserOptions$: Observable<Array<string>>;
  private panelsStateMap: Map<UserAuditPanel, boolean> = new Map();
  // This is required in order to reference the enums in the html template.
  public userAuditPanel = UserAuditPanel;
  public appOrgNameList: Array<string> = [];
  public resourceOrgNameList: Array<string> = [];
  public hideAppRowFunc = this.hideAppRow.bind(this);
  public hideResourceRowFunc = this.hideResourceRow.bind(this);
  public appAccessFilterManager: FilterManager = new FilterManager();
  public resourceAccessFilterManager: FilterManager = new FilterManager();
  public showEmptyAppRows = false;
  public showEmptyResourceRows = false;
  public filteredResourceTypes: Array<ResourceType> = [];
  public pageDescriptiveText = `Audit records are used to diagnose problems a user may be having or security damage caused by a breach. 
  You may also view and update multi-factor authentication information.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/users/#h-audits`;
  public isSearching = false;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public resourceType = ResourceType;

  private contentFilterMenuOption: FilterMenuOption = {
    name: 'content',
    displayName: 'Content',
    icon: 'visibility_off',
    type: FilterMenuOptionType.checkbox,
  };
  private resourceTypeFilterMenuOption: FilterMenuOption = {
    name: 'type',
    displayName: 'Resource Type',
    icon: 'category',
    type: FilterMenuOptionType.checkbox,
  };

  public appAccessFilterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [this.contentFilterMenuOption.name, this.contentFilterMenuOption],
  ]);

  public resourceAccessFilterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [this.contentFilterMenuOption.name, this.contentFilterMenuOption],
    [this.resourceTypeFilterMenuOption.name, this.resourceTypeFilterMenuOption],
  ]);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private papa: Papa,
    private renderer: Renderer2,
    private usersService: UsersService,
    private auditsService: AuditsService,
    private logsQueryService: DiagnosticsService,
    private notificationService: NotificationService,
    private issuersService: IssuersService
  ) {
    this.initializePanelsStateMap();
    this.setAppAccessFilters();
    this.setResourceAccessFilters();
  }

  private getEmptyUserState(errorMessage: string): UserAuditState {
    return {
      user: undefined,
      mfa_challenge_methods: [],
      audits: { raw_audit_data: [], audits_formatted: [] },
      application_access: [],
      resource_access: [],
      logs: [],
      userMfaEnrollmentMetadata: undefined,
      current_org: undefined,
      error_message: errorMessage,
    };
  }

  private setAppAccessFilters(): void {
    this.appAccessFilterManager.addCheckboxFilterOption(this.getShowEmptyAppRowsCheckboxOption());
  }

  private getDefaultShowEmptyRowsCheckboxOption(
    filterMenuOptions: Map<string, FilterMenuOption>,
    doFilter: (checkboxOption: CheckboxOption) => void
  ): CheckboxOption {
    return {
      name: 'Show empty rows',
      displayName: 'Show empty rows',
      label: filterMenuOptions.get('content').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: doFilter.bind(this),
    };
  }

  public getShowEmptyAppRowsCheckboxOption(): CheckboxOption {
    return this.getDefaultShowEmptyRowsCheckboxOption(this.appAccessFilterMenuOptions, this.toggleShowEmptyAppRows);
  }

  public getShowEmptyResourceRowsCheckboxOption(): CheckboxOption {
    return this.getDefaultShowEmptyRowsCheckboxOption(this.resourceAccessFilterMenuOptions, this.toggleShowEmptyResourceRows);
  }

  private getDefaultResourceTypeCheckboxOption(name: ResourceType): CheckboxOption {
    return {
      name,
      displayName: capitalizeFirstLetter(convertResourceTypeToReadableString(name)),
      label: this.resourceAccessFilterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleResourceTypes.bind(this),
    };
  }

  public getShowResourceCheckboxOption(resourceType: ResourceType): CheckboxOption {
    return this.getDefaultResourceTypeCheckboxOption(resourceType);
  }

  private setResourceAccessFilters(): void {
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowEmptyResourceRowsCheckboxOption());
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.application));
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.ssh));
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.fileshare));
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.desktop));
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.application_service));
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.launcher));
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.service_forwarder));
    this.resourceAccessFilterManager.addCheckboxFilterOption(this.getShowResourceCheckboxOption(ResourceType.group));
  }

  public ngOnInit(): void {
    this.initializeFormGroups();
    this.initializeColumnDefs();
    this.orgState$ = this.store.pipe(select(selectOrganisations));
    this.permissionState$ = this.store.pipe(
      select(
        createCombinedPermissionsSelector(
          createCombinedPermissionsSelector(selectCanReadUsers, selectCanReadDiagnostics),
          createCombinedPermissionsSelector(selectCanReadResources, selectCanReadApps)
        )
      )
    );
    this.componentContext$ = combineLatest([this.permissionState$, this.state$.asObservable()]).pipe(
      startWith(0),
      switchMap(() => this.getState$().pipe(map((state) => ({ state }))))
    );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializePanelsStateMap(): void {
    for (const value of Object.keys(UserAuditPanel)) {
      const isUserAuditPanelEnum = createEnumChecker(UserAuditPanel);
      if (isUserAuditPanelEnum(value)) {
        this.panelsStateMap.set(value, false);
      }
    }
    // We want to default the app access overview to open.
    this.panelsStateMap.set(UserAuditPanel.APP_ACCESS_OVERVIEW, true);
  }

  private getAllStateInfo$(orgId: string): Observable<UserAuditState> {
    if (this.userEmail.length === 0) {
      // no user has been specified; this case happens on page load
      return of(this.getEmptyUserState(undefined));
    }
    const listCombinedUserDetailsParams: ListCombinedUserDetailsRequestParams = {
      org_id: orgId,
      type: 'user',
      email: this.userEmail,
    };
    const users$ = this.usersService.listCombinedUserDetails(listCombinedUserDetailsParams);
    return combineLatest([users$, this.orgState$]).pipe(
      concatMap(([usersResp, orgStateResp]) => {
        const currentUser = usersResp.combined_user_details[0];
        const userId = currentUser?.metadata?.id;

        // Don't load all the logs for an org only load them if a user is specified
        if (currentUser === undefined || userId === undefined) {
          return combineLatest([
            of(usersResp),
            of(currentUser),
            of(orgStateResp),
            of(undefined),
            of(undefined),
            of(undefined),
            of(undefined),
            of(undefined),
            of(undefined),
          ]);
        }

        return combineLatest([
          of(usersResp),
          of(currentUser),
          of(orgStateResp),
          this.getAudits(userId, orgId),
          this.getLogs(currentUser, orgId),
          this.getUserApplicationAccessInfo(userId, orgId),
          this.getUserResourceAccessInfo(userId, orgId),
          this.getUserMetadata(userId, orgId),
          this.getSupportedMfaMethods(orgId),
        ]);
      }),
      map(
        ([
          usersResp,
          currentUser,
          orgStateResp,
          auditsResp,
          logsResp,
          userAppAccessInfoResp,
          userResourceAccessInfoResp,
          userMetadataResp,
          supportedMfaMethodsResp,
        ]: [
          ListCombinedUserDetailsResponse,
          CombinedUserDetail,
          OrganisationsState,
          ListAuthAuditsResponse,
          ListLogsResponse,
          ListUserApplicationAccessInfoResponse,
          ListUserResourceAccessInfoResponse,
          ListUserMetadataResponse,
          Array<string>
        ]) => {
          this.isSearching = false;
          if (!currentUser) {
            const errorMessage = `User "${this.getUserEmailFormValue()}" does not exist. Please try another user.`;
            return this.getEmptyUserState(errorMessage);
          }
          if (!!userAppAccessInfoResp) {
            this.appOrgNameList = getUniqueNamesList(
              userAppAccessInfoResp.user_application_access_info.map((info) => info.status.org_name)
            );
          }
          if (!!userResourceAccessInfoResp) {
            this.resourceOrgNameList = getUniqueNamesList(
              userResourceAccessInfoResp.user_resource_access_info.map((info) => info.status.org_name)
            );
          }
          // Need the org names before we can initialize the app and share access column defs.
          this.initializeAccessTableColumnDefs(this.appAccessTableColumnDefs, 'Application Name', this.appOrgNameList);
          this.initializeAccessTableColumnDefs(this.resourceAccessTableColumnDefs, 'Resource Name', this.resourceOrgNameList);
          const mockUsersResp: ListUsersResponse = {
            users: [
              {
                email: currentUser?.status?.user?.email,
                id: currentUser?.status?.user?.id,
              },
            ],
            limit: 500,
          };
          const authAuditsPlusEmailList = getAuthAuditsPlusEmailList(auditsResp, mockUsersResp);

          const state: UserAuditState = {
            user: currentUser.status.user,
            mfa_challenge_methods: this.getMfaMethodElements(currentUser, supportedMfaMethodsResp),
            audits: {
              audits_formatted: getAuditElements(authAuditsPlusEmailList, this.initializeNestedAuthAuditColumnDefs.bind(this)),
              raw_audit_data: auditsResp.auth_audits,
            },
            application_access: getAppAccessElements(userAppAccessInfoResp),
            resource_access: getResourceAccessElements(userResourceAccessInfoResp),
            logs: this.getLogElements(logsResp),
            userMfaEnrollmentMetadata: getMfaEnrollmentExpiryFromUserMetadata(userMetadataResp),
            current_org: orgStateResp?.current_organisation,
            error_message: undefined,
          };
          return state;
        }
      ),
      catchError((_) => {
        this.isSearching = false;
        const errorMessage = `Failed to retrieve user "${this.userEmail}". Please try again.`;
        return of(this.getEmptyUserState(errorMessage));
      })
    );
  }

  private getState$(): Observable<UserAuditState> {
    this.userEmail = this.getUserEmailFormValue();
    return this.permissionState$.pipe(
      mergeMap((permission) => {
        this.orgId = permission.orgId;
        this.hasPermissions = permission.hasPermission;
        this.changeDetector.detectChanges();
        return iif(() => permission.hasPermission, this.getAllStateInfo$(permission.orgId), of(this.getEmptyUserState(undefined)));
      })
    );
  }

  public getUserEmailFormValue(): string {
    return this.userSearchForm.get('user_email').value;
  }

  private getMfaMethodElements(user: CombinedUserDetail, supportedMethods: Array<string>): Array<MFAChallengeMethodElement> | undefined {
    if (!user || !supportedMethods) {
      return undefined;
    }
    return user.status.mfa_challenge_methods?.map((mfaMethod) => {
      return {
        challenge_type: mfaMethod.spec.challenge_type,
        nickname: mfaMethod.spec.nickname,
        enabled: mfaMethod.spec.enabled,
        created: mfaMethod.metadata.created,
        updated: mfaMethod.metadata.updated,
        supported_method: supportedMethods.includes(mfaMethod.spec.challenge_type),
      };
    });
  }

  private getLogElements(logsResp: ListLogsResponse): Array<LogElement> | undefined {
    if (!logsResp) {
      return undefined;
    }
    return logsResp.logs?.map((log) => {
      return {
        timestamp: log.timestamp,
        log: log.log,
      };
    });
  }

  private initializeUserEmailFormGroup(): void {
    this.userSearchForm = this.formBuilder.group({
      user_email: ['', [Validators.required]],
    });

    this.filteredUserOptions$ = this.userSearchForm.get('user_email').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      filter((input) => input !== null),
      debounceTime(350), // allow for some delay so we don't make api calls on every keyup, only the last value is returned after 350ms
      distinctUntilChanged(), // only make api calls if the latest value is different from the previous value
      concatMap((input: string) => {
        // get users whose email prefix matches the input
        return this.usersService
          .listCombinedUserDetails({
            org_id: this.orgId,
            type: 'user',
            status: [UserStatusEnum.active, UserStatusEnum.pending],
            prefix_email_search: input,
          })
          .pipe(
            takeUntil(this.unsubscribe$),
            map((usersResp) => {
              return usersResp.combined_user_details.map((user) => user.status.user.email);
            })
          );
      })
    );
  }

  private initializeUserAuditFilterFormGroup(): void {
    this.userAuditFilterForm = this.formBuilder.group({
      dtFrom: null,
      dtTo: null,
      quick_select_time: '60',
    });
  }

  private initializeFormGroups(): void {
    this.initializeUserEmailFormGroup();
    this.initializeUserAuditFilterFormGroup();
  }

  private getResourceTypeColumn(): Column<ResourceAccessElement> {
    const resourceTypeColumn = createIconColumn('resource_type');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    resourceTypeColumn.getDisplayValue = (element: OptionalResourceAccessElement) => {
      const isResourceTypeEnum = createEnumChecker(ResourceType);
      if (isResourceTypeEnum(element.resource_type)) {
        return getResourceTypeIcon(element.resource_type);
      }
      return '';
    };
    resourceTypeColumn.getTooltip = (element: OptionalResourceAccessElement) => {
      const isResourceTypeEnum = createEnumChecker(ResourceType);
      if (isResourceTypeEnum(element.resource_type)) {
        return getResourceTypeTooltip(element.resource_type);
      }
      return '';
    };
    return resourceTypeColumn;
  }

  private initializeAccessTableColumnDefs(
    colDefs: Map<string, Column<ResourceAccessElement>>,
    colDisplayName: string,
    orgNames: Array<string>
  ): void {
    colDefs.clear();

    const resourceTypeColumn = this.getResourceTypeColumn();

    const resourceNameColumn = createReadonlyColumn('resource_name');
    resourceNameColumn.displayName = colDisplayName;

    // Set the key/values for the column definitions map
    colDefs.set(resourceTypeColumn.name, resourceTypeColumn);
    colDefs.set(resourceNameColumn.name, resourceNameColumn);

    for (const orgName of orgNames) {
      const currentOrgColumn = createReadonlyColumn(orgName);
      currentOrgColumn.displayName = capitalizeFirstLetter(orgName);
      colDefs.set(currentOrgColumn.name, currentOrgColumn);
    }
  }

  private initializeMFATableColumnDefs(): void {
    const nicknameColumn = createReadonlyColumn('nickname');
    nicknameColumn.displayName = 'Device Name';

    const challengeTypeColumn = createReadonlyColumn('challenge_type');
    challengeTypeColumn.displayName = 'MFA Method Name';

    const enabledColumn = createReadonlyColumn('enabled');
    enabledColumn.displayName = 'State';
    enabledColumn.getDisplayValue = () => '';
    enabledColumn.hasIconPrefix = true;
    enabledColumn.getIconPrefix = (element: OptionalMFAChallengeMethodElement) => {
      return this.getMfaEnrollmentIconState(element).icon;
    };
    enabledColumn.getIconColor = (element: OptionalMFAChallengeMethodElement) => {
      return this.getMfaEnrollmentIconState(element).color;
    };
    enabledColumn.getTooltip = (element: OptionalMFAChallengeMethodElement) => {
      return this.getMfaEnrollmentIconState(element).tooltip;
    };

    const createdColumn = createReadonlyColumn('created');
    createdColumn.displayName = 'Time Enabled';

    const updatedColumn = createReadonlyColumn('updated');
    updatedColumn.displayName = 'Time Last Modified';

    // Set the key/values for the column definitions map
    this.mfaTableColumnDefs.set(nicknameColumn.name, nicknameColumn);
    this.mfaTableColumnDefs.set(challengeTypeColumn.name, challengeTypeColumn);
    this.mfaTableColumnDefs.set(enabledColumn.name, enabledColumn);
    this.mfaTableColumnDefs.set(createdColumn.name, createdColumn);
    this.mfaTableColumnDefs.set(updatedColumn.name, updatedColumn);
  }

  // Session Audit Table:

  /**
   * Parent Table Columns
   */
  private initializeAuditTableColumnDefs(): void {
    setColumnDefs(
      [
        getAuthAuditTimeColumn(),
        getAuthAuditEventNameColumn(),
        getAuthAuditResultColumn(),
        getAuthAuditClientIdColumn(),
        getAuthAuditSourceIpColumn(),
        createExpandColumn(),
      ],
      this.auditTableColumnDefs
    );
  }

  /**
   * Nested Table Columns
   */
  private initializeNestedAuthAuditColumnDefs(nestedColumnDefs: Map<string, Column<TableElement>>): void {
    setColumnDefs(
      [
        getAuthAuditTimeColumn(),
        getAuthAuditEventNameColumn(),
        getAuthAuditResultColumn(),
        getAuthAuditClientIdColumn(),
        getAuthAuditSourceIpColumn(),
        getAuthAuditTokenIdColumn(),
        getAuthAuditTraceIdColumn(),
        getAuthAuditUpstreamIdpColumn(),
        getAuthAuditUserAgentColumn(),
        getAuthAuditApplicationNameColumn(),
      ],
      nestedColumnDefs
    );
  }

  // Log Table:

  private initializeLogTableColumnDefs(): void {
    const timeColumn = createReadonlyColumn('timestamp');
    const logColumn = createReadonlyColumn('log');

    // Set the key/values for the column definitions map
    this.logTableColumnDefs.set(timeColumn.name, timeColumn);
    this.logTableColumnDefs.set(logColumn.name, logColumn);
  }

  private initializeColumnDefs(): void {
    this.initializeMFATableColumnDefs();
    this.initializeAuditTableColumnDefs();
    this.initializeLogTableColumnDefs();
  }

  private getAudits(userId: string, orgId: string): Observable<ListAuthAuditsResponse | undefined> {
    const authAuditFilterData = this.getAuthAuditFilterData(userId, orgId);
    return this.auditsService.listAuthRecords(authAuditFilterData).pipe(
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the authentication audit data');
        return of(undefined);
      })
    );
  }

  private getUserApplicationAccessInfo(userId: string, orgId: string): Observable<ListUserApplicationAccessInfoResponse | undefined> {
    const userAppAccessParams: ListUserApplicationAccessInfoRequestParams = {
      org_id: orgId,
      user_id: userId,
    };
    return this.usersService.listUserApplicationAccessInfo(userAppAccessParams).pipe(
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the application access information');
        return of(undefined);
      })
    );
  }

  private getUserResourceAccessInfo(userId: string, orgId: string): Observable<ListUserResourceAccessInfoResponse | undefined> {
    const userResourceAccessParams: ListUserResourceAccessInfoRequestParams = {
      org_id: orgId,
      user_id: userId,
    };
    return this.usersService.listUserResourceAccessInfo(userResourceAccessParams).pipe(
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the resource access information');
        return of(undefined);
      })
    );
  }

  private getUserMetadata(userId: string, orgId: string): Observable<ListUserMetadataResponse | undefined> {
    const listUserMetadataParams: ListUserMetadataRequestParams = {
      user_id: userId,
      org_id: orgId,
      data_type: UserMetadataSpec.DataTypeEnum.mfa_enrollment_expiry,
    };
    return this.usersService.listUserMetadata(listUserMetadataParams).pipe(
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the user metadata');
        return of(undefined);
      })
    );
  }

  public getAuthAuditFilterData(userId: string, orgId: string): ListAuthRecordsRequestParams {
    const authAuditFilterData: ListAuthRecordsRequestParams = {
      org_id: orgId,
      user_id: userId,
    };
    const targetDates = setStartAndEndDatesForFilter(this.userAuditFilterForm);
    if (targetDates.startDate !== null) {
      authAuditFilterData.dt_from = targetDates.startDate.toISOString();
    }
    if (targetDates.endDate !== null) {
      authAuditFilterData.dt_to = targetDates.endDate.toISOString();
    }
    return authAuditFilterData;
  }

  private getUserInfo(): void {
    this.isSearching = true;
    this.state$.next(null);
  }

  /**
   * Triggers getting the user info when an action such as a button click
   * or enter keypress occurs.
   */
  public getUserInfoOnAction(): void {
    if (this.userSearchForm.invalid) {
      return;
    }
    this.getUserInfo();
  }

  public getUserNameAndEmailString(user: User): string {
    const userName = getUserNameFromUser(user);
    if (!!userName) {
      return `${userName}, ${user.email}`;
    }
    return user.email;
  }

  public downloadUserAuditData(data: Array<AuthAudits>): void {
    downloadDataToCsv(data, this.papa, this.renderer, 'user-audit-data');
  }

  public resetMfaPreferences(userToReset: User): void {
    // Reset the given users 2-factor enrollment preferences
    const body: ResetMFAChallengeMethod = {
      org_id: this.orgId,
    };
    const mrp: ResetUserMfaChallengeMethodsRequestParams = {
      user_id: userToReset.id,
      ResetMFAChallengeMethod: body,
    };
    this.usersService.resetUserMfaChallengeMethods(mrp).subscribe(
      (_) => {},
      (error) => {
        this.notificationService.error(`Unable to reset user ${userToReset.email} multi-factor-authentication`);
      }
    );
    this.getUserInfo();
  }

  private getLogsFilterData(user: CombinedUserDetail, orgId: string): ListLogsRequestParams {
    const logsFilterData: ListLogsRequestParams = {
      org_id: orgId,
      sub: user?.metadata?.id,
    };
    const targetDates = setStartAndEndDatesForFilter(this.userAuditFilterForm);
    if (targetDates.startDate !== null) {
      logsFilterData.dt_from = targetDates.startDate.toISOString();
    }
    if (targetDates.endDate !== null) {
      logsFilterData.dt_to = targetDates.endDate.toISOString();
    }
    return logsFilterData;
  }

  private getLogs(user: CombinedUserDetail, orgId: string): Observable<ListLogsResponse> {
    const logsFilterData = this.getLogsFilterData(user, orgId);
    return this.logsQueryService.listLogs(logsFilterData).pipe(
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the log data');
        return of(undefined);
      })
    );
  }

  public isUserMfaEnrollmentExpired(mfaEnrollmentExpiryDate: string): boolean {
    if (!mfaEnrollmentExpiryDate) {
      return false;
    }
    const convertedMfaEnrollmentExpiryDate = new Date(mfaEnrollmentExpiryDate);
    const currentDate = getCurrentDate();
    return convertedMfaEnrollmentExpiryDate.getTime() < currentDate.getTime();
  }

  public createUserMetadataRequest(userAuditState: UserAuditState): void {
    if (userAuditState.current_org.trust_on_first_use_duration === undefined) {
      this.notificationService.error(`Unable to set user multi-factor-authentication deadline. No organisation enrollment deadline set`);
      return;
    }
    const enrollmentExpiryString = getMfaEnrollmentExpiryDateString(userAuditState.current_org.trust_on_first_use_duration);
    const createUserMetadataRequestParams: CreateUserMetadataRequestParams = {
      UserMetadata: {
        spec: {
          user_id: userAuditState.user.id,
          org_id: userAuditState.current_org.id,
          name: 'mfa_enrollment_expiry_time',
          data: enrollmentExpiryString,
          data_type: UserMetadataSpec.DataTypeEnum.mfa_enrollment_expiry,
        },
      },
    };
    this.usersService.createUserMetadata(createUserMetadataRequestParams).subscribe(
      (resp) => {
        // OK
        userAuditState.userMfaEnrollmentMetadata = resp;
      },
      (err) => {
        this.notificationService.error(`Unable to set user ${userAuditState.user.email} multi-factor-authentication deadline`);
      }
    );
  }

  public resetMfaEnrollmentDeadline(userAuditState: UserAuditState): void {
    if (userAuditState.current_org.trust_on_first_use_duration === undefined) {
      this.notificationService.error(`Unable to set user multi-factor-authentication deadline. No organisation enrollment deadline set`);
      return;
    }
    const enrollmentExpiryString = getMfaEnrollmentExpiryDateString(userAuditState.current_org.trust_on_first_use_duration);

    if (!this.hasMfaEnrollmentDeadline(userAuditState.userMfaEnrollmentMetadata)) {
      this.createUserMetadataRequest(userAuditState);
    } else {
      userAuditState.userMfaEnrollmentMetadata.spec.data = enrollmentExpiryString;
      const replaceUserMetadataParams: ReplaceUserMetadataRequestParams = {
        metadata_id: userAuditState.userMfaEnrollmentMetadata.metadata.id,
        UserMetadata: userAuditState.userMfaEnrollmentMetadata,
      };
      this.usersService.replaceUserMetadata(replaceUserMetadataParams).subscribe(
        (resp) => {
          userAuditState.userMfaEnrollmentMetadata = resp;

          // OK
        },
        (error) => {
          this.notificationService.error(`Unable to reset user ${userAuditState.user.email} multi-factor-authentication deadline`);
        }
      );
    }
  }

  public getMfaEnrollmentDisplayValue(userMfaEnrollmentMetadata: UserMetadata): string {
    if (!this.hasMfaEnrollmentDeadline(userMfaEnrollmentMetadata)) {
      return 'None';
    }
    return convertDateToReadableFormat(new Date(userMfaEnrollmentMetadata?.spec?.data));
  }

  public hasMfaEnrollmentDeadline(userMfaEnrollmentMetadata: UserMetadata): boolean {
    return !!userMfaEnrollmentMetadata && !!userMfaEnrollmentMetadata.spec.data;
  }

  private getSupportedMfaMethods(orgId: string): Observable<Array<string>> {
    const listWellknownIssuerInfoParams: ListWellknownIssuerInfoRequestParams = {
      org_id: orgId,
    };
    return this.issuersService.listWellknownIssuerInfo(listWellknownIssuerInfoParams).pipe(
      map((listWellknownIssuerInfoResp) => {
        if (!!listWellknownIssuerInfoResp.well_known_info && listWellknownIssuerInfoResp.well_known_info.length !== 0) {
          return listWellknownIssuerInfoResp.well_known_info[0].supported_mfa_methods;
        }
        return [];
      }),
      catchError((_) => {
        this.notificationService.error('Failed to retrieve the supported multi-factor authentication data');
        return of([]);
      })
    );
  }

  private getMfaEnrollmentIconState(element: OptionalMFAChallengeMethodElement): IconState {
    if (!element || !element.enabled) {
      return {
        icon: '',
        color: IconColor.none,
        tooltip: '',
      };
    }
    if (element.supported_method) {
      return {
        icon: 'check_circle',
        color: IconColor.success,
        tooltip: 'This method is enabled',
      };
    }
    return {
      icon: 'offline_pin',
      color: IconColor.disabled,
      tooltip: 'This method is enabled for this user, but is not supported by this organisation',
    };
  }

  public onPanelOpen(panel: UserAuditPanel): void {
    this.panelsStateMap.set(panel, true);
  }

  public onPanelClose(panel: UserAuditPanel): void {
    this.panelsStateMap.set(panel, false);
  }

  public getPanelState(panel: UserAuditPanel): boolean {
    return this.panelsStateMap.get(panel);
  }

  public hideAppRow(row: ResourceAccessElement): boolean {
    if (this.showEmptyAppRows) {
      return false;
    }
    for (const orgName of this.appOrgNameList) {
      if (row[orgName] !== '') {
        return false;
      }
    }
    return true;
  }

  public hideResourceRow(row: ResourceAccessElement): boolean {
    if (this.isResourceTypeHidden(row)) {
      return true;
    }
    if (this.showEmptyResourceRows) {
      return false;
    }
    for (const orgName of this.resourceOrgNameList) {
      if (row[orgName] !== '') {
        return false;
      }
    }
    return true;
  }

  private isResourceTypeHidden(row: ResourceAccessElement): boolean {
    if (this.filteredResourceTypes.length === 0) {
      return false;
    }
    if (this.filteredResourceTypes.includes(row.resource_type)) {
      return false;
    }
    return true;
  }

  public triggerUpdate(): void {
    this.state$.next(null);
  }

  public toggleShowEmptyAppRows(checkboxOption: CheckboxOption): void {
    this.showEmptyAppRows = checkboxOption.isChecked;
    this.triggerUpdate();
  }

  public toggleShowEmptyResourceRows(checkboxOption: CheckboxOption): void {
    this.showEmptyResourceRows = checkboxOption.isChecked;
    this.triggerUpdate();
  }

  public toggleResourceTypes(checkboxOption: CheckboxOption): void {
    const isResourceTypeEnum = createEnumChecker(ResourceType);
    if (!isResourceTypeEnum(checkboxOption.name)) {
      return;
    }
    if (checkboxOption.isChecked) {
      this.filteredResourceTypes.push(checkboxOption.name);
    }
    if (!checkboxOption.isChecked) {
      removeStringFromList(checkboxOption.name, this.filteredResourceTypes);
    }
    this.triggerUpdate();
  }
}
