import { Environment } from '@agilicus/angular';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { getProxyLocationOptionData } from '../application-template-utils';

@Component({
  selector: 'portal-proxy-location-selection',
  templateUrl: './proxy-location-selection.component.html',
  styleUrls: ['./proxy-location-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProxyLocationSelectionComponent {
  @Input() public formCtrl: UntypedFormControl;
  @Input() public label = '';
  @Output() public handleProxyLocationOptionChange = new EventEmitter<any>();
  public proxyLocationOptionData = getProxyLocationOptionData();

  constructor() {}

  public onProxyLocationOptionChange(selectedProxyLocationValue: Environment.ProxyLocationEnum): void {
    this.handleProxyLocationOptionChange.emit(selectedProxyLocationValue);
  }
}
