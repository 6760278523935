import { Injectable, APP_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ActionApiApplicationsSetState,
  ApiApplicationsActionTypes,
  ActionApiApplicationsAppSaveFinished,
  ActionApiApplicationsCreatingNewAppCanceled,
  ActionApiApplicationsCreatingNewApp,
  ActionApiApplicationsUpdateAppId,
  ActionApiApplicationsRefreshAppState,
  ActionApiApplicationsUpdateCurrentApp,
  ActionApiApplicationsCreatingNewAppCanceledOnAppChanges,
  ActionApiApplicationsUpdateEnvName,
  ActionApiApplicationsRefreshEnvState,
  ActionApiApplicationsUpdateCurrentEnv,
  ActionApiApplicationsRefreshRuleState,
  ActionApiApplicationsUpdateCurrentRule,
  ActionApiApplicationsLoadApplications,
  ActionApiApplicationsInitApplications,
  ActionApiApplicationsSetEnvConfigVarList,
  ApiApplicationsEnvConfigWatchActions,
  ActionApiApplicationsSetEnvFileConfigList,
  ActionApiApplicationsDeletingEnvFileConfig,
  ActionApiApplicationsRemoveFromEnvFileConfigList,
  ActionApiApplicationsCreatingEnvFileConfig,
  ActionApiApplicationsAddToEnvFileConfigList,
  ActionApiApplicationsModifyingEnvFileConfig,
  ActionApiApplicationsUpdateEnvFileConfigList,
  ActionApiApplicationsFailedEnvFileConfigUpdate,
  ActionApiApplicationsLoadingEnvFileConfigs,
  ActionApiApplicationsModifyCurrentApp,
  ActionApiApplicationsSavingEnvConfigVarList,
  ApiApplicationsAppChangeActions,
  ActionApiApplicationsCreatingAppBundle,
  ActionApiApplicationsAddToAppBundleList,
  ActionApiApplicationsModifyingAppBundle,
  ActionApiApplicationsUpdateAppBundleList,
  ActionApiApplicationsDeletingAppBundle,
  ActionApiApplicationsRemoveFromAppBundleList,
  ActionApiApplicationsSetEnvConfigList,
  ActionApiApplicationsFailedEnvConfigLoad,
  ActionApiApplicationsUpdateEnvConfigList,
  ActionApiApplicationsDeletingEnvConfig,
  ActionApiApplicationsSavingEnvConfig,
  ActionApiApplicationsAddToEnvConfigList,
  ActionApiApplicationsRemoveFromEnvConfigList,
  ActionApiApplicationsSetRuleList,
  ActionApiApplicationsAddToRuleList,
  ActionApiApplicationsUpdateRuleList,
  ActionApiApplicationsRemoveFromRuleList,
  ActionApiApplicationsSetRoleToRuleEntriesList,
  ActionApiApplicationsFailedRoleToRuleEntriesLoad,
  ActionApiApplicationsRemoveFromRoleToRuleEntriesList,
  ActionApiApplicationsAddToRoleToRuleEntriesList,
  ActionApiApplicationsUpdateRoleToRuleEntriesList,
  ActionApiApplicationsSetRoleList,
  ActionApiApplicationsFailedRoleLoad,
  ActionApiApplicationsFailedRuleLoad,
  ActionApiApplicationsAddToRoleList,
  ActionApiApplicationsUpdateRoleList,
  ActionApiApplicationsRemoveFromRoleList,
  ActionApiApplicationsUpdateRuleId,
  ApiApplicationsLoadRoleToRuleEntriesActions,
  ActionApiApplicationsSavingAppIconFile,
  ActionApiApplicationsSuccessfulAppIconFileSave,
  ActionApiApplicationsFailedAppIconFileSave,
  ActionApiApplicationsUpdatingAppIconUrl,
  ActionApiApplicationsSuccessfulAppIconFileDeletion,
  ActionApiApplicationsFailedAppIconFileDeletion,
  ActionApiApplicationsSetAppFilesList,
  ActionApiApplicationsFailedAppFilesUpdate,
  ActionApiApplicationsRefreshAppFilesState,
  ActionApiApplicationsSavingRoleToRuleEntry,
  ActionApiApplicationsSavingRule,
  ActionApiApplicationsSavingRole,
  ActionApiApplicationsMaintainState,
  ActionApiApplicationsUnsetDefaultRoleFlag,
  ActionApiApplicationsDeletingAppIconUrl,
  ActionApiApplicationsAppDeleteFinished,
  ActionApiApplicationsEnvConfigVarSaveFinished,
  ActionApiApplicationsDeletingRoleToRuleEntries,
  ActionApiApplicationsSavingRoleToRuleEntries,
  ActionApiApplicationsRuleSaveFinished,
  ApiApplicationsChangeRulesList,
  ActionApiApplicationsDeletingRules,
  ActionApiApplicationsSetCurrentApplicationState,
} from './api-applications.actions';
import { map, catchError, mergeMap, concatMap, withLatestFrom, filter, switchMap } from 'rxjs/operators';
import {
  Application,
  _Application,
  ApplicationsService,
  FilesService,
  makeApplicationFileConfig,
  ApplicationFileConfig,
  FileConfigDeleteRequestArgs,
  FileConfigCreateRequestArgs,
  FileConfigUpdateRequestArgs,
  FileConfigUpdateMetadataRequestArgs,
  patch_via_put,
  EnvironmentConfigVarList,
  ListFilesResponse,
  AddFileRequestParams,
  ReplaceFileRequestParams,
  DeleteFileRequestParams,
  ListFilesRequestParams,
  ListConfigsRequestParams,
  ListConfigsResponse,
  EnvironmentConfig,
  AddConfigRequestParams,
  DeleteConfigRequestParams,
  ListRulesRequestParams,
  ListRules,
  AddRuleRequestParams,
  RuleV2,
  ListRoleToRuleEntriesRequestParams,
  ListRoleToRuleEntries,
  RoleToRuleEntry,
  AddRoleToRuleEntryRequestParams,
  ListRolesRequestParams,
  ListRoles,
  RoleV2,
  DeleteRoleRequestParams,
  FileSummary,
  Organisation,
  RuleConfig,
  PolicyTemplatesService,
  ResourceTypeEnum,
} from '@agilicus/angular';
import { of, EMPTY, from, Observable, forkJoin } from 'rxjs';

import { NotificationService, AppState } from '@app/core';
import { createBlankApplication, ApiApplicationsState } from './api-applications.models';
import {
  getRouterLinkFromPath,
  getAppFromId,
  getEnvFromName,
  getRuleFromId,
  saveAction,
  deleteAction,
  isApplicationExternal,
} from '@app/shared/components/utils';
import { getMergedRoute, MergedRouteNavigationAction, MergedRoute } from '../merged-route';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { RouterHelperService } from '../router-helper/router-helper.service';
import { selectApiOrgId, selectCurrentOrg } from '../user/user.selectors';
import { UserActionTypes, ActionUserSetCurrentOrg } from '../user/user.actions';
import { createRefreshOrgDependentEffect } from '../helpers/effect-factories';
import {
  selectApiApplications,
  selectApiCurrentApplication,
  selectApiApplicationsEnvConfigVarId,
  selectApiApplicationsEnvironmentConfigVarList,
  selectApiApplicationsShouldPopulateValue,
  selectApiApplicationsGetAllCurrentAppConfig,
} from './api-applications.selectors';
import { selectCanAdminApps, selectCanReadApps } from '../user/permissions/app.selectors';
import { OrgQualifiedPermission } from '../user/permissions/permissions.selectors';
import { AppBundle } from '../api/applications/app-bundle';
import { selectApiApplicationsState } from '../core.state';
import { cloneDeep } from 'lodash-es';
import {
  createNewRole,
  createRoleToRuleEntry,
  deleteRoleToRuleEntry,
  deleteRule,
  getDefaultRoleForPublishedApplication,
  makeEnvConfigVarGuid,
  updateExistingRoleToRuleEntry,
} from './api-applications-utils';
import { selectExpansionPanelsState, selectTabsState } from '../ui/ui.selectors';
import { ExpansionPanelsState, TabsState } from '../models/ui/ui-model';
import { ActionUIUpdateExpansionPanelsState, ActionUIUpdateTabsState } from '../ui/ui.actions';
import { ApplicationStateService } from '../state-services/application-state.service';
import { getCrudActionTypeName, getNewCrudStateObjGuid } from '../api/state-driven-crud/state-driven-crud';
import { EnvironmentConfigVarStateService } from '../state-services/environment-config-var-state.service';
import { CrudActions, ResetStateAction } from '../api/state-driven-crud/state-driven-crud.actions';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import * as ApplicationActions from './api-applications.actions';
import { getIconFilesFromList } from './api-applications.reducer';
import { AppDefineExpansionPanel, TabGroup } from '../ui/ui.models';
import { selectPolicyTemplateInstanceResourcesList } from '../policy-template-instance-state/policy-template-instance.selectors';
import {
  getNewApplicationEmptyPolicy,
  getTargetPolicyTemplateInstanceResource,
  PolicyTemplateInstanceResource,
} from '../api/policy-template-instance/policy-template-instance-utils';
import {
  createNewPolicyTemplateInstance$,
  getPolicyTemplateInstanceForResource$,
} from '../api/policy-template-instance/policy-template-instance-api-utils';
import * as PolicyActions from '../policy-template-instance-state/policy-template-instance.actions';

/**
 * The post response for a newly added File is currently returning the AppBundle with
 * a 'lock' property in error. We need to delete this property or it will cause errors
 * when modifying the file. In order to do so, we need to use this new type or we
 * encounter type safety errors that prevent us from deleting the 'lock' property.
 */
interface AppBundlePlus extends AppBundle {
  lock?: boolean;
}

@Injectable()
export class ApiApplicationsEffects {
  public app_file_cfg: ApplicationFileConfig;

  constructor(
    private actions$: Actions,
    private applicationsService: ApplicationsService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private router: Router,
    private routerHelperService: RouterHelperService,
    private files: FilesService,
    private applicationStateService: ApplicationStateService,
    private environmentConfigVarStateService: EnvironmentConfigVarStateService,
    private policyTemplatesService: PolicyTemplatesService
  ) {
    this.app_file_cfg = makeApplicationFileConfig(this.files, this.applicationsService);
  }

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnApplicationsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.loadApplications),
      map((action) => {
        return new ActionApiApplicationsSetState(action.objs, action.org_id, action.blankSlate);
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnApplicationSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.upsertApplication),
      map((action) => {
        return new ActionApiApplicationsAppSaveFinished(action.obj, action.refreshData);
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnApplicationDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.deleteApplication),
      map((action) => {
        return new ActionApiApplicationsAppDeleteFinished(action.obj, action.refreshData);
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnApplicationsDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.deleteApplications),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))),
      concatMap(([action, orgId]) => {
        const observablesList = [];
        for (const appId of action.ids) {
          observablesList.push(this.applicationsService.deleteApplication({ app_id: appId, org_id: orgId }));
        }
        return forkJoin(observablesList).pipe(
          map((_) => {
            return new ApplicationActions.ActionApiApplicationsAppDeletesFinished(action.ids, action.refreshData);
          })
        );
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnApplicationRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.refreshApplications),
      map((action) => {
        return new ActionApiApplicationsRefreshAppState();
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnEnvConfigVarSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.upsertEnvConfigVar),
      map((action) => {
        return new ActionApiApplicationsEnvConfigVarSaveFinished(action.obj);
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnEnvConfigVarLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.loadEnvConfigVars),
      map((action) => {
        return new ActionApiApplicationsSetEnvConfigVarList(action.objs[0]);
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnEnvConfigVarMaintain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.maintainApplications),
      map((action) => {
        return new ActionApiApplicationsMaintainState();
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnEnvConfigVarRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.refreshEnvConfigVars),
      map((action) => {
        return new ActionApiApplicationsRefreshAppState();
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnApplicationClear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.clearApplications),
      map((action) => {
        return new ApplicationActions.ActionApiApplicationsClearApplicationState();
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnApplicationMaintain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationActions.maintainApplications),
      map((action) => {
        return new ApplicationActions.ActionApiApplicationsMaintainState();
      })
    )
  );

  public refreshOrg$ = createRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    ActionApiApplicationsLoadApplications,
    selectApiApplicationsShouldPopulateValue
  );

  public initState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.INIT_APPLICATIONS),
      concatMap((action: ActionApiApplicationsInitApplications) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId)), this.store.pipe(select(selectApiApplications))))
      ),
      map(([action, orgId, state]) => {
        if (!orgId || (!!state.applications_org_id && orgId === state.applications_org_id && !action.force) || state.creating_new_app) {
          return new ActionApiApplicationsMaintainState();
        }
        this.applicationStateService.list(orgId, action.blankSlate);
        return new ActionApiApplicationsMaintainState();
      })
    )
  );

  // TODO: implement when converting application state to ngrx entity
  /*
  public initState$ = createLoadStateEffect<Application, ApplicationsService>(
    this.store,
    this.actions$,
    ApplicationActions.initApplications,
    ApplicationActions.getApplications,
    ApplicationActions.maintainApplications,
    selectApiApplications,
  );
  */

  public loadState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.LOAD_APPLICATIONS),
      concatMap((action: ActionApiApplicationsLoadApplications) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCanReadApps)), this.store.pipe(select(selectCurrentOrg))))
      ),
      map(([action, canRead, currentOrg]: [ActionApiApplicationsLoadApplications, OrgQualifiedPermission, Organisation]) => {
        if (!canRead.hasPermission || canRead.orgId !== action.org_id || currentOrg.id !== action.org_id) {
          return ApplicationActions.clearApplications();
        }
        this.applicationStateService.list(action.org_id, action.blankSlate);
        return new ActionApiApplicationsMaintainState();
      })
    )
  );

  public loadStateSideEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SET_APP_STATE),
      concatMap((action: ActionApiApplicationsSetState) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCanReadApps)), this.store.pipe(select(selectApiApplications))))
      ),
      concatMap(([action, canRead, appState]: [ActionApiApplicationsSetState, OrgQualifiedPermission, ApiApplicationsState]) => {
        let currentApp: Application;
        if (!canRead.hasPermission || action.applications.length === 0 || action.blankSlate) {
          currentApp = createBlankApplication();
        } else {
          currentApp = this.getCurrentAppIfExists(action.applications, appState.current_application);
        }
        return [
          new ActionApiApplicationsLoadingEnvFileConfigs(),
          new ActionApiApplicationsSetCurrentApplicationState(currentApp, action.org_id),
        ];
      })
    )
  );

  public modifyingApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.MODIFY_CURRENT_APP),
      concatMap((action: ActionApiApplicationsModifyCurrentApp) => {
        if (action.current_application.id === getNewCrudStateObjGuid()) {
          this.applicationStateService.create(getNewCrudStateObjGuid(), action.current_application, true, true);
        } else {
          this.applicationStateService.update(action.current_application.id, action.current_application, true, action.refreshData);
        }
        return of(new ActionApiApplicationsMaintainState());
      })
    )
  );

  public setApplicationDefaultRoleAndPublish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.ADD_TO_ROLE_LIST),
      concatMap((action: ActionApiApplicationsAddToRoleList) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplications))))
      ),
      concatMap(([action, appState]: [ActionApiApplicationsAddToRoleList, ApiApplicationsState]) => {
        if (!!appState.set_default_role_and_publish_application) {
          const updatedApplication = cloneDeep(appState.current_application);
          updatedApplication.default_role_id = action.api_obj.metadata.id;
          updatedApplication.published = Application.PublishedEnum.public;
          return of(new ActionApiApplicationsModifyCurrentApp(updatedApplication));
        }
        return of(new ActionApiApplicationsUnsetDefaultRoleFlag());
      })
    )
  );

  public navigateOnSavedApp$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApiApplicationsActionTypes.APP_SAVE_FINISHED),
        concatMap((action: ActionApiApplicationsAppSaveFinished) =>
          of(action).pipe(withLatestFrom(this.store.pipe(select(getMergedRoute))))
        ),
        mergeMap(([action, mergedRoute]) => {
          let currentLink = window.location.pathname;
          if (mergedRoute.url.startsWith('/application-define') && mergedRoute.params.appId === 'new') {
            currentLink = getRouterLinkFromPath() + '/' + action.current_application.id;
          }
          this.router.navigate([currentLink], {
            queryParams: { org_id: action.current_application.org_id },
            queryParamsHandling: 'merge',
          });
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  public addPolicyOnSavedApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.APP_SAVE_FINISHED),
      concatMap((action: ActionApiApplicationsAppSaveFinished) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplications))))
      ),
      concatMap(([action, appState]) => {
        if (!appState.use_policy_rules) {
          return of(new ActionApiApplicationsMaintainState());
        }
        const existingPolicyTemplateInstance$ = getPolicyTemplateInstanceForResource$(
          this.policyTemplatesService,
          action.current_application.org_id,
          action.current_application.id,
          ResourceTypeEnum.application
        );
        return existingPolicyTemplateInstance$.pipe(
          concatMap((existingPolicyTemplateInstanceResp) => {
            if (!!existingPolicyTemplateInstanceResp) {
              // Policy template already exists
              return of(new ActionApiApplicationsMaintainState());
            }
            const newPolicyTemplateInstance = getNewApplicationEmptyPolicy(action.current_application);
            return createNewPolicyTemplateInstance$(this.policyTemplatesService, newPolicyTemplateInstance).pipe(
              concatMap((newPolicyTemplateInstanceResp) => {
                // Add new policy to the Policy State
                return of(PolicyActions.upsertPolicyTemplateInstance({ obj: newPolicyTemplateInstanceResp, refreshData: true }));
              })
            );
          })
        );
      })
    )
  );

  public creatingNewAppCanceled$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApiApplicationsActionTypes.CREATING_NEW_APP_CANCELED),
        concatMap((action: ActionApiApplicationsCreatingNewAppCanceled) =>
          of(action).pipe(
            withLatestFrom(
              this.store.pipe(select(selectApiApplications)),
              this.store.pipe(select(selectApiOrgId)),
              this.store.pipe(select(getMergedRoute))
            )
          )
        ),
        mergeMap(([action, appState, orgId, mergedRoute]) => {
          const routerLink = getRouterLinkFromPath();
          if (!appState.current_application.id) {
            // No apps exist in the org, therefore we were creating
            // a new app.
            this.router.navigate([routerLink], {
              queryParams: { org_id: orgId },
            });
            return EMPTY;
          }
          if (mergedRoute.url.startsWith('/application-define') && mergedRoute.params.appId === 'new') {
            let appId = appState.current_application.id;
            if (!appId) {
              appId = '';
            }
            // Canceled creating a new app, so we must route to the existing application.
            const currentLink = getRouterLinkFromPath() + '/' + appId;
            this.router.navigate([currentLink], {
              queryParams: { org_id: orgId },
            });
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  public newAppSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATE_CURRENT_APP),
      concatMap((action: ActionApiApplicationsUpdateCurrentApp) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplications))))
      ),
      filter(([_, appState]) => appState.creating_new_app),
      map((_) => {
        return new ActionApiApplicationsCreatingNewAppCanceledOnAppChanges();
      })
    )
  );

  public updateAppIdFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter((action: MergedRouteNavigationAction) => this.doesRouteStartWithAppDefine(action)),
      map((action: MergedRouteNavigationAction) => {
        return new ActionApiApplicationsUpdateAppId(action.payload.routerState.params.appId);
      })
    )
  );

  public refreshOnAppIdChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATE_APP_ID),
      map((action: ActionApiApplicationsUpdateAppId) => {
        return new ActionApiApplicationsRefreshAppState();
      })
    )
  );

  public updateCurrentAppFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.REFRESH_APP_STATE),
      concatMap((action: ActionApiApplicationsRefreshAppState) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectApiApplications)),
            this.store.pipe(select(getMergedRoute)),
            this.store.pipe(select(selectApiOrgId))
          )
        )
      ),
      mergeMap(([action, appState, mergedRoute, orgId]) => {
        const selectedAppId = this.getSelectedApplicationId(appState, mergedRoute);
        return this.onSelectedAppIdChange(appState, mergedRoute, selectedAppId, orgId);
      })
    )
  );

  public newAppState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SET_APP_STATE),
      map((action: ActionApiApplicationsSetState) => {
        return new ActionApiApplicationsRefreshAppState();
      })
    )
  );

  public updateEnvNameFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter((action: MergedRouteNavigationAction) => this.doesRouteStartWithAppDefine(action)),
      map((action: MergedRouteNavigationAction) => {
        return new ActionApiApplicationsUpdateEnvName(action.payload.routerState.params.envName);
      })
    )
  );

  public refreshOnEnvNameChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATE_ENV_NAME),
      map((action: ActionApiApplicationsUpdateEnvName) => {
        return new ActionApiApplicationsRefreshEnvState();
      })
    )
  );

  public updateCurrentEnvFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.REFRESH_ENV_STATE),
      concatMap((action: ActionApiApplicationsRefreshEnvState) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplications)), this.store.pipe(select(getMergedRoute))))
      ),
      mergeMap(([action, appState, mergedRoute]) => {
        const selectedEnvName = this.getSelectedUrlParam(appState, mergedRoute, 'environment_name');
        return this.onSelectedEnvNameChange(appState, mergedRoute, selectedEnvName);
      })
    )
  );

  public updateCurrentEnvOnAppChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATE_CURRENT_APP),
      map((action: ActionApiApplicationsUpdateCurrentApp) => {
        return new ActionApiApplicationsRefreshEnvState();
      })
    )
  );

  public newEnvState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SET_APP_STATE),
      map((action: ActionApiApplicationsSetState) => {
        return new ActionApiApplicationsRefreshEnvState();
      })
    )
  );

  public orgSwitched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.SET_CURRENT_ORG),
      concatMap((action: ActionUserSetCurrentOrg) => {
        return of(action.orgId).pipe(withLatestFrom(this.store.select(selectApiApplications)));
      }),
      concatMap(([orgId, state]: [string, ApiApplicationsState]) => {
        if (!state.applications_org_id || state.applications_org_id === orgId) {
          return EMPTY;
        }
        return of(new ActionApiApplicationsLoadApplications(orgId));
      })
    )
  );

  public updateEnvConfigVars$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiApplicationsActionTypes.SET_CURRENT_APP_STATE,
        ApiApplicationsActionTypes.UPDATE_CURRENT_APP,
        ApiApplicationsActionTypes.UPDATE_CURRENT_ENV,
        ApiApplicationsActionTypes.RELOAD_APPLICATION
      ),
      concatMap((action: ApiApplicationsEnvConfigWatchActions) => {
        return of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanAdminApps)),
            this.store.select(selectApiApplications),
            this.store.select(selectApiOrgId),
            this.store.pipe(select(selectApiApplicationsGetAllCurrentAppConfig))
          )
        );
      }),
      filter(
        ([action, canAdminApps, apiAppState, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsEnvConfigWatchActions,
          OrgQualifiedPermission,
          ApiApplicationsState,
          string,
          boolean
        ]) => {
          // This can happen if we log in to an org with no permissions
          return !!apiAppState.current_application;
        }
      ),
      concatMap(
        ([action, canAdminApps, apiAppState, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsEnvConfigWatchActions,
          OrgQualifiedPermission,
          ApiApplicationsState,
          string,
          boolean
        ]) => {
          if (!getAllCurrentAppConfig) {
            return of(new ActionApiApplicationsMaintainState());
          }
          if (
            !canAdminApps.hasPermission ||
            !apiAppState.current_environment ||
            apiAppState.current_environment.maintenance_org_id !== orgId
          ) {
            // This is to prevent a 403 error from the api call if the
            // 'apiAppState.current_environment.maintenance_org_id' does not
            // match the current org the user is logged in under.
            const empty: EnvironmentConfigVarList = {
              configs: [],
            };
            return of(new ActionApiApplicationsSetEnvConfigVarList(empty));
          }
          this.environmentConfigVarStateService.get(
            makeEnvConfigVarGuid(apiAppState.current_application.id, apiAppState.current_environment.name),
            orgId,
            false
          );
          return of(new ActionApiApplicationsMaintainState());
        }
      )
    )
  );

  public updateEnvFileConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiApplicationsActionTypes.SET_CURRENT_APP_STATE,
        ApiApplicationsActionTypes.UPDATE_CURRENT_APP,
        ApiApplicationsActionTypes.UPDATE_CURRENT_ENV,
        ApiApplicationsActionTypes.RELOAD_APPLICATION
      ),
      concatMap((action: ApiApplicationsEnvConfigWatchActions) => {
        return of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanAdminApps)),
            this.store.select(selectApiApplications),
            this.store.select(selectApiOrgId),
            this.store.pipe(select(selectApiApplicationsGetAllCurrentAppConfig))
          )
        );
      }),
      filter(
        ([action, canAdminApps, apiAppState, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsEnvConfigWatchActions,
          OrgQualifiedPermission,
          ApiApplicationsState,
          string,
          boolean
        ]) => {
          // This can happen if we log in to an org with no permissions
          return !!apiAppState.current_application;
        }
      ),
      concatMap(
        ([action, canAdminApps, apiAppState, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsEnvConfigWatchActions,
          OrgQualifiedPermission,
          ApiApplicationsState,
          string,
          boolean
        ]) => {
          if (!getAllCurrentAppConfig) {
            return of(new ActionApiApplicationsMaintainState());
          }
          if (
            !canAdminApps.hasPermission ||
            !apiAppState.current_environment ||
            apiAppState.current_environment.maintenance_org_id !== orgId
          ) {
            // This is to prevent a 403 error from the api call if the
            // 'apiAppState.current_environment.maintenance_org_id' does not
            // match the current org the user is logged in under.
            return of(new ActionApiApplicationsSetEnvFileConfigList([]));
          }
          return from(
            this.app_file_cfg.list({
              app_id: apiAppState.current_application.id,
              org_id: apiAppState.current_environment.maintenance_org_id,
              env_name: apiAppState.current_environment.name,
            })
          ).pipe(
            map((envFileConfigs) => {
              return new ActionApiApplicationsSetEnvFileConfigList(envFileConfigs);
            }),
            catchError((_) => {
              this.notificationService.error('Failed to retrieve the file config list');
              return of(new ActionApiApplicationsFailedEnvFileConfigUpdate());
            })
          );
        }
      )
    )
  );

  public createEnvFileConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.CREATING_ENV_FILE_CONFIG),
      concatMap((action: ActionApiApplicationsCreatingEnvFileConfig) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectApiApplications), this.store.pipe(select(selectApiOrgId))));
      }),
      concatMap(([action, apiAppState, orgId]: [ActionApiApplicationsCreatingEnvFileConfig, ApiApplicationsState, string]) => {
        const args: FileConfigCreateRequestArgs = {
          app_id: apiAppState.current_application.id,
          org_id: orgId,
          env_name: apiAppState.current_environment.name,
          config: action.env_file_config_to_create,
          to_upload: action.blob_to_upload,
        };
        return from(this.app_file_cfg.create(args)).pipe(
          concatMap((createResp) => {
            this.notificationService.success('"' + action.env_file_config_to_create.path + '" was successfully uploaded!');
            return of(new ActionApiApplicationsAddToEnvFileConfigList(createResp));
          }),
          catchError((_) => {
            this.notificationService.error('Failed to upload file "' + action.env_file_config_to_create.path + '"');
            return EMPTY;
          })
        );
      })
    )
  );

  public modifyEnvFileConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.MODIFYING_ENV_FILE_CONFIG),
      concatMap((action: ActionApiApplicationsModifyingEnvFileConfig) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectApiApplications), this.store.pipe(select(selectApiOrgId))));
      }),
      concatMap(([action, apiAppState, orgId]: [ActionApiApplicationsModifyingEnvFileConfig, ApiApplicationsState, string]) => {
        if (action.blob_to_upload !== undefined) {
          return this.updateAndUploadFile(action, apiAppState, orgId);
        } else {
          return this.updateFileMetadata(action, apiAppState, orgId);
        }
      })
    )
  );

  public deleteEnvFileConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.DELETE_ENV_FILE_CONFIG),
      concatMap((action: ActionApiApplicationsDeletingEnvFileConfig) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectApiApplications), this.store.pipe(select(selectApiOrgId))));
      }),
      concatMap(([action, apiAppState, orgId]: [ActionApiApplicationsDeletingEnvFileConfig, ApiApplicationsState, string]) => {
        const args: FileConfigDeleteRequestArgs = {
          app_id: apiAppState.current_application.id,
          org_id: orgId,
          env_name: apiAppState.current_environment.name,
          config_id: action.env_file_config_to_delete.config_id,
        };
        return from(this.app_file_cfg.delete(args)).pipe(
          concatMap((_) => {
            this.notificationService.success('"' + action.env_file_config_to_delete.path + '" was successfully deleted!');
            return of(new ActionApiApplicationsRemoveFromEnvFileConfigList(action.env_file_config_to_delete));
          }),
          catchError((_) => {
            this.notificationService.error('Failed to delete file "' + action.env_file_config_to_delete.path + '"');
            return EMPTY;
          })
        );
      })
    )
  );

  public loadAppFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiApplicationsActionTypes.SET_CURRENT_APP_STATE,
        ApiApplicationsActionTypes.UPDATE_CURRENT_APP,
        ApiApplicationsActionTypes.RELOAD_APPLICATION,
        ApiApplicationsActionTypes.REFRESH_APP_FILES_STATE
      ),
      concatMap((action: ApiApplicationsAppChangeActions) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanAdminApps)),
            this.store.pipe(select(selectApiCurrentApplication)),
            this.store.pipe(select(selectApiOrgId)),
            this.store.pipe(select(selectApiApplicationsGetAllCurrentAppConfig))
          )
        )
      ),
      concatMap(
        ([action, canAdminApps, currentApp, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsAppChangeActions,
          OrgQualifiedPermission,
          Application,
          string,
          boolean
        ]) => {
          if (!getAllCurrentAppConfig) {
            return of(new ActionApiApplicationsMaintainState());
          }
          if (!canAdminApps.hasPermission || !currentApp || !currentApp.id || currentApp.id === getNewCrudStateObjGuid()) {
            return of(new ActionApiApplicationsSetAppFilesList([]));
          }
          const args: ListFilesRequestParams = { org_id: orgId, tag: currentApp.id };
          return this.files.listFiles(args).pipe(
            map((files: ListFilesResponse) => {
              return new ActionApiApplicationsSetAppFilesList(files.files);
            }),
            catchError((_) => {
              this.notificationService.error('Failed to retrieve the application files list');
              return of(new ActionApiApplicationsFailedAppFilesUpdate());
            })
          );
        }
      )
    )
  );

  public createAppBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.CREATING_APP_BUNDLE),
      concatMap((action: ActionApiApplicationsCreatingAppBundle) => {
        return of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectApiCurrentApplication)), this.store.pipe(select(selectApiOrgId)))
        );
      }),
      concatMap(([action, currentApp, orgId]: [ActionApiApplicationsCreatingAppBundle, Application, string]) => {
        const args: AddFileRequestParams = {
          name: action.app_bundle_to_create.name,
          file_zip: action.new_file,
          org_id: orgId,
          tag: currentApp.id,
          label: action.app_bundle_to_create.label,
        };
        return this.files.addFile(args).pipe(
          map((fileResp: AppBundlePlus) => {
            // The post response is currently returning the AppBundle with a 'lock' property
            // in error. Need to delete this property or it will cause errors when modifying the file.
            delete fileResp.lock;
            this.notificationService.success('Application bundle "' + fileResp.label + '" was successfully uploaded!');
            return new ActionApiApplicationsAddToAppBundleList(fileResp);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to upload application bundle "' + action.app_bundle_to_create.label + '"');
            return EMPTY;
          })
        );
      })
    )
  );

  public modifyAppBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.MODIFYING_APP_BUNDLE),
      concatMap((action: ActionApiApplicationsModifyingAppBundle) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))));
      }),
      concatMap(([action, orgId]: [ActionApiApplicationsModifyingAppBundle, string]) => {
        return this.updateAppBundle(action, orgId);
      })
    )
  );

  public deleteAppBundle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.DELETING_APP_BUNDLE),
      concatMap((action: ActionApiApplicationsDeletingAppBundle) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))));
      }),
      concatMap(([action, orgId]: [ActionApiApplicationsDeletingAppBundle, string]) => {
        const args: DeleteFileRequestParams = {
          file_id: action.app_bundle_to_delete.id,
          org_id: orgId,
        };
        return this.files.deleteFile(args).pipe(
          map((_) => {
            this.notificationService.success('"' + action.app_bundle_to_delete.label + '" was successfully deleted!');
            return new ActionApiApplicationsRemoveFromAppBundleList(action.app_bundle_to_delete);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to delete application bundle "' + action.app_bundle_to_delete.label + '"');
            return EMPTY;
          })
        );
      })
    )
  );

  public removeAppBundleFromEnvironmentOnDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.REMOVE_FROM_APP_BUNDLE_LIST),
      concatMap((action: ActionApiApplicationsRemoveFromAppBundleList) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplicationsState))));
      }),
      concatMap(([action, appState]: [ActionApiApplicationsRemoveFromAppBundleList, ApiApplicationsState]) => {
        const updatedCurrentApp: Application = cloneDeep(appState.current_application);
        this.removeBundleFromEnvironment(action.deleted_app_bundle, updatedCurrentApp);
        return of(new ActionApiApplicationsModifyCurrentApp(updatedCurrentApp, appState.set_default_role_and_publish_application));
      })
    )
  );

  public loadEnvConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiApplicationsActionTypes.SET_CURRENT_APP_STATE,
        ApiApplicationsActionTypes.UPDATE_CURRENT_APP,
        ApiApplicationsActionTypes.UPDATE_CURRENT_ENV,
        ApiApplicationsActionTypes.RELOAD_APPLICATION
      ),
      concatMap((action: ApiApplicationsEnvConfigWatchActions) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanAdminApps)),
            this.store.pipe(select(selectApiApplicationsState)),
            this.store.pipe(select(selectApiOrgId)),
            this.store.pipe(select(selectApiApplicationsGetAllCurrentAppConfig))
          )
        )
      ),
      concatMap(
        ([action, canAdminApps, apiAppState, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsEnvConfigWatchActions,
          OrgQualifiedPermission,
          ApiApplicationsState,
          string,
          boolean
        ]) => {
          if (!getAllCurrentAppConfig) {
            return of(new ActionApiApplicationsMaintainState());
          }
          if (
            !canAdminApps.hasPermission ||
            !apiAppState.current_application ||
            !apiAppState.current_application.id ||
            apiAppState.current_application.id === getNewCrudStateObjGuid() ||
            !apiAppState.current_environment
          ) {
            return of(new ActionApiApplicationsSetEnvConfigList([]));
          }
          if (apiAppState.current_environment.maintenance_org_id !== orgId) {
            // This is to prevent a 403 error from the api call if the
            // 'apiAppState.current_environment.maintenance_org_id' does not
            // match the current org the user is logged in under.
            return of(new ActionApiApplicationsSetEnvConfigList([]));
          }
          const args: ListConfigsRequestParams = {
            app_id: apiAppState.current_application.id,
            env_name: apiAppState.current_environment.name,
            maintenance_org_id: apiAppState.current_environment.maintenance_org_id,
          };
          return this.applicationsService.listConfigs(args).pipe(
            map((envConfigs: ListConfigsResponse) => {
              const filteredEnvConfigs = envConfigs.configs.filter(
                (envConfig) =>
                  envConfig.config_type === EnvironmentConfig.ConfigTypeEnum.mount_smb ||
                  envConfig.config_type === EnvironmentConfig.ConfigTypeEnum.mount_gcs ||
                  envConfig.config_type === EnvironmentConfig.ConfigTypeEnum.mount_tmpdir
              );
              return new ActionApiApplicationsSetEnvConfigList(filteredEnvConfigs);
            }),
            catchError((_) => {
              this.notificationService.error('Failed to retrieve the external mounts');
              return of(new ActionApiApplicationsFailedEnvConfigLoad());
            })
          );
        }
      )
    )
  );

  public savingEnvConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SAVING_ENV_CONFIG),
      concatMap((action: ActionApiApplicationsSavingEnvConfig) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplicationsState))));
      }),
      concatMap(([action, apiAppState]: [ActionApiApplicationsSavingEnvConfig, ApiApplicationsState]) => {
        if (!action.external_mount_to_create.id) {
          return this.postEnvConfig(action.external_mount_to_create, apiAppState);
        }
        return this.putEnvConfig(action.external_mount_to_create, apiAppState);
      })
    )
  );

  public deleteEnvConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.DELETING_ENV_CONFIG),
      concatMap((action: ActionApiApplicationsDeletingEnvConfig) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplicationsState))));
      }),
      concatMap(([action, apiAppState]: [ActionApiApplicationsDeletingEnvConfig, ApiApplicationsState]) => {
        const args: DeleteConfigRequestParams = {
          app_id: apiAppState.current_application.id,
          env_name: apiAppState.current_environment.name,
          env_config_id: action.external_mount_to_delete.id,
          maintenance_org_id: apiAppState.current_environment.maintenance_org_id,
        };
        return this.applicationsService.deleteConfig(args).pipe(
          map((_) => {
            this.notificationService.success(
              'External mount for  "' + action.external_mount_to_delete.mount_path + '" was successfully deleted!'
            );
            return new ActionApiApplicationsRemoveFromEnvConfigList(action.external_mount_to_delete);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to delete external mount for "' + action.external_mount_to_delete.mount_path + '"');
            return EMPTY;
          })
        );
      })
    )
  );

  public loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiApplicationsActionTypes.SET_CURRENT_APP_STATE,
        ApiApplicationsActionTypes.UPDATE_CURRENT_APP,
        ApiApplicationsActionTypes.RELOAD_APPLICATION
      ),
      concatMap((action: ApiApplicationsAppChangeActions) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanReadApps)),
            this.store.pipe(select(selectApiApplicationsState)),
            this.store.pipe(select(selectApiOrgId)),
            this.store.pipe(select(selectApiApplicationsGetAllCurrentAppConfig))
          )
        )
      ),
      concatMap(
        ([action, canRead, apiAppState, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsAppChangeActions,
          OrgQualifiedPermission,
          ApiApplicationsState,
          string,
          boolean
        ]) => {
          if (!getAllCurrentAppConfig) {
            return of(new ActionApiApplicationsMaintainState());
          }
          if (
            !canRead.hasPermission ||
            canRead.orgId !== action.org_id ||
            !apiAppState.current_application?.id ||
            apiAppState.current_application.id === getNewCrudStateObjGuid() ||
            apiAppState.creating_new_app
          ) {
            return of(new ActionApiApplicationsSetRoleList([]));
          }
          const args: ListRolesRequestParams = {
            app_id: apiAppState.current_application.id,
            org_id: orgId,
          };
          return this.applicationsService.listRoles(args).pipe(
            map((rolesResp: ListRoles) => {
              return new ActionApiApplicationsSetRoleList(rolesResp.roles);
            }),
            catchError((_) => {
              this.notificationService.error('Failed to retrieve the roles list');
              return of(new ActionApiApplicationsFailedRoleLoad());
            })
          );
        }
      )
    )
  );

  public savingRole$ = saveAction<RoleV2, ApiApplicationsState>(
    this.store,
    this.actions$,
    ApiApplicationsActionTypes.SAVING_ROLE,
    selectApiApplicationsState,
    this.postRole.bind(this),
    this.putRole.bind(this)
  );

  public deleteRole$ = deleteAction<RoleV2, ApiApplicationsState>(
    this.store,
    this.actions$,
    ApiApplicationsActionTypes.DELETING_ROLE,
    selectApiApplicationsState,
    this.deleteRole.bind(this)
  );

  public loadRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiApplicationsActionTypes.SET_CURRENT_APP_STATE,
        ApiApplicationsActionTypes.UPDATE_CURRENT_APP,
        ApiApplicationsActionTypes.RELOAD_APPLICATION
      ),
      concatMap((action: ApiApplicationsAppChangeActions) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanReadApps)),
            this.store.pipe(select(selectApiCurrentApplication)),
            this.store.pipe(select(selectApiOrgId)),
            this.store.pipe(select(selectApiApplicationsGetAllCurrentAppConfig)),
            this.store.pipe(select(selectPolicyTemplateInstanceResourcesList))
          )
        )
      ),
      concatMap(
        ([action, canRead, currentApp, orgId, getAllCurrentAppConfig, policyResourceList]: [
          ApiApplicationsAppChangeActions,
          OrgQualifiedPermission,
          Application,
          string,
          boolean,
          Array<PolicyTemplateInstanceResource>
        ]) => {
          if (!getAllCurrentAppConfig) {
            return of(new ActionApiApplicationsMaintainState());
          }
          if (
            !canRead.hasPermission ||
            canRead.orgId !== action.org_id ||
            !currentApp ||
            !currentApp.id ||
            currentApp.id === getNewCrudStateObjGuid()
          ) {
            return of(new ActionApiApplicationsSetRuleList([], policyResourceList));
          }
          const args: ListRulesRequestParams = {
            app_id: currentApp.id,
            org_id: orgId,
          };
          return this.applicationsService.listRules(args).pipe(
            map((rulesResp: ListRules) => {
              return new ActionApiApplicationsSetRuleList(rulesResp.rules, policyResourceList);
            }),
            catchError((_) => {
              this.notificationService.error('Failed to retrieve the rules list');
              return of(new ActionApiApplicationsFailedRuleLoad());
            })
          );
        }
      )
    )
  );

  public savingRule$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SAVING_RULE),
      concatMap((action: ActionApiApplicationsSavingRule) => {
        return of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectApiApplicationsState)), this.store.pipe(select(selectApiOrgId)))
        );
      }),
      concatMap(([action, state, orgId]: [ActionApiApplicationsSavingRule, ApiApplicationsState, string]) => {
        if (!action.api_obj.metadata) {
          return this.postRule(action.api_obj, state, action.assigned_roles);
        }
        return this.putRule(action.api_obj, state, orgId, action.assigned_roles, action.roles_to_delete);
      })
    )
  );

  public deleteRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.DELETING_RULES),
      concatMap((action: ActionApiApplicationsDeletingRules) => {
        return of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectApiApplicationsState)), this.store.pipe(select(selectApiOrgId)))
        );
      }),
      concatMap(([action, apiAppState, orgId]: [ActionApiApplicationsDeletingRules, ApiApplicationsState, string]) => {
        const filteredRulesToDelete = action.api_objs.filter((rule) => !!rule.metadata?.id);
        const observablesArray$: Array<Observable<void>> = [];
        for (const rule of filteredRulesToDelete) {
          observablesArray$.push(deleteRule(this.applicationsService, rule, apiAppState.current_application.id, orgId));
        }
        if (observablesArray$.length === 0) {
          return of(new ActionApiApplicationsRuleSaveFinished());
        }
        return forkJoin(observablesArray$).pipe(
          map((_) => {
            this.notificationService.success('Rules successfully deleted!');
            return new ActionApiApplicationsRemoveFromRuleList(filteredRulesToDelete, orgId);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to delete rules');
            return of(new ActionApiApplicationsRuleSaveFinished());
          })
        );
      })
    )
  );

  public onRulesDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.REMOVE_FROM_RULE_LIST),
      map((action: ActionApiApplicationsRemoveFromRuleList) => {
        return new ActionApiApplicationsRuleSaveFinished();
      })
    )
  );

  public loadRoleToRuleEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ApiApplicationsActionTypes.SET_CURRENT_APP_STATE,
        ApiApplicationsActionTypes.UPDATE_CURRENT_APP,
        ApiApplicationsActionTypes.RELOAD_APPLICATION,
        ApiApplicationsActionTypes.REMOVE_FROM_ROLE_LIST,
        ApiApplicationsActionTypes.REMOVE_FROM_RULE_LIST
      ),
      // Need to also reload when a role or rule is deleted.
      concatMap((action: ApiApplicationsLoadRoleToRuleEntriesActions) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanReadApps)),
            this.store.pipe(select(selectApiCurrentApplication)),
            this.store.pipe(select(selectApiOrgId)),
            this.store.pipe(select(selectApiApplicationsGetAllCurrentAppConfig))
          )
        )
      ),
      concatMap(
        ([action, canRead, currentApp, orgId, getAllCurrentAppConfig]: [
          ApiApplicationsLoadRoleToRuleEntriesActions,
          OrgQualifiedPermission,
          Application,
          string,
          boolean
        ]) => {
          if (!getAllCurrentAppConfig) {
            return of(new ActionApiApplicationsMaintainState());
          }
          if (
            !canRead.hasPermission ||
            canRead.orgId !== action.org_id ||
            !currentApp ||
            !currentApp.id ||
            currentApp.id === getNewCrudStateObjGuid()
          ) {
            return of(new ActionApiApplicationsSetRoleToRuleEntriesList([]));
          }
          const args: ListRoleToRuleEntriesRequestParams = {
            app_id: currentApp.id,
            org_id: orgId,
          };
          return this.applicationsService.listRoleToRuleEntries(args).pipe(
            map((roleToRuleEntriesResp: ListRoleToRuleEntries) => {
              return new ActionApiApplicationsSetRoleToRuleEntriesList(roleToRuleEntriesResp.role_to_rule_entries);
            }),
            catchError((_) => {
              this.notificationService.error('Failed to retrieve the role to rule entries list');
              return of(new ActionApiApplicationsFailedRoleToRuleEntriesLoad());
            })
          );
        }
      )
    )
  );

  public updateRoleToRuleEntriesOnRuleSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.ADD_TO_RULE_LIST, ApiApplicationsActionTypes.UPDATE_RULE_LIST),
      map((action: ApiApplicationsChangeRulesList) => {
        const newAssignedRoles = this.getNewAssignedRoles(action.api_obj, action.assigned_roles);
        if (newAssignedRoles.length !== 0) {
          return new ActionApiApplicationsSavingRoleToRuleEntries(newAssignedRoles, action.roles_to_delete);
        }
        if (action.roles_to_delete && action.roles_to_delete.length !== 0) {
          return new ActionApiApplicationsDeletingRoleToRuleEntries(action.roles_to_delete);
        }
        return new ActionApiApplicationsRuleSaveFinished();
      })
    )
  );

  public savingRoleToRuleEntry$ = saveAction<RoleToRuleEntry, ApiApplicationsState>(
    this.store,
    this.actions$,
    ApiApplicationsActionTypes.SAVING_ROLE_TO_RULE_ENTRY,
    selectApiApplicationsState,
    this.postRoleToRuleEntry.bind(this),
    this.putRoleToRuleEntry.bind(this)
  );

  public savingRoleToRuleEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SAVING_ROLE_TO_RULE_ENTRIES),
      concatMap((action: ActionApiApplicationsSavingRoleToRuleEntries) => {
        return of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectApiApplicationsState)), this.store.pipe(select(selectApiOrgId)))
        );
      }),
      concatMap(([action, apiAppState, orgId]: [ActionApiApplicationsSavingRoleToRuleEntries, ApiApplicationsState, string]) => {
        const observablesArray$: Array<Observable<RoleToRuleEntry>> = [];
        for (const roleToRuleEntry of action.api_objs) {
          if (!roleToRuleEntry.metadata?.id) {
            observablesArray$.push(createRoleToRuleEntry(this.applicationsService, roleToRuleEntry, apiAppState.current_application.id));
          } else {
            observablesArray$.push(
              updateExistingRoleToRuleEntry(this.applicationsService, roleToRuleEntry, apiAppState.current_application.id, orgId)
            );
          }
        }
        return forkJoin(observablesArray$).pipe(
          map((resp) => {
            this.notificationService.success('Role to rule entries successfully updated!');
            return new ActionApiApplicationsUpdateRoleToRuleEntriesList(resp, action.roles_to_delete);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to update role to rule entries');
            return of(new ActionApiApplicationsRuleSaveFinished());
          })
        );
      })
    )
  );

  public onRoleToRuleEntryUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATE_ROLE_TO_RULE_ENTRIES_LIST),
      map((action: ActionApiApplicationsUpdateRoleToRuleEntriesList) => {
        if (action.roles_to_delete) {
          return new ActionApiApplicationsDeletingRoleToRuleEntries(action.roles_to_delete);
        }
        return new ActionApiApplicationsRuleSaveFinished();
      })
    )
  );

  public deleteRoleToRuleEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.DELETING_ROLE_TO_RULE_ENTRIES),
      concatMap((action: ActionApiApplicationsDeletingRoleToRuleEntries) => {
        return of(action).pipe(
          withLatestFrom(this.store.pipe(select(selectApiApplicationsState)), this.store.pipe(select(selectApiOrgId)))
        );
      }),
      concatMap(([action, apiAppState, orgId]: [ActionApiApplicationsDeletingRoleToRuleEntries, ApiApplicationsState, string]) => {
        const observablesArray$: Array<Observable<void>> = [];
        for (const roleToRuleEntry of action.api_objs) {
          observablesArray$.push(
            deleteRoleToRuleEntry(this.applicationsService, roleToRuleEntry, apiAppState.current_application.id, orgId)
          );
        }
        if (observablesArray$.length === 0) {
          return of(new ActionApiApplicationsRuleSaveFinished());
        }
        return forkJoin(observablesArray$).pipe(
          map((_) => {
            this.notificationService.success('Role to rule entries successfully deleted!');
            return new ActionApiApplicationsRemoveFromRoleToRuleEntriesList(action.api_objs);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to delete role to rule entries');
            return of(new ActionApiApplicationsRuleSaveFinished());
          })
        );
      })
    )
  );

  public onRoleToRuleEntriesDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.REMOVE_FROM_ROLE_TO_RULE_ENTRIES_LIST),
      map((action: ActionApiApplicationsRemoveFromRoleToRuleEntriesList) => {
        return new ActionApiApplicationsRuleSaveFinished();
      })
    )
  );

  /**
   * When a rule save is finished we need to reset the state of the application,
   * since updating rules will modify the applicaiton object in the back-end.
   */
  public resetApplicationWhenRuleSaveFinished$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.RULE_SAVE_FINISHED),
      concatMap((action: ActionApiApplicationsRuleSaveFinished) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiCurrentApplication))));
      }),
      concatMap(([action, currentApplication]: [ActionApiApplicationsRuleSaveFinished, Application]) => {
        return of(
          new ResetStateAction<string>(
            getCrudActionTypeName(CrudActions.RESET_STATE, CrudStateCollection.applications),
            CrudStateCollection.applications,
            currentApplication.id
          )
        );
      })
    )
  );

  public updateRuleIdFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      filter((action: MergedRouteNavigationAction) => this.doesRouteStartWithAppDefine(action)),
      map((action: MergedRouteNavigationAction) => {
        return new ActionApiApplicationsUpdateRuleId(action.payload.routerState.params.ruleId);
      })
    )
  );

  public refreshOnRuleIdChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATE_RULE_ID),
      map((action: ActionApiApplicationsUpdateRuleId) => {
        return new ActionApiApplicationsRefreshRuleState();
      })
    )
  );

  public updateCurrentRuleFromUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.REFRESH_RULE_STATE),
      concatMap((action: ActionApiApplicationsRefreshRuleState) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectApiApplications)),
            this.store.pipe(select(getMergedRoute)),
            this.store.pipe(select(selectPolicyTemplateInstanceResourcesList))
          )
        )
      ),
      mergeMap(([action, appState, mergedRoute, policyResourceList]) => {
        const selectedRuleId = this.getSelectedUrlParam(appState, mergedRoute, 'rule_id');
        return this.onSelectedRuleIdChange(appState, mergedRoute, selectedRuleId, policyResourceList);
      })
    )
  );

  public savingEnvConfigVarList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SAVING_ENV_CONFIG_VAR_LIST),
      concatMap((action: ActionApiApplicationsSavingEnvConfigVarList) => {
        return of(action).pipe(withLatestFrom(this.store.select(selectApiApplications)));
      }),
      concatMap(([action, apiAppState]: [ActionApiApplicationsSavingEnvConfigVarList, ApiApplicationsState]) => {
        const envConfigVarGuid = makeEnvConfigVarGuid(apiAppState.current_application.id, apiAppState.current_environment.name);
        this.environmentConfigVarStateService.update(envConfigVarGuid, action.current_environment_config_var_list, true);
        return of(new ActionApiApplicationsMaintainState());
      })
    )
  );

  public saveAppIconFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SAVING_APP_ICON_FILE),
      concatMap((action: ActionApiApplicationsSavingAppIconFile) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId)), this.store.select(selectApiApplications)))
      ),
      concatMap(([action, orgId, apiAppState]: [ActionApiApplicationsSavingAppIconFile, string, ApiApplicationsState]) => {
        const addFileParams: AddFileRequestParams = {
          name: action.new_file.name,
          file_zip: action.new_file,
          org_id: orgId,
          tag: apiAppState.current_application.id,
          label: 'app_icon',
          visibility: 'public',
        };
        return this.files.addFile(addFileParams).pipe(
          map((fileResp: FileSummary) => {
            this.notificationService.success('Icon "' + fileResp.name + '" was successfully uploaded!');
            return new ActionApiApplicationsSuccessfulAppIconFileSave(fileResp);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to save new icon "' + action.new_file.name + '". Please try again.');
            return of(new ActionApiApplicationsFailedAppIconFileSave());
          })
        );
      })
    )
  );

  public updateAppIconUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_APP_ICON_FILE_SAVE),
      switchMap((action: ActionApiApplicationsSuccessfulAppIconFileSave) => {
        return of(new ActionApiApplicationsUpdatingAppIconUrl(action.new_app_icon_file));
      })
    )
  );

  public saveAppIconUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATING_APP_ICON_URL),
      concatMap((action: ActionApiApplicationsUpdatingAppIconUrl) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplications))))
      ),
      concatMap(([action, appState]: [ActionApiApplicationsUpdatingAppIconUrl, ApiApplicationsState]) => {
        const currentAppCopy = cloneDeep(appState.current_application);
        currentAppCopy.icon_url = action.new_app_icon_file.public_url;
        return of(new ActionApiApplicationsModifyCurrentApp(currentAppCopy, appState.set_default_role_and_publish_application, true));
      })
    )
  );

  public deleteAppIconUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.DELETING_APP_ICON_URL),
      concatMap((action: ActionApiApplicationsDeletingAppIconUrl) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplications))))
      ),
      concatMap(([action, appState]: [ActionApiApplicationsDeletingAppIconUrl, ApiApplicationsState]) => {
        const currentAppCopy = cloneDeep(appState.current_application);
        currentAppCopy.icon_url = '';
        return of(new ActionApiApplicationsModifyCurrentApp(currentAppCopy, appState.set_default_role_and_publish_application));
      })
    )
  );

  public deletingAppIconFilesOnSavedApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.APP_SAVE_FINISHED),
      concatMap((action: ActionApiApplicationsAppSaveFinished) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))),
      concatMap(([action, orgId]: [ActionApiApplicationsAppSaveFinished, string]) => {
        const args: ListFilesRequestParams = { org_id: orgId, tag: action.current_application.id };
        return this.files.listFiles(args).pipe(
          concatMap((resp) => {
            return of(resp).pipe(withLatestFrom(of(action), of(orgId)));
          })
        );
      }),
      concatMap(([filesListResp, action, orgId]) => {
        const iconFiles = getIconFilesFromList(filesListResp.files);
        const iconFilesToDelete = iconFiles.filter((icon_file) => icon_file.public_url !== action.current_application.icon_url);
        const deleteFilesObservablesArray = [];
        for (const file of iconFilesToDelete) {
          deleteFilesObservablesArray.push(this.files.deleteFile({ file_id: file.id, org_id: orgId }));
        }
        if (deleteFilesObservablesArray.length === 0) {
          return of(new ActionApiApplicationsMaintainState());
        }
        return forkJoin(deleteFilesObservablesArray).pipe(
          map((_) => {
            return new ActionApiApplicationsSuccessfulAppIconFileDeletion();
          }),
          catchError((_) => {
            this.notificationService.error('Failed to delete the previous application icons.');
            return of(new ActionApiApplicationsFailedAppIconFileDeletion());
          })
        );
      })
    )
  );

  public refreshAppIconFilesOnFailedDelete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.FAILED_APP_ICON_FILE_DELETION),
      concatMap((action: ActionApiApplicationsFailedAppIconFileDeletion) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
      ),
      map(([action, orgId]: [ActionApiApplicationsFailedAppIconFileDeletion, string]) => {
        return new ActionApiApplicationsRefreshAppFilesState(orgId);
      })
    )
  );

  public createAndSetDefaultRoleForNewPublishedApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.APP_SAVE_FINISHED),
      concatMap((action: ActionApiApplicationsAppSaveFinished) =>
        of(action).pipe(withLatestFrom(this.store.select(selectApiApplications)))
      ),
      concatMap(([action, appState]: [ActionApiApplicationsAppSaveFinished, ApiApplicationsState]) => {
        if (!!appState.set_default_role_and_publish_application) {
          const defaultRole = getDefaultRoleForPublishedApplication(action.current_application, action.current_application.org_id);
          return of(new ActionApiApplicationsSavingRole(defaultRole, true));
        }
        return of(new ActionApiApplicationsUnsetDefaultRoleFlag());
      })
    )
  );

  public updateExpansionPanelUIStateOnAppChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.UPDATE_CURRENT_APP),
      concatMap((action: ActionApiApplicationsUpdateCurrentApp) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectExpansionPanelsState))))
      ),
      mergeMap(([action, expansionPanelsState]: [ActionApiApplicationsUpdateCurrentApp, ExpansionPanelsState]) => {
        if (!isApplicationExternal(action.current_application)) {
          return EMPTY;
        }
        const expansionPanelsStateCopy = cloneDeep(expansionPanelsState);
        this.closePanelsForExternalApp(expansionPanelsStateCopy);
        return of(new ActionUIUpdateExpansionPanelsState(expansionPanelsStateCopy));
      })
    )
  );

  public updateTabUIStateOnNewAppCreate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.CREATING_NEW_APP),
      concatMap((action: ActionApiApplicationsCreatingNewApp) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectTabsState))))),
      mergeMap(([action, uiTabState]: [ActionApiApplicationsCreatingNewApp, TabsState]) => {
        const uiTabStateCopy = cloneDeep(uiTabState);
        // We want to land on the first tab when creating a new app
        uiTabStateCopy.tabs[TabGroup.appDefineTabGroup] = 0;
        return of(new ActionUIUpdateTabsState(uiTabStateCopy));
      })
    )
  );

  public setApplicationCrudState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SET_APP_STATE, ApiApplicationsActionTypes.RELOAD_APPLICATION),
      concatMap((action: ApiApplicationsAppChangeActions) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplicationsState))))
      ),
      map(([action, apiAppState]: [ApiApplicationsAppChangeActions, ApiApplicationsState]) => {
        if (!!apiAppState?.applications) {
          for (const app of apiAppState.applications) {
            this.applicationStateService.set(app.id, app);
          }
        }
        return new ActionApiApplicationsMaintainState();
      })
    )
  );

  public setEnvConfigVarCrudState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SET_ENV_CONFIG_VAR_LIST),
      concatMap((action: ActionApiApplicationsSetEnvConfigVarList) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectApiApplicationsEnvConfigVarId)),
            this.store.pipe(select(selectApiApplicationsEnvironmentConfigVarList))
          )
        )
      ),
      map(
        ([action, envConfigVarGuid, currentEnvConfigVarList]: [
          ActionApiApplicationsSetEnvConfigVarList,
          string,
          EnvironmentConfigVarList
        ]) => {
          if (!!envConfigVarGuid) {
            this.environmentConfigVarStateService.set(envConfigVarGuid, currentEnvConfigVarList);
          }
          return new ActionApiApplicationsMaintainState();
        }
      )
    )
  );

  private getSelectedApplicationId(appState: ApiApplicationsState, mergedRoute: MergedRoute): string | undefined | null {
    // appState.applications_org_id is the org_id used to retrieve
    // the applications list from the api.
    if (mergedRoute.queryParams.org_id !== appState.applications_org_id) {
      // The application state has not yet been updated with
      // the correct org from the url.
      return null;
    }
    if (!mergedRoute.url.startsWith('/application-define')) {
      return null;
    }
    if (appState.application_id === undefined) {
      return undefined;
    }
    return appState.application_id;
  }

  private onSelectedAppIdChange(
    appState: ApiApplicationsState,
    mergedRoute: MergedRoute,
    selectedAppId: string,
    orgId: string
  ): Observable<ActionApiApplicationsCreatingNewApp> {
    if (selectedAppId === null) {
      // If null, we either have not yet logged in to the target org,
      // the appState has not been updated with the correct org
      // or we have navigated away from the application-define screen.
      return EMPTY;
    }
    if (selectedAppId === 'new') {
      // Creating a new application.
      if (!appState.creating_new_app) {
        // Return the action if we are not yet creating the new app.
        return of(new ActionApiApplicationsCreatingNewApp());
      }
      return EMPTY;
    }
    if (selectedAppId === undefined) {
      // No app id has been provided in the url.
      this.handleUndefinedUrlAppId(appState, mergedRoute, orgId);
      return EMPTY;
    }
    this.handleUrlAppIdUpdate(appState, mergedRoute, selectedAppId, orgId);
    return EMPTY;
  }

  /**
   * Will reroute to the application-overview screen
   * and notify the user of the url error.
   */
  private rerouteToApplicationOverviewOnUrlError(mergedRoute: MergedRoute, errorMessage: string): void {
    this.routerHelperService.redirect('application-overview', {
      org_id: mergedRoute.queryParams.org_id,
    });
    this.notificationService.error(errorMessage);
  }

  private createAppIdErrorMessage(mergedRoute: MergedRoute, selectedAppId: string): string {
    return 'Application with id: "' + selectedAppId + '" does not exist in organisation with id: "' + mergedRoute.queryParams.org_id + '".';
  }

  /**
   * Handles when the appId exists in the url and has been changed
   * from the previous value, but is not set to 'new'.
   */
  public handleUrlAppIdUpdate(appState: ApiApplicationsState, mergedRoute: MergedRoute, selectedAppId: string, orgId: string): void {
    if (selectedAppId === 'new' || selectedAppId === undefined || selectedAppId === null) {
      return;
    }
    const targetApp = getAppFromId(selectedAppId, appState.applications);
    if (targetApp === undefined) {
      // Application does not exist in this org,
      // so we route to the app overview screen.
      const errorMessage = this.createAppIdErrorMessage(mergedRoute, selectedAppId);
      this.rerouteToApplicationOverviewOnUrlError(mergedRoute, errorMessage);
      return;
    }
    if (selectedAppId !== appState.current_application.id) {
      this.store.dispatch(new ActionApiApplicationsLoadingEnvFileConfigs());
      this.store.dispatch(new ActionApiApplicationsUpdateCurrentApp(targetApp, orgId));
    }
  }

  /**
   * Handles when we navigate to application-define,
   * but no app id has been provided in the url.
   */
  public handleUndefinedUrlAppId(appState: ApiApplicationsState, mergedRoute: MergedRoute, orgId: string): void {
    if (!mergedRoute.url.startsWith('/application-define') || appState.applications.length === 0) {
      return;
    }
    let appId = appState.current_application.id ? appState.current_application.id : '';
    if (appId === 'new') {
      appId = appState.applications[0].id;
    }
    // If app list is populated, set the url to the current app.
    this.routerHelperService.redirect('application-define/' + appId, {
      org_id: mergedRoute.queryParams.org_id,
    });
  }

  private doesAppStateMatchUrl(appState: ApiApplicationsState, mergedRoute: MergedRoute, targetValue: string): boolean {
    // appState.applications_org_id is the org_id used to retrieve
    // the applications list from the api.
    if (mergedRoute.queryParams.org_id !== appState.applications_org_id) {
      // The application state has not yet been updated with
      // the correct org from the url.
      return false;
    }
    if (!mergedRoute.url.startsWith('/application-define')) {
      return false;
    }
    if (appState[targetValue] === undefined) {
      return false;
    }
    if (mergedRoute.params.appId !== appState.current_application.id) {
      // Correct application not yet selected.
      return false;
    }
    return true;
  }

  private getSelectedUrlParam(appState: ApiApplicationsState, mergedRoute: MergedRoute, targetParam: string): null | string {
    if (!this.doesAppStateMatchUrl(appState, mergedRoute, targetParam)) {
      return null;
    }
    return appState[targetParam];
  }

  private onSelectedEnvNameChange(
    appState: ApiApplicationsState,
    mergedRoute: MergedRoute,
    selectedEnvName: string
  ): Observable<ActionApiApplicationsLoadingEnvFileConfigs | ActionApiApplicationsUpdateCurrentEnv> {
    if (selectedEnvName === null) {
      // If null, we either have not yet logged in to the target org,
      // the appState has not been updated with the correct org,
      // we have navigated away from the application-define screen,
      // no env name has been provided in the url or
      // we have not yet navigated to the correct app from the url.
      return EMPTY;
    }
    return this.handleUrlEnvNameUpdate(appState, mergedRoute, selectedEnvName);
  }

  private createEnvNameErrorMessage(mergedRoute: MergedRoute, selectedEnvName: string): string {
    return 'Instance with name: "' + selectedEnvName + '" does not exist in application with id: "' + mergedRoute.params.appId + '".';
  }

  /**
   * Will reroute to the application-define screen
   * and notify the user of the url error.
   */
  private rerouteToDefineOnUrlError(mergedRoute: MergedRoute, errorMessage: string): void {
    this.routerHelperService.redirect('application-define/' + mergedRoute.params.appId, { org_id: mergedRoute.queryParams.org_id });
    this.notificationService.error(errorMessage);
  }

  private handleUrlEnvNameUpdate(
    appState: ApiApplicationsState,
    mergedRoute: MergedRoute,
    selectedEnvName: string
  ): Observable<ActionApiApplicationsLoadingEnvFileConfigs | ActionApiApplicationsUpdateCurrentEnv> {
    const targetEnv = getEnvFromName(selectedEnvName, appState.current_application);
    if (targetEnv === undefined) {
      // Environment does not exist in this application,
      // so we route to the app define screen.
      const errorMessage = this.createEnvNameErrorMessage(mergedRoute, selectedEnvName);
      this.rerouteToDefineOnUrlError(mergedRoute, errorMessage);
      return EMPTY;
    }
    if (appState.current_environment === undefined || selectedEnvName !== appState.current_environment.name) {
      return of(new ActionApiApplicationsLoadingEnvFileConfigs(), new ActionApiApplicationsUpdateCurrentEnv(targetEnv));
    }
    return EMPTY;
  }

  /**
   * Checks if the navigation url starts with '/application-define'.
   */
  private doesRouteStartWithAppDefine(action: MergedRouteNavigationAction): boolean {
    const url = action.payload.routerState.url;
    if (url.startsWith('/application-define')) {
      return true;
    }
    return false;
  }

  private updateAndUploadFile(
    action: ActionApiApplicationsModifyingEnvFileConfig,
    apiAppState: ApiApplicationsState,
    orgId: string
  ): Observable<ActionApiApplicationsUpdateEnvFileConfigList> {
    const args: FileConfigUpdateRequestArgs = {
      app_id: apiAppState.current_application.id,
      org_id: orgId,
      env_name: apiAppState.current_environment.name,
      config: action.env_file_config_to_modify,
      to_upload: action.blob_to_upload,
    };
    return from(this.app_file_cfg.update(args)).pipe(
      concatMap((updateResp) => {
        this.notificationService.success('"' + action.env_file_config_to_modify.path + '" was successfully updated!');
        return of(new ActionApiApplicationsUpdateEnvFileConfigList(updateResp));
      }),
      catchError((_) => {
        this.notificationService.error('Failed to update file "' + action.env_file_config_to_modify.path + '"');
        return EMPTY;
      })
    );
  }

  private updateFileMetadata(
    action: ActionApiApplicationsModifyingEnvFileConfig,
    apiAppState: ApiApplicationsState,
    orgId: string
  ): Observable<ActionApiApplicationsUpdateEnvFileConfigList> {
    const args: FileConfigUpdateMetadataRequestArgs = {
      app_id: apiAppState.current_application.id,
      org_id: orgId,
      env_name: apiAppState.current_environment.name,
      config: action.env_file_config_to_modify,
    };
    return from(this.app_file_cfg.updateMetadata(args)).pipe(
      concatMap((updateResp) => {
        this.notificationService.success('"' + action.env_file_config_to_modify.path + '" was successfully updated!');
        return of(new ActionApiApplicationsUpdateEnvFileConfigList(updateResp));
      }),
      catchError((_) => {
        this.notificationService.error('Failed to update file "' + action.env_file_config_to_modify.path + '"');
        return EMPTY;
      })
    );
  }

  private updateAppBundle(
    action: ActionApiApplicationsModifyingAppBundle,
    orgId: string
  ): Observable<ActionApiApplicationsUpdateAppBundleList> {
    const args: ReplaceFileRequestParams = {
      file_id: action.app_bundle_to_modify.id,
      ModelFile: action.app_bundle_to_modify,
      org_id: orgId,
    };
    return from(this.files.replaceFile(args)).pipe(
      concatMap((updateResp) => {
        this.notificationService.success('"' + action.app_bundle_to_modify.label + '" was successfully updated!');
        return of(new ActionApiApplicationsUpdateAppBundleList(updateResp));
      }),
      catchError((_) => {
        this.notificationService.error('Failed to update application bundle "' + action.app_bundle_to_modify.label + '"');
        return EMPTY;
      })
    );
  }

  /**
   * Resets an environments serverless_image property to an empty string
   * if the associated app bundle file has been deleted.
   */
  private removeBundleFromEnvironment(deletedAppBundle: AppBundle, currentApp: Application): void {
    for (const env of currentApp.environments) {
      if (env.serverless_image === deletedAppBundle.id) {
        env.serverless_image = '';
      }
    }
  }

  private postEnvConfig(
    envConfigToCreate: EnvironmentConfig,
    apiAppState: ApiApplicationsState
  ): Observable<ActionApiApplicationsAddToEnvConfigList> {
    const args: AddConfigRequestParams = {
      app_id: apiAppState.current_application.id,
      env_name: apiAppState.current_environment.name,
      EnvironmentConfig: envConfigToCreate,
    };
    return this.applicationsService.addConfig(args).pipe(
      map((envConfigResp: EnvironmentConfig) => {
        this.notificationService.success('External mount for "' + envConfigResp.mount_path + '" was successfully configured!');
        return new ActionApiApplicationsAddToEnvConfigList(envConfigResp);
      }),
      catchError((_) => {
        this.notificationService.error('Failed to configure external mount for "' + envConfigToCreate.mount_path + '"');
        return EMPTY;
      })
    );
  }

  private putEnvConfig(
    envConfigToModify: EnvironmentConfig,
    apiAppState: ApiApplicationsState
  ): Observable<ActionApiApplicationsUpdateEnvConfigList> {
    const putter = (envConfig: EnvironmentConfig) => {
      return this.applicationsService.replaceConfig({
        app_id: apiAppState.current_application.id,
        env_name: apiAppState.current_environment.name,
        env_config_id: envConfig.id,
        EnvironmentConfig: envConfig,
      });
    };
    const getter = (envConfig: EnvironmentConfig) => {
      return this.applicationsService.getConfig({
        app_id: apiAppState.current_application.id,
        env_name: apiAppState.current_environment.name,
        env_config_id: envConfig.id,
        maintenance_org_id: envConfig.maintenance_org_id,
      });
    };
    const putEnvConfig$ = patch_via_put(envConfigToModify, getter, putter);
    return putEnvConfig$.pipe(
      concatMap((updateResp) => {
        this.notificationService.success('External mount for "' + envConfigToModify.mount_path + '" was successfully updated!');
        return of(new ActionApiApplicationsUpdateEnvConfigList(updateResp));
      }),
      catchError((_) => {
        this.notificationService.error('Failed to update external mount for "' + envConfigToModify.mount_path + '"');
        return EMPTY;
      })
    );
  }

  private postRole(
    action: ActionApiApplicationsSavingRole,
    apiAppState: ApiApplicationsState
  ): Observable<ActionApiApplicationsAddToRoleList | ActionApiApplicationsUnsetDefaultRoleFlag> {
    return createNewRole(this.applicationsService, action.api_obj, apiAppState.current_application).pipe(
      concatMap((postRoleResp) => {
        this.notificationService.success(`Role "${postRoleResp.spec.name}" was successfully created!`);
        return of(new ActionApiApplicationsAddToRoleList(postRoleResp));
      }),
      catchError((_) => {
        this.notificationService.error(`Failed to create role "${action.api_obj.spec.name}"`);
        return of(new ActionApiApplicationsUnsetDefaultRoleFlag());
      })
    );
  }

  private putRole(roleToModify: RoleV2, apiAppState: ApiApplicationsState, orgId: string): Observable<ActionApiApplicationsUpdateRoleList> {
    const previousRoleId = roleToModify.metadata.id;
    const getter = (role: RoleV2) => {
      return this.applicationsService.getRole({
        app_id: apiAppState.current_application.id,
        role_id: role.metadata.id,
        org_id: orgId,
      });
    };
    const putter = (role: RoleV2) => {
      return this.applicationsService.replaceRole({
        app_id: apiAppState.current_application.id,
        role_id: role.metadata.id,
        RoleV2: role,
      });
    };
    const putRole$ = patch_via_put(roleToModify, getter, putter);
    return putRole$.pipe(
      concatMap((updateResp) => {
        this.notificationService.success(`Role "${updateResp.spec.name}" was successfully updated!`);
        return of(new ActionApiApplicationsUpdateRoleList(updateResp, previousRoleId));
      }),
      catchError((_) => {
        this.notificationService.error(`Failed to update role "${roleToModify.spec.name}"`);
        return EMPTY;
      })
    );
  }

  private deleteRole(
    roleToDelete: RoleV2,
    apiAppState: ApiApplicationsState,
    orgId: string
  ): Observable<ActionApiApplicationsRemoveFromRoleList> {
    const args: DeleteRoleRequestParams = {
      app_id: apiAppState.current_application.id,
      role_id: roleToDelete.metadata.id,
      org_id: orgId,
    };
    return this.applicationsService.deleteRole(args).pipe(
      map((_) => {
        this.notificationService.success('Role "' + roleToDelete.spec.name + '" was successfully deleted!');
        return new ActionApiApplicationsRemoveFromRoleList(roleToDelete, orgId);
      }),
      catchError((_) => {
        this.notificationService.error('Failed to delete role "' + roleToDelete.spec.name + '"');
        return EMPTY;
      })
    );
  }

  private getNewAssignedRoles(rule: RuleV2, currentAssignedRoles: Array<RoleToRuleEntry>): Array<RoleToRuleEntry> {
    if (!currentAssignedRoles) {
      return [];
    }
    const currentAssignedRolesCopy: Array<RoleToRuleEntry> = cloneDeep(currentAssignedRoles);
    const newAssignedRoles: Array<RoleToRuleEntry> = [];
    for (const roleAssignment of currentAssignedRolesCopy) {
      if (!roleAssignment.metadata) {
        // Need to add new role_to_rule_entry.
        roleAssignment.spec.rule_id = rule.metadata.id;
        newAssignedRoles.push(roleAssignment);
      }
    }
    return newAssignedRoles;
  }

  private postRule(
    ruleToCreate: RuleV2,
    apiAppState: ApiApplicationsState,
    currentAssignedRoles?: Array<RoleToRuleEntry>
  ): Observable<ActionApiApplicationsAddToRuleList> {
    const args: AddRuleRequestParams = {
      app_id: apiAppState.current_application.id,
      RuleV2: ruleToCreate,
    };
    return this.applicationsService.addRule(args).pipe(
      map((ruleResp: RuleV2) => {
        this.notificationService.success(
          `Rule with path "${ruleResp.spec.condition.path_regex}" and method "${ruleResp.spec.condition.methods[0]}" was successfully created!`
        );
        return new ActionApiApplicationsAddToRuleList(ruleResp, currentAssignedRoles);
      }),
      catchError((_) => {
        this.notificationService.error(
          `Failed to create rule with path "${ruleToCreate.spec.condition.path_regex}" and method "${ruleToCreate.spec.condition.methods[0]}"`
        );
        return EMPTY;
      })
    );
  }

  private putRule(
    ruleToModify: RuleV2,
    apiAppState: ApiApplicationsState,
    orgId: string,
    currentAssignedRoles?: Array<RoleToRuleEntry>,
    rolesToDelete?: Array<RoleToRuleEntry>
  ): Observable<ActionApiApplicationsUpdateRuleList | ActionApiApplicationsRuleSaveFinished> {
    const putter = (rule: RuleV2) => {
      return this.applicationsService.replaceRule({
        app_id: apiAppState.current_application.id,
        rule_id: rule.metadata.id,
        RuleV2: rule,
      });
    };
    const getter = (rule: RuleV2) => {
      return this.applicationsService.getRule({
        app_id: apiAppState.current_application.id,
        rule_id: rule.metadata.id,
        org_id: orgId,
      });
    };
    const putRule$ = patch_via_put(ruleToModify, getter, putter);
    return putRule$.pipe(
      map((updateResp) => {
        this.notificationService.success(
          `Rule with path "${updateResp.spec.condition.path_regex}" and method "${updateResp.spec.condition.methods[0]}" was successfully updated!`
        );
        return new ActionApiApplicationsUpdateRuleList(updateResp, currentAssignedRoles, rolesToDelete);
      }),
      catchError((_) => {
        this.notificationService.error(
          `Failed to update rule with path "${ruleToModify.spec.condition.path_regex}" and method "${ruleToModify.spec.condition.methods[0]}"`
        );
        return of(new ActionApiApplicationsRuleSaveFinished());
      })
    );
  }

  private postRoleToRuleEntry(
    action: ActionApiApplicationsSavingRoleToRuleEntry,
    apiAppState: ApiApplicationsState
  ): Observable<ActionApiApplicationsAddToRoleToRuleEntriesList> {
    const args: AddRoleToRuleEntryRequestParams = {
      app_id: apiAppState.current_application.id,
      RoleToRuleEntry: action.api_obj,
    };
    return this.applicationsService.addRoleToRuleEntry(args).pipe(
      map((roleToRuleEntryResp: RoleToRuleEntry) => {
        this.notificationService.success('Role to rule entry was successfully created!');
        return new ActionApiApplicationsAddToRoleToRuleEntriesList(roleToRuleEntryResp);
      }),
      catchError((_) => {
        this.notificationService.error('Failed to create role to rule entry');
        return EMPTY;
      })
    );
  }

  private putRoleToRuleEntry(
    roleToRuleEntryToModify: RoleToRuleEntry,
    apiAppState: ApiApplicationsState,
    orgId: string
  ): Observable<ActionApiApplicationsUpdateRoleToRuleEntriesList> {
    const putter = (roleToRuleEntry: RoleToRuleEntry) => {
      return this.applicationsService.replaceRoleToRuleEntry({
        app_id: apiAppState.current_application.id,
        role_to_rule_entry_id: roleToRuleEntry.metadata.id,
        RoleToRuleEntry: roleToRuleEntry,
      });
    };
    const getter = (roleToRuleEntry: RoleToRuleEntry) => {
      return this.applicationsService.getRoleToRuleEntry({
        app_id: apiAppState.current_application.id,
        role_to_rule_entry_id: roleToRuleEntry.metadata.id,
        org_id: orgId,
      });
    };
    const putRoleToRuleEntry$ = patch_via_put(roleToRuleEntryToModify, getter, putter);
    return putRoleToRuleEntry$.pipe(
      concatMap((updateResp) => {
        this.notificationService.success('Role to rule entry was successfully updated!');
        return of(new ActionApiApplicationsUpdateRoleToRuleEntriesList([updateResp]));
      }),
      catchError((_) => {
        this.notificationService.error('Failed to update role to rule entry');
        return EMPTY;
      })
    );
  }

  private onSelectedRuleIdChange(
    appState: ApiApplicationsState,
    mergedRoute: MergedRoute,
    selectedRuleId: string,
    policyResourceList: Array<PolicyTemplateInstanceResource>
  ): Observable<ActionApiApplicationsUpdateCurrentRule> {
    if (selectedRuleId === null) {
      // If null, we either have not yet logged in to the target org,
      // the appState has not been updated with the correct org,
      // we have navigated away from the application-define screen,
      // no rule id has been provided in the url or
      // we have not yet navigated to the correct app from the url.
      return EMPTY;
    }
    return this.handleUrlRuleIdUpdate(appState, mergedRoute, selectedRuleId, policyResourceList);
  }

  private handleUrlRuleIdUpdate(
    appState: ApiApplicationsState,
    mergedRoute: MergedRoute,
    selectedRuleId: string,
    policyResourceList: Array<PolicyTemplateInstanceResource>
  ): Observable<ActionApiApplicationsUpdateCurrentRule> {
    const targetPolicyTemplateInstanceResource = getTargetPolicyTemplateInstanceResource(
      policyResourceList,
      appState.current_application?.id
    );
    let targetRule = undefined;
    if (!!targetPolicyTemplateInstanceResource) {
      const targetRulesList = targetPolicyTemplateInstanceResource?.spec?.template?.rules;
      if (!!targetRulesList) {
        targetRule = targetRulesList.find((rule) => rule.name === selectedRuleId);
      }
    } else {
      targetRule = getRuleFromId(selectedRuleId, appState.current_rules_list);
    }
    if (targetRule === undefined) {
      // Rule does not exist in this application,
      // so we route to the app define screen.
      const errorMessage = this.createRuleIdErrorMessage(mergedRoute, selectedRuleId);
      this.rerouteToDefineOnUrlError(mergedRoute, errorMessage);
      return EMPTY;
    }
    const currentRuleAsRuleV2 = appState.current_rule as RuleV2;
    if (!!currentRuleAsRuleV2 && !currentRuleAsRuleV2.metadata) {
      // Is a RuleConfig
      const currentRuleAsRuleConfig = appState.current_rule as RuleConfig;
      if (!currentRuleAsRuleConfig || selectedRuleId !== currentRuleAsRuleConfig.name) {
        return of(new ActionApiApplicationsUpdateCurrentRule(targetRule));
      }
    } else if (!currentRuleAsRuleV2 || selectedRuleId !== currentRuleAsRuleV2.metadata.id) {
      return of(new ActionApiApplicationsUpdateCurrentRule(targetRule));
    }
    return EMPTY;
  }

  private createRuleIdErrorMessage(mergedRoute: MergedRoute, selectedRuleId: string): string {
    return 'Rule with id: "' + selectedRuleId + '" is not associated with application with id: "' + mergedRoute.params.appId + '".';
  }

  /**
   * Will return the current app if it exists in the list.
   * Otherwise, returns the first app in the list.
   */
  private getCurrentAppIfExists(applicationsList: Array<Application>, currentApp: Application): Application {
    for (const app of applicationsList) {
      if (app.id === currentApp?.id) {
        return app;
      }
    }
    return applicationsList[0];
  }

  /**
   * We need to close app define panels that are not accessible to external applications
   */
  private closePanelsForExternalApp(expansionPanelsState: ExpansionPanelsState): void {
    expansionPanelsState.panels[AppDefineExpansionPanel.appDefineBundlesPanel] = false;
    expansionPanelsState.panels[AppDefineExpansionPanel.appDefineFirewallRulesPanel] = false;
    expansionPanelsState.panels[AppDefineExpansionPanel.appDefineWebApplicationSecurityPanel] = false;
    expansionPanelsState.panels[AppDefineExpansionPanel.appDefineHttpRewritesPanel] = false;
    expansionPanelsState.panels[AppDefineExpansionPanel.appDefineProxiedServiceConfigPanel] = false;
  }
}
