import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { delayStepperAdvanceToInProgressStep, getStartOptionData } from '../application-template-utils';
import { DefaultStepperState } from '../default-stepper-state';
import { StartOption } from '../start-option.enum';
import { StepperType } from '../stepper-type.enum';

@Component({
  selector: 'portal-start-step',
  templateUrl: './start-step.component.html',
  styleUrls: ['./start-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StartStepComponent implements OnChanges {
  @Input() public stepperType = StepperType.application;
  @Input() public startOptionFormGroup: UntypedFormGroup;
  @Input() public stepperState: DefaultStepperState = {
    selectedStartOption: undefined,
    selectedTemplates: undefined,
  };
  @Input() public hasTemplates = false;
  @Input() public stepper: MatStepper;
  @Input() public isInProgress = false;
  @Output() public updateModel = new EventEmitter<any>();
  @Output() public resetModel = new EventEmitter<any>();
  public startOptionData = getStartOptionData(this.stepperType, this.hasTemplates);

  constructor() {}

  public ngOnChanges(): void {
    this.startOptionData = getStartOptionData(this.stepperType, this.hasTemplates);
  }

  public onStartOptionChange(startOption: StartOption): void {
    this.stepperState.selectedStartOption = startOption;
    if (this.hasTemplates && startOption !== StartOption.template) {
      this.stepperState.selectedTemplates = [];
    }
    if (startOption === StartOption.in_progress) {
      delayStepperAdvanceToInProgressStep(this.stepper);
    }
    if (startOption === StartOption.new) {
      this.resetApplicationModel();
      return;
    }
    this.updateApplicationModel();
  }

  private updateApplicationModel(): void {
    this.updateModel.emit();
  }

  private resetApplicationModel(): void {
    this.resetModel.emit();
  }

  public disableStartOption(option: StartOption): boolean {
    if (option !== StartOption.in_progress) {
      return false;
    }
    if (this.isInProgress) {
      return false;
    }
    return true;
  }
}
