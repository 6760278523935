import { ApplicationService, ApplicationServicesService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import {
  createNewApplicationService,
  deleteExistingApplicationService,
  getApplicationService,
  getApplicationServicesList,
  updateExistingApplicationService,
} from '../application-service-state/application-services-utils';
import * as ApplicationServiceActions from '../application-service-state/application-service.actions';

@Injectable({
  providedIn: 'root',
})
export class ApplicationServiceStateService extends SimpleCRUDInterface<ApplicationService, string> {
  public crudRegistryName = CrudStateCollection.application_services;

  constructor(
    public store: Store<AppState>,
    private applicationServicesService: ApplicationServicesService,
    private crudRegistryService: CrudRegistryService<ApplicationService, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<ApplicationService, string> = {
        create: (id: string, obj: ApplicationService, orgId: string) => createNewApplicationService(this.applicationServicesService, obj),
        update: (id: string, obj: ApplicationService, orgId: string) =>
          updateExistingApplicationService(this.applicationServicesService, obj),
        get: (id: string, orgId: string) => getApplicationService(this.applicationServicesService, id, orgId),
        list: (orgId: string) => getApplicationServicesList(this.applicationServicesService, orgId),
        delete: (id: string, orgId: string) => deleteExistingApplicationService(this.applicationServicesService, id, orgId),
      };
      const crudRegistry: CrudRegistry<ApplicationService, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: ApplicationService) => obj.name,
        doneSaveAction: ApplicationServiceActions.upsertApplicationService,
        doneSaveListAction: ApplicationServiceActions.upsertApplicationServices,
        doneDeleteAction: ApplicationServiceActions.deleteApplicationService,
        doneDeleteListAction: ApplicationServiceActions.deleteApplicationServices,
        setListAction: ApplicationServiceActions.loadApplicationServices,
        refreshStateAction: ApplicationServiceActions.refreshApplicationServices,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
