export enum ModeOptions {
  disabled = 'disabled',
  block = 'block',
  sanitise = 'sanitise',
  deny = 'deny',
  sameorigin = 'sameorigin',
  clear = 'clear',
  override = 'override',
  nosniff = 'nosniff',
  unsafe_none = 'unsafe_none',
  require_corp = 'require_corp',
  same_origin_allow_popups = 'same_origin_allow_popups',
  same_origin = 'same_origin',
  same_site = 'same_site',
  cross_origin = 'cross_origin',
  no_referrer = 'no_referrer',
  no_referrer_when_downgrade = 'no_referrer_when_downgrade',
  origin = 'origin',
  origin_when_cross_origin = 'origin_when_cross_origin',
  strict_origin = 'strict_origin',
  strict_origin_when_cross_origin = 'strict_origin_when_cross_origin',
  usafe_url = 'usafe_url',
}
