import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'portal-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadButtonComponent {
  @Input() public buttonDescription = '';
  @Input() public disableButton = false;
  @Input() public tooltipText = 'Click to download';
  @Output() private downloadFile = new EventEmitter<any>();

  constructor() {}

  public onDownloadFile(): void {
    this.downloadFile.emit();
  }
}
