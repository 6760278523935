<portal-page-info
  pageTitle="New Network"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasAllPermissions() && !!currentModel"
  class="stepper-container"
>
  <mat-vertical-stepper
    linear
    #networkStepper
    portalMatVerticalStepperScroller
    (selectionChange)="onStepperSelectionChange($event.selectedIndex)"
  >
    <mat-step [stepControl]="connectorFormGroup">
      <ng-template matStepLabel>Select connector</ng-template>
      <portal-connector-selection
        [connectorFormGroup]="connectorFormGroup"
        [connectors]="connectors"
        (updateModel)="updateConnector($event)"
      >
      </portal-connector-selection>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="nameFormGroup">
      <ng-template matStepLabel>Network name</ng-template>
      <form
        [formGroup]="nameFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the network name"
          label="My network will be called (a valid hostname)..."
          [formCtrl]="nameFormGroup.get('name')"
          required="true"
          [tooltip]="getNetworkNameTooltipText()"
          (formBlur)="onFormBlur(nameFormGroup, 'name', getModel())"
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="addressFormGroup">
      <ng-template matStepLabel>Address</ng-template>
      <form
        [formGroup]="addressFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the hostname"
          label="The hostname or IP of the network is..."
          [formCtrl]="addressFormGroup.get('hostname')"
          required="true"
          [tooltip]="getNetworkHostnameTooltipText()"
          (formBlur)="onFormBlur(addressFormGroup, 'hostname', getModel())"
        >
        </portal-stepper-form-field>

        <portal-stepper-form-field
          placeholder="Enter the port"
          label="The port of the network is..."
          [formCtrl]="addressFormGroup.get('port')"
          required="true"
          [tooltip]="getNetworkPortTooltipText()"
          (formBlur)="onFormBlur(addressFormGroup, 'port', getModel())"
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="optionalConfigFormGroup">
      <ng-template matStepLabel>Advanced Configuration (optional)</ng-template>
      <form
        [formGroup]="optionalConfigFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <label
          class="radio-button-group-label"
        >
          My network has additional options:
        </label>
        <mat-radio-group
          formControlName="optional_config"
          aria-labelledby="optional config radio buttons"
          class="optional-config-radio-group"
        >
          <mat-radio-button
            *ngFor="let option of optionalConfigOptions"
            class="optional-config-radio-button"
            [value]="option.value"
            (change)="onOptionalConfigChange(option.value)"
          >
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>
        <div
          *ngIf="!!optionalConfigFormGroup.get('optional_config').value"
          class="optional-config-container"
        >
          <portal-stepper-form-field
            placeholder="Enter the override IP"
            [formCtrl]="optionalConfigFormGroup.get('override_ip')"
            (formBlur)="onFormBlur(optionalConfigFormGroup, 'override_ip', getModel())"
            [tooltip]="getNetworkOverrideIpTooltipText()"
          >
          </portal-stepper-form-field>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="tls_enabled"
              (change)="onCheckboxChange('tls_enabled', $event.checked)"
              aria-label="Checkbox that toggles whether the network is accessed via tls"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              My internal network service uses TLS
            </mat-checkbox>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="tls_verify"
              (change)="onCheckboxChange('tls_verify', $event.checked)"
              aria-label="Checkbox that toggles whether tls is verified"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              My internal network service verifies TLS
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getNetworkTlsVerifyTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="expose_as_hostname"
              (change)="onCheckboxChange('expose_as_hostname', $event.checked)"
              aria-label="Checkbox that toggles whether the network is exposed as a tls endpoint"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              My internal network service exposes the network as a TLS endpoint
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getNetworkExposeAsHostnameTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="dynamic_source_port_override"
              (change)="onCheckboxChange('dynamic_source_port_override', $event.checked)"
              aria-label="Checkbox that toggles whether the dynamic source port override is enabled"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              My launchers dynamically allocate the listening port
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getDynamicSourcePortOverrideTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>

          <portal-stepper-form-field
            placeholder="Enter the (optional) source port override"
            [formCtrl]="optionalConfigFormGroup.get('source_port_override')"
            (formBlur)="onFormBlur(optionalConfigFormGroup, 'source_port_override', getModel())"
            [tooltip]="getNetworkSourcePortOverrideTooltipText()"
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            placeholder="Enter the (optional) source address override"
            [formCtrl]="optionalConfigFormGroup.get('source_address_override')"
            (formBlur)="onFormBlur(optionalConfigFormGroup, 'source_address_override', getModel())"
            [tooltip]="getNetworkSourceAddressOverrideTooltipText()"
          >
          </portal-stepper-form-field>

        </div>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [completed]="isStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">{{ completedStepperText }}</p>

      <div
        *ngIf="!!getModel()"
        class="summary-container"
      >
        <h4>Summary:</h4>
        <p>Connector: {{ getModel().connector_name }}</p>
        <p>Network name: {{ getModel().name }}</p>
        <p>Hostname: {{ getModel().hostname }}</p>
        <p>Port: {{ getModel().config.ports[0].port }}</p>
        <p *ngIf="!!getModel().override_ip">Override IP: {{ getModel().override_ip }}</p>
        <p *ngIf="!!getModel().tls_enabled">My internal network service uses TLS</p>
        <p *ngIf="!!getModel().tls_verify">My internal network service verifies TLS</p>
        <p *ngIf="!!getModel().expose_as_hostname">My internal network service exposes the network as a TLS endpoint</p>
        <p *ngIf="!!getModel().dynamic_source_port_override">My launchers dynamically allocate the listening port</p>
        <p *ngIf="!!getModel().source_port_override">Source port override: {{ getModel().source_port_override }}</p>
        <p *ngIf="!!getModel().source_address_override">Source address override: {{ getModel().source_address_override }}</p>
      </div>

      <portal-apply-app-model
        [currentApplicationModel]="currentModel"
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [appModelStatus]="modelStatus"
        [stepperType]="stepperType"
        (submitModel)="submitModel($event)"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <portal-stepper-done-config-options
        *ngIf="!!getModel().id"
        [resourceId]="getModel().id"
        [resourceName]="getModel().name"
        [resourceType]="resourceType"
        [orgId]="orgId"
      >
      </portal-stepper-done-config-options>
    </mat-step>

  </mat-vertical-stepper>
</div>
