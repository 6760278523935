import { Component, OnInit, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { ListActiveUsersRequestParams, MetricsService, Resource, ResourcesService, TimeIntervalMetrics } from '@agilicus/angular';
import { forkJoin, Observable, of } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { takeUntil, concatMap, take } from 'rxjs/operators';
import { Application } from '@agilicus/angular';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { FilterManager } from '../filter/filter-manager';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { Subject } from 'rxjs';
import { ApplicationsService } from '@agilicus/angular';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectCanReadMetrics } from '@app/core/user/permissions/metrics.selectors';
import { selectCanReadApps } from '@app/core/user/permissions/app.selectors';
import { fillOptionalDataFromForm, getEnumValuesAsStringArray, getUniqueNamesList } from '../utils';
import { getStartDateMaxSetter, getEndDateMinSetter } from '../date-utils';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { getPageSizeOptions } from '@app/shared/utilities/tables/pagination';
import { createCombinedPermissionsSelector, OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { DiagnosticDateRange } from '../diagnostic-types';
import { FilterChipOptions } from '../filter-chip-options';
import { getApplicationsForMetrics, getFilteredOptions, getResoucesForMetrics, getResourceFilterList } from '../metrics-utils';
import { ResourceType } from '../resource-type.enum';
import { MapObject, UnparseData } from 'ngx-papaparse/lib/interfaces/unparse-data';

export interface MetricsFile {
  Time: string;
  'Active Users': number;
}

export interface GraphData {
  name: string;
  value: number;
  extra: {
    warning: string;
    fullTime: Date;
  };
}

export interface MetricsTimes {
  truncated: string;
  full: Date;
}

export interface MetricsTableData {
  time: string;
  metric: number;
}

@Component({
  selector: 'portal-metrics-active-users',
  templateUrl: './metrics-active-users.component.html',
  styleUrls: ['./metrics-active-users.component.scss'],
})
export class MetricsActiveUsersComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public org_id: string;
  public applications: Array<Application>;
  public resources: Array<Resource>;
  public resourceNameList: Array<string> = [];
  public graphDataSource: Array<GraphData>;
  public tableDataSource: MatTableDataSource<MetricsTableData> = new MatTableDataSource();
  private permissions$: Observable<OrgQualifiedPermission>;
  public hasPermissions: boolean;
  public maxDate: Date = new Date();
  public displayedMetricsColumns: Array<string> = ['time', 'metric'];
  public activeMetricsForm: UntypedFormGroup;
  public filteredAppOptions: Observable<Array<string>>;
  public filteredResourceOptions: Observable<Array<string>>;
  public resourceTypesList = getEnumValuesAsStringArray(ResourceType);
  public pageDescriptiveText = `View end-user activity, how many user actions per time`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/usage-metrics/#h-active-users`;

  public filterChipOptions: FilterChipOptions = {
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  public filterManager: FilterManager = new FilterManager();

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getStartDateMaxSetter = getStartDateMaxSetter;
  public getEndDateMinSetter = getEndDateMinSetter;

  @ViewChild('metricsTableSort', { static: false }) public metricsTableSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) public paginator: MatPaginator;

  // Graph options
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'Time';
  public showYAxisLabel = true;
  public yAxisLabel = 'Active Users';

  constructor(
    private activeMetricsService: MetricsService,
    private formBuilder: UntypedFormBuilder,
    private papa: Papa,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private applicationsService: ApplicationsService,
    private resourcesService: ResourcesService
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.permissions$ = this.getPermissions();
    this.permissions$
      .pipe(
        takeUntil(this.unsubscribe$),
        concatMap((permissions) => {
          this.org_id = permissions.orgId;
          this.hasPermissions = permissions.hasPermission;
          if (!this.org_id || !this.hasPermissions) {
            return of([]);
          }
          const applications$ = getApplicationsForMetrics(this.org_id, this.applicationsService);
          const resources$ = getResoucesForMetrics(this.org_id, this.resourcesService);
          return forkJoin([applications$, resources$]);
        })
      )
      .subscribe(([appsResp, resourceResp]) => {
        this.applications = appsResp;
        this.resources = resourceResp;
        this.resourceNameList = this.getCombinedAppsAndResourcesNamesList();
        this.setResourceAutocomplete();
        this.graphDataSource = [];
        this.tableDataSource.data = [];
        if (appsResp?.length && resourceResp?.length) {
          this.listActiveUsers();
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getCombinedAppsAndResourcesNamesList(): Array<string> {
    if (!this.applications || !this.resources) {
      return [];
    }
    const appNames = this.applications.map((app) => app.name);
    const resourceNames = this.resources.map((app) => app.spec.name);
    return getUniqueNamesList([...appNames, ...resourceNames]);
  }

  private initializeFormGroup(): void {
    this.activeMetricsForm = this.formBuilder.group({
      dtFrom: null,
      dtTo: null,
      resource_name: '',
      resource_type: '',
    });
  }

  private getPermissions(): Observable<OrgQualifiedPermission> {
    return this.store.pipe(select(createCombinedPermissionsSelector(selectCanReadApps, selectCanReadMetrics)));
  }

  private getHourlyTimezoneOffset(date: Date): number {
    // getTimezoneOffset() returns the offset in minutes and in the opposite sign (e.g. UTC-4 is +4)
    return -date.getTimezoneOffset() / 60;
  }

  public setStartAndEndDates(): DiagnosticDateRange {
    const targetDates: DiagnosticDateRange = {
      startDate: null,
      endDate: null,
      interval: null,
    };
    targetDates.startDate = this.activeMetricsForm.value.dtFrom;
    targetDates.endDate = this.activeMetricsForm.value.dtTo ? new Date(this.activeMetricsForm.value.dtTo) : new Date();
    if (targetDates.startDate == null) {
      // set the start date to the month before if it is not specified
      targetDates.startDate = new Date(new Date(targetDates.endDate).setDate(targetDates.endDate.getDate() - 30));
      targetDates.startDate.setMinutes(0);
      // set to 00:00 UTC
      targetDates.startDate.setHours(this.getHourlyTimezoneOffset(targetDates.startDate));
    }
    // set seconds and milliseconds to zero
    targetDates.startDate.setSeconds(0);
    targetDates.startDate.setMilliseconds(0);
    targetDates.endDate.setSeconds(0);
    targetDates.endDate.setMilliseconds(0);

    return targetDates;
  }

  private setResourceAutocomplete(): void {
    this.filteredResourceOptions = getFilteredOptions(this.resourceNameList, 'resource_name', this.activeMetricsForm);
  }

  private getInterval(diff: number): number {
    const days_in_milliseconds = 1000 * 60 * 60 * 24;

    // if range is 3-10 days, use 4-hour
    if (diff < days_in_milliseconds * 10 && diff >= days_in_milliseconds * 3) {
      return 60 * 60 * 4;
    }
    // if range is < 3 day, use 3600 [hourly]
    else if (diff < days_in_milliseconds * 3) {
      return 60 * 60;
    }
    // if range is > 10 days, use 24-hour [daily]
    else {
      return days_in_milliseconds / 1000;
    }
  }

  public getActiveMetricsFilter(): ListActiveUsersRequestParams {
    const activeMetricsFilter: ListActiveUsersRequestParams = {
      org_id: this.org_id,
    };
    fillOptionalDataFromForm(activeMetricsFilter, this.activeMetricsForm, getResourceFilterList());
    const targetDates = this.setStartAndEndDates();
    activeMetricsFilter.dt_from = targetDates.startDate?.toISOString();
    activeMetricsFilter.dt_to = targetDates.endDate?.toISOString();
    const diff = targetDates.endDate.getTime() - targetDates.startDate.getTime();
    activeMetricsFilter.interval = this.getInterval(diff);
    return activeMetricsFilter;
  }

  private createBucket(inputDate: string, interval: number): Date {
    let newDate = new Date(inputDate);
    newDate.setMinutes(0);
    // daily intervals should start at 00:00 UTC, adjust start time for timezone
    // (e.g. if the start time is 11:00 EDT then the start time should be 20:00 EDT on the previous day because EDT is UTC-4)
    if (interval === 86400) {
      let adjustedDate = new Date(newDate);
      adjustedDate.setHours(0);
      adjustedDate = new Date(adjustedDate.getTime() + this.getHourlyTimezoneOffset(adjustedDate) * 60 * 60 * 1000);
      // if the start time falls into the interval of the adjusted date set it to this time, otherwise use the next interval
      if (newDate.getTime() < adjustedDate.getTime() + interval * 1000) {
        newDate = adjustedDate;
      } else {
        newDate = new Date(adjustedDate.getTime() + interval * 1000);
      }
    }
    return newDate;
  }

  private addData(entry: GraphData, data: TimeIntervalMetrics[], interval: number): void {
    const curTime = new Date(entry.name).getTime();
    for (const row of data) {
      const curDate = new Date(row.time).getTime();
      // if the data fits in the bucket, use the metric value for this bucket
      if (curDate < 1000 * interval + curTime && curDate >= curTime) {
        entry.value = row.metric;
        break;
      }
    }
  }

  private addLeftPartialDataWarning(curTime: GraphData, dtFrom: string, startTime: Date, interval: number): void {
    if (startTime.getTime() !== new Date(dtFrom).getTime()) {
      if (interval === 86400) {
        curTime.extra.warning =
          'Displayed metrics may be using partial data because\nthe entire bucket is not included in time range.\nDaily intervals begin at 00:00 (UTC).\n';
      } else {
        curTime.extra.warning = 'Displayed metrics may be using partial data because\nthe entire bucket is not included in time range.\n';
      }
    }
  }

  private addRightPartialDataWarning(curTime: GraphData, endTime: number, interval: number): void {
    const intervalRange = curTime.extra.fullTime.getTime() + 1000 * interval;
    const today = new Date();
    today.setMilliseconds(0);
    today.setSeconds(0);

    if ((intervalRange > today.getTime() && endTime < today.getTime()) || (intervalRange < today.getTime() && endTime < intervalRange)) {
      if (interval === 86400) {
        curTime.extra.warning =
          'Displayed metrics may be using partial data because\nthe entire bucket is not included in time range.\nDaily intervals begin at 00:00 (UTC).\n';
      } else {
        curTime.extra.warning = 'Displayed metrics may be using partial data because\nthe entire bucket is not included in time range.\n';
      }
    }
  }

  private createTimeSeriesData(data: TimeIntervalMetrics[], activeMetricsFilter: ListActiveUsersRequestParams): Array<GraphData> {
    if (activeMetricsFilter.interval < 1) {
      throw new RangeError('Interval must be greater than 0');
    }

    const startTime = this.createBucket(activeMetricsFilter.dt_from, activeMetricsFilter.interval);
    const endTime = new Date(activeMetricsFilter.dt_to);
    const times: Array<MetricsTimes> = [];
    let index = 0;
    let increment = 0;
    let nextTime = new Date(startTime);
    const dateFormat = activeMetricsFilter.interval === 86400 ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm';

    // ignore the 1 hour offset caused by daylight time savings to make the times more consistent when dealing with daily intervals
    const timezoneOffset = this.getHourlyTimezoneOffset(nextTime).toString();
    while (1000 * activeMetricsFilter.interval + nextTime.getTime() < endTime.getTime()) {
      nextTime = new Date(startTime.getTime() + increment);
      times.push({ truncated: formatDate(nextTime, dateFormat, 'en-CA', timezoneOffset), full: nextTime });
      index++;
      increment = index * activeMetricsFilter.interval * 1000;
    }
    const timeseries: Array<GraphData> = [];
    for (const time of times) {
      timeseries.push({ name: time.truncated, value: 0, extra: { warning: null, fullTime: time.full } });
    }

    if (timeseries.length === 0) {
      return timeseries;
    }

    for (const entry of timeseries) {
      this.addData(entry, data, activeMetricsFilter.interval);
    }

    this.addLeftPartialDataWarning(timeseries[0], activeMetricsFilter.dt_from, startTime, activeMetricsFilter.interval);

    this.addRightPartialDataWarning(timeseries[timeseries.length - 1], endTime.getTime(), activeMetricsFilter.interval);
    return timeseries;
  }

  private reformatMetricsDataTable(data: Array<GraphData>): Array<MetricsTableData> {
    const reformattedData: Array<MetricsTableData> = [];
    for (const metric of data) {
      reformattedData.push({
        time: metric.extra.fullTime.toISOString(),
        metric: metric.value,
      });
    }
    return reformattedData;
  }

  private reformatMetricsData(data: Array<GraphData>): UnparseData {
    const reformattedData: Array<Array<string>> = [];
    const fields: string[] = ['time', 'active_users'];
    for (const metric of data) {
      const data = [metric.extra.fullTime.toISOString(), metric.value.toString()];
      reformattedData.push(data);
    }
    const mapObject: MapObject = {
      fields: fields,
      data: reformattedData,
    };
    return mapObject;
  }

  public listActiveUsers(): void {
    const activeMetricsFilter = this.getActiveMetricsFilter();
    this.activeMetricsService
      .listActiveUsers(activeMetricsFilter)
      .pipe(take(1))
      .subscribe((metricsResp) => {
        this.graphDataSource = this.createTimeSeriesData(metricsResp.active_users, activeMetricsFilter);
        this.tableDataSource = new MatTableDataSource(this.reformatMetricsDataTable(this.graphDataSource));
        this.tableDataSource.sort = this.metricsTableSort;
        this.tableDataSource.paginator = this.paginator;
        this.filterManager.createFilterPredicate(this.tableDataSource, this.displayedMetricsColumns);
      });
  }

  public unparseDataToCsv(data: Array<GraphData>): string {
    const metricsData = this.reformatMetricsData(data);
    const options: UnparseConfig = {
      quotes: true,
      header: true,
      newline: '\n',
    };
    return this.papa.unparse(metricsData, options);
  }

  private downloadMetrics(data: Array<GraphData>): void {
    const metricsCsv = this.unparseDataToCsv(data);
    const link = this.renderer.createElement('a');
    const blob = new Blob([metricsCsv], { type: 'text/csv' });
    link.href = window.URL.createObjectURL(blob);
    link.download = 'active_users.csv';
    link.click();
  }

  public listActiveUsersAndDownload(): void {
    const activeMetricsFilter = this.getActiveMetricsFilter();
    this.activeMetricsService
      .listActiveUsers(activeMetricsFilter)
      .pipe(take(1))
      .subscribe((activeUsersResp) => {
        const data = this.createTimeSeriesData(activeUsersResp.active_users, activeMetricsFilter);
        this.downloadMetrics(data);
      });
  }

  public useSharedPageSizeOptions(): Array<number> {
    return getPageSizeOptions(this.tableDataSource.data.length);
  }
}
