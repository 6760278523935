<div *ngIf="!isSigningUp" class="welcome-container">

  <div *ngIf="showUnwelcomeMessage(); then unwelcomeBlock else welcomeBlock"></div>

  <ng-template #unwelcomeBlock>
    <div class="welcome">
      <h1>SIGN-UP USE ONLY</h1>
      <br>
      <p>This specific URL is for sign-up use only.
         If you are looking to sign-up and create a new account with Agilicus AnyX,
         you may start the process by selecting 'SIGN UP' below.
      </p><br>
      <div class="login-button-container">
        <button mat-raised-button color="primary" aria-label="SIGN UP" (click)="redirectSignin()">
          SIGN UP
        </button>
      </div>
      <br>
      <p>
      If you have already gone through the sign-up process for Agilicus AnyX, you chose a custom domain name at that time.
      You will have received a personalised email with your domain name information at that time.</p>
      <br>
      In this email you will see 2 URL:
      <ol>
        <li>https://admin.MYDOMAIN/ -- used by administrators of the system for configuration.</li>
        <li>https://profile.MYDOMAIN/ -- used by end-users of the system for their personal profile and launchpad.</li>
      </ol>
      <p>
      If you are looking to make administrative changes to the system, refer to that email and 
      nagivate to the URL as shown in it.</p><br>
      <p>
      If you cannot recall your custom domain name,
      please open the chat icon in the lower right and the Agilicus
      support team would be happy to look this up for you.
      </p><br>
    </div>
  </ng-template>

  <ng-template #welcomeBlock>
    <div *ngIf="showTaskBoard()" class="task-board">
      <portal-task-board [orgId]="orgId">
      </portal-task-board>
    </div>

    <div *ngIf="showWelcomeMessage()" class="welcome">
      <h1>ZERO TRUST NETWORK ACCESS</h1>

      <div class="desc">
        <div>
          Any Application. Any User. Any Device. Any Network. Any Time.<br>
          <hr>
          <div class="flex-row">

            <div class="desc flex-row flex-70-custom">
              <div class="desc-left">
                Already setup?<br>
                <div class="login-button-container">
                  <button mat-raised-button color="primary" aria-label="Signup/Login" (click)="onLoginClick()">
                    SIGN IN >>
                  </button>
                </div>
                <br>

                <hr>
                Trying for the first time?<br>
                <div class="login-button-container">
                  <button mat-raised-button color="warn" (click)="redirectSignin()" aria-label="Initial Setup">
                    Initial Setup
                  </button>
                </div>
                <br>

                <hr>
                <portal-page-info [productGuideLink]="productGuideLink" pageInfoWidth="full">
                </portal-page-info>
              </div>
              <div class="desc-right">
                <img class="diag" alt="desktop schematic" src="assets/img/sys-overview.svg">
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

</div>