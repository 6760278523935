import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { BillingAccountFull } from '../models/billing/billing-account-full';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadBillingAccountFulls = createAction(
  '[BillingAccountFull/API] Load BillingAccountFulls',
  props<{ objs: BillingAccountFull[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state.
 */
export const addBillingAccountFull = createAction(
  '[BillingAccountFull/API] Add BillingAccountFull',
  props<{ obj: BillingAccountFull; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertBillingAccountFull = createAction(
  '[BillingAccountFull/API] Upsert BillingAccountFull',
  props<{ obj: BillingAccountFull; refreshData: boolean }>()
);

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addBillingAccountFulls = createAction(
  '[BillingAccountFull/API] Add BillingAccountFulls',
  props<{ objs: BillingAccountFull[] }>()
);

/**
 * Will add new or update existing objects in the ngrx entity state.
 * Not currently implemented since we only update a single BillingAccountFull.
 */
export const upsertBillingAccountFulls = createAction(
  '[BillingAccountFull/API] Upsert BillingAccountFulls',
  props<{ objs: BillingAccountFull[]; refreshData: boolean }>()
);

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateBillingAccountFull = createAction(
  '[BillingAccountFull/API] Update BillingAccountFull',
  props<{ obj: Update<BillingAccountFull> }>()
);

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we only update a single BillingAccountFull.
 */
export const updateBillingAccountFulls = createAction(
  '[BillingAccountFull/API] Update BillingAccountFulls',
  props<{ objs: Update<BillingAccountFull>[] }>()
);

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteBillingAccountFull = createAction(
  '[BillingAccountFull/API] Delete BillingAccountFull',
  props<{ id: string; obj: BillingAccountFull; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteBillingAccountFulls = createAction(
  '[BillingAccountFull/API] Delete BillingAccountFulls',
  props<{ ids: string[]; refreshData?: boolean }>()
);

/**
 * Will delete all data from the ngrx entity state
 */
export const clearBillingAccountFulls = createAction('[BillingAccountFull/API] Clear BillingAccountFulls');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initBillingAccountFull = createAction(
  '[BillingAccountFull/API] Init BillingAccountFull',
  props<{ force: boolean; blankSlate: boolean }>()
);

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getBillingAccountFull = createAction(
  '[BillingAccountFull/API] Get BillingAccountFull',
  props<{ org_id: string; blankSlate: boolean }>()
);

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingBillingAccountFull = createAction(
  '[BillingAccountFull/API] Saving BillingAccountFull',
  props<{ obj: BillingAccountFull; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingBillingAccountFulls = createAction(
  '[BillingAccountFull/API] Saving BillingAccountFulls',
  props<{ objs: Array<BillingAccountFull>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingBillingAccountFull = createAction(
  '[BillingAccountFull/API] Deleting BillingAccountFull',
  props<{ obj: BillingAccountFull; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of  data objects for the relevant data type
 */
export const deletingBillingAccountFulls = createAction(
  '[BillingAccountFull/API] Deleting BillingAccountFulls',
  props<{ objs: Array<BillingAccountFull>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshBillingAccountFull = createAction('[BillingAccountFull/API] Refresh BillingAccountFull');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainBillingAccountFull = createAction('[BillingAccountFull/API] Maintain BillingAccountFull');
