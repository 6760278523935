<portal-page-info
  pageTitle="Assign Permissions"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div class="main-content-container">
  <mat-slide-toggle
    class="mark-complete-slider"
    [checked]="isTaskComplete"
    (change)="onCompleteToggle($event.checked)"
  >
    {{ getTaskCompleteToggleText(targetTask, gettingStartedDataCopy) }}
  </mat-slide-toggle>

  <form
    [formGroup]="assignPermissionsFormGroup"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="parent-radio-group">
      <mat-radio-group
        formControlName="isWebApp"
        aria-labelledby="is a web application option radio buttons"
        class="radio-group"
      >
        <mat-radio-button
          class="radio-button full-line-radio-button"
          value="yes"
        >
          My resource is a web application
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          value="no"
        >
          My resource is not a web application
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="isFirstOptionSelected()">
      <div class="child-radio-group nest-level-1">
        <mat-radio-group
          formControlName="numberOfUsers"
          aria-labelledby="number of users option radio buttons"
          class="radio-group nested-radio-group"
        >
          <mat-radio-button
            class="radio-button full-line-radio-button"
            [value]="numberOfUserOptions.all"
          >
            The resource is accessible by all users
          </mat-radio-button>
          <mat-radio-button
            class="radio-button full-line-radio-button"
            [value]="numberOfUserOptions.some"
          >
            The resource is accessible by more than one user
          </mat-radio-button>
          <mat-radio-button
            class="radio-button full-line-radio-button"
            [value]="numberOfUserOptions.one"
          >
            The resource is accessible by only one user
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </form>

  <div class="recommendations-container">
    <div *ngIf="showApplicationAllUsersRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you add the users to your organisation and assign application permissions to the 'all-users' group."
        recommendationCommonText = "See the images below for directions on how to do so. 
        Click the images to navigate to these screens."
        imageSourcePath="assets/img/assign-permissions-users.png"
        (imageClick)="onImageClick('user-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-app-all-users.png"
        (imageClick)="onImageClick('application-permissions-admin')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showApplicationSomeUsersRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you add the users to your organisation. Then create a group and add these users to the group. Finally, assign the necessary application permissions to the group."
        recommendationCommonText = "See the images below for directions on how to do so. 
        Click the images to navigate to these screens."
        imageSourcePath="assets/img/assign-permissions-users.png"
        (imageClick)="onImageClick('user-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-groups.png"
        (imageClick)="onImageClick('group-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-app-add-permission.png"
        (imageClick)="onImageClick('application-permissions-admin')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showApplicationOneUserRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you add the user to your organisation and assign the necessary application permissions to that user."
        recommendationCommonText = "See the images below for directions on how to do so. 
        Click the images to navigate to these screens."
        imageSourcePath="assets/img/assign-permissions-users.png"
        (imageClick)="onImageClick('user-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-app-add-permission.png"
        (imageClick)="onImageClick('application-permissions-admin')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showResourceAllUsersRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you add the users to your organisation and assign resource permissions to the 'all-users' group."
        recommendationCommonText = "See the images below for directions on how to do so. 
        Click the images to navigate to these screens."
        imageSourcePath="assets/img/assign-permissions-users.png"
        (imageClick)="onImageClick('user-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-resource-all-users.png"
        (imageClick)="onImageClick('resource-permissions-admin')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showResourceSomeUsersRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you add the users to your organisation. Then create a group and add these users to the group. Finally, assign the necessary resource permissions to the group."
        recommendationCommonText = "See the images below for directions on how to do so. 
        Click the images to navigate to these screens."
        imageSourcePath="assets/img/assign-permissions-users.png"
        (imageClick)="onImageClick('user-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-groups.png"
        (imageClick)="onImageClick('group-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-resource-add-permission.png"
        (imageClick)="onImageClick('resource-permissions-admin')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="showResourceOneUserRecommendation()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you add the user to your organisation and assign the necessary resource permissions to that user."
        recommendationCommonText = "See the images below for directions on how to do so. 
        Click the images to navigate to these screens."
        imageSourcePath="assets/img/assign-permissions-users.png"
        (imageClick)="onImageClick('user-admin')"
      >
      </portal-getting-started-recommendations>
      <portal-getting-started-recommendations
        recommendationCommonText = ""
        imageSourcePath="assets/img/assign-permissions-resource-add-permission.png"
        (imageClick)="onImageClick('resource-permissions-admin')"
      >
      </portal-getting-started-recommendations>
    </div>
  </div>
</div>