import {
  AgentConnector,
  Application,
  ApplicationService,
  ApplicationServicesService,
  ApplicationsService,
  AuthenticationDocument,
  Connector,
  ConnectorSpec,
  ConnectorsService,
  DesktopResource,
  DesktopResourceSpec,
  EnvironmentConfigVar,
  EnvironmentConfigVarList,
  FileShareService,
  Group,
  GroupsService,
  IssuerClient,
  IssuersService,
  ListResourceRolesResponse,
  NetworkPortRange,
  Organisation,
  PermissionsService,
  ResourcePermission,
  ResourcePermissionSpec,
  TokensService,
  User,
} from '@agilicus/angular';
import { catchError, combineLatest, concatMap, forkJoin, map, Observable, of, throwError } from 'rxjs';
import { getApplicationByName$, getDefaultApplicationRoleName } from '../api-applications/api-applications-utils';
import {
  getApplicationServiceByName$,
  getDesktopResourceByName$,
  getFileShareServiceByName$,
  getSelfHostedUpstreamServiceName,
} from '../application-service-state/application-services-utils';
import { deleteIssuerClient$ } from '../issuer-clients/issuer-clients-utils';
import { ApplicationModel, UpstreamService } from '../models/application/application-model';
import { getDefaultApplicationModel } from '../models/application/application-model-utils';
import { defaultDesktopModel, DesktopModel } from '../models/desktop/desktop-model';
import { defaultFileShareModel, FileShareModel } from '../models/file-share/file-share-model';
import { ApiDataCreation, createNewApiDataHandleErrors, getExistingData } from './api-utils';
import {
  createAgentConnector,
  deleteAllInstances$,
  getAgentConnectorToCreate,
  getConnectorByName$,
} from './connectors/connectors-api-utils';
import { addNewGroupMember$, createNewGroup$, getGroupByName$, updateExistingGroup$ } from './group-api-utils';
import { createNewResourcePermissionAndHandleConflict$ } from './permissions-api-utils';
import { createAuthenticationDocument, getAuthenticationDocument } from './token-api-utils';
import { selectUser } from '../user/user.selectors';

export function getDemoAgentConnectorName(): string {
  return 'demo-connector';
}

export function getDemoAgentConnector(organisation: Organisation): AgentConnector {
  return getAgentConnectorToCreate(getDemoAgentConnectorName(), organisation);
}

export function getDemoConnector$(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  type?: ConnectorSpec.ConnectorTypeEnum
): Observable<Connector> {
  return getConnectorByName$(connectorsService, getDemoAgentConnectorName(), orgId, type);
}

export function createDemoAgentConnector$(connectorsService: ConnectorsService, organisation: Organisation): Observable<AgentConnector> {
  const newAgentConnector = getDemoAgentConnector(organisation);
  const createdAgentConnector$ = createAgentConnector(connectorsService, newAgentConnector);
  return createDemoAgentConnectorHandleErrors$(connectorsService, createdAgentConnector$, organisation.id);
}

/**
 * If the connector already exists then we need to delete all instances
 */
export function createDemoAgentConnectorHandleErrors$(
  connectorsService: ConnectorsService,
  createdAgentConnector$: Observable<AgentConnector>,
  orgId: string
): Observable<AgentConnector> {
  return createdAgentConnector$.pipe(
    concatMap((resp) => {
      return of(resp);
    }),
    catchError((err) => {
      const existingData: AgentConnector = getExistingData(err);
      if (!existingData) {
        return throwError(err);
      }
      return getDemoConnector$(connectorsService, existingData.spec.org_id, ConnectorSpec.ConnectorTypeEnum.agent).pipe(
        concatMap((getConnectorResp) => {
          const connectorInstances = getConnectorResp.status.instances;
          return deleteAllInstances$(connectorsService, connectorInstances, getConnectorResp.metadata.id, orgId).pipe(
            map((_) => existingData)
          );
        })
      );
    })
  );
}

export function getDemoAuthenticationDocument(userId: string, organisation: Organisation): AuthenticationDocument {
  return getAuthenticationDocument(userId, organisation);
}

export function createDemoAuthenticationDocument$(
  tokensService: TokensService,
  userId: string,
  organisation: Organisation
): Observable<AuthenticationDocument> {
  return createAuthenticationDocument(tokensService, userId, organisation);
}

export function createDemoConnectorWithAuthenticationDocument$(
  connectorsService: ConnectorsService,
  tokensService: TokensService,
  organisation: Organisation
): Observable<[AgentConnector, AuthenticationDocument]> {
  return createDemoAgentConnector$(connectorsService, organisation).pipe(
    concatMap((connectorResp) => {
      return createDemoAuthenticationDocument$(tokensService, connectorResp.status.service_account_id, organisation).pipe(
        concatMap((authDocResp) => {
          return forkJoin([of(connectorResp), of(authDocResp)]);
        })
      );
    })
  );
}

export function getDemoUpstreamService(): UpstreamService {
  return {
    hostname: 'localhost',
    port: 8000,
    expose_type: 'application',
    protocol_config: {
      http_config: {
        disable_http2: false,
      },
    },
  };
}

export function getDemoApplicationName(): string {
  return 'demo-container';
}

export function getDemoApplicationModel(): ApplicationModel {
  const demoApplicationModel = getDefaultApplicationModel();
  demoApplicationModel.is_demo = true;
  demoApplicationModel.name = getDemoApplicationName();
  demoApplicationModel.description = 'An application demonstrating a connector and various resources';
  demoApplicationModel.category = 'demo';
  demoApplicationModel.hosting.enabled = true;
  demoApplicationModel.hosting.on_prem.enabled = true;
  demoApplicationModel.hosting.on_prem.upstream_services = [getDemoUpstreamService()];
  demoApplicationModel.hosting.on_prem.connector_name = getDemoAgentConnectorName();
  demoApplicationModel.hosting.on_prem.agent.enabled = true;
  demoApplicationModel.hosting.in_cloud.enabled = true;
  demoApplicationModel.hosting.in_cloud.runtime.enabled = true;
  demoApplicationModel.hosting.in_cloud.runtime.own.enabled = true;
  demoApplicationModel.hosting.in_cloud.runtime.own.image = 'cr.agilicus.com/applications/kde-vnc';
  demoApplicationModel.hosting.in_cloud.runtime.own.port = 8000;
  demoApplicationModel.hosting.in_cloud.runtime.own.version_tag = 'latest';
  demoApplicationModel.authentication.enabled = true;
  demoApplicationModel.authentication.proxy.enabled = true;
  demoApplicationModel.authentication.proxy.logout_url = '/logout.html';
  demoApplicationModel.authorization.single_role_users = true;
  return demoApplicationModel;
}

export function getDemoEnvConfigVars(
  authDoc: AuthenticationDocument,
  organisation: Organisation,
  connectorId: string
): Array<EnvironmentConfigVar> {
  delete authDoc._builtin_original;
  return [
    { name: 'AGILICUS_AUTHDOC', value: JSON.stringify(authDoc) },
    { name: 'AGILICUS_ISSUER', value: organisation.issuer },
    { name: 'AGILICUS_CONNECTOR_ID', value: connectorId },
  ];
}

export function getDemoEnvConfigVarsList(
  authDoc: AuthenticationDocument,
  organisation: Organisation,
  connectorId: string
): EnvironmentConfigVarList {
  return { configs: getDemoEnvConfigVars(authDoc, organisation, connectorId) };
}

export function getDemoDesktopName(): string {
  return 'demo-desktop';
}

export function getDemoDesktopModel(): DesktopModel {
  const demoDesktopModel = defaultDesktopModel();
  demoDesktopModel.is_demo = true;
  demoDesktopModel.name = getDemoDesktopName();
  demoDesktopModel.address = 'localhost';
  demoDesktopModel.desktop_type = DesktopResourceSpec.DesktopTypeEnum.vnc;
  demoDesktopModel.connector_name = getDemoAgentConnectorName();
  demoDesktopModel.config = {
    ports: [
      {
        port: '5900',
        protocol: NetworkPortRange.ProtocolEnum.tcp,
      },
    ],
    dynamic_source_port_override: false,
  };
  return demoDesktopModel;
}

export function getDemoShareName(): string {
  return 'demo-share';
}

export function getDemoShareModel(): FileShareModel {
  const demoShareModel = defaultFileShareModel();
  demoShareModel.is_demo = true;
  demoShareModel.name = getDemoShareName();
  demoShareModel.share_name = 'demo-share';
  demoShareModel.connector_name = getDemoAgentConnectorName();
  demoShareModel.local_path = '/tmp';
  return demoShareModel;
}

export function getDemoGroupName(): string {
  return 'demo-group';
}

export function getDemoGroup(applicationModel: ApplicationModel, orgId: string): Group {
  const newGroup: Group = {
    first_name: getDemoGroupName(),
    roles: {},
    org_id: orgId,
  };
  newGroup.roles[applicationModel.name] = [getDefaultApplicationRoleName()];
  return newGroup;
}

export function createDemoGroupWithApplicationPermissions$(
  groupsService: GroupsService,
  applicationModel: ApplicationModel,
  organisation: Organisation
): Observable<Group> {
  const newGroup = getDemoGroup(applicationModel, organisation.id);
  const createdGroup$ = createNewGroup$(groupsService, newGroup);
  const creationData: ApiDataCreation<GroupsService, Group> = {
    service: groupsService,
    newData: newGroup,
    updateExistingFunc: updateExistingGroup$,
  };
  return createNewApiDataHandleErrors(createdGroup$, creationData);
}

export function addUserToDemoGroup$(groupsService: GroupsService, currentUser: User, orgID: string, demoGroupId: string): Observable<User> {
  return createNewApiDataHandleErrors(addNewGroupMember$(groupsService, demoGroupId, currentUser.id, orgID));
}

export function createAndSetupDemoGroup$(
  groupsService: GroupsService,
  applicationModel: ApplicationModel,
  currentUser: User,
  organisation: Organisation
): Observable<User | undefined> {
  return createDemoGroupWithApplicationPermissions$(groupsService, applicationModel, organisation).pipe(
    concatMap((newGroupResp) => {
      if (!currentUser) {
        console.log('user not logged in creating demo group');
        return of(undefined);
      }
      return addUserToDemoGroup$(groupsService, currentUser, organisation.id, newGroupResp.id);
    })
  );
}

export function getDemoGroupFromApi$(groupsService: GroupsService, organisation: Organisation): Observable<Group | undefined> {
  return groupsService
    .listGroups({
      org_id: organisation.id,
      type: ['group'],
      first_name: getDemoGroupName(),
    })
    .pipe(
      map((groupListResp) => {
        return groupListResp.groups.find((group) => group.first_name === getDemoGroupName());
      })
    );
}

export function getDefaultResourceRoleName(): string {
  return 'owner';
}

function getDemoResourcePermission(
  group: Group,
  orgId: string,
  resourceId: string,
  resourceType: ResourcePermissionSpec.ResourceTypeEnum,
  listResourceRoleResp: ListResourceRolesResponse
): ResourcePermission {
  const tagretResourceRole = listResourceRoleResp.resource_roles.find((role) => role.spec.role_name === getDefaultResourceRoleName());
  return {
    spec: {
      user_id: group.id,
      org_id: orgId,
      resource_id: resourceId,
      resource_type: resourceType,
      resource_role_name: !!tagretResourceRole ? tagretResourceRole.spec.role_name : listResourceRoleResp.resource_roles[0].spec.role_name,
    },
  };
}

export function createNewDemoResourcePermission$(
  permissionsService: PermissionsService,
  resourcePermission: ResourcePermission
): Observable<ResourcePermission> {
  const createdResourcePermission$ = createNewResourcePermissionAndHandleConflict$(permissionsService, resourcePermission);
  return createNewApiDataHandleErrors(createdResourcePermission$);
}

export function assignDemoResourcePermissions$(
  groupsService: GroupsService,
  permissionsService: PermissionsService,
  organisation: Organisation,
  resourceId: string,
  resourceType: ResourcePermissionSpec.ResourceTypeEnum
): Observable<ResourcePermission> {
  return getDemoGroupFromApi$(groupsService, organisation).pipe(
    concatMap((demoGroupResp) => {
      const listResourceRole$ = permissionsService
        .listResourceRoles({
          org_id: organisation.id,
          resource_type: resourceType,
          resource_role_name: getDefaultResourceRoleName(),
        })
        .pipe(catchError((_) => of(undefined)));
      return forkJoin([of(demoGroupResp), listResourceRole$]);
    }),
    concatMap(([demoGroupResp, listResourceRoleResp]) => {
      let createResourcePermission$: Observable<ResourcePermission | undefined> = of(undefined);
      if (!!listResourceRoleResp) {
        const resourcePermission = getDemoResourcePermission(
          demoGroupResp,
          organisation.id,
          resourceId,
          resourceType,
          listResourceRoleResp
        );
        createResourcePermission$ = createNewDemoResourcePermission$(permissionsService, resourcePermission);
      }
      return createResourcePermission$;
    })
  );
}

export function getDemoUpstreamServiceName(): string {
  return getSelfHostedUpstreamServiceName(getDemoApplicationModel(), 0);
}

export function getAllDemoResources$(
  applicationsService: ApplicationsService,
  issuersService: IssuersService,
  groupService: GroupsService,
  applicationServicesService: ApplicationServicesService,
  connectorsService: ConnectorsService,
  orgId: string
): Observable<[Application, Array<IssuerClient>, Group, DesktopResource, FileShareService, ApplicationService, Connector]> {
  const demoApplication$ = getApplicationByName$(applicationsService, getDemoApplicationName(), orgId);
  const demoIssuerClients$ = getDemoIssuerClientsByApplicationName$(issuersService, getDemoApplicationName(), orgId);
  const demoGroup$ = getGroupByName$(groupService, getDemoGroupName(), orgId);
  const demoDesktop$ = getDesktopResourceByName$(applicationServicesService, getDemoDesktopName(), orgId);
  const demoShare$ = getFileShareServiceByName$(applicationServicesService, getDemoShareName(), orgId);
  const demoApplicationService$ = getApplicationServiceByName$(applicationServicesService, getDemoUpstreamServiceName(), orgId);
  const demoConnector$ = getConnectorByName$(connectorsService, getDemoAgentConnectorName(), orgId);
  return combineLatest([
    demoApplication$,
    demoIssuerClients$,
    demoGroup$,
    demoDesktop$,
    demoShare$,
    demoApplicationService$,
    demoConnector$,
  ]);
}

/**
 * Since the Issuer Client name is modified in the back-end to include the guid,
 * we need to find it based on the matching application name.
 */
export function getDemoIssuerClientsByApplicationName$(
  issuersService: IssuersService,
  appName: string,
  orgId: string
): Observable<Array<IssuerClient> | undefined> {
  return issuersService
    .listClients({
      org_id: orgId,
    })
    .pipe(
      map((resp) => {
        const filteredIssuerClientsList = resp.clients.filter((client) => client.application === appName);
        return filteredIssuerClientsList;
      })
    );
}

export function deleteDemoIssuerClientsByApplicationName$(
  issuersService: IssuersService,
  appName: string,
  orgId: string
): Observable<Array<IssuerClient> | undefined> {
  return getDemoIssuerClientsByApplicationName$(issuersService, appName, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp || getResp.length === 0) {
        return of(undefined);
      }
      const observablesArray: Array<Observable<IssuerClient>> = [];
      for (const issuerClient of getResp) {
        observablesArray.push(deleteIssuerClient$(issuersService, issuerClient).pipe(map((deleteResp) => issuerClient)));
      }
      return forkJoin([...observablesArray]);
    })
  );
}

export function getCreateDemoButtonName(): string {
  return 'CREATE INITIAL DEMO SETUP';
}

export function getDeleteDemoButtonName(): string {
  return 'DESTROY INITIAL DEMO SETUP';
}
