<div *ngIf="!!data.chiplistInput" class="dialog-container">
  <mat-dialog-content>
    <h2>Update labels for all selected resources</h2>

    <div class="main-container">
      <portal-custom-chiplist-input
        [chiplistInput]="data.chiplistInput"
        [element]="data.placeholderElement"
        placeholder = 'Labels'
        [filterChipOptions]="filterChipOptions"
        [isChipRemovable]="filterChipOptions.removable"
        [keyTabManager]="keyTabManager"
        (removeChip)="removeChip($event, data.placeholderElement.labels)"
      >
      </portal-custom-chiplist-input>
    </div>
    
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="onConfirmClick()" [disabled]="disableSaveButton()" tabindex="2">REPLACE ALL</button>
    <button mat-raised-button mat-dialog-close tabindex="-1">CANCEL</button>
  </mat-dialog-actions>
</div>