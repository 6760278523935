import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { StartStepComponent } from './components/start-step/start-step.component';
import { StepperFormFieldComponent } from './components/stepper-form-field/stepper-form-field/stepper-form-field.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatRadioModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTooltipModule],
  declarations: [StartStepComponent, StepperFormFieldComponent],
  exports: [StartStepComponent, StepperFormFieldComponent],
})
export class StepperModule {}
