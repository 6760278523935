import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TableElement } from '../../table-layout/table-element';

export enum ButtonColor {
  BASIC = 'basic',
  PRIMARY = 'primary',
  ACCENT = 'accent',
  WARN = 'warn',
}

export interface TableButton {
  buttonName: string;
  buttonColor: ButtonColor;
  tooltipText: string;
  ariaLabelText: string;
  disabledButtonTooltipText: (elements?: Array<TableElement>) => string;
  disabledButtonAriaLabelText: string;
  isDisabled: (data?: Array<any>) => boolean;
  isHidden: (data?: Array<any>) => boolean;
  callback: (elements: Array<TableElement>) => void;
}

// TableScopedButton is always enabled
export class TableScopedButton implements TableButton {
  public disabledButtonTooltipText = () => '';
  public disabledButtonAriaLabelText = '';
  public isDisabled(data?: Array<TableElement>): boolean {
    return false;
  }
  public isHidden(data?: Array<TableElement>): boolean {
    return false;
  }
  constructor(
    public buttonName: string,
    public buttonColor: ButtonColor,
    public tooltipText: string,
    public ariaLabelText: string,
    public callback: (elements: Array<TableElement>) => void
  ) {}
}

// RowScopedButton is enabled when at least one element is checked
export class RowScopedButton implements TableButton {
  public isDisabled(data: Array<any>): boolean {
    for (const element of data) {
      if (element.isChecked) {
        return false;
      }
    }
    return true;
  }
  public isHidden(data?: Array<TableElement>): boolean {
    return false;
  }

  constructor(
    public buttonName: string,
    public buttonColor: ButtonColor,
    public tooltipText: string,
    public ariaLabelText: string,
    public disabledButtonTooltipText: (elements?: Array<TableElement>) => string,
    public disabledButtonAriaLabelText: string,
    public callback: (elements: Array<TableElement>) => void
  ) {}
}

@Component({
  selector: 'portal-table-button',
  templateUrl: './table-button.component.html',
  styleUrls: ['./table-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableButtonComponent {
  @Input() public buttonName = '';
  @Input() public buttonColor = 'primary';
  @Input() public tooltipText = '';
  @Input() public ariaLabelText = '';
  @Input() public disabledButtonTooltipText = '';
  @Input() public disabledButtonAriaLabelText = '';
  @Input() public disableButton = false;
  @Input() public disableTooltip = true;
  @Input() public hideButton = false;
  @Output() private buttonClick = new EventEmitter<any>();

  constructor() {}

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
