// TODO: rename file & folder to policy

import { Action } from '@ngrx/store';
import { Issuer, FileSummary, Policy, _PolicyRule, PolicyRule, PolicyGroup } from '@agilicus/angular';

export enum PolicyActionTypes {
  SET_THEME = '[Policy] SetTheme',
  SAVING_THEME = '[Policy] SavingTheme',
  SUCCESSFUL_THEME_SAVE = '[Policy] SuccessfulThemeSave',
  FAILED_THEME_SAVE = '[Policy] FailedThemeSave',
  UPDATING_ISSUER_THEME_ID = '[Policy] UpdatingIssuerThemeID',
  DELETING_ISSUER_THEME_ID = '[Policy] DeletingIssuerThemeID',
  DELETING_THEME = '[Policy] DeletingTheme',
  SUCCESSFUL_THEME_DELETION = '[Policy] SuccessfulThemeDeletion',
  FAILED_THEME_DELETION = '[Policy] FailedThemeDeletion',
  RESET_THEME = '[Policy] ResetTheme',
  RESET_THEME_UPLOAD_STATUS = '[Policy] ResetThemeUploadStatus',
  LOAD_POLICY = '[Policy] LoadPolicy',
  SET_POLICY = '[Policy] SetPolicy',
  SAVING_POLICY = '[Policy] SavingPolicy',
  SUCCESSFUL_POLICY_SAVE = '[Policy] SuccessfulPolicySave',
  FAILED_POLICY_SAVE = '[Policy] FailedPolicySave',
  SET_POLICY_RULE_LIST = '[Policy] SetPolicyRuleList',
  FAILED_POLICY_RULE_LOAD = '[Policy] FailedPolicyRuleLoad',
  SAVING_POLICY_RULE = '[Policy] SavingPolicyRule',
  ADD_TO_POLICY_RULE_LIST = '[Policy] AddToPolicyRuleList',
  UPDATE_POLICY_RULE_LIST = '[Policy] UpdatePolicyRuleList',
  DELETING_POLICY_RULE = '[Policy] DeletingPolicyRule',
  REMOVE_FROM_POLICY_RULE_LIST = '[Policy] RemoveFromPolicyRuleList',
  DELETING_POLICY_GROUP = '[Policy] DeletingPolicyGroup',
  SUCCESSFUL_POLICY_RULES_DELETION = '[Policy] SuccessfulPolicyRulesDeletion',
  FAILED_POLICY_RULES_DELETION = '[Policy] FailedPolicyRulesDeletion',
  REFRESH_ISSUER = '[Policy] RefreshIssuer',
  MAINTAIN_STATE = '[Policy] MaintainState',
}

export class ActionPolicySetTheme implements Action {
  public readonly type = PolicyActionTypes.SET_THEME;
  constructor(readonly current_theme: FileSummary) {
    this.current_theme = current_theme;
  }
}

export class ActionPolicySavingTheme implements Action {
  public readonly type = PolicyActionTypes.SAVING_THEME;
  constructor(readonly new_file: File) {
    this.new_file = new_file;
  }
}

export class ActionPolicySuccessfulThemeSave implements Action {
  public readonly type = PolicyActionTypes.SUCCESSFUL_THEME_SAVE;
  constructor(readonly new_theme: FileSummary) {
    this.new_theme = new_theme;
  }
}

export class ActionPolicyFailedThemeSave implements Action {
  public readonly type = PolicyActionTypes.FAILED_THEME_SAVE;
  constructor() {}
}

export class ActionPolicyUpdatingIssuerThemeId implements Action {
  public readonly type = PolicyActionTypes.UPDATING_ISSUER_THEME_ID;
  constructor(readonly new_theme_id: string) {
    this.new_theme_id = new_theme_id;
  }
}

export class ActionPolicyDeletingIssuerThemeId implements Action {
  public readonly type = PolicyActionTypes.DELETING_ISSUER_THEME_ID;
  constructor() {}
}

export class ActionPolicyDeletingTheme implements Action {
  public readonly type = PolicyActionTypes.DELETING_THEME;
  constructor(readonly theme_to_delete: FileSummary) {
    this.theme_to_delete = theme_to_delete;
  }
}

export class ActionPolicySuccessfulThemeDeletion implements Action {
  public readonly type = PolicyActionTypes.SUCCESSFUL_THEME_DELETION;
  constructor() {}
}

export class ActionPolicyFailedThemeDeletion implements Action {
  public readonly type = PolicyActionTypes.FAILED_THEME_DELETION;
  constructor() {}
}

export class ActionPolicyResetTheme implements Action {
  public readonly type = PolicyActionTypes.RESET_THEME;
  constructor() {}
}

export class ActionPolicyResetThemeUploadStatus implements Action {
  public readonly type = PolicyActionTypes.RESET_THEME_UPLOAD_STATUS;
  constructor() {}
}

export class ActionPolicyLoadPolicy implements Action {
  public readonly type = PolicyActionTypes.LOAD_POLICY;
  constructor() {}
}

export class ActionPolicySetPolicy implements Action {
  public readonly type = PolicyActionTypes.SET_POLICY;
  constructor(readonly issuer_policy: Policy) {}
}

export class ActionPolicySavingPolicy implements Action {
  public readonly type = PolicyActionTypes.SAVING_POLICY;
  constructor(readonly issuer_policy: Policy, readonly overwrite_policy: boolean) {}
}

export class ActionPolicySuccessfulPolicySave implements Action {
  public readonly type = PolicyActionTypes.SUCCESSFUL_POLICY_SAVE;
  constructor(readonly issuer_policy: Policy) {}
}

export class ActionPolicyFailedPolicySave implements Action {
  public readonly type = PolicyActionTypes.FAILED_POLICY_SAVE;
  constructor() {}
}

export class ActionPolicySetPolicyRuleList implements Action {
  public readonly type = PolicyActionTypes.SET_POLICY_RULE_LIST;
  constructor(readonly current_policy_rules_list: Array<PolicyRule>) {}
}

export class ActionPolicyFailedPolicyRuleLoad implements Action {
  public readonly type = PolicyActionTypes.FAILED_POLICY_RULE_LOAD;
  constructor() {}
}

export class ActionPolicySavingPolicyRule implements Action {
  public readonly type = PolicyActionTypes.SAVING_POLICY_RULE;
  constructor(readonly api_obj: PolicyRule, readonly policyGroupId?: string) {}
}

export class ActionPolicyAddToPolicyRuleList implements Action {
  public readonly type = PolicyActionTypes.ADD_TO_POLICY_RULE_LIST;
  constructor(readonly api_obj: PolicyRule) {}
}

export class ActionPolicyUpdatePolicyRuleList implements Action {
  public readonly type = PolicyActionTypes.UPDATE_POLICY_RULE_LIST;
  constructor(readonly api_obj: PolicyRule) {}
}

export class ActionPolicyDeletingPolicyRule implements Action {
  public readonly type = PolicyActionTypes.DELETING_POLICY_RULE;
  constructor(readonly api_obj: PolicyRule) {}
}

export class ActionPolicyRemoveFromPolicyRuleList implements Action {
  public readonly type = PolicyActionTypes.REMOVE_FROM_POLICY_RULE_LIST;
  constructor(readonly api_obj: PolicyRule) {}
}

export class ActionPolicySuccessfulPolicyRulesDeletion implements Action {
  public readonly type = PolicyActionTypes.SUCCESSFUL_POLICY_RULES_DELETION;
  constructor() {}
}

export class ActionPolicyFailedPolicyRulesDeletion implements Action {
  public readonly type = PolicyActionTypes.FAILED_POLICY_RULES_DELETION;
  constructor() {}
}

export class ActionPolicyDeletingPolicyGroup implements Action {
  public readonly type = PolicyActionTypes.DELETING_POLICY_GROUP;
  constructor(readonly policyGroupToDelete: PolicyGroup) {}
}

export class ActionPolicyRefreshIssuer implements Action {
  public readonly type = PolicyActionTypes.REFRESH_ISSUER;
  constructor(readonly current_issuer: Issuer) {
    this.current_issuer = current_issuer;
  }
}

export class ActionPolicyMaintainState implements Action {
  public readonly type = PolicyActionTypes.MAINTAIN_STATE;
  constructor() {}
}

export type PolicyActions =
  | ActionPolicySetTheme
  | ActionPolicySavingTheme
  | ActionPolicySuccessfulThemeSave
  | ActionPolicyFailedThemeSave
  | ActionPolicyUpdatingIssuerThemeId
  | ActionPolicyDeletingIssuerThemeId
  | ActionPolicyDeletingTheme
  | ActionPolicySuccessfulThemeDeletion
  | ActionPolicyFailedThemeDeletion
  | ActionPolicyResetTheme
  | ActionPolicyResetThemeUploadStatus
  | ActionPolicySetPolicy
  | ActionPolicySavingPolicy
  | ActionPolicySuccessfulPolicySave
  | ActionPolicyFailedPolicySave
  | ActionPolicySetPolicyRuleList
  | ActionPolicyFailedPolicyRuleLoad
  | ActionPolicySavingPolicyRule
  | ActionPolicyAddToPolicyRuleList
  | ActionPolicyUpdatePolicyRuleList
  | ActionPolicyDeletingPolicyRule
  | ActionPolicyRemoveFromPolicyRuleList
  | ActionPolicyDeletingPolicyGroup
  | ActionPolicySuccessfulPolicyRulesDeletion
  | ActionPolicyFailedPolicyRulesDeletion
  | ActionPolicyRefreshIssuer
  | ActionPolicyMaintainState;
