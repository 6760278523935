import { createSelector, createFeatureSelector } from '@ngrx/store';
import { UserState } from './user.models';

export const selectUserSelector = createFeatureSelector<UserState>('user');

export const selectApiOrgId = createSelector(selectUserSelector, (state: UserState) => state.current_org_id);

export const selectCurrentOrg = createSelector(selectUserSelector, (state: UserState) => state.current_org);

export const selectBaseOrgId = createSelector(selectUserSelector, (state: UserState) => state.base_org_id);

export const selectUser = createSelector(selectUserSelector, (state: UserState) => state.user);

export const selectMemberOrgs = createSelector(selectUserSelector, (state: UserState) => state.member_orgs);

export const selectIssuer = createSelector(selectUserSelector, (state: UserState) => {
  if (!state.current_org) {
    return undefined;
  }
  return state.current_org.issuer;
});

export const selectIssuerId = createSelector(selectUserSelector, (state: UserState) => {
  if (!state.current_org) {
    return undefined;
  }
  return state.current_org.issuer_id;
});

export const selectAdminPortalUserMetadata = createSelector(selectUserSelector, (state: UserState) => state.admin_portal_user_metadata);

export const selectGettingStartedData = createSelector(selectUserSelector, (state: UserState) => state.getting_started_data);
