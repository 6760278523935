<portal-table-layout 
  class="table-layout"
  [tableData]="tableData"
  [columnDefs]="columnDefs"
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [rowObjectName]="rowObjectName"
  [buttonsToShow]="buttonsToShow"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  pageDescriptiveText="Create a directory which will be used for read-write scratch files."
  productGuideLink="https://www.agilicus.com/anyx-guide/product-guide-applications/#h-instance-details"
  (updateEvent)="updateEvent($event)"
  (removeSelected)="deleteSelected($event)"
>
</portal-table-layout>