import { createSelector } from '@ngrx/store';

import { selectOrganisationsState } from '../core.state';
import { OrganisationsState } from './organisations.models';
import { cloneDeep } from 'lodash-es';
import { fromJson } from '@app/shared/components/utils';

export const selectOrganisations = createSelector(selectOrganisationsState, (state: OrganisationsState) => {
  if (state === undefined) {
    return state;
  }
  // Need to clone the state since it is readonly.
  const stateCopy = cloneDeep(state);
  // Need to convert back into maps from json strings.
  const orgIdToOrgNameJson = stateCopy.org_id_to_org_name_map;
  const orgIdToOrgNameMap = fromJson(orgIdToOrgNameJson);
  stateCopy.org_id_to_org_name_map = orgIdToOrgNameMap;
  const orgNameToOrgIdJson = stateCopy.org_name_to_org_id_map;
  const orgNameToOrgIdMap = fromJson(orgNameToOrgIdJson);
  stateCopy.org_name_to_org_id_map = orgNameToOrgIdMap;
  return stateCopy;
});

export const selectCurrentOrganisation = createSelector(
  selectOrganisationsState,
  (state: OrganisationsState) => state.current_organisation
);

export const selectSubOrganisations = createSelector(selectOrganisationsState, (state: OrganisationsState) => state.sub_organisations);

export const selectAllOrganisations = createSelector(selectOrganisationsState, (state: OrganisationsState) => state.all_organisations);

export const selectOrgIdToOrgNameMap = createSelector(selectOrganisationsState, (state: OrganisationsState) => {
  if (state === undefined || state.org_id_to_org_name_map === undefined) {
    return undefined;
  }
  // Need to clone the state since it is readonly.
  const stateCopy = cloneDeep(state);
  // Need to convert back into a map from a json string.
  const orgIdToOrgNameJson = stateCopy.org_id_to_org_name_map;
  const orgIdToOrgNameMap = fromJson(orgIdToOrgNameJson);
  return orgIdToOrgNameMap;
});

export const selectOrgNameToOrgIdMap = createSelector(selectOrganisationsState, (state: OrganisationsState) => {
  if (state === undefined || state.org_name_to_org_id_map === undefined) {
    return undefined;
  }
  // Need to clone the state since it is readonly.
  const stateCopy = cloneDeep(state);
  // Need to convert back into a map from a json string.
  const orgNameToOrgIdJson = stateCopy.org_name_to_org_id_map;
  const orgNameToOrgIdMap = fromJson(orgNameToOrgIdJson);
  return orgNameToOrgIdMap;
});

export const selectCurrentOrgIssuer = createSelector(selectOrganisationsState, (state: OrganisationsState) => {
  if (!state.current_organisation) {
    return undefined;
  }
  return state.current_organisation.issuer;
});
