import { TabHeaderPosition } from './tab-header-position.enum';

export function getTabHeaderPositionFromScreenSize(isSmallScreen: boolean): TabHeaderPosition {
  if (isSmallScreen) {
    return TabHeaderPosition.below;
  }
  return TabHeaderPosition.above;
}

export function getSmallScreenSizeBreakpoint(): number {
  return 900;
}
