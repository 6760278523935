import { Directive, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AppState } from '@app/core';
import { ExpansionPanelsState } from '@app/core/models/ui/ui-model';
import { ActionUIUpdateExpansionPanelsState } from '@app/core/ui/ui.actions';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';

@Directive({
  selector: '[portalExpansionPanelState]',
})
export class AppExpansionPanelStateDirective implements OnInit, OnChanges {
  @Input() private appExpansionPanelState: ExpansionPanelsState;
  @Input() private panelId: string;
  @Input() private initializeAsOpen = false;
  private appExpansionPanelStateCopy: ExpansionPanelsState;

  constructor(private store: Store<AppState>, private parentComponent: MatExpansionPanel) {}

  @HostListener('opened', ['$event']) private onPanelOpen(event): void {
    // Need to create a copy here to avoid "read only property" errors
    const copyOfAppExpansionPanelStateCopy = cloneDeep(this.appExpansionPanelStateCopy);
    copyOfAppExpansionPanelStateCopy.panels[this.panelId] = true;
    this.appExpansionPanelStateCopy = copyOfAppExpansionPanelStateCopy;
    this.store.dispatch(new ActionUIUpdateExpansionPanelsState(this.appExpansionPanelStateCopy));
  }

  @HostListener('closed', ['$event']) private onPanelClosed(event): void {
    // Need to create a copy here to avoid "read only property" errors
    const copyOfAppExpansionPanelStateCopy = cloneDeep(this.appExpansionPanelStateCopy);
    copyOfAppExpansionPanelStateCopy.panels[this.panelId] = false;
    this.appExpansionPanelStateCopy = copyOfAppExpansionPanelStateCopy;
    this.store.dispatch(new ActionUIUpdateExpansionPanelsState(this.appExpansionPanelStateCopy));
  }

  public ngOnInit(): void {
    const expansionStateOpened = this.appExpansionPanelStateCopy.panels[this.panelId];
    if (expansionStateOpened === undefined) {
      // If no state exists, we must add it:
      this.appExpansionPanelStateCopy.panels[this.panelId] = this.initializeAsOpen;
      this.store.dispatch(new ActionUIUpdateExpansionPanelsState(this.appExpansionPanelStateCopy));
      if (this.initializeAsOpen) {
        this.parentComponent.open();
      }
      return;
    }
    if (expansionStateOpened && !this.parentComponent.expanded) {
      this.parentComponent.open();
    } else if (!expansionStateOpened && this.parentComponent.expanded) {
      this.parentComponent.close();
    }
  }

  public ngOnChanges(): void {
    this.appExpansionPanelStateCopy = cloneDeep(this.appExpansionPanelState);
  }
}
