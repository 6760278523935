import { GetLauncherRequestParams, Launcher, LaunchersService, ListLaunchersRequestParams, patch_via_put } from '@agilicus/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export function createNewLauncher(launchersService: LaunchersService, newLauncher: Launcher): Observable<Launcher | undefined> {
  return launchersService.createLauncher({
    Launcher: newLauncher,
  });
}

export function getLauncherById(
  launchersService: LaunchersService,
  id: string,
  orgId: string | undefined
): Observable<Launcher | undefined> {
  const getLauncherRequestParams: GetLauncherRequestParams = {
    launcher_id: id,
    org_id: orgId,
  };
  return launchersService.getLauncher(getLauncherRequestParams);
}

export function getLaunchersList(launchersService: LaunchersService, orgId: string | undefined): Observable<Array<Launcher>> {
  const listLauncherRequestParams: ListLaunchersRequestParams = {
    org_id: orgId,
  };
  return launchersService.listLaunchers(listLauncherRequestParams).pipe(
    map((resp) => {
      return resp.launchers;
    })
  );
}

export function deleteExistingLauncher(launchersService: LaunchersService, id: string, orgId: string): Observable<void> {
  return launchersService.deleteLauncher({
    launcher_id: id,
    org_id: orgId,
  });
}

export function updateExistingLauncher(launchersService: LaunchersService, launcherToUpdate: Launcher): Observable<Launcher | undefined> {
  const putter = (launcher: Launcher) => {
    return launchersService.replaceLauncher({
      launcher_id: launcher.metadata.id,
      Launcher: launcher,
    });
  };
  const getter = (launcher: Launcher) => {
    return launchersService.getLauncher({
      launcher_id: launcher.metadata.id,
      org_id: launcher.spec.org_id,
    });
  };
  return patch_via_put(launcherToUpdate, getter, putter);
}
