import {
  BillingAccount,
  BillingAccountStatus,
  BillingOrgSubscription,
  BillingService,
  BillingSubscription,
  ListBillingAccountsRequestParams,
  Organisation,
} from '@agilicus/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { convertDateToShortReadableFormat, convertSecondsToDays, getCurrentDateInSeconds } from '@app/shared/components/date-utils';
import { catchError, map, Observable, of } from 'rxjs';
import { BillingAccountFull } from '../models/billing/billing-account-full';
import { BillingPaymentStatus } from '../models/billing/billing-payment-status.enum';
import { BillingAccountStatusFull } from '../models/billing/billing-account-status-full';
import { BillingSubscriptionFull, BillingSubscriptionStripe } from '../models/billing/billing-subscription-full';
import { pluralizeString } from '@app/shared/components/utils';

export interface DatePeriodData {
  daysRemaining: number | null;
}

export interface TrialPeriodData extends DatePeriodData {
  percentageRemaining: number | null;
}

export interface CurrentUsageAlertData {
  metric: string;
  minUsage: number;
  shouldAlert: boolean;
}

export function getEmptyBillingAccountFull(): BillingAccountFull {
  return {
    status: {
      orgs: [],
      subscriptions: [],
      customer: {
        name: '',
        email: '',
      },
      products: [],
    },
    metadata: undefined,
    spec: undefined,
  };
}

export function getListOfBillingAccountFullAsList(billingService: BillingService, orgId: string): Observable<Array<BillingAccountFull>> {
  const params: ListBillingAccountsRequestParams = {
    org_id: orgId,
    get_subscription_data: true,
    get_customer_data: true,
    get_usage_metrics: true,
  };
  return billingService.listBillingAccounts(params).pipe(
    map((resp) => {
      const billingAccounts = resp.billing_accounts;
      if (!billingAccounts) {
        return [];
      }
      return billingAccounts.map((billingAccount) => billingAccountToFull(billingAccount));
    }),
    catchError((err: HttpErrorResponse) => {
      if (err.status === 404) {
        return of([]);
      }
      // otherwise rethrow
      throw err;
    })
  );
}

export function getBillingAccountFull(billingService: BillingService, orgId: string): Observable<BillingAccountFull | undefined> {
  const params: ListBillingAccountsRequestParams = {
    org_id: orgId,
    get_subscription_data: true,
    get_customer_data: true,
    get_usage_metrics: true,
  };
  return billingService.listBillingAccounts(params).pipe(
    map((resp) => {
      const billingAccounts = resp.billing_accounts;
      if (!billingAccounts || billingAccounts.length === 0) {
        return undefined;
      }
      // Only one billing account per org:
      return billingAccountToFull(billingAccounts[0]);
    }),
    catchError((err: HttpErrorResponse) => {
      if (err.status === 404) {
        return of(undefined);
      }
      // otherwise rethrow
      throw err;
    })
  );
}

function billingOrgSubscriptionToFull(sub: BillingOrgSubscription): BillingSubscriptionFull | undefined {
  if (!sub.status?.subscription) {
    return undefined;
  }

  return {
    agilicus_subscription: sub,
    stripe_subscription: sub.status.subscription as BillingSubscriptionStripe,
  };
}

function oldSubscriptionToFull(sub: BillingSubscription): BillingSubscriptionFull | undefined {
  return {
    stripe_subscription: sub as BillingSubscriptionStripe,
  };
}

function billingAccountStatusToFull(status?: BillingAccountStatus): BillingAccountStatusFull | undefined {
  if (!status) {
    return undefined;
  }
  const oldSubscriptions = status.subscriptions ? status.subscriptions.map((sub) => oldSubscriptionToFull(sub)) : [];
  const orgSubscriptions = status.org_subscriptions
    ? status.org_subscriptions.map((sub) => billingOrgSubscriptionToFull(sub)).filter((sub) => sub !== undefined)
    : [];
  const result: BillingAccountStatusFull = {
    subscriptions: orgSubscriptions.length > 0 ? orgSubscriptions : oldSubscriptions,
    orgs: status.orgs,
    customer: status.customer,
    products: status.products,
    product: status.product,
  };

  return result;
}

function billingAccountToFull(account: BillingAccount): BillingAccountFull {
  const resp: BillingAccountFull = {
    metadata: account.metadata,
    spec: account.spec,
    status: billingAccountStatusToFull(account.status),
  };
  return resp;
}

export function getBillingAccountFullTrialPeriodData(
  billingAccount: BillingAccountFull,
  currentOrg: Organisation
): TrialPeriodData | undefined {
  if (!billingAccount?.status?.subscriptions || !currentOrg) {
    return undefined;
  }
  const currentDateInSeconds = getCurrentDateInSeconds();
  const trialPercentageRemainingList: Array<TrialPeriodData> = billingAccount.status.subscriptions
    .filter(
      (sub) =>
        !!sub &&
        sub.stripe_subscription.status !== BillingPaymentStatus.active &&
        sub.agilicus_subscription?.metadata?.id === currentOrg.billing_subscription_id
    )
    .map((sub) => sub.stripe_subscription)
    .map((sub) => {
      const totalTrialLengthInSeconds = sub.trial_end - sub.trial_start;
      const trialTimeRemainingInSeconds = sub.trial_end - currentDateInSeconds;
      const percentOfTrialTimeRemaining = (trialTimeRemainingInSeconds / totalTrialLengthInSeconds) * 100;
      const daysRemaining = Math.max(0, Math.floor(convertSecondsToDays(trialTimeRemainingInSeconds)));
      return {
        percentageRemaining: percentOfTrialTimeRemaining,
        daysRemaining: daysRemaining,
      };
    });

  if (trialPercentageRemainingList.length === 0) {
    return undefined;
  }
  const sortedTrialPercentageRemainingList = trialPercentageRemainingList.sort((lhs: TrialPeriodData, rhs: TrialPeriodData) => {
    return lhs.percentageRemaining - rhs.percentageRemaining;
  });
  // Get the trial percentage that is the smallest, ie the shortest trial period remaining.
  return sortedTrialPercentageRemainingList[0];
}

export function getAgilicusSubscriptionList(billingAccount: BillingAccountFull, currentOrg: Organisation): Array<BillingOrgSubscription> {
  const agilicusSubscriptions: Array<BillingOrgSubscription> = billingAccount.status.subscriptions
    .filter((sub) => !!sub && sub.agilicus_subscription?.metadata?.id === currentOrg.billing_subscription_id)
    .map((sub) => sub.agilicus_subscription);
  return agilicusSubscriptions;
}

export function getBillingAccountFullEstimatedBalanceEndDateData(
  billingAccount: BillingAccountFull | undefined,
  currentOrg: Organisation | undefined
): DatePeriodData | undefined {
  if (!billingAccount?.status?.subscriptions || !currentOrg) {
    return undefined;
  }
  const estimatedBalanceEndDateList: Array<DatePeriodData> = getAgilicusSubscriptionList(billingAccount, currentOrg).map((sub) => {
    const estimatedBalanceEndDate = sub?.status?.balance?.estimate_balance_end_date;
    const daysRemaining = getEstimateBalanceEndDateDaysRemaining(estimatedBalanceEndDate);
    return {
      daysRemaining: daysRemaining,
    };
  });

  if (estimatedBalanceEndDateList.length === 0) {
    return undefined;
  }
  const sortedEstimatedBalanceEndDateList = estimatedBalanceEndDateList.sort((lhs: DatePeriodData, rhs: DatePeriodData) => {
    return lhs.daysRemaining - rhs.daysRemaining;
  });
  // Get the date that is the smallest, ie the shortest period remaining.
  return sortedEstimatedBalanceEndDateList[0];
}

export function getEstimateBalanceEndDateDaysRemaining(estimateBalanceEndDate: Date | string | undefined): number | undefined {
  const currentDate = new Date();
  if (!estimateBalanceEndDate) {
    return undefined;
  }
  // May not actually be a "Date" from the sdk, so do conversion here:
  const estimateBalanceEndDateAsDate = new Date(estimateBalanceEndDate);
  const diffInMilliseconds = estimateBalanceEndDateAsDate.getTime() - currentDate.getTime();
  const daysRemaining = diffInMilliseconds / (1000 * 60 * 60 * 24);
  return daysRemaining;
}

export function getEstimateBalanceEndDateMax(): number {
  return 60;
}

export function warnOnEstimateBalanceEndDate(estimateBalanceEndDate: Date | undefined): boolean {
  if (!estimateBalanceEndDate) {
    return false;
  }
  return getEstimateBalanceEndDateDaysRemaining(estimateBalanceEndDate) <= getEstimateBalanceEndDateMax();
}

export function getEmptyCurrentUsageAlertData(): CurrentUsageAlertData {
  return {
    metric: undefined,
    minUsage: undefined,
    shouldAlert: false,
  };
}

export function shouldAlertOnCurrentUsageData(
  billingAccount: BillingAccountFull,
  currentOrg: Organisation,
  alreadyAlerted: boolean
): CurrentUsageAlertData {
  if (alreadyAlerted === true) {
    return getEmptyCurrentUsageAlertData();
  }
  if (!billingAccount?.status?.subscriptions || !currentOrg) {
    return getEmptyCurrentUsageAlertData();
  }
  const agilicusSubscriptions = getAgilicusSubscriptionList(billingAccount, currentOrg);
  for (const sub of agilicusSubscriptions) {
    const usageMetrics = sub?.status?.usage_metrics?.metrics;
    const usageOverrides = sub?.spec?.usage_override;
    if (!usageOverrides) {
      continue;
    }
    for (const override of usageOverrides) {
      const targetUsageMetric = !!usageMetrics ? usageMetrics.find((metric) => metric.type === override.metric) : undefined;
      if (shouldWarnOnCurrentUsageData(targetUsageMetric?.active?.current, override.min_quantity)) {
        return {
          metric: targetUsageMetric.type,
          minUsage: override.min_quantity,
          shouldAlert: true,
        };
      }
    }
  }
  return getEmptyCurrentUsageAlertData();
}

export function shouldWarnOnCurrentUsageData(currentUsage: number | undefined, minUsage: number | undefined): boolean {
  if (currentUsage === undefined || minUsage === undefined) {
    return false;
  }
  return currentUsage > minUsage;
}

export function getFormatedBillingDateString(dateInSeconds: number): string {
  const date = new Date(dateInSeconds * 1000);
  return convertDateToShortReadableFormat(date);
}

export function convertCentsToReadableDollarsString(cents: number | undefined, currency?: string): string {
  if (!cents) {
    return ``;
  }
  const currencyDisplayValue = getCurrencyDisplayValue(currency);
  if (currencyDisplayValue === 'CAD') {
    return (cents / 100).toLocaleString('en-CA', { style: 'currency', currency: currencyDisplayValue });
  }
  if (currencyDisplayValue === 'EUR') {
    return (cents / 100).toLocaleString('de-DE', { style: 'currency', currency: currencyDisplayValue });
  }
  // If no currency defined, default to USD:
  return (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function getCurrencyDisplayValue(currency: string | undefined): string {
  if (!currency) {
    return ``;
  }
  return currency.toUpperCase();
}

export function canOrgFetchDataFromApi(currentOrg: Organisation | undefined): boolean {
  return !!currentOrg && (!currentOrg.parent_id || currentOrg.parent_id === currentOrg.root_org_id);
}

export function canReadBillingData(currentOrg: Organisation | undefined): boolean {
  return canOrgFetchDataFromApi(currentOrg);
}

export function getSubscriptionButtonText(): string {
  return 'VIEW/UPDATE PAYMENT INFORMATION';
}

export function getDefaultPaymentMethodFromBillingAccount(
  currentBillingAccountFullResp: BillingAccountFull | undefined
): string | null | undefined {
  return currentBillingAccountFullResp?.status?.customer?.invoice_settings?.default_payment_method;
}

export function getPaymentDialogEndMessage(): string {
  return `<p><br>Contact <a href="mailto:sales@agilicus.com" target="_blank">sales@agilicus.com</a> for additional options or questions. 
  You can also talk with us directly by clicking the chat icon at the bottom right of the screen.`;
}

export function getStarterPlanMaxConnectors(): number {
  return 1;
}

export function getStarterPlanMaxUsers(): number {
  return 2;
}

export function getStarterPaymentDialogMessageBeginning(type: 'connector' | 'user'): string {
  let message = `Please be aware our Starter Product only allows for `;
  let starterPlanMax = 0;
  if (type === 'connector') {
    starterPlanMax = getStarterPlanMaxConnectors();
  } else if (type === 'user') {
    starterPlanMax = getStarterPlanMaxUsers();
  }
  if (starterPlanMax === 1) {
    message += `1 ${type}.`;
  } else {
    message += `${starterPlanMax} ${pluralizeString(type)}.`;
  }
  return message;
}

export function getRefreshBillingQueryParam(): string {
  return 'refresh_billing=true';
}

export function doNotNeedPaymentNag(
  paymentMethod: string | null | undefined,
  isStarterPlan: boolean | undefined,
  currentOrg: Organisation,
  refreshBillingDataStateValue: number
): boolean {
  if (!!paymentMethod) {
    // We already have payment on file.
    return true;
  }
  if (!canReadBillingData(currentOrg)) {
    // They do not have access to the billing data.
    return true;
  }
  if (isStarterPlan === false) {
    return true;
  }
  if (isStarterPlan === undefined && refreshBillingDataStateValue === 0) {
    // We have not yet fetched the billing account data
    return false;
  }
  return !(isStarterPlan && !paymentMethod);
}

export function isStarterPlan(billingAccount: BillingAccountFull | undefined): boolean | undefined {
  if (!billingAccount) {
    return undefined;
  }
  if (!billingAccount.status?.subscriptions) {
    return false;
  }
  for (const sub of billingAccount.status.subscriptions) {
    const targetProductLabel = sub?.agilicus_subscription?.status?.product?.spec?.label;
    if (!targetProductLabel) {
      continue;
    }
    if (targetProductLabel.toLowerCase().includes('starter')) {
      return true;
    }
  }
  return false;
}

export function isStarterPlanWithoutPayment(isStarterPlan: boolean | undefined, paymentMethod: string | null | undefined): boolean {
  return !!isStarterPlan && !paymentMethod;
}

export function noPaymentOnFile(billingAccount: BillingAccountFull | undefined): boolean | undefined {
  if (!billingAccount) {
    return undefined;
  }
  if (!billingAccount.status?.customer?.address) {
    return true;
  }
  return billingAccount.status.customer.address.country === 'AQ' || billingAccount.status.customer.address.city === null;
}
