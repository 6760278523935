import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { Environment, RuntimeStatus } from '@agilicus/angular';
import { getEmptyStringIfUnset, getStatusIcon, getStatusIconColor, capitalizeFirstLetter } from '../utils';
import { OptionalEnvironmentElement } from '../optional-types';

@Component({
  selector: 'portal-application-environment-status',
  templateUrl: './application-environment-status.component.html',
  styleUrls: ['./application-environment-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationEnvironmentStatusComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private appState$: Observable<ApiApplicationsState>;
  public currentEnvironment: Environment;
  public statusForm: UntypedFormGroup;

  constructor(private store: Store<AppState>, private formBuilder: UntypedFormBuilder, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.appState$ = this.store.pipe(select(selectApiApplications));
    this.appState$.subscribe((appStateResp) => {
      if (appStateResp === undefined || appStateResp.current_environment === undefined) {
        return;
      }
      this.currentEnvironment = appStateResp.current_environment;
      this.checkForStatusAndInitializeFormGroup();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private checkForStatusAndInitializeFormGroup(): void {
    if (this.currentEnvironment.status) {
      this.initializeStatusFormGroup(this.currentEnvironment.status.runtime_status);
    } else {
      this.initializeStatusFormGroup(undefined);
    }
  }

  private initializeStatusFormGroup(runtimeStatus: RuntimeStatus | undefined): void {
    this.statusForm = this.formBuilder.group({
      overall_status: [
        {
          value: runtimeStatus ? capitalizeFirstLetter(getEmptyStringIfUnset(runtimeStatus.overall_status)) : '',
          disabled: true,
        },
      ],
      running_replicas: [
        {
          value: runtimeStatus ? getEmptyStringIfUnset(runtimeStatus.running_replicas) : '',
          disabled: true,
        },
      ],
      error_message: [
        {
          value: runtimeStatus ? getEmptyStringIfUnset(runtimeStatus.error_message) : '',
          disabled: true,
        },
      ],
      restarts: [
        {
          value: runtimeStatus ? getEmptyStringIfUnset(runtimeStatus.restarts) : '',
          disabled: true,
        },
      ],
      last_apply_time: [
        {
          value: runtimeStatus ? getEmptyStringIfUnset(runtimeStatus.last_apply_time) : '',
          disabled: true,
        },
      ],
      running_image: [
        {
          value: runtimeStatus ? getEmptyStringIfUnset(runtimeStatus.running_image) : '',
          disabled: true,
        },
      ],
      running_hash: [
        {
          value: runtimeStatus ? getEmptyStringIfUnset(runtimeStatus.running_hash) : '',
          disabled: true,
        },
      ],
    });
    this.changeDetector.detectChanges();
  }

  public getInstanceStatusIcon(): string {
    if (this.currentEnvironment.status && this.currentEnvironment.status.runtime_status) {
      const envElement: OptionalEnvironmentElement = { overallStatus: this.currentEnvironment.status.runtime_status.overall_status };
      return getStatusIcon(envElement.overallStatus);
    }
    return '';
  }

  public getInstanceStatusIconColor(): string {
    if (this.currentEnvironment.status && this.currentEnvironment.status.runtime_status) {
      const envElement: OptionalEnvironmentElement = { overallStatus: this.currentEnvironment.status.runtime_status.overall_status };
      return getStatusIconColor(envElement.overallStatus);
    }
    return '';
  }

  public getStatusPlaceholder(): string {
    if (
      this.currentEnvironment.status &&
      this.currentEnvironment.status.runtime_status &&
      this.currentEnvironment.status.runtime_status.overall_status
    ) {
      return '';
    }
    return 'Status';
  }
}
