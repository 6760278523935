import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { Connector, Organisation, User } from '@agilicus/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/core';
import {
  ActionApiApplicationsResetFileShareServiceModel,
  ActionApiApplicationsSubmittingFileShareServiceModel,
} from '@app/core/api-applications/api-applications.actions';
import {
  selectApiApplicationsFileShareModel,
  selectApiApplicationsFileShareModelStatus,
} from '@app/core/api-applications/api-applications.selectors';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { FileShareModel } from '@app/core/models/file-share/file-share-model';
import { cloneDeep } from 'lodash-es';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { capitalizeFirstLetter, getEmptyStringIfUnset, modifyDataOnFormBlur, pluralizeString } from '../utils';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { delayStepperAdvanceOnSuccessfulApply, handleStateOnFirstStepSelection } from '../application-template-utils';
import { MatStepper } from '@angular/material/stepper';
import { DefaultStepperState } from '../default-stepper-state';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { StepperType } from '../stepper-type.enum';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorList } from '@app/core/connector-state/connector.selectors';
import { ResourceType } from '../resource-type.enum';
import { ProfileLinkService } from '@app/core/api/profile-link/profile-link.service';
import { getTrimmedResourceName } from '../resource-utils';

@Component({
  selector: 'portal-file-shares-stepper',
  templateUrl: './file-shares-stepper.component.html',
  styleUrls: ['./file-shares-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSharesStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public currentFileShareModel: FileShareModel;
  private fileShareModelStatus: ApplicationModelStatus;
  public hasAppsPermissions: boolean;
  public hasUsersPermissions: boolean;
  public hasIssuersPermissions: boolean;
  public connectors: Array<Connector>;
  public connectorFormGroup: UntypedFormGroup;
  public shareNameFormGroup: UntypedFormGroup;
  public localPathFormGroup: UntypedFormGroup;
  public startOptionFormGroup: UntypedFormGroup;
  public completedFileShareText = `Your share setup is complete. Please review it below. Make any corrections needed above.`;
  public currentOrg: Organisation;
  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();
  public fileShareStepperState: DefaultStepperState = {
    selectedStartOption: undefined,
  };
  public stepperType = StepperType.share;
  public hasTemplates = false;
  public apiKey = '';
  public profileURL = '';

  public keyTabManager: KeyTabManager = new KeyTabManager();

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public pluralizeString = pluralizeString;
  public pageDescriptiveText = `Shares are a means of taking a directory on a local server and making the contents available to any user, without a VPN and without a client.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-shares/`;
  public orgId: string;
  public resourceType = ResourceType.fileshare;

  @ViewChild('fileSharesStepper') public stepper: MatStepper;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private customValidatorsService: CustomValidatorsService,
    private profileLinkService: ProfileLinkService
  ) {}

  public ngOnInit(): void {
    this.resetModel();
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const hasIssuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    const fileShareModelState$ = this.store.pipe(select(selectApiApplicationsFileShareModel));
    const fileShareModelStatusState$ = this.store.pipe(select(selectApiApplicationsFileShareModelStatus));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const profileLink$ = this.profileLinkService.getProfileUrlForLink$();
    combineLatest([
      hasUsersPermissions$,
      hasAppsPermissions$,
      hasIssuersPermissions$,
      currentOrg$,
      fileShareModelState$,
      fileShareModelStatusState$,
      connectorListState$,
      profileLink$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasUsersPermissionsResp,
          hasAppsPermissionsResp,
          hasIssuersPermissionsResp,
          currentOrgResp,
          fileShareModelStateResp,
          fileShareModelStatusStateResp,
          connectorListStateResp,
          profileLinkResp,
        ]: [
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          Organisation,
          FileShareModel,
          ApplicationModelStatus,
          Array<Connector>,
          string
        ]) => {
          this.orgId = hasUsersPermissionsResp?.orgId;
          this.profileURL = profileLinkResp;
          this.fileShareModelStatus = fileShareModelStatusStateResp;
          if (!this.currentFileShareModel || this.fileShareModelStatus.complete) {
            this.currentFileShareModel = cloneDeep(fileShareModelStateResp);
          }
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.hasIssuersPermissions = hasIssuersPermissionsResp.hasPermission;
          const filteredConnectorList: Array<Connector> = connectorListStateResp.filter(
            (connector) => connector.status.instances.length <= 1
          );
          this.connectors = filteredConnectorList;
          this.currentOrg = currentOrgResp;
          if (!this.fileShareModelStatus.saving) {
            this.initializeFormGroups();
          }
          delayStepperAdvanceOnSuccessfulApply(this.stepper, this.fileShareModelStatus);
        }
      );
  }

  public onStepperSelectionChange(selectedIndex: number): void {
    handleStateOnFirstStepSelection(
      selectedIndex,
      this.fileShareModelStatus,
      this.fileShareStepperState,
      this.resetModel.bind(this),
      this.initializeStartOptionFormGroup.bind(this)
    );
  }

  private initializeStartOptionFormGroup(): void {
    this.startOptionFormGroup = this.formBuilder.group({
      selectedStartOption: [this.fileShareStepperState.selectedStartOption, Validators.required],
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroups(): void {
    this.initializeConnectorFormGroup();
    this.initializeShareNameFormGroup();
    this.initializeLocalPathFormGroup();
    this.changeDetector.detectChanges();
  }

  private initializeConnectorFormGroup(): void {
    this.connectorFormGroup = this.formBuilder.group({
      connector_name: [getEmptyStringIfUnset(this.currentFileShareModel?.connector_name), [Validators.required]],
    });
  }

  private getModel(): FileShareModel | undefined {
    return this.currentFileShareModel;
  }

  private initializeShareNameFormGroup(): void {
    const fileShareService = this.getModel();
    this.shareNameFormGroup = this.formBuilder.group({
      name: [
        getEmptyStringIfUnset(fileShareService?.name),
        [Validators.required, this.customValidatorsService.resourceNameValidator()],
        [this.customValidatorsService.isDuplicateResourceName(this.orgId, this.fileShareModelStatus.complete)],
      ],
    });
  }

  private initializeLocalPathFormGroup(): void {
    const fileShareService = this.getModel();
    this.localPathFormGroup = this.formBuilder.group({
      local_path: [getEmptyStringIfUnset(fileShareService?.local_path), [Validators.required]],
    });
  }

  public hasAllPermissions(): boolean {
    return !!this.hasAppsPermissions && !!this.hasUsersPermissions && !!this.hasIssuersPermissions;
  }

  public showNoPermissionsText(): boolean {
    if (this.hasAppsPermissions === undefined || this.hasUsersPermissions === undefined || this.hasIssuersPermissions === undefined) {
      return false;
    }
    if (this.hasAllPermissions()) {
      return false;
    }
    return true;
  }

  public onFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyAppStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyAppStepperDataOnFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    let formValue = form.get(formField).value;
    if (formField === 'port') {
      formValue = parseInt(formValue, 10);
    }
    if (formField === 'name') {
      formValue = getTrimmedResourceName(formValue);
    }
    obj[formField] = formValue;
    this.updateFileShareServiceModel();
  }

  public updateConnector(connectorName: string): void {
    this.currentFileShareModel.connector_name = connectorName;
    this.updateFileShareServiceModel();
  }

  private updateFileShareServiceModel(): void {
    // We want to set the "share_name" property to match the "name" property.
    this.currentFileShareModel.share_name = this.currentFileShareModel.name;
  }

  public isAppStepperComplete(): boolean {
    return this.fileShareModelStatus.complete;
  }

  public submitFileShareModel(shareModel: FileShareModel): void {
    this.store.dispatch(new ActionApiApplicationsSubmittingFileShareServiceModel(shareModel));
  }

  private resetModel(): void {
    this.store.dispatch(new ActionApiApplicationsResetFileShareServiceModel());
  }

  public canDeactivate(): Observable<boolean> | boolean {
    this.resetModel();
    return true;
  }
}
