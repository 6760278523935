<div *ngIf="!fixedData && !!httpRewriteOptionsForm">
  <portal-page-info
    [pageDescriptiveText]="httpRewritesDescriptiveText"
    [productGuideLink]="httpRewritesProductGuideLink"
    pageInfoWidth="full"
  >
  </portal-page-info>
  <form
    [formGroup]="httpRewriteOptionsForm"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="checkbox-container">
      <mat-checkbox
        formControlName="includeUserContextHeaders"
        (change)="onCheckboxChange()"
        aria-label="Checkbox that toggles whether user context headers are sent to the application"
      >
        Include User Context Headers
      </mat-checkbox>
      <mat-icon
        class="help-icon"
        [matTooltip]="includeUserContextHeadersTooltipText"
      >
        help_outline
      </mat-icon>
    </div>

    <div class="common-path-prefix-container">
      <mat-form-field>
        <mat-label>Common Path Prefix</mat-label>
        <input
          matInput
          type="text"
          placeholder="/common_path_prefix"
          formControlName="commonPathPrefix"
          (blur)="updateCommonPathPrefixRuleOnFormBlur(httpRewriteOptionsForm, 'commonPathPrefix')"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <mat-error>Path must begin with "/"</mat-error>
      </mat-form-field>
      <mat-icon
        class="help-icon"
        [matTooltip]="redirectSubpathTooltipText"
      >
        help_outline
      </mat-icon>
    </div>

    <div class="checkbox-container">
      <mat-checkbox
        formControlName="use_recursive_replacement_system"
        (change)="onCheckboxChange()"
        aria-label="Checkbox that toggles whether the recursive replacement systemis enabled"
      >
        Use Recursive Replacement System
      </mat-checkbox>
      <mat-icon
        class="help-icon"
        [matTooltip]="useRecursiveReplacementSystemTooltipText"
      >
        help_outline
      </mat-icon>
    </div>
  </form>

  <mat-accordion class="expansion-container" multi>
    <!-- Rewrite Media Types Panel -->
    <mat-expansion-panel
      #appDefineRewriteMediaTypesPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineRewriteMediaTypesPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Rewrite Media Types
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineRewriteMediaTypesPanel)">
          Configure which types of content can be rewritten
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div
        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineRewriteMediaTypesPanel)"
        class="rewrite-media-types-content-container"
      >
        <portal-page-info
          [pageDescriptiveText]="rewriteMediaTypesDescriptiveText"
          [productGuideLink]="rewriteMediaTypesProductGuideLink"
          pageInfoWidth="full"
        >
        </portal-page-info>        
        <div class="button-container">
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="rewrite-common-media-types-button"
            (click)="onRewriteCommonMimeTypesClick()"
          >
            REWRITE COMMON MEDIA TYPES
          </button>
          <mat-icon
            class="help-icon"
            [matTooltip]="getRewriteCommonMediaTypesTooltip()"
          >
            help_outline
          </mat-icon>
        </div>

        <form
          [formGroup]="standardHeadersFormGroup"
          spellcheck="false"
          autocomplete="off"
        >
          <div class="checkbox-container">
            <mat-checkbox
              formControlName="host"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether the application will rewrite host"
            >
              Rewrite Host
            </mat-checkbox>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="location"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether the application will rewrite redirects"
            >
              Rewrite Redirects
            </mat-checkbox>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="origin"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether the application will rewrite origin"
            >
              Rewrite Origin
            </mat-checkbox>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="path"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether the rewrite acts on the path as well as the host in the uri"
            >
              Rewrite Path
            </mat-checkbox>
          </div>
        </form>

        <portal-media-types
          *ngIf="isExpansionPanelOpen(uiState, 'appDefineRewriteMediaTypesPanel')"
          [currentApplicationCopy]="currentApplicationCopy"
          [currentOrgIssuer]="currentOrgIssuer"
          (updateApplication)="updateApplicationOnAppConfigsChange($event)"
        >
        </portal-media-types>
      </div>
    </mat-expansion-panel>

    <!-- Rewrite Rules Panel -->
    <mat-expansion-panel
      #appDefineRewriteRulesPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineRewriteRulesPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Rewrite Rules
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineRewriteRulesPanel)">
          Define rules to rewrite responses from the application
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div
        *ngIf="!!rewriteRulesForm && isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineRewriteRulesPanel)"
        class="form-container"
      >
        <portal-page-info
          [pageDescriptiveText]="rewriteRulesDescriptiveText"
          [productGuideLink]="rewriteRulesProductGuideLink"
          pageInfoWidth="full"
        >
        </portal-page-info>   
        <form
          [formGroup]="rewriteRulesForm"
          spellcheck="false"
          autocomplete="off"
        >
          <mat-form-field class="extended-field">
            <input
              matInput
              type="text"
              placeholder="My application expects the http host to be..."
              formControlName="primary_internal_name"
              (blur)="onFormBlur(rewriteRulesForm, 'primary_internal_name')"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            />
            <mat-error>Primary internal name is required</mat-error>
          </mat-form-field>
          <mat-icon 
            class="help-icon" 
            matTooltip="The primary internal name is the HTTP host that the internal server expects to see. The host header of the HTTP request will be rewritten to this."
          >
            help_outline
          </mat-icon>
        </form>

        <portal-other-mappings
          [currentApplicationCopy]="currentApplicationCopy"
          [currentOrgIssuer]="currentOrgIssuer"
          (updateApplication)="updateApplicationOnAppConfigsChange($event)"
        >
        </portal-other-mappings>
      </div>
    </mat-expansion-panel>

    <!-- Response Header Overrides Panel -->
    <mat-expansion-panel
      #appDefineResponseHeaderOverridesPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineResponseHeaderOverridesPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Response Header Overrides
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineResponseHeaderOverridesPanel)">
          Customize HTTP headers in responses
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div
        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineResponseHeaderOverridesPanel)"
        class="response-header-overrides-content-container"
      >
        <portal-header-overrides
          [curentOIDCProxyHeaderUserConfig]="this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.headers?.header_overrides?.response"
          [pageDescriptiveText]="responseHeaderOverridesDescriptiveText"
          [productGuideLink]="responseHeaderOverridesProductGuideLink"
          (updateHeaderOverrides)="updateHeaderOverrides($event, 'response')"
        >
        </portal-header-overrides>
      </div>
    </mat-expansion-panel>

    <!-- Request Header Overrides Panel -->
    <mat-expansion-panel
      #appDefineRequestHeaderOverridesPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineRequestHeaderOverridesPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Request Header Overrides
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineRequestHeaderOverridesPanel)">
          Customize HTTP headers in requests
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div
        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineRequestHeaderOverridesPanel)"
        class="request-header-overrides-content-container"
      >
        <portal-header-overrides
          [curentOIDCProxyHeaderUserConfig]="this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.headers?.header_overrides?.request"
          [pageDescriptiveText]="requestHeaderOverridesDescriptiveText"
          [productGuideLink]="requestHeaderOverridesProductGuideLink"
          (updateHeaderOverrides)="updateHeaderOverrides($event, 'request')"
        >
        </portal-header-overrides>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
