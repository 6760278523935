import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core';
import { catchError, filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { selectUserSelector } from '../user/user.selectors';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInService {
  constructor(private store: Store<AppState>) {}

  public getIsLoggedIn(): Observable<boolean | undefined> {
    return this.store.select(selectUserSelector).pipe(
      // Do not return anything until we have succesfully finished processing the login
      filter((state) => !state.logging_in),
      map((userResp) => {
        return !!userResp.user;
      }),
      catchError((_) => of(undefined))
    );
  }
}
