import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AgentConfigData } from '@app/core/models/application/application-model';
import { getAgentConfigFileName } from '@app/core/models/application/application-model-api-agent-utils';
import { DynamicEnvironmentService } from '@app/core/services/dynamic-environment.init';
import {
  APIKey,
  AgentConnectorBootstrap,
  AuthenticationDocument,
  Challenge,
  ChallengeStatus,
  ChallengesService,
  ConnectorSpec,
  CreateApiKeyRequestParams,
  CreateChallengeRequestParams,
  GetChallengeRequestParams,
  TokensService,
  User,
  Connector,
  AgentConnectorProxy,
} from '@agilicus/angular';
import { downloadJSON } from '../file-utils';
import { OperatingSystemEnum } from '../operatingSystem.enum';
import { addHTTPSToUrlIfNotSet, capitalizeFirstLetter } from '../utils';
import { TabGroup, UIState } from '@app/core/ui/ui.models';
import { selectUI } from '@app/core/ui/ui.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/core';
import { agentDownloadURLs } from '../connector-download-instructions/connector-download-instructions.component';
import { ActionUIInitConnectorInstallUIState, ActionUIUpdateTabsState } from '@app/core/ui/ui.actions';
import {
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  concatMap,
  delay,
  expand,
  filter,
  map,
  of,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { addSecondsToDate } from '../date-utils';
import { selectUser } from '@app/core/user/user.selectors';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { getDefaultTourStepConfig } from '../tour.utils';
import { getCreateDemoButtonName } from '@app/core/api/demo-api-utils';
import { cloneDeep } from 'lodash-es';
import { MatTabGroup } from '@angular/material/tabs';
import { doesConnectorHaveShares, getNumberOfConnectorInstances, getNumberOfDownConnectorInstances } from '../connector-utils';

export interface ConnectorDownloadDialogData {
  connector: Connector;
  orgId: string;
  issuer?: string;
  agentConnectorProxy?: AgentConnectorProxy;
}

interface ChallengeDetails {
  challengeID: string;
  challengeCode: string;
  failed?: boolean;
}

interface DownloadInfo {
  singleLinuxInstallCmd: string;
  singleWindowsInstallCmd: string;
  singleWindowsPSInstallCmd: string;
  singleManualInstallCmd: string;
  singleContainerInstallCmd: string;
  failed?: boolean;
  challengeID?: string;
}

export enum CommandTypeEnum {
  linux = 'linux',
  cmd = 'cmd',
  power_shell = 'power_shell',
  manual = 'manual',
}

@Component({
  selector: 'portal-connector-download-dialog',
  templateUrl: './connector-download-dialog.component.html',
  styleUrls: ['../../../../styles-tab-component.scss', './connector-download-dialog.component.scss'],
})
export class ConnectorDownloadDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public connector: Connector;
  public agentConnectorProxy: AgentConnectorProxy;
  public hasShares: boolean;
  public number_of_instances = 0;
  public number_of_down_instances = 0;
  public orgId: string;
  public issuer: string;
  public singleLinuxInstallCmd: string;
  public singleLinuxArmInstallCmd: string;
  public singleFreebsdArmInstallCmd: string;
  public singleLinuxMipsbeInstallCmd: string;
  public singleLinuxMipsleInstallCmd: string;
  public singleWindowsInstallCmd: string;
  public singleWindowsPSInstallCmd: string;
  public singleManualInstallCmd: string;
  public singleConnectorInstallCmd: string;
  public configFileName: string;
  public shouldPollForCompletion: boolean = true;
  public joinClusterFormGroup: FormGroup;
  private userIdleInterval: NodeJS.Timer;
  private timerStart: number; // in milliseconds
  public showDemoCreateButton = false;
  public tourAnchorId = 'connector-dialog-demo';
  private tourSteps: Array<IMdStepOption> = [
    {
      ...getDefaultTourStepConfig(),
      stepId: '0',
      anchorId: this.tourAnchorId,
      title: 'Create Demo Setup',
      content: `I see that this is your first time here. 
      Would you like to explore a demo environment? 
      If so, click "${getCreateDemoButtonName()}".`,
    },
  ];

  public uiState: UIState;
  public tabsLength = 2;
  public tabGroupId = TabGroup.connectorInstallTabGroup;
  public localTabIndex: number;
  private localUIStateRefreshValue = 0;

  public capitalizeFirstLetter = capitalizeFirstLetter;

  // This is required in order to reference the enums in the html template.
  public operatingSystemEnum = OperatingSystemEnum;
  public commandTypeEnum = CommandTypeEnum;

  public downloadInfo$: Observable<DownloadInfo>;
  public lastDownloadInfo$: ReplaySubject<DownloadInfo> = new ReplaySubject<DownloadInfo>(1);
  public generateDownloadInfo$: Subject<void> = new ReplaySubject<void>(1);
  public defaultDownloadInfo: DownloadInfo = {
    singleLinuxInstallCmd: '',
    singleWindowsInstallCmd: '',
    singleManualInstallCmd: '',
    singleWindowsPSInstallCmd: '',
    singleContainerInstallCmd: '',
  };

  public installScopes = [
    'urn:agilicus:api:applications:reader?',
    'urn:agilicus:api:applications:owner?',
    'urn:agilicus:application_service:*:owner?',
    'urn:agilicus:api:traffic-tokens:owner',
    'urn:agilicus:token_payload:multiorg:true',
  ];
  // 20 minutes
  private apiKeyDurationSeconds = 20 * 60;

  private challengePollPeriodMilliseconds = 5000;

  @ViewChild('tabGroup', { static: false }) private tabGroup: MatTabGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ConnectorDownloadDialogData,
    private dialogRef: MatDialogRef<ConnectorDownloadDialogComponent>,
    public http: HttpClient,
    public renderer: Renderer2,
    private env: DynamicEnvironmentService,
    private store: Store<AppState>,
    private tokens: TokensService,
    private challenges: ChallengesService,
    private formBuilder: FormBuilder,
    private tourService: TourService
  ) {
    this.connector = data.connector;
    this.hasShares = doesConnectorHaveShares(data.connector);
    this.number_of_instances = getNumberOfConnectorInstances(data.connector);
    this.number_of_down_instances = getNumberOfDownConnectorInstances(data.connector);
    this.issuer = data.issuer;
    this.orgId = data.orgId;
    this.agentConnectorProxy = data.agentConnectorProxy;
  }

  public ngOnInit(): void {
    this.statEventListeners();
    this.startIdleTimer();
    this.initializeJoinClusterFormGroup();
    this.store.dispatch(new ActionUIInitConnectorInstallUIState());
    const uiState$ = this.store.pipe(select(selectUI));
    uiState$.pipe(takeUntil(this.unsubscribe$)).subscribe((uiStateResp) => {
      if (!this.localTabIndex || this.localUIStateRefreshValue !== uiStateResp.refresh_data) {
        // We only want to set the localTabIndex to the index from the UI State
        // when first loading the page. Otherwise, multiple quick changes to the index
        // would result in the previous UI state value overwriting the currently
        // selected local value before the UI State is updated to match the current local value.
        this.localTabIndex = uiStateResp.tabsState.tabs[this.tabGroupId];
        this.localUIStateRefreshValue = uiStateResp.refresh_data;
      }
      this.uiState = uiStateResp;
      if (this.connector?.spec?.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
        this.configFileName = getAgentConfigFileName(this.connector?.spec?.name);
      }
    });

    this.downloadInfo$ = this.buildBootstrapChallenge$().pipe(
      map((details: ChallengeDetails) => {
        const result = this.detailsToCommands(details);

        // Sucks to have side effects like this, but I couldn't figure out a good way to
        // test the observable as seen by the component while also keeping track of the
        // last info
        this.lastDownloadInfo$.next(result);
        return result;
      })
    );

    if (this.shouldPollForCompletion) {
      this.pollForChallengeCompletion(this.challengePollPeriodMilliseconds);
    }

    // Always generate once
    this.generateDownloadInfo$.next();
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.stopIdleTimer();
  }

  public downloadAgentConfig(authDoc: AuthenticationDocument): void {
    const agentConfigData: AgentConfigData = {
      agilicus_config: {
        agent_id: this.connector?.metadata?.id,
      },
      auth_config: {
        issuer: this.issuer,
        client_id: 'agilicus-builtin-agent-connector',
        client_secret: undefined,
        scopes: ['*'],
        service_account: authDoc,
      },
    };
    const apiDomain = this.env.environment.overrideApiDomain;
    if (apiDomain) {
      agentConfigData.agilicus_config.api_server = apiDomain;
    }
    downloadJSON(agentConfigData, this.renderer, this.configFileName);
  }

  public onManualDownloadClick(): void {
    window.open(this.getAgentDownloadURL(), '_blank');
    const uiStateCopy = cloneDeep(this.uiState);
    // Switch to the Manual tab:
    uiStateCopy.tabsState.tabs[this.tabGroupId] = 4;
    this.store.dispatch(new ActionUIUpdateTabsState(uiStateCopy.tabsState, true));
    this.tabGroup.selectedIndex = 4;
  }

  public getAgentDownloadURL(): string {
    let operatingSystem = OperatingSystemEnum.linux;
    const tab = this.uiState?.tabsState.tabs[this.tabGroupId];
    if (tab === 1 || tab === 2) {
      operatingSystem = OperatingSystemEnum.windows;
    }
    return agentDownloadURLs[operatingSystem];
  }

  private buildBootstrapChallenge$(): Observable<ChallengeDetails> {
    return this.generateDownloadInfo$.pipe(
      switchMap(() => {
        return this.store.pipe(select(selectUser));
      }),
      concatMap((user: User) => {
        return this.buildBootstrapChallengeFromUser$(user);
      }),
      catchError((_) => {
        const result: ChallengeDetails = {
          challengeCode: '',
          challengeID: '',
          failed: true,
        };
        return of(result);
      })
    );
  }

  private buildBootstrapChallengeFromUser$(user: User): Observable<ChallengeDetails> {
    const apiKeyReq: CreateApiKeyRequestParams = {
      APIKey: {
        spec: {
          user_id: user.id,
          org_id: this.orgId,
          scopes: this.installScopes,
          expiry: addSecondsToDate(this.apiKeyDurationSeconds, new Date()),
          label: 'agilicus-portal-connector-install',
          name: this.connector?.spec?.name,
        },
      },
    };
    return this.tokens.createApiKey(apiKeyReq).pipe(
      map((resp: APIKey) => {
        const bootstrap: AgentConnectorBootstrap = {
          api_key: resp.status?.api_key,
          org_id: resp.spec.org_id,
          api_key_user: user.email,
          issuer: this.issuer,
          connector_id: this.connector?.metadata?.id,
        };
        const challengeReq: CreateChallengeRequestParams = {
          Challenge: {
            spec: {
              user_id: user.id,
              challenge_types: ['code'],
              answer_data: bootstrap,
            },
          },
        };
        return challengeReq;
      }),
      concatMap((req: CreateChallengeRequestParams) => {
        return this.challenges.createChallenge(req);
      }),
      map((challenge: Challenge) => {
        return {
          challengeID: challenge.metadata?.id,
          challengeCode: challenge.status?.code,
        };
      })
    );
  }

  private detailsToCommands(details: ChallengeDetails): DownloadInfo {
    if (details.failed) {
      const result: DownloadInfo = {
        singleLinuxInstallCmd: '',
        singleManualInstallCmd: '',
        singleWindowsPSInstallCmd: '',
        singleWindowsInstallCmd: '',
        singleContainerInstallCmd: '',
        failed: true,
      };
      return result;
    }
    const result: DownloadInfo = {
      singleWindowsInstallCmd: `curl -sSL -o "%TEMP%\\aa.exe" https://www.agilicus.com/www/releases/secure-agent/stable/agilicus-agent.exe && "%TEMP%\\aa.exe" client --install --challenge-id ${details.challengeID} --challenge-code ${details.challengeCode} --join-cluster && del "%TEMP%\\aa.exe"`,
      singleWindowsPSInstallCmd: `$ErrorActionPreference = 'Stop'; $ProgressPreference = 'SilentlyContinue';
try {
 Remove-Item "$env:Temp\\aa.exe" -ErrorAction Ignore;
 Invoke-WebRequest -Uri "https://www.agilicus.com/www/releases/secure-agent/stable/agilicus-agent.exe" -OutFile "$env:Temp\\aa.exe";
 . "$env:Temp\\aa.exe" client --install --challenge-id ${details.challengeID} --challenge-code ${details.challengeCode} --join-cluster
} catch { $_.Exception.Message};
`,
      singleLinuxInstallCmd: `which curl && (curl -sSL https://www.agilicus.com/www/releases/secure-agent/stable/install.sh > /tmp/i.sh) || (wget -O - https://www.agilicus.com/www/releases/secure-agent/stable/install.sh > /tmp/i.sh); sh /tmp/i.sh -c ${details.challengeID} -s ${details.challengeCode} -- --join-cluster`,
      singleManualInstallCmd: `agilicus-agent client --install --challenge-id ${details.challengeID} --challenge-code ${details.challengeCode} --join-cluster`,
      singleContainerInstallCmd: `docker run -d --name agilicus-connector --net=host -v agilicus_cfg:/etc/agilicus/agent -e AGILICUS_CHALLENGE_ID=${details.challengeID} -e AGILICUS_CHALLENGE_CODE=${details.challengeCode} cr.agilicus.com/pub/images/agilicus-agent/client:stable`,
      challengeID: details.challengeID,
    };
    return result;
  }

  public pollForChallengeCompletion(delayMilliseconds: number): void {
    of(undefined)
      .pipe(
        // This recursively calls getChallengeState every delayMilliseconds  until the
        // state is terminal.
        expand((challengeState?: ChallengeStatus.StateEnum) => {
          if (this.challengeComplete(challengeState)) {
            return of(challengeState);
          }
          return this.lastDownloadInfo$.pipe(
            take(1),
            delay(delayMilliseconds),
            concatMap((info: DownloadInfo) => {
              return this.getChallengeState(info);
            })
          );
        }),
        takeUntil(this.unsubscribe$),
        filter(
          (state?: ChallengeStatus.StateEnum) =>
            state && state !== ChallengeStatus.StateEnum.issued && state !== ChallengeStatus.StateEnum.pending
        ),
        take(1)
      )
      .subscribe((state) => {
        if (state === ChallengeStatus.StateEnum.challenge_passed) {
          // if the challenge completed, the install worked. Otherwise it must have timed out
          // or something.
          this.dialogRef.close(true);
        }
      });
  }

  private getChallengeState(info: DownloadInfo): Observable<ChallengeStatus.StateEnum | undefined> {
    if (!info.challengeID) {
      return of(undefined);
    }
    const params: GetChallengeRequestParams = {
      challenge_id: info.challengeID,
    };
    return this.challenges.getChallenge(params, 'body', getIgnoreErrorsHeader()).pipe(
      catchError((err) => {
        // Just keep trying if thre's an error
        console.log('failed to fetch challenge: ', err);
        return of(undefined);
      }),
      map((challenge?: Challenge) => {
        if (!challenge) {
          return undefined;
        }
        return challenge.status.state;
      })
    );
  }

  private challengeComplete(state?: ChallengeStatus.StateEnum): boolean {
    if (!state) {
      return false;
    }

    return !(state === ChallengeStatus.StateEnum.issued || state === ChallengeStatus.StateEnum.pending);
  }

  private initializeJoinClusterFormGroup(): void {
    this.joinClusterFormGroup = this.formBuilder.group({
      join_cluster: [
        { value: this.getInitialJoinClusterOptionValue(), disabled: !this.enableJoinClusterOptionSelection() },
        [Validators.required],
      ],
    });
  }

  private getInitialJoinClusterOptionValue(): boolean {
    if (this.hasShares || this.number_of_instances === 0) {
      return false;
    }
    if (this.number_of_instances > this.number_of_down_instances) {
      return true;
    }
    return false;
  }

  /**
   * Only allow a user to manually select/change this option value if the connector has
   * both "good" and "down" instances. Otherwise, we choose this option for them and
   * do not allow them to change it.
   */
  private enableJoinClusterOptionSelection(): boolean {
    return this.number_of_down_instances !== 0 && this.number_of_instances > this.number_of_down_instances;
  }

  private userInteractionListener(): void {
    if (this.showDemoCreateButton) {
      return;
    }
    this.stopIdleTimer();
    this.startIdleTimer();
  }

  private startMouseMoveListener(): void {
    window.addEventListener('click', this.userInteractionListener.bind(this), false);
  }

  private startKeypressListener(): void {
    window.addEventListener('keyup', this.userInteractionListener.bind(this), false);
  }

  private statEventListeners(): void {
    this.startMouseMoveListener();
    this.startKeypressListener();
  }

  private idleTimerFunc(): void {
    const now = new Date().getTime();
    if (now - this.timerStart >= 10000) {
      this.showDemoCreateButton = true;
      this.stopIdleTimer();
    }
  }

  private startIdleTimer() {
    this.timerStart = new Date().getTime();
    this.userIdleInterval = setInterval(() => this.idleTimerFunc(), 1000);
  }

  private stopIdleTimer() {
    clearInterval(this.userIdleInterval);
  }

  public getShowDemoCreateButton(): boolean {
    return this.showDemoCreateButton;
  }

  public startDemoTour() {
    setTimeout(() => {
      this.tourService.initialize(this.tourSteps);
      this.tourService.start();
    }, 500);
  }

  private getProxyUrlFromConnectorProxy(connectorProxy: AgentConnectorProxy): string | undefined {
    if (!connectorProxy?.status?.outer_connector_tunnels || connectorProxy.status.outer_connector_tunnels.length === 0) {
      return undefined;
    }
    return connectorProxy.status.outer_connector_tunnels[0].proxy_url;
  }

  private getRootCertFromConnectorProxy(connectorProxy: AgentConnectorProxy): string | undefined {
    if (!connectorProxy?.status?.outer_connector_tunnels || connectorProxy.status.outer_connector_tunnels.length === 0) {
      return undefined;
    }
    return connectorProxy.status.outer_connector_tunnels[0].root_certificate?.spec?.certificate;
  }

  public getLinuxInstallRootCertString(): string {
    const rootCert = this.getRootCertFromConnectorProxy(this.agentConnectorProxy);
    if (!rootCert) {
      return '';
    }
    return `cat <<EOF > /tmp/agilicus-root-ca.crt\n${rootCert}\nEOF\n`;
  }

  public getWindowsCmdInstallRootCertString(): string {
    const rootCert = this.getRootCertFromConnectorProxy(this.agentConnectorProxy);
    if (!rootCert) {
      return '';
    }
    let lines: string = '';
    const rootCertLinesArray = rootCert.split('\n');
    for (const line of rootCertLinesArray) {
      if (!!line) {
        lines += `@echo ${line}\n`;
      }
    }
    return `(\n${lines}\n)>%TEMP%\\agilicus-root-ca.crt\n`;
  }

  public getWindowsPowerShellInstallRootCertString(): string {
    const rootCert = this.getRootCertFromConnectorProxy(this.agentConnectorProxy);
    if (!rootCert) {
      return '';
    }
    return `"${rootCert}" | Out-File -FilePath $env:Temp\\agilicus-root-ca.crt -Encoding ascii\n`;
  }

  private getNestedConnectorInstallCommandStringToAppend(commandType: CommandTypeEnum): string {
    let tpmdir = '';
    if (commandType === CommandTypeEnum.linux) {
      tpmdir = '/tmp';
    }
    if (commandType === CommandTypeEnum.cmd) {
      tpmdir = '%TEMP%';
    }
    if (commandType === CommandTypeEnum.power_shell) {
      tpmdir = '$env:Temp';
    }
    const proxyUrl = this.getProxyUrlFromConnectorProxy(this.agentConnectorProxy);
    if (!proxyUrl) {
      return '';
    }
    let result = `--egress-proxy-url ${addHTTPSToUrlIfNotSet(proxyUrl)}`;
    if (commandType === CommandTypeEnum.manual) {
      result = `${result} --egress-proxy-ca-cert [path_to_root_cert]`;
    } else {
      result = `${result} --egress-proxy-ca-cert ${tpmdir}/agilicus-root-ca.crt`;
    }
    return result;
  }

  public getInstallCommandWithNestedConnectorInfo(installCommand: string, commandType: CommandTypeEnum): string {
    const nestedInfoToInsert = this.getNestedConnectorInstallCommandStringToAppend(commandType);
    if (commandType === CommandTypeEnum.cmd || commandType === CommandTypeEnum.power_shell) {
      const tagetIndex = installCommand.indexOf('--challenge-id');
      const output = [installCommand.slice(0, tagetIndex), `${nestedInfoToInsert} `, installCommand.slice(tagetIndex)].join('');
      return output;
    }
    return `${installCommand} ${nestedInfoToInsert}`.trim();
  }
}
