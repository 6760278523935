export enum ButtonType {
  ADD = 'ADD',
  DELETE = 'DELETE',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
  UPLOAD = 'UPLOAD',
  DOWNLOAD = 'DOWNLOAD',
  RESET = 'RESET',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}
