import { isAuthenticated, isAuthenticatedViaProxy } from '../application/application-model-utils';
import { RuleType } from '../../../shared/components/rule-type.enum';
import { Application, RuleScopeEnum, HttpRule, RuleV2, RuleConfig, HttpRuleCondition, RuleAction } from '@agilicus/angular';

import { ApplicationModel } from './application-model';
import { generateRandomUuid } from '@app/shared/components/utils';

function createHTTPRule(
  appId: string,
  orgId: string,
  httpMethods: Array<HttpRule.MethodsEnum>,
  pathRegex: string,
  ruleScope: RuleScopeEnum
): RuleV2 {
  const rule: RuleV2 = {
    spec: {
      app_id: appId,
      org_id: orgId,
      condition: {
        rule_type: RuleType.HttpRule,
        methods: httpMethods,
        path_regex: pathRegex,
      },
      scope: ruleScope,
      comments: 'whitelist all for unauthenticated app',
    },
  };
  return rule;
}

function createHTTPPolicyRuleConfig(httpMethods: Array<HttpRule.MethodsEnum>, pathRegex: string, ruleScope: RuleScopeEnum): RuleConfig {
  const newRuleName = generateRandomUuid();
  const httpRuleCondition: HttpRuleCondition = {
    rule_type: RuleType.HttpRule,
    methods: httpMethods,
    path_regex: pathRegex,
    condition_type: 'http_rule_condition',
  };
  const rule: RuleConfig = {
    actions: [{ action: RuleAction.ActionEnum.allow }],
    comments: 'whitelist all for unauthenticated app',
    extended_condition: {
      condition: httpRuleCondition,
      negated: false,
    },
    name: newRuleName,
    priority: 0,
    roles: [],
    scope: ruleScope,
  };
  return rule;
}

export function getProxyRules(appModel: ApplicationModel, app: Application, orgId: string): Array<RuleV2> {
  if (!app) {
    return [];
  }
  if (!isAuthenticated(appModel.authentication)) {
    return [createHTTPRule(app.id, orgId, [HttpRule.MethodsEnum.all], '/', RuleScopeEnum.anyone)];
  }
  if (isAuthenticatedViaProxy(appModel.authentication)) {
    let logoutPath = '/logout';
    if (appModel.authentication.proxy.logout_url) {
      logoutPath = appModel.authentication.proxy.logout_url;
    }
    return [createHTTPRule(app.id, orgId, [HttpRule.MethodsEnum.all], logoutPath, RuleScopeEnum.any_known_user)];
  }
  return [];
}

export function getProxyPolicyRules(appModel: ApplicationModel, app: Application, orgId: string): Array<RuleConfig> {
  if (!app) {
    return [];
  }
  if (!isAuthenticated(appModel.authentication)) {
    return [createHTTPPolicyRuleConfig([HttpRule.MethodsEnum.all], '/', RuleScopeEnum.anyone)];
  }
  if (isAuthenticatedViaProxy(appModel.authentication)) {
    let logoutPath = '/logout';
    if (appModel.authentication.proxy.logout_url) {
      logoutPath = appModel.authentication.proxy.logout_url;
    }
    return [createHTTPPolicyRuleConfig([HttpRule.MethodsEnum.all], logoutPath, RuleScopeEnum.any_known_user)];
  }
  return [];
}
