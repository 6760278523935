import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { AgentConnector } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadAgentConnectors = createAction(
  '[AgentConnector/API] Load AgentConnectors',
  props<{ objs: AgentConnector[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addAgentConnector = createAction('[AgentConnector/API] Add AgentConnector', props<{ obj: AgentConnector }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertAgentConnector = createAction(
  '[AgentConnector/API] Upsert AgentConnector',
  props<{ obj: AgentConnector; refreshData: boolean }>()
);

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addAgentConnectors = createAction('[AgentConnector/API] Add AgentConnectors', props<{ objs: AgentConnector[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertAgentConnectors = createAction(
  '[AgentConnector/API] Upsert AgentConnectors',
  props<{ objs: AgentConnector[]; refreshData: boolean }>()
);

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateAgentConnector = createAction('[AgentConnector/API] Update AgentConnector', props<{ obj: Update<AgentConnector> }>());

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateAgentConnectors = createAction(
  '[AgentConnector/API] Update AgentConnectors',
  props<{ objs: Update<AgentConnector>[] }>()
);

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteAgentConnector = createAction(
  '[AgentConnector/API] Delete AgentConnector',
  props<{ id: string; obj: AgentConnector; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteAgentConnectors = createAction(
  '[AgentConnector/API] Delete AgentConnectors',
  props<{ ids: string[]; refreshData?: boolean }>()
);

/**
 * Will delete all data from the ngrx entity state
 */
export const clearAgentConnectors = createAction('[AgentConnector/API] Clear AgentConnectors');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initAgentConnectors = createAction(
  '[AgentConnector/API] Init AgentConnectors',
  props<{ force: boolean; blankSlate: boolean }>()
);

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getAgentConnectors = createAction(
  '[AgentConnector/API] Get AgentConnectors',
  props<{ org_id: string; blankSlate: boolean }>()
);

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingAgentConnector = createAction(
  '[AgentConnector/API] Saving AgentConnector',
  props<{ obj: AgentConnector; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingAgentConnectors = createAction(
  '[AgentConnector/API] Saving AgentConnectors',
  props<{ objs: Array<AgentConnector>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingAgentConnector = createAction(
  '[AgentConnector/API] Deleting AgentConnector',
  props<{ obj: AgentConnector; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingAgentConnectors = createAction(
  '[AgentConnector/API] Deleting AgentConnectors',
  props<{ objs: Array<AgentConnector>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshAgentConnectors = createAction('[AgentConnector/API] Refresh AgentConnectors');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainAgentConnectors = createAction('[AgentConnector/API] Maintain AgentConnectors');
