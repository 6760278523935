<div *ngIf="!!resourceConfigOptionsFormGroup" class="done-config-step-container">
  <div class=user-info-text>
    <p>
      The {{ getFormattedResourceType(resourceType) }} "{{ resourceName }}" has been created. You can view a list of
      all {{ getFormattedPluralizedResourceType(resourceType) }} in this organisation on the overview page (Resources > {{ capitalizeFirstLetter(getFormattedPluralizedResourceType(resourceType, true)) }} > Overview).
    </p>
  </div>
  
  <form
    [formGroup]="resourceConfigOptionsFormGroup"
    class="stepper-form"
    spellcheck="false"
    autocomplete="off"
  >
    <!-- Resource Groups-->
    <div class="resource-groups-container">
      <label
        class="radio-button-group-label"
      >
        I would like to add this {{ getFormattedResourceType(resourceType) }} to one or more resource groups now:
      </label>
      <mat-radio-group
        formControlName="add_to_resource_groups"
        aria-labelledby="add to resource groups radio buttons"
        class="radio-group-main"
      >
        <mat-radio-button
          *ngFor="let option of radioButtonOptions"
          class="radio-button-main"
          [value]="option.value"
          (change)="onAddToResourceGroupChange(option.value)"
          matTooltip="You do not have sufficient permissions to access these options. If you feel this is an error, please contact your administrator for further assistance."
          [matTooltipDisabled]="!!hasResourcesPermissions"
        >
          {{ option.name }}
        </mat-radio-button>
      </mat-radio-group>

      <div
        *ngIf="!!getAddToResourceGroupsFormValue()"
        class="option-content-container"
      >
        <portal-resource-group-admin
          [showPageInfo]="showResourceGroupPageInfo"
          [hideFilter]="hideResourceGroupFilter"
          [overrideResourceMemebersAllowedValuesResourceId]="resourceId"
        >
        </portal-resource-group-admin>
      </div>

      <div
        *ngIf="getAddToResourceGroupsFormValue() === false && getAddToResourceGroupsFormValue() !== undefined && getAddToResourceGroupsFormValue() !== null"
        class="option-content-container"
      >
        <p>You may still add this {{ getFormattedResourceType(resourceType) }} to resource groups later using the "Resource Groups" page (Access > Resource Groups)</p>
      </div>
    </div>

    <!-- Permissions -->
    <div *ngIf="showResourcePermissions || showApplicationPermissions" class="permissions-container">
      <label
        class="radio-button-group-label"
      >
        I would like to assign permissions for this {{ getFormattedResourceType(resourceType) }} now:
      </label>
      <mat-radio-group
        formControlName="assign_permissions"
        aria-labelledby="assign permissions radio buttons"
        class="radio-group-main"
      >
        <mat-radio-button
          *ngFor="let option of radioButtonOptions"
          class="radio-button-main"
          [value]="option.value"
          (change)="onAssignPermissionsChange(option.value)"
          matTooltip="You do not have sufficient permissions to access these options. If you feel this is an error, please contact your administrator for further assistance."
          [matTooltipDisabled]="!!hasResourcesPermissions"
        >
          {{ option.name }}
        </mat-radio-button>
      </mat-radio-group>

      <div
        *ngIf="!!getAssignPermissionsFormValue() && showResourcePermissions"
        class="option-content-container"
      >
        <p>Assign permissions to users:</p>
        <div class="permissions-form-table-container" formArrayName="permissions_list">
          <table class="assign-permissions-table-container">
            <div *ngFor="let permission of getPermissionsListFormArray().controls; let i=index" [formGroupName]="i">
              <tr>
                <td class="user-column">
                  <mat-form-field>
                    <input
                      #autoinput
                      matInput
                      type="text"
                      placeholder="Enter user identity"
                      formControlName="user"
                      [matAutocomplete]="auto"
                      autocomplete="off"
                      #triggerAutoInput="matAutocompleteTrigger"
                      (keyup.enter)="keyTabManager.keyTabTableAutoInput($event.target.id, triggerAutoInput)"
                    />
                    <mat-autocomplete 
                      #auto="matAutocomplete"
                    >
                      <mat-option
                        *ngFor="let user of filteredUserOptions$ | async"
                        [value]="user.display_name"
                      >
                        {{ user.display_name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>A valid user identity is required</mat-error>
                  </mat-form-field>
                </td>

                <td class="roles-column">
                  <portal-custom-chiplist-input
                    class="chiplist-form-field"
                    [chiplistInput]="getRolesChiplistInput(permission)"
                    [element]="resourcePermissionElementsList[i]"
                    placeholder="Select permissions"
                    [filterChipOptions]="filterChipOptions"
                    [isChipRemovable]="true"
                    [keyTabManager]="keyTabManager"
                    [required]="requiredChiplist"
                    isTemplateGeneratedChiplistInput="true"
                    (removeChip)="removeRoleChip($event, i)"
                  >
                  </portal-custom-chiplist-input>
                </td>

                <td>
                  <div *ngIf="i > 0" class="delete-permission-button">
                    <input
                      type="button"
                      class="mat-raised-button mat-button-base mat-warn"
                      value="Delete"
                      (click)="removePermission(i)"
                      (keydown.enter)="preventDeleteOnEnter($event)"
                    >
                  </div>
                </td>
              </tr>
            </div>
          </table>
        </div>
        <div class="add-permission-button-container">
          <span class="buttonAsText" (click)="addPermission()">+ Add permission</span>
        </div>

        <div class="save-button-container">
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="action-button"
            attr.aria-label="Button to assign permisssions"
            (click)="submitPermissions()"
            [disabled]="isSubmittingPermissions"
          >
            ASSIGN PERMISSIONS
          </button>
        </div>
      </div>

      <div
        *ngIf="!!getAssignPermissionsFormValue() && showApplicationPermissions"
        class="option-content-container"
      >
        <portal-permissions-admin
          [appId]="resourceId"
          [showPageInfo]="showApplicationPermissionsPageInfo"
        >
        </portal-permissions-admin>
      </div>
      
      <div
        *ngIf="getAssignPermissionsFormValue() === false && getAssignPermissionsFormValue() !== undefined && getAssignPermissionsFormValue() !== null"
        class="option-content-container"
      >
        <p *ngIf="getFormattedResourceType(resourceType) === resourceTypeEnum.application">
          You may still assign permissions later using the "Application Permissions" page (Access > Application Permissions)
        </p>
        <p *ngIf="getFormattedResourceType(resourceType) !== resourceTypeEnum.application">
          You may still assign permissions later using the "Resource Permissions" page (Access > Resource Permissions)</p>
      </div>
    </div>

    <!-- Policies-->
    <div *ngIf="!shouldGateAccess() && showPolicies" class="policies-container">
      <label
        class="radio-button-group-label"
      >
        I would like to add this {{ getFormattedResourceType(resourceType) }} to one or more policies now:
      </label>
      <mat-radio-group
        formControlName="add_to_policies"
        aria-labelledby="add to policies radio buttons"
        class="radio-group-main"
      >
        <mat-radio-button
          *ngFor="let option of radioButtonOptions"
          class="radio-button-main"
          [value]="option.value"
          (change)="onAddToPoliciesChange(option.value)"
          matTooltip="You do not have sufficient permissions to access these options. If you feel this is an error, please contact your administrator for further assistance."
          [matTooltipDisabled]="!!hasRulesPermissions"
        >
          {{ option.name }}
        </mat-radio-button>
      </mat-radio-group>

      <div
        *ngIf="!!getAddToPoliciesFormValue()"
        class="option-content-container"
      >
        <portal-custom-chiplist-input
          class="chiplist-form-field"
          [chiplistInput]="policiesChiplistInput"
          [element]="resourceTableElement"
          placeholder="My {{ getFormattedResourceType(resourceType) }} is applied to policies..."
          [filterChipOptions]="filterChipOptions"
          [isChipRemovable]="true"
          [keyTabManager]="keyTabManager"
          required="true"
          (removeChip)="removePolicyChip($event)"
        >
        </portal-custom-chiplist-input>

        <div class="save-button-container">
          <button
            type="button"
            mat-raised-button
            color="primary"
            class="action-button"
            attr.aria-label="Button to apply to a policy"
            (click)="submitPolicies()"
            [disabled]="isSubmittingPolicies || disableApplyPoliciesButtonIfNothingToUpdate()"
          >
            APPLY POLICIES
          </button>
        </div>
      </div>

      <div
        *ngIf="getAddToPoliciesFormValue() === false && getAddToPoliciesFormValue() !== undefined && getAddToResourceGroupsFormValue() !== null"
        class="option-content-container"
      >
        <p>You may still add this {{ getFormattedResourceType(resourceType) }} to policies later using the "Overview" page (Resources > {{ capitalizeFirstLetter(getFormattedPluralizedResourceType(resourceType, true)) }})</p>
      </div>
    </div>
  </form>
</div>