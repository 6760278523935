import { Environment } from '@agilicus/angular';
import {
  ApplicationModel,
  ApplicationModelAuthentication,
  ApplicationModelAuthorization,
  ApplicationModelHosting,
} from './application-model';

const defaultCategory = 'default';

export function getEmptyApplicationModel(): ApplicationModel {
  return {
    name: '',
    description: '',
    category: defaultCategory,
  };
}

/**
 * Sets all enabled flags to false.
 */
export function getDefaultApplicationModel(): ApplicationModel {
  return {
    name: '',
    description: '',
    category: defaultCategory,
    hosting: {
      enabled: true,
      on_prem: {
        enabled: true,
        agent: {
          enabled: true,
          proxy_location: Environment.ProxyLocationEnum.on_site,
        },
        vpn: {
          enabled: false,
        },
        upstream_services: [
          {
            hostname: '',
            port: null,
            expose_type: 'application',
            protocol_config: {
              http_config: {
                disable_http2: false,
              },
            },
          },
        ],
        connector_name: '',
        rewrite_common_media_types: true,
        cors: {
          enabled: false,
          corsType: null,
        },
      },
      in_cloud: {
        enabled: false,
        services: [],
        runtime: {
          enabled: false,
          agilicus: {
            enabled: false,
            image: '',
            bundles: [],
          },
          own: {
            enabled: false,
            image: '',
            port: null,
            version_tag: '',
          },
        },
      },
      fqdnAliases: {
        enabled: false,
        fqdnAliasList: [],
      },
    },
    authentication: {
      enabled: true,
      oidc: {
        enabled: false,
        redirect_uri: '',
      },
      saml: {
        enabled: false,
        saml_metadata_file: '',
      },
      proxy: {
        enabled: true,
        logout_url: '',
        redirect_after_signin_path: '',
      },
      launch_desktop: false,
    },
    authorization: {
      all_users: false,
      all_org_users: false,
      single_role_users: false,
      user_defined_roles: {
        enabled: false,
        roles: [],
      },
      rules: [],
      policy_rules: [],
      application_model_routing: {
        common_path_prefix: '',
      },
    },
    is_demo: false,
  };
}

export function isHosting(appModelHosting: ApplicationModelHosting): boolean {
  return !!appModelHosting && appModelHosting.enabled;
}

export function isAccessedFromCloud(appModelHosting: ApplicationModelHosting): boolean {
  if (isHosting(appModelHosting) && !!appModelHosting.in_cloud && appModelHosting.in_cloud.enabled) {
    return true;
  }
  return false;
}

export function isAccessedViaAgent(appModelHosting: ApplicationModelHosting): boolean {
  if (
    isHosting(appModelHosting) &&
    !!appModelHosting.on_prem &&
    appModelHosting.on_prem.enabled &&
    !!appModelHosting.on_prem.agent &&
    appModelHosting.on_prem.agent.enabled
  ) {
    return true;
  }
  return false;
}

export function isAccessedViaVpn(appModelHosting: ApplicationModelHosting): boolean {
  if (
    isHosting(appModelHosting) &&
    !!appModelHosting.on_prem &&
    appModelHosting.on_prem.enabled &&
    !!appModelHosting.on_prem.vpn &&
    appModelHosting.on_prem.vpn.enabled
  ) {
    return true;
  }
  return false;
}

export function isAccessedOnPrem(appModelHosting: ApplicationModelHosting): boolean {
  return isAccessedViaAgent(appModelHosting) || isAccessedViaVpn(appModelHosting);
}

export function isAuthenticated(appModelAuthentication: ApplicationModelAuthentication): boolean {
  return !!appModelAuthentication && appModelAuthentication.enabled;
}

export function isAuthenticatedViaProxy(appModelAuthentication: ApplicationModelAuthentication): boolean {
  if (isAuthenticated(appModelAuthentication) && !!appModelAuthentication.proxy && appModelAuthentication.proxy.enabled) {
    return true;
  }
  return false;
}

export function isAuthenticatedViaSaml(appModelAuthentication: ApplicationModelAuthentication): boolean {
  if (isAuthenticated(appModelAuthentication) && !!appModelAuthentication.saml && appModelAuthentication.saml.enabled) {
    return true;
  }
  return false;
}

export function isAuthenticatedViaOidc(appModelAuthentication: ApplicationModelAuthentication): boolean {
  if (isAuthenticated(appModelAuthentication) && !!appModelAuthentication.oidc && appModelAuthentication.oidc.enabled) {
    return true;
  }
  return false;
}

export function isUserDefinedRolesEnabled(appModelAuthorization: ApplicationModelAuthorization): boolean {
  return !!appModelAuthorization.user_defined_roles && !!appModelAuthorization.user_defined_roles.enabled;
}

export function hasUserDefinedRoles(appModelAuthorization: ApplicationModelAuthorization): boolean {
  return !!appModelAuthorization.user_defined_roles && !!appModelAuthorization.user_defined_roles.roles;
}

export function isRuntimeEnabled(appModelHosting: ApplicationModelHosting): boolean {
  return isAccessedFromCloud(appModelHosting) && !!appModelHosting.in_cloud.runtime && appModelHosting.in_cloud.runtime.enabled;
}

export function isUsingOwnRuntime(appModelHosting: ApplicationModelHosting): boolean {
  if (isRuntimeEnabled(appModelHosting) && !!appModelHosting.in_cloud.runtime.own && appModelHosting.in_cloud.runtime.own.enabled) {
    return true;
  }
  return false;
}

export function isUsingAgilicusRuntime(appModelHosting: ApplicationModelHosting): boolean {
  if (
    isRuntimeEnabled(appModelHosting) &&
    !!appModelHosting.in_cloud.runtime.agilicus &&
    appModelHosting.in_cloud.runtime.agilicus.enabled
  ) {
    return true;
  }
  return false;
}

export function haveSelectedOidcProxyRuntime(appModelHosting: ApplicationModelHosting): boolean {
  if (appModelHosting?.in_cloud?.runtime?.agilicus?.image === 'oidc-proxy') {
    return true;
  }
  return false;
}

export function hasNoNamedUsers(appModelAuthorization: ApplicationModelAuthorization): boolean {
  return !!appModelAuthorization && appModelAuthorization.all_users;
}

export function isTlsEnabled(appModelHosting: ApplicationModelHosting, index: number): boolean {
  return appModelHosting?.on_prem?.upstream_services[index]?.tls_enabled;
}
