import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { AuthService } from '@app/core/services/auth-service.service';
import { Observable, take } from 'rxjs';
import { getProductLinkFromUser$ } from './product-guide-link.utils';

@Component({
  selector: 'portal-product-guide-link',
  templateUrl: './product-guide-link.component.html',
  styleUrls: ['./product-guide-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductGuideLinkComponent implements OnChanges {
  @Input() public productGuidePreText = '';
  @Input() public productGuideLink = '';
  @Input() public productGuideLinkText = 'Product Guide';
  @Input() public productGuidePostText = '';
  @Input() public icon = '';
  @Input() public largeText = false;
  public productGuideDeepLink = '';
  private productGuideLinkLocalCache = '';

  constructor(private authService: AuthService, private changeDetector: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.setProductGuideDeepLink();
  }

  public getProductGuideDeepLink$(): Observable<string> {
    return getProductLinkFromUser$(this.productGuideLink, this.authService);
  }

  public setProductGuideDeepLink(): void {
    if (!this.productGuideLink || this.productGuideLink === this.productGuideLinkLocalCache) {
      return;
    }
    this.getProductGuideDeepLink$()
      .pipe(take(1))
      .subscribe((productGuideLinkResp) => {
        this.productGuideDeepLink = productGuideLinkResp;
        this.productGuideLinkLocalCache = this.productGuideLink;
        this.changeDetector.detectChanges();
      });
  }

  public getProductGuideDeepLink(): string {
    return this.productGuideDeepLink;
  }
}
