import { Launcher } from '@agilicus/angular';
import { LauncherModel } from '../models/launcher/launcher-model';

export function getLauncherResourceFromModel(model: LauncherModel, orgId: string): Launcher | undefined {
  const newLauncher: Launcher = {
    spec: {
      name: model.name,
      config: model.config,
      resource_members: model.resource_members,
      applications: model.applications,
      org_id: orgId,
    },
  };
  return newLauncher;
}

export function getLauncherDescriptiveText(): string {
  return `A launcher encompasses the configuration for launching a program via a connector, allowing the launched program's requests to be proxied through Agilicus to the corresponding backend resources. 
  Create a launcher to have it automatically configure a shortcut on the local desktops of users who have permission for it. This shortcut will launch the program so that it accesses all of its resources through Agilicus without needing a virtual private network.`;
}

export function getLauncherProductGuideLink(): string {
  return `https://www.agilicus.com/anyx-guide/launchers`;
}

export function getLauncherNameTooltipText(): string {
  return `This name uniquely identifies the launcher within this organisation.`;
}

export function getLauncherCommandPathTooltipText(): string {
  return `The application full path and name, located on the client computer`;
}

export function getLauncherResourceMembersTooltipText(): string {
  return `The resources that are necessary for this launcher to run. 
  For example, it could be an Application Service using TCP port(s), or a combination of Services using TCP ports or Shares.`;
}

export function getLauncherCommandArgumentsTooltipText(): string {
  return `The arguments necessary for the command to run`;
}

export function getLauncherStartInTooltipText(): string {
  return `The directory in which the launched program starts`;
}

export function getLauncherApplicationsTooltipText(): string {
  return `The associated applications that this launcher may launch.`;
}

export function getLauncherDoInterceptTooltipText(): string {
  return `Whether the launcher command requires the use of an interceptor`;
}

export function getLauncherEndExistingIfRunningTooltipText(): string {
  return `If the launcher is already running you may want to terminate that process before starting the launcher again. If so, enable this option.`;
}

export function getLauncherHideConsoleTooltipText(): string {
  return `Hide the initial command launcher`;
}

export function getLauncherRunAsAdminTooltipText(): string {
  return `Enabling this option will check if the application needs to run with elevated permissions on your machine.`;
}

export function getForkThenAttachTooltipText(): string {
  return `A small number of applications have problems during the initialization phase. If having trouble making the application function, try enabling this option.`;
}

export function getLauncherDoInterceptDescriptiveText(): string {
  return `My launcher requires DNS (name service) interception`;
}

export function getLauncherHideConsoleDescriptiveText(): string {
  return `Hide the launcher command window`;
}

export function getLauncherRunAsAdminDescriptiveText(): string {
  return `My launcher runs with elevated privileges`;
}

export function getLauncherEndExistingIfRunningDescriptiveText(): string {
  return `My launcher will terminate the process if it is already running when the launcher starts`;
}

export function getLauncherForkThenAttachDescriptiveText(): string {
  return `My launcher requires custom, delayed initialisation`;
}

export function getLauncherExtraProcessProgramNameTooltipText(): string {
  return `Full path to the process to run or attach to`;
}

export function getLauncherExtraProcessCommandArgumentsTooltipText(): string {
  return `The arguments necessary for the command to run`;
}

export function getLauncherExtraProcessStartInTooltipText(): string {
  return `The directory this extra process will start in`;
}

export function getLauncherExtraProcessNameRegexFlagTooltipText(): string {
  return `Use the program name as a regex for finding the process to attach to by name or executable path. This option can't be set if "start if not running" is set.`;
}

export function getLauncherExtraProcessStartIfNotRunningTooltipText(): string {
  return `When running the launcher, if there isn't a process running this service, then start it`;
}

export function getLauncherExtraProcessExitWhenEndingTooltipText(): string {
  return `If the process should be terminated when the launcher exits`;
}

export function getLauncherExtraProcessAttachIfAlreadyRunningTooltipText(): string {
  return `If the process is already running when the launcher starts, attach to it and intercept its traffic`;
}

export function getLauncherExtraProcessForkThenAttachTooltipText(): string {
  return `Some programs require specific startup initialization procedures that cause the initial interceptor spawn + resume startup procedure to not function correctly. The typical behavior for this type of program is exhibited as the program terminating prematurely. This setting, when true, does a normal fork and will attach to the pid after the process has initially started.`;
}

export function getLauncherExtraProcessWaitForExitTooltipText(): string {
  return `When running the interceptor, wait on this proccess as well as the main process. You can use this with the process name to intercept processes that call some kind of launcher which runs another process.`;
}
