import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

export class KeyTabManager {
  public getElements(): NodeListOf<Element> {
    const targetElements = document.querySelectorAll('input,textarea,mat-select');
    return targetElements;
  }

  private isTabable(element: HTMLElement): boolean {
    if (element === undefined) {
      return false;
    }
    const elementDisabled = element.getAttribute('disabled');
    const elementReadonly = element.getAttribute('readonly');
    const elementHidden = element.getAttribute('hidden');
    const elementKeyTabDisabled = element.getAttribute('portalDisableKeyTab');
    // The below selector accounts for the table paginator dropdown selector.
    const elementMatSelectDisabled = Array.from(element.classList).includes('mat-select-disabled');
    if (
      elementDisabled !== null ||
      elementHidden !== null ||
      elementReadonly ||
      elementKeyTabDisabled !== null ||
      elementMatSelectDisabled
    ) {
      return false;
    }
    return true;
  }

  public getCurrentElementIndex(currentId: string, allElements: NodeListOf<Element>): number | undefined {
    for (let i = 0; i < allElements.length; i++) {
      const element = allElements[i] as HTMLElement;
      if (element.id === currentId) {
        return i;
      }
    }
    return undefined;
  }

  private getNextElement(allElements: NodeListOf<Element>, startingIndex: number): HTMLElement {
    let targetIndex = startingIndex + 1;
    let nextElement = allElements[targetIndex] as HTMLElement;
    while (nextElement !== undefined && !this.isTabable(nextElement)) {
      targetIndex++;
      nextElement = allElements[targetIndex] as HTMLElement;
    }
    return nextElement;
  }

  public keyTab(currentId: string): void {
    const allElements = this.getElements();
    for (let i = 0; i < allElements.length; i++) {
      const element = allElements[i] as HTMLElement;
      if (element.id === currentId) {
        const nextElement = this.getNextElement(allElements, i);
        if (nextElement === undefined) {
          // Reached the final element on the screen.
          element.blur();
          break;
        }
        nextElement.focus();
        break;
      }
    }
  }

  public keyTabChipList(currentValue: string, currentId: string, trigger: MatAutocompleteTrigger, newChipAdded: boolean): void {
    if (newChipAdded) {
      return;
    }
    if (currentValue === '') {
      this.keyTab(currentId);
      // Close the autocomplete dropdown.
      if (trigger) {
        trigger.closePanel();
      }
    }
  }

  public keyTabAutoInput(currentId: string, trigger: MatAutocompleteTrigger): void {
    this.keyTab(currentId);
    // Close the autocomplete dropdown.
    trigger.closePanel();
  }

  public keyTabTableAutoInput(currentId: string, trigger: MatAutocompleteTrigger): void {
    this.keyTab(currentId);
    // Close the autocomplete dropdown.
    trigger.closePanel();
  }

  public keyTabSelect(currentId: string, select: MatSelect): void {
    // The panel will toggle on keydown and the check will occur on keyup.
    if (!select.panelOpen) {
      this.keyTab(currentId);
    }
  }

  public keyTabFilterInput(currentId: string, newInputOptionAdded: boolean): void {
    if (!newInputOptionAdded) {
      this.keyTab(currentId);
    }
  }

  public keyTabByIndex(currentElementIndex: number): void {
    const allElements = this.getElements();
    const element = allElements[currentElementIndex] as HTMLElement;
    const nextElement = this.getNextElement(allElements, currentElementIndex);
    if (nextElement === undefined) {
      // Reached the final element on the screen.
      element.blur();
      return;
    }
    nextElement.focus();
  }
}
