import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as PolicyTemplateInstanceActions from './policy-template-instance.actions';
import { PolicyTemplateInstance } from '@agilicus/angular';
import { BasicCollectionState } from '../basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';

export const policyTemplateInstancesFeatureKey = 'policyTemplateInstance';

export type PolicyTemplateInstanceState = BasicCollectionState<PolicyTemplateInstance>;

export const adapter: EntityAdapter<PolicyTemplateInstance> = createEntityAdapter<PolicyTemplateInstance>({
  sortComparer: sortListByCreatedDate,
  selectId: (policyTemplateInstance) => policyTemplateInstance.metadata.id,
});

export const initialState: PolicyTemplateInstanceState = adapter.getInitialState({
  ...getDefaultBasicCollectionState<PolicyTemplateInstance>(),
  // additional entity state properties
  state_save_success: false,
});
export const policyTemplateInstanceReducer = createReducer(
  initialState,
  on(PolicyTemplateInstanceActions.addPolicyTemplateInstance, (state, action) => adapter.addOne(action.obj, state)),
  on(PolicyTemplateInstanceActions.upsertPolicyTemplateInstance, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      state_save_success: true,
    })
  ),
  on(PolicyTemplateInstanceActions.addPolicyTemplateInstances, (state, action) => adapter.addMany(action.objs, state)),
  on(PolicyTemplateInstanceActions.upsertPolicyTemplateInstances, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(PolicyTemplateInstanceActions.updatePolicyTemplateInstance, (state, action) => adapter.updateOne(action.obj, state)),
  on(PolicyTemplateInstanceActions.updatePolicyTemplateInstances, (state, action) => adapter.updateMany(action.objs, state)),
  on(PolicyTemplateInstanceActions.deletePolicyTemplateInstance, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(PolicyTemplateInstanceActions.deletePolicyTemplateInstances, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(PolicyTemplateInstanceActions.loadPolicyTemplateInstances, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(PolicyTemplateInstanceActions.clearPolicyTemplateInstances, (state) =>
    adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })
  ),
  // custom reducers:
  on(PolicyTemplateInstanceActions.savingPolicyTemplateInstance, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects, state_save_success: false };
  }),
  on(PolicyTemplateInstanceActions.savingPolicyTemplateInstances, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects, state_save_success: false };
  }),
  on(PolicyTemplateInstanceActions.initPolicyTemplateInstances, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(PolicyTemplateInstanceActions.refreshPolicyTemplateInstances, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
