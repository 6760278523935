<div class="group-mappings section">
  <h4 class="section-label">Group Mappings</h4>
  <portal-page-info
    [pageDescriptiveText]="pageDescriptiveText"
    [productGuideLink]="productGuideLink"
    pageInfoWidth="full"
  >
  </portal-page-info>

  <portal-table-layout
    class="table-template"
    [tableData]="groupMappingEntryTableData"
    [columnDefs]="groupMappingsColumnDefs"
    [filterManager]="filterManager"
    rowObjectName="GROUP MAPPING"
    [makeEmptyTableElement]="makeEmptyGroupMappingTableElementFunc"
    [customButtons]="customButtons"
    (updateEvent)="updateGroupMappingEvent()"
    (removeSelected)="deleteSelectedGroupMappingEntries()"
    >
  </portal-table-layout>
</div>

<div class="excluded-groups section">
  <h4 class="section-label">Excluded Groups</h4>
  <portal-table-layout
    class="table-template"
    [tableData]="excludedGroupTableData"
    [columnDefs]="excludedGroupColumnDefs"
    [filterManager]="filterManager"
    rowObjectName="EXCLUDED GROUP"
    [makeEmptyTableElement]="makeEmptyExcludedGroupsTableElementFunc"
    (updateEvent)="updateGroupMappingEvent()"
    (removeSelected)="deleteSelectedExcludedGroupEntries()"
    >
  </portal-table-layout>
</div>