import { ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
  Application,
  BulkApproveRequestsRequestParams,
  UserRequestUserUpdate,
  DeleteUserRequestParams,
  ListAccessRequestsRequestParams,
  ListAccessRequestsResponse,
  PermissionsService,
  Resource,
  ResourceRole,
  ResourceRoleSpec,
  ResourcesService,
  Role,
  UpdateUserRequestRequestParams,
  User,
  UserRequestInfo,
  UserRequestInfoSpec,
  UsersService,
  UserStatusEnum,
  ResourceTypeEnum,
} from '@agilicus/angular';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { AppState, NotificationService } from '@app/core';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { FilterManager } from '../filter/filter-manager';
import {
  Column,
  createExpandColumn,
  createIconColumn,
  createInputColumn,
  createSelectColumn,
  createSelectRowColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { ButtonType } from '../button-type.enum';
import {
  PaginatorActions,
  PaginatorConfig,
  TablePaginatorComponent,
  UpdateTableParams,
} from '../table-paginator/table-paginator.component';
import { catchError, concatMap, map, take, takeUntil } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { OptionalResourceRole } from '../optional-types';
import { capitalizeFirstLetter, createEnumChecker, replaceCharacterWithSpace } from '../utils';
import { createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { UserRequestElementExpandable } from '../user-request-element';
import { UserRequestState } from '../user-request-state';
import { UserRequestDetail } from '../user-request-details';
import { EmailParams } from '../email-params';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TableElement } from '../table-layout/table-element';
import { getResouces, getResourceRoles } from '@app/core/api/resources/resources-api-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { getDefaultNestedDataProperties, getDefaultTableProperties, getDisplayedColumnNames } from '../table-layout-utils';
import { ResourceType } from '../resource-type.enum';
import { getResourceDefaultRole, getResourceNameAndTypeString, getResourceTypeIcon, getResourceTypeTooltip } from '../resource-utils';
import { selectCanReadResources } from '@app/core/user/permissions/resources.selectors';
import { Router } from '@angular/router';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import { selectApiApplicationsList } from '@app/core/api-applications/api-applications.selectors';
import { selectCanReadApps } from '@app/core/user/permissions/app.selectors';
import { convertDateToReadableFormat, getDateThirtyDaysFromNow } from '../date-utils';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';
import { updateExistingUser$ } from '@app/core/user/user.utils';
import { ButtonColor, RowScopedButton, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';

export interface ResourceRequestNestedElement extends TableElement, UserRequestInfoSpec {
  request: UserRequestInfo;
  user: User;
  parentId: string | number;
  created?: string;
}

export interface RequestLocationData {
  parentIndex: number;
  requestIndex: number;
}

@Component({
  selector: 'portal-user-resource-access-requests',
  templateUrl: './user-resource-access-requests.component.html',
  styleUrls: ['./user-resource-access-requests.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserResourceAccessRequestsComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public orgId: string;
  public hasPermissions: boolean;
  private appsList$: Observable<Array<Application>>;
  private resources$: Observable<Array<Resource>>;
  private resourceRoles$: Observable<Array<ResourceRole>>;
  private resourceRoles: Array<ResourceRole>;
  private resourceRoleNameToResourceRoleMap: Map<string, ResourceRole> = new Map();
  private resourceIdToResourceMap: Map<string, Resource> = new Map();
  private resourceNameAndTypeToResourceMap: Map<string, Resource> = new Map();
  private appIdToAppMap: Map<string, Application> = new Map();
  private appNameToAppMap: Map<string, Application> = new Map();
  public tableData: Array<UserRequestElementExpandable> = [];
  public parentColumnDefs: Map<string, Column<UserRequestElementExpandable>> = new Map();
  public requestColumnDefs: Map<string, Column<ResourceRequestNestedElement>> = new Map();
  public parentFilterManager: FilterManager = new FilterManager();
  public nestedFilterManager: FilterManager = new FilterManager();
  public rowObjectName = 'REQUEST';
  public cachedResults: EmailParams = { previousEmail: '', nextEmail: '' };
  public linkDataSource = false;
  public paginatorConfig = new PaginatorConfig<UserRequestElementExpandable>(
    true,
    true,
    25,
    5,
    new PaginatorActions<UserRequestElementExpandable>(),
    'email',
    {
      previousKey: '',
      nextKey: '',
      previousWindow: [],
    }
  );
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public getDisplayedColumnNames = getDisplayedColumnNames;
  public pageDescriptiveText = `Users can self-request access. Here you can grant/deny the requested permissions.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/application-request-access/`;
  private appIdToAppRolesMap: Map<string, Array<ResourceRole>> = new Map();
  /**
   * We do not want to remove the highlighted colour from the target row after we remove the request id
   * from the url, so we store it locally here. When the page is refreshed it will clear this value and
   * thus remove the highlight.
   */
  private highlightedRequestId: string;
  private defaultUserMessageTooltip = `The user will receive this message after the request has been approved or rejected. This field is optional.`;

  private approveUserButtonTooltipText = 'Click to approve users and all requests by these users';
  public customButtons: Array<TableButton> = [
    new RowScopedButton(
      'APPROVE USERS',
      ButtonColor.PRIMARY,
      this.approveUserButtonTooltipText,
      `Approve selected users and all requests by those users`,
      () => 'Please select users to approve',
      `Button container that displays a tooltip when the approve users button is disabled`,
      (usersToApprove: Array<UserRequestElementExpandable>) => {
        this.approveSelectedUsers(usersToApprove);
      }
    ),
  ];

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<UserRequestElementExpandable>;
  @ViewChild('outerSort', { static: true }) public outerSort: MatSort;
  @ViewChild(TablePaginatorComponent, { static: true }) public paginator: TablePaginatorComponent<any>;

  constructor(
    private usersService: UsersService,
    private permissionsService: PermissionsService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private resourcesService: ResourcesService,
    public router: Router
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, false, false));
    const permissions$ = this.store.pipe(
      select(
        createCombinedPermissionsSelector(createCombinedPermissionsSelector(selectCanAdminUsers, selectCanReadResources), selectCanReadApps)
      )
    );
    permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((permissions) => {
      this.orgId = permissions.orgId;
      if (!this.orgId) {
        return;
      }
      this.hasPermissions = permissions.hasPermission;
      if (this.hasPermissions) {
        this.getData();

        this.updateTable();
        this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((params: UpdateTableParams) => {
          this.updateTable(params.key, params.searchDirection, params.limit);
        });
      }
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getData(): void {
    this.appsList$ = this.store.pipe(select(selectApiApplicationsList));
    this.resources$ = getResouces(this.resourcesService, this.orgId);
    this.resourceRoles$ = getResourceRoles(this.permissionsService, this.orgId);
  }

  private setAppIdToAppRolesMap(appsList: Array<Application>): void {
    for (const app of appsList) {
      const targetAppRolesList = app ? app.roles : [];
      const convertedRolesList: Array<ResourceRole> = targetAppRolesList.map((role) =>
        this.convertApplicationRoleToBasicResourceRole(
          role,
          this.getResourceTypeFromRequestedResourceType(UserRequestInfoSpec.RequestedResourceTypeEnum.application)
        )
      );
      this.appIdToAppRolesMap.set(app.id, convertedRolesList);
    }
  }

  public updateTable(
    emailKey = '',
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): void {
    const allData$ = combineLatest([this.appsList$, this.resources$, this.resourceRoles$]).pipe(
      concatMap(([appsListResp, resourcesResp, resourceRolesResp]) => {
        this.resourceRoles = resourceRolesResp;
        for (const resourceRole of this.resourceRoles) {
          this.resourceRoleNameToResourceRoleMap.set(resourceRole.spec.role_name, resourceRole);
        }
        this.setAppIdToAppRolesMap(appsListResp);
        this.setResourceMaps(resourcesResp);
        this.setApplicationMaps(appsListResp);
        return combineLatest([
          this.getUserRequestState$(limitParam, emailKey, searchDirectionParam),
          of(appsListResp),
          of(resourcesResp),
          of(resourceRolesResp),
        ]);
      })
    );

    allData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      ([userRequestStateResp, appsListResp, resourcesResp, _]: [
        UserRequestState,
        Array<Application>,
        Array<Resource>,
        Array<ResourceRole>
      ]) => {
        if (!resourcesResp || !appsListResp || (resourcesResp.length === 0 && appsListResp.length === 0)) {
          this.paginatorConfig.actions.dataFetched({
            data: [],
            searchDirection: 'forwards',
            limit: limitParam,
            nextKey: '',
            previousKey: '',
          });
          return;
        }
        this.initializeParentColumnDefs();
        this.buildData(userRequestStateResp, searchDirectionParam, limitParam);
        this.highlightRequestFromUrl();
        this.removeRequestIdParamFromUrl();
      },
      (err) => {
        this.paginatorConfig.actions.errorHandler(err);
      }
    );
  }

  private highlightRequestFromUrl(): void {
    const resourceRequestId = !!this.highlightedRequestId ? this.highlightedRequestId : this.getResourceRequestIdFromUrl();
    const targetRequestLocationData = this.getTargetRequestLocationDataFromId(resourceRequestId);
    // Open the expander:
    this.tableLayoutComp.expandedElementId = !!targetRequestLocationData
      ? targetRequestLocationData.parentIndex
      : this.tableLayoutComp.expandedElementId;
    // Highlight the row inside the expander:
    if (!!targetRequestLocationData) {
      this.tableData[targetRequestLocationData.parentIndex].expandedData.nestedTableData[
        targetRequestLocationData.requestIndex
      ].isHighlightedRow = true;
      this.highlightedRequestId = resourceRequestId;
    }
  }

  private getResourceRequestIdFromUrl(): string | undefined {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const resourceRequestId = urlParams.get('resource_request_id');
    return resourceRequestId;
  }

  public removeRequestIdParamFromUrl(): void {
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        org_id: this.orgId,
        resource_request_id: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  private getTargetRequestLocationDataFromId(requestId: string | undefined): RequestLocationData | undefined {
    if (!requestId) {
      return undefined;
    }
    for (const row of this.tableData) {
      for (const request of row.expandedData.nestedTableData) {
        if (request.request.metadata.id === requestId) {
          return {
            parentIndex: row.index,
            requestIndex: request.index,
          };
        }
      }
    }
    return undefined;
  }

  private setResourceMaps(resourcesResp: Array<Resource> | undefined): void {
    if (!resourcesResp) {
      return;
    }
    this.resourceIdToResourceMap.clear();
    this.resourceNameAndTypeToResourceMap.clear();
    for (const resource of resourcesResp) {
      this.resourceIdToResourceMap.set(resource.metadata.id, resource);
      this.resourceNameAndTypeToResourceMap.set(getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type), resource);
    }
  }

  private setApplicationMaps(appsResp: Array<Application> | undefined): void {
    if (!appsResp) {
      return;
    }
    this.appIdToAppMap.clear();
    this.appNameToAppMap.clear();
    for (const app of appsResp) {
      this.appIdToAppMap.set(app.id, app);
      this.appNameToAppMap.set(app.name, app);
    }
  }

  private reloadWindow(): void {
    this.paginatorConfig.actions.reloadWindow();
  }

  /**
   * Maps the resources/roles to the user for display in the table.
   */
  private buildData(userRequestState: UserRequestState, searchDirectionParam: 'forwards' | 'backwards', limitParam: number): void {
    this.tableData = [];
    this.tableData = this.setData(userRequestState);
    // signal that data has been fetched from the api
    this.paginatorConfig.actions.dataFetched({
      data: this.tableData,
      searchDirection: searchDirectionParam,
      limit: limitParam,
      nextKey: userRequestState.next_page_email,
      previousKey: userRequestState.previous_page_email,
    });
  }

  private setData(userRequestState: UserRequestState): Array<UserRequestElementExpandable> {
    const data: Array<UserRequestElementExpandable> = [];
    const userEmailsArray = Array.from(userRequestState.userEmailtoRequestsMap.keys());
    for (let i = 0; i < userEmailsArray.length; i++) {
      const userEmail = userEmailsArray[i];
      data.push(this.createUserRequestElementExpandable(userRequestState, userEmail, i));
    }
    this.setNestedTableData(data);
    return data;
  }

  private createUserRequestElementExpandable(
    userRequestState: UserRequestState,
    userEmail: string,
    index: number
  ): UserRequestElementExpandable {
    const detail: UserRequestDetail = userRequestState.userEmailtoRequestsMap.get(userEmail);
    const data: UserRequestElementExpandable = {
      ...getDefaultTableProperties(index),
      email: userEmail,
      id: detail.userId,
      external_id: detail.user.external_id,
      first_name: detail.user.first_name,
      last_name: detail.user.last_name,
      number_of_requests: this.getNumberOfRequests(detail),
      requests: detail.resourceRequests,
      user: detail.user,
      blanket_message: '',
      nestedFormColumnDefs: new Map(),
    };
    setColumnDefs([this.getBlanketMessageColumn()], data.nestedFormColumnDefs);
    return data;
  }

  private setNestedTableData(data: Array<UserRequestElementExpandable>): void {
    for (const element of data) {
      element.expandedData = {
        ...getDefaultNestedDataProperties(element),
        nestedRowObjectName: 'REQUEST',
        nestedButtonsToShow: [ButtonType.APPROVE, ButtonType.REJECT],
        customButtons: this.getCustomButtons(),
      };
      this.initializeNestedColumnDefs(element.expandedData.nestedColumnDefs);
      for (let i = 0; i < element.requests.length; i++) {
        const request = element.requests[i];
        const nestedElement = this.createRequestElement(element, request, i, element.user);
        element.expandedData.nestedTableData.push(nestedElement);
      }
    }
  }

  private getCustomButtons(): Array<TableButton> {
    const approveForThirtyDaysButton = new RowScopedButton(
      'APPROVE REQUEST FOR 30 DAYS',
      ButtonColor.PRIMARY,
      'Approve the request and disable the user in 30 days',
      'Button that approves request and disables user in 30 days',
      (requests: Array<ResourceRequestNestedElement>) => {
        const user = this.getUserFromRequests(requests);
        if (user?.status === UserStatusEnum.active) {
          return 'User has already been activated';
        }
        return 'Please select at least one request to approve';
      },
      `Button container that displays a tooltip when the approve request for 30 days button is disabled`,
      (requestsToApprove: Array<ResourceRequestNestedElement>) => {
        this.acceptBulkUsersRequests(requestsToApprove, true);
      }
    );
    approveForThirtyDaysButton.isDisabled = (requests: Array<ResourceRequestNestedElement>) => {
      const user = this.getUserFromRequests(requests);
      if (user?.status === UserStatusEnum.active) {
        return true;
      }
      for (const element of requests) {
        if (element.isChecked) {
          return false;
        }
      }
      return true;
    };
    return [approveForThirtyDaysButton];
  }

  private createRequestElement(
    parentElement: UserRequestElementExpandable,
    request: UserRequestInfo,
    index: number,
    user: User
  ): ResourceRequestNestedElement {
    const data: ResourceRequestNestedElement = {
      request,
      user,
      ...getDefaultTableProperties(index),
      ...request.spec,
      parentId: parentElement.index,
    };
    this.setDefaultRoleRequestValues(data);
    return data;
  }

  private getNumberOfRequests(userRequestDetail: UserRequestDetail): number {
    if (!userRequestDetail.resourceRequests) {
      return 0;
    }
    return userRequestDetail.resourceRequests.length;
  }

  /**
   * Adds the default role as the value for requested resource.
   */
  private setDefaultRoleRequestValues(userRequestElement: ResourceRequestNestedElement): void {
    if (
      userRequestElement.requested_resource_type === UserRequestInfoSpec.RequestedResourceTypeEnum.application ||
      userRequestElement.requested_resource_type === UserRequestInfoSpec.RequestedResourceTypeEnum.application_access
    ) {
      let targetApp = this.appNameToAppMap.get(userRequestElement.requested_resource);
      if (!targetApp) {
        targetApp = this.appIdToAppMap.get(userRequestElement.requested_resource);
      }
      userRequestElement.requested_sub_resource = !!targetApp?.default_role_name ? targetApp.default_role_name : '';
      userRequestElement.request.spec.requested_sub_resource = !!targetApp?.default_role_name ? targetApp.default_role_name : '';
      return;
    }
    let targetResource = this.resourceIdToResourceMap.get(userRequestElement.requested_resource);
    if (!targetResource) {
      // Workaround because profile use to set the "requested_resource" to app name for apps instead of the id.
      targetResource = this.resourceNameAndTypeToResourceMap.get(
        getResourceNameAndTypeString(userRequestElement.requested_resource, userRequestElement.requested_resource_type as ResourceTypeEnum)
      );
    }
    if (!!targetResource) {
      const targetResourceRole = this.resourceRoleNameToResourceRoleMap.get(userRequestElement.requested_sub_resource);
      if (!targetResourceRole) {
        // The user has not requested a specific role, so we assign it to the default resource role.
        userRequestElement.requested_sub_resource = getResourceDefaultRole(userRequestElement.request.spec);
        userRequestElement.request.spec.requested_sub_resource = getResourceDefaultRole(userRequestElement.request.spec);
      }
    }
  }

  private getResourceRoleName(role: OptionalResourceRole): string {
    return role.spec.role_name;
  }

  private getAccessRequests$(limit_param, email_param, search_direction_param): Observable<ListAccessRequestsResponse | null> {
    const params: ListAccessRequestsRequestParams = {
      org_id: this.orgId,
      limit: limit_param,
      email: email_param,
      search_direction: search_direction_param,
      request_state: 'pending',
    };
    return this.usersService.listAccessRequests(params).pipe(
      catchError((_) => {
        return of(null);
      })
    );
  }

  private getUserRequestState$(limit, email, search_direction): Observable<UserRequestState> {
    const userRequestsResp$ = this.getAccessRequests$(limit, email, search_direction);
    return userRequestsResp$.pipe(
      map((userRequestsResp) => {
        const previous_page_email = userRequestsResp.previous_page_email;
        const next_page_email = userRequestsResp.next_page_email;
        const userEmailtoRequestsMap: Map<string, UserRequestDetail> = new Map();
        for (const accessRequests of userRequestsResp.access_requests) {
          const newDetails: UserRequestDetail = {
            resourceRequests: accessRequests.status.user_requests,
            userId: accessRequests.metadata.id,
            user: accessRequests.status.user,
          };
          userEmailtoRequestsMap.set(accessRequests.status.user.email, newDetails);
        }
        const resourceNamesSet: Set<string> = new Set();
        for (const userRequest of userRequestsResp.access_requests) {
          for (const request of userRequest.status.user_requests) {
            const targetResource = this.resourceIdToResourceMap.get(request.spec.requested_resource);
            if (!!targetResource) {
              resourceNamesSet.add(targetResource.spec.name);
            }
          }
        }
        return {
          userEmailtoRequestsMap,
          resourceNamesSet,
          previous_page_email,
          next_page_email,
        };
      })
    );
  }

  /**
   * Workaround because profile sets the "requested_resource" to app name for apps instead of the id.
   */
  private getResourceName(element: ResourceRequestNestedElement): string | undefined {
    if (
      element.requested_resource_type === UserRequestInfoSpec.RequestedResourceTypeEnum.application ||
      element.requested_resource_type === UserRequestInfoSpec.RequestedResourceTypeEnum.application_access
    ) {
      const targetApp = this.getAppFromRequest(element);
      if (!!targetApp) {
        return targetApp.name;
      }
    }
    const targetResource = this.resourceIdToResourceMap.get(element.requested_resource);
    if (!!targetResource) {
      return targetResource.spec.name;
    }
    return `DELETED RESOURCE(${element.requested_resource})`;
  }

  private checkAllRequestsAreValidAndNotify(allUserRequestsToAccept: Array<ResourceRequestNestedElement>): boolean {
    for (const userRequestToAccept of allUserRequestsToAccept) {
      const resourceName = this.getResourceName(userRequestToAccept);
      if (!this.isValidRequest(userRequestToAccept.request)) {
        this.notificationService.error(this.getNoRoleErrorMessage(resourceName, userRequestToAccept.request.spec.requested_resource_type));
        return false;
      }
    }
    return true;
  }

  private isValidRequest(request: UserRequestInfo): boolean {
    if (!request.spec.requested_sub_resource) {
      // Role has not been chosen for the request, therefore it is 'invalid'.
      return false;
    }
    return true;
  }

  private setUserRequestMessage(userRequest: ResourceRequestNestedElement): void {
    const parentElement = this.getParentElementFromParentId(userRequest);
    if (!userRequest.response_information && !!parentElement.blanket_message) {
      userRequest.request.spec.response_information = parentElement.blanket_message;
    }
    if (!!userRequest.response_information) {
      userRequest.request.spec.response_information = userRequest.response_information;
    }
  }

  private getUserRequestFromTable(userRequest: ResourceRequestNestedElement): UserRequestInfo {
    this.setUserRequestMessage(userRequest);
    return userRequest.request;
  }

  /**
   * Will update the approved request values to match the table values.
   */
  private getApprovedRequestFromTableData(userRequestToAccept: ResourceRequestNestedElement): UserRequestInfo {
    const userRequest = this.getUserRequestFromTable(userRequestToAccept);
    userRequest.spec.requested_sub_resource = userRequestToAccept.requested_sub_resource;
    userRequest.spec.state = UserRequestInfoSpec.StateEnum.approved;
    return userRequestToAccept.request;
  }

  /**
   * Will update the rejected request values to match the table values.
   */
  private getRejectedRequestFromTableData(userRequestToAccept: ResourceRequestNestedElement): UserRequestInfo {
    const userRequest = this.getUserRequestFromTable(userRequestToAccept);
    userRequest.spec.state = UserRequestInfoSpec.StateEnum.declined;
    return userRequestToAccept.request;
  }

  private getPendingObjects(allUserRequestsToAccept: Array<ResourceRequestNestedElement>): Array<UserRequestUserUpdate> {
    const pendingObjects = allUserRequestsToAccept.filter((obj) => obj.user.status === 'pending');
    const uniqueObjects = pendingObjects.reduce((acc, obj) => {
      const key = `${obj.org_id}-${obj.user_id}`;
      if (!acc[key]) {
        acc[key] = {
          org_id: obj.org_id,
          user_id: obj.user_id,
          new_status: UserStatusEnum.active,
          reset_permissions: true,
        };
      }
      return acc;
    }, {});
    return Object.values(uniqueObjects);
  }

  private getUserFromRequests(userRequestElements: Array<ResourceRequestNestedElement>): User | undefined {
    if (!userRequestElements || userRequestElements.length === 0) {
      return undefined;
    }
    return userRequestElements[0].user;
  }

  public updateUserStatusOnApproval(user: User, isTemporary = false): void {
    if (user.status === UserStatusEnum.pending) {
      user.status = UserStatusEnum.active;
    }
    if (isTemporary) {
      user.disabled_at_time = getDateThirtyDaysFromNow();
    }
  }

  private updateUserIfPending$(user: User, isTemporary = false): Observable<User | undefined> {
    let updateUser$: Observable<User | undefined> = of(undefined);
    if (user?.status === UserStatusEnum.pending) {
      this.updateUserStatusOnApproval(user, isTemporary);
      updateUser$ = updateExistingUser$(this.usersService, user);
    }
    return updateUser$;
  }

  private getUpdatedUser$(allUserRequestsToAccept: Array<ResourceRequestNestedElement>, isTemporary = false): Observable<User | undefined> {
    const user = this.getUserFromRequests(allUserRequestsToAccept);
    return this.updateUserIfPending$(user, isTemporary);
  }

  private getBulkUsersRequestsObservable$(
    allUserRequestsToAccept: Array<ResourceRequestNestedElement>,
    isTemporary = false
  ): Observable<User | undefined> {
    if (!this.checkAllRequestsAreValidAndNotify(allUserRequestsToAccept)) {
      return of(undefined);
    }
    const allRequestsArray: Array<UserRequestInfo> = [];
    for (const userRequestToAccept of allUserRequestsToAccept) {
      const targetRequest = this.getApprovedRequestFromTableData(userRequestToAccept);
      allRequestsArray.push(targetRequest);
    }
    const requestParameters: BulkApproveRequestsRequestParams = {
      BulkUserRequestApproval: {
        org_id: this.orgId,
        user_updates: this.getPendingObjects(allUserRequestsToAccept),
        user_requests: allRequestsArray,
      },
    };

    const updatedUser$ = this.getUpdatedUser$(allUserRequestsToAccept, isTemporary);
    return updatedUser$.pipe(
      concatMap((userResp) => {
        return this.usersService.bulkApproveRequests(requestParameters, 'body', getIgnoreErrorsHeader()).pipe(map((_) => userResp));
      })
    );
  }

  public acceptBulkUsersRequests(allUserRequestsToAccept: Array<ResourceRequestNestedElement>, isTemporary = false): void {
    this.getBulkUsersRequestsObservable$(allUserRequestsToAccept, isTemporary)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (requestsResp: User) => {
          this.notificationService.success('All user requests successfully approved!');
        },
        (err) => {
          this.notificationService.error('Failed to approve all user requests. Please try again.');
        },
        () => {
          this.reloadWindow();
        }
      );
  }

  private getDeleteUser$(user: User): Observable<User> {
    const deleteParams: DeleteUserRequestParams = {
      user_id: user.id,
      org_id: user.org_id,
    };

    return this.usersService.deleteUser(deleteParams);
  }

  public rejectAllUserRequests(allUserRequestsToReject: Array<ResourceRequestNestedElement>): void {
    const allRequestsArray: Array<Observable<UserRequestInfo>> = [];
    for (const singleUserRequestsToReject of allUserRequestsToReject) {
      allRequestsArray.push(this.rejectSingleUserRequest$(singleUserRequestsToReject));
    }
    forkJoin(allRequestsArray)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success('User requests successfully rejected!');
        },
        (err) => {
          this.notificationService.error('Failed to reject all user requests. Please try again.');
        },
        () => {
          this.reloadWindow();
        }
      );
  }

  private rejectSingleUserRequest$(singleUserRequestsToReject: ResourceRequestNestedElement): Observable<UserRequestInfo> {
    let deleteUser: Observable<undefined> = of(undefined);
    if (singleUserRequestsToReject.user && singleUserRequestsToReject.user.status === UserStatusEnum.pending) {
      deleteUser = this.getDeleteUser$(singleUserRequestsToReject.user).pipe(map(() => undefined));
    }

    return deleteUser.pipe(
      concatMap((_) => {
        const targetRequest = this.getRejectedRequestFromTableData(singleUserRequestsToReject);
        const updateRequestParams: UpdateUserRequestRequestParams = {
          user_request_id: targetRequest.metadata?.id,
          UserRequestInfo: targetRequest,
        };
        return this.usersService.updateUserRequest(updateRequestParams);
      })
    );
  }

  private getNoRoleErrorMessage(resourceName: string, resourceType: UserRequestInfoSpec.RequestedResourceTypeEnum): string {
    const formatedResourceType = replaceCharacterWithSpace(resourceType, '_');
    return `Please choose a role for ${formatedResourceType} "${resourceName}"`;
  }

  public updateSelection(params: {
    value: string;
    column: Column<ResourceRequestNestedElement>;
    element: ResourceRequestNestedElement;
  }): void {
    if (params.value === '' && !!params.element.request) {
      const targetResourceName = this.getResourceName(params.element);
      this.notificationService.error(this.getNoRoleErrorMessage(targetResourceName, params.element.requested_resource_type));
      return;
    }
    params.element.requested_sub_resource = params.value;
  }

  private getResourceTypeFromRequestedResourceType(
    requestedResourceType: UserRequestInfoSpec.RequestedResourceTypeEnum
  ): ResourceRoleSpec.ResourceTypeEnum {
    if (
      requestedResourceType === UserRequestInfoSpec.RequestedResourceTypeEnum.fileshare ||
      requestedResourceType === UserRequestInfoSpec.RequestedResourceTypeEnum.file_share_access
    ) {
      return ResourceRoleSpec.ResourceTypeEnum.fileshare;
    }
    if (requestedResourceType === UserRequestInfoSpec.RequestedResourceTypeEnum.application_service) {
      return ResourceRoleSpec.ResourceTypeEnum.application_service;
    }
    if (requestedResourceType === UserRequestInfoSpec.RequestedResourceTypeEnum.desktop) {
      return ResourceRoleSpec.ResourceTypeEnum.desktop;
    }
    if (
      requestedResourceType === UserRequestInfoSpec.RequestedResourceTypeEnum.application ||
      requestedResourceType === UserRequestInfoSpec.RequestedResourceTypeEnum.application_access
    ) {
      return ResourceRoleSpec.ResourceTypeEnum.application;
    }
    return undefined;
  }

  /**
   * Parent Table Column
   */
  private getEmailColumn(): Column<UserRequestElementExpandable> {
    const emailColumn = createInputColumn('email');
    emailColumn.isEditable = false;
    emailColumn.isReadOnly = () => true;
    return emailColumn;
  }

  /**
   * Parent Table Column
   */
  private getFirstNameColumn(): Column<UserRequestElementExpandable> {
    const firstNameColumn = createInputColumn('first_name');
    firstNameColumn.isEditable = false;
    firstNameColumn.isReadOnly = () => true;
    return firstNameColumn;
  }

  /**
   * Parent Table Column
   */
  private getLastNameColumn(): Column<UserRequestElementExpandable> {
    const lastNameColumn = createInputColumn('last_name');
    lastNameColumn.isEditable = false;
    lastNameColumn.isReadOnly = () => true;
    return lastNameColumn;
  }

  /**
   * Parent Table Column
   */
  private getExternalIdColumn(): Column<UserRequestElementExpandable> {
    const externalIdColumn = createInputColumn('external_id');
    externalIdColumn.isEditable = false;
    externalIdColumn.isReadOnly = () => true;
    return externalIdColumn;
  }

  /**
   * Parent Table Column
   */
  private getNumberOfRequestsColumn(): Column<UserRequestElementExpandable> {
    const numberOfRequestsColumn = createInputColumn('number_of_requests');
    numberOfRequestsColumn.isEditable = false;
    numberOfRequestsColumn.isReadOnly = () => true;
    return numberOfRequestsColumn;
  }

  /**
   * Parent Table Column
   */
  private getExpandColumn(): Column<UserRequestElementExpandable> {
    const expandColumn = createExpandColumn();
    expandColumn.disableField = (element: UserRequestElementExpandable) => {
      return !element.requests || element.requests.length === 0;
    };
    return expandColumn;
  }

  private initializeParentColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getEmailColumn(),
        this.getFirstNameColumn(),
        this.getLastNameColumn(),
        this.getExternalIdColumn(),
        this.getNumberOfRequestsColumn(),
        this.getExpandColumn(),
      ],
      this.parentColumnDefs
    );
  }

  /**
   * Nested Form Column
   */
  private getBlanketMessageColumn(): Column<UserRequestElementExpandable> {
    const column = createInputColumn('blanket_message');
    column.displayName = 'Enter message to user here...';
    column.isEditable = true;
    column.getHeaderTooltip = (): string => {
      return `This message will be applied to all selected requests below for this user when approved or rejected. ${this.defaultUserMessageTooltip}`;
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getResourceTypeColumn(): Column<ResourceRequestNestedElement> {
    const resourceTypeColumn = createIconColumn('resource_type');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    resourceTypeColumn.getDisplayValue = (element: ResourceRequestNestedElement) => {
      const isResourceTypeEnum = createEnumChecker(ResourceType);
      if (isResourceTypeEnum(element.requested_resource_type)) {
        return getResourceTypeIcon(element.requested_resource_type);
      }
      return '';
    };
    resourceTypeColumn.getTooltip = (element: ResourceRequestNestedElement) => {
      const isResourceTypeEnum = createEnumChecker(ResourceType);
      if (isResourceTypeEnum(element.requested_resource_type)) {
        return getResourceTypeTooltip(element.requested_resource_type);
      }
      return '';
    };
    return resourceTypeColumn;
  }

  /**
   * Nested Table Column
   */
  private getRequestedResourceColumn(): Column<ResourceRequestNestedElement> {
    const requestedResourceColumn = createInputColumn('requested_resource');
    requestedResourceColumn.displayName = 'Resource Name';
    requestedResourceColumn.isReadOnly = () => true;
    requestedResourceColumn.getDisplayValue = (element: ResourceRequestNestedElement): string => {
      return this.getResourceName(element);
    };
    return requestedResourceColumn;
  }

  private convertApplicationRoleToBasicResourceRole(role: Role, resourceType: ResourceRoleSpec.ResourceTypeEnum): ResourceRole {
    return {
      spec: {
        resource_type: resourceType,
        role_name: role.name,
      },
    };
  }

  private getAppFromRequest(element: ResourceRequestNestedElement): Application | undefined {
    let targetApp = this.appNameToAppMap.get(element.requested_resource);
    if (!targetApp) {
      targetApp = this.appIdToAppMap.get(element.requested_resource);
    }
    return targetApp;
  }

  /**
   * Nested Table Column
   */
  private getRequestedSubResourceColumn(): Column<ResourceRequestNestedElement> {
    const requestedSubResourceColumn = createSelectColumn('requested_sub_resource');
    requestedSubResourceColumn.displayName = 'Role';
    // Need to only allow roles that pertain to this resource type in the dropdown.
    requestedSubResourceColumn.getAllowedValues = (element: ResourceRequestNestedElement): Array<ResourceRole> => {
      if (
        element.requested_resource_type === UserRequestInfoSpec.RequestedResourceTypeEnum.application ||
        element.requested_resource_type === UserRequestInfoSpec.RequestedResourceTypeEnum.application_access
      ) {
        const targetApp = this.getAppFromRequest(element);
        if (!!targetApp) {
          const roles = this.appIdToAppRolesMap.get(targetApp.id);
          return !!roles ? roles : [];
        }
        return [];
      }
      const filteredRolesArray: Array<ResourceRole> = this.resourceRoles.filter(
        (role) => role.spec.resource_type === this.getResourceTypeFromRequestedResourceType(element.requested_resource_type)
      );
      return filteredRolesArray;
    };
    requestedSubResourceColumn.formControl = new UntypedFormControl();
    requestedSubResourceColumn.getOptionValue = (role: OptionalResourceRole) => {
      return this.getResourceRoleName(role);
    };
    requestedSubResourceColumn.getOptionDisplayValue = (role: OptionalResourceRole): string => {
      return this.getResourceRoleName(role);
    };
    return requestedSubResourceColumn;
  }

  /**
   * Nested Table Column
   */
  private getRequestInformationColumn(): Column<ResourceRequestNestedElement> {
    const requestInformationColumn = createInputColumn('request_information');
    requestInformationColumn.displayName = 'Reason';
    requestInformationColumn.isReadOnly = () => true;
    return requestInformationColumn;
  }

  /**
   * Nested Table Column
   */
  private getRequestTimeColumn(): Column<ResourceRequestNestedElement> {
    const requestTimeColumn = createInputColumn('created');
    requestTimeColumn.displayName = 'Time';
    requestTimeColumn.isReadOnly = () => true;
    requestTimeColumn.getDisplayValue = (element: ResourceRequestNestedElement): string => {
      return element.request.metadata?.created ? convertDateToReadableFormat(element.request.metadata.created) : '';
    };
    return requestTimeColumn;
  }

  /**
   * Nested Table Column
   */
  private getResponseInformationColumn(): Column<ResourceRequestNestedElement> {
    const column = createInputColumn('response_information');
    column.displayName = 'Message';
    column.isEditable = true;
    column.getHeaderTooltip = (): string => {
      return `This message will be applied to this request only and will override the general message above if provided. ${this.defaultUserMessageTooltip}`;
    };
    return column;
  }

  private initializeNestedColumnDefs(nestedColumnDefs: Map<string, Column<ResourceRequestNestedElement>>): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getResourceTypeColumn(),
        this.getRequestedResourceColumn(),
        this.getRequestedSubResourceColumn(),
        this.getRequestInformationColumn(),
        this.getRequestTimeColumn(),
        this.getResponseInformationColumn(),
      ],
      nestedColumnDefs
    );
  }

  public getFormatedHeaderTitle(title: string): string {
    return capitalizeFirstLetter(replaceCharacterWithSpace(title, '_'));
  }

  private getParentElementFromParentId(
    resourceRequestNestedElement: ResourceRequestNestedElement
  ): UserRequestElementExpandable | undefined {
    return this.tableData[resourceRequestNestedElement.parentId];
  }

  private getApprovedUserRequestUserUpdateFromUser(user: User): UserRequestUserUpdate {
    return {
      org_id: this.orgId,
      user_id: user.id,
      new_status: UserStatusEnum.active,
      reset_permissions: true,
    };
  }

  public approveSelectedUsers(usersToApprove: Array<UserRequestElementExpandable>): void {
    const pendingUsersToApprove: Array<User> = usersToApprove
      .map((userElem) => userElem.user)
      .filter((user) => user.status === UserStatusEnum.pending);
    const pendingUserRequestUserUpdates: Array<UserRequestUserUpdate> = pendingUsersToApprove.map((user) =>
      this.getApprovedUserRequestUserUpdateFromUser(user)
    );
    let requestElemsToApprove: Array<ResourceRequestNestedElement> = [];
    for (const userElem of usersToApprove) {
      if (!!userElem.expandedData?.nestedTableData && userElem.expandedData?.nestedTableData.length !== 0) {
        const requestElems = userElem.expandedData.nestedTableData as Array<ResourceRequestNestedElement>;
        requestElemsToApprove = [...requestElemsToApprove, ...requestElems];
      }
    }
    if (!this.checkAllRequestsAreValidAndNotify(requestElemsToApprove)) {
      // Stop and notify if any requests are invalid
      return;
    }
    const requestsToApprove = requestElemsToApprove.map((requestElem) => this.getApprovedRequestFromTableData(requestElem));
    const requestParameters: BulkApproveRequestsRequestParams = {
      BulkUserRequestApproval: {
        org_id: this.orgId,
        user_updates: pendingUserRequestUserUpdates,
        user_requests: requestsToApprove,
      },
    };
    this.usersService
      .bulkApproveRequests(requestParameters, 'body', getIgnoreErrorsHeader())
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('All users successfully approved!');
        },
        (err) => {
          this.notificationService.error('Failed to approve all users. Please try again.');
        },
        () => {
          this.reloadWindow();
        }
      );
  }
}
