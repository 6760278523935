import { NgModule } from '@angular/core';
import { SharedModule } from '.';
import { CustomChiplistInputHelperService } from './components/custom-chiplist-input/custom-chiplist-input-helper.service';
import { CustomChiplistInputComponent } from './components/custom-chiplist-input/custom-chiplist-input.component';

@NgModule({
  imports: [SharedModule],
  declarations: [CustomChiplistInputComponent],
  exports: [CustomChiplistInputComponent],
  providers: [CustomChiplistInputHelperService],
})
export class CustomChiplistInputModule {}
