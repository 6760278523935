import { Component, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import { ApplicationModel } from '@app/core/models/application/application-model';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { StepperType } from '../stepper-type.enum';
import { getDefaultInitialModelStatus } from '@app/core/api-applications/api-applications.reducer';

@Component({
  selector: 'portal-apply-app-model',
  templateUrl: './apply-app-model.component.html',
  styleUrls: ['./apply-app-model.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplyAppModelComponent implements OnChanges, OnDestroy {
  @Input() public currentApplicationModel: ApplicationModel;
  @Input() public appModelStatus: ApplicationModelStatus = getDefaultInitialModelStatus();
  @Input() public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();
  @Input() public stepperType = StepperType.application;
  @Input() public hideButton = false;
  @Input() public progressBarInfoText = '';
  @Output() public submitModel = new EventEmitter<any>();
  @Output() public resetModelStatus = new EventEmitter<any>();
  public isSubmittingAppModel = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnChanges(): void {
    this.handleAppModelSubmissionProgressBarStateAndDetectChanges();
    this.handleAppModelDeleteProgressBarStateAndDetectChanges();
  }

  public ngOnDestroy(): void {
    this.resetModelStatus.emit();
  }

  public submitApplicationModel(): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.initializeProgressBar();
    this.isSubmittingAppModel = true;
    this.changeDetector.detectChanges();
    this.onSubmitModel();
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideAppModelSubmissionProgressBar(): void {
    setTimeout(() => {
      // Need to re-assign the progressBarController in order to
      // trigger the update in the template.
      this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.appModelSubmissionProgressBarController.hideProgressBarDelay);
  }

  private handleAppModelSubmissionProgressBarState(): void {
    if (this.appModelStatus.saving) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.initializeProgressBar();
      this.isSubmittingAppModel = true;
    }
    if (!this.appModelStatus.save_success && !this.appModelStatus.save_fail) {
      return;
    }
    if (this.appModelStatus.save_success) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.updateProgressBarValue(1, 1);
      this.delayHideAppModelSubmissionProgressBar();
    }
    if (this.appModelStatus.save_fail) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.onFailedUpload();
    }
    this.isSubmittingAppModel = false;
  }

  private handleAppModelSubmissionProgressBarStateAndDetectChanges(): void {
    this.handleAppModelSubmissionProgressBarState();
    this.changeDetector.detectChanges();
  }

  private handleAppModelDeleteProgressBarState(): void {
    if (this.appModelStatus.deleting) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.initializeProgressBar();
      this.isSubmittingAppModel = true;
    }
    if (!this.appModelStatus.delete_success && !this.appModelStatus.delete_failed) {
      return;
    }
    if (this.appModelStatus.delete_success) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.updateProgressBarValue(1, 1);
      this.delayHideAppModelSubmissionProgressBar();
    }
    if (this.appModelStatus.delete_failed) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.appModelSubmissionProgressBarController = this.appModelSubmissionProgressBarController.onFailedUpload();
    }
    this.isSubmittingAppModel = false;
  }

  private handleAppModelDeleteProgressBarStateAndDetectChanges(): void {
    this.handleAppModelDeleteProgressBarState();
    this.changeDetector.detectChanges();
  }

  private onSubmitModel(): void {
    this.submitModel.emit(this.currentApplicationModel);
  }

  public showButton(): boolean {
    return !this.hideButton;
  }
}
