import { Application, ApplicationConfig, OIDCProxyDomainNameMapping } from '@agilicus/angular';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { setDomainMappingConfigIfUnset } from '../application-configs-utils';
import { FilterManager } from '../filter/filter-manager';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { Column, createInputColumn, createSelectRowColumn } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { updateTableElements } from '../utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { Observable } from 'rxjs';

export interface OtherMappingsElement extends OIDCProxyDomainNameMapping, TableElement {}

@Component({
  selector: 'portal-other-mappings',
  templateUrl: './other-mappings.component.html',
  styleUrls: ['./other-mappings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherMappingsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public currentApplicationCopy: Application;
  @Input() public currentOrgIssuer: string;
  @Output() public updateApplication = new EventEmitter<any>();
  public tableData: Array<OtherMappingsElement> = [];
  public columnDefs: Map<string, Column<OtherMappingsElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'OTHER MAPPING';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
  }

  public ngOnChanges(): void {
    if (!this.currentApplicationCopy?.environments || this.currentApplicationCopy.environments.length === 0) {
      this.resetEmptyTable();
      return;
    }
    this.updateTable();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<OtherMappingsElement> = [];
    const otherMappings = this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.domain_mapping?.other_mappings;
    if (!!otherMappings) {
      for (let i = 0; i < otherMappings.length; i++) {
        data.push(this.createOtherMappingsElement(otherMappings[i], i));
      }
    }
    updateTableElements(this.tableData, data);
  }

  private createOtherMappingsElement(otherMapping: OIDCProxyDomainNameMapping, index: number): OtherMappingsElement {
    const data: OtherMappingsElement = {
      internal_name: '',
      external_name: '',
      ...getDefaultTableProperties(index),
      ...otherMapping,
    };
    return data;
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const internalNameColumn = createInputColumn('internal_name');
    internalNameColumn.isEditable = true;
    internalNameColumn.requiredField = () => true;

    const externalNameColumn = createInputColumn('external_name');
    externalNameColumn.isEditable = true;
    externalNameColumn.requiredField = () => true;

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(internalNameColumn.name, internalNameColumn);
    this.columnDefs.set(externalNameColumn.name, externalNameColumn);
  }

  public makeEmptyTableElement(): OtherMappingsElement {
    return {
      internal_name: '',
      external_name: '',
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Updates the config when a change is made in the table.
   */
  public updateEvent(): void {
    this.updateAppConfigsOtherMappings();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private removeOtherMappings(): void {
    this.tableData = this.tableData.filter((otherMappingsElement) => !otherMappingsElement.isChecked);
  }

  public deleteSelected(): void {
    this.removeOtherMappings();
    this.updateAppConfigsOtherMappings();
  }

  private updateAppConfigsOtherMappings(): void {
    const updatedAppConfigs = this.getUpdatedAppConfigs();
    this.updateApplication.emit(updatedAppConfigs);
  }

  private getUpdatedAppConfigs(): ApplicationConfig {
    const existingEnvironmentCopy = cloneDeep(this.currentApplicationCopy.environments[0]);
    setDomainMappingConfigIfUnset(existingEnvironmentCopy, this.currentOrgIssuer);
    const updatedOtherMappings = this.getOtherMappingsFromTable();
    existingEnvironmentCopy.application_configs.oidc_config.domain_mapping.other_mappings = updatedOtherMappings;
    return existingEnvironmentCopy.application_configs;
  }

  private getOtherMappingsFromTable(): Array<OIDCProxyDomainNameMapping> {
    const otherMappings: Array<OIDCProxyDomainNameMapping> = [];
    for (const element of this.tableData) {
      otherMappings.push({ internal_name: element.internal_name, external_name: element.external_name });
    }
    return otherMappings;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }
}
