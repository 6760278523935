<mat-paginator
  [hidden]="!usePaginator()"
  [disabled]="!usePaginator()"
  [pageSize]="paginatorConfig.paginatorPageSize"
  [pageSizeOptions]="useSharedPageSizeOptions()"
  showFirstLastButtons
  (page)="onPageEvent($event)"
  portalPageSelection
  [showPageSelection]="paginatorConfig.showPageSelection"
  [pageWindowSize]="paginatorConfig.pageWindowSize"
  [updatePageSelection]="updatePageSelection.asObservable()"
  [dataLength]="dataLength"
>
</mat-paginator>
