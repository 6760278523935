import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState, selectIssuerClientsState, selectPolicyState } from '../core.state';
import { Store, select } from '@ngrx/store';
import { concatMap, map, catchError, withLatestFrom, mergeMap, switchMap } from 'rxjs/operators';
import { of, EMPTY, Observable } from 'rxjs';
import {
  IssuerClientsActionTypes,
  ActionIssuerClientsInit,
  ActionIssuerClientsLoadIssuerClients,
  ActionIssuerClientsSetIssuerClientList,
  ActionIssuerClientsSavingIssuerClient,
  ActionIssuerClientsDeletingIssuerClient,
  ActionIssuerClientsSuccessfulIssuerClientSave,
  ActionIssuerClientsFailedIssuerClientSave,
  ActionIssuerClientsRefreshIssuerClientState,
  ActionIssuerClientsSuccessfulIssuerClientDeletion,
  ActionIssuerClientsFailedIssuerClientDeletion,
  ActionIssuerClientsSavingUpstreamAlias,
  ActionIssuerClientsSuccessfulUpstreamAliasUpdate,
  ActionIssuerClientsFailedUpstreamAliasUpdate,
  ActionIssuerClientsClearState,
  ActionIssuerClientsMaintainState,
} from './issuer-clients.actions';
import { selectApiOrgId, selectCurrentOrg } from '../user/user.selectors';
import { IssuerClientsState } from './issuer-clients.models';
import { selectCanReadApps } from '../user/permissions/app.selectors';
import {
  CreateUpstreamAliasRequestParams,
  GetUpstreamAliasRequestParams,
  Issuer,
  IssuersService,
  Organisation,
  patch_via_put,
  ReplaceUpstreamAliasRequestParams,
  UpstreamAlias,
} from '@agilicus/angular';
import { selectCanReadIssuers } from '../user/permissions/issuers.selectors';
import { NotificationService } from '../notifications/notification.service';
import { UserActionTypes, ActionUserRefreshOrgDependentData } from '../user/user.actions';
import { OrgQualifiedPermission, createCombinedPermissionsSelector } from '../user/permissions/permissions.selectors';
import { AppErrorHandler } from '../error-handler/app-error-handler.service';
import { createEntityRefreshOrgDependentEffect } from '../helpers/effect-factories';
import { createNewIssuerClient, deleteIssuerClient$, updateExistingIssuerClient } from './issuer-clients-utils';
import { cloneDeep } from 'lodash';
import { selectCurrentIssuer } from '../issuer-state/issuer.selectors';
import { PolicyState } from '../issuer/issuer.models';
import { selectIssuerClientsShouldPopulateValue } from './issuer-clients.selectors';
import * as IssuerClientsActions from './issuer-clients.actions';

@Injectable()
export class IssuerClientsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private issuersService: IssuersService,
    private notificationService: NotificationService,
    private appErrorHandler: AppErrorHandler
  ) {}

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnIssuerClientsLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActions.loadIssuerClients),
      map((action) => {
        return new ActionIssuerClientsLoadIssuerClients(action.org_id);
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnIssuerClientsMaintain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActions.maintainIssuerClients),
      map((action) => {
        return new ActionIssuerClientsMaintainState();
      })
    )
  );

  // Need this until we convert to use the ngrx entity types
  public convertActionTypeOnIssuerClientsClear$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActions.clearIssuerClients),
      map((action) => {
        return new ActionIssuerClientsClearState();
      })
    )
  );

  public initState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActionTypes.INIT),
      concatMap((action: ActionIssuerClientsInit) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId)), this.store.pipe(select(selectIssuerClientsState))))
      ),
      map(([action, orgId, state]) => {
        if (!orgId || (!!state.loaded_org_id && orgId === state.loaded_org_id && !action.force)) {
          return new ActionIssuerClientsMaintainState();
        }
        return new ActionIssuerClientsLoadIssuerClients(orgId);
      })
    )
  );

  public loadState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActionTypes.LOAD_ISSUER_CLIENTS),
      concatMap((action: ActionIssuerClientsLoadIssuerClients) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCurrentOrg)),
            this.store.pipe(select(createCombinedPermissionsSelector(selectCanReadIssuers, selectCanReadApps)))
          )
        )
      ),
      concatMap(([action, currentOrg, permissions]: [ActionIssuerClientsLoadIssuerClients, Organisation, OrgQualifiedPermission]) => {
        if (!permissions.hasPermission || permissions.orgId !== action.org_id || currentOrg?.id !== action.org_id) {
          return of(new ActionIssuerClientsClearState());
        }
        return this.issuersService.listClients({ org_id: action.org_id, summarize_collection: false }).pipe(
          map((issuerClients) => {
            return new ActionIssuerClientsSetIssuerClientList(issuerClients.clients, action.org_id);
          }),
          catchError((_) => {
            return of(new ActionIssuerClientsSetIssuerClientList([]));
          })
        );
      })
    )
  );

  public refreshOrg$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    IssuerClientsActions.loadIssuerClients,
    IssuerClientsActions.maintainIssuerClients,
    selectIssuerClientsShouldPopulateValue
  );

  public saving$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActionTypes.SAVING_ISSUER_CLIENT),
      switchMap((action: ActionIssuerClientsSavingIssuerClient) => {
        if (!action.current_issuer_client.id) {
          return this.postIssuerClient(action);
        }
        return this.putIssuerClient(action);
      })
    )
  );

  public deleting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActionTypes.DELETING_ISSUER_CLIENT),
      mergeMap((action: ActionIssuerClientsDeletingIssuerClient) => {
        return this.deleteIssuerClient(action);
      })
    )
  );

  public orgSwitched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.REFRESH_ORG_DEPENDENT_DATA),
      concatMap((action: ActionUserRefreshOrgDependentData) => {
        return of(action.orgId).pipe(withLatestFrom(this.store.select(selectIssuerClientsState)));
      }),
      concatMap(([orgId, state]: [string, IssuerClientsState]) => {
        if (!state.loaded_org_id || state.loaded_org_id === orgId) {
          return EMPTY;
        }
        return of(new ActionIssuerClientsLoadIssuerClients(orgId));
      })
    )
  );

  public saveUpstreamAliasOnIssuerClientSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActionTypes.SUCCESSFUL_ISSUER_CLIENT_SAVE),
      concatMap((action: ActionIssuerClientsSuccessfulIssuerClientSave) => {
        if (!action.upstreamAlias) {
          return EMPTY;
        }
        const upstreamAliasCopy = cloneDeep(action.upstreamAlias);
        upstreamAliasCopy.spec.client_id = action.current_issuer_client.id;
        return of(new ActionIssuerClientsSavingUpstreamAlias(upstreamAliasCopy));
      })
    )
  );

  public savingUpstreamAlias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerClientsActionTypes.SAVING_UPSTREAM_ALIAS),
      concatMap((action: ActionIssuerClientsSavingUpstreamAlias) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentIssuer)), this.store.pipe(select(selectPolicyState))))
      ),
      concatMap(([action, currentIssuer, policyState]: [ActionIssuerClientsSavingUpstreamAlias, Issuer, PolicyState]) => {
        if (action.upstream_alias === undefined) {
          return EMPTY;
        }
        if (!action.upstream_alias.metadata) {
          // Need to create a new upstream alias list:
          return this.postUpstreamAlias(action.upstream_alias, currentIssuer);
        }
        return this.putUpstreamAlias(action.upstream_alias, currentIssuer, policyState);
      })
    )
  );

  private postIssuerClient(
    action: ActionIssuerClientsSavingIssuerClient
  ): Observable<ActionIssuerClientsSuccessfulIssuerClientSave | ActionIssuerClientsFailedIssuerClientSave> {
    return createNewIssuerClient(this.issuersService, action.current_issuer_client).pipe(
      map((postResp) => {
        this.notificationService.success('New issuer client "' + postResp.name + '" was successfully created');
        return new ActionIssuerClientsSuccessfulIssuerClientSave(postResp, action.upstreamAlias);
      }),
      catchError((_) => {
        this.notificationService.error('Failed to create new issuer client "' + action.current_issuer_client.name + '"');
        return of(new ActionIssuerClientsFailedIssuerClientSave());
      })
    );
  }

  private putIssuerClient(
    action: ActionIssuerClientsSavingIssuerClient
  ): Observable<ActionIssuerClientsSuccessfulIssuerClientSave | ActionIssuerClientsFailedIssuerClientSave> {
    return updateExistingIssuerClient(this.issuersService, action.current_issuer_client).pipe(
      map((updatedClient) => {
        this.notificationService.success('Issuer client "' + updatedClient.name + '" was successfully updated');
        return new ActionIssuerClientsSuccessfulIssuerClientSave(updatedClient, action.upstreamAlias);
      }),
      catchError((error) => {
        const baseMessage = 'Failed to update issuer client "' + action.current_issuer_client.name + '"';
        this.appErrorHandler.handlePotentialConflict(error, baseMessage);
        return of(new ActionIssuerClientsFailedIssuerClientSave());
      })
    );
  }

  private deleteIssuerClient(
    action: ActionIssuerClientsDeletingIssuerClient
  ): Observable<
    | ActionIssuerClientsRefreshIssuerClientState
    | ActionIssuerClientsSuccessfulIssuerClientDeletion
    | ActionIssuerClientsFailedIssuerClientDeletion
  > {
    const issuerClientToDelete = action.issuer_client_to_delete;
    if (issuerClientToDelete.index === -1) {
      // Refreshing the state will remove the unsaved issuer client.
      return of(new ActionIssuerClientsRefreshIssuerClientState());
    }
    return deleteIssuerClient$(this.issuersService, issuerClientToDelete).pipe(
      map((_) => {
        this.notificationService.success('Issuer client "' + issuerClientToDelete.name + '" was successfully deleted');
        return new ActionIssuerClientsSuccessfulIssuerClientDeletion(issuerClientToDelete);
      }),
      catchError((_) => {
        this.notificationService.error('Failed to delete issuer client "' + issuerClientToDelete.name + '"');
        return of(new ActionIssuerClientsFailedIssuerClientDeletion());
      })
    );
  }

  private postUpstreamAlias(
    upstreamAlias: UpstreamAlias,
    currentIssuer: Issuer
  ): Observable<ActionIssuerClientsSuccessfulUpstreamAliasUpdate | ActionIssuerClientsFailedUpstreamAliasUpdate> {
    const createUpstreamAliasRequestParams: CreateUpstreamAliasRequestParams = {
      issuer_id: currentIssuer.id,
      UpstreamAlias: upstreamAlias,
    };
    return this.issuersService.createUpstreamAlias(createUpstreamAliasRequestParams).pipe(
      map((_) => {
        this.notificationService.success('Upstream aliases were successfully created!');
        return new ActionIssuerClientsSuccessfulUpstreamAliasUpdate();
      }),
      catchError((_) => {
        this.notificationService.error('Failed to create upstream aliases');
        return of(new ActionIssuerClientsFailedUpstreamAliasUpdate());
      })
    );
  }

  private putUpstreamAlias(
    upstreamAliasToModify: UpstreamAlias,
    currentIssuer: Issuer,
    policyState: PolicyState
  ): Observable<ActionIssuerClientsSuccessfulUpstreamAliasUpdate | ActionIssuerClientsFailedUpstreamAliasUpdate> {
    const putter = (upstreamAlias: UpstreamAlias) => {
      const replaceUpstreamAliasRequestParams: ReplaceUpstreamAliasRequestParams = {
        issuer_id: currentIssuer.id,
        upstream_alias_id: upstreamAlias.metadata.id,
        UpstreamAlias: upstreamAlias,
      };
      return this.issuersService.replaceUpstreamAlias(replaceUpstreamAliasRequestParams);
    };
    const getter = (upstreamAlias: UpstreamAlias) => {
      const getUpstreamAliasRequestParams: GetUpstreamAliasRequestParams = {
        issuer_id: policyState.current_issuer_policy.metadata.id,
        upstream_alias_id: upstreamAlias.metadata.id,
        org_id: upstreamAlias.spec.org_id,
      };
      return this.issuersService.getUpstreamAlias(getUpstreamAliasRequestParams);
    };
    const putUpstreamAlias$ = patch_via_put(upstreamAliasToModify, getter, putter);
    return putUpstreamAlias$.pipe(
      concatMap((_) => {
        this.notificationService.success('Upstream aliases were successfully updated!');
        return of(new ActionIssuerClientsSuccessfulUpstreamAliasUpdate());
      }),
      catchError((_) => {
        this.notificationService.error('Failed to update upstream aliases');
        return of(new ActionIssuerClientsFailedUpstreamAliasUpdate());
      })
    );
  }
}
