import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DefaultPresetValues } from '../auth-preset-dialog/auth-preset-dialog.component';
import { PageInfoData } from '../page-info/page-info.component';

export interface DialogButtonOptions {
  confirm: string;
  cancel: string;
}
export interface ConfirmationDialogData {
  messagePrefix?: string;
  message?: string;
  buttonText?: DialogButtonOptions;
  informationDialog?: boolean;
  orgId?: string;
  defaultValues?: DefaultPresetValues;
  icon?: string;
  productGuideData?: PageInfoData;
  showDoNotShowButton?: boolean;
}

@Component({
  selector: 'portal-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  public messagePrefix = '';
  public message = 'Do you want to continue?';
  public confirmButtonText = 'Yes';
  public cancelButtonText = 'Cancel';
  public informationDialog = false;
  public icon = 'warning';
  public productGuideData: PageInfoData;
  public showDoNotShowButton = false;

  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData, private dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
    if (data) {
      this.messagePrefix = data.messagePrefix || this.messagePrefix;
      this.message = data.message || this.message;
      this.icon = !!data.icon ? data.icon : this.icon;
      this.productGuideData = data.productGuideData;
      this.showDoNotShowButton = !!data.showDoNotShowButton;
      if (data.buttonText?.confirm.length >= 0) {
        this.confirmButtonText = data.buttonText.confirm;
      }
      if (data.buttonText?.cancel.length >= 0) {
        // the cancel button will be hidden if cancelButtonText.length === 0
        this.cancelButtonText = data.buttonText.cancel;
      }
      if (data.informationDialog) {
        this.informationDialog = true;
      }
    }
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  public onDoNotShowClick(): void {
    this.dialogRef.close(false);
  }
}
