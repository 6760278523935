import { ApiApplicationsState, createBlankApplication } from './api-applications.models';
import { ApiApplicationsActions, ApiApplicationsActionTypes } from './api-applications.actions';
import {
  Application,
  EnvironmentConfigVarList,
  Environment,
  RuleV2,
  FileSummary,
  CSPSettings,
  RoleV2,
  RuleConfig,
} from '@agilicus/angular';
import { cloneDeep } from 'lodash-es';
import {
  getAppFromId,
  getEnvFromName,
  unshiftElementToListIfProperty,
  updateElementInListIfProperty,
  removeElementFromListIfProperty,
  unshiftElementToListIfId,
  removeElementFromListIfId,
  updateElementInListIfId,
  getFirstElementForDefault,
  getDefaultCspSettings,
  getCspFromEnvironmentsList,
  getDefaultCspValue,
  getCspFromEnvConfigVarsList,
} from '@app/shared/components/utils';
import { getEmptyApplicationModel } from '../models/application/application-model-utils';
import { defaultFileShareModel } from '../models/file-share/file-share-model';
import { defaultDesktopModel } from '../models/desktop/desktop-model';
import { defaultSSHModel } from '../models/ssh/ssh-model';
import { removeRoleToRuleEntries, removeRulesFromList, updateRoleToRuleEntriesList } from './api-applications-utils';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import { defaultNetworkModel } from '../models/network/network-model';
import { defaultLauncherModel } from '../models/launcher/launcher-model';
import { ResourceModel } from '../models/resource/resource-model';
import { getGuidFromObject } from '../api/state-driven-crud/state-driven-crud';
import {
  getTargetPolicyTemplateInstanceResource,
  PolicyTemplateInstanceResource,
} from '../api/policy-template-instance/policy-template-instance-utils';

export const apiApplicationsInitialState: ApiApplicationsState = {
  applications: [],
  current_application: undefined,
  previous_application: undefined,
  current_environment: undefined,
  previous_environment: undefined,
  current_rule: undefined,
  previous_rule: undefined,
  current_environment_config_var_list: { configs: [] },
  previous_environment_config_var_list: { configs: [] },
  current_environment_file_config_list: [],
  previous_environment_file_config_list: [],
  current_external_mounts_list: [],
  previous_external_mounts_list: [],
  current_application_bundles_list: [],
  previous_application_bundles_list: [],
  current_roles_list: [],
  previous_roles_list: [],
  set_default_role_and_publish_application: false,
  current_rules_list: [],
  previous_rules_list: [],
  current_role_to_rule_entries_list: [],
  previous_role_to_rule_entries_list: [],
  current_application_content_security_policy: undefined,
  previous_application_content_security_policy: undefined,
  saving_app: false,
  app_saved: true,
  creating_new_app: false,
  applications_org_id: undefined,
  application_id: undefined,
  environment_name: undefined,
  rule_id: undefined,
  new_file_added: false,
  loading_env_file_config: false,
  should_populate: false,
  new_app_bundle_added: false,
  saving_app_icon_file: false,
  app_icon_file_save_success: false,
  app_icon_file_save_fail: false,
  deleting_current_app_icon_file: false,
  current_icon_files_list: [],
  application_model: getEmptyApplicationModel(),
  application_model_status: getDefaultInitialModelStatus(),
  file_share_model: defaultFileShareModel(),
  file_share_model_status: getDefaultInitialModelStatus(),
  desktop_model: defaultDesktopModel(),
  desktop_model_status: getDefaultInitialModelStatus(),
  ssh_model: defaultSSHModel(),
  ssh_model_status: getDefaultInitialModelStatus(),
  network_model: defaultNetworkModel(),
  network_model_status: getDefaultInitialModelStatus(),
  launcher_model: defaultLauncherModel(),
  launcher_model_status: getDefaultInitialModelStatus(),
  refresh_data: 0,
  saving_rule: false,
  demo_status: getDefaultInitialModelStatus(),
  get_all_current_app_config_from_api: true,
  use_policy_rules: false,
};

export function getDefaultInitialModelStatus(): ApplicationModelStatus {
  return {
    saving: false,
    save_success: false,
    save_fail: false,
    complete: false,
  };
}

export function apiApplicationsReducer(
  state: ApiApplicationsState = apiApplicationsInitialState,
  action: ApiApplicationsActions
): ApiApplicationsState {
  switch (action.type) {
    case ApiApplicationsActionTypes.SET_APP_STATE:
      return {
        ...state,
        applications: action.applications,
        applications_org_id: action.org_id,
        refresh_data: state.refresh_data + 1,
      };

    case ApiApplicationsActionTypes.SET_CURRENT_APP_STATE:
      return {
        ...state,
        current_application: action.current_application,
        previous_application: undefined,
        current_environment: getDefaultCurrentEnvironment(action.current_application),
        previous_environment: undefined,
        saving_app: false,
        app_saved: true,
        creating_new_app: false,
      };

    case ApiApplicationsActionTypes.UPDATE_APPLICATIONS:
      return {
        ...state,
        applications: action.applications,
        current_application: updateCurrentAppFromAppListUpdate(state, action.applications),
        current_environment: updateCurrentEnvFromAppListUpdate(state, action.applications),
      };

    case ApiApplicationsActionTypes.MODIFY_CURRENT_APP:
      return {
        ...state,
        saving_app: true,
        app_saved: false,
        set_default_role_and_publish_application: !!action.setDefaultRoleAndPublishApplication,
        use_policy_rules: action.usePolicyRules,
        refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      };

    case ApiApplicationsActionTypes.APP_SAVE_FINISHED:
      return {
        ...state,
        applications: getUpdatedApplications(action.current_application, state.applications),
        current_application: !!action.current_application ? action.current_application : state.current_application,
        current_environment: getUpdatedCurrentEnvironment(action.current_application, state.current_environment),
        saving_app: false,
        app_saved: true,
        creating_new_app: false,
        refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      };

    case ApiApplicationsActionTypes.APP_DELETE_FINISHED:
      return {
        ...state,
        applications: removeDeletedApplications([action.deleted_application.id], state.applications),
        current_application: resetCurrentAppIfDeleted([action.deleted_application.id], state.current_application, state.applications),
        current_environment: getUpdatedCurrentEnvironment(
          resetCurrentAppIfDeleted([action.deleted_application.id], state.current_application, state.applications),
          state.current_environment
        ),
        refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      };

    case ApiApplicationsActionTypes.APP_DELETES_FINISHED:
      return {
        ...state,
        applications: removeDeletedApplications(action.deleted_application_ids, state.applications),
        current_application: resetCurrentAppIfDeleted(action.deleted_application_ids, state.current_application, state.applications),
        current_environment: getUpdatedCurrentEnvironment(
          resetCurrentAppIfDeleted(action.deleted_application_ids, state.current_application, state.applications),
          state.current_environment
        ),
        refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      };

    case ApiApplicationsActionTypes.UNSET_DEFAULT_ROLE_FLAG:
      return {
        ...state,
        set_default_role_and_publish_application: false,
      };

    case ApiApplicationsActionTypes.CREATING_NEW_APP:
      return {
        ...state,
        previous_application: state.current_application,
        current_application: createBlankApplication(),
        current_environment_config_var_list: { configs: [] },
        previous_environment_config_var_list: state.current_environment_config_var_list,
        current_application_bundles_list: [],
        previous_application_bundles_list: state.current_application_bundles_list,
        current_roles_list: [],
        previous_roles_list: state.current_roles_list,
        current_rules_list: [],
        previous_rules_list: state.current_rules_list,
        current_role_to_rule_entries_list: [],
        previous_role_to_rule_entries_list: state.current_role_to_rule_entries_list,
        current_external_mounts_list: [],
        previous_external_mounts_list: state.current_external_mounts_list,
        current_environment_file_config_list: [],
        previous_environment_file_config_list: state.current_environment_file_config_list,
        current_environment: undefined,
        previous_environment: state.current_environment,
        current_application_content_security_policy: getContentSecuityPolicyFromEnvVars(undefined),
        previous_application_content_security_policy: state.current_application_content_security_policy,
        saving_app: false,
        app_saved: false,
        creating_new_app: true,
      };

    case ApiApplicationsActionTypes.CREATING_NEW_APP_CANCELED:
      return {
        ...state,
        current_application: state.previous_application,
        current_environment: state.previous_environment,
        current_rule: state.previous_rule,
        current_environment_config_var_list: state.previous_environment_config_var_list,
        current_application_bundles_list: state.previous_application_bundles_list,
        current_roles_list: state.previous_roles_list,
        current_rules_list: state.previous_rules_list,
        current_role_to_rule_entries_list: state.previous_role_to_rule_entries_list,
        current_external_mounts_list: state.previous_external_mounts_list,
        current_environment_file_config_list: state.previous_environment_file_config_list,
        current_application_content_security_policy: state.previous_application_content_security_policy,
        saving_app: false,
        app_saved: true,
        creating_new_app: false,
      };

    case ApiApplicationsActionTypes.CREATING_NEW_APP_CANCELED_ON_APP_CHANGES:
      return {
        ...state,
        saving_app: false,
        app_saved: true,
        creating_new_app: false,
      };

    case ApiApplicationsActionTypes.UPDATE_APP_ID:
      return {
        ...state,
        application_id: action.application_id,
      };

    case ApiApplicationsActionTypes.REFRESH_APP_STATE:
      return {
        ...state,
      };

    case ApiApplicationsActionTypes.UPDATE_CURRENT_APP:
      return {
        ...state,
        current_application: action.current_application,
        current_environment: getDefaultCurrentEnvironment(action.current_application),
        refresh_data: state.refresh_data + 1,
      };

    case ApiApplicationsActionTypes.UPDATE_CURRENT_ENV:
      return {
        ...state,
        previous_environment: action.current_environment,
        current_environment: action.current_environment,
      };

    case ApiApplicationsActionTypes.UPDATE_ENV_NAME:
      return {
        ...state,
        environment_name: action.environment_name,
        // current_environment update is triggered by a later effect
      };

    case ApiApplicationsActionTypes.REFRESH_ENV_STATE:
      return {
        ...state,
      };

    case ApiApplicationsActionTypes.REFRESH_RULE_STATE:
      return {
        ...state,
      };

    case ApiApplicationsActionTypes.RELOAD_APPLICATION:
      return {
        ...state,
        applications: updateAppListFromApp(state.applications, action.updated_application),
        current_application: updateCurrentAppFromAppListUpdate(state, updateAppListFromApp(state.applications, action.updated_application)),
        current_environment: updateCurrentEnvFromAppListUpdate(state, updateAppListFromApp(state.applications, action.updated_application)),
      };

    case ApiApplicationsActionTypes.SET_ENV_CONFIG_VAR_LIST:
      return {
        ...state,
        current_environment_config_var_list: action.current_environment_config_var_list,
        previous_environment_config_var_list: action.current_environment_config_var_list,
        current_application_content_security_policy: getContentSecuityPolicyFromEnvVars(action.current_environment_config_var_list),
        previous_application_content_security_policy: getContentSecuityPolicyFromEnvVars(action.current_environment_config_var_list),
      };

    case ApiApplicationsActionTypes.ENV_CONFIG_VAR_SAVE_FINISHED:
      return {
        ...state,
        current_environment_config_var_list: !!action.current_environment_config_var_list
          ? action.current_environment_config_var_list
          : state.current_environment_config_var_list,
      };

    case ApiApplicationsActionTypes.SET_ENV_FILE_CONFIG_LIST:
      return {
        ...state,
        current_environment_file_config_list: action.current_environment_file_config_list,
        previous_environment_file_config_list: action.current_environment_file_config_list,
        loading_env_file_config: false,
      };

    case ApiApplicationsActionTypes.LOADING_ENV_FILE_CONFIGS:
      return {
        ...state,
        loading_env_file_config: true,
      };

    case ApiApplicationsActionTypes.FAILED_ENV_FILE_CONFIG_UPDATE:
      return {
        ...state,
        current_environment_file_config_list: [],
        loading_env_file_config: false,
      };

    case ApiApplicationsActionTypes.ADD_TO_ENV_FILE_CONFIG_LIST:
      return {
        ...state,
        current_environment_file_config_list: unshiftElementToListIfProperty(
          action.env_file_config_to_add,
          state.current_environment_file_config_list,
          'config_id'
        ),
        new_file_added: true,
      };

    case ApiApplicationsActionTypes.UPDATE_ENV_FILE_CONFIG_LIST:
      return {
        ...state,
        current_environment_file_config_list: updateElementInListIfProperty(
          action.env_file_config_to_update,
          state.current_environment_file_config_list,
          'config_id'
        ),
      };

    case ApiApplicationsActionTypes.REMOVE_FROM_ENV_FILE_CONFIG_LIST:
      return {
        ...state,
        current_environment_file_config_list: removeElementFromListIfProperty(
          action.deleted_env_file_config,
          state.current_environment_file_config_list,
          'config_id'
        ),
      };

    case ApiApplicationsActionTypes.REMOVE_NEW_FILE_ADDED_FLAG:
      return {
        ...state,
        new_file_added: false,
      };

    case ApiApplicationsActionTypes.INIT_APPLICATIONS:
      return {
        ...state,
        should_populate: true,
        // reset the models if they are undefined
        application_model: !!state?.application_model ? state.application_model : getEmptyApplicationModel(),
        desktop_model: !!state?.desktop_model ? state.desktop_model : defaultDesktopModel(),
        ssh_model: !!state?.ssh_model ? state.ssh_model : defaultSSHModel(),
        file_share_model: !!state?.file_share_model ? state.file_share_model : defaultFileShareModel(),
        network_model: !!state?.network_model ? state.network_model : defaultNetworkModel(),
        launcher_model: !!state?.launcher_model ? state.launcher_model : defaultLauncherModel(),
        get_all_current_app_config_from_api: action.getAllCurrentAppConfig,
      };

    case ApiApplicationsActionTypes.ADD_TO_APP_BUNDLE_LIST:
      return {
        ...state,
        current_application_bundles_list: unshiftElementToListIfProperty(action.app_bundle_to_add, state.current_application_bundles_list),
        new_app_bundle_added: true,
      };

    case ApiApplicationsActionTypes.UPDATE_APP_BUNDLE_LIST:
      return {
        ...state,
        current_application_bundles_list: updateElementInListIfProperty(
          action.app_bundle_to_update,
          state.current_application_bundles_list
        ),
      };

    case ApiApplicationsActionTypes.REMOVE_FROM_APP_BUNDLE_LIST:
      return {
        ...state,
        current_application_bundles_list: removeElementFromListIfProperty(
          action.deleted_app_bundle,
          state.current_application_bundles_list
        ),
      };

    case ApiApplicationsActionTypes.REMOVE_NEW_APP_BUNDLE_ADDED_FLAG:
      return {
        ...state,
        new_app_bundle_added: false,
      };

    case ApiApplicationsActionTypes.SET_ENV_CONFIG_LIST:
      return {
        ...state,
        current_external_mounts_list: action.current_external_mounts_list,
        previous_external_mounts_list: action.current_external_mounts_list,
      };

    case ApiApplicationsActionTypes.FAILED_ENV_CONFIG_LOAD:
      return {
        ...state,
        current_external_mounts_list: [],
      };

    case ApiApplicationsActionTypes.ADD_TO_ENV_CONFIG_LIST:
      return {
        ...state,
        current_external_mounts_list: unshiftElementToListIfProperty(action.external_mount_to_add, state.current_external_mounts_list),
      };

    case ApiApplicationsActionTypes.UPDATE_ENV_CONFIG_LIST:
      return {
        ...state,
        current_external_mounts_list: updateElementInListIfProperty(action.external_mount_to_update, state.current_external_mounts_list),
      };

    case ApiApplicationsActionTypes.REMOVE_FROM_ENV_CONFIG_LIST:
      return {
        ...state,
        current_external_mounts_list: removeElementFromListIfProperty(action.deleted_external_mount, state.current_external_mounts_list),
      };

    case ApiApplicationsActionTypes.SET_ROLE_LIST:
      return {
        ...state,
        current_roles_list: action.current_roles_list,
        previous_roles_list: action.current_roles_list,
      };

    case ApiApplicationsActionTypes.FAILED_ROLE_LOAD:
      return {
        ...state,
        current_roles_list: [],
      };

    case ApiApplicationsActionTypes.SAVING_ROLE:
      return {
        ...state,
        set_default_role_and_publish_application: !!action.setDefaultRoleAndPublishApplication,
      };

    case ApiApplicationsActionTypes.ADD_TO_ROLE_LIST:
      return {
        ...state,
        current_roles_list: unshiftElementToListIfId(action.api_obj, state.current_roles_list),
      };

    case ApiApplicationsActionTypes.UPDATE_ROLE_LIST:
      return {
        ...state,
        current_roles_list: updateRolesList(action.api_obj, action.previousRoleId, state.current_roles_list),
      };

    case ApiApplicationsActionTypes.REMOVE_FROM_ROLE_LIST:
      return {
        ...state,
        current_roles_list: removeElementFromListIfId(action.api_obj, state.current_roles_list),
      };

    case ApiApplicationsActionTypes.REFRESH_ROLE_STATE:
      return {
        ...state,
        current_roles_list: cloneDeep(state.current_roles_list),
      };

    case ApiApplicationsActionTypes.SET_RULE_LIST:
      return {
        ...state,
        current_rules_list: action.current_rules_list,
        previous_rules_list: action.current_rules_list,
        current_rule: getCurrentRuleFromPolicy(action.current_rules_list, state, action.current_policy_resource_list, undefined),
        previous_rule: getCurrentRuleFromPolicy(action.current_rules_list, state, action.current_policy_resource_list, undefined),
        refresh_data: state.refresh_data + 1,
      };

    case ApiApplicationsActionTypes.SET_CURRENT_RULE_FROM_POLICY:
      return {
        ...state,
        previous_rule: state.current_rule,
        current_rule: getCurrentRuleFromPolicy(state.current_rules_list, state, action.current_policy_resource_list, state.current_rule),
        refresh_data: state.refresh_data + 1,
      };

    case ApiApplicationsActionTypes.FAILED_RULE_LOAD:
      return {
        ...state,
        current_rules_list: [],
        current_rule: undefined,
        previous_rule: undefined,
      };

    case ApiApplicationsActionTypes.ADD_TO_RULE_LIST:
      return {
        ...state,
        current_rules_list: unshiftElementToListIfId(action.api_obj, state.current_rules_list),
      };

    case ApiApplicationsActionTypes.UPDATE_RULE_LIST:
      return {
        ...state,
        current_rules_list: updateElementInListIfId(action.api_obj, state.current_rules_list),
        current_rule: action.api_obj,
        previous_rule: action.api_obj,
      };

    case ApiApplicationsActionTypes.REMOVE_FROM_RULE_LIST:
      return {
        ...state,
        current_rules_list: removeRulesFromList(action.api_objs, state.current_rules_list),
        current_rule: setRuleToUndefinedIfDeleted(action.api_objs, state.current_rule),
        previous_rule: setRuleToUndefinedIfDeleted(action.api_objs, state.previous_rule),
      };

    case ApiApplicationsActionTypes.SET_ROLE_TO_RULE_ENTRIES_LIST:
      return {
        ...state,
        current_role_to_rule_entries_list: action.current_role_to_rule_entries_list,
        previous_role_to_rule_entries_list: action.current_role_to_rule_entries_list,
      };

    case ApiApplicationsActionTypes.FAILED_ROLE_TO_RULE_ENTRIES_LOAD:
      return {
        ...state,
        current_role_to_rule_entries_list: [],
      };

    case ApiApplicationsActionTypes.ADD_TO_ROLE_TO_RULE_ENTRIES_LIST:
      return {
        ...state,
        current_role_to_rule_entries_list: unshiftElementToListIfId(action.api_obj, state.current_role_to_rule_entries_list),
      };

    case ApiApplicationsActionTypes.UPDATE_ROLE_TO_RULE_ENTRIES_LIST:
      return {
        ...state,
        current_role_to_rule_entries_list: updateRoleToRuleEntriesList(action.api_objs, state.current_role_to_rule_entries_list),
      };

    case ApiApplicationsActionTypes.REMOVE_FROM_ROLE_TO_RULE_ENTRIES_LIST:
      return {
        ...state,
        current_role_to_rule_entries_list: removeRoleToRuleEntries(action.api_objs, state.current_role_to_rule_entries_list),
      };

    case ApiApplicationsActionTypes.UPDATE_RULE_ID:
      return {
        ...state,
        rule_id: action.rule_id,
        // current_rule update is triggered by a later effect
      };

    case ApiApplicationsActionTypes.UPDATE_CURRENT_RULE:
      return {
        ...state,
        current_rule: action.current_rule,
        previous_rule: action.current_rule,
      };

    case ApiApplicationsActionTypes.SAVING_APP_ICON_FILE:
      return {
        ...state,
        saving_app_icon_file: true,
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_APP_ICON_FILE_SAVE:
      return {
        ...state,
        saving_app_icon_file: false,
        app_icon_file_save_success: true,
        app_icon_file_save_fail: false,
        current_icon_files_list: unshiftElementToListIfProperty(action.new_app_icon_file, state.current_icon_files_list),
      };

    case ApiApplicationsActionTypes.FAILED_APP_ICON_FILE_SAVE:
      return {
        ...state,
        saving_app_icon_file: false,
        app_icon_file_save_success: false,
        app_icon_file_save_fail: true,
      };

    case ApiApplicationsActionTypes.DELETING_APP_ICON_URL:
      return {
        ...state,
        deleting_current_app_icon_file: true,
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_APP_ICON_FILE_DELETION:
      return {
        ...state,
        deleting_current_app_icon_file: false,
        current_icon_files_list: removePreviousIconFiles(state),
      };

    case ApiApplicationsActionTypes.RESET_APP_ICON_FILE_UPLOAD_STATUS:
      return {
        ...state,
        saving_app_icon_file: false,
        app_icon_file_save_success: false,
        app_icon_file_save_fail: false,
      };

    case ApiApplicationsActionTypes.SET_APP_FILES_LIST:
      return {
        ...state,
        current_application_bundles_list: getAppBundleFilesFromList(action.application_files_list),
        previous_application_bundles_list: getAppBundleFilesFromList(action.application_files_list),
        current_icon_files_list: getIconFilesFromList(action.application_files_list),
      };

    case ApiApplicationsActionTypes.FAILED_APP_FILES_UPDATE:
      return {
        ...state,
        current_application_bundles_list: [],
        current_icon_files_list: [],
      };

    case ApiApplicationsActionTypes.UPDATE_APPLICATION_MODEL:
      return {
        ...state,
        application_model: action.application_model,
        application_model_status: {
          ...state.application_model_status,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.RESET_APPLICATION_MODEL:
      return {
        ...state,
        application_model: getEmptyApplicationModel(),
        application_model_status: getDefaultInitialModelStatus(),
      };

    case ApiApplicationsActionTypes.SUBMITTING_APPLICATION_MODEL:
      return {
        ...state,
        application_model_status: {
          saving: true,
          save_success: false,
          save_fail: false,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_APPLICATION_MODEL_SUBMISSION:
      return {
        ...state,
        application_model_status: {
          saving: false,
          save_success: true,
          save_fail: false,
          complete: true,
        },
      };

    case ApiApplicationsActionTypes.FAILED_APPLICATION_MODEL_SUBMISSION:
      return {
        ...state,
        application_model_status: {
          saving: false,
          save_success: false,
          save_fail: true,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.RESET_APP_MODEL_SUBMISSION_STATUS:
      return {
        ...state,
        application_model_status: {
          saving: false,
          save_success: false,
          save_fail: false,
          complete: state.application_model_status.complete,
        },
      };

    case ApiApplicationsActionTypes.UPDATE_FILE_SHARE_SERVICE_MODEL:
      return {
        ...state,
        file_share_model: action.model,
        file_share_model_status: {
          ...state.file_share_model_status,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.RESET_FILE_SHARE_SERVICE_MODEL:
      return {
        ...state,
        file_share_model: defaultFileShareModel(),
        file_share_model_status: getDefaultInitialModelStatus(),
      };

    case ApiApplicationsActionTypes.SUBMITTING_FILE_SHARE_SERVICE_MODEL:
      return {
        ...state,
        file_share_model: action.model,
        file_share_model_status: {
          saving: true,
          save_success: false,
          save_fail: false,
          complete: false,
          share: undefined,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_FILE_SHARE_SERVICE_MODEL_SUBMISSION:
      return {
        ...state,
        file_share_model: addIdToModel(state.file_share_model, action.share),
        file_share_model_status: {
          saving: false,
          save_success: true,
          save_fail: false,
          complete: true,
          share: action.share,
        },
      };

    case ApiApplicationsActionTypes.FAILED_FILE_SHARE_SERVICE_MODEL_SUBMISSION:
      return {
        ...state,
        file_share_model_status: {
          saving: false,
          save_success: false,
          save_fail: true,
          complete: false,
          share: undefined,
        },
      };

    case ApiApplicationsActionTypes.RESET_FILE_SHARE_SERVICE_MODEL_SUBMISSION_STATUS:
      return {
        ...state,
        file_share_model_status: {
          saving: false,
          save_success: false,
          save_fail: false,
          complete: state.file_share_model_status.complete,
          // Copy over the share because we need to propagate it between steps
          share: state.file_share_model_status.share,
        },
      };

    case ApiApplicationsActionTypes.UPDATE_DESKTOP_MODEL:
      return {
        ...state,
        desktop_model: action.desktop_model,
        desktop_model_status: {
          ...state.desktop_model_status,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.RESET_DESKTOP_MODEL:
      return {
        ...state,
        desktop_model: defaultDesktopModel(),
        desktop_model_status: getDefaultInitialModelStatus(),
      };

    case ApiApplicationsActionTypes.SUBMITTING_DESKTOP_MODEL:
      return {
        ...state,
        desktop_model: action.desktop_model,
        desktop_model_status: {
          saving: true,
          save_success: false,
          save_fail: false,
          complete: false,
          share: undefined,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_DESKTOP_MODEL_SUBMISSION:
      return {
        ...state,
        desktop_model: addIdToModel(state.desktop_model, action.desktopResource),
        desktop_model_status: {
          saving: false,
          save_success: true,
          save_fail: false,
          complete: true,
          desktopResource: action.desktopResource,
        },
      };

    case ApiApplicationsActionTypes.FAILED_DESKTOP_MODEL_SUBMISSION:
      return {
        ...state,
        desktop_model_status: {
          saving: false,
          save_success: false,
          save_fail: true,
          complete: false,
          desktopResource: undefined,
        },
      };

    case ApiApplicationsActionTypes.RESET_DESKTOP_MODEL_SUBMISSION_STATUS:
      return {
        ...state,
        desktop_model_status: {
          saving: false,
          save_success: false,
          save_fail: false,
          complete: state.desktop_model_status.complete,
          // Copy over the desktop because we need to propagate it between steps
          desktopResource: state.desktop_model_status.desktopResource,
        },
      };

    case ApiApplicationsActionTypes.UPDATE_SSH_MODEL:
      return {
        ...state,
        ssh_model: action.ssh_model,
        ssh_model_status: {
          ...state.ssh_model_status,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.RESET_SSH_MODEL:
      return {
        ...state,
        ssh_model: defaultSSHModel(),
        ssh_model_status: getDefaultInitialModelStatus(),
      };

    case ApiApplicationsActionTypes.SUBMITTING_SSH_MODEL:
      return {
        ...state,
        ssh_model: action.ssh_model,
        ssh_model_status: {
          saving: true,
          save_success: false,
          save_fail: false,
          complete: false,
          sshResource: undefined,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_SSH_MODEL_SUBMISSION:
      return {
        ...state,
        ssh_model: addIdToModel(state.ssh_model, action.SSHResource),
        ssh_model_status: {
          saving: false,
          save_success: true,
          save_fail: false,
          complete: true,
          sshResource: action.SSHResource,
        },
      };

    case ApiApplicationsActionTypes.FAILED_SSH_MODEL_SUBMISSION:
      return {
        ...state,
        ssh_model_status: {
          saving: false,
          save_success: false,
          save_fail: true,
          complete: false,
          sshResource: undefined,
        },
      };

    case ApiApplicationsActionTypes.RESET_SSH_MODEL_SUBMISSION_STATUS:
      return {
        ...state,
        ssh_model_status: {
          saving: false,
          save_success: false,
          save_fail: false,
          complete: state.ssh_model_status.complete,
          // Copy over the ssh because we need to propagate it between steps
          sshResource: state.ssh_model_status.sshResource,
        },
      };

    case ApiApplicationsActionTypes.UPDATE_NETWORK_MODEL:
      return {
        ...state,
        network_model: action.model,
        network_model_status: {
          ...state.network_model_status,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.RESET_NETWORK_MODEL:
      return {
        ...state,
        network_model: defaultNetworkModel(),
        network_model_status: getDefaultInitialModelStatus(),
      };

    case ApiApplicationsActionTypes.SUBMITTING_NETWORK_MODEL:
      return {
        ...state,
        network_model: action.model,
        network_model_status: {
          saving: true,
          save_success: false,
          save_fail: false,
          complete: false,
          network: undefined,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_NETWORK_MODEL_SUBMISSION:
      return {
        ...state,
        network_model: addIdToModel(state.network_model, action.object),
        network_model_status: {
          saving: false,
          save_success: true,
          save_fail: false,
          complete: true,
          network: action.object,
        },
      };

    case ApiApplicationsActionTypes.FAILED_NETWORK_MODEL_SUBMISSION:
      return {
        ...state,
        network_model_status: {
          saving: false,
          save_success: false,
          save_fail: true,
          complete: false,
          network: undefined,
        },
      };

    case ApiApplicationsActionTypes.RESET_NETWORK_MODEL_SUBMISSION_STATUS:
      return {
        ...state,
        network_model_status: {
          saving: false,
          save_success: false,
          save_fail: false,
          complete: state.network_model_status.complete,
          // Copy over the network because we need to propagate it between steps
          network: state.network_model_status.network,
        },
      };

    case ApiApplicationsActionTypes.UPDATE_LAUNCHER_MODEL:
      return {
        ...state,
        launcher_model: action.model,
        launcher_model_status: {
          ...state.launcher_model_status,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.RESET_LAUNCHER_MODEL:
      return {
        ...state,
        launcher_model: defaultLauncherModel(),
        launcher_model_status: getDefaultInitialModelStatus(),
      };

    case ApiApplicationsActionTypes.SUBMITTING_LAUNCHER_MODEL:
      return {
        ...state,
        launcher_model: action.model,
        launcher_model_status: {
          saving: true,
          save_success: false,
          save_fail: false,
          complete: false,
          launcher: undefined,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_LAUNCHER_MODEL_SUBMISSION:
      return {
        ...state,
        launcher_model: addIdToModel(state.launcher_model, action.object),
        launcher_model_status: {
          saving: false,
          save_success: true,
          save_fail: false,
          complete: true,
          launcher: action.object,
        },
      };

    case ApiApplicationsActionTypes.FAILED_LAUNCHER_MODEL_SUBMISSION:
      return {
        ...state,
        launcher_model_status: {
          saving: false,
          save_success: false,
          save_fail: true,
          complete: false,
          launcher: undefined,
        },
      };

    case ApiApplicationsActionTypes.RESET_LAUNCHER_MODEL_SUBMISSION_STATUS:
      return {
        ...state,
        launcher_model_status: {
          saving: false,
          save_success: false,
          save_fail: false,
          complete: state.launcher_model_status.complete,
          // Copy over the network because we need to propagate it between steps
          launcher: state.launcher_model_status.launcher,
        },
      };

    case ApiApplicationsActionTypes.CREATE_DEMO:
      return {
        ...state,
        demo_status: {
          saving: true,
          save_success: false,
          save_fail: false,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_DEMO_CREATION:
      return {
        ...state,
        demo_status: {
          saving: false,
          save_success: true,
          save_fail: false,
          complete: true,
        },
      };

    case ApiApplicationsActionTypes.FAILED_DEMO_CREATION:
      return {
        ...state,
        demo_status: {
          saving: false,
          save_success: false,
          save_fail: true,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.DELETE_DEMO:
      return {
        ...state,
        demo_status: {
          ...getDefaultInitialModelStatus(),
          deleting: true,
          delete_success: false,
          delete_failed: false,
          saving: false,
          save_success: false,
          save_fail: false,
          complete: false,
        },
      };

    case ApiApplicationsActionTypes.SUCCESSFUL_DEMO_DELETE:
      return {
        ...state,
        demo_status: {
          ...state.demo_status,
          deleting: false,
          delete_success: true,
          delete_failed: false,
        },
      };

    case ApiApplicationsActionTypes.FAILED_DEMO_DELETE:
      return {
        ...state,
        demo_status: {
          ...state.demo_status,
          deleting: false,
          delete_success: false,
          delete_failed: true,
        },
      };

    case ApiApplicationsActionTypes.RESET_DEMO_STATUS:
      return {
        ...state,
        demo_status: {
          saving: false,
          save_success: false,
          save_fail: false,
          complete: false,
          deleting: false,
          delete_success: false,
          delete_failed: false,
        },
      };

    case ApiApplicationsActionTypes.SAVING_RULE:
      return {
        ...state,
        saving_rule: true,
      };

    case ApiApplicationsActionTypes.DELETING_RULES:
      return {
        ...state,
        saving_rule: true,
      };

    case ApiApplicationsActionTypes.RULE_SAVE_FINISHED:
      return {
        ...state,
        saving_rule: false,
      };

    case ApiApplicationsActionTypes.CLEAR_APPLICATION_STATE:
      return {
        ...state,
        ...apiApplicationsInitialState,
        current_application: createBlankApplication(),
        applications_org_id: state.applications_org_id,
        // We need to trigger the components to refresh the data
        refresh_data: state.refresh_data + 1,
      };

    // The following action types fall to the default:
    // CREATING_ENV_FILE_CONFIG,
    // MODIFYING_ENV_FILE_CONFIG,
    // DELETE_ENV_FILE_CONFIG
    // SAVING_ENV_CONFIG
    // DELETING_ENV_CONFIG
    // SAVING_ENV_CONFIG_VAR_LIST
    // DELETING_ROLE
    // SAVING_ROLE_TO_RULE_ENTRY
    // FAILED_APP_ICON_FILE_DELETION
    // FAILED_ENV_CONFIG_VAR_UPDATE
    // MAINTAIN_STATE
    default:
      return state;
  }
}

export function getUpdatedApplications(updatedApp: Application | undefined, apps: Array<Application>): Array<Application> {
  if (!updatedApp) {
    return apps;
  }
  const appsCopy = apps.map((app) => cloneDeep(app));

  let match = false;
  for (const app of appsCopy) {
    if (app.id === updatedApp.id) {
      match = true;
      for (const key of Object.keys(updatedApp)) {
        app[key] = updatedApp[key];
      }
      break;
    }
  }
  if (!match) {
    appsCopy.unshift(updatedApp);
  }
  return appsCopy;
}

export function removeDeletedApplications(deletedAppIds: Array<string>, apps: Array<Application>): Array<Application> {
  const appsCopy = apps.map((app) => cloneDeep(app)).filter((app) => !deletedAppIds.includes(app.id));
  return appsCopy;
}

export function resetCurrentAppIfDeleted(deletedAppIds: Array<string>, currentApp: Application, apps: Array<Application>): Application {
  if (!deletedAppIds.includes(currentApp.id)) {
    return currentApp;
  }
  for (const app of apps) {
    if (!deletedAppIds.includes(app.id)) {
      return app;
    }
  }
  return undefined;
}

export function getDefaultCurrentEnvironment(currentApp: Application): Environment {
  if (currentApp !== undefined && currentApp.environments !== undefined && currentApp.environments.length !== 0) {
    return currentApp.environments[0];
  }
  return undefined;
}

export function getUpdatedCurrentEnvironment(application: Application | undefined, currentEnv: Environment): Environment {
  if (currentEnv === undefined || !application) {
    return undefined;
  }
  for (const env of application.environments) {
    if (env.name === currentEnv.name) {
      return env;
    }
  }
  return undefined;
}

export function updateCurrentAppFromAppListUpdate(state: ApiApplicationsState, updatedAppList: Array<Application>): Application {
  if (state.current_application === undefined || state.current_application.id === '') {
    return state.current_application;
  }
  return getAppFromId(state.current_application.id, updatedAppList);
}

export function updateCurrentEnvFromAppListUpdate(state: ApiApplicationsState, updatedAppList: Array<Application>): Environment {
  if (state.current_environment === undefined) {
    return state.current_environment;
  }
  const updatedApp = updateCurrentAppFromAppListUpdate(state, updatedAppList);
  return getEnvFromName(state.current_environment.name, updatedApp);
}

export function updateAppListFromApp(appList: Array<Application>, updatedApp: Application): Array<Application> {
  const appListCopy: Array<Application> = [...appList];
  const updatedAppCopy: Application = cloneDeep(updatedApp);
  for (let i = 0; i < appListCopy.length; i++) {
    const currentApp = appListCopy[i];
    if (currentApp.id === updatedAppCopy.id) {
      // Need to set these manually since the api does not
      // currently return these properties for getApplication.
      updatedAppCopy.assigned = currentApp.assigned;
      updatedAppCopy.maintained = currentApp.maintained;
      updatedAppCopy.owned = currentApp.owned;
      appListCopy.splice(i, 1, updatedAppCopy);
      break;
    }
  }
  return appListCopy;
}

function replace_env_vars(original: EnvironmentConfigVarList, updated: EnvironmentConfigVarList): EnvironmentConfigVarList {
  // We do this so we still have access to the original tracker variables for later patching
  const result = { ...original };
  result.configs = updated.configs;
  return result;
}

function setRuleToUndefinedIfDeleted(deletedRules: Array<RuleV2>, currentRule: RuleV2 | RuleConfig): RuleV2 | RuleConfig | undefined {
  const currentRuleAsRuleV2 = currentRule as RuleV2;
  if (!currentRuleAsRuleV2.metadata) {
    // Current rule is not a RuleV2 so it wasn't deleted:
    return currentRule;
  }
  for (const rule of deletedRules) {
    if (rule.metadata.id === currentRuleAsRuleV2.metadata.id) {
      return undefined;
    }
  }
  return currentRule;
}

function getContentSecuityPolicyFromEnvVars(envConfigVarList: EnvironmentConfigVarList): string {
  const defaultCsp = getDefaultCspValue();
  if (!envConfigVarList) {
    return defaultCsp;
  }
  const currentCsp = getCspFromEnvConfigVarsList(envConfigVarList);
  if (!currentCsp) {
    // Set as the default csp.
    return defaultCsp;
  }
  return currentCsp;
}

function getContentSecuityPolicyFromEnvironments(envsList: Array<Environment>): CSPSettings {
  const defaultCsp = getDefaultCspSettings();
  if (!envsList) {
    return defaultCsp;
  }
  const currentCsp = getCspFromEnvironmentsList(envsList);
  if (!currentCsp) {
    // Set as the default csp.
    return defaultCsp;
  }
  return currentCsp;
}

export function getIconFilesFromList(filesList: Array<FileSummary>): Array<FileSummary> {
  return filesList.filter((file) => file.label === 'app_icon');
}

export function getAppBundleFilesFromList(filesList: Array<FileSummary>): Array<FileSummary> {
  // Currently, we are assuming everything that is not labeled as 'app_icon' is
  // an app bundle.
  return filesList.filter((file) => file.label !== 'app_icon');
}

export function removePreviousIconFiles(apiAppState: ApiApplicationsState): Array<FileSummary> {
  return apiAppState.current_icon_files_list.filter((file) => file.public_url === apiAppState.current_application.icon_url);
}

export function updateRolesList(updatedRole: RoleV2, previousRoleId: string, rolesList: Array<RoleV2>): Array<RoleV2> {
  if (!updatedRole || !updatedRole.metadata || !updatedRole.metadata.id) {
    return rolesList;
  }
  const rolesListCopy: Array<RoleV2> = [...rolesList];
  for (let i = 0; i < rolesListCopy.length; i++) {
    if (rolesListCopy[i].metadata.id === previousRoleId) {
      rolesListCopy[i] = updatedRole;
      break;
    }
  }
  return rolesListCopy;
}

export function addIdToModel<T extends ResourceModel>(model: T, obj: any): T {
  const modelCopy = { ...model };
  modelCopy.id = getGuidFromObject(obj);
  return modelCopy;
}

export function getCurrentRule(currentRulesList: Array<RuleV2>, apiAppState: ApiApplicationsState): RuleV2 {
  if (!!apiAppState.rule_id) {
    // If the rule id has been set from the url, get that rule if it exists.
    const targetRule = currentRulesList.find((rule) => rule.metadata.id === apiAppState.rule_id);
    if (!!targetRule) {
      return targetRule;
    }
  }
  return getFirstElementForDefault(currentRulesList);
}

export function getCurrentRuleFromPolicy(
  currentRulesList: Array<RuleV2>,
  apiAppState: ApiApplicationsState,
  policyResourceList: Array<PolicyTemplateInstanceResource>,
  currentRule: RuleV2 | RuleConfig
): RuleV2 | RuleConfig | undefined {
  const targetCurrentRule = !!currentRule ? currentRule : getCurrentRule(currentRulesList, apiAppState);
  if (!targetCurrentRule) {
    return undefined;
  }
  const targetPolicyTemplateInstanceResource = getTargetPolicyTemplateInstanceResource(
    policyResourceList,
    apiAppState?.current_application?.id
  );
  const currentRuleAsRuleV2 = targetCurrentRule as RuleV2;
  const currentRuleAsRuleConfig = targetCurrentRule as RuleConfig;
  let targetRuleId = !!apiAppState?.rule_id ? apiAppState.rule_id : undefined;
  if (!targetRuleId) {
    if (!currentRuleAsRuleV2?.metadata?.id) {
      targetRuleId = currentRuleAsRuleConfig.name;
    } else {
      targetRuleId = currentRuleAsRuleV2.metadata.id;
    }
  }
  let targetRuleConfig: RuleConfig = undefined;
  const targetRulesList = targetPolicyTemplateInstanceResource?.spec?.template?.rules;
  if (!!targetRulesList) {
    targetRuleConfig = targetRulesList.find((rule) => rule.name === targetRuleId);
  }
  if (!!targetRuleConfig) {
    return targetRuleConfig;
  }
  return targetCurrentRule;
}
