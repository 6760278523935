import {
  Application,
  ApplicationConfig,
  Environment,
  OIDCContentType,
  OIDCProxyHeaderUserConfig,
  RuleConfig,
  SimpleResourcePolicyTemplate,
} from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { AppState } from '@app/core';
import { ActionApiApplicationsModifyCurrentApp } from '@app/core/api-applications/api-applications.actions';
import { selectApiCurrentApplication } from '@app/core/api-applications/api-applications.selectors';
import { getCommonMediaTypes } from '@app/core/models/application/application-model-api-utils';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { Subject, Observable, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  setDomainMappingConfigIfUnset,
  setAdditionalContextConfigIfUnset,
  setContentManipulationConfigIfUnset,
  setOidcHeadersConfigIfUnset,
  setOidcHeaderOverridesConfigIfUnset,
} from '../application-configs-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { getEmptyStringIfUnset, getRewriteCommonMediaTypesTooltip, modifyDataOnFormBlur } from '../utils';
import { MediaTypesComponent } from '../media-types/media-types.component';
import { OtherMappingsComponent } from '../other-mappings/other-mappings.component';
import { HeaderOverridesComponent } from '../header-overrides/header-overrides.component';
import { isExpansionPanelOpen } from '@app/core/models/ui/ui-model.utils';
import { selectUI } from '@app/core/ui/ui.selectors';
import { AppDefineExpansionPanel, UIState } from '@app/core/ui/ui.models';
import {
  addCommonPathPrefixRuleToApplication,
  addCommonPathPrefixRuleToPolicyTemplate,
  getCommonPathPrefixRuleFromApp,
  getCommonPathPrefixRuleFromPolicyTemplate,
  getCommonPathPrefixRuleIndexFromRuleConfigList,
  getRedirectSubpathTooltipText,
} from '@app/core/api-applications/api-applications-utils';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import {
  initPolicyTemplateInstances,
  savingPolicyTemplateInstance,
} from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  getPolicyDataBeforeApplicationInit$,
  getTargetPolicyTemplateInstanceResource,
  PolicyTemplateInstanceResource,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import {
  selectPolicyTemplateInstanceRefreshDataValue,
  selectPolicyTemplateInstanceResourcesList,
} from '@app/core/policy-template-instance-state/policy-template-instance.selectors';

@Component({
  selector: 'portal-http-rewrites',
  templateUrl: './http-rewrites.component.html',
  styleUrls: ['./http-rewrites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpRewritesComponent implements OnInit, OnDestroy {
  @Input() public fixedData = false;
  @Input() public currentOrgIssuer: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private currentApplicationCopy: Application;
  public rewriteRulesForm: UntypedFormGroup;
  public httpRewriteOptionsForm: UntypedFormGroup;
  public standardHeadersFormGroup: UntypedFormGroup;
  public includeUserContextHeadersTooltipText =
    'Whether or not to include user context headers along with the http request. These context headers include the authentication status of the user, the users email, roles, and user id from the Agilicus System';
  public httpRewritesDescriptiveText = `Configure how requests and responses appear to the client and server.`;
  public httpRewritesProductGuideLink = `https://www.agilicus.com/anyx-guide/proxy/#include-user-context-headers`;
  public rewriteMediaTypesDescriptiveText = `Internal applications sometimes have internal-referenced links that need to be re-written. 
  This is done by media (mime) type and commonly applies to "text/html". 
  If you find that your web links or search results refer to the internal host name, you may add their media type to this list to be re-written.`;
  public rewriteMediaTypesProductGuideLink = `https://www.agilicus.com/anyx-guide/proxy/#h-http-media-type-rewrite`;
  public rewriteRulesDescriptiveText = `Some web servers provide content that includes host names that only make sense within the internal network. 
  These names need to be mapped to an externally-accessible host in order for the client to access them. 
  The proxy can map between the internal and external hosts in order to ensure client accessibility. 
  By default, the proxy will map the host name used by the client to the primary internal name, and vice versa. 
  However, if the server uses names other than those, you can remap them by adding to the 'other mappings' list.`;
  public rewriteRulesProductGuideLink = `https://www.agilicus.com/anyx-guide/proxy/#h-http-host-names-rewrite`;
  public responseHeaderOverridesDescriptiveText = `Customize HTTP headers in responses.`;
  public responseHeaderOverridesProductGuideLink = `https://www.agilicus.com/anyx-guide/proxy/#h-http-response-header-overrides`;
  public requestHeaderOverridesDescriptiveText = `Customize HTTP headers in requests.`;
  public requestHeaderOverridesProductGuideLink = `https://www.agilicus.com/anyx-guide/proxy/#http-request-header-overrides`;
  public uiState: UIState;
  public isExpansionPanelOpen = isExpansionPanelOpen;
  public redirectSubpathTooltipText = getRedirectSubpathTooltipText();
  public useRecursiveReplacementSystemTooltipText = `The recursive replacement system will replace the same domain multiple times if one is a subdomain of the other. 
  If you require multiple matches for distinct values, check this option. 
  Otherwise, it should be unchecked.`;
  private policyTemplateInstanceResourceCopy: PolicyTemplateInstanceResource;
  public policyTemplate: SimpleResourcePolicyTemplate;

  // This is required in order to reference the enums in the html template.
  public appDefineExpansionPanel = AppDefineExpansionPanel;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  // Expansion panels
  @ViewChild(MatAccordion) public accordion: MatAccordion;

  @ViewChild(MediaTypesComponent) public mediaTypes: MediaTypesComponent;
  @ViewChild(OtherMappingsComponent) public otherMappings: OtherMappingsComponent;
  @ViewChild(HeaderOverridesComponent) public headerOverrides: HeaderOverridesComponent;

  public getRewriteCommonMediaTypesTooltip = getRewriteCommonMediaTypesTooltip;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private customValidatorsService: CustomValidatorsService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    const policyDataBeforeApplicationInit$ = getPolicyDataBeforeApplicationInit$(
      this.store,
      selectPolicyTemplateInstanceResourcesList,
      selectPolicyTemplateInstanceRefreshDataValue
    );
    const currentAppState$ = this.store.pipe(select(selectApiCurrentApplication));
    const uiState$ = this.store.pipe(select(selectUI));
    combineLatest([policyDataBeforeApplicationInit$, currentAppState$, uiState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([policyDataBeforeApplicationInitResp, currentAppStateResp, uiStateResp]) => {
        const policyTemplateInstanceResourceListStateResp = policyDataBeforeApplicationInitResp[0];
        this.uiState = uiStateResp;
        if (!currentAppStateResp || !currentAppStateResp.environments || currentAppStateResp.environments.length === 0) {
          return;
        }
        this.currentApplicationCopy = cloneDeep(currentAppStateResp);
        const targetPolicyTemplateInstanceResource = !!policyTemplateInstanceResourceListStateResp
          ? getTargetPolicyTemplateInstanceResource(policyTemplateInstanceResourceListStateResp, this.currentApplicationCopy?.id)
          : undefined;
        this.policyTemplateInstanceResourceCopy = cloneDeep(targetPolicyTemplateInstanceResource);
        this.policyTemplate = this.policyTemplateInstanceResourceCopy?.spec?.template;
        this.initializeFormGroups();
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public modifyApplication(updatedApplication: Application): void {
    this.currentApplicationCopy = updatedApplication;
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  public updateApplicationOnAppConfigsChange(appConfigs: ApplicationConfig): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const env of copyOfCurrentApplicationCopy.environments) {
      env.application_configs = appConfigs;
    }
    this.modifyApplication(copyOfCurrentApplicationCopy);
  }

  public onCheckboxChange(): void {
    this.modifyApplicationOnValueChanges();
  }

  public onFormBlur(form: UntypedFormGroup, formField: string): void {
    modifyDataOnFormBlur(form, formField, this.modifyApplicationOnValueChanges.bind(this));
  }

  private getCommonPathPrefixValueFromForm(): string {
    return this.httpRewriteOptionsForm.get('commonPathPrefix').value;
  }

  private getExistingCommonPathPrefixRule(): RuleConfig {
    if (!!this.usePolicyRulesVersion()) {
      return getCommonPathPrefixRuleFromPolicyTemplate(this.policyTemplate);
    } else {
      return getCommonPathPrefixRuleFromApp(this.currentApplicationCopy);
    }
  }

  private getExistingCommonPathPrefixRuleIndex(): number {
    if (!!this.usePolicyRulesVersion()) {
      return getCommonPathPrefixRuleIndexFromRuleConfigList(this.policyTemplate?.rules);
    } else {
      return getCommonPathPrefixRuleIndexFromRuleConfigList(this.currentApplicationCopy?.rules_config?.rules);
    }
  }

  private updateCommonPathPrefix(): void {
    const newCommonPathPrefixRulePathValue: string = this.getCommonPathPrefixValueFromForm();
    const existingCommonPathPrefixRule = this.getExistingCommonPathPrefixRule();
    const existingCommonPathPrefixRuleIndex = this.getExistingCommonPathPrefixRuleIndex();
    if (!!existingCommonPathPrefixRule) {
      // Rule already exists
      if (!!newCommonPathPrefixRulePathValue) {
        // Update the existing rule:
        existingCommonPathPrefixRule.actions[0].path = newCommonPathPrefixRulePathValue;
      } else {
        // Remove the existing rule:
        if (!!this.usePolicyRulesVersion()) {
          this.policyTemplate.rules.splice(existingCommonPathPrefixRuleIndex, 1);
        } else {
          this.currentApplicationCopy.rules_config.rules.splice(existingCommonPathPrefixRuleIndex, 1);
        }
      }
    } else {
      // Rule does not yet exist
      if (!!newCommonPathPrefixRulePathValue) {
        // Add new rule:
        if (!!this.usePolicyRulesVersion()) {
          addCommonPathPrefixRuleToPolicyTemplate(newCommonPathPrefixRulePathValue, this.policyTemplate);
        } else {
          addCommonPathPrefixRuleToApplication(newCommonPathPrefixRulePathValue, this.currentApplicationCopy);
        }
      }
    }
    if (!!this.usePolicyRulesVersion()) {
      // Save policy:
      this.savePolicy();
    } else {
      // Save app:
      this.modifyApplicationOnValueChanges();
    }
  }

  public updateCommonPathPrefixRuleOnFormBlur(form: UntypedFormGroup, formField: string): void {
    if (form.controls[formField].invalid) {
      return;
    }
    // Do not proceed if the value has not been changed.
    if (!form.controls[formField].dirty) {
      return;
    }
    this.updateCommonPathPrefix();
  }

  private savePolicy(): void {
    this.store.dispatch(
      savingPolicyTemplateInstance({
        obj: this.policyTemplateInstanceResourceCopy,
        trigger_update_side_effects: false,
        notifyUser: true,
      })
    );
  }

  private modifyApplicationOnValueChanges(): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    // Need to update the application_configs of every environment for this application.
    for (const env of copyOfCurrentApplicationCopy.environments) {
      this.setAllFieldsFromForms(env);
    }
    this.modifyApplication(copyOfCurrentApplicationCopy);
  }

  private initializeFormGroups(): void {
    this.initializeRewriteRulesFormGroup();
    this.initializeHttpRewriteOptionsFormGroup();
    this.initializeStandardHeadersFormGroup();
    this.changeDetector.detectChanges();
  }

  private initializeRewriteRulesFormGroup(): void {
    this.rewriteRulesForm = this.formBuilder.group({
      primary_internal_name: [
        getEmptyStringIfUnset(
          this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.domain_mapping?.primary_internal_name
        ),
        [Validators.required],
      ],
    });
  }

  private initializeHttpRewriteOptionsFormGroup(): void {
    let includeContextHeaders = false;
    if (this.currentApplicationCopy?.environments[0]?.application_configs?.additional_context?.include_user_context_headers) {
      includeContextHeaders = true;
    }
    let commonPathPrefixRule: RuleConfig = undefined;
    if (!this.usePolicyRulesVersion()) {
      commonPathPrefixRule = getCommonPathPrefixRuleFromApp(this.currentApplicationCopy);
    } else {
      commonPathPrefixRule = getCommonPathPrefixRuleFromPolicyTemplate(this.policyTemplate);
    }
    this.httpRewriteOptionsForm = this.formBuilder.group({
      includeUserContextHeaders: [includeContextHeaders],
      commonPathPrefix: [
        getEmptyStringIfUnset(commonPathPrefixRule?.actions[0]?.path),
        this.customValidatorsService.commonPathPrefixValidator(),
      ],
      use_recursive_replacement_system: [
        getEmptyStringIfUnset(
          this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.domain_mapping?.use_recursive_replacement_system
        ),
      ],
    });
  }

  private initializeStandardHeadersFormGroup(): void {
    this.standardHeadersFormGroup = this.formBuilder.group({
      host: this.currentApplicationCopy?.environments[0].application_configs?.oidc_config?.headers?.domain_substitution?.standard_headers
        ?.host,
      location:
        this.currentApplicationCopy?.environments[0].application_configs?.oidc_config?.headers?.domain_substitution?.standard_headers
          ?.location,
      origin:
        this.currentApplicationCopy?.environments[0].application_configs?.oidc_config?.headers?.domain_substitution?.standard_headers
          ?.origin,
      path: !!this.currentApplicationCopy?.environments[0].application_configs?.oidc_config?.headers?.domain_substitution?.path,
    });
  }

  private setRewriteRulesFromForm(environment: Environment): void {
    setDomainMappingConfigIfUnset(environment, this.currentOrgIssuer);
    environment.application_configs.oidc_config.domain_mapping.primary_internal_name =
      this.rewriteRulesForm.get('primary_internal_name').value;
  }

  private setAdditionalContextFromForm(environment: Environment): void {
    setAdditionalContextConfigIfUnset(environment);
    environment.application_configs.additional_context.include_user_context_headers =
      this.httpRewriteOptionsForm.get('includeUserContextHeaders').value;
  }

  private setStandardHeadersFromForm(environment: Environment): void {
    setOidcHeadersConfigIfUnset(environment, this.currentOrgIssuer);
    environment.application_configs.oidc_config.headers.domain_substitution.standard_headers.host =
      this.standardHeadersFormGroup.get('host').value;
    environment.application_configs.oidc_config.headers.domain_substitution.standard_headers.location =
      this.standardHeadersFormGroup.get('location').value;
    environment.application_configs.oidc_config.headers.domain_substitution.standard_headers.origin =
      this.standardHeadersFormGroup.get('origin').value;
    environment.application_configs.oidc_config.headers.domain_substitution.path = this.standardHeadersFormGroup.get('path').value;
  }

  private setRecursiveReplacementFromForm(environment: Environment): void {
    const useRecursiveReplacementSystemFormValue = this.httpRewriteOptionsForm.get('use_recursive_replacement_system').value;
    if (useRecursiveReplacementSystemFormValue === undefined) {
      return;
    }
    setDomainMappingConfigIfUnset(environment, this.currentOrgIssuer);
    environment.application_configs.oidc_config.domain_mapping.use_recursive_replacement_system = useRecursiveReplacementSystemFormValue;
  }

  private setAllFieldsFromForms(environment: Environment): void {
    this.setRewriteRulesFromForm(environment);
    this.setAdditionalContextFromForm(environment);
    this.setStandardHeadersFromForm(environment);
    this.setRecursiveReplacementFromForm(environment);
  }

  public onRewriteCommonMimeTypesClick(): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const environment of copyOfCurrentApplicationCopy.environments) {
      setContentManipulationConfigIfUnset(environment, this.currentOrgIssuer);
      setOidcHeadersConfigIfUnset(environment, this.currentOrgIssuer);
      environment.application_configs.oidc_config.content_manipulation.media_types = this.getUniqueMediaTypesList([
        ...environment.application_configs.oidc_config.content_manipulation.media_types,
        ...getCommonMediaTypes(),
      ]);
      environment.application_configs.oidc_config.headers.domain_substitution.standard_headers = {
        host: true,
        origin: true,
        location: true,
      };
    }
    this.modifyApplication(copyOfCurrentApplicationCopy);
  }

  private getUniqueMediaTypesList(mediaTypes: Array<OIDCContentType>): Array<OIDCContentType> {
    const mediaTypesStringSet: Set<string> = new Set(mediaTypes.map((type) => type.name));
    const uniqueTypesStringArray = Array.from(mediaTypesStringSet);
    return uniqueTypesStringArray.map((type) => {
      return { name: type };
    });
  }

  public updateHeaderOverrides(updatedOIDCProxyHeaderUserConfig: OIDCProxyHeaderUserConfig, type: 'response' | 'request'): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const environment of copyOfCurrentApplicationCopy.environments) {
      setOidcHeaderOverridesConfigIfUnset(environment, this.currentOrgIssuer);
      environment.application_configs.oidc_config.headers.header_overrides[type] = updatedOIDCProxyHeaderUserConfig;
    }
    this.modifyApplication(copyOfCurrentApplicationCopy);
  }

  public usePolicyRulesVersion(): boolean {
    return !!this.policyTemplate;
  }

  public canDeactivate(): Observable<boolean> | boolean {
    const mediaTypesValidate = this?.mediaTypes ? this.mediaTypes.canDeactivate() : true;
    const otherMappingsValidate = this?.otherMappings ? this.otherMappings.canDeactivate() : true;
    const headerOverridesValidate = this?.headerOverrides ? this.headerOverrides.canDeactivate() : true;
    return mediaTypesValidate && otherMappingsValidate && headerOverridesValidate;
  }
}
