<portal-table-layout 
  class="table-layout"
  [columnDefs]="columnDefs"
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElement"
  [rowObjectName]="rowObjectName"
  [paginatorConfig]="paginatorConfig"
  [linkDataSource] = "linkDataSource"
  pageDescriptiveText="Set environment variables to configure your application."
  productGuideLink="https://www.agilicus.com/anyx-guide/product-guide-applications/#h-instance-details"
  (updateEvent)="updateEvent($event)"
  (removeSelected)="deleteSelected()"
>
</portal-table-layout>
