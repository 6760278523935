import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { RuleV2, RuleScopeEnum, RoleToRuleEntry, RoleV2, RuleConfig, HttpRuleCondition, RuleAction } from '@agilicus/angular';
import { Subject, Observable, combineLatest } from 'rxjs';
import { Application } from '@agilicus/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { Rule } from '@agilicus/angular';
import {
  getEmptyStringIfUnset,
  setFormControlEnableState,
  reloadFormFieldValues,
  useValueIfNotInMap,
  isFormChipRemovable,
  scrollToTop,
} from '../utils';
import {
  ActionApiApplicationsSavingRule,
  ActionApiApplicationsSavingRoleToRuleEntry,
  ActionApiApplicationsDeletingRoleToRuleEntries,
} from '@app/core/api-applications/api-applications.actions';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { ENTER, COMMA, TAB } from '@angular/cdk/keycodes';
import { cloneDeep } from 'lodash-es';
import { FilterChipOptions } from '../filter-chip-options';
import { getPolicyRulePriorityErrorMessage, isFormValid, isValidPolicyRulePriority } from '../validation-utils';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { createChiplistInput } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { OptionalRoleToRuleEntry } from '../optional-types';
import { ApplicationBodyComponent } from '../application-body/application-body.component';
import { ApplicationQueryParametersComponent } from '../application-query-parameters/application-query-parameters.component';
import {
  selectPolicyTemplateInstanceRefreshDataValue,
  selectPolicyTemplateInstanceResourcesList,
} from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import {
  getPolicyDataBeforeApplicationInit$,
  getTargetPolicyTemplateInstanceResource,
  PolicyTemplateInstanceResource,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import {
  initPolicyTemplateInstances,
  savingPolicyTemplateInstance,
} from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { getHttpConditionFromRule } from '@app/core/api-applications/api-applications-utils';

@Component({
  selector: 'portal-application-rule-config',
  templateUrl: './application-rule-config.component.html',
  styleUrls: ['./application-rule-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationRuleConfigComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public orgId: string;
  public applications: Array<Application>;
  private currentApplication: Application;
  public currentRuleCopy: RuleV2 | RuleConfig;
  private currentRolesList: Array<RoleV2> = [];
  private policyTemplateInstanceResourceCopy: PolicyTemplateInstanceResource;
  public ruleForm: UntypedFormGroup;
  public filteredRuleOptions$: Observable<Array<string>>;
  private ruleFormFieldNames: Array<string> = [];
  public fixedData = false;
  public availableScopes: Array<string> = Object.keys(RuleScopeEnum);
  public availableMethods: Array<string> = Object.keys(Rule.MethodEnum);
  public currentRoleToRuleEntriesList: Array<RoleToRuleEntry>;
  public filteredMethodOptions$: Observable<Array<string>>;
  public filteredRolesOptions$: Observable<Array<string>>;
  public currentAssignedRoleToRuleEntries: Array<RoleToRuleEntry>;
  public allRoleToRuleEntries: Array<RoleToRuleEntry>;
  private roleIdToNameMap: Map<string, string> = new Map();
  private roleNameToIdMap: Map<string, string> = new Map();
  private methodsMap: Map<string, Rule.MethodEnum> = new Map();
  public methodsChiplistInput: ChiplistInput<object>;
  public rolesChiplistInput: ChiplistInput<object>;
  public actionsChiplistInput: ChiplistInput<object>;
  private localApplicationRefreshDataValue = 0;
  private localPolicyTemplateInstanceRefreshDataValue = 0;

  @ViewChild(ApplicationBodyComponent) public applicationBody: ApplicationBodyComponent;
  @ViewChild(ApplicationQueryParametersComponent) public applicationQueryParameters: ApplicationQueryParametersComponent;

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA, TAB],
  };

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public isFormChipRemovable = isFormChipRemovable;
  public getHttpConditionFromRule = getHttpConditionFromRule;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private routerHelperService: RouterHelperService,
    private customValidatorsService: CustomValidatorsService
  ) {}

  public ngOnInit(): void {
    // Scroll to top of page when component is loaded.
    this.scrollToTop();
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeFormGroup();
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const policyDataBeforeApplicationInit$ = getPolicyDataBeforeApplicationInit$(
      this.store,
      selectPolicyTemplateInstanceResourcesList,
      selectPolicyTemplateInstanceRefreshDataValue
    );
    const appState$ = this.store.pipe(select(selectApiApplications));
    combineLatest([orgId$, policyDataBeforeApplicationInit$, appState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, policyDataBeforeApplicationInitResp, appStateResp]) => {
        const policyTemplateInstanceResourceListStateResp = policyDataBeforeApplicationInitResp[0];
        const refreshPolicyTemplateInstanceDataStateResp = policyDataBeforeApplicationInitResp[1];
        this.orgId = orgIdResp;
        if (appStateResp === undefined) {
          return;
        }
        const refreshApplicationDataStateResp = appStateResp.refresh_data;
        this.applications = appStateResp.applications;
        this.currentApplication = appStateResp.current_application;
        if (!this.currentRuleCopy) {
          this.currentRuleCopy = cloneDeep(appStateResp.current_rule);
        }
        this.currentRoleToRuleEntriesList = appStateResp.current_role_to_rule_entries_list;
        this.currentRolesList = !!appStateResp?.current_roles_list ? appStateResp.current_roles_list : [];
        if (this.currentRuleCopy === undefined) {
          return;
        }
        const targetPolicyTemplateInstanceResource = !!policyTemplateInstanceResourceListStateResp
          ? getTargetPolicyTemplateInstanceResource(policyTemplateInstanceResourceListStateResp, this.currentApplication?.id)
          : undefined;
        this.policyTemplateInstanceResourceCopy = cloneDeep(targetPolicyTemplateInstanceResource);
        this.setMethodsMap();
        this.setRolesMaps(appStateResp.current_roles_list);
        this.allRoleToRuleEntries = this.getRoleAssignments(appStateResp.current_roles_list);
        this.fixedData = this.isDataReadonly();
        this.currentAssignedRoleToRuleEntries = this.getAssignedRoleToRuleEntries();
        if (
          this.localApplicationRefreshDataValue !== refreshApplicationDataStateResp ||
          this.localPolicyTemplateInstanceRefreshDataValue !== refreshPolicyTemplateInstanceDataStateResp ||
          !this.ruleForm
        ) {
          this.localApplicationRefreshDataValue = refreshApplicationDataStateResp;
          this.localPolicyTemplateInstanceRefreshDataValue = refreshPolicyTemplateInstanceDataStateResp;
          this.currentRuleCopy = cloneDeep(appStateResp.current_rule);
          // Wait until policy resource data is fetched before setting form values:
          this.setFormControlValues();
          reloadFormFieldValues(this.currentRuleCopy, this.ruleForm, this.ruleFormFieldNames, this.changeDetector);
          const formControlNamesArray = ['path_regex', 'scope', 'methods', 'assignedRoles', 'comments'];
          if (this.usePolicyRulesVersion()) {
            formControlNamesArray.push('priority', 'actions', 'negated');
          }
          setFormControlEnableState(formControlNamesArray, this.ruleForm, !this.isDataReadonly());
        }
        if (!this.methodsChiplistInput) {
          this.methodsChiplistInput = this.getMethodsChiplistInput();
        }
        if (!this.rolesChiplistInput) {
          this.rolesChiplistInput = this.getRolesChiplistInput();
        }
        if (!this.actionsChiplistInput && this.usePolicyRulesVersion()) {
          this.actionsChiplistInput = this.getActionsChiplistInput();
        }
        this.setRoleColumnAllowedValues(this.rolesChiplistInput);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public initializeFormGroup(): void {
    this.ruleForm = this.formBuilder.group({
      path_regex: ['', Validators.required],
      // Dropdown:
      scope: [],
      comments: [''],
      // This is the input used to enter chip values, not the chip values themselves:
      methods: '',
      // This is the input used to enter chip values, not the chip values themselves:
      assignedRoles: '',
    });
    this.changeDetector.detectChanges();
  }

  private setFormControlValues(): void {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    let pathRegexValue = undefined;
    let scopeValue = undefined;
    let commentsValue = undefined;
    let priorityValue = undefined;
    let negatedValue = undefined;

    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
      const httpRuleCondition: HttpRuleCondition = currentRuleAsRuleConfig?.extended_condition?.condition as HttpRuleCondition;
      pathRegexValue = httpRuleCondition?.path_regex;
      scopeValue = currentRuleAsRuleConfig?.scope;
      commentsValue = currentRuleAsRuleConfig?.comments;
      priorityValue = currentRuleAsRuleConfig.priority;
      negatedValue = !!currentRuleAsRuleConfig?.extended_condition?.negated;
    } else {
      // Is a RuleV2
      pathRegexValue = currentRuleAsRuleV2?.spec?.condition?.path_regex;
      scopeValue = currentRuleAsRuleV2?.spec?.scope;
      commentsValue = currentRuleAsRuleV2?.spec?.comments;
    }
    this.ruleForm.get('path_regex').setValue(getEmptyStringIfUnset(pathRegexValue));
    this.ruleForm.get('scope').setValue(scopeValue);
    this.ruleForm.get('comments').setValue(getEmptyStringIfUnset(commentsValue));
    if (!!currentRuleAsRuleV2 && this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      this.ruleForm.addControl('actions', new FormControl(''));
      this.ruleForm.addControl(
        'priority',
        new FormControl(priorityValue, [
          Validators.required,
          this.customValidatorsService.customValidator(this.isCustomValidPolicyRulePriority.bind(this)),
        ])
      );
      this.ruleForm.addControl('negated', new FormControl(negatedValue));
    }
    this.changeDetector.detectChanges();
  }

  private uniquePolicyRulePriorityIsNotApplicable(): boolean {
    if (!this.currentRuleCopy) {
      // Not applicable
      return true;
    }
    if (!this.usePolicyRulesVersion()) {
      // Is a RuleV2, so priority is not applicable
      return true;
    }
    return false;
  }

  private isCustomValidPolicyRulePriority(priorityValue: string): boolean {
    if (this.uniquePolicyRulePriorityIsNotApplicable()) {
      return true;
    }
    return isValidPolicyRulePriority(priorityValue, this.currentRuleCopy, this.policyTemplateInstanceResourceCopy);
  }

  public getPriorityFormFieldErrorMessage(): string {
    const priorityValue = this.ruleForm.get('priority').value;
    const errorMessage = getPolicyRulePriorityErrorMessage(priorityValue, this.currentRuleCopy, this.policyTemplateInstanceResourceCopy);
    return !!errorMessage ? errorMessage : `Priority is required`;
  }

  public modifyRuleOnFormBlur(formField: string): void {
    if (!isFormValid(formField, this.ruleForm)) {
      return;
    }
    // Do not proceed if the value has not been changed.
    if (!this.ruleForm.controls[formField].dirty) {
      return;
    }
    this.setRuleFromForm();
    this.modifyCurrentRule();
  }

  public modifyRuleOnFormSelectionChange(formField: string): void {
    if (!isFormValid(formField, this.ruleForm)) {
      return;
    }
    this.setRuleFromForm();
    this.modifyCurrentRule();
  }

  private updatePolicyTemplateWithCurrentRuleChanges(): void {
    const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
    let targetRule = this.policyTemplateInstanceResourceCopy.spec.template.rules.find((rule) => rule.name === currentRuleAsRuleConfig.name);
    for (const key of Object.keys(targetRule)) {
      targetRule[key] = this.currentRuleCopy[key];
    }
  }

  private updateAndSavePolicyTemplateRule(): void {
    this.updatePolicyTemplateWithCurrentRuleChanges();
    this.store.dispatch(
      savingPolicyTemplateInstance({
        obj: this.policyTemplateInstanceResourceCopy,
        trigger_update_side_effects: false,
        notifyUser: true,
      })
    );
  }

  private modifyCurrentRule(): void {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      this.updateAndSavePolicyTemplateRule();
    } else {
      // Is a RuleV2
      this.store.dispatch(new ActionApiApplicationsSavingRule(currentRuleAsRuleV2));
    }
  }

  private setRuleFromForm(): void {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    const pathFormValue = this.ruleForm.get('path_regex').value;
    const scopeFormValue = this.ruleForm.get('scope').value;
    const commentsFormValue = this.ruleForm.get('comments').value;
    const ruleCondition = getHttpConditionFromRule(this.currentRuleCopy);
    ruleCondition.path_regex = pathFormValue.trim();
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
      currentRuleAsRuleConfig.scope = scopeFormValue.trim();
      currentRuleAsRuleConfig.comments = commentsFormValue;
      const priorityFormValue = this.ruleForm.get('priority').value;
      currentRuleAsRuleConfig.priority = parseInt(priorityFormValue, 10);
      const negatedFormValue = this.ruleForm.get('negated').value;
      currentRuleAsRuleConfig.extended_condition.negated = negatedFormValue;
    } else {
      // Is a RuleV2
      currentRuleAsRuleV2.spec.scope = scopeFormValue.trim();
      currentRuleAsRuleV2.spec.comments = commentsFormValue;
    }
  }

  /**
   * Scrolls to the top of the page.
   */
  public scrollToTop(): void {
    scrollToTop();
  }

  public returnToApp(): void {
    this.routerHelperService.routeToAppView(this.currentApplication.id, {
      org_id: this.orgId,
    });
  }

  private isAppEditable(): boolean {
    if (!this.applications || !this.currentApplication) {
      return false;
    }
    if (this.applications.length === 0 || !this.currentApplication.owned) {
      return false;
    }
    return true;
  }

  public isDataReadonly(): boolean {
    return !this.isAppEditable();
  }

  private createNewRoleToRuleEntryFromRoleName(roleName: string): RoleToRuleEntry {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      return undefined;
    }
    return {
      spec: {
        role_id: useValueIfNotInMap(roleName, this.roleNameToIdMap),
        rule_id: currentRuleAsRuleV2.metadata.id,
        app_id: this.currentApplication.id,
        org_id: this.orgId,
      },
    };
  }

  public removeMethodChip(chipValue: Rule.MethodEnum): void {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
      const httpRuleCondition: HttpRuleCondition = currentRuleAsRuleConfig?.extended_condition?.condition as HttpRuleCondition;
      httpRuleCondition.methods = httpRuleCondition.methods.filter((method) => method !== chipValue);
      this.updateAndSavePolicyTemplateRule();
    } else {
      // Is a RuleV2
      currentRuleAsRuleV2.spec.condition.methods = currentRuleAsRuleV2.spec.condition.methods.filter((method) => method !== chipValue);
      this.modifyCurrentRule();
    }
  }

  public removeAssignedRoleChip(chipValue: RoleToRuleEntry | string): void {
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      const chipValueAsString = chipValue as string;
      const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
      currentRuleAsRuleConfig.roles = currentRuleAsRuleConfig.roles.filter((role) => role !== chipValueAsString);
      this.updateAndSavePolicyTemplateRule();
      return;
    }
    // Is a RuleV2
    const chipValueAsRoleToRuleEntry = chipValue as RoleToRuleEntry;
    this.store.dispatch(new ActionApiApplicationsDeletingRoleToRuleEntries([chipValueAsRoleToRuleEntry]));
  }

  public removeActionChip(chipValue: RuleAction.ActionEnum): void {
    const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
    currentRuleAsRuleConfig.actions = currentRuleAsRuleConfig.actions.filter((actionEnum) => actionEnum.action !== chipValue);
    this.updateAndSavePolicyTemplateRule();
  }

  private getAssignedRoleToRuleEntries(): Array<RoleToRuleEntry> {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      return [];
    }
    const assignedRoleToRuleEntries: Array<RoleToRuleEntry> = [];
    for (const roleToRuleEntry of this.currentRoleToRuleEntriesList) {
      if (roleToRuleEntry.spec.rule_id === currentRuleAsRuleV2.metadata.id) {
        assignedRoleToRuleEntries.push(roleToRuleEntry);
      }
    }
    return assignedRoleToRuleEntries;
  }

  private setMethodsMap(): void {
    this.methodsMap.clear();
    for (const key of Object.keys(Rule.MethodEnum)) {
      this.methodsMap.set(key, Rule.MethodEnum[key]);
    }
  }

  private setRolesMaps(currentRoleList: Array<RoleV2>): void {
    this.roleIdToNameMap.clear();
    this.roleNameToIdMap.clear();
    for (const role of currentRoleList) {
      this.roleIdToNameMap.set(role.metadata.id, role.spec.name);
      this.roleNameToIdMap.set(role.spec.name, role.metadata.id);
    }
  }

  public getRoleNameFromId(roleId: string): string {
    return useValueIfNotInMap(roleId, this.roleIdToNameMap);
  }

  private getRoleAssignments(currentRoles: Array<RoleV2>): Array<RoleToRuleEntry> {
    return currentRoles.map((role) => {
      return {
        spec: {
          role_id: role.metadata.id,
          rule_id: '',
          app_id: this.currentApplication.id,
          org_id: this.orgId,
        },
      };
    });
  }

  private getMethodsChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('methods');
    chiplistInput.allowedValues = this.availableMethods;
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.ruleForm.get('methods') as UntypedFormControl;
    return chiplistInput;
  }

  private getRolesChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('assignedRoles');
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.ruleForm.get('assignedRoles') as UntypedFormControl;

    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      chiplistInput.getChiplistValues = () => {
        const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
        return currentRuleAsRuleConfig.roles;
      };
    } else {
      // Is a RuleV2
      chiplistInput.getChiplistValues = () => {
        return this.currentAssignedRoleToRuleEntries;
      };
      chiplistInput.getDisplayValue = (roleToRuleEntry: OptionalRoleToRuleEntry) => {
        const roleName = this.getRoleNameFromId(roleToRuleEntry?.spec?.role_id);
        if (!roleName && !roleToRuleEntry?.spec) {
          return roleToRuleEntry as string;
        }
        return roleName;
      };
      chiplistInput.getElementFromValue = (optionValue: string) => {
        return this.createNewRoleToRuleEntryFromRoleName(optionValue);
      };
    }
    return chiplistInput;
  }

  private getActionsChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('actions');
    chiplistInput.allowedValues = [RuleAction.ActionEnum.allow, RuleAction.ActionEnum.deny, RuleAction.ActionEnum.none];
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.ruleForm.get('actions') as UntypedFormControl;
    chiplistInput.getDisplayValue = (ruleActionValue: RuleAction | string) => {
      if (!ruleActionValue) {
        return '';
      }
      const ruleActionValueAsRuleAction = ruleActionValue as RuleAction;
      if (!!ruleActionValueAsRuleAction.action) {
        return ruleActionValueAsRuleAction.action;
      }
      return ruleActionValue as string;
    };
    chiplistInput.getElementFromValue = (ruleActionString: string) => {
      return { action: ruleActionString };
    };
    return chiplistInput;
  }

  private setRoleColumnAllowedValues(column: ChiplistInput<object>): void {
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      column.allowedValues = this.currentRolesList.map((role) => role.spec.name);
    } else {
      // Is a RuleV2
      column.allowedValues = this.allRoleToRuleEntries.map((entry) => useValueIfNotInMap(entry.spec.role_id, this.roleIdToNameMap));
    }
  }

  public updateRuleEvent(): void {
    this.modifyCurrentRule();
  }

  public updateRolesEvent(): void {
    if (this.usePolicyRulesVersion()) {
      // Is a RuleConfig
      this.modifyCurrentRule();
      return;
    }
    // Is a RuleV2
    for (const assignedRoleToRuleEntry of this.currentAssignedRoleToRuleEntries) {
      if (!assignedRoleToRuleEntry.metadata) {
        this.store.dispatch(new ActionApiApplicationsSavingRoleToRuleEntry(assignedRoleToRuleEntry));
      }
    }
  }

  public usePolicyRulesVersion(): boolean {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    return !currentRuleAsRuleV2.metadata;
  }

  public canDeactivate(): Observable<boolean> | boolean {
    const applicationBodyValidate = this?.applicationBody ? this.applicationBody.canDeactivate() : true;
    const applicationQueryParametersValidate = this?.applicationQueryParameters ? this.applicationQueryParameters.canDeactivate() : true;
    return applicationBodyValidate && applicationQueryParametersValidate;
  }
}
