import { ResourceModel } from '../resource/resource-model';

export interface FileShareModel extends ResourceModel {
  share_name: string;
  name: string;
  local_path: string;
  connector_name: string;
  file_level_access_permissions: boolean;
  is_demo?: boolean;
}

export function defaultFileShareModel(): FileShareModel {
  return {
    id: undefined,
    share_name: '',
    name: '',
    local_path: '',
    connector_name: '',
    file_level_access_permissions: false,
    is_demo: false,
  };
}
