<portal-table-layout 
  [tableData]="tableData" 
  [columnDefs]="columnDefs" 
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName" 
  [dropdownSelector]="defaultRoleDropdownSelector"
  [hasBlankOption]="hasBlankOption"
  [pageDescriptiveText]="rolesDescriptiveText"
  [productGuideLink]="rolesProductGuideLink"
  pageInfoWidth="full"
  (updateEvent)="updateEvent($event)"
  (removeSelected)="deleteSelected($event)"
>
</portal-table-layout>
