import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DesktopResource, FilesService } from '@agilicus/angular';
import { AppState, NotificationService } from '..';
import { selectCanAdminApps } from '../user/permissions/app.selectors';
import * as DesktopResourceActions from './desktop.actions';
import {
  selectDesktopResources,
  selectDesktopResourceEntity,
  selectDesktopResourceList,
  selectDesktopResourceShouldPopulateValue,
} from './desktop.selectors';
import { DesktopResourceState } from './desktop.reducer';
import {
  createSavingResourceIconFileEffect,
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
  createUpdateResourceWithIconFileEffect,
} from '../helpers/effect-factories';
import { DesktopResourceStateService } from '../state-services/desktop-state.service';

@Injectable()
export class DesktopResourceEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private desktopStateService: DesktopResourceStateService,
    private filesService: FilesService,
    private notificationService: NotificationService
  ) {}

  public initState$ = createInitStateEffect<DesktopResourceState>(
    this.store,
    this.actions$,
    DesktopResourceActions.initDesktopResources,
    DesktopResourceActions.getDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    selectDesktopResources
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    DesktopResourceActions.getDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    selectDesktopResourceShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<DesktopResource, string>(
    this.store,
    this.actions$,
    DesktopResourceActions.getDesktopResources,
    DesktopResourceActions.clearDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    this.desktopStateService,
    selectCanAdminApps
  );

  public setCrudState$ = createSetCrudStateEffect<DesktopResource, string>(
    this.store,
    this.actions$,
    DesktopResourceActions.loadDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    this.desktopStateService,
    selectDesktopResourceList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<DesktopResource, string>(
    this.store,
    this.actions$,
    DesktopResourceActions.savingDesktopResource,
    DesktopResourceActions.maintainDesktopResources,
    this.desktopStateService,
    selectDesktopResourceEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<DesktopResource, string>(
    this.store,
    this.actions$,
    DesktopResourceActions.savingDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    this.desktopStateService,
    selectDesktopResources
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<DesktopResource, string>(
    this.actions$,
    DesktopResourceActions.deletingDesktopResource,
    DesktopResourceActions.refreshDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    this.desktopStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<DesktopResource, string>(
    this.actions$,
    DesktopResourceActions.deletingDesktopResources,
    DesktopResourceActions.refreshDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    this.desktopStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<DesktopResourceState>(
    this.store,
    this.actions$,
    DesktopResourceActions.getDesktopResources,
    DesktopResourceActions.maintainDesktopResources,
    selectDesktopResources
  );

  // Custom Effects:

  public savingIconFile$ = createSavingResourceIconFileEffect<DesktopResource>(
    this.store,
    this.actions$,
    DesktopResourceActions.savingIconDesktopResources,
    DesktopResourceActions.successfulIconSaveDesktopResources,
    DesktopResourceActions.failedIconSaveDesktopResources,
    this.filesService,
    this.notificationService
  );

  public updateResourceWithIconFile$ = createUpdateResourceWithIconFileEffect<DesktopResource>(
    this.actions$,
    DesktopResourceActions.successfulIconSaveDesktopResources,
    DesktopResourceActions.savingDesktopResource
  );
}
