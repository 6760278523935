export enum ResourceType {
  application = 'application',
  fileshare = 'fileshare',
  application_service = 'application_service',
  service_forwarder = 'service_forwarder',
  desktop = 'desktop',
  ssh = 'ssh',
  group = 'group',
  launcher = 'launcher',
  // The below options are deprecated. Here for backwards compatibility:
  application_access = 'application_access',
  file_share_access = 'file_share_access',
  ip = 'ip',
}
