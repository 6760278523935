<div class="table-controls">
  <portal-upload-button
    [isUploading]="isUploading"
    [buttonDescription]="buttonDescription"
    [tooltipText]="uploadButtonTooltipText"
    [preventUpload]="preventUpload"
    [disableUpload]="disableUpload"
    (readFile)="onReadUsers($event)"
    (uploadClick)="onUploadClickFunc()"
  >
  </portal-upload-button>

  <portal-download-button
    [buttonDescription]="buttonDescription"
    tooltipText="Click to download users"
    (downloadFile)="downloadUsers()"
  >
  </portal-download-button>
  
  <div class="flex-div"></div>
</div>

<portal-progress-bar 
  [progressBarController]="progressBarController"
>
</portal-progress-bar>
