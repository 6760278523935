import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterHelperService {
  constructor(private router: Router) {}

  /**
   * Redirects the router and replaces the previous url so that
   * the user can continue to navigate using the browser's
   * back and forth buttons.
   * @param url is the full url path including params separated by
   * a '/'.
   * @param orgId is the org_id to add as a queryParam at the end
   * of the url.
   */
  public redirect(url: string, queryParams: object): void {
    this.router.navigate([url], {
      queryParams,
      replaceUrl: true,
    });
  }

  /**
   * Will route to the application-define screen's currently selected application.
   * @param appId is the id of the currently selected application.
   * @param queryParams are any queryParams to add to the end of the url.
   */
  public routeToAppView(appId: string, queryParams: object): void {
    this.router.navigate(['application-define/' + appId], {
      queryParams,
    });
  }

  /**
   * Routes to the screen specified by the link
   */
  public routeToScreen(routerLink: string, orgId: string): void {
    this.router.navigate([routerLink], {
      queryParams: { org_id: orgId },
    });
  }
}
