import { Injectable } from '@angular/core';
import { findTargetMenuItem, getSideNavMenuWithTargetRouteOpen } from '@app/shared/components/submenu/side-nav-menu-utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { concatMap, map, of, withLatestFrom } from 'rxjs';
import { AppState } from '../core.state';
import { createRefreshOrgDependentEffect } from '../helpers/effect-factories';
import {
  ActionOrganisationsSetCurrentOrg,
  ActionOrganisationsSetState,
  OrganisationsActionTypes,
} from '../organisations/organisations.actions';
import { selectCurrentOrganisation } from '../organisations/organisations.selectors';
import { ActionUserLoggedIn, UserActionTypes } from '../user/user.actions';
import { selectUser } from '../user/user.selectors';
import {
  ActionUILoadSideNavMenuUIState,
  ActionUIMaintainState,
  ActionUIOpenSideNavMenuTargetRoute,
  ActionUIResetSideNavMenuUIState,
  ActionUISetSideNavMenuUIState,
  ActionUISetVersion,
  ActionUIUpdateSideNavMenuItem,
  ActionUIUpdateSideNavMenuItemList,
  UIActionTypes,
} from './ui.actions';
import { selectUIStateShouldPopulateValue, selectUIStateIsSideNavLoaded, selectNavMenuItemsListState } from './ui.selectors';

@Injectable()
export class UIStateEffects {
  constructor(private actions$: Actions, private store: Store<AppState>) {}

  public loadSideNavDataOnLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.LOGGED_IN, OrganisationsActionTypes.SET_ORG_STATE, OrganisationsActionTypes.SET_CURRENT_ORG),
      concatMap((action: ActionUserLoggedIn | ActionOrganisationsSetState | ActionOrganisationsSetCurrentOrg) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectUser)),
            this.store.pipe(select(selectCurrentOrganisation)),
            this.store.pipe(select(selectUIStateIsSideNavLoaded))
          )
        )
      ),
      concatMap(([action, user, currentOrg, isSideNavLoaded]) => {
        if (!user || !currentOrg) {
          // Not logged into org, so reset the side nav state
          return of(new ActionUIResetSideNavMenuUIState());
        }
        if (isSideNavLoaded) {
          return of(new ActionUIMaintainState());
        }
        return of(new ActionUILoadSideNavMenuUIState());
      })
    )
  );

  public loadSideNavData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActionTypes.LOAD_SIDE_NAV_MENU_UI_STATE),
      map((action: ActionUILoadSideNavMenuUIState) => {
        return new ActionUISetSideNavMenuUIState();
      })
    )
  );

  public loadSideNavDataOnVersionUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActionTypes.SET_VERSION),
      map((action: ActionUISetVersion) => {
        return new ActionUILoadSideNavMenuUIState();
      })
    )
  );

  /**
   * I'm doing this conversion here rather than in the reducer because doing so in the
   * reducer causes an error that breaks the entire portal and results in a blank screen.
   * I believe this has to do with calling a recursive function in the reducer.
   */
  public openTargetRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActionTypes.OPEN_SIDE_NAV_MENU_TARGET_ROUTE),
      concatMap((action: ActionUIOpenSideNavMenuTargetRoute) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectNavMenuItemsListState))))
      ),
      map(([action, navMenuItemsListState]) => {
        const updatedNavMenuList = getSideNavMenuWithTargetRouteOpen(navMenuItemsListState, action.targetRoute);
        if (!updatedNavMenuList) {
          return new ActionUIMaintainState();
        }
        return new ActionUIUpdateSideNavMenuItemList(updatedNavMenuList);
      })
    )
  );

  /**
   * I'm doing this conversion here rather than in the reducer because doing so in the
   * reducer causes an error that breaks the entire portal and results in a blank screen.
   * I believe this has to do with calling a recursive function in the reducer.
   */
  public updateTargetMenuItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UIActionTypes.UPDATE_SIDE_NAV_MENU_ITEM),
      concatMap((action: ActionUIUpdateSideNavMenuItem) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectNavMenuItemsListState))))
      ),
      map(([action, navMenuItemsListState]) => {
        const copyOfMenuItems = cloneDeep(navMenuItemsListState);
        const targetMenuItem = findTargetMenuItem(action.navMenuItem, copyOfMenuItems);
        if (!targetMenuItem) {
          return new ActionUIMaintainState();
        }
        targetMenuItem.showMenu = action.navMenuItem.showMenu;
        return new ActionUIUpdateSideNavMenuItemList(copyOfMenuItems);
      })
    )
  );

  public refreshOrg$ = createRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    ActionUILoadSideNavMenuUIState,
    selectUIStateShouldPopulateValue
  );
}
