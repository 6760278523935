import { AgentConnector, AgentConnectorLocalBind } from '@agilicus/angular';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { cloneDeep } from 'lodash-es';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';

export interface ConnectorRoutesDialogData {
  agentConnector: AgentConnector;
}

@Component({
  selector: 'portal-connector-routes-dialog',
  templateUrl: './connector-routes-dialog.component.html',
  styleUrls: ['./connector-routes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorRoutesDialogComponent implements OnInit {
  public routesFormGroup: FormGroup;
  public keyTabManager: KeyTabManager = new KeyTabManager();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConnectorRoutesDialogData,
    private dialogRef: MatDialogRef<ConnectorRoutesDialogComponent>,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService
  ) {}

  public ngOnInit(): void {
    this.initializeRoutesFormGroup();
  }

  private initializeRoutesFormGroup(): void {
    this.routesFormGroup = this.formBuilder.group({
      routes_list: this.formBuilder.array([]),
    });
    const routesList: Array<AgentConnectorLocalBind> = this.data?.agentConnector?.spec?.routing?.local_binds
      ? this.data.agentConnector.spec.routing.local_binds
      : [];
    for (const route of routesList) {
      this.addRoute(route);
    }
    if (routesList.length === 0) {
      // Add empty route
      this.addRoute();
    }
  }

  public getRoutesListFormArray(): FormArray {
    return this.routesFormGroup.get('routes_list') as FormArray;
  }

  public newRoute(route?: AgentConnectorLocalBind): UntypedFormGroup {
    const routeFormGroup = this.formBuilder.group({
      bind_port: [route?.bind_port ? route.bind_port : '', [Validators.required, this.customValidatorsService.portValidator()]],
      bind_host: [
        { value: route?.bind_host ? route.bind_host : '', disabled: route?.bind_host === null },
        [this.customValidatorsService.hostnameOrIp4OrIpValidator()],
      ],
      bindToEverything: [{ value: route?.bind_host === null, disabled: !!route?.bind_host }],
    });
    return routeFormGroup;
  }

  public addRoute(route?: AgentConnectorLocalBind) {
    this.getRoutesListFormArray().push(this.newRoute(route));
  }

  public removeRoute(index: number) {
    if (index >= 0) {
      this.getRoutesListFormArray().removeAt(index);
    }
  }

  public preventDeleteOnEnter(event: any): void {
    event.preventDefault();
  }

  public onConfirmClick(): void {
    const routesList = this.getRoutesList();
    const agentConnectorCopy = cloneDeep(this.data.agentConnector);
    if (!agentConnectorCopy.spec.routing) {
      agentConnectorCopy.spec.routing = {
        local_binds: routesList,
      };
    } else {
      agentConnectorCopy.spec.routing.local_binds = routesList;
    }
    this.dialogRef.close(agentConnectorCopy);
  }

  private getRoutesList(): Array<AgentConnectorLocalBind> {
    const routesList: Array<AgentConnectorLocalBind> = [];
    const routesListFormControl = this.getRoutesListFormArray().controls;
    for (const routesForm of routesListFormControl) {
      const bindPortFormValue = parseInt(routesForm.get('bind_port').value.toString(), 10);
      const bindToEverythingFormValue = routesForm.get('bindToEverything').value;
      const bindHostFormValue = routesForm.get('bind_host').value;
      routesList.push({
        bind_port: bindPortFormValue,
        bind_host: bindToEverythingFormValue || bindHostFormValue === '' ? null : bindHostFormValue,
      });
    }
    return routesList;
  }

  public onBindHostFormBlur(index: number): void {
    const bindHostFormControl = this.getRoutesListFormArray().at(index).get('bind_host');
    const bindToEverythingFormControl = this.getRoutesListFormArray().at(index).get('bindToEverything');
    !!bindHostFormControl.value ? bindToEverythingFormControl.disable() : bindToEverythingFormControl.enable();
  }

  public onCheckboxChange(isBoxChecked: boolean, index: number): void {
    const bindHostFormControl = this.getRoutesListFormArray().at(index).get('bind_host');
    isBoxChecked ? bindHostFormControl.disable() : bindHostFormControl.enable();
  }

  public getBindPortTooltipText(): string {
    return `The port to bind to.
    "0" binds to a random, free port chosen by the system.
    Be careful to choose a port that the connector has permission to bind.
    E.g. on some systems, low-numbered ports such as 443 require special permissions.`;
  }

  public getBindHostTooltipText(): string {
    return `The host or IP address to bind to.
    "0.0.0.0" will bind to all IPv4 addresses.
    "::" will bind to all IPv6 addresses.
    Setting it to a hostname will cause the connector to bind to the IP of that host.`;
  }

  public getBindToEverythingTooltipText(): string {
    return `This will bind to locally available unicast and anycast IP addresses`;
  }

  public getBindToEverythingDisabledTooltipText(): string {
    return `If disabled, please clear hostname/ip value. To provide own hostname/ip, this box must be unchecked.`;
  }
}
