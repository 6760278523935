import { FeatureFlag, Organisation, OrganisationsService, OrganisationStateSelector, patch_via_put } from '@agilicus/angular';
import { Observable } from 'rxjs';

export function markOrgAsDeleted(organisation: Organisation): void {
  organisation.admin_state = OrganisationStateSelector.disabled;
  const orgLifecycleDeleted: FeatureFlag = {
    feature: 'org_lifecycle',
    enabled: true,
    setting: 'deleted',
  };
  setFeature(organisation, orgLifecycleDeleted);
}

export function setFeature(organisation: Organisation, featureFlag: FeatureFlag): void {
  if (!organisation.feature_flags) {
    organisation.feature_flags = [];
  }
  for (const flag of organisation.feature_flags) {
    if (flag.feature === featureFlag.feature) {
      flag.enabled = featureFlag.enabled;
      flag.setting = featureFlag.setting;
      return;
    }
  }
  organisation.feature_flags.push(featureFlag);
}

export function updateOrg$(updatedOrg: Organisation, organisationsService: OrganisationsService): Observable<Organisation> {
  const putter = (org: Organisation) => {
    return organisationsService.replaceOrg({ org_id: org.id, Organisation: org });
  };
  const getter = (org: Organisation) => {
    return organisationsService.getOrg({
      org_id: org.id,
    });
  };
  return patch_via_put(updatedOrg, getter, putter);
}
