import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { FileSummary, SSHResource } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadSSHResources = createAction(
  '[SSHResource/API] Load SSHResources',
  props<{ objs: SSHResource[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addSSHResource = createAction('[SSHResource/API] Add SSHResource', props<{ obj: SSHResource }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertSSHResource = createAction('[SSHResource/API] Upsert SSHResource', props<{ obj: SSHResource; refreshData: boolean }>());

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addSSHResources = createAction('[SSHResource/API] Add SSHResources', props<{ objs: SSHResource[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertSSHResources = createAction(
  '[SSHResource/API] Upsert SSHResources',
  props<{ objs: SSHResource[]; refreshData: boolean }>()
);

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateSSHResource = createAction('[SSHResource/API] Update SSHResource', props<{ obj: Update<SSHResource> }>());

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateSSHResources = createAction('[SSHResource/API] Update SSHResources', props<{ objs: Update<SSHResource>[] }>());

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteSSHResource = createAction(
  '[SSHResource/API] Delete SSHResource',
  props<{ id: string; obj: SSHResource; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteSSHResources = createAction('[SSHResource/API] Delete SSHResources', props<{ ids: string[]; refreshData?: boolean }>());

/**
 * Will delete all data from the ngrx entity state
 */
export const clearSSHResources = createAction('[SSHResource/API] Clear SSHResources');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initSSHResources = createAction('[SSHResource/API] Init SSHResources', props<{ force: boolean; blankSlate: boolean }>());

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getSSHResources = createAction('[SSHResource/API] Get SSHResources', props<{ org_id: string; blankSlate: boolean }>());

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingSSHResource = createAction(
  '[SSHResource/API] Saving SSHResource',
  props<{ obj: SSHResource; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingSSHResources = createAction(
  '[SSHResource/API] Saving SSHResources',
  props<{ objs: Array<SSHResource>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingSSHResource = createAction(
  '[SSHResource/API] Deleting SSHResource',
  props<{ obj: SSHResource; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingSSHResources = createAction(
  '[SSHResource/API] Deleting SSHResources',
  props<{ objs: Array<SSHResource>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshSSHResources = createAction('[SSHResource/API] Refresh SSHResources');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainSSHResources = createAction('[SSHResource/API] Maintain SSHResources');

// Custom resource actions:

/**
 * Will add an icon file to the relevant data type
 */
export const savingIconSSHResources = createAction('[SSHResource/API] Saving Icon SSHResources', props<{ file: File; obj: SSHResource }>());

export const successfulIconSaveSSHResources = createAction(
  '[SSHResource/API] Successful Icon Save SSHResources',
  props<{ fileSummary: FileSummary; obj: SSHResource }>()
);

export const failedIconSaveSSHResources = createAction('[SSHResource/API] Failed Icon Save SSHResources', props<{ obj: SSHResource }>());

export const resetIconStatusSSHResources = createAction('[SSHResource/API] Reset Icon Status SSHResources');
