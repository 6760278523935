import { PolicyTemplateInstance, SimpleResourcePolicyTemplate } from '@agilicus/angular';
import { PolicyTemplateWithLabels } from '@app/shared/components/policy-template-with-labels';
import { createSelector } from '@ngrx/store';
import {
  PolicyTemplateInstanceResource,
  PolicyTemplateInstanceWithLabels,
} from '../api/policy-template-instance/policy-template-instance-utils';
import { PolicyTemplateType } from '../api/policy-template-instance/policy-template-type';
import { selectPolicyTemplateInstanceState } from '../core.state';
import { PolicyTemplateInstanceState, selectAll, selectEntities } from './policy-template-instance.reducer';

export const selectPolicyTemplateInstances = createSelector(
  selectPolicyTemplateInstanceState,
  (state: PolicyTemplateInstanceState) => state
);

export const selectPolicyTemplateInstanceList = createSelector(selectPolicyTemplateInstances, selectAll);

export const selectPolicyTemplateInstanceWithLabelsList = createSelector(
  selectPolicyTemplateInstanceList,
  (policyTemplateInstanceList: Array<PolicyTemplateInstance>) => {
    const policyTemplateInstanceWithLabelsList: Array<PolicyTemplateInstanceWithLabels> = [];
    for (const policy of policyTemplateInstanceList) {
      const policyTemplateWithLabels = policy.spec.template as PolicyTemplateWithLabels;
      if (policyTemplateWithLabels.labels !== undefined) {
        policyTemplateInstanceWithLabelsList.push(policy as PolicyTemplateInstanceWithLabels);
      }
    }
    return policyTemplateInstanceWithLabelsList;
  }
);

export const selectPolicyTemplateInstanceResourcesList = createSelector(
  selectPolicyTemplateInstanceList,
  (policyTemplateInstanceList: Array<PolicyTemplateInstance>) => {
    const policyTemplateInstanceResourcesList: Array<PolicyTemplateInstanceResource> = [];
    for (const policy of policyTemplateInstanceList) {
      const policyTemplateResource = policy.spec.template as SimpleResourcePolicyTemplate;
      if (policyTemplateResource.template_type === PolicyTemplateType.simple_resource) {
        policyTemplateInstanceResourcesList.push(policy as PolicyTemplateInstanceResource);
      }
    }
    return policyTemplateInstanceResourcesList;
  }
);

export const selectPolicyTemplateInstanceEntities = createSelector(selectPolicyTemplateInstances, selectEntities);

export const selectPolicyTemplateInstanceEntity = (props: { id: string }) =>
  createSelector(selectPolicyTemplateInstanceEntities, (entities) => {
    return entities[props.id];
  });

export const selectPolicyTemplateInstanceRefreshDataValue = createSelector(
  selectPolicyTemplateInstances,
  (state: PolicyTemplateInstanceState) => state.refresh_data
);

export const selectPolicyTemplateInstanceShouldPopulateValue = createSelector(
  selectPolicyTemplateInstances,
  (state: PolicyTemplateInstanceState) => state.should_populate
);
