<portal-page-info
  pageTitle="Onsite Connector Identity Providers"
  [productGuideLink]="productGuideLink"
  [pageDescriptiveTextWithImageWrap]="pageDescriptiveTextWithImageWrap"
  [pageDescriptiveHelpImageWithTextWrap]="pageDescriptiveHelpImageWithTextWrap"
  productGuidePreText= "For more details on installing the Connector and configuring this identity Provider, see the "
>
</portal-page-info>

<div class="authentication-group">

  <div *ngIf="hasPermissions !== undefined && !hasPermissions">
    <portal-no-permissions></portal-no-permissions>
  </div>

  <div *ngIf="hasPermissions">
    <div
      *ngIf="currentOrgIssuer === undefined && issuerCopy === undefined"
      class="no-info"
    >
      <h2>No issuer information available.</h2>
    </div>

    <div
      *ngIf="currentOrgIssuer !== undefined"
      class="issuer-url title"
    >
      <h5>Issuer: {{ currentOrgIssuer }}</h5>
    </div>

    <div *ngIf="issuerCopy !== undefined" class="table-outer-container">
      <div class="local-auth-providers">
        <portal-table-layout
          class="table-template"
          [tableData]="localAuthTableData"
          [columnDefs]="localAuthColumnDefs"
          [filterManager]="filterManager"
          [rowObjectName]="rowObjectName"
          [buttonsToShow]="buttonsToShow"
          [customButtons]="customButtons"
          (updateEvent)="updateLocalAuthEvent()"
          (removeSelected)="deleteSelectedLocalAuthUpstreams()"
          (updateSelection)="updateLocalAuthSelection($event)"
          >
        </portal-table-layout>
      </div>
    </div>
  </div>
</div>