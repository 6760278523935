import { Group, PolicyCondition } from '@agilicus/angular';
import { getFieldType } from '../utilities/model-helpers/issuers';
import { isJson } from './file-utils';
import { TableElement } from './table-layout/table-element';

export interface PolicyConditionElement extends TableElement, PolicyCondition {
  value_list: Array<string>;
  value_input_value: string;
}

function convert_list(value: string, field_type: string): string | Array<string> {
  if (typeof value !== 'string') {
    return value;
  }

  switch (field_type) {
    case 'string': {
      const string_list = value.split(',');
      const retval = new Array<string>();
      string_list.forEach((entry) => retval.push(entry.trim()));
      return retval;
    }
    default: {
      return [value];
    }
  }
}

export function convert_value(value: string, field_type: string): string | number | boolean {
  switch (field_type) {
    case 'boolean': {
      if (typeof value === 'boolean') {
        return value;
      }
      return value === 'true';
    }
    case 'number': {
      return Number(value);
    }
    default: {
      return value;
    }
  }
}

export function convert_value_type(
  value: string,
  field: string,
  type: PolicyCondition.ConditionTypeEnum,
  operator: PolicyCondition.OperatorEnum
): string {
  if (type === PolicyCondition.ConditionTypeEnum.last_successful_mfa) {
    return JSON.stringify(parseInt(value, 10));
  }

  if (operator === PolicyCondition.OperatorEnum.in || operator === PolicyCondition.OperatorEnum.not_in) {
    if (!field) {
      JSON.stringify(convert_list(value, 'string'));
    } else {
      return JSON.stringify(convert_list(value, getFieldType(field.split('.'))));
    }
  }

  if (!field) {
    return isJson(value) ? value : JSON.stringify(value);
  }

  const converted_value = convert_value(value, getFieldType(field.split('.')));
  if (typeof converted_value === 'string') {
    return isJson(converted_value) ? converted_value : JSON.stringify(converted_value);
  }
  return JSON.stringify(converted_value);
}

export function isAttributeAList(field: string): boolean {
  if (!field) {
    return false;
  }
  if (field.startsWith('user_mfa_preferences')) {
    return true;
  }
  return false;
}

export function formatGroupNamesValues(
  policyCondition: PolicyCondition,
  groupIdToGroupMap: Map<string, Group>,
  groupDisplayNameToGroupMap: Map<string, Group>,
  targetGroupNamesList: Array<string>
): void {
  if (policyCondition.field === 'user_member_of.id') {
    let groupIdsList = [];
    for (const groupName of targetGroupNamesList) {
      let targetGroup = groupDisplayNameToGroupMap.get(groupName);
      targetGroup = !!targetGroup ? targetGroup : groupIdToGroupMap.get(groupName);
      if (targetGroup?.id) {
        groupIdsList.push(targetGroup.id);
      }
    }
    policyCondition.value = `${groupIdsList}`;
  }
}

export function formatApplicationNamesValues(policyCondition: PolicyCondition, targetApplicationNamesList: Array<string>): void {
  if (policyCondition.field === 'clients.application') {
    policyCondition.value = `${targetApplicationNamesList}`;
  }
}

export function formatConditionValues(
  policyConditionElement: PolicyConditionElement,
  groupIdToGroupMap: Map<string, Group>,
  groupDisplayNameToGroupMap: Map<string, Group>,
  valuesList: Array<string>
): void {
  if (policyConditionElement.field === 'user_member_of.id') {
    let groupIdsList = [];
    for (const groupName of valuesList) {
      let targetGroup = groupDisplayNameToGroupMap.get(groupName);
      targetGroup = !!targetGroup ? targetGroup : groupIdToGroupMap.get(groupName);
      if (targetGroup?.id) {
        groupIdsList.push(targetGroup.id);
      }
    }
    policyConditionElement.value = groupIdsList.length !== 0 ? JSON.stringify(groupIdsList) : JSON.stringify([]);
    return;
  }
  if (policyConditionElement.field === 'clients.application') {
    policyConditionElement.value = valuesList.length !== 0 ? JSON.stringify(valuesList) : JSON.stringify([]);
    return;
  }
  policyConditionElement.value = convert_value_type(
    policyConditionElement.value,
    policyConditionElement.field,
    policyConditionElement.condition_type,
    policyConditionElement.operator
  );
}

export function setConditionTypeToDisplayValueMap(conditionTypeToDisplayValueMap: Map<PolicyCondition.ConditionTypeEnum, string>): void {
  conditionTypeToDisplayValueMap.set(PolicyCondition.ConditionTypeEnum.client_id_list, 'Client id');
  conditionTypeToDisplayValueMap.set(PolicyCondition.ConditionTypeEnum.ip_in_cidr_list, 'IP in CIDR');
  conditionTypeToDisplayValueMap.set(PolicyCondition.ConditionTypeEnum.object_attribute, 'Object attribute');
  conditionTypeToDisplayValueMap.set(PolicyCondition.ConditionTypeEnum.org_id_list, 'Org id');
  conditionTypeToDisplayValueMap.set(PolicyCondition.ConditionTypeEnum.upstream_idp_list, 'Upstream identity provider');
  conditionTypeToDisplayValueMap.set(PolicyCondition.ConditionTypeEnum.last_successful_mfa, 'Successful Multi-Factor Login');
  conditionTypeToDisplayValueMap.set(PolicyCondition.ConditionTypeEnum.user_id_list, 'User id');
  conditionTypeToDisplayValueMap.set(
    PolicyCondition.ConditionTypeEnum.user_has_enrolled_multifactor_method,
    'User has enrolled Multi-Factor method'
  );
}

export function policyConditionValueIsAList(fieldValue: string): boolean {
  if (fieldValue === 'user_member_of.id' || fieldValue === 'clients.application') {
    return true;
  }
  return false;
}
