import { getDateDifferenceInDays } from '@app/shared/components/date-utils';
import { AdminPortalData } from '../user/preferences/admin-portal-data';
import { SignupState } from './signup.models';
import { environment as environ } from '@env/environment';
import { Step, StepperProgressBarController } from '@agilicus/stepper-progress-bar';

export function firstSignupStepInProgress(signupState: SignupState): boolean {
  return (
    !!signupState?.is_signing_up &&
    !signupState?.initial_auth_complete &&
    !signupState?.create_complete &&
    !signupState?.signup_bounce &&
    !signupState?.final_auth &&
    !signupState?.signup_complete &&
    !signupState?.signup_org_id &&
    signupState?.selected_index === 0
  );
}

export function secondSignupStepInProgress(signupState: SignupState): boolean {
  return (
    !!signupState?.is_signing_up &&
    !!signupState?.initial_auth_complete &&
    !signupState?.create_complete &&
    !signupState?.signup_bounce &&
    !signupState?.final_auth &&
    !signupState?.signup_complete &&
    !!signupState?.signup_org_id &&
    signupState?.selected_index === 1
  );
}

export function thirdSignupStepInProgress(signupState: SignupState): boolean {
  return (
    !!signupState?.is_signing_up &&
    !!signupState?.initial_auth_complete &&
    !!signupState?.create_complete &&
    !signupState?.signup_bounce &&
    !signupState?.final_auth &&
    !signupState?.signup_complete &&
    !!signupState?.signup_org_id &&
    signupState?.selected_index === 2
  );
}

export function signupInProgress(signupState: SignupState): boolean {
  return firstSignupStepInProgress(signupState) || secondSignupStepInProgress(signupState) || thirdSignupStepInProgress(signupState);
}

export function getDaysSinceSignup(adminPortalData: AdminPortalData): number | undefined {
  if (!adminPortalData?.gettingStarted) {
    return undefined;
  }
  const signupDate = new Date(adminPortalData.gettingStarted.signup_date);
  const currentDate = new Date();
  return getDateDifferenceInDays(signupDate, currentDate);
}

export function signupLog(message: any) {
  try {
    const url = 'https://www.agilicus.com/log/';
    const data = {
      log_type: 'signup',
      agent: 'signup',
      signup: window.sessionStorage.signup,
      mautic_device_id: window.localStorage.mautic_device_id,
      mtc_id: window.localStorage.mtc_id,
      mtc_sid: window.localStorage.mtc_sid,
      user: window.localStorage.email,
      version: environ.versions.app,
      origin: window.origin,
      msg: message,
    };
    const config = {
      method: 'POST',
      mode: 'no-cors' as RequestMode,
      headers: {
        'Content-Type': 'application/json',
        'ngsw-bypass': '',
      },
      body: JSON.stringify(data),
    };
    fetch(url, config);
  } catch (er) {}
}

export function passProgressBarStep(progressStepper: StepperProgressBarController) {
  progressStepper.pass();
}

export function failProgressBarStep(progressStepper: StepperProgressBarController) {
  progressStepper.fail();
}

export function skipProgressBarStep(progressStepper: StepperProgressBarController) {
  progressStepper.skip();
}

export function resetProgressBarSteps(progressStepper: StepperProgressBarController, steps: Array<Step>) {
  for (let i = -1; i < steps.length + 1; i++) {
    progressStepper.previousStep();
  }
}

export function passAllProgressBarSteps(progressStepper: StepperProgressBarController) {
  passProgressBarStep(progressStepper);
  passProgressBarStep(progressStepper);
  skipProgressBarStep(progressStepper);
  skipProgressBarStep(progressStepper);
}

export function failFirstProgressBarStep(progressStepper: StepperProgressBarController) {
  failProgressBarStep(progressStepper);
  skipProgressBarStep(progressStepper);
  skipProgressBarStep(progressStepper);
  skipProgressBarStep(progressStepper);
}

export function failSecondProgressBarStep(progressStepper: StepperProgressBarController) {
  passProgressBarStep(progressStepper);
  failProgressBarStep(progressStepper);
  skipProgressBarStep(progressStepper);
  skipProgressBarStep(progressStepper);
}

export function getSubdomainHintLabel(): string {
  return 'Max length of 63 characters. Only letters (a-z), numbers (0-9), hyphens (-), and periods (.) are allowed.';
}

export function getCnameDestination(): string {
  return 'ca-1.agilicus.ca';
}

export function getSplitHorizonProductGuideDeepLink(): string {
  return 'https://www.agilicus.com/anyx-guide/signup/#note-split-horizon-dns';
}

export function getSplitHorizonMessagePrefix(): string {
  return 'Unable to resolve via split-horizon dns';
}

export function getSplitHorizonMessage(deepLink: string): string {
  return `ERROR: split horizon nameserver mismatch detected. You appear to have both a public and a private DNS. It appears the public DNS is setup with the wildcard CNAME correctly, but the nameserver your browser uses does not have the same information. For more information see <a href="${deepLink}" target="_blank"> split-horizon dns </a>`;
}

export function getProductGuideSignupDeepLink(): string {
  return 'https://www.agilicus.com/anyx-guide/signup/';
}

export function getSubdomainProductGuidePostText(cnameDestination: string): string {
  return `wildcard pointing from *.subdomain.YOURDOMAIN.com (wildcard) to ${cnameDestination}. You would normally
  do this in your DNS administrative interface, e.g. GoDaddy, Google Domains, etc.`;
}

export function getProgressBarEstimateText(): string {
  return 'Estimated Time: 60-90 seconds. Creating initial SSL certificate, logins, etc.';
}
