import { Observable } from 'rxjs';
import { TableElement } from '@app/shared/components/table-layout/table-element';
import { getDefaultNewRowProperties } from '@app/shared/components/table-layout-utils';
import { ChiplistColumn, Column } from '@app/shared/components/table-layout/column-definitions';

export function canNavigateFromTable(
  tableData: Array<TableElement>,
  columnDefs: Map<string, Column<TableElement>>,
  updateEvent: (element?: TableElement) => void,
  skipKeysList: Array<string> = []
): Observable<boolean> | boolean {
  let allowNavigation = true;
  for (const column of Array.from(columnDefs.values())) {
    const chiplistColumn = column as ChiplistColumn<TableElement>;
    if (chiplistColumn.isFormInputDirty) {
      // If any value has been typed in the chiplist input field,
      // but has not yet been saved as a chip, then prevent navigation.
      // Also, set it to error state:
      chiplistColumn.inError = true;
      return false;
    }
  }
  for (const row of tableData) {
    if (!row.dirty) {
      // Nothing has been changed for this row so we can skip it
      continue;
    }
    if (row.isNew && areAllFieldsBlank(row, skipKeysList)) {
      // Allow navigation if the row is new and the user has added but then removed
      // data from all fields (ie. they are all now blank)
      continue;
    }
    if (row.isValid) {
      // Row has been changed and is valid so we update it and navigate away
      updateEvent(row);
      return true;
    }
    // The row is invalid
    if (row.resetIsValid) {
      // If resetIsValid is true then we need to check every row in the table to ensure they are also reset
      row.isValid = true;
      row.resetIsValid = false;
      allowNavigation = false;
      continue;
    }
    return false;
  }
  return allowNavigation;
}

export function areAllFieldsBlank<T extends TableElement>(obj: T, skipKeysList: Array<string> = []): boolean {
  const updatedSkipKeysList = [...skipKeysList, ...Object.keys(getDefaultNewRowProperties())];
  for (const key of Object.keys(obj)) {
    if (updatedSkipKeysList.includes(key)) {
      // skip these properties
      continue;
    }
    if (key === 'id' || key === 'org_id' || key === 'orgId') {
      // skip the id and org id properties since they are always readonly
      continue;
    }
    if (typeof obj[key] === 'boolean' || typeof obj[key] === 'object') {
      // skip booleans and objects/arrays
      continue;
    }
    if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      return false;
    }
  }
  return true;
}
