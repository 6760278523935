import {
  PatchErrorImpl,
  PermissionsService,
  PolicyTemplateInstance,
  Resource,
  ResourcePermission,
  ResourcePermissionSpec,
  ResourceRole,
  ResourcesService,
  User,
  UsersService,
} from '@agilicus/angular';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppState, NotificationService } from '@app/core';
import {
  getDetailedTemplateDefinitionBasedOnType,
  getPolicyNameAndTypeString,
  ResourceTableElement,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { getResourceRoles } from '@app/core/api/resources/resources-api-utils';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';
import { PolicyResourceLinkService } from '@app/core/policy-resource-link-service/policy-resource-link.service';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { selectPolicyTemplateInstanceWithLabelsList } from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminOrReadResources } from '@app/core/user/permissions/resources.selectors';
import { selectCanAdminRules } from '@app/core/user/permissions/rules.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import {
  catchError,
  combineLatest,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  takeUntil,
} from 'rxjs';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { createChiplistInput } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { InputData } from '../custom-chiplist-input/input-data';
import { FilterChipOptions } from '../filter-chip-options';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ResourceType } from '../resource-type.enum';
import {
  convertResourceTypeToResourcePermissionType,
  getFormattedPluralizedResourceType,
  getFormattedResourceType,
} from '../resource-utils';
import { getDefaultNewRowProperties } from '../table-layout-utils';
import { capitalizeFirstLetter } from '../utils';

export interface ResourcePermissionStepperElement extends InputData {
  user: string;
  roleNames: Array<string>;
}

export interface ResourcePermissionSpecModified {
  user_display_name: string;
  org_id: string;
  resource_id: string;
  resource_type: ResourcePermissionSpec.ResourceTypeEnum;
  resource_role_name: string;
}

export interface GetUserResponse {
  user: User;
  failure: { nonExistantUser: boolean; email: string; duplicatePermission: boolean };
}

@Component({
  selector: 'portal-stepper-done-config-options',
  templateUrl: './stepper-done-config-options.component.html',
  styleUrls: ['./stepper-done-config-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperDoneConfigOptionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public resourceType: ResourceType;
  @Input() public resourceId: string;
  @Input() public resourceName: string;
  @Input() public orgId: string;
  @Input() public showResourcePermissions = true;
  @Input() public showApplicationPermissions = false;
  @Input() public showPolicies = true;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasResourcesPermissions: boolean;
  public hasRulesPermissions: boolean;
  public resourceConfigOptionsFormGroup: FormGroup;
  public radioButtonOptions: Array<{ name: string; value: boolean }> = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  public stepperState: { assignPermissions: boolean | undefined; addToResourceGroups: boolean | undefined; addToPolicies: boolean } = {
    assignPermissions: undefined,
    addToResourceGroups: undefined,
    addToPolicies: undefined,
  };
  private resourceRoles: Array<ResourceRole>;
  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };
  public requiredChiplist = true;
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public rolesChiplistInput: ChiplistInput<object>;
  public resourcePermissionElementsList: Array<ResourcePermissionStepperElement> = [];
  public filteredUserOptions$: Observable<Array<User>>;
  public isSubmittingPermissions = false;
  private errorMessage = '';
  public showResourceGroupPageInfo = false;
  public hideResourceGroupFilter = true;
  private searchedUsersDisplayNameToUserMap: Map<string, User> = new Map();
  private policyTemplateInstanceList: Array<PolicyTemplateInstance> = [];
  public policiesChiplistInput: ChiplistInput<object>;
  private policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstance> = new Map();
  public isSubmittingPolicies = false;
  private backingResourceObject: Resource;
  public resourceTableElement: ResourceTableElement;
  public showApplicationPermissionsPageInfo = false;

  public getFormattedPluralizedResourceType = getFormattedPluralizedResourceType;
  public capitalizeFirstLetter = capitalizeFirstLetter;
  public getFormattedResourceType = getFormattedResourceType;
  public resourceTypeEnum = ResourceType;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private permissionsService: PermissionsService,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private resourcesService: ResourcesService,
    private policyResourceLinkService: PolicyResourceLinkService<ResourceTableElement>,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  public ngOnInit(): void {
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    const hasResourcesPermissions$ = this.store.pipe(
      select(createCombinedPermissionsSelector(selectCanAdminUsers, selectCanAdminOrReadResources))
    );
    const hasRulesPermissions$ = this.store.pipe(select(selectCanAdminRules));
    const policyTemplateInstanceWithLabelsListState$ = this.store.pipe(select(selectPolicyTemplateInstanceWithLabelsList));
    const backingResourceObject$ = this.resourcesService.getResource({
      resource_id: this.resourceId,
      org_id: this.orgId,
    });
    combineLatest([hasResourcesPermissions$, hasRulesPermissions$, policyTemplateInstanceWithLabelsListState$, backingResourceObject$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasResourcesPermissionsResp,
          hasRulesPermissionsResp,
          policyTemplateInstanceWithLabelsListStateResp,
          backingResourceObjectResp,
        ]) => {
          this.hasResourcesPermissions = hasResourcesPermissionsResp.hasPermission;
          this.hasRulesPermissions = hasRulesPermissionsResp.hasPermission;
          this.policyTemplateInstanceList = !!policyTemplateInstanceWithLabelsListStateResp
            ? policyTemplateInstanceWithLabelsListStateResp
            : [];
          this.backingResourceObject = backingResourceObjectResp;
          if (!this.resourceTableElement) {
            this.resourceTableElement = {
              ...this.policyResourceLinkService.getResourceElementWithPolicies(
                this.backingResourceObject,
                undefined,
                undefined,
                policyTemplateInstanceWithLabelsListStateResp
              ),
              ...getDefaultNewRowProperties(),
            };
          }
          this.setPolicyMap();
          this.initializePermissionsFormGroup();
          this.policiesChiplistInput = this.getPoliciesChiplistInput();
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnChanges(): void {
    this.getData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private makeEmptyResourcePermissionElement(): ResourcePermissionStepperElement {
    return {
      user: undefined,
      roleNames: [],
      dirty: false,
    };
  }

  private getData(): void {
    let resourceRoles$: Observable<Array<ResourceRole> | undefined> = of(undefined);
    if (!!this.orgId) {
      resourceRoles$ = getResourceRoles(this.permissionsService, this.orgId, this.resourceType);
    }
    resourceRoles$.pipe(takeUntil(this.unsubscribe$)).subscribe((resourceRolesResp) => {
      this.resourceRoles = resourceRolesResp;
    });
  }

  private initializePermissionsFormGroup(): void {
    if (!!this.resourceConfigOptionsFormGroup) {
      return;
    }
    this.resourceConfigOptionsFormGroup = this.formBuilder.group({
      assign_permissions: [{ value: this.stepperState.assignPermissions, disabled: !this.hasResourcesPermissions }, [Validators.required]],
      permissions_list: this.formBuilder.array([]),
      add_to_resource_groups: [
        { value: this.stepperState.addToResourceGroups, disabled: !this.hasResourcesPermissions },
        [Validators.required],
      ],
      add_to_policies: [{ value: this.stepperState.addToPolicies, disabled: !this.hasRulesPermissions }, [Validators.required]],
      // This is the input used to enter chip values, not the chip values themselves:
      policy_input_value: '',
    });
    this.addPermission();
  }

  public getPermissionsListFormArray(): UntypedFormArray {
    return this.resourceConfigOptionsFormGroup.get('permissions_list') as UntypedFormArray;
  }

  public newPermissionFormGroup(): UntypedFormGroup {
    const permissionFormGroup = this.formBuilder.group({
      user: ['', [Validators.required]],
      roleNames: [[], [Validators.required]],
      // This is the input used to enter chip values, not the chip values themselves:
      role_input_value: '',
    });

    this.filteredUserOptions$ = permissionFormGroup.get('user').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      filter((input) => input !== null),
      debounceTime(350), // allow for some delay so we don't make api calls on every keyup, only the last value is returned after 350ms
      distinctUntilChanged(), // only make api calls if the latest value is different from the previous value
      concatMap((input: string) => {
        // get users whose email prefix matches the input
        return this.usersService
          .listUsers({
            org_id: this.orgId,
            prefix_email_search: input,
            limit: 15,
          })
          .pipe(
            takeUntil(this.unsubscribe$),
            map((usersResp) => {
              for (const user of usersResp.users) {
                this.addUserIdentityToMap(user);
              }
              return usersResp.users;
            })
          );
      })
    );
    return permissionFormGroup;
  }

  public addPermission() {
    this.getPermissionsListFormArray().push(this.newPermissionFormGroup());
    this.resourcePermissionElementsList.push(this.makeEmptyResourcePermissionElement());
  }

  public removePermission(index: number) {
    if (index >= 0) {
      this.getPermissionsListFormArray().removeAt(index);
      this.resourcePermissionElementsList.splice(index, 1);
    }
  }

  public onAssignPermissionsChange(isSelected: boolean): void {
    this.stepperState.assignPermissions = isSelected;
  }

  public onAddToResourceGroupChange(isSelected: boolean): void {
    this.stepperState.addToResourceGroups = isSelected;
  }

  public getAssignPermissionsFormValue(): boolean {
    return this.resourceConfigOptionsFormGroup.get('assign_permissions').value;
  }

  public getAddToResourceGroupsFormValue(): boolean {
    return this.resourceConfigOptionsFormGroup.get('add_to_resource_groups').value;
  }

  public preventDeleteOnEnter(event: any): void {
    event.preventDefault();
  }

  public removeRoleChip(chipValue: string, index: number): void {
    this.resourcePermissionElementsList[index].roleNames = this.resourcePermissionElementsList[index].roleNames.filter(
      (role) => role !== chipValue
    );
  }

  public getRolesChiplistInput(formControl: AbstractControl<any, any>): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('roleNames');
    chiplistInput.allowedValues = !!this.resourceRoles ? this.resourceRoles.map((resourceRole) => resourceRole.spec.role_name) : [];
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = formControl.get('role_input_value') as UntypedFormControl;
    return chiplistInput;
  }

  // TODO: check this is not needed and remove
  public onFormBlur(formField: string, obj: ResourcePermissionStepperElement, index: number): void {
    const selectedService = this.getPermissionsListFormArray().at(index).value;
    obj[formField] = selectedService[formField];
  }

  public submitPermissions(): void {
    this.isSubmittingPermissions = true;
    this.changeDetector.detectChanges();
    this.onSubmitPermissions();
  }

  private onSubmitPermissions(): void {
    const allPermissionsList: Array<ResourcePermissionSpecModified> = [];
    for (let i = 0; i < this.resourcePermissionElementsList.length; i++) {
      const element = this.resourcePermissionElementsList[i];
      const userDisplayName: string = this.getPermissionsListFormArray().controls[i].get('user').value;
      for (const role of element.roleNames) {
        allPermissionsList.push({
          user_display_name: userDisplayName,
          org_id: this.orgId,
          resource_id: this.resourceId,
          resource_type: convertResourceTypeToResourcePermissionType(this.resourceType),
          resource_role_name: role,
        });
      }
    }
    if (allPermissionsList.length === 0) {
      this.notificationService.error(`No roles have been selected for any users. Please select at least one role per user and try again.`);
      this.isSubmittingPermissions = false;
      this.changeDetector.detectChanges();
      return;
    }
    this.createAllResourcePermissions(allPermissionsList);
  }

  private createAllResourcePermissions(allPermissionsList: Array<ResourcePermissionSpecModified>): void {
    const permissionsObservablesArray$ = this.getPermissionObservablesList$(allPermissionsList);
    forkJoin(permissionsObservablesArray$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (respList: Array<ResourcePermission | GetUserResponse | undefined>) => {
          const failedList = [];
          for (const resp of respList) {
            if (resp === undefined) {
              failedList.push(resp);
            }
          }
          if (failedList.length !== 0) {
            if (failedList.length === respList.length) {
              // all permissions have failed.
              this.errorMessage = `Failed to assign these permissions. They may already exist.`;
            } else {
              // only some of the permissions have failed.
              this.errorMessage = `Failed to assign some permissions. They may already exist.`;
            }
          }
          const nonExistantUserIdentities = respList
            .filter((resp: GetUserResponse) => !!resp?.failure?.nonExistantUser)
            .map((resp: GetUserResponse) => resp.failure.email);
          if (nonExistantUserIdentities.length !== 0) {
            this.errorMessage = `Failed to assign some permissions. The following users do not exist: ${this.getNonExistantUsersListString(
              nonExistantUserIdentities
            )}`;
          }
        },
        (err) => {
          // All errors are being caught, so we will never enter here.
        },
        () => {
          this.isSubmittingPermissions = false;
          if (!!this.errorMessage) {
            this.notificationService.error(this.errorMessage);
          } else {
            this.notificationService.success(`Permissions were successfully assigned`);
          }
          this.errorMessage = '';
          this.changeDetector.detectChanges();
        }
      );
  }

  private getPermissionObservablesList$(
    allPermissionsList: Array<ResourcePermissionSpecModified>
  ): Array<Observable<ResourcePermission | GetUserResponse | undefined>> {
    const permissionsObservablesArray$: Array<Observable<ResourcePermission | GetUserResponse | undefined>> = [];
    for (const permission of allPermissionsList) {
      permissionsObservablesArray$.push(this.createResourcePermission$(permission));
    }
    return permissionsObservablesArray$;
  }

  private getNonExistantUsersListString(nonExistantUserIdentities: Array<string>): string {
    // Remove duplicates:
    const nonExistantUserIdentitiesSet = new Set(nonExistantUserIdentities);
    const userIdentities = Array.from(nonExistantUserIdentitiesSet).map((identity) => `"${identity}"`);
    return userIdentities.join(', ');
  }

  private createResourcePermission$(data: ResourcePermissionSpecModified): Observable<ResourcePermission | GetUserResponse | undefined> {
    const targetUser: User = this.searchedUsersDisplayNameToUserMap.get(data.user_display_name);
    const failureResponse: GetUserResponse = {
      user: undefined,
      failure: { nonExistantUser: true, email: data.user_display_name, duplicatePermission: false },
    };
    let user$ = of(failureResponse);
    if (!!targetUser) {
      const successResp: GetUserResponse = { user: targetUser, failure: undefined };
      user$ = of(successResp);
    }
    return user$.pipe(
      concatMap((userResp) => {
        if (!!userResp.failure?.nonExistantUser) {
          return of(userResp);
        }
        return this.permissionsService
          .createResourcePermission(
            {
              ResourcePermission: {
                spec: {
                  user_id: userResp.user.id,
                  org_id: data.org_id,
                  resource_id: data.resource_id,
                  resource_type: data.resource_type,
                  resource_role_name: data.resource_role_name,
                },
              },
            },
            'body',
            getIgnoreErrorsHeader()
          )
          .pipe(
            catchError((err: HttpErrorResponse | Error | PatchErrorImpl) => {
              if (err instanceof HttpErrorResponse && err.status === 400 && err.error.error_code === 'UNIQUE_CONFLICT') {
                const failureResponse: GetUserResponse = {
                  user: userResp.user,
                  failure: { nonExistantUser: false, email: data.user_display_name, duplicatePermission: true },
                };
                return of(failureResponse);
              }
              return of(undefined);
            })
          );
      })
    );
  }

  public addUserIdentityToMap(user: User): void {
    this.searchedUsersDisplayNameToUserMap.set(user.display_name, user);
  }

  public onAddToPoliciesChange(isSelected: boolean): void {
    this.stepperState.addToPolicies = isSelected;
  }

  public getAddToPoliciesFormValue(): boolean {
    return this.resourceConfigOptionsFormGroup.get('add_to_policies').value;
  }

  private setPolicyMap(): void {
    for (const policy of this.policyTemplateInstanceList) {
      this.policyNameAndTypeStringToPolicyMap.set(getPolicyNameAndTypeString(policy), policy);
    }
  }

  private getPoliciesChiplistInput(): ChiplistInput<object> {
    const chiplistInput = createChiplistInput('policies');
    chiplistInput.allowedValues = this.policyTemplateInstanceList;
    chiplistInput.hasAutocomplete = true;
    chiplistInput.formControl = this.resourceConfigOptionsFormGroup.get('policy_input_value') as UntypedFormControl;
    chiplistInput.getDisplayValue = (item: PolicyTemplateInstance | string) => {
      const itemAsPolicyTemplateInstance = item as PolicyTemplateInstance;
      if (!!itemAsPolicyTemplateInstance.metadata) {
        return getPolicyNameAndTypeString(itemAsPolicyTemplateInstance);
      }
      const itemAsString = item as string;
      return itemAsString;
    };
    chiplistInput.getElementFromValue = (item: string): any => {
      return this.policyNameAndTypeStringToPolicyMap.get(item);
    };
    chiplistInput.getTooltip = (value: any): string => {
      const valueAsPolicyTemplateInstance = value as PolicyTemplateInstance;
      return getDetailedTemplateDefinitionBasedOnType(valueAsPolicyTemplateInstance);
    };
    chiplistInput.getOptionTooltip = (option: PolicyTemplateInstance): string => {
      return getDetailedTemplateDefinitionBasedOnType(option);
    };
    return chiplistInput;
  }

  public removePolicyChip(chipValue: PolicyTemplateInstance): void {
    this.resourceTableElement.policies = this.resourceTableElement.policies.filter((policyTemplateInstance) => {
      return policyTemplateInstance.metadata.id !== chipValue.metadata.id;
    });
  }

  public submitPolicies(): void {
    this.isSubmittingPolicies = true;
    this.changeDetector.detectChanges();
    this.onSubmitPolicies();
  }

  private onSubmitPolicies(): void {
    this.policyResourceLinkService
      .submitPoliciesResult$(this.resourceTableElement, this.orgId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelsAndPolicyResponse) => {
        this.policyResourceLinkService.onSubmitPoliciesFinish(this.resourceTableElement, resourceAndLabelsAndPolicyResponse);
        this.isSubmittingPolicies = false;
      });
  }

  public disableApplyPoliciesButtonIfNothingToUpdate(): boolean {
    return this.resourceTableElement.policies.length === 0 && this.resourceTableElement.previousPolicies.length === 0;
  }
}
