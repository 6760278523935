import { createSelector } from '@ngrx/store';
import { selectAgentConnectorState, selectConnectorState } from '../core.state';
import { AgentConnectorState, selectAll, selectEntities } from './agent-connector.reducer';

export const selectAgentConnectors = createSelector(selectAgentConnectorState, (state: AgentConnectorState) => state);

export const selectAgentConnectorList = createSelector(selectAgentConnectors, selectAll);

export const selectAgentConnectorEntities = createSelector(selectAgentConnectors, selectEntities);

export const selectAgentConnectorEntity = (props: { id: string }) =>
  createSelector(selectAgentConnectorEntities, (entities) => {
    return entities[props.id];
  });

export const selectAgentConnectorRefreshDataValue = createSelector(
  selectAgentConnectors,
  (state: AgentConnectorState) => state.refresh_data
);

export const selectAgentConnectorShouldPopulateValue = createSelector(
  selectAgentConnectors,
  (state: AgentConnectorState) => state.should_populate
);
