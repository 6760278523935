<portal-table-layout 
  class="table-layout"
  [tableData]="tableData" 
  [columnDefs]="columnDefs" 
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [rowObjectName]="rowObjectName"
  [buttonsToShow]="buttonsToShow"
  [dragAndDropHeaderOnly]="dragAndDropHeaderOnly"
  [pageDescriptiveText]="bundlesDescriptiveText"
  [productGuideLink]="bundlesProductGuideLink"
  pageInfoWidth="full"
  (updateEvent)="updateEvent($event)"
  (uploadFile)="readFile($event)"
  (removeSelected)="deleteSelected($event)"
  (addFiles)="addFiles($event)"
>
</portal-table-layout>