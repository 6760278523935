import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { AppExpansionPanelStateDirective } from './app-expansion-panel-state.directive';

@NgModule({
  imports: [MatExpansionModule],
  declarations: [AppExpansionPanelStateDirective],
  exports: [AppExpansionPanelStateDirective],
})
export class AppExpansionPanelStateModule {}
