import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AgentConnector } from '@agilicus/angular';
import { AppState } from '..';
import { selectCanAdminApps } from '../user/permissions/app.selectors';
import * as AgentConnectorActions from './agent-connector.actions';
import {
  selectAgentConnectors,
  selectAgentConnectorEntity,
  selectAgentConnectorList,
  selectAgentConnectorShouldPopulateValue,
} from './agent-connector.selectors';
import { AgentConnectorState } from './agent-connector.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
} from '../helpers/effect-factories';
import { AgentConnectorStateService } from '../state-services/agent-connector-state.service';
import { deleteConnectors } from '../connector-state/connector.actions';

@Injectable()
export class AgentConnectorEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private agentConnectorStateService: AgentConnectorStateService) {}

  public initState$ = createInitStateEffect<AgentConnectorState>(
    this.store,
    this.actions$,
    AgentConnectorActions.initAgentConnectors,
    AgentConnectorActions.getAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    selectAgentConnectors
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    AgentConnectorActions.getAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    selectAgentConnectorShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<AgentConnector, string>(
    this.store,
    this.actions$,
    AgentConnectorActions.getAgentConnectors,
    AgentConnectorActions.clearAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    this.agentConnectorStateService,
    selectCanAdminApps
  );

  public setCrudState$ = createSetCrudStateEffect<AgentConnector, string>(
    this.store,
    this.actions$,
    AgentConnectorActions.loadAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    this.agentConnectorStateService,
    selectAgentConnectorList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<AgentConnector, string>(
    this.store,
    this.actions$,
    AgentConnectorActions.savingAgentConnector,
    AgentConnectorActions.maintainAgentConnectors,
    this.agentConnectorStateService,
    selectAgentConnectorEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<AgentConnector, string>(
    this.store,
    this.actions$,
    AgentConnectorActions.savingAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    this.agentConnectorStateService,
    selectAgentConnectors
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<AgentConnector, string>(
    this.actions$,
    AgentConnectorActions.deletingAgentConnector,
    AgentConnectorActions.refreshAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    this.agentConnectorStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<AgentConnector, string>(
    this.actions$,
    AgentConnectorActions.deletingAgentConnectors,
    AgentConnectorActions.refreshAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    this.agentConnectorStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<AgentConnectorState>(
    this.store,
    this.actions$,
    AgentConnectorActions.getAgentConnectors,
    AgentConnectorActions.maintainAgentConnectors,
    selectAgentConnectors
  );

  /**
   * Custom side effect to remove the base connectors from the entity state when
   * the connector has been deleted. The base connector will be deleted by
   * the backend when the connector is deleted so we need to remove it from
   * our ngrx entity state as well.
   */
  public deleteConnectorsFromStateOnAgentConnectorDeletesSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AgentConnectorActions.deleteAgentConnectors),
      concatMap((action) => {
        return of(deleteConnectors({ ids: action.ids, refreshData: true }));
      })
    )
  );
}
