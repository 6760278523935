import {
  AdminStatus,
  ApplicationUpstreamIdentityProvider,
  BaseUpstream,
  Issuer,
  IssuersService,
  KerberosUpstreamIdentityProvider,
  LocalAuthUpstreamIdentityProvider,
  ManagedUpstreamIdentityProvider,
  OIDCUpstreamIdentityProvider,
  OperationalStatus,
} from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppState } from '@app/core';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { initIssuer, initIssuerPolling, savingIssuer, stopIssuerPolling } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer, selectIssuerRefreshDataValue } from '@app/core/issuer-state/issuer.selectors';
import {
  getIssuerUpstreamIdentityProviderKeyFromType,
  getListOfUpstreamIdentityProvidersPoll$,
  getUpstreamIdentityProviderOperationalStatusIcon,
  getUpstreamIdentityProviderOperationalStatusIconColor,
  getUpstreamIdentityProviderTypeIcon,
  UpstreamIdentityProviderTypeEnum,
} from '@app/core/issuer-state/issuer.utils';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
import { ButtonType } from '../button-type.enum';
import { FilterManager } from '../filter/filter-manager';
import { IconColor } from '../icon-color.enum';
import { getDefaultTableProperties } from '../table-layout-utils';
import {
  Column,
  createIconColumn,
  createInputColumn,
  createSelectColumn,
  InputColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { capitalizeFirstLetter, createEnumChecker, replaceCharacterWithSpace, updateTableElements } from '../utils';

export interface UpstreamIdentityProviderStatusElement extends TableElement {
  name: string;
  issuer: string;
  upstream_type: UpstreamIdentityProviderTypeEnum;
  operational_status?: OperationalStatus;
  admin_status?: AdminStatus;
  backingLocalAuthUpstreamIdentityProvider?: LocalAuthUpstreamIdentityProvider;
  backingOIDCUpstreamIdentityProvider?: OIDCUpstreamIdentityProvider;
  backingApplicationUpstreamIdentityProvider?: ApplicationUpstreamIdentityProvider;
  backingKerberosUpstreamIdentityProvider?: KerberosUpstreamIdentityProvider;
  backingManagedUpstreamIdentityProvider?: ManagedUpstreamIdentityProvider;
}

@Component({
  selector: 'portal-authentication-overview',
  templateUrl: './authentication-overview.component.html',
  styleUrls: ['./authentication-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticationOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasPermissions: boolean;
  private orgId: string;
  public issuerCopy: Issuer;
  public columnDefs: Map<string, Column<UpstreamIdentityProviderStatusElement>> = new Map();
  public tableData: Array<UpstreamIdentityProviderStatusElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public buttonsToShow: Array<ButtonType> = [];
  private localRefreshDataValue = 0;
  // TODO: update these:
  public pageDescriptiveText = `See a list of issuers, their status, and modify their common properties`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/identity-and-authentication/`;
  private isPollingIssuerState = false;
  private upstreamNameToUpstreamMap: Map<string, BaseUpstream> = new Map();
  private isPollingUpstreamList = false;

  @ViewChild('tableLayoutComp') private tableLayoutComp: TableLayoutComponent<UpstreamIdentityProviderStatusElement>;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef, private issuersService: IssuersService) {}

  public ngOnInit(): void {
    // TODO: uncomment this when back-end returns status with upstreams list:
    // this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    this.startPollingIssuerState();
    this.initializeColumnDefs();
    const permissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const currentIssuer$ = this.store.pipe(select(selectCurrentIssuer));
    const refreshDataState$ = this.store.pipe(select(selectIssuerRefreshDataValue));
    combineLatest([permissions$, currentIssuer$, refreshDataState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([permissionsResp, currentIssuerResp, refreshDataStateResp]) => {
        this.hasPermissions = permissionsResp.hasPermission;
        this.orgId = permissionsResp.orgId;
        if (!this.hasPermissions) {
          // Need this in order for the "No Permissions" text to be displayed when the page first loads.
          this.changeDetector.detectChanges();
          return;
        }
        if (
          !currentIssuerResp ||
          (!currentIssuerResp.application_upstreams &&
            !currentIssuerResp.local_auth_upstreams &&
            !currentIssuerResp.oidc_upstreams &&
            !currentIssuerResp.kerberos_upstreams)
        ) {
          this.issuerCopy = undefined;
          this.resetEmptyTable();
          return;
        }
        if (!this.issuerCopy || this.localRefreshDataValue !== refreshDataStateResp) {
          this.localRefreshDataValue = refreshDataStateResp;
          this.issuerCopy = cloneDeep(currentIssuerResp);
          if (this.issuerCopy === undefined) {
            this.resetEmptyTable();
          } else {
            this.updateTable();
          }
        }
        // TODO: enable this when back-end returns status with upstreams list:
        /*
        if (!!this.issuerCopy && !!this.orgId) {
          this.getListOfUpstreamIdentityProviders();
        }
        */
        // If polling has stopped, we'll want to restart polling once the new data has been retrieved from the api/ngrx state
        if (!this.isPollingIssuerState) {
          this.startPollingIssuerState();
        }
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(stopIssuerPolling());
  }

  private getListOfUpstreamIdentityProviders(): void {
    if (!!this.isPollingUpstreamList) {
      return;
    }
    const fullUpstreamIdentityProvidersList$ = getListOfUpstreamIdentityProvidersPoll$(this.issuersService, this.issuerCopy.id, this.orgId);
    fullUpstreamIdentityProvidersList$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
      this.setUpstreamIdentityProviderDataAndUpdateTable(resp);
    });
    this.isPollingUpstreamList = true;
  }

  private setUpstreamIdentityProviderDataAndUpdateTable(upstreamListResp: Array<BaseUpstream>): void {
    this.setUpstreamIdentityProvidersMap(upstreamListResp);
    // Trigger the table to show the newly received status data:
    this.tableLayoutComp.triggerChangeDetectionFromParentComponent();
  }

  private setUpstreamIdentityProvidersMap(upstreamListResp: Array<BaseUpstream>): void {
    this.upstreamNameToUpstreamMap.clear();
    for (const upstream of upstreamListResp) {
      this.upstreamNameToUpstreamMap.set(upstream.name, upstream);
    }
  }

  private startPollingIssuerState(): void {
    if (this.isPollingIssuerState) {
      return;
    }
    this.store.dispatch(initIssuerPolling({ force: true, blankSlate: false }));
    this.isPollingIssuerState = true;
  }

  private stopPollingIssuerState(): void {
    if (!this.isPollingIssuerState) {
      return;
    }
    this.store.dispatch(stopIssuerPolling());
    this.isPollingIssuerState = false;
  }

  private getTypeColumn(): InputColumn<UpstreamIdentityProviderStatusElement> {
    const column = createIconColumn('upstream_type');
    column.displayName = 'Type';
    column.isEditable = false;
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    column.getDisplayValue = (element: UpstreamIdentityProviderStatusElement) => {
      return getUpstreamIdentityProviderTypeIcon(element.upstream_type);
    };
    column.getTooltip = (element: UpstreamIdentityProviderStatusElement) => {
      return capitalizeFirstLetter(replaceCharacterWithSpace(element.upstream_type, '_'));
    };
    return column;
  }

  private getNameColumn(): InputColumn<UpstreamIdentityProviderStatusElement> {
    const column = createInputColumn('name');
    column.isEditable = false;
    column.isReadOnly = () => true;
    return column;
  }

  private getIssuerColumn(): InputColumn<UpstreamIdentityProviderStatusElement> {
    const column = createInputColumn('issuer');
    column.isEditable = false;
    column.isReadOnly = () => true;
    return column;
  }

  private getOperationalStatusColumn(): Column<UpstreamIdentityProviderStatusElement> {
    const column = createInputColumn('operational_status');
    column.hasIconPrefix = true;
    // TODO: Change to this getDisplayValue when back-end returns status with upstreams list:
    /*
    column.getDisplayValue = (element: UpstreamIdentityProviderStatusElement) => {
      const targetUpstreamData = this.upstreamNameToUpstreamMap.get(element.name);
      return targetUpstreamData.operational_status?.status;
    };
    */
    column.getDisplayValue = (element: UpstreamIdentityProviderStatusElement) => {
      return element.operational_status?.status;
    };
    column.getIconPrefix = (element: UpstreamIdentityProviderStatusElement) => {
      return getUpstreamIdentityProviderOperationalStatusIcon(element.operational_status?.status);
    };
    column.getIconColor = (element: UpstreamIdentityProviderStatusElement) => {
      return getUpstreamIdentityProviderOperationalStatusIconColor(element.operational_status?.status) as IconColor;
    };
    column.isReadOnly = () => true;
    return column;
  }

  private getAdminStatusColumn(): Column<UpstreamIdentityProviderStatusElement> {
    const column = createSelectColumn('admin_status');
    column.isEditable = true;
    column.allowedValues = Object.values(AdminStatus);
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [this.getTypeColumn(), this.getNameColumn(), this.getIssuerColumn(), this.getOperationalStatusColumn(), this.getAdminStatusColumn()],
      this.columnDefs
    );
  }

  private updateTable(): void {
    this.buildTableData();
    this.replaceTableWithCopy();
  }

  private buildTableData(): void {
    const data: Array<UpstreamIdentityProviderStatusElement> = [];
    let indexTracker = 0;
    if (!!this.issuerCopy.local_auth_upstreams) {
      for (let i = 0; i < this.issuerCopy.local_auth_upstreams.length; i++) {
        data.push(
          this.createUpstreamIdentityProviderStatusElementFromLocalAuthUpstream(this.issuerCopy.local_auth_upstreams[i], indexTracker)
        );
        indexTracker++;
      }
    }
    if (!!this.issuerCopy.oidc_upstreams) {
      for (let i = 0; i < this.issuerCopy.oidc_upstreams.length; i++) {
        data.push(this.createUpstreamIdentityProviderStatusElementFromOIDCUpstream(this.issuerCopy.oidc_upstreams[i], indexTracker));
        indexTracker++;
      }
    }
    if (!!this.issuerCopy.application_upstreams) {
      for (let i = 0; i < this.issuerCopy.application_upstreams.length; i++) {
        data.push(
          this.createUpstreamIdentityProviderStatusElementFromApplicationUpstream(this.issuerCopy.application_upstreams[i], indexTracker)
        );
        indexTracker++;
      }
    }
    if (!!this.issuerCopy.kerberos_upstreams) {
      for (let i = 0; i < this.issuerCopy.kerberos_upstreams.length; i++) {
        data.push(
          this.createUpstreamIdentityProviderStatusElementFromKerberosUpstream(this.issuerCopy.kerberos_upstreams[i], indexTracker)
        );
        indexTracker++;
      }
    }
    updateTableElements(this.tableData, data);
  }

  private createUpstreamIdentityProviderStatusElementFromLocalAuthUpstream(
    upstream: LocalAuthUpstreamIdentityProvider,
    index: number
  ): UpstreamIdentityProviderStatusElement {
    const data: UpstreamIdentityProviderStatusElement = {
      name: upstream.name,
      issuer: upstream.issuer,
      upstream_type: upstream.upstream_type as UpstreamIdentityProviderTypeEnum,
      operational_status: upstream.operational_status,
      admin_status: upstream.admin_status,
      backingLocalAuthUpstreamIdentityProvider: upstream,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private createUpstreamIdentityProviderStatusElementFromOIDCUpstream(
    upstream: OIDCUpstreamIdentityProvider,
    index: number
  ): UpstreamIdentityProviderStatusElement {
    const data: UpstreamIdentityProviderStatusElement = {
      name: upstream.name,
      issuer: upstream.issuer,
      upstream_type: UpstreamIdentityProviderTypeEnum.oidc,
      operational_status: upstream.operational_status,
      admin_status: upstream.admin_status,
      backingOIDCUpstreamIdentityProvider: upstream,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private createUpstreamIdentityProviderStatusElementFromApplicationUpstream(
    upstream: ApplicationUpstreamIdentityProvider,
    index: number
  ): UpstreamIdentityProviderStatusElement {
    const data: UpstreamIdentityProviderStatusElement = {
      name: upstream.name,
      issuer: upstream.issuer,
      upstream_type: upstream.upstream_type as UpstreamIdentityProviderTypeEnum,
      operational_status: upstream.operational_status,
      admin_status: upstream.admin_status,
      backingApplicationUpstreamIdentityProvider: upstream,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private createUpstreamIdentityProviderStatusElementFromKerberosUpstream(
    upstream: KerberosUpstreamIdentityProvider,
    index: number
  ): UpstreamIdentityProviderStatusElement {
    const data: UpstreamIdentityProviderStatusElement = {
      name: upstream.name,
      issuer: upstream.issuer,
      upstream_type: upstream.upstream_type as UpstreamIdentityProviderTypeEnum,
      operational_status: upstream.operational_status,
      admin_status: upstream.admin_status,
      backingKerberosUpstreamIdentityProvider: upstream,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  public showNoPermissionsText(): boolean {
    return this.hasPermissions !== undefined && !this.hasPermissions;
  }

  private updateIssuerUpstreamIdentityProvider(
    issuer: Issuer,
    updatedUpstreamIdentityProvider: UpstreamIdentityProviderStatusElement
  ): void {
    const upstreamIdentityProviderKey = getIssuerUpstreamIdentityProviderKeyFromType(updatedUpstreamIdentityProvider.upstream_type);
    for (const provider of issuer[upstreamIdentityProviderKey]) {
      if (provider.name === updatedUpstreamIdentityProvider.name) {
        provider.admin_status = updatedUpstreamIdentityProvider.admin_status;
      }
    }
  }

  /**
   * Receives a UpstreamIdentityProviderStatusElement from the table
   * then updates and saves the issuer.
   */
  public updateEvent(updatedUpstreamIdentityProvider: UpstreamIdentityProviderStatusElement): void {
    const copyOfIssuerCopy = cloneDeep(this.issuerCopy);
    this.updateIssuerUpstreamIdentityProvider(copyOfIssuerCopy, updatedUpstreamIdentityProvider);
    this.saveIssuer(copyOfIssuerCopy);
  }

  private saveIssuer(issuer: Issuer): void {
    this.issuerCopy = issuer;
    this.store.dispatch(savingIssuer({ obj: this.issuerCopy, trigger_update_side_effects: false, notifyUser: true }));
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: {
    value: string;
    column: Column<UpstreamIdentityProviderStatusElement>;
    element: UpstreamIdentityProviderStatusElement;
  }): void {
    if (params.column.name !== 'admin_status') {
      return;
    }
    const isAdminStatusEnum = createEnumChecker(AdminStatus);
    if (isAdminStatusEnum(params.value)) {
      params.element.admin_status = params.value;
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public triggerRowDirtyEvent(column: Column<UpstreamIdentityProviderStatusElement>): void {
    // Stop polling when the table is being modified to avoid overwriting data
    this.stopPollingIssuerState();
  }

  public triggerRowCheckedEvent(): void {
    const checkedRow = this.tableData.find((element) => element.isChecked);
    if (!!checkedRow) {
      // Stop polling when rows of the table are selected (checked)
      this.stopPollingIssuerState();
    } else {
      // Start polling when no rows of the table are selected (checked)
      this.startPollingIssuerState();
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
