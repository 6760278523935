import { User } from '@agilicus/angular';

export function sanitiseUser(user: User): User {
  const result: User = { ...user };
  // remove empty strings that cannot be empty
  if (!result.external_id) {
    delete result.external_id;
  }

  if (!result.provider) {
    delete result.provider;
  }

  return result;
}
