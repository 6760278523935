<button 
  type="button" 
  mat-raised-button 
  color="primary" 
  class="return-to-app-button"
  matTooltip="Return to the main application page" 
  aria-label="Button to return to the main application page"
  (click)="returnToApp()" 
>
  <mat-icon>arrow_back</mat-icon> RETURN TO APPLICATION
</button>

<div class="title">
  <H1>Configure Firewall Rule</H1>
</div>

<div class="application-rules-form-container">
  <form 
    *ngIf="currentRuleCopy !== undefined" 
    class="rules-update-form" 
    [formGroup]="ruleForm"
    spellcheck="false"
    autocomplete="off"
  >
    <mat-form-field>
      <input 
        matInput 
        type="text" 
        placeholder="Path" 
        formControlName="path_regex" 
        required 
        (blur)="modifyRuleOnFormBlur('path_regex')"
        (keyup.enter)="keyTabManager.keyTab($event.target.id)"
      />
      <mat-error>Path is required</mat-error>
    </mat-form-field>

    <portal-custom-chiplist-input
      [chiplistInput]="methodsChiplistInput"
      [element]="getHttpConditionFromRule(currentRuleCopy)"
      placeholder="Methods"
      [filterChipOptions]="filterChipOptions"
      [isChipRemovable]="isFormChipRemovable(getHttpConditionFromRule(currentRuleCopy).methods, ruleForm.get('methods'), filterChipOptions.removable)"
      [keyTabManager]="keyTabManager"
      (updateEvent)="updateRuleEvent()"
      (removeChip)="removeMethodChip($event)"
    >
    </portal-custom-chiplist-input>

    <mat-form-field>
      <mat-label>Scope</mat-label>
      <mat-select 
        #selectScope
        formControlName="scope"
        (selectionChange)="modifyRuleOnFormSelectionChange('scope')"
        (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectScope)"
      >
        <mat-option 
          *ngFor="let scope of availableScopes"
          value="{{ scope }}"
        >
          {{ scope }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <portal-custom-chiplist-input
      [chiplistInput]="rolesChiplistInput"
      [element]="getHttpConditionFromRule(currentRuleCopy)"
      placeholder="Application Roles"
      [filterChipOptions]="filterChipOptions"
      [isChipRemovable]="filterChipOptions.removable"
      [keyTabManager]="keyTabManager"
      (updateEvent)="updateRolesEvent()"
      (removeChip)="removeAssignedRoleChip($event)"
    >
    </portal-custom-chiplist-input>

    <mat-form-field>
      <input 
        matInput 
        type="text" 
        placeholder="Comments" 
        formControlName="comments" 
        (blur)="modifyRuleOnFormBlur('comments')"
        (keyup.enter)="keyTabManager.keyTab($event.target.id)"
      />
    </mat-form-field>

    <!-- For Policy Rules Only -->
    <div *ngIf="!currentRuleCopy.metadata">
      <mat-form-field>
        <input
          matInput
          type="text"
          placeholder="Priority"
          formControlName="priority"
          required
          (blur)="modifyRuleOnFormBlur('priority')"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <mat-error>{{ getPriorityFormFieldErrorMessage() }}</mat-error>
      </mat-form-field>

      <portal-custom-chiplist-input
        [chiplistInput]="actionsChiplistInput"
        [element]="currentRuleCopy"
        placeholder="Actions"
        [filterChipOptions]="filterChipOptions"
        [isChipRemovable]="isFormChipRemovable(currentRuleCopy.actions, ruleForm.get('actions'), filterChipOptions.removable)"
        [keyTabManager]="keyTabManager"
        (updateEvent)="updateRuleEvent()"
        (removeChip)="removeActionChip($event)"
      >
      </portal-custom-chiplist-input>

      <div class="checkbox-container">
        <mat-checkbox
          formControlName="negated"
          (change)="modifyRuleOnFormBlur('negated')"
          aria-label="Checkbox that toggles whether the negated option is selected"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        >
          Rule evaluates to true if its conditions <b>do not</b> match
        </mat-checkbox>
      </div>
    </div>

  </form>
</div>

<portal-application-query-parameters
  [fixedTable]="fixedData"
>
</portal-application-query-parameters>

<portal-application-body
  [fixedTable]="fixedData"
>
</portal-application-body>
