<div class="table-controls">
  <portal-upload-button
    [isUploading]="isUploading"
    [buttonDescription]="rowObjectName"
    (readFile)="uploadFileEvent($event)"
    *ngIf="showButton(buttonType.UPLOAD)"
    class="upload-button"
  >
  </portal-upload-button>

  <portal-table-button
    buttonName="ADD {{ rowObjectName.toUpperCase() }}"
    tooltipText="Add new {{ rowObjectName.toLowerCase() }}"
    ariaLabelText="Button that adds a new row to the table"
    disabledButtonTooltipText="New {{ rowObjectName.toLowerCase() }} is missing required information"
    disabledButtonAriaLabelText="Button container that displays a tooltip when the table is missing required information"
    [disableButton]="doesNewRowExist || disableAddNewRow"
    [disableTooltip]="!doesNewRowExist"
    (buttonClick)="addNewRowToTableEvent(); addNewEntryFocusEvent()"
    *ngIf="showButton(buttonType.ADD)"
  >
  </portal-table-button>

  <portal-table-button
    buttonColor="warn"
    buttonName="DELETE {{ pluralizeRowObjectName().toUpperCase() }}"
    tooltipText="Delete selected {{ pluralizeRowObjectName().toLowerCase() }}"
    ariaLabelText="Button that deletes a row from the table"
    disabledButtonTooltipText="Please select {{ pluralizeRowObjectName().toLowerCase() }} to delete"
    disabledButtonAriaLabelText="Button container that displays a tooltip when the delete button is disabled"
    [disableButton]="!isAtLeastOneSelected"
    [disableTooltip]="isAtLeastOneSelected"
    (buttonClick)="deleteRowsEvent()"
    *ngIf="showButton(buttonType.DELETE)"
  >
  </portal-table-button>

  <portal-table-button
    buttonName="ENABLE {{ pluralizeRowObjectName().toUpperCase() }}"
    tooltipText="Enable selected {{ pluralizeRowObjectName().toLowerCase() }}"
    ariaLabelText="Button that enables selected rows"
    disabledButtonTooltipText="Please select {{ pluralizeRowObjectName().toLowerCase() }} to enable"
    disabledButtonAriaLabelText="Button container that displays a tooltip when the enable button is disabled"
    [disableButton]="!isAtLeastOneSelected"
    [disableTooltip]="isAtLeastOneSelected"
    (buttonClick)="enableRowsEvent()"
    *ngIf="showButton(buttonType.ENABLE)"
  >
  </portal-table-button>

  <portal-table-button
    buttonColor="warn"
    buttonName="DISABLE {{ pluralizeRowObjectName().toUpperCase() }}"
    tooltipText="Disable selected {{ pluralizeRowObjectName().toLowerCase() }}"
    ariaLabelText="Button that disables selected rows"
    disabledButtonTooltipText="Please select {{ pluralizeRowObjectName().toLowerCase() }} to disable"
    disabledButtonAriaLabelText="Button container that displays a tooltip when the disable button is disabled"
    [disableButton]="!isAtLeastOneSelected"
    [disableTooltip]="isAtLeastOneSelected"
    (buttonClick)="disableRowsEvent()"
    *ngIf="showButton(buttonType.DISABLE)"
  >
  </portal-table-button>

  <portal-table-button
    buttonColor="warn"
    buttonName="RESET {{ pluralizeRowObjectName().toUpperCase() }}"
    [tooltipText]="getResetButtonTooltipText()"
    ariaLabelText="Reset selected"
    disabledButtonTooltipText="Please select {{ pluralizeRowObjectName().toLowerCase() }} to reset"
    disabledButtonAriaLabelText="Button container that displays a tooltip when the reset button is disabled"
    [disableButton]="!isAtLeastOneSelected"
    [disableTooltip]="isAtLeastOneSelected"
    (buttonClick)="resetRowsEvent()"
    *ngIf="showButton(buttonType.RESET)"
  >
  </portal-table-button>

  <portal-table-button
    buttonName="APPROVE {{ pluralizeRowObjectName().toUpperCase() }}"
    tooltipText="Approve {{ pluralizeRowObjectName().toLowerCase() }}"
    ariaLabelText="Approves selected"
    disabledButtonTooltipText="Please select {{ pluralizeRowObjectName().toLowerCase() }} to approve"
    disabledButtonAriaLabelText="Button container that displays a tooltip when the approve button is disabled"
    [disableButton]="!isAtLeastOneSelected"
    [disableTooltip]="isAtLeastOneSelected"
    (buttonClick)="approveRowsEvent()"
    *ngIf="showButton(buttonType.APPROVE)"
  >
  </portal-table-button>

  <portal-table-button
    buttonColor="warn"
    buttonName="REJECT {{ pluralizeRowObjectName().toUpperCase() }}"
    tooltipText="Reject {{ pluralizeRowObjectName().toLowerCase() }}"
    ariaLabelText="Rejects selected"
    disabledButtonTooltipText="Please select {{ pluralizeRowObjectName().toLowerCase() }} to reject"
    disabledButtonAriaLabelText="Button container that displays a tooltip when the reject button is disabled"
    [disableButton]="!isAtLeastOneSelected"
    [disableTooltip]="isAtLeastOneSelected"
    (buttonClick)="rejectRowsEvent()"
    *ngIf="showButton(buttonType.REJECT)"
  >
  </portal-table-button>

</div>
