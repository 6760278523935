import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface OrgDeleteDialogData {
  orgName: string;
}

@Component({
  selector: 'portal-org-delete-confirmation-dialog',
  templateUrl: './org-delete-confirmation-dialog.component.html',
  styleUrls: ['./org-delete-confirmation-dialog.component.scss'],
})
export class OrgDeleteConfirmationDialogComponent implements OnInit {
  public orgName: string;
  public orgForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: OrgDeleteDialogData,
    private dialogRef: MatDialogRef<OrgDeleteConfirmationDialogComponent>,
    private formBuilder: UntypedFormBuilder
  ) {
    this.orgName = data.orgName;
  }

  public ngOnInit(): void {
    this.orgForm = this.formBuilder.group({
      orgName: ['', [Validators.required, this.checkOrgName.bind(this)]],
    });
  }

  public checkOrgName(control: AbstractControl): ValidationErrors | null {
    const invalid = { invalidOrgName: { value: control.value } };
    if (control.value !== this.orgName) {
      return invalid;
    }
    return null;
  }

  public onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
