import { Action } from '@ngrx/store';

export enum SignupActionTypes {
  INIT = '[Signup] Init',
  SIGNING_UP = '[Signup] SigningUp',
  SIGNUP_AUTH_COMPLETE = '[Signup] SignupAuthComplete',
  SIGNUP_CREATE_COMPLETE = '[Signup] SignupCreateComplete',
  SIGNUP_BOUNCE = '[Signup] SignupBounce',
  SIGNUP_RE_AUTH = '[Signup] SignupReAuth',
  SIGNUP_COMPLETE = '[Signup] SignupComplete',
  SIGNUP_SET_SELECTED_INDEX = '[Signup] SignupSetSelectedIndex',
  MAINTAIN_STATE = '[Signup] SignupMaintainState',
  CANCEL_SIGNUP = '[Signup] CancelSignup',
  SIGNUP_REDIRECTING = '[Signup] SignupRedirecting',
  SIGNUP_SET_BILLING_ACCOUNT_ID = '[Signup] SignupSetBillingAccountId',
  SIGNUP_SET_PRODUCT_LABEL_OVERRIDE = '[Signup] SignupSetProductLabelOverride',
  SIGNUP_NUKE_STATE = '[Signup] SignupNukeState',
  SIGNUP_BEGIN = '[Signup] SignupBegin',
}

export class ActionSignupInit implements Action {
  public readonly type = SignupActionTypes.INIT;
  constructor() {}
}

export class ActionSigningUp implements Action {
  public readonly type = SignupActionTypes.SIGNING_UP;
  constructor() {}
}

export class ActionSignupAuthComplete implements Action {
  public readonly type = SignupActionTypes.SIGNUP_AUTH_COMPLETE;
  constructor(readonly org_id: string) {
    this.org_id = org_id;
  }
}

export class ActionSignupCreateComplete implements Action {
  public readonly type = SignupActionTypes.SIGNUP_CREATE_COMPLETE;
  constructor(public redirectUri: string) {}
}

export class ActionSignupBounce implements Action {
  public readonly type = SignupActionTypes.SIGNUP_BOUNCE;
  constructor() {}
}

export class ActionSignupReAuth implements Action {
  public readonly type = SignupActionTypes.SIGNUP_RE_AUTH;
  constructor() {}
}

export class ActionSignupBegin implements Action {
  public readonly type = SignupActionTypes.SIGNUP_BEGIN;
  constructor() {}
}

export class ActionSignupComplete implements Action {
  public readonly type = SignupActionTypes.SIGNUP_COMPLETE;
  constructor() {}
}

export class ActionSignupSetSelectedIndex implements Action {
  public readonly type = SignupActionTypes.SIGNUP_SET_SELECTED_INDEX;
  constructor(public readonly selected_index: number) {
    this.selected_index = selected_index;
  }
}
export class ActionSignupSetBillingAccountId implements Action {
  public readonly type = SignupActionTypes.SIGNUP_SET_BILLING_ACCOUNT_ID;
  constructor(public readonly billing_account_id: string | undefined) {
    this.billing_account_id = billing_account_id;
  }
}

export class ActionSignupSetProductLabelOverride implements Action {
  public readonly type = SignupActionTypes.SIGNUP_SET_PRODUCT_LABEL_OVERRIDE;
  constructor(public readonly product_label_override: string | undefined) {
    this.product_label_override = product_label_override;
  }
}

/**
 * The state is not changed
 */
export class ActionSignupMaintainState implements Action {
  public readonly type = SignupActionTypes.MAINTAIN_STATE;
  constructor() {}
}

export class ActionSignupCancelSignup implements Action {
  public readonly type = SignupActionTypes.CANCEL_SIGNUP;
  constructor() {}
}

export class ActionSignupRedirecting implements Action {
  public readonly type = SignupActionTypes.SIGNUP_REDIRECTING;
  constructor() {}
}

export class ActionSignupNukeState implements Action {
  public readonly type = SignupActionTypes.SIGNUP_NUKE_STATE;
  constructor(public readonly reload: boolean = true) {}
}

export type SignupActions =
  | ActionSignupInit
  | ActionSigningUp
  | ActionSignupAuthComplete
  | ActionSignupCreateComplete
  | ActionSignupBounce
  | ActionSignupReAuth
  | ActionSignupComplete
  | ActionSignupSetSelectedIndex
  | ActionSignupSetBillingAccountId
  | ActionSignupSetProductLabelOverride
  | ActionSignupMaintainState
  | ActionSignupCancelSignup
  | ActionSignupRedirecting
  | ActionSignupNukeState
  | ActionSignupBegin;
