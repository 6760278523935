import { Connector, ConnectorsService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import * as ConnectorActions from '../connector-state/connector.actions';
import { deleteExistingConnector, getAllConnectorsWithStatsList, getConnectorById } from '../api/connectors/connectors-api-utils';

@Injectable({
  providedIn: 'root',
})
export class ConnectorStateService extends SimpleCRUDInterface<Connector, string> {
  public crudRegistryName = CrudStateCollection.connector;

  constructor(
    public store: Store<AppState>,
    private connectorsService: ConnectorsService,
    private crudRegistryService: CrudRegistryService<Connector, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<Connector, string> = {
        // the base "Connector" type cannot be created or updated
        create: null,
        update: null,
        get: (id: string, orgId: string) => getConnectorById(this.connectorsService, id, orgId),
        list: (orgId: string) => getAllConnectorsWithStatsList(this.connectorsService, orgId),
        delete: (id: string, orgId: string) => deleteExistingConnector(this.connectorsService, id, orgId),
      };
      const crudRegistry: CrudRegistry<Connector, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: Connector) => obj.spec.name,
        doneSaveAction: ConnectorActions.upsertConnector,
        doneSaveListAction: ConnectorActions.upsertConnectors,
        doneDeleteAction: ConnectorActions.deleteConnector,
        doneDeleteListAction: ConnectorActions.deleteConnectors,
        setListAction: ConnectorActions.loadConnectors,
        refreshStateAction: ConnectorActions.refreshConnectors,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
