<div class="recommendation-container">
  <p>
    {{ recommendationCustomText }} {{ recommendationCommonText }}
  </p>
  <div *ngIf="!!imageSourcePath" class="instruction-help-img clickable">
    <img
      class="bordered-img"
      [src]="imageSourcePath"
      (click)="onImageClick()"
    >
  </div>
</div>
