import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { PolicyTemplateInstance, RuleConfig, RuleV2 } from '@agilicus/angular';
import { AppState } from '..';
import { selectCanAdminApps, selectCanReadApps } from '../user/permissions/app.selectors';
import * as PolicyTemplateInstanceActions from './policy-template-instance.actions';
import {
  selectPolicyTemplateInstances,
  selectPolicyTemplateInstanceEntity,
  selectPolicyTemplateInstanceList,
  selectPolicyTemplateInstanceShouldPopulateValue,
  selectPolicyTemplateInstanceResourcesList,
} from './policy-template-instance.selectors';
import { PolicyTemplateInstanceState } from './policy-template-instance.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
} from '../helpers/effect-factories';
import { PolicyTemplateInstanceStateService } from '../state-services/policy-template-instance-state.service';
import {
  selectApiApplicationsCurrentRule,
  selectApiApplicationsRuleId,
  selectApiCurrentApplication,
} from '../api-applications/api-applications.selectors';
import { concatMap, of, withLatestFrom } from 'rxjs';
import { ActionApiApplicationsSetCurrentRuleFromPolicy } from '../api-applications/api-applications.actions';
import { getNewCrudStateObjGuid } from '../api/state-driven-crud/state-driven-crud';

@Injectable()
export class PolicyTemplateInstanceEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private policyTemplateInstanceStateService: PolicyTemplateInstanceStateService
  ) {}

  public initState$ = createInitStateEffect<PolicyTemplateInstanceState>(
    this.store,
    this.actions$,
    PolicyTemplateInstanceActions.initPolicyTemplateInstances,
    PolicyTemplateInstanceActions.getPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    selectPolicyTemplateInstances
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    PolicyTemplateInstanceActions.getPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    selectPolicyTemplateInstanceShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<PolicyTemplateInstance, string>(
    this.store,
    this.actions$,
    PolicyTemplateInstanceActions.getPolicyTemplateInstances,
    PolicyTemplateInstanceActions.clearPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    this.policyTemplateInstanceStateService,
    selectCanAdminApps
  );

  public setCrudState$ = createSetCrudStateEffect<PolicyTemplateInstance, string>(
    this.store,
    this.actions$,
    PolicyTemplateInstanceActions.loadPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    this.policyTemplateInstanceStateService,
    selectPolicyTemplateInstanceList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<PolicyTemplateInstance, string>(
    this.store,
    this.actions$,
    PolicyTemplateInstanceActions.savingPolicyTemplateInstance,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    this.policyTemplateInstanceStateService,
    selectPolicyTemplateInstanceEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<PolicyTemplateInstance, string>(
    this.store,
    this.actions$,
    PolicyTemplateInstanceActions.savingPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    this.policyTemplateInstanceStateService,
    selectPolicyTemplateInstances
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<PolicyTemplateInstance, string>(
    this.actions$,
    PolicyTemplateInstanceActions.deletingPolicyTemplateInstance,
    PolicyTemplateInstanceActions.refreshPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    this.policyTemplateInstanceStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<PolicyTemplateInstance, string>(
    this.actions$,
    PolicyTemplateInstanceActions.deletingPolicyTemplateInstances,
    PolicyTemplateInstanceActions.refreshPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    this.policyTemplateInstanceStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<PolicyTemplateInstanceState>(
    this.store,
    this.actions$,
    PolicyTemplateInstanceActions.getPolicyTemplateInstances,
    PolicyTemplateInstanceActions.maintainPolicyTemplateInstances,
    selectPolicyTemplateInstances
  );

  // Custom Effects:
  public setCurrentRuleFromPolicyTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyTemplateInstanceActions.loadPolicyTemplateInstances),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectCanReadApps)),
            this.store.pipe(select(selectApiCurrentApplication)),
            this.store.pipe(select(selectApiApplicationsCurrentRule)),
            this.store.pipe(select(selectApiApplicationsRuleId)),
            this.store.pipe(select(selectPolicyTemplateInstanceResourcesList))
          )
        )
      ),
      concatMap(([action, canRead, currentApp, currentRule, ruleId, policyResourceList]) => {
        if (
          !canRead.hasPermission ||
          canRead.orgId !== action.org_id ||
          !currentApp ||
          !currentApp.id ||
          currentApp.id === getNewCrudStateObjGuid()
        ) {
          return of(PolicyTemplateInstanceActions.maintainPolicyTemplateInstances());
        }
        return of(new ActionApiApplicationsSetCurrentRuleFromPolicy(policyResourceList));
      })
    )
  );
}
