import * as db from 'mime-db';
import { AppBundle } from '@app/core/api/applications/app-bundle';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { Renderer2 } from '@angular/core';
import { NotificationService } from '@app/core';
import { ArrayData, MapObject } from 'ngx-papaparse/lib/interfaces/unparse-data';
import { D } from '@angular/cdk/keycodes';

export function getFileNameFromPath(path: string): string {
  const lastSlash = path.lastIndexOf('/');
  return path.substring(lastSlash + 1);
}

export function getDirectoryFromPath(path: string): string {
  const lastSlash = path.lastIndexOf('/');
  return path.substring(0, lastSlash + 1);
}

export function getFileExtension(file: File): string {
  const name = file.name;
  const lastDot = name.lastIndexOf('.');
  return name.substring(lastDot + 1).toLowerCase();
}

export function uploadIsCsv(file: File): boolean {
  if (file.type === 'text/csv') {
    return true;
  }
  if (file.type === 'application/vnd.ms-excel' && getFileExtension(file) === 'csv') {
    return true;
  }
  return false;
}

export function isImageFile(fileType: string): boolean {
  if (!fileType) {
    return false;
  }
  if (fileType.match('image.*')) {
    return true;
  }
  return false;
}

export function isTextFile(fileType: string): boolean {
  if (!fileType) {
    return false;
  }
  if (fileType.match('text/.*')) {
    return true;
  }
  const lookup = db[fileType];
  if (lookup && lookup.charset) {
    return true;
  }
  return false;
}

export function isNotTextOrImageFile(fileType: string): boolean {
  if (!isTextFile(fileType) && !isImageFile(fileType)) {
    return true;
  }
  return false;
}

export function isLargeFile(fileSizeBytes: number): boolean {
  return fileSizeBytes > 200000;
}

export function blobToFile(blob: Blob, fileName: string): File {
  return new File([blob], fileName, { type: blob.type });
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if (i >= sizes.length) {
    return bytes + ' Bytes';
  }
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Gets the uploaded file.
 */
export function getFile(event: any): File | undefined {
  let file: File | undefined;
  const input = event.target;
  if (!input) {
    return undefined;
  }
  if (input.files && input.files.length > 0) {
    file = input.files[0];
  }
  // Need to reset the input so that the same file can be uploaded in consecutive attempts.
  input.value = '';
  return file;
}

export function createBlankAppBundle(): AppBundle {
  return {
    name: '',
    tag: '',
    label: '',
  };
}

export function unparseDataToCsv<T extends object>(data: Array<T>, papa: Papa): string {
  const options: UnparseConfig = {
    quotes: true,
    header: true,
    newline: '\n',
  };
  let fields: string[] = [];
  const ad: Array<Array<string>> = [];
  for (const d of data) {
    if (fields.length == 0) {
      fields = Object.keys(d);
    }
    ad.push(Object.values(d));
  }
  const mapObject: MapObject = {
    fields: fields,
    data: ad,
  };
  return papa.unparse(mapObject, options);
}

export function downloadDataToCsv<T extends object>(data: Array<T>, papa: Papa, renderer: Renderer2, fileName?: string): void {
  const csv = unparseDataToCsv(data, papa);
  const link = renderer.createElement('a');
  const blob = new Blob([csv], { type: 'text/csv' });
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName ? fileName + '.csv' : 'data.csv';
  link.click();
}

export function downloadJSON<T extends object>(data: T, renderer: Renderer2, fileName?: string): void {
  const contentType = 'application/json;charset=utf-8;';
  const link = renderer.createElement('a');
  link.download = fileName ? fileName + '.json' : 'data.json';
  link.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(data));
  link.click();
}

export function uploadIsJSON(file: File): boolean {
  if (file.type === 'application/json') {
    return true;
  }
  return false;
}

export function isJson(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getJSONFromString<T extends object>(str: string): T {
  if (isJson(str)) {
    return JSON.parse(str);
  }
  return undefined;
}

export function getJSONFromFileReader<T extends object>(reader: FileReader): T {
  const str = reader.result as string;
  return getJSONFromString(str);
}

export function getTextFileErrorMessage(file: File, fileType: string): string {
  return `This file does not appear to be in ${getFileExtensionFromType(fileType)} format. Please upload a ${getFileExtensionFromType(
    fileType
  )} file or rename the file with ".${getFileExtensionFromType(fileType)}" extension.
    File is of type "${file.type}". Expected type "text/${fileType}".`;
}

export function isValidTextFileData(file: File, fileType: string, notificationService: NotificationService): boolean {
  if (file === undefined) {
    return false;
  }
  if (file.type !== `text/${fileType}`) {
    notificationService.error(getTextFileErrorMessage(file, fileType));
    return false;
  }
  return true;
}

export function isValidPemFile(file: File): boolean {
  if (file === undefined) {
    return false;
  }
  const fileType = file.type;
  if (fileType === 'application/x-x509-ca-cert' || fileType === 'application/x-pem-file' || getFileExtension(file) === 'pem') {
    return true;
  }
  return false;
}

export function getPemFileErrorMessage(file: File): string {
  return `This file does not appear to be in PEM format. Please upload a valid pem file.
    File is of type "${file.type}". Expected type "application/x-x509-ca-cert" or "application/x-pem-file".`;
}

export function uploadDataFromTextFile(
  event: any,
  notificationService: NotificationService,
  onReadFile: (reader: FileReader) => void,
  fileType: string
): void {
  const file = getFile(event);
  if (!isValidTextFileData(file, fileType, notificationService)) {
    return;
  }
  uploadDataFromFile(file, onReadFile);
}

export function uploadDataFromFile(file: File, onReadFile: (reader: FileReader) => void): void {
  const reader = new FileReader();
  reader.onloadend = () => {
    onReadFile(reader);
  };
  reader.readAsText(file);
}

export function downloadTextFileData(data: string, renderer: Renderer2, fileType: string, fileName?: string): void {
  const link = renderer.createElement('a');
  const blob = new Blob([data], { type: `text/${fileType}` });
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName ? `${fileName}.${getFileExtensionFromType(fileType)}` : `data.${getFileExtensionFromType(fileType)}`;
  link.click();
}

export function downloadPemFileData(data: string, renderer: Renderer2, fileName?: string): void {
  const link = renderer.createElement('a');
  const blob = new Blob([data], { type: 'application/x-pem-file' });
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName ? `${fileName}.pem` : `data.pem`;
  link.click();
}

function getFileExtensionFromType(fileType: string): string {
  if (fileType === 'plain') {
    return 'txt';
  }
  return fileType;
}

export function b64toBlob(b64Data: string, contentType: string, sliceSize = 512): Blob {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export function downloadBlob(blob: Blob, renderer: Renderer2, extention: string, fileName?: string): void {
  const link = renderer.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName ? `${fileName}.${extention}` : `data.${extention}`;
  link.click();
}

export function getZipFileMimeTypesList(): Array<string> {
  return ['application/zip', 'application/x-zip-compressed'];
}

export function getTarFileMimeTypesList(): Array<string> {
  return ['application/x-tar', 'application/x-gtar', 'application/gzip', 'application/x-gzip'];
}

export function isZipFileMimeType(file: File): boolean {
  return getZipFileMimeTypesList().includes(file.type);
}

export function isTarFileMimeType(file: File): boolean {
  return getTarFileMimeTypesList().includes(file.type);
}
