import { Injectable } from '@angular/core';
import {
  User,
  patch_via_put,
  MFAChallengeMethod,
  ListCombinedUserDetailsRequestParams,
  ListCombinedUserDetailsResponse,
  ListGroupsResponse,
} from '@agilicus/angular';
import { Group, AddGroupMemberRequest } from '@agilicus/angular';
import { UsersService } from '@agilicus/angular';
import { GroupsService } from '@agilicus/angular';
import { Observable, of, forkJoin } from 'rxjs';
import { findUniqueItems } from '@app/shared/components/utils';
import { concatMap, map, mergeMap } from 'rxjs/operators';
import { sanitiseUser } from '@app/shared/utilities/model-helpers/users';
import { addNewGroupMember$, deleteGroupMember$ } from '../group-api-utils';
import { updateExistingUser$ } from '@app/core/user/user.utils';

export interface GroupAddStatus {
  id: string;
  add: boolean;
}

export interface UserWithDetail extends User {
  mfaMethods?: Array<MFAChallengeMethod>;
  mfaEnrolled?: boolean;
  mfaEnabled?: boolean;
}

export interface UsersResp {
  users: Array<UserWithDetail>;
  nextPageEmail: string;
  previousPageEmail: string;
}

@Injectable({
  providedIn: 'root',
})
export class UsersToGroupsService {
  public idToUserMap: Map<string, User> = new Map();
  public idToGroupMap: Map<string, Group> = new Map();

  constructor(private usersService: UsersService, private groupsService: GroupsService) {}

  /**
   * Returns a list of all group ids that have either had a user removed from
   * or added to its list of members.
   * @param updatedUser The user that is being added to/removed from any
   * number of groups
   */
  private getUpdatedGroups(updatedUser: User): Array<GroupAddStatus> {
    const currentGroupList = this.idToUserMap.get(updatedUser.id).member_of.filter((group) => group.type === User.TypeEnum.group);
    const updatedGroupList = updatedUser.member_of.filter((group) => group.type === User.TypeEnum.group);
    const currentGroupIds = currentGroupList.map((group) => group.id);
    const updatedGroupIds = updatedGroupList.map((group) => group.id);
    const groupsToAdd = findUniqueItems(updatedGroupIds, currentGroupIds).map((groupId) => {
      return { id: groupId, add: true };
    });
    const groupsToRemove = findUniqueItems(currentGroupIds, updatedGroupIds).map((groupId) => {
      return { id: groupId, add: false };
    });
    return [...groupsToAdd, ...groupsToRemove];
  }

  public get_users_and_groups(params: ListCombinedUserDetailsRequestParams): Observable<[UsersResp, Array<Group>]> {
    const users$ = this.usersService.listCombinedUserDetails(params).pipe(
      map((usersResp: ListCombinedUserDetailsResponse) => {
        const nextEmail = usersResp.next_page_email;
        const previousEmail = usersResp.previous_page_email;
        const results: Array<UserWithDetail> = [];
        for (const user of usersResp.combined_user_details) {
          const userResp: UserWithDetail = {
            ...user.status.user,
            mfaMethods: user.status.mfa_challenge_methods,
          };
          userResp.mfaEnrolled = userResp.mfaMethods?.length > 0;
          userResp.mfaEnabled = false;
          for (const mfaPreference of userResp.mfaMethods) {
            if (mfaPreference.spec.enabled) {
              // If any are enabled, overall the user is enabled for MFA
              userResp.mfaEnabled = true;
            }
          }

          userResp.member_of = userResp.member_of.filter((group) => group.type === User.TypeEnum.group);
          results.push(userResp);
        }
        return { users: results, nextPageEmail: nextEmail, previousPageEmail: previousEmail };
      })
    );
    const groups$ = this.groupsService.listGroups({ org_id: params.org_id }).pipe(
      map((groupsResp: ListGroupsResponse) => {
        return groupsResp.groups.filter((group) => group.type === User.TypeEnum.group);
      })
    );
    return forkJoin([users$, groups$]);
  }

  /**
   * Creates an observables array of add_member requests to add a user to any
   * number of groups.
   * @param updatedUser The user object to add to each group
   */
  private prepareGroupsForUserAddition(updatedUser: User): Array<Observable<Group>> {
    const groupIdsList = updatedUser.member_of.map((group) => group.id);
    const observablesArray = [];
    for (const id of groupIdsList) {
      observablesArray.push(addNewGroupMember$(this.groupsService, id, updatedUser.id, updatedUser.org_id));
    }
    return observablesArray;
  }

  /**
   * Creates a new user and also can add that user as a member to any
   * number of groups.
   * @param updatedUser The user object to create and add to groups
   */
  public post_users_to_groups(inputUser: User): Observable<any> {
    const updatedUser = sanitiseUser(inputUser);
    if (updatedUser.member_of.length === 0) {
      return this.usersService.createUser({ User: updatedUser });
    }
    return this.usersService
      .createUser({ User: updatedUser })
      .pipe(
        map((userResp) => {
          const new_user: User = {
            id: userResp.id,
            ...updatedUser,
          };
          return forkJoin([of(new_user), ...this.prepareGroupsForUserAddition(new_user)]);
        })
      )
      .pipe(mergeMap((value) => value));
  }

  public updateUser$(updatedUser: User): Observable<User> {
    return updateExistingUser$(this.usersService, updatedUser);
  }

  private updateGroupMembers$(updatedUser: User): Observable<Array<User> | undefined> {
    const updatedGroups = this.getUpdatedGroups(updatedUser);
    const observablesArray$: Array<Observable<User>> = [];
    for (const group of updatedGroups) {
      const body: AddGroupMemberRequest = {
        id: updatedUser.id,
        org_id: updatedUser.org_id,
      };
      if (group.add) {
        observablesArray$.push(
          this.groupsService.addGroupMember({
            group_id: group.id,
            AddGroupMemberRequest: body,
          })
        );
      } else {
        observablesArray$.push(deleteGroupMember$(this.groupsService, group.id, updatedUser));
      }
    }
    if (observablesArray$.length === 0) {
      return of(undefined);
    }
    return forkJoin(observablesArray$);
  }

  /**
   * Updates an existing user and also can add them to or remove them from any
   * number of groups.
   * @param updatedUser The user object to update and add to or remove from groups.
   */
  public put_users_to_groups(inputUser: User): Observable<User> {
    const updatedUser = sanitiseUser(inputUser);
    // First we update the user
    return this.updateUser$(updatedUser).pipe(
      // Then we update the group members
      concatMap((updatedUserResp) => forkJoin([of(updatedUserResp), this.updateGroupMembers$(updatedUser)])),
      concatMap(([updatedUserResp, _]) => {
        // Updating the group members will also change the User object in the back-end
        // so we need to get the most updated version of the User object
        return this.usersService.getUser({
          user_id: updatedUserResp.id,
          org_id: updatedUserResp.org_id,
        });
      })
    );
  }

  /**
   * Deletes a user from an organisation.
   */
  public delete_users_to_groups(params: {
    /**
     * Organisation's Unique identifier
     */
    orgId: string;
    /**
     * User's Unique identifier
     */
    userId: string;
  }): Observable<object> {
    return this.usersService.deleteUser({
      org_id: params.orgId,
      user_id: params.userId,
    });
  }
}
