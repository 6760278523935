<div class="content-container">

  <portal-page-info productGuideLink="https://www.agilicus.com/anyx-guide/agilicus-connector/" pageInfoWidth="full">
  </portal-page-info>

  <div class="join-cluster-form-container">
    <form [formGroup]="joinClusterFormGroup" class="join-cluster-form" spellcheck="false" autocomplete="off">
      <label class="radio-button-group-label">
        This is a subsequent connector (create a cluster):
      </label>
      <mat-radio-group formControlName="join_cluster" aria-labelledby="join cluster radio buttons"
        class="join-cluster-radio-group">
        <mat-radio-button *ngFor="let option of joinClusterOptions" class="join-cluster-radio-button"
          [value]="option.value" matTooltip="This option is not available for Connectors associated with Shares"
          [matTooltipDisabled]="!hasShares || !option.value">
          {{ option.name }}
        </mat-radio-button>
      </mat-radio-group>
    </form>
  </div>

  <div class="descriptive-text-container">
    <p class="windows-instruction-text" *ngIf="operatingSystem === operatingSystemEnum.windows">
      <strong>Open an administrator prompt on target-machine and paste the following:</strong>
      <br>
      <strong>Note:</strong> If Windows prior to Windows 10, you may need <a href="https://curl.se/windows"
        target="_blank">curl</a> installed.
      <br>
    </p>

    <p class="unix-instruction-text" *ngIf="operatingSystem === operatingSystemEnum.linux">
      <portal-product-guide-link
        productGuidePreText="Various Linux platforms, including embedded devices are supported. For more information, see "
        productGuideLink="https://www.agilicus.com/anyx-guide/agilicus-connector/"
        productGuideLinkText="Installation">
      </portal-product-guide-link>
      <strong>Open a root command prompt on the target and paste the following command:</strong>
    </p>

    <p class="container-instruction-text" *ngIf="operatingSystem === operatingSystemEnum.container">
      <portal-product-guide-link
        productGuidePreText="Various container-runtime environments, e.g. docker."
        productGuideLink="https://www.agilicus.com/anyx-guide/agilicus-connector/"
        productGuideLinkText="Installation">
      </portal-product-guide-link>
      <strong>Paste the following on the machine running Docker.</strong>
    </p>

    <p class="manual-instruction-text" *ngIf="operatingSystem === operatingSystemEnum.manual">
      <strong>copy/edit command-line (change suffix, select one of --install or --kubernetes-install).</strong>
    </p>
  </div>


  <div class="code-container">
    <div>
      <pre>
        <code *ngIf="!installCmd"><div class="loading">Generating...</div></code>
        <code *ngIf="!!installCmd && !!rootCertCmd">{{ rootCertCmd }}</code>
        <code *ngIf="!!installCmd">{{ getInstallCmd(installCmd) }}</code>
    </pre>
    </div>
    <button
      matSuffix
      mat-raised-button
      color="primary"
      class="copy-button"
      matTooltip="Copy the install command"
      [disabled]="!!failed"
      ngxClipboard
      [cbContent]="getRootCertAndInstallCmd(installCmd)"
    >
      <mat-icon>content_copy</mat-icon> COPY
    </button>

  </div>

</div>