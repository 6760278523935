// TODO: rename file & folder to policy

import { PolicyActionTypes, PolicyActions } from './issuer.actions';
import { PolicyState } from './issuer.models';
import { Policy, PolicyRule } from '@agilicus/angular';
import { removeElementFromListIfId, unshiftElementToListIfId, updateElementInListIfId } from '@app/shared/components/utils';

export const policyInitialState: PolicyState = {
  current_theme: undefined,
  previous_theme: undefined,
  saving_theme: false,
  theme_save_success: false,
  theme_save_fail: false,
  deleting_current_theme: false,
  loaded_org_id: undefined,
  current_issuer_policy: undefined,
  previous_issuer_policy: undefined,
  current_policy_rules_list: [],
  updated_group_id: undefined,
  policy_rule_ids_to_delete: [],
  saving_policy: false,
  saving_policy_rule: false,
  policy_rule_save_success: false,
};

export function policyReducer(state: PolicyState = policyInitialState, action: PolicyActions): PolicyState {
  switch (action.type) {
    case PolicyActionTypes.SET_THEME:
      return {
        ...state,
        current_theme: action.current_theme,
      };

    case PolicyActionTypes.SAVING_THEME:
      return {
        ...state,
        saving_theme: true,
      };

    case PolicyActionTypes.SUCCESSFUL_THEME_SAVE:
      return {
        ...state,
        current_theme: action.new_theme,
        previous_theme: state.current_theme,
        saving_theme: false,
        theme_save_success: true,
        theme_save_fail: false,
      };

    case PolicyActionTypes.FAILED_THEME_SAVE:
      return {
        ...state,
        saving_theme: false,
        theme_save_success: false,
        theme_save_fail: true,
      };

    case PolicyActionTypes.DELETING_ISSUER_THEME_ID:
      return {
        ...state,
        current_theme: undefined,
        previous_theme: state.current_theme,
        deleting_current_theme: true,
      };

    case PolicyActionTypes.SUCCESSFUL_THEME_DELETION:
      return {
        ...state,
        previous_theme: undefined,
        deleting_current_theme: false,
      };

    case PolicyActionTypes.FAILED_THEME_DELETION:
      return {
        ...state,
        previous_theme: undefined,
      };

    case PolicyActionTypes.RESET_THEME:
      return {
        ...state,
        current_theme: state.previous_theme !== undefined ? state.previous_theme : state.current_theme,
        previous_theme: undefined,
        deleting_current_theme: false,
      };

    case PolicyActionTypes.RESET_THEME_UPLOAD_STATUS:
      return {
        ...state,
        saving_theme: false,
        theme_save_success: false,
        theme_save_fail: false,
      };

    case PolicyActionTypes.SET_POLICY:
      return {
        ...state,
        current_issuer_policy: action.issuer_policy,
        current_policy_rules_list: getPolicyRulesListFromPolicy(action.issuer_policy),
      };

    case PolicyActionTypes.SAVING_POLICY:
      // Need to update the state here before the api call to prevent race condition issues when
      // multiple calls are made to the same endpoint.
      return {
        ...state,
        previous_issuer_policy: state.current_issuer_policy,
        current_issuer_policy: action.issuer_policy,
        updated_group_id: undefined,
        saving_policy: true,
      };

    case PolicyActionTypes.SUCCESSFUL_POLICY_SAVE:
      return {
        ...state,
        current_issuer_policy: action.issuer_policy,
        current_policy_rules_list: getPolicyRulesListFromPolicy(action.issuer_policy),
        updated_group_id: undefined,
        saving_policy: false,
      };

    case PolicyActionTypes.FAILED_POLICY_SAVE:
      return {
        ...state,
        current_issuer_policy: state.previous_issuer_policy,
        updated_group_id: undefined,
        saving_policy: false,
      };

    case PolicyActionTypes.SAVING_POLICY_RULE:
      return {
        ...state,
        updated_group_id: action.policyGroupId,
        saving_policy_rule: true,
        policy_rule_save_success: false,
      };

    case PolicyActionTypes.ADD_TO_POLICY_RULE_LIST:
      return {
        ...state,
        current_policy_rules_list: unshiftElementToListIfId(action.api_obj, state.current_policy_rules_list),
        saving_policy_rule: false,
        policy_rule_save_success: true,
      };

    case PolicyActionTypes.UPDATE_POLICY_RULE_LIST:
      return {
        ...state,
        current_policy_rules_list: updateElementInListIfId(action.api_obj, state.current_policy_rules_list),
        saving_policy_rule: false,
        policy_rule_save_success: true,
      };

    case PolicyActionTypes.REMOVE_FROM_POLICY_RULE_LIST:
      return {
        ...state,
        current_policy_rules_list: removeElementFromListIfId(action.api_obj, state.current_policy_rules_list),
        policy_rule_ids_to_delete: removeFromPolicyRuleIdsToDeleteList(action.api_obj, state.policy_rule_ids_to_delete),
        saving_policy_rule: false,
      };

    case PolicyActionTypes.DELETING_POLICY_GROUP:
      return {
        ...state,
        policy_rule_ids_to_delete: action.policyGroupToDelete.spec.rule_ids,
      };

    // The following action types fall to the default:
    // DELETING_THEME
    default:
      return state;
  }
}

export function getPolicyRulesListFromPolicy(issuerPolicy: Policy): Array<PolicyRule> {
  if (!issuerPolicy || !issuerPolicy.spec.rules) {
    return [];
  }
  return issuerPolicy.spec.rules;
}

/**
 * Will remove the id of a deleted policy rule from the policy_rule_ids_to_delete list
 * after that rule has been deleted.
 * @param deletedPolicyRule is the policy rule that was just deleted.
 * @param policyRuleIdsToDelete is the policy_rule_ids_to_delete list stored in our state.
 */
export function removeFromPolicyRuleIdsToDeleteList(deletedPolicyRule: PolicyRule, policyRuleIdsToDelete: Array<string>): Array<string> {
  return policyRuleIdsToDelete.filter((policyRuleId) => policyRuleId !== deletedPolicyRule.metadata.id);
}
