import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, filter } from 'rxjs/operators';
import { EMPTY, of, forkJoin } from 'rxjs';
import { OrganisationsActionTypes } from './organisations.actions';
import { OrganisationsService } from '@agilicus/angular';
import { ActionOrganisationsSetState, ActionOrganisationsLoad } from './organisations.actions';
import { NotificationService } from '../notifications/notification.service';
import { mergeMap, concatMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@app/core';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { MergedRouteNavigationAction } from '../merged-route';
import { selectApiOrgId } from '../user/user.selectors';
import { ActionUserChosenOrgChanged, UserActionTypes, ActionUserRefreshOrgDependentData } from '../user/user.actions';
import { selectCanReadOrgs } from '../user/permissions/orgs.selectors';
import { OrgQualifiedPermission } from '../user/permissions/permissions.selectors';
import { InitEmptyCrudStateAction } from '../api/state-driven-crud/state-driven-crud.actions';

@Injectable()
export class OrganisationsEffects {
  constructor(
    private actions$: Actions,
    private organisationsService: OrganisationsService,
    private notificationService: NotificationService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  public login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActionTypes.LOAD_ORGS),
      concatMap((action: ActionOrganisationsLoad) => {
        const currentOrg$ = this.organisationsService.getOrg({
          org_id: action.org_id,
        });
        const subOrgs$ = this.organisationsService.listSubOrgs({
          org_id: action.org_id,
        });
        return forkJoin([currentOrg$, subOrgs$]).pipe(
          map(([currentOrg, subOrgs]) => {
            return new ActionOrganisationsSetState(currentOrg, subOrgs.orgs);
          }),
          catchError((error) => {
            this.notificationService.error('Failed to retrieve organisation information');
            return of(new ActionOrganisationsSetState(undefined, []));
          })
        );
      })
    )
  );

  public initCrudStateOnOrglogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationsActionTypes.LOAD_ORGS),
      concatMap((action: ActionOrganisationsLoad) => {
        return of(new InitEmptyCrudStateAction());
      })
    )
  );

  public refreshState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.REFRESH_ORG_DEPENDENT_DATA),
      concatMap((action: ActionUserRefreshOrgDependentData) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectCanReadOrgs))))),
      filter(([_, canRead]) => canRead.hasPermission),
      map(([action, _]: [ActionUserRefreshOrgDependentData, OrgQualifiedPermission]) => {
        return new ActionOrganisationsLoad(action.orgId);
      })
    )
  );

  public updateOrg$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      concatMap((action: MergedRouteNavigationAction) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))),
      mergeMap(([action, orgId]) => {
        const newOrgId = action.payload.routerState.queryParams.org_id;
        if (orgId && newOrgId === undefined) {
          // No org id has been provided in url. Add the current org id
          // from the store to the url.
          this.router.navigate([action.payload.routerState.url], {
            queryParams: { org_id: orgId },
            replaceUrl: true,
          });
          return EMPTY;
        }
        // Switch to the new org.
        return of(new ActionUserChosenOrgChanged(newOrgId));
      })
    )
  );
}
