import { OrganisationsService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { AppState } from '@app/core/core.state';
import { getSubdomainFromIssuer } from '@app/core/issuer-state/issuer.utils';
import { DynamicEnvironmentService } from '@app/core/services/dynamic-environment.init';
import { selectCurrentOrg } from '@app/core/user/user.selectors';
import { getUrlSearchParams } from '@app/shared/components/utils';
import { select, Store } from '@ngrx/store';
import { catchError, concatMap, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileLinkService {
  constructor(private store: Store<AppState>, private env: DynamicEnvironmentService, private organisationsService: OrganisationsService) {}

  public getProfileUrlForLink$(): Observable<string> {
    const urlParams = getUrlSearchParams();
    const orgIdParam = urlParams.get('org_id');
    const currentOrg$ = this.store.pipe(select(selectCurrentOrg)).pipe(
      concatMap((currentOrgResp) => {
        if (!currentOrgResp && !!orgIdParam) {
          return this.organisationsService.getOrg({ org_id: orgIdParam });
        }
        return of(currentOrgResp);
      }),
      catchError((_) => {
        return of(undefined);
      })
    );
    return currentOrg$.pipe(
      map((currentOrg) => {
        let base = getSubdomainFromIssuer(currentOrg?.issuer);
        if (!base && !!this.env.environment?.overrideDomain) {
          base = this.env.environment.overrideDomain;
        }
        return `https://profile.${base}`;
      })
    );
  }
}
