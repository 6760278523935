import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { FileShareService, FileSummary } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadFileShareServices = createAction(
  '[FileShareService/API] Load FileShareServices',
  props<{ objs: FileShareService[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addFileShareService = createAction('[FileShareService/API] Add FileShareService', props<{ obj: FileShareService }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertFileShareService = createAction(
  '[FileShareService/API] Upsert FileShareService',
  props<{ obj: FileShareService; refreshData: boolean }>()
);

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addFileShareServices = createAction('[FileShareService/API] Add FileShareServices', props<{ objs: FileShareService[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertFileShareServices = createAction(
  '[FileShareService/API] Upsert FileShareServices',
  props<{ objs: FileShareService[]; refreshData: boolean }>()
);

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateFileShareService = createAction(
  '[FileShareService/API] Update FileShareService',
  props<{ obj: Update<FileShareService> }>()
);

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateFileShareServices = createAction(
  '[FileShareService/API] Update FileShareServices',
  props<{ objs: Update<FileShareService>[] }>()
);

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteFileShareService = createAction(
  '[FileShareService/API] Delete FileShareService',
  props<{ id: string; obj: FileShareService; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteFileShareServices = createAction(
  '[FileShareService/API] Delete FileShareServices',
  props<{ ids: string[]; refreshData?: boolean }>()
);

/**
 * Will delete all data from the ngrx entity state
 */
export const clearFileShareServices = createAction('[FileShareService/API] Clear FileShareServices');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initFileShareServices = createAction(
  '[FileShareService/API] Init FileShareServices',
  props<{ force: boolean; blankSlate: boolean }>()
);

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getFileShareServices = createAction(
  '[FileShareService/API] Get FileShareServices',
  props<{ org_id: string; blankSlate: boolean }>()
);

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingFileShareService = createAction(
  '[FileShareService/API] Saving FileShareService',
  props<{ obj: FileShareService; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingFileShareServices = createAction(
  '[FileShareService/API] Saving FileShareServices',
  props<{ objs: Array<FileShareService>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingFileShareService = createAction(
  '[FileShareService/API] Deleting FileShareService',
  props<{ obj: FileShareService; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingFileShareServices = createAction(
  '[FileShareService/API] Deleting FileShareServices',
  props<{ objs: Array<FileShareService>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshFileShareServices = createAction('[FileShareService/API] Refresh FileShareServices');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainFileShareServices = createAction('[FileShareService/API] Maintain FileShareServices');

// Custom resource actions:

/**
 * Will add an icon file to the relevant data type
 */
export const savingIconFileShareServices = createAction(
  '[FileShareService/API] Saving Icon FileShareServices',
  props<{ file: File; obj: FileShareService }>()
);

export const successfulIconSaveFileShareServices = createAction(
  '[FileShareService/API] Successful Icon Save FileShareServices',
  props<{ fileSummary: FileSummary; obj: FileShareService }>()
);

export const failedIconSaveFileShareServices = createAction(
  '[FileShareService/API] Failed Icon Save FileShareServices',
  props<{ obj: FileShareService }>()
);

export const resetIconStatusFileShareServices = createAction('[FileShareService/API] Reset Icon Status FileShareServices');
