import { ModeOptions } from '@app/core/models/security/mode-options.enum';
import { capitalizeFirstLetter, replaceCharacterWithSpace } from './utils';

export interface SecurityHeaderModeOption {
  modeName: ModeOptions;
  modeDescription: string;
}

export function getFormatedMode(mode: string): string {
  return capitalizeFirstLetter(replaceCharacterWithSpace(mode, '_'));
}

export function getFrameOptionsModeOptions(): Array<SecurityHeaderModeOption> {
  return [
    {
      modeName: ModeOptions.deny,
      modeDescription: 'Denies ever loading the site from within a frame',
    },
    {
      modeName: ModeOptions.sameorigin,
      modeDescription: 'Can only load the site within a frame on the same origin as the page itself.',
    },
    {
      modeName: ModeOptions.clear,
      modeDescription: `Removes the header from the server's response if present`,
    },
    {
      modeName: ModeOptions.override,
      modeDescription: 'Use the value specified in the override field. If it is not present, the header will be set to an empty string.',
    },
  ];
}

export function getContentTypeOptionsModeOptions(): Array<SecurityHeaderModeOption> {
  return [
    {
      modeName: ModeOptions.nosniff,
      modeDescription: `Blocks the request if it doesn't align with the destination element. Also enables Cross-Origin Read Blocking (CORB) protection for certain mime types.`,
    },
    {
      modeName: ModeOptions.clear,
      modeDescription: `Removes the header from the server's response if present`,
    },
    {
      modeName: ModeOptions.override,
      modeDescription: 'Use the value specified in the override field. If it is not present, the header will be set to an empty string.',
    },
  ];
}

export function getPermittedCrossDomainPoliciesModeOptions(): Array<SecurityHeaderModeOption> {
  return [
    {
      modeName: ModeOptions.clear,
      modeDescription: `Removes the header from the server's response if present`,
    },
    {
      modeName: ModeOptions.override,
      modeDescription: 'Use the value specified in the override field. If it is not present, the header will be set to an empty string.',
    },
  ];
}

export function getCoepModeOptions(): Array<SecurityHeaderModeOption> {
  return [
    {
      modeName: ModeOptions.unsafe_none,
      modeDescription:
        'Allows fetching of cross-origin resources without having explicit permission to do so via CORS (Cross Origin Resource Sharing) or CORP (Cross Origin Resource Policy)',
    },
    {
      modeName: ModeOptions.require_corp,
      modeDescription:
        'Can only fetch and load resources from the same origin, or which have been marked as loadable from a different origin. Either a cross-origin attribute in conjunction with CORS, or permission from the CORP must be given in order to load the resource.',
    },
    {
      modeName: ModeOptions.clear,
      modeDescription: `Removes the header from the server's response if present`,
    },
    {
      modeName: ModeOptions.override,
      modeDescription: 'Use the value specified in the override field. If it is not present, the header will be set to an empty string.',
    },
  ];
}

export function getCoopModeOptions(): Array<SecurityHeaderModeOption> {
  return [
    {
      modeName: ModeOptions.unsafe_none,
      modeDescription: `Allows the document to be be added to the popup's context, unless the opening document has 'same_origin' or 'same_origin_allow_popups'`,
    },
    {
      modeName: ModeOptions.same_origin_allow_popups,
      modeDescription: `Allows documents which don't have COOP, or set it to 'unsafe_none', to reference this document from their windows or tabs.`,
    },
    {
      modeName: ModeOptions.same_origin,
      modeDescription: 'Only documents from the same origin can reference this one',
    },
    {
      modeName: ModeOptions.clear,
      modeDescription: `Removes the header from the server's response if present`,
    },
    {
      modeName: ModeOptions.override,
      modeDescription: 'Use the value specified in the override field. If it is not present, the header will be set to an empty string.',
    },
  ];
}

export function getCorpModeOptions(): Array<SecurityHeaderModeOption> {
  return [
    {
      modeName: ModeOptions.same_site,
      modeDescription: `Requests from the same site can read the resource. Note that this is different than 'same_origin'. For example, \"https://example.com\" is the same site as \"https://my-app.example.com\".`,
    },

    {
      modeName: ModeOptions.same_origin,
      modeDescription: 'Only documents from the same origin can reference this one',
    },
    {
      modeName: ModeOptions.cross_origin,
      modeDescription: 'Requests from any origin can read the resource',
    },
    {
      modeName: ModeOptions.clear,
      modeDescription: `Removes the header from the server's response if present`,
    },
    {
      modeName: ModeOptions.override,
      modeDescription: 'Use the value specified in the override field. If it is not present, the header will be set to an empty string.',
    },
  ];
}

export function getReferrerPolicyModeOptions(): Array<SecurityHeaderModeOption> {
  return [
    {
      modeName: ModeOptions.no_referrer,
      modeDescription: 'The Referer header will not be sent by the client',
    },
    {
      modeName: ModeOptions.no_referrer_when_downgrade,
      modeDescription: `Only send the Referer header when the protocol's security does not degrade (e.g. HTTPS to HTTP will lead to the header not being sent)`,
    },
    {
      modeName: ModeOptions.origin,
      modeDescription: 'Only send the origin in the Referer header',
    },
    {
      modeName: ModeOptions.origin_when_cross_origin,
      modeDescription:
        'Only send the origin in the Referer header when making cross origin requests. Same-origin requests send the entire referer.',
    },
    {
      modeName: ModeOptions.same_origin,
      modeDescription: 'Only send the origin in the Referer header for same-origin requests',
    },
    {
      modeName: ModeOptions.strict_origin,
      modeDescription: `Send only the origin, and only then when the protocol's security is HTTPS`,
    },
    {
      modeName: ModeOptions.strict_origin_when_cross_origin,
      modeDescription: `Send the full Referer header for same-origin requests. Send only the origin for cross-origin requests. Do not send any Referer header when the protocol's security degrades. This option is the default behaviour for most clients.`,
    },
    {
      modeName: ModeOptions.usafe_url,
      modeDescription:
        'Always send the entire Referer header. Warning: this setting could potentially leak private or sensitive information contained in URLs.',
    },
    {
      modeName: ModeOptions.clear,
      modeDescription: `Removes the header from the server's response if present`,
    },
    {
      modeName: ModeOptions.override,
      modeDescription: 'Use the value specified in the override field. If it is not present, the header will be set to an empty string.',
    },
  ];
}
