import { Actions, createEffect, ofType, CreateEffectMetadata } from '@ngrx/effects';
import { UserActionTypes, ActionUserRefreshOrgDependentData, ActionUserSetCurrentOrg } from '../user/user.actions';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { Action, ActionCreator, DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { AppState, NotificationService } from '@app/core';
import { selectSignup } from '@app/core/signup/signup.selectors';
import { selectApiOrgId, selectCurrentOrg } from '../user/user.selectors';
import { TypedAction } from '@ngrx/store/src/models';
import { OrgQualifiedPermission } from '../user/permissions/permissions.selectors';
import {
  defaultSanitiseObjectConflicts,
  getGuidFromObject,
  getNewCrudStateObjGuid,
  ResourceObject,
  SimpleCRUDInterface,
} from '@app/core/api/state-driven-crud/state-driven-crud';
import { EntityState } from '@ngrx/entity';
import { getSubdomainFromIssuer } from '../issuer-state/issuer.utils';
import {
  AddFileRequestParams,
  CreateFileAssociationRequestParams,
  FileAssociation,
  FilesService,
  FileSummary,
  ListFilesRequestParams,
  ReuploadFileRequestParams,
} from '@agilicus/angular';
import { cloneDeep } from 'lodash-es';
import { getDefaultIconPurpose, getIconFromResource, getIconURIWithoutQueryParams } from '@app/shared/components/utils';
import { canOrgFetchDataFromApi } from '../billing-state/billing-api-utils';

/**
 *
 * @param store is the ngrx store from which data is being selected
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param getDataAction is the ngrx action used to trigger the api call to get/list the target data
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param getStateSelector is the ngrx state selector used to retrieve the relevant state data for the target data type
 * @returns will check if the org state is valid and if so will get the new data for that org from the api via the stateService.
 * Otherwise, the state is unchanged.
 */
export function createInitStateEffect<StateType extends { loaded_org_id?: string; creating_new?: boolean }>(
  store: Store<AppState>,
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { force: boolean; blankSlate: boolean | undefined }) => {
      force: boolean;
      blankSlate: boolean | undefined;
    } & TypedAction<string>
  >,
  getDataAction: ActionCreator<
    string,
    (props: { org_id: string; blankSlate: boolean | undefined }) => {
      org_id: string;
      blankSlate: boolean | undefined;
    } & TypedAction<string>
  >,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  getStateSelector: MemoizedSelector<object, StateType, DefaultProjectorFn<StateType>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      concatMap((action) => of(action).pipe(withLatestFrom(store.pipe(select(selectApiOrgId)), store.pipe(select(getStateSelector))))),
      map(([action, orgId, state]) => {
        if (!orgId || (!!state.loaded_org_id && orgId === state.loaded_org_id && !action.force) || state.creating_new) {
          return maintainStateAction();
        }
        return getDataAction({ org_id: orgId, blankSlate: action.blankSlate });
      })
    )
  );
}

/**
 *
 * @param store is the ngrx store from which data is being selected
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param clearStateAction is the ngx action which sets the entity state to empty
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param stateService is the crud state manangement service for the target data type
 * @param permissionsSelector is the ngrx state selector used to retrieve the permissions data for the target data type
 * @param idStateSelector is the ngrx state selector used to retrieve the id for the target data type
 * @returns will get data from the api via the stateService if the user has sufficient permissions.
 * If an idStateSelector is provided, will get the single data object.
 * Otherwise, will get the list of data objects.
 */
export function createLoadStateEffect<DataType, IDType>(
  store: Store<AppState>,
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { org_id: string; blankSlate: boolean }) => {
      org_id: string;
      blankSlate: boolean;
    } & TypedAction<string>
  >,
  clearStateAction: ActionCreator<string, () => TypedAction<string>>,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  stateService: SimpleCRUDInterface<DataType, IDType>,
  permissionsSelector: MemoizedSelector<any, OrgQualifiedPermission, DefaultProjectorFn<OrgQualifiedPermission>>,
  checkParentOrg: boolean = false,
  checkOrgSubdomain: boolean = false,
  idStateSelector?: MemoizedSelector<object, IDType, DefaultProjectorFn<IDType>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            store.pipe(select(selectCurrentOrg)),
            store.pipe(select(permissionsSelector)),
            !!idStateSelector ? store.pipe(select(idStateSelector)) : of(undefined)
          )
        )
      ),
      map(([action, currentOrg, permission, id]) => {
        if (!currentOrg) {
          // "currentOrg" will always be undefined the first time this function is called when the page loads;
          return maintainStateAction();
        }
        if (!permission.hasPermission || permission.orgId !== action.org_id || currentOrg.id !== action.org_id) {
          // We need to remove the stale state from the previous org, if any.
          return clearStateAction();
        }
        if (checkParentOrg && !canOrgFetchDataFromApi(currentOrg)) {
          return clearStateAction();
        }
        if (checkOrgSubdomain && currentOrg.subdomain !== getSubdomainFromIssuer(currentOrg.issuer)) {
          return clearStateAction();
        }
        if (!!id) {
          stateService.get(id, action.org_id, action.blankSlate);
        }
        if (!idStateSelector) {
          stateService.list(action.org_id, action.blankSlate);
        }
        return maintainStateAction();
      })
    )
  );
}

/**
 *
 * @param store is the ngrx store from which data is being selected
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param stateService is the crud state manangement service for the target data type
 * @param getStateDataSelector is the ngrx state selector used to retrieve the data object
 * or list of data objects for the target data type
 * @returns will load the single data object or list of objects in to the crud management state
 */
export function createSetCrudStateEffect<DataType, IDType>(
  store: Store<AppState>,
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { objs: Array<DataType>; org_id: string; blankSlate: boolean | undefined }) => {
      objs: Array<DataType>;
      org_id: string;
      blankSlate: boolean | undefined;
    } & TypedAction<string>
  >,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  stateService: SimpleCRUDInterface<DataType, IDType>,
  getStateDataSelector: MemoizedSelector<object, DataType | Array<DataType>, DefaultProjectorFn<DataType | Array<DataType>>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      concatMap((action) => of(action).pipe(withLatestFrom(store.pipe(select(getStateDataSelector))))),
      map(([action, stateData]) => {
        if (Array.isArray(stateData) && stateData?.length > 0) {
          for (const obj of stateData) {
            stateService.set(getGuidFromObject(obj), obj);
          }
        } else if (!Array.isArray(stateData)) {
          stateService.set(getGuidFromObject(stateData), stateData);
        }
        return maintainStateAction();
      })
    )
  );
}

/**
 *
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param stateService is the crud state manangement service for the target data type
 * @returns will check if the data object is new and if so will create new by calling the api
 * via the stateService. If object is not new, will update the existing by calling
 * the api via the stateService.
 */
export function createSaveOneCrudStateObjectEffect<DataType, IDType>(
  store: Store<AppState>,
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { obj: DataType; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }) => {
      obj: DataType;
      trigger_update_side_effects: boolean;
      notifyUser: boolean;
      refreshData?: boolean;
    } & TypedAction<string>
  >,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  stateService: SimpleCRUDInterface<DataType, IDType>,
  getStateSelector: (props: { id: IDType }) => MemoizedSelector<AppState, DataType, DefaultProjectorFn<DataType>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      concatMap((action) => of(action).pipe(withLatestFrom(store.pipe(select(getStateSelector({ id: getGuidFromObject(action.obj) })))))),
      map(([action, lastSavedObj]) => {
        if (getGuidFromObject(action.obj) === getNewCrudStateObjGuid()) {
          stateService.create(
            getGuidFromObject(action.obj),
            action.obj,
            action.notifyUser,
            // We always need to refresh the data when a new object is created
            // so that we update all state with necessary info from the api, such as the guid.
            true
          );
        } else {
          const sanitisedObj = defaultSanitiseObjectConflicts<DataType, IDType>(action.obj, lastSavedObj);
          stateService.update(
            getGuidFromObject(sanitisedObj),
            sanitisedObj,
            action.notifyUser,
            action.refreshData !== undefined ? action.refreshData : false
          );
        }
        return maintainStateAction();
      })
    )
  );
}

/**
 *
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param stateService is the crud state manangement service for the target data type
 * @returns for each object in a list, will check if the data object is new and, if so,
 * will create new by calling the api via the stateService. If object is not new,
 * will update the existing by calling the api via the stateService.
 */
export function createSaveListCrudStateObjectsEffect<DataType, IDType>(
  store: Store<AppState>,
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { objs: Array<DataType>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }) => {
      objs: Array<DataType>;
      trigger_update_side_effects: boolean;
      notifyUser: boolean;
      refreshData?: boolean;
    } & TypedAction<string>
  >,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  stateService: SimpleCRUDInterface<DataType, IDType>,
  getStateSelector: MemoizedSelector<object, EntityState<DataType>, DefaultProjectorFn<EntityState<DataType>>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      concatMap((action) => of(action).pipe(withLatestFrom(store.pipe(select(getStateSelector))))),
      map(([action, entityState]) => {
        const sanitisedObjList = [];
        for (const obj of action.objs) {
          const targetGuid: string = getGuidFromObject(obj);
          const lastSavedObj: DataType = entityState.entities[targetGuid];
          const sanitisedObj = defaultSanitiseObjectConflicts<DataType, IDType>(obj, lastSavedObj);
          sanitisedObjList.push(sanitisedObj);
        }
        stateService.updateList(sanitisedObjList, action.notifyUser, action.refreshData !== undefined ? action.refreshData : false);
        return maintainStateAction();
      })
    )
  );
}

/**
 *
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param refreshStateAction is the ngx action which triggers the state to refresh without actually changing the state
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param stateService is the crud state manangement service for the target data type
 * @returns will check if the object is new and, if so, refresh the state which will remove the unsaved object.
 * If object already exists, will delete by calling the api via the stateService.
 */
export function createDeletingCrudStateObjectEffect<DataType, IDType>(
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { obj: DataType }) => {
      obj: DataType;
    } & TypedAction<string>
  >,
  refreshStateAction: ActionCreator<string, () => TypedAction<string>>,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  stateService: SimpleCRUDInterface<DataType, IDType>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      map((action) => {
        if (getGuidFromObject(action.obj) === getNewCrudStateObjGuid()) {
          // TODO: find better way to do this:
          // Refreshing the state will remove the unsaved obj:
          return refreshStateAction();
        }
        stateService.delete(getGuidFromObject(action.obj), action.obj);
        return maintainStateAction();
      })
    )
  );
}

/**
 *
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param refreshStateAction is the ngx action which triggers the state to refresh without actually changing the state
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param stateService is the crud state manangement service for the target data type
 * @returns will check if the list of objects contains only one new object and, if so,
 * refresh the state which will remove the unsaved object. If objects already exists,
 * will delete by calling the api via the stateService.
 */
export function createDeleteListCrudStateObjectsEffect<DataType, IDType>(
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { objs: Array<DataType>; trigger_update_side_effects: boolean; notifyUser: boolean }) => {
      objs: Array<DataType>;
      trigger_update_side_effects: boolean;
      notifyUser: boolean;
    } & TypedAction<string>
  >,
  refreshStateAction: ActionCreator<string, () => TypedAction<string>>,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  stateService: SimpleCRUDInterface<DataType, IDType>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      map((action) => {
        if (action.objs.length === 1 && getGuidFromObject(action.objs[0]) === getNewCrudStateObjGuid()) {
          // TODO: find better way to do this:
          // Refreshing the state will remove the unsaved obj:
          return refreshStateAction();
        }
        stateService.deleteList(action.objs, action.notifyUser);
        return maintainStateAction();
      })
    )
  );
}

/**
 *
 * @param store is the ngrx store from which data is being selected
 * @param actions are the ngrx actions being filtered
 * @param getDataAction is the ngrx action used to trigger the api call to get/list the target data
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param getStateSelector is the ngrx state selector used to retrieve the relevant state data for the target data type
 * @returns will check if the org has changed and, if so, will get the new data for that org
 */
export function createOrgSwitchedEffect<StateType extends { loaded_org_id?: string }>(
  store: Store<AppState>,
  actions: Actions<Action>,
  getDataAction: ActionCreator<
    string,
    (props: { org_id: string; blankSlate: boolean | undefined }) => {
      org_id: string;
      blankSlate: boolean | undefined;
    } & TypedAction<string>
  >,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  getStateSelector: MemoizedSelector<object, StateType, DefaultProjectorFn<StateType>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(UserActionTypes.SET_CURRENT_ORG),
      concatMap((action: ActionUserSetCurrentOrg) => {
        return of(action.orgId).pipe(withLatestFrom(store.select(getStateSelector)));
      }),
      map(([orgId, state]) => {
        if (!state.loaded_org_id || state.loaded_org_id === orgId) {
          return maintainStateAction();
        }
        return getDataAction({ org_id: orgId, blankSlate: false });
      })
    )
  );
}

/**
 *
 * @param store is the ngrx store from which data is being selected
 * @param actions are the ngrx actions being filtered
 * @param getDataAction is the ngrx action used to trigger the api call to get/list the target data
 * @param maintainStateAction is the ngx action which returns when no change in state is required
 * @param shouldPopulateStateSelector is the ngrx state selector used to determine if the state should be populated with the data from the api
 * @returns will watch for a RefreshOrgDependentData action and either get the new data for that org
 * or do nothing if the user is currently signing up
 */
export function createEntityRefreshOrgDependentEffect(
  store: Store<AppState>,
  actions: Actions<Action>,
  getDataAction: ActionCreator<
    string,
    (props: { org_id: string; blankSlate: boolean | undefined }) => {
      org_id: string;
      blankSlate: boolean | undefined;
    } & TypedAction<string>
  >,
  maintainStateAction: ActionCreator<string, () => TypedAction<string>>,
  shouldPopulateStateSelector: MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(UserActionTypes.REFRESH_ORG_DEPENDENT_DATA),
      concatMap((action: ActionUserRefreshOrgDependentData) =>
        of(action).pipe(withLatestFrom(store.pipe(select(selectSignup)), store.pipe(select(shouldPopulateStateSelector))))
      ),
      map(([action, signingUp, shouldPopulate]: [ActionUserRefreshOrgDependentData, boolean, boolean]) => {
        if (signingUp || !shouldPopulate) {
          return maintainStateAction();
        }
        return getDataAction({ org_id: action.orgId, blankSlate: false });
      })
    )
  );
}

// TODO: remove this and replace all instances with createEntityRefreshOrgDependentEffect below
export function createRefreshOrgDependentEffect(
  store: Store<AppState>,
  actions: Actions<Action>,
  resultType: new (orgId: string) => Action,
  shouldPopulateStateSelector: MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>>
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(UserActionTypes.REFRESH_ORG_DEPENDENT_DATA),
      concatMap((action: ActionUserRefreshOrgDependentData) => {
        return of(action).pipe(withLatestFrom(store.pipe(select(selectSignup)), store.pipe(select(shouldPopulateStateSelector))));
      }),
      concatMap(([action, signingUp, shouldPopulate]: [ActionUserRefreshOrgDependentData, boolean, boolean]) => {
        if (signingUp || !shouldPopulate) {
          return EMPTY;
        }
        const orgId: string = action.orgId;
        return of(new resultType(orgId));
      })
    )
  );
}

/**
 *
 * @param store is the ngrx store from which data is being selected
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param successAction is the ngrx action that is disptached when the api call is successful
 * @param failAction is the ngrx action that is disptached when the api call fails
 * @param filesService is the service that interacts with the files api
 * @param notificationService is the service that presents messages to the user in the UI
 * @returns will attempt to create a new file in the api and dispatch actions based on whether
 * the api call was successful
 */
export function createSavingResourceIconFileEffect<DataType extends ResourceObject<string>>(
  store: Store<AppState>,
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { file: File; obj: DataType }) => {
      file: File;
      obj: DataType;
    } & TypedAction<string>
  >,
  successAction: ActionCreator<
    string,
    (props: { fileSummary: FileSummary; obj: DataType }) => {
      fileSummary: FileSummary;
      obj: DataType;
    } & TypedAction<string>
  >,
  failAction: ActionCreator<
    string,
    (props: { obj: DataType }) => {
      obj: DataType;
    } & TypedAction<string>
  >,
  filesService: FilesService,
  notificationService: NotificationService
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      concatMap((action) => of(action).pipe(withLatestFrom(store.pipe(select(selectApiOrgId))))),
      concatMap(([action, orgId]) => {
        const listFilesRequestParams: ListFilesRequestParams = {
          org_id: orgId,
          tag: getGuidFromObject<DataType, string>(action.obj),
        };
        return filesService.listFiles(listFilesRequestParams).pipe(
          concatMap((filesListResp) => {
            const icon = getIconFromResource(action.obj);
            const targetFile = !!icon
              ? filesListResp.files.find((file) => getIconURIWithoutQueryParams(file.public_url) === getIconURIWithoutQueryParams(icon.uri))
              : undefined;
            if (!targetFile) {
              const addFileParams: AddFileRequestParams = {
                name: action.file.name,
                file_zip: action.file,
                org_id: orgId,
                tag: getGuidFromObject<DataType, string>(action.obj),
                label: 'resource_icon',
                visibility: 'public',
              };
              return filesService.addFile(addFileParams).pipe(
                concatMap((addNewFileResp) => {
                  return of(addNewFileResp).pipe(withLatestFrom(of(action), of(orgId)));
                }),
                catchError((_) => {
                  return of(undefined).pipe(withLatestFrom(of(action), of(orgId)));
                })
              );
            }
            const reuploadFileRequestParams: ReuploadFileRequestParams = {
              file_id: targetFile.id,
              org_id: orgId,
              file_zip: action.file,
            };
            return filesService.reuploadFile(reuploadFileRequestParams).pipe(
              concatMap((replaceFileResp) => {
                return of(replaceFileResp).pipe(withLatestFrom(of(action), of(orgId)));
              }),
              catchError((_) => {
                return of(undefined).pipe(withLatestFrom(of(action), of(orgId)));
              })
            );
          })
        );
      }),
      concatMap(([updateResp, action, orgId]) => {
        if (!updateResp) {
          notificationService.error(`Failed to save new icon "${action.file.name}". Please try again.`);
          return of(failAction({ obj: action.obj }));
        }
        if (!!updateResp.has_been_associated) {
          return of(successAction({ fileSummary: updateResp, obj: action.obj }));
        }
        const fileAssociation: FileAssociation = {
          spec: {
            file_id: updateResp.id,
            object_id: getGuidFromObject<DataType, string>(action.obj),
            org_id: orgId,
          },
        };
        const createFileAssociationRequestParams: CreateFileAssociationRequestParams = {
          FileAssociation: fileAssociation,
        };
        return filesService.createFileAssociation(createFileAssociationRequestParams).pipe(
          concatMap((_) => {
            return of(successAction({ fileSummary: updateResp, obj: action.obj }));
          })
        );
      })
    )
  );
}

/**
 *
 * @param actions are the ngrx actions being filtered
 * @param watchAction is the ngrx action being watched to trigger the effect
 * @param saveDataAction is the ngrx action which triggers updating the object in the api
 * @returns will update the object with the new file data and dispatch the save action
 * which triggers the api update
 */
export function createUpdateResourceWithIconFileEffect<DataType extends ResourceObject<string>>(
  actions: Actions<Action>,
  watchAction: ActionCreator<
    string,
    (props: { fileSummary: FileSummary; obj: DataType }) => {
      fileSummary: FileSummary;
      obj: DataType;
    } & TypedAction<string>
  >,
  saveDataAction: ActionCreator<
    string,
    (props: { obj: DataType; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }) => {
      obj: DataType;
      trigger_update_side_effects: boolean;
      notifyUser: boolean;
      refreshData?: boolean;
    } & TypedAction<string>
  >
): Observable<Action> & CreateEffectMetadata {
  return createEffect(() =>
    actions.pipe(
      ofType(watchAction),
      map((action) => {
        const updatedObj = cloneDeep(action.obj);
        if (!updatedObj.spec.resource_config) {
          updatedObj.spec.resource_config = {
            display_info: {
              icons: [
                {
                  purposes: [getDefaultIconPurpose()],
                  uri: action.fileSummary.public_url,
                },
              ],
            },
          };
        } else if (!updatedObj.spec.resource_config.display_info) {
          updatedObj.spec.resource_config.display_info = {
            icons: [
              {
                purposes: [getDefaultIconPurpose()],
                uri: action.fileSummary.public_url,
              },
            ],
          };
        } else {
          const icon = getIconFromResource(updatedObj);
          if (!!icon) {
            icon.uri = action.fileSummary.public_url;
          } else {
            updatedObj.spec.resource_config.display_info.icons.push({
              purposes: [getDefaultIconPurpose()],
              uri: action.fileSummary.public_url,
            });
          }
        }
        return saveDataAction({ obj: updatedObj, trigger_update_side_effects: false, notifyUser: true, refreshData: true });
      })
    )
  );
}
