import { Directive, HostListener, Input, OnChanges } from '@angular/core';
import { AppState } from '@app/core';
import { TabsState } from '@app/core/models/ui/ui-model';
import { ActionUIUpdateTabsState } from '@app/core/ui/ui.actions';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

@Directive({
  selector: '[portalSwipeable]',
})
export class SwipeableDirective implements OnChanges {
  @Input() private appTabState: TabsState;
  @Input() private tabGroupId: string;
  @Input() private tabsLength: number;
  private appTabStateCopy: TabsState;
  private SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  constructor(private store: Store<AppState>) {}

  @HostListener('swipeleft', ['$event'])
  private onSwipeLeft(event: Event): void {
    this.onSwipe('swipeleft');
  }

  @HostListener('swiperight', ['$event'])
  private onSwipeRight(event: Event): void {
    this.onSwipe('swiperight');
  }

  public ngOnChanges(): void {
    this.appTabStateCopy = cloneDeep(this.appTabState);
  }

  public onSwipe(eType: string): void {
    if (!this.tabsLength || !this.appTabStateCopy || !this.tabGroupId) {
      return;
    }
    if (eType === this.SWIPE_ACTION.LEFT && this.appTabStateCopy.tabs[this.tabGroupId] < this.tabsLength - 1) {
      this.appTabStateCopy.tabs[this.tabGroupId]++;
    } else if (eType === this.SWIPE_ACTION.RIGHT && this.appTabStateCopy.tabs[this.tabGroupId] > 0) {
      this.appTabStateCopy.tabs[this.tabGroupId]--;
    }
    this.store.dispatch(new ActionUIUpdateTabsState(this.appTabStateCopy));
  }
}
