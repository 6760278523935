import {
  CreateObjectCredentialRequestParams,
  CredentialsService,
  DeleteObjectCredentialRequestParams,
  GetObjectCredentialRequestParams,
  ListObjectCredentialsRequestParams,
  ObjectCredential,
  patch_via_put,
  ReplaceObjectCredentialRequestParams,
} from '@agilicus/angular';
import { map, Observable } from 'rxjs';

export function getSshCredentialsList$(
  credentialsService: CredentialsService,
  objectId: string,
  objectType: string,
  orgId: string | undefined
): Observable<Array<ObjectCredential>> {
  const listObjectCredentialsRequestParams: ListObjectCredentialsRequestParams = {
    object_id: objectId,
    object_type: objectType,
    org_id: orgId,
  };
  return credentialsService.listObjectCredentials(listObjectCredentialsRequestParams).pipe(
    map((resp) => {
      return resp.object_credentials;
    })
  );
}

export function createNewSshCredential$(
  credentialsService: CredentialsService,
  newCredential: ObjectCredential
): Observable<ObjectCredential> {
  const createObjectCredentialRequestParams: CreateObjectCredentialRequestParams = {
    ObjectCredential: newCredential,
  };
  return credentialsService.createObjectCredential(createObjectCredentialRequestParams);
}

export function deleteExistingSshCredential$(
  credentialsService: CredentialsService,
  credentialToDelete: ObjectCredential
): Observable<any> {
  const deleteObjectCredentialRequestParams: DeleteObjectCredentialRequestParams = {
    object_credential_id: credentialToDelete.metadata.id,
    org_id: credentialToDelete.spec.org_id,
  };
  return credentialsService.deleteObjectCredential(deleteObjectCredentialRequestParams);
}

export function updateExistingSshCredential$(
  credentialsService: CredentialsService,
  credentialToUpdate: ObjectCredential
): Observable<ObjectCredential> {
  const getter = (item: ObjectCredential) => {
    const getRequestParams: GetObjectCredentialRequestParams = {
      object_credential_id: item.metadata.id,
      org_id: item.spec.org_id,
    };
    return credentialsService.getObjectCredential(getRequestParams);
  };
  const putter = (item: ObjectCredential) => {
    const replaceRequestParams: ReplaceObjectCredentialRequestParams = {
      object_credential_id: item.metadata.id,
      ObjectCredential: item,
    };
    return credentialsService.replaceObjectCredential(replaceRequestParams);
  };
  return patch_via_put(credentialToUpdate, getter, putter);
}
