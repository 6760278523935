import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as DesktopResourceActions from './desktop.actions';
import { DesktopResource } from '@agilicus/angular';
import { ResourceBasicCollectionState } from '@app/core/basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultIconStatus, getDefaultResourceBasicCollectionState } from '../helpers/entity-state-utils';

export const desktopsFeatureKey = 'desktop';

export type DesktopResourceState = ResourceBasicCollectionState<DesktopResource>;

export const adapter: EntityAdapter<DesktopResource> = createEntityAdapter<DesktopResource>({
  sortComparer: sortListByCreatedDate,
  selectId: (desktop) => desktop.metadata.id,
});

export const initialState: DesktopResourceState = adapter.getInitialState(getDefaultResourceBasicCollectionState<DesktopResource>());

export const desktopReducer = createReducer(
  initialState,
  on(DesktopResourceActions.addDesktopResource, (state, action) => adapter.addOne(action.obj, state)),
  on(DesktopResourceActions.upsertDesktopResource, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(DesktopResourceActions.addDesktopResources, (state, action) => adapter.addMany(action.objs, state)),
  on(DesktopResourceActions.upsertDesktopResources, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(DesktopResourceActions.updateDesktopResource, (state, action) => adapter.updateOne(action.obj, state)),
  on(DesktopResourceActions.updateDesktopResources, (state, action) => adapter.updateMany(action.objs, state)),
  on(DesktopResourceActions.deleteDesktopResource, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(DesktopResourceActions.deleteDesktopResources, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(DesktopResourceActions.loadDesktopResources, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(DesktopResourceActions.clearDesktopResources, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(DesktopResourceActions.savingDesktopResource, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(DesktopResourceActions.savingDesktopResources, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(DesktopResourceActions.initDesktopResources, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(DesktopResourceActions.refreshDesktopResources, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  }),
  // custom resource reducers:
  on(DesktopResourceActions.savingIconDesktopResources, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: true,
        icon_file_save_success: false,
        icon_file_save_fail: false,
      },
    };
  }),
  on(DesktopResourceActions.successfulIconSaveDesktopResources, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: true,
        icon_file_save_fail: false,
      },
    };
  }),
  on(DesktopResourceActions.failedIconSaveDesktopResources, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: false,
        icon_file_save_fail: true,
      },
    };
  }),
  on(DesktopResourceActions.resetIconStatusDesktopResources, (state, action) => {
    return { ...state, icon_status: getDefaultIconStatus() };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
