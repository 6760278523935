import { Action } from '@ngrx/store';
import { User, Organisation, UserMetadata } from '@agilicus/angular';
import { GettingStartedData } from './preferences/getting-started-data';

export enum UserActionTypes {
  CANNOT_SEE_CURRENT_ORG = '[User] CannotSeeCurrentOrg',
  CHOSEN_ORG_CHANGED = '[User] ChosenOrgChanged',
  INIT = '[User] Init',
  LOGGED_IN = '[User] LoggedIn',
  LOG_OUT = '[User] Logout',
  LOGGED_OUT = '[User] LoggedOut',
  LOG_OUT_COMPLETE = '[User] LogoutComplete',
  LOAD_ORGS_AND_LOG_IN = '[User] LoadOrgsAndLogIn',
  MEMBER_ORGS_UPDATED = '[User] MemberOrgsUpdated',
  NOT_LOGGED_IN = '[User] NotLoggedIn',
  ORGANISATION_SELECTED = '[User] OrganisationSelected',
  ORG_SWITCHED = '[User] OrgSwitched',
  REFRESH_MEMBER_ORGS = '[User] RefreshMemberOrgs',
  REFRESH_ORG_DEPENDENT_DATA = '[User] RefreshOrgDependentData',
  REFRESH_PERMISSIONS = '[User] RefreshPermissions',
  SET_CURRENT_ORG = '[User] SetCurrentOrg',
  SET_PERMISSIONS = '[User] SetPermissions',
  SYNC_LOGIN = '[User] Sync Login',
  UPDATE_ADMIN_PORTAL_METADATA = '[User] UpdateAdminPortalMetadata',
  UPDATE_GETTING_STARTED_DATA = '[User] UpdateGettingStartedData',
  FAILED_GETTING_STARTED_DATA_UPDATE = '[User] FailedGettingStartedDataUpdate',
  MAINTAIN_STATE = '[User] MaintainState',
  HIDE_PAYMENT_REMINDER = '[User] HidePaymentReminder',
}

export class ActionUserCannotSeeCurrentOrg implements Action {
  public readonly type = UserActionTypes.CANNOT_SEE_CURRENT_ORG;
  constructor() {}
}

export class ActionUserRefreshMemberOrgs implements Action {
  public readonly type = UserActionTypes.REFRESH_MEMBER_ORGS;
  constructor(readonly organisation?: Organisation) {}
}

export class ActionUserInit implements Action {
  public readonly type = UserActionTypes.INIT;
  constructor() {}
}

export class ActionUserLoadOrgsAndLogIn implements Action {
  public readonly type = UserActionTypes.LOAD_ORGS_AND_LOG_IN;
  constructor(readonly user: User, readonly desiredOrgId: string) {}
}

export class ActionUserLoggedIn implements Action {
  public readonly type = UserActionTypes.LOGGED_IN;
  constructor(readonly user: User, readonly orgId: string) {}
}

export class ActionUserLogout implements Action {
  public readonly type = UserActionTypes.LOG_OUT;
  constructor(readonly redirectURI?: string, readonly localOnly?: boolean) {}
}

export class ActionUserLoggedOut implements Action {
  public readonly type = UserActionTypes.LOGGED_OUT;
  constructor(readonly redirectURI?: string, readonly localOnly?: boolean) {}
}

export class ActionUserLogoutComplete implements Action {
  public readonly type = UserActionTypes.LOG_OUT_COMPLETE;
  constructor(readonly reload?: boolean) {}
}

export class ActionUserMemberOrgsUpdated implements Action {
  public readonly type = UserActionTypes.MEMBER_ORGS_UPDATED;
  constructor(readonly orgs: Organisation[]) {}
}

export class ActionUserNotLoggedIn implements Action {
  public readonly type = UserActionTypes.NOT_LOGGED_IN;
  constructor() {}
}

export class ActionOrganisationSelected implements Action {
  public readonly type = UserActionTypes.ORGANISATION_SELECTED;
  constructor(readonly organisation: Organisation) {}
}

export class ActionUserProcessOrgSwitch implements Action {
  public readonly type = UserActionTypes.CHOSEN_ORG_CHANGED;
  public readonly org_id?: string;
  constructor(org_id?: string) {
    this.org_id = org_id;
  }
}

export class ActionUserChosenOrgChanged implements Action {
  public readonly type = UserActionTypes.CHOSEN_ORG_CHANGED;
  public readonly org_id?: string;
  constructor(org_id?: string) {
    this.org_id = org_id;
  }
}

export class ActionUserOrgSwitched implements Action {
  public readonly type = UserActionTypes.ORG_SWITCHED;
  public readonly org_id?: string;
  constructor(org_id?: string) {
    this.org_id = org_id;
  }
}

export class ActionUserRefreshOrgDependentData implements Action {
  public readonly type = UserActionTypes.REFRESH_ORG_DEPENDENT_DATA;
  constructor(readonly orgId: string) {}
}

export class ActionUserRefreshPermissions implements Action {
  public readonly type = UserActionTypes.REFRESH_PERMISSIONS;
  constructor(readonly org_id: string) {}
}

export class ActionUserSetCurrentOrg implements Action {
  public readonly type = UserActionTypes.SET_CURRENT_ORG;
  constructor(readonly permissions: Map<string, string[]>, readonly orgId: string) {}
}

export class ActionUserSyncLogin implements Action {
  public readonly type = UserActionTypes.SYNC_LOGIN;
  constructor(readonly org_id: string) {}
}

export class ActionUserUpdateAdminPortalMetadata implements Action {
  public readonly type = UserActionTypes.UPDATE_ADMIN_PORTAL_METADATA;
  constructor(readonly userMetadata: UserMetadata) {}
}

export class ActionUserUpdateGettingStartedData implements Action {
  public readonly type = UserActionTypes.UPDATE_GETTING_STARTED_DATA;
  constructor(readonly updatedGettingStartedData: GettingStartedData) {}
}

export class ActionUserFailedGettingStartedDataUpdate implements Action {
  public readonly type = UserActionTypes.FAILED_GETTING_STARTED_DATA_UPDATE;
  constructor() {}
}

export class ActionUserHidePaymentReminder implements Action {
  public readonly type = UserActionTypes.HIDE_PAYMENT_REMINDER;
  constructor() {}
}

export class ActionUserMaintainState implements Action {
  public readonly type = UserActionTypes.MAINTAIN_STATE;
  constructor() {}
}

export type UserActions =
  | ActionOrganisationSelected
  | ActionUserCannotSeeCurrentOrg
  | ActionUserChosenOrgChanged
  | ActionUserInit
  | ActionUserLoggedIn
  | ActionUserLogout
  | ActionUserLoadOrgsAndLogIn
  | ActionUserLoggedOut
  | ActionUserLogoutComplete
  | ActionUserMemberOrgsUpdated
  | ActionUserNotLoggedIn
  | ActionUserOrgSwitched
  | ActionUserRefreshMemberOrgs
  | ActionUserRefreshOrgDependentData
  | ActionUserRefreshPermissions
  | ActionUserSetCurrentOrg
  | ActionUserSyncLogin
  | ActionUserUpdateAdminPortalMetadata
  | ActionUserUpdateGettingStartedData
  | ActionUserFailedGettingStartedDataUpdate
  | ActionUserHidePaymentReminder
  | ActionUserMaintainState;
