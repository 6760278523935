export enum PermissionsCheckboxOptions {
  DISABLED = 'Show disabled users',
  PENDING = 'Show pending users',
  USERS = 'user',
  GROUPS = 'group',
  BIGROUPS = 'bigroup',
  SERVICE_ACCOUNT = 'service account',
  RESOURCE_GROUP = 'Hide resources in a group',
  HIDE_DELETED_RESOURCES = 'Hide permissions for deleted resources',
}
