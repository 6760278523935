import {
  AddGroupMemberRequest,
  CreateGroupRequestParams,
  Group,
  GroupsService,
  ListGroupsRequestParams,
  patch_via_put,
  User,
} from '@agilicus/angular';
import { concatMap, map, Observable, of, throwError } from 'rxjs';
import { getMoreThanOneResultError } from './api-utils';

export function getGroupByName$(
  groupService: GroupsService,
  name: string,
  orgId: string,
  type?: Array<'group' | 'sysgroup' | 'bigroup'>
): Observable<Group> {
  const listGroupsRequestParams: ListGroupsRequestParams = {
    org_id: orgId,
    first_name: name,
  };
  if (!!type) {
    listGroupsRequestParams.type = type;
  }
  return groupService.listGroups(listGroupsRequestParams).pipe(
    concatMap((resp) => {
      const moreThanOneResultError = getMoreThanOneResultError(resp.groups);
      if (!!moreThanOneResultError) {
        return throwError(() => moreThanOneResultError);
      }
      return of(resp.groups.length === 0 ? undefined : resp.groups[0]);
    })
  );
}

export function createNewGroup$(groupService: GroupsService, group: Group): Observable<Group> {
  const createGroupRequestParams: CreateGroupRequestParams = {
    Group: group,
  };
  return groupService.createGroup(createGroupRequestParams);
}

export function updateExistingGroup$(groupService: GroupsService, groupToUpdate: Group): Observable<Group | undefined> {
  const putter = (group: Group) => {
    return groupService.replaceGroup({
      group_id: group.id,
      Group: group,
    });
  };
  const getter = (group: Group) => {
    return groupService.getGroup({
      group_id: group.id,
      org_id: group.org_id,
    });
  };
  return patch_via_put(groupToUpdate, getter, putter);
}

export function addNewGroupMember$(
  groupsService: GroupsService,
  parentGroupId: string,
  memberGroupId: string,
  orgId: string
): Observable<Group> {
  const addGroupMemberRequest: AddGroupMemberRequest = {
    id: memberGroupId,
    org_id: orgId,
    member_org_id: orgId,
  };
  return groupsService.addGroupMember({
    group_id: parentGroupId,
    AddGroupMemberRequest: addGroupMemberRequest,
  });
}

export function deleteGroup$(groupService: GroupsService, groupId: string, orgId: string): Observable<any> {
  return groupService.deleteGroup({
    group_id: groupId,
    org_id: orgId,
  });
}

export function deleteGroupByName$(groupService: GroupsService, groupName: string, orgId: string): Observable<Group> {
  return getGroupByName$(groupService, groupName, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp) {
        return of(undefined);
      }
      return deleteGroup$(groupService, getResp.id, orgId).pipe(map((deleteResp) => getResp));
    })
  );
}

export function deleteGroupMember$(groupService: GroupsService, groupId: string, groupMember: User): Observable<any> {
  return groupService.deleteGroupMember({
    group_id: groupId,
    member_id: groupMember.id,
    org_id: groupMember.org_id,
  });
}
