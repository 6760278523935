export interface CsvData {
  csvRowNumber?: number;
  error?: boolean;
}

export enum UploadStatus {
  PASS = 'pass',
  FAIL = 'fail',
}

/**
 * Removes whitespace from either side of all values
 */
export function removeWhitespace<T>(item: T): void {
  for (const key of Object.keys(item)) {
    if (typeof item[key] === 'string') {
      item[key] = item[key].trim();
    }
  }
}

/**
 * Adds a row number to each entry which corresponds to the row in the csv file.
 * Does not account for empty rows in the csv file.
 */
export function addRowNumbers<T extends CsvData>(csvParseResult: Array<T>): void {
  for (let i = 0; i < csvParseResult.length; i++) {
    csvParseResult[i].csvRowNumber = i + 2;
  }
}

export function removeInvalidColumns<T>(csvParseResult: Array<T>, validHeaders: Set<string>): Array<T> {
  return csvParseResult.map((element) => {
    for (const key of Object.keys(element)) {
      if (!isValidColumn(key, validHeaders)) {
        delete element[key];
      }
    }
    return element;
  });
}

export function isValidColumn(header: string, validHeaders: Set<string>): boolean {
  return validHeaders.has(header);
}

export function checkValidEntry(value: any, defaultVal: any): any {
  if (value === undefined || value === '' || value === null) {
    return defaultVal;
  } else {
    return value;
  }
}
