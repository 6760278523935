import { AuthenticationDocument, EnvironmentConfigVar, EnvironmentConfigVarList, IssuerClient, Organisation } from '@agilicus/angular';
import { AgentConfigData, ApplicationModel, ApplicationModelHosting } from './application-model';
import { needToCreateAgent } from './application-model-api-utils';
import { isAccessedOnPrem, isAccessedViaAgent } from './application-model-utils';

export function getAgentConnectionUri(appName: string, org: Organisation): string {
  return `wss://${appName}.${org.subdomain}/.agilicus-agent/control`;
}

export function getAgentName(appName: string): string {
  return `${appName}-default-agent`;
}

export function getConnectorEnvConfigVars(appModelHosting: ApplicationModelHosting): EnvironmentConfigVarList {
  let envConfigVars = [];
  if (isAccessedOnPrem(appModelHosting)) {
    envConfigVars = [...envConfigVars, ...getDefaultConnectorEnvConfigVars()];
  }
  if (isAccessedViaAgent(appModelHosting)) {
    envConfigVars = [...envConfigVars, ...getAgentEnvConfigVars()];
  }
  return { configs: envConfigVars };
}

export function getDefaultConnectorEnvConfigVars(): Array<EnvironmentConfigVar> {
  return [{ name: 'SESSION_SECRET', value: getSessionSecretValue() }];
}

export function getAgentEnvConfigVars(): Array<EnvironmentConfigVar> {
  return [{ name: 'RUN_AGENT', value: 'YES' }];
}

export function getSessionSecretValue(): string {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return btoa(String.fromCharCode(...array));
}

export function getAgentConfigData(
  appModel: ApplicationModel,
  agentId: string,
  issuer: string,
  issuerClent: IssuerClient,
  authDoc: AuthenticationDocument | undefined,
  apiDomain: string | undefined
): AgentConfigData | undefined {
  if (!needToCreateAgent(appModel)) {
    return undefined;
  }
  const agentConfigData: AgentConfigData = {
    agilicus_config: {
      agent_id: agentId,
    },
    auth_config: {
      issuer,
      client_id: issuerClent.name,
      client_secret: issuerClent.secret,
      scopes: [getAgentAuthConfigScope(issuerClent.name)],
      service_account: authDoc,
    },
  };
  if (apiDomain) {
    agentConfigData.agilicus_config.api_server = apiDomain;
  }
  return agentConfigData;
}

export function getAgentAuthConfigScope(appName: string): string {
  return `urn:agilicus:app:${appName}:agilicus-agent`;
}

export function getAgentConfigFileName(appName: string): string {
  return appName + '_agent_config';
}
