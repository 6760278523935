import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FilesService, SSHResource } from '@agilicus/angular';
import { AppState, NotificationService } from '..';
import { selectCanAdminApps } from '../user/permissions/app.selectors';
import * as SSHResourceActions from './ssh.actions';
import { selectSSHResources, selectSSHResourceEntity, selectSSHResourceList, selectSSHResourceShouldPopulateValue } from './ssh.selectors';
import { SSHResourceState } from './ssh.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSavingResourceIconFileEffect,
  createSetCrudStateEffect,
  createUpdateResourceWithIconFileEffect,
} from '../helpers/effect-factories';
import { SSHResourceStateService } from '../state-services/ssh-state.service';

@Injectable()
export class SSHResourceEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private sshStateService: SSHResourceStateService,
    private filesService: FilesService,
    private notificationService: NotificationService
  ) {}

  public initState$ = createInitStateEffect<SSHResourceState>(
    this.store,
    this.actions$,
    SSHResourceActions.initSSHResources,
    SSHResourceActions.getSSHResources,
    SSHResourceActions.maintainSSHResources,
    selectSSHResources
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    SSHResourceActions.getSSHResources,
    SSHResourceActions.maintainSSHResources,
    selectSSHResourceShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<SSHResource, string>(
    this.store,
    this.actions$,
    SSHResourceActions.getSSHResources,
    SSHResourceActions.clearSSHResources,
    SSHResourceActions.maintainSSHResources,
    this.sshStateService,
    selectCanAdminApps
  );

  public setCrudState$ = createSetCrudStateEffect<SSHResource, string>(
    this.store,
    this.actions$,
    SSHResourceActions.loadSSHResources,
    SSHResourceActions.maintainSSHResources,
    this.sshStateService,
    selectSSHResourceList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<SSHResource, string>(
    this.store,
    this.actions$,
    SSHResourceActions.savingSSHResource,
    SSHResourceActions.maintainSSHResources,
    this.sshStateService,
    selectSSHResourceEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<SSHResource, string>(
    this.store,
    this.actions$,
    SSHResourceActions.savingSSHResources,
    SSHResourceActions.maintainSSHResources,
    this.sshStateService,
    selectSSHResources
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<SSHResource, string>(
    this.actions$,
    SSHResourceActions.deletingSSHResource,
    SSHResourceActions.refreshSSHResources,
    SSHResourceActions.maintainSSHResources,
    this.sshStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<SSHResource, string>(
    this.actions$,
    SSHResourceActions.deletingSSHResources,
    SSHResourceActions.refreshSSHResources,
    SSHResourceActions.maintainSSHResources,
    this.sshStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<SSHResourceState>(
    this.store,
    this.actions$,
    SSHResourceActions.getSSHResources,
    SSHResourceActions.maintainSSHResources,
    selectSSHResources
  );

  // Custom Effects:

  public savingIconFile$ = createSavingResourceIconFileEffect<SSHResource>(
    this.store,
    this.actions$,
    SSHResourceActions.savingIconSSHResources,
    SSHResourceActions.successfulIconSaveSSHResources,
    SSHResourceActions.failedIconSaveSSHResources,
    this.filesService,
    this.notificationService
  );

  public updateResourceWithIconFile$ = createUpdateResourceWithIconFileEffect<SSHResource>(
    this.actions$,
    SSHResourceActions.successfulIconSaveSSHResources,
    SSHResourceActions.savingSSHResource
  );
}
