import { createSelector } from '@ngrx/store';
import { selectLauncherState } from '../core.state';
import { LauncherState, selectAll, selectEntities } from './launcher.reducer';

export const selectLaunchers = createSelector(selectLauncherState, (state: LauncherState) => state);

export const selectLauncherList = createSelector(selectLaunchers, selectAll);

export const selectLauncherEntities = createSelector(selectLaunchers, selectEntities);

export const selectLauncherEntity = (props: { id: string }) =>
  createSelector(selectLauncherEntities, (entities) => {
    return entities[props.id];
  });

export const selectLauncherRefreshDataValue = createSelector(selectLaunchers, (state: LauncherState) => state.refresh_data);

export const selectLauncherShouldPopulateValue = createSelector(selectLaunchers, (state: LauncherState) => state.should_populate);

// Custom resource selectors:
export const selectLauncherIconStatus = createSelector(selectLaunchers, (state: LauncherState) => state.icon_status);
