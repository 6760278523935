import {
  CertificateTransparencySettings,
  ContentTypeOptionsSettings,
  CrossOriginEmbedderPolicySettings,
  CrossOriginOpenerPolicySettings,
  CrossOriginResourcePolicySettings,
  FrameOptionsSettings,
  HSTSSettings,
  HTTPSecuritySettings,
  PermittedCrossDomainPoliciesSettings,
  ReferrerPolicySettings,
  XSSSettings,
} from '@agilicus/angular';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { ModeOptions } from '@app/core/models/security/mode-options.enum';
import { SecuritySettings } from '@app/core/models/security/security-settings.enum';
import { cloneDeep } from 'lodash-es';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import {
  getCoepModeOptions,
  getContentTypeOptionsModeOptions,
  getCoopModeOptions,
  getCorpModeOptions,
  getFormatedMode,
  getFrameOptionsModeOptions,
  getPermittedCrossDomainPoliciesModeOptions,
  getReferrerPolicyModeOptions,
} from '../security-utils';
import { createEnumChecker } from '../utils';

export interface GenericSecuritySettings {
  enabled: boolean;
  mode: string;
  override?: string | null;
}

export interface SecurityHeaderModeOption {
  modeName: ModeOptions;
  modeDescription: string;
}

export interface GenericSecurityDefinition {
  panelName: string;
  type: SecuritySettings;
  settings: GenericSecuritySettings;
  modeOptions: Array<SecurityHeaderModeOption>;
}

export type GenericSecurityHeader =
  | FrameOptionsSettings
  | ContentTypeOptionsSettings
  | PermittedCrossDomainPoliciesSettings
  | CrossOriginEmbedderPolicySettings
  | CrossOriginOpenerPolicySettings
  | CrossOriginResourcePolicySettings
  | ReferrerPolicySettings;

@Component({
  selector: 'portal-security-headers',
  templateUrl: './security-headers.component.html',
  styleUrls: ['./security-headers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecurityHeadersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public currentSecurityHeadersCopy: HTTPSecuritySettings;
  @Output() public updateSecurityHeaders = new EventEmitter<any>();
  public hstsSettingsForm: UntypedFormGroup;
  public xssProtectionForm: UntypedFormGroup;
  public certificateTransparencyForm: UntypedFormGroup;
  public genericSecurityHeaderSettingsList: Array<GenericSecurityDefinition> = [];
  /**
   * A mapping of a panel to whether that panel is open.
   */
  private panelsStateMap: Map<SecuritySettings, boolean> = new Map();
  public securitySettings = SecuritySettings;
  public xssModeOptions = [
    {
      modeName: ModeOptions.block,
      modeDescription: 'Filtering is enabled and attached pages are not rendered',
    },
    {
      modeName: ModeOptions.disabled,
      modeDescription: 'Filtering is disabled',
    },
    {
      modeName: ModeOptions.sanitise,
      modeDescription: 'Filtering is enabled. Pages being attached are sanitised.',
    },
  ];
  public securityHeadersDescriptiveText = `Here you may configure overrides of various HTTP security headers. 
  See the product guide for more information on their values.`;
  public webAppSecurityProductGuideLink = `https://www.agilicus.com/anyx-guide/web-application-security/`;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getFormatedMode = getFormatedMode;

  // Expansion panels
  @ViewChild(MatAccordion) public accordion: MatAccordion;

  constructor(private formBuilder: UntypedFormBuilder, private changeDetector: ChangeDetectorRef) {
    this.setPanelsStateMapToClosed();
  }

  public ngOnInit(): void {
    this.initializeFormGroups();
  }

  public ngOnChanges(): void {
    if (!this.currentSecurityHeadersCopy) {
      return;
    }
    this.setGenericSecurityHeaderSettingsList();
    this.setFormGroupValues();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private initializeFormGroups(): void {
    this.initializeHstsSettingsFormGroup();
    this.initializeXssProtectionFormGroup();
    this.initializeCertificateTransparencyFormGroup();
    this.changeDetector.detectChanges();
  }

  private setFormGroupValues(): void {
    this.setHstsSettingsFormGroupValues();
    this.setXssProtectionFormGroupValues();
    this.setCertificateTransparencyFormGroupValues();
  }

  private initializeHstsSettingsFormGroup(): void {
    if (!!this.hstsSettingsForm) {
      return;
    }
    this.hstsSettingsForm = this.formBuilder.group({
      enabled: [false, [Validators.required]],
      max_age_seconds: [0, [Validators.required]],
      include_sub_domains: [false, [Validators.required]],
      preload: [false, [Validators.required]],
    });
  }

  private setHstsSettingsFormGroupValues(): void {
    this.initializeHstsSettingsFormGroup();
    this.hstsSettingsForm.get('enabled').setValue(this.currentSecurityHeadersCopy.hsts?.enabled);
    this.hstsSettingsForm
      .get('max_age_seconds')
      .setValue(!!this.currentSecurityHeadersCopy.hsts.max_age_seconds ? this.currentSecurityHeadersCopy.hsts.max_age_seconds : 0);
    this.hstsSettingsForm.get('include_sub_domains').setValue(this.currentSecurityHeadersCopy.hsts?.include_sub_domains);
    this.hstsSettingsForm.get('preload').setValue(this.currentSecurityHeadersCopy.hsts?.preload);
    this.changeDetector.detectChanges();
  }

  private initializeXssProtectionFormGroup(): void {
    if (!!this.xssProtectionForm) {
      return;
    }
    this.xssProtectionForm = this.formBuilder.group({
      enabled: [false, [Validators.required]],
      mode: [XSSSettings.ModeEnum.disabled, [Validators.required]],
      report_uri: '',
    });
  }

  private setXssProtectionFormGroupValues(): void {
    this.initializeXssProtectionFormGroup();
    this.xssProtectionForm.get('enabled').setValue(this.currentSecurityHeadersCopy.xss_protection?.enabled);
    this.xssProtectionForm.get('mode').setValue(this.currentSecurityHeadersCopy.xss_protection?.mode);
    this.xssProtectionForm.get('report_uri').setValue(this.currentSecurityHeadersCopy.xss_protection?.report_uri);
    this.changeDetector.detectChanges();
  }

  private initializeCertificateTransparencyFormGroup(): void {
    if (!!this.certificateTransparencyForm) {
      return;
    }
    this.certificateTransparencyForm = this.formBuilder.group({
      enabled: [false, [Validators.required]],
      enforce: [false, [Validators.required]],
      max_age_seconds: [0, [Validators.required]],
      report_uri: '',
    });
  }

  private setCertificateTransparencyFormGroupValues(): void {
    this.initializeCertificateTransparencyFormGroup();
    this.certificateTransparencyForm.get('enabled').setValue(this.currentSecurityHeadersCopy.certificate_transparency?.enabled);
    this.certificateTransparencyForm.get('enforce').setValue(this.currentSecurityHeadersCopy.certificate_transparency?.enforce);
    this.certificateTransparencyForm
      .get('max_age_seconds')
      .setValue(this.currentSecurityHeadersCopy.certificate_transparency?.max_age_seconds);
    this.certificateTransparencyForm.get('report_uri').setValue(this.currentSecurityHeadersCopy.certificate_transparency?.report_uri);
    this.changeDetector.detectChanges();
  }

  private setPanelsStateMapToClosed(): void {
    for (const value of Object.keys(SecuritySettings)) {
      const isSecuritySettingsEnum = createEnumChecker(SecuritySettings);
      if (isSecuritySettingsEnum(value)) {
        this.panelsStateMap.set(value, false);
      }
    }
  }

  private setGenericSecurityHeaderSettingsList(): void {
    this.genericSecurityHeaderSettingsList = [
      {
        panelName: 'Frame Options',
        type: SecuritySettings.frame_options,
        settings: this.currentSecurityHeadersCopy.frame_options,
        modeOptions: getFrameOptionsModeOptions(),
      },
      {
        panelName: 'Content Type Options',
        type: SecuritySettings.content_type_options,
        settings: this.currentSecurityHeadersCopy.content_type_options,
        modeOptions: getContentTypeOptionsModeOptions(),
      },
      {
        panelName: 'Permitted Cross Domain Policies',
        type: SecuritySettings.permitted_cross_domain_policies,
        settings: this.currentSecurityHeadersCopy.permitted_cross_domain_policies,
        modeOptions: getPermittedCrossDomainPoliciesModeOptions(),
      },
      {
        panelName: 'Cross Origin Embedder Policy',
        type: SecuritySettings.coep,
        settings: this.currentSecurityHeadersCopy.coep,
        modeOptions: getCoepModeOptions(),
      },
      {
        panelName: 'Cross Origin Opener Policy',
        type: SecuritySettings.coop,
        settings: this.currentSecurityHeadersCopy.coop,
        modeOptions: getCoopModeOptions(),
      },
      {
        panelName: 'Cross Origin Resource Policy',
        type: SecuritySettings.corp,
        settings: this.currentSecurityHeadersCopy.corp,
        modeOptions: getCorpModeOptions(),
      },
      {
        panelName: 'Referrer Policy',
        type: SecuritySettings.referrer_policy,
        settings: this.currentSecurityHeadersCopy.referrer_policy,
        modeOptions: getReferrerPolicyModeOptions(),
      },
    ];
  }

  public onGenericSecurityHeaderChange(params: { type: string; updatedGenericSecurityHeader: GenericSecurityHeader }): void {
    const copyOfCurrentSecurityHeadersCopy = cloneDeep(this.currentSecurityHeadersCopy);
    copyOfCurrentSecurityHeadersCopy[params.type] = params.updatedGenericSecurityHeader;
    this.currentSecurityHeadersCopy = copyOfCurrentSecurityHeadersCopy;
    this.updateSecurityHeaders.emit(this.currentSecurityHeadersCopy);
  }

  public onPanelOpen(panel: SecuritySettings): void {
    this.panelsStateMap.set(panel, true);
  }

  public onPanelClose(panel: SecuritySettings): void {
    this.panelsStateMap.set(panel, false);
  }

  public getPanelState(panel: SecuritySettings): boolean {
    return this.panelsStateMap.get(panel);
  }

  private getHstsSettingsFromForm(): HSTSSettings {
    return {
      enabled: this.hstsSettingsForm.get('enabled').value,
      max_age_seconds: this.hstsSettingsForm.get('max_age_seconds').value,
      include_sub_domains: this.hstsSettingsForm.get('include_sub_domains').value,
      preload: this.hstsSettingsForm.get('preload').value,
    };
  }

  private getXssProtectionFromForm(): XSSSettings {
    return {
      enabled: this.xssProtectionForm.get('enabled').value,
      mode: this.xssProtectionForm.get('mode').value,
      report_uri: this.xssProtectionForm.get('report_uri').value,
    };
  }

  private getCertificateTransparencyFromForm(): CertificateTransparencySettings {
    return {
      enabled: this.certificateTransparencyForm.get('enabled').value,
      enforce: this.certificateTransparencyForm.get('enforce').value,
      max_age_seconds: this.certificateTransparencyForm.get('max_age_seconds').value,
      report_uri: this.certificateTransparencyForm.get('report_uri').value,
    };
  }

  private updateSecurityHeadersFromForms(): void {
    const copyOfCurrentSecurityHeadersCopy = cloneDeep(this.currentSecurityHeadersCopy);
    copyOfCurrentSecurityHeadersCopy.hsts = this.getHstsSettingsFromForm();
    copyOfCurrentSecurityHeadersCopy.xss_protection = this.getXssProtectionFromForm();
    copyOfCurrentSecurityHeadersCopy.certificate_transparency = this.getCertificateTransparencyFromForm();
    this.currentSecurityHeadersCopy = copyOfCurrentSecurityHeadersCopy;
    this.updateSecurityHeaders.emit(this.currentSecurityHeadersCopy);
  }

  public onCheckboxChange(): void {
    this.updateSecurityHeadersFromForms();
  }

  public onFormBlur(form: UntypedFormGroup, formField: string): void {
    if (form.controls[formField].invalid) {
      return;
    }
    // Do not proceed if the value has not been changed.
    if (!form.controls[formField].dirty) {
      return;
    }
    this.updateSecurityHeadersFromForms();
  }

  public onXssModeOptionChange(value: XSSSettings.ModeEnum): void {
    const modeFormControl = this.xssProtectionForm.get('mode');
    modeFormControl.setValue(value);
    const isModeFormControlValid = modeFormControl.valid;
    if (!isModeFormControlValid) {
      return;
    }
    this.updateSecurityHeadersFromForms();
  }
}
