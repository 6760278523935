import { EnvironmentConfigVarList } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import { getEnvConfigVarsList, createOrUpdateEnvConfigVarsList } from '../api-applications/api-applications-utils';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import { EnvironmentConfigService } from '../services/environment-config/environment-config.service';
import * as EnvironmentConfigVarActions from '../api-applications/api-applications.actions';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigVarStateService extends SimpleCRUDInterface<EnvironmentConfigVarList, string> {
  public crudRegistryName = CrudStateCollection.environment_config_var;

  constructor(
    public store: Store<AppState>,
    private environmentConfigService: EnvironmentConfigService,
    private crudRegistryService: CrudRegistryService<EnvironmentConfigVarList, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<EnvironmentConfigVarList, string> = {
        create: (id: string, obj: EnvironmentConfigVarList, orgId: string) =>
          createOrUpdateEnvConfigVarsList(
            this.environmentConfigService,
            this.getAppIdFromEnvConfigVarGuid(id),
            this.getEnvNameFromEnvConfigVarGuid(id),
            orgId,
            obj
          ),
        update: (id: string, obj: EnvironmentConfigVarList, orgId: string) =>
          createOrUpdateEnvConfigVarsList(
            this.environmentConfigService,
            this.getAppIdFromEnvConfigVarGuid(id),
            this.getEnvNameFromEnvConfigVarGuid(id),
            orgId,
            obj
          ),
        get: (id: string, orgId: string) =>
          getEnvConfigVarsList(
            this.environmentConfigService,
            this.getAppIdFromEnvConfigVarGuid(id),
            this.getEnvNameFromEnvConfigVarGuid(id),
            orgId
          ),
        // the list is never deleted; it's set to empty instead
        delete: null,
        list: null,
      };
      const crudRegistry: CrudRegistry<EnvironmentConfigVarList, string> = {
        getNameFromObject: (obj: EnvironmentConfigVarList) => '',
        crudInterface: apiInterface,
        doneSaveAction: EnvironmentConfigVarActions.upsertEnvConfigVar,
        setListAction: EnvironmentConfigVarActions.loadEnvConfigVars,
        refreshStateAction: EnvironmentConfigVarActions.refreshEnvConfigVars,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }

  private getAppIdFromEnvConfigVarGuid(guid: string): string {
    return guid.split('_')[0];
  }

  private getEnvNameFromEnvConfigVarGuid(guid: string): string {
    return guid.split('_')[1];
  }
}
