import { createSelector } from '@ngrx/store';
import { selectRawPermissionsSelector } from './permissions.selectors';
import { OrgQualifiedPermissions } from './permissions.selectors';
import { makePermission } from './utils';

export const selectCanReadOrgs = createSelector(selectRawPermissionsSelector, (state: OrgQualifiedPermissions) =>
  makePermission(state.orgId, state.usersRoles, ['owner', 'reader'])
);

export const selectCanAdminOrgs = createSelector(selectRawPermissionsSelector, (state: OrgQualifiedPermissions) =>
  makePermission(state.orgId, state.usersRoles, ['owner'])
);

export const selectCanAdminOrReadOrgs = selectCanReadOrgs;
