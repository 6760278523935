import { Injectable } from '@angular/core';
import { ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { CRUD } from './state-driven-crud';

export interface CrudRegistry<DataType, IDType> {
  crudInterface: CRUD<DataType, IDType>;
  getNameFromObject: (obj: DataType) => string;
  doneSaveAction: ActionCreator<
    string,
    (props: { obj: DataType; refreshData?: boolean }) => {
      obj: DataType;
      refreshData?: boolean;
    } & TypedAction<string>
  >;
  refreshStateAction: ActionCreator<string, () => TypedAction<string>>;
  doneSaveListAction?: ActionCreator<
    string,
    (props: { objs: Array<DataType>; refreshData?: boolean }) => {
      objs: Array<DataType>;
      refreshData?: boolean;
    } & TypedAction<string>
  >;
  doneDeleteAction?: ActionCreator<
    string,
    (props: { id: IDType; obj: DataType }) => {
      id: IDType;
      obj: DataType;
    } & TypedAction<string>
  >;
  doneDeleteListAction?: ActionCreator<
    string,
    (props: { ids: Array<IDType> }) => {
      ids: Array<IDType>;
    } & TypedAction<string>
  >;
  setListAction?: ActionCreator<
    string,
    (props: { objs: Array<DataType>; org_id: string; blankSlate: boolean | undefined }) => {
      objs: Array<DataType>;
      org_id: string;
      blankSlate: boolean | undefined;
    } & TypedAction<string>
  >;
  customSanitiseObjectConflictsFunc?: (desiredObj: DataType, latestObjFromApi: DataType) => DataType;
}

@Injectable({
  providedIn: 'root',
})
export class CrudRegistryService<DataType, IDType> {
  public crudRegistry: Map<string, CrudRegistry<DataType, IDType>> = new Map();

  constructor() {}
}
