<portal-page-info
  [pageDescriptiveText]="securityHeadersDescriptiveText"
  [productGuideLink]="webAppSecurityProductGuideLink"
  pageInfoWidth="full"
>
</portal-page-info>
        
<mat-accordion class="expansion-container" multi>
  <mat-expansion-panel
    [expanded]="getPanelState(securitySettings.hsts)"
    (opened)="onPanelOpen(securitySettings.hsts)"
    (closed)="onPanelClose(securitySettings.hsts)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        HTTP Strict Transport Security (HSTS)
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div
      *ngIf="getPanelState(securitySettings.hsts)"
      class="form-container"
    >
      <form
        [formGroup]="hstsSettingsForm"
        spellcheck="false"
        autocomplete="off"
      >
        <div class="checkbox-container">
          <mat-checkbox
            formControlName="enabled"
            (change)="onCheckboxChange()"
            aria-label="Checkbox that toggles whether the security header option is enabled"
          >
            Enable
          </mat-checkbox>
          <mat-icon 
            class="help-icon" 
            matTooltip="Whether or not to send the HSTS header. If disabled, any HSTS headers set by the application will be passed through unchanged."
          >
            help_outline
          </mat-icon>
        </div>

        <div class="checkbox-container">
          <mat-checkbox
            formControlName="include_sub_domains"
            (change)="onCheckboxChange()"
            aria-label="Checkbox that toggles whether to include sub domains"
          >
            Include Subdomains
          </mat-checkbox>
          <mat-icon 
            class="help-icon" 
            matTooltip="Whether subdomains of the Application should be included in the HSTS restriction"
          >
            help_outline
          </mat-icon>
        </div>
      
        <div class="checkbox-container">
          <mat-checkbox
            formControlName="preload"
            (change)="onCheckboxChange()"
            aria-label="Checkbox that toggles whether the security header option is enabled"
          >
            Preload
          </mat-checkbox>
          <mat-icon 
            class="help-icon" 
            matTooltip="Indicate to HSTS preload services that this application should be preloaded"
          >
            help_outline
          </mat-icon>
        </div>
      
        <mat-form-field class="extended-field">
          <input
            matInput
            type="text"
            placeholder="Max age (in seconds)"
            formControlName="max_age_seconds"
            (blur)="onFormBlur(hstsSettingsForm, 'max_age_seconds')"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          />
        </mat-form-field>
        <mat-icon 
          class="help-icon" 
          matTooltip="The number of seconds for which to remember that accesses need to be over HTTPS. A value of 0 means that the browser should forget."
        >
          help_outline
        </mat-icon>
      </form>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    [expanded]="getPanelState(securitySettings.xss_protection)"
    (opened)="onPanelOpen(securitySettings.xss_protection)"
    (closed)="onPanelClose(securitySettings.xss_protection)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          XSS Protection
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        *ngIf="getPanelState(securitySettings.xss_protection)"
        class="form-container"
      >
        <form
          [formGroup]="xssProtectionForm"
          spellcheck="false"
          autocomplete="off"
        >
          <div class="checkbox-container">
            <mat-checkbox
              formControlName="enabled"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether the security header option is enabled"
            >
              Enable
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              matTooltip="Whether or not to send the XSS-Protection-Header. If false, the system will not add the XSS-Protection-Header. Any headers added by the application will be passed through unchanged."
            >
              help_outline
            </mat-icon>
          </div>

          <div class="mode-container">
            <div class="radio-button-label-container">
              <label
                id="mode-radio-group-label"
                class="radio-button-group-label"
              >
                Mode:
              </label>
              <mat-icon 
                class="help-icon" 
                matTooltip="Controls whether or not XSS filtering is enabled and how it is enabled"
              >
                help_outline
              </mat-icon>
            </div>
            <mat-radio-group
              formControlName="mode"
              aria-label="mode selection radio buttons"
              class="mode-radio-group"
            >
              <div
                *ngFor="let value of xssModeOptions"
                class="shared-line-radio-button"
              >
                <mat-radio-button
                  class="mode-radio-button"
                  [value]="value.modeName"
                  (change)="onXssModeOptionChange(value.modeName)"
                >
                  {{ getFormatedMode(value.modeName) }}
                </mat-radio-button>
                <mat-icon 
                  class="help-icon" 
                  [matTooltip]="value.modeDescription"
                >
                  help_outline
                </mat-icon>
              </div>
            </mat-radio-group>
          </div>
        
          <mat-form-field class="extended-field">
            <input
              matInput
              type="text"
              placeholder="Report URI"
              formControlName="report_uri"
              (blur)="onFormBlur(xssProtectionForm, 'report_uri')"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            />
          </mat-form-field>
          <mat-icon 
            class="help-icon" 
            matTooltip="Detected attacks are reported to this URI for supported browsers. Omitting this field or setting it to `\` will lead to no reports being generated"
          >
            help_outline
          </mat-icon>
        </form>
      </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    [expanded]="getPanelState(securitySettings.certificate_transparency)"
    (opened)="onPanelOpen(securitySettings.certificate_transparency)"
    (closed)="onPanelClose(securitySettings.certificate_transparency)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Certificate Transparency
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        *ngIf="getPanelState(securitySettings.certificate_transparency)"
        class="form-container"
      >
        <form
          [formGroup]="certificateTransparencyForm"
          spellcheck="false"
          autocomplete="off"
        >
          <div class="checkbox-container">
            <mat-checkbox
              formControlName="enabled"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether the security header option is enabled"
            >
              Enable
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              matTooltip="Whether to apply the Certificate Transparency Settings. If false, no settings will be applied. Settings applied by the application itself will take effect."
            >
              help_outline
            </mat-icon>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="enforce"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether to set the enforce option"
            >
              Enforce
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              matTooltip="Whether or not certificate transparency failures cause the browser to take action"
            >
              help_outline
            </mat-icon>
          </div>
        
          <mat-form-field class="extended-field">
            <input
              matInput
              type="text"
              placeholder="Max age (in seconds)"
              formControlName="max_age_seconds"
              (blur)="onFormBlur(certificateTransparencyForm, 'max_age_seconds')"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            />
          </mat-form-field>
          <mat-icon 
            class="help-icon" 
            matTooltip="The number of seconds for which to remember that the Application expects certificate transparency"
          >
            help_outline
          </mat-icon>

          <mat-form-field class="extended-field">
            <input
              matInput
              type="text"
              placeholder="Report URI"
              formControlName="report_uri"
              (blur)="onFormBlur(certificateTransparencyForm, 'report_uri')"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            />
          </mat-form-field>
          <mat-icon 
            class="help-icon" 
            matTooltip="The uri where the browser will report failures. Setting this to `null` or `\` will prevent reports."
          >
            help_outline
          </mat-icon>
        </form>
      </div>
  </mat-expansion-panel>

  <mat-expansion-panel
    *ngFor="let setting of genericSecurityHeaderSettingsList"
    [expanded]="getPanelState(setting.type)"
    (opened)="onPanelOpen(setting.type)"
    (closed)="onPanelClose(setting.type)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ setting.panelName }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div
        *ngIf="getPanelState(setting.type)"
        class="form-container"
      >
        <portal-security-header-generic-form
          [genericSecurityHeaderSettings]="setting"
          (genericSecurityHeaderChange)="onGenericSecurityHeaderChange($event)"
        >
        </portal-security-header-generic-form>
      </div>
  </mat-expansion-panel>
</mat-accordion>

