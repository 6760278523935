import { AlertsState, ExpansionPanelsState, NavMenuState, TabsState } from '../models/ui/ui-model';

export interface UIState {
  expansionPanelsState: ExpansionPanelsState;
  tabsState: TabsState;
  navMenuState: NavMenuState;
  version: string;
  alertsState: AlertsState;
  /**
   * We increment this number by 1 when we want to refresh the data on a page.
   */
  refresh_data: number;
}

export enum AppDefineExpansionPanel {
  appDefineInstancesPanel = 'appDefineInstancesPanel',
  appDefineBundlesPanel = 'appDefineBundlesPanel',
  appDefineApplicationRolesPanel = 'appDefineApplicationRolesPanel',
  appDefineFirewallRulesPanel = 'appDefineFirewallRulesPanel',
  appDefineWebApplicationSecurityPanel = 'appDefineWebApplicationSecurityPanel',
  appDefineHttpRewritesPanel = 'appDefineHttpRewritesPanel',
  appDefineProxiedServiceConfigPanel = 'appDefineProxiedServiceConfigPanel',
  appDefineRewriteMediaTypesPanel = 'appDefineRewriteMediaTypesPanel',
  appDefineRewriteRulesPanel = 'appDefineRewriteRulesPanel',
  appDefineResponseHeaderOverridesPanel = 'appDefineResponseHeaderOverridesPanel',
  appDefineRequestHeaderOverridesPanel = 'appDefineRequestHeaderOverridesPanel',
  appDefineAuthenticationPanel = 'appDefineAuthenticationPanel',
  appDefineSecurityHeadersPanel = 'appDefineSecurityHeadersPanel',
  appDefineContentSecurityPolicyPanel = 'appDefineContentSecurityPolicyPanel',
  appDefineCorsPanel = 'appDefineCorsPanel',
}

export type ExpansionPanelType = AppDefineExpansionPanel;

export enum TabGroup {
  appDefineTabGroup = 'appDefineTabGroup',
  appConfigureInstanceTabGroup = 'appConfigureInstanceTabGroup',
  connectorInstallTabGroup = 'connectorInstallTabGroup',
}
