<portal-page-info
  pageTitle="Identity Providers"
  [productGuideLink]="productGuideLink"
  [pageDescriptiveTextWithImageWrap]="pageDescriptiveTextWithImageWrap"
  [pageDescriptiveHelpImageWithTextWrap]="pageDescriptiveHelpImageWithTextWrap"
>
</portal-page-info>

<div class="authentication-group">

  <div *ngIf="hasIssuersPermissions !== undefined && !hasIssuersPermissions">
    <portal-no-permissions></portal-no-permissions>
  </div>
  
  <div *ngIf="hasIssuersPermissions">
    <div *ngIf="currentOrgIssuer === undefined && issuerCopy === undefined" class="no-info">
      <h2>No issuer information available.</h2>
    </div>
  
    <div *ngIf="currentOrgIssuer !== undefined" class="issuer-url title">
      <h5>Issuer: {{ currentOrgIssuer }}</h5>
    </div>
  
    <div *ngIf="issuerCopy !== undefined" class="table-outer-container">

      <div class="oidc-providers">
        <portal-table-layout
          class="table-template"
          [tableData]="tableData"
          [columnDefs]="columnDefs"
          [filterManager]="filterManager"
          [makeEmptyTableElement]="makeEmptyTableElementFunc"
          [rowObjectName]="rowObjectName"
          [buttonsToShow]="buttonsToShow"
          [customButtons]="customButtons"
          (updateEvent)="updateEvent()"
          (updateAutoInput)="updateAutoInput($event)"
          (removeSelected)="deleteSelected()"
        >
        </portal-table-layout>
      </div>
    </div>
  </div>
</div>