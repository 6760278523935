<portal-page-info
  pageTitle="New Connector"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasConnectorPermissions !== undefined && !hasConnectorPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasConnectorPermissions && !!allForms"
  class="stepper-container"
>
  <mat-vertical-stepper
    linear="true"
    #stepper
    [ngClass]="{'disable-header-navigation': disableStepperNavigation()}"
  >
    <!-- Commented the below code as we will revisit this in the future -->
    <!-- <mat-step errorMessage="Invalid connector type" [completed]="isConnectorTypeStepValid()">
      <form [formGroup]="connectorTypeForm">
        <ng-template matStepLabel>Connector Type</ng-template>
        <body>
          I expose my local services using:
          <mat-radio-group
            formControlName="type"
            aria-labelledby="connector type radio buttons"
            class="column-radio-group"
            (change)="onConnectorTypeChange()"
          >
            <mat-radio-button
              [value]="connectorTypeEnum.agent"
            >
              An outbound connector
            </mat-radio-button>
            <mat-radio-button
              [value]="connectorTypeEnum.ipsec"
            >
              A point-to-point VPN
            </mat-radio-button>
          </mat-radio-group>

          <div *ngIf="connectorTypeForm.value.type === connectorTypeEnum.ipsec">
            <portal-connection-stepper
              [vpnConnectorForm]="vpnConnectorForm"
              [connectorTypeChanged]="connectorTypeChanged"
              (resetConnectorTypeChanged)="resetConnectorTypeChanged()"
            >
            </portal-connection-stepper>
          </div>

        </body>
        <div class="nav-buttons">
          <button mat-button matStepperNext>Next</button>
        </div>
      </form>
    </mat-step> -->
    <mat-step errorMessage="Invalid connector name" [completed]="connectorCreated">
      <form
        [formGroup]="connectorSpecForm"
        spellcheck="false"
        autocomplete="off"
      >
        <ng-template matStepLabel>Connector name</ng-template>
        <body>
          <portal-stepper-form-field
            placeholder="My connector will be called..."
            [formCtrl]="connectorSpecForm.get('name')"
            required="true"
            tooltip="This name must be unique across all connectors."
          >
          </portal-stepper-form-field>
        </body>
      </form>
      <div class="nav-buttons">
        <button 
          mat-raised-button
          color="primary"
          matTooltip="Click to create the {{ getConnectorType() }} connector"
          attr.aria-label="A button to create the {{ getConnectorType() }} connector"
          [disabled]="!connectorSpecForm.valid"
          (click)="createConnector()"
        >
          Create
        </button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div *ngIf="connectorTypeForm.value.type === connectorTypeEnum.agent">
        <body>The connector "{{ getConnectorNameFromForm() }}" has been created. You can view a list of 
        all connectors in this organisation on the overview page (Resources > Connectors > Overview).</body>
        <div>
          <button
            matSuffix
            mat-raised-button
            color="primary"
            matTooltip="Install the Agilicus Connector"
            class="download-buttons"
            (click)="openDownloadDialog()"
          >
            Install Connector
          </button>
        </div>
      </div>

      <div *ngIf="connectorTypeForm.value.type === connectorTypeEnum.ipsec">
        <div class=user-instructions-text>
          <p>You may check the status of your VPN connector with name "{{ getConnectorNameFromForm() }}" using the "Overview" sub-menu under the "Connectors" tab on the left.</p>
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>

  <portal-demo-setup
    hideDeleteButton="true"
    [tourAnchorId]="tourAnchorId"
    [blockDemoTour]="blockDemoTour"
    (startTourInParent)="startDemoTour()"
    (startedDemoCreation)="onDemoCreationStart()"
  >
  </portal-demo-setup>
</div>
