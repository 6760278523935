import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[portalPreventStepperAdvance]',
})
export class PreventStepperAdvanceDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) private onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
}
