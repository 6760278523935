import { Action } from '@ngrx/store';
import { EnvironmentState } from './environment.model';

export enum EnvironmentActionTypes {
  ENVIRONMENT_LOADED = '[Environment] EnvironmentLoaded',
}

export class ActionEnvironmentSetState implements Action {
  public readonly type = EnvironmentActionTypes.ENVIRONMENT_LOADED;
  public readonly current_environment: EnvironmentState;
  constructor(current_environment: EnvironmentState) {
    this.current_environment = current_environment;
  }
}

export type EnvironmentActions = ActionEnvironmentSetState;
