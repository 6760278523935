import { Component, OnInit, ViewChild, OnDestroy, Renderer2 } from '@angular/core';
import { ListTopUsersRequestParams, MetricsService, Resource, ResourcesService, UserMetrics } from '@agilicus/angular';
import { forkJoin, Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { takeUntil, concatMap, take } from 'rxjs/operators';
import { Application } from '@agilicus/angular';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { FilterManager } from '../filter/filter-manager';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { Subject, of } from 'rxjs';
import { ApplicationsService } from '@agilicus/angular';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectCanReadMetrics } from '@app/core/user/permissions/metrics.selectors';
import { selectCanReadApps } from '@app/core/user/permissions/app.selectors';
import { fillOptionalDataFromForm, getEnumValuesAsStringArray, getUniqueNamesList } from '../utils';
import { getStartDateMaxSetter, getEndDateMinSetter } from '../date-utils';
import { getPageSizeOptions } from '@app/shared/utilities/tables/pagination';
import { OrgQualifiedPermission, createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { DiagnosticDateRange } from '../diagnostic-types';
import { FilterChipOptions } from '../filter-chip-options';
import { getApplicationsForMetrics, getFilteredOptions, getResoucesForMetrics, getResourceFilterList } from '../metrics-utils';
import { ResourceType } from '../resource-type.enum';
import { MapObject, UnparseData } from 'ngx-papaparse/lib/interfaces/unparse-data';

export interface MetricsFile {
  User: string;
  '# Sessions': number;
}

export interface GraphData {
  name: string;
  value: number;
}

@Component({
  selector: 'portal-metrics-top-users',
  templateUrl: './metrics-top-users.component.html',
  styleUrls: ['./metrics-top-users.component.scss'],
})
export class MetricsTopUsersComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public org_id: string;
  public applications: Array<Application>;
  public resources: Array<Resource>;
  public resourceNameList: Array<string> = [];
  public graphDataSource: Array<GraphData>;
  public tableDataSource: MatTableDataSource<UserMetrics> = new MatTableDataSource();
  private permissions$: Observable<OrgQualifiedPermission>;
  public hasPermissions: boolean;
  public maxDate: Date = new Date();
  public topMetricsForm: UntypedFormGroup;
  public displayedMetricsColumns: Array<string> = ['email', 'count'];
  public filteredAppOptions: Observable<Array<string>>;
  public filteredResourceOptions: Observable<Array<string>>;
  public resourceTypesList = getEnumValuesAsStringArray(ResourceType);
  public pageDescriptiveText = `View usage metrics of top end users`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/usage-metrics/#h-top-users`;

  public filterChipOptions: FilterChipOptions = {
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  public filterManager: FilterManager = new FilterManager();

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getStartDateMaxSetter = getStartDateMaxSetter;
  public getEndDateMinSetter = getEndDateMinSetter;

  @ViewChild('metricsTableSort', { static: false }) public metricsTableSort: MatSort;
  @ViewChild(MatPaginator, { static: false }) public paginator: MatPaginator;

  // Graph options
  public showXAxis = true;
  public showYAxis = true;
  public gradient = false;
  public showLegend = true;
  public showXAxisLabel = true;
  public xAxisLabel = 'User';
  public showYAxisLabel = true;
  public yAxisLabel = '# Sessions';

  constructor(
    private topMetricsService: MetricsService,
    private formBuilder: UntypedFormBuilder,
    private papa: Papa,
    private store: Store<AppState>,
    private renderer: Renderer2,
    private applicationsService: ApplicationsService,
    private resourcesService: ResourcesService
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.permissions$ = this.getPermissions();
    this.permissions$
      .pipe(
        takeUntil(this.unsubscribe$),
        concatMap((permissions) => {
          this.org_id = permissions.orgId;
          this.hasPermissions = permissions.hasPermission;
          if (!this.org_id || !this.hasPermissions) {
            return of([]);
          }
          const applications$ = getApplicationsForMetrics(this.org_id, this.applicationsService);
          const resources$ = getResoucesForMetrics(this.org_id, this.resourcesService);
          return forkJoin([applications$, resources$]);
        })
      )
      .subscribe(([appsResp, resourceResp]) => {
        this.applications = appsResp;
        this.resources = resourceResp;
        this.resourceNameList = this.getCombinedAppsAndResourcesNamesList();
        this.setResourceAutocomplete();
        this.graphDataSource = [];
        this.tableDataSource.data = [];
        if (appsResp?.length && resourceResp?.length) {
          const today = new Date();
          this.topMetricsForm.value.dtTo = today;
          this.topMetricsForm.value.dtFrom = new Date(new Date(today).setDate(today.getDate() - 30));
          this.listTopUsers();
        }
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getCombinedAppsAndResourcesNamesList(): Array<string> {
    if (!this.applications || !this.resources) {
      return [];
    }
    const appNames = this.applications.map((app) => app.name);
    const resourceNames = this.resources.map((app) => app.spec.name);
    return getUniqueNamesList([...appNames, ...resourceNames]);
  }

  private initializeFormGroup(): void {
    this.topMetricsForm = this.formBuilder.group({
      dtFrom: null,
      dtTo: null,
      resource_name: '',
      resource_type: '',
    });
  }

  private getPermissions(): Observable<OrgQualifiedPermission> {
    return this.store.pipe(select(createCombinedPermissionsSelector(selectCanReadApps, selectCanReadMetrics)));
  }

  public setStartAndEndDates(): DiagnosticDateRange {
    const targetDates: DiagnosticDateRange = {
      startDate: null,
      endDate: null,
    };
    targetDates.startDate = this.topMetricsForm.value.dtFrom;
    targetDates.endDate = this.topMetricsForm.value.dtTo;
    return targetDates;
  }

  private setResourceAutocomplete(): void {
    this.filteredResourceOptions = getFilteredOptions(this.resourceNameList, 'resource_name', this.topMetricsForm);
  }

  public getTopMetricsFilter(): ListTopUsersRequestParams {
    const intervalParam = 300;
    const limitParam = 500;
    const topMetricsFilter: ListTopUsersRequestParams = {
      org_id: this.org_id,
      interval: intervalParam,
      limit: limitParam,
    };
    fillOptionalDataFromForm(topMetricsFilter, this.topMetricsForm, getResourceFilterList());
    const targetDates = this.setStartAndEndDates();
    if (targetDates.startDate !== null) {
      topMetricsFilter.dt_from = targetDates.startDate.toISOString();
    }
    if (targetDates.endDate !== null) {
      topMetricsFilter.dt_to = targetDates.endDate.toISOString();
    }
    return topMetricsFilter;
  }

  private getGraphData(data: UserMetrics[]): Array<GraphData> {
    if (data.length <= 30) {
      return data.map((metric) => ({ name: metric.email, value: metric.count }));
    } else {
      // Display top 30 users and combine others in a remainder category
      // (data is already sorted in descending order by the metrics api).
      const graphData = [];
      for (let i = 0; i < 30; i++) {
        graphData.push({ name: data[i].email, value: data[i].count });
      }
      let remainder = 0;
      for (let j = 30; j < data.length; j++) {
        remainder += data[j].count;
      }
      graphData.push({ name: 'Remainder', value: remainder });
      return graphData;
    }
  }

  public listTopUsers(): void {
    const topMetricsFilter = this.getTopMetricsFilter();
    this.topMetricsService
      .listTopUsers(topMetricsFilter)
      .pipe(take(1))
      .subscribe((metricsResp) => {
        this.graphDataSource = this.getGraphData(metricsResp.top_users);
        this.tableDataSource = new MatTableDataSource(metricsResp.top_users);
        this.tableDataSource.sort = this.metricsTableSort;
        this.tableDataSource.paginator = this.paginator;
        this.filterManager.createFilterPredicate(this.tableDataSource, this.displayedMetricsColumns);
      });
  }

  /**
   * Renames the columns so the csv will match the table.
   */
  private reformatMetricsData(data: Array<UserMetrics>): UnparseData {
    const metricsData: Array<Array<string>> = [];
    const fields: string[] = ['User', '# Sessions'];
    for (const metric of data) {
      const metricData = [metric.email, metric.count.toString()];
      metricsData.push(metricData);
    }
    const mapObject: MapObject = {
      fields: fields,
      data: metricsData,
    };
    return mapObject;
  }

  public unparseDataToCsv(data: Array<UserMetrics>): string {
    const metricsData = this.reformatMetricsData(data);
    const options: UnparseConfig = {
      quotes: true,
      header: true,
      newline: '\n',
    };
    return this.papa.unparse(metricsData, options);
  }

  private downloadMetrics(data: Array<UserMetrics>): void {
    const metricsCsv = this.unparseDataToCsv(data);
    const link = this.renderer.createElement('a');
    const blob = new Blob([metricsCsv], { type: 'text/csv' });
    link.href = window.URL.createObjectURL(blob);
    link.download = 'top_users.csv';
    link.click();
  }

  public listTopUsersAndDownload(): void {
    const topMetricsFilter = this.getTopMetricsFilter();
    this.topMetricsService
      .listTopUsers(topMetricsFilter)
      .pipe(take(1))
      .subscribe((topUsersResp) => {
        this.downloadMetrics(topUsersResp.top_users);
      });
  }

  public useSharedPageSizeOptions(): Array<number> {
    return getPageSizeOptions(this.tableDataSource.data.length);
  }
}
