<p class="descriptive-text">
  Lists the origins allowed to access the resources of this application. 
  Any matching origin will have its value echoed back in the <code class="inline-code">Access-Control-Allow-Origin</code> header. 
  An origin is the base URL of the site you want to access the application. 
  For example, "https://my-other-app.agilicus.cloud"
</p>

<portal-table-layout
  [tableData]="tableData"
  [columnDefs]="columnDefs"
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName"
  (updateEvent)="updateEvent()"
  (removeSelected)="deleteSelected()"
>
</portal-table-layout>