<form
  [formGroup]="connectorFormGroup"
  class="stepper-form"
  spellcheck="false"
  autocomplete="off"
>
  <p class="descriptive-text">{{ descriptiveText }}</p>
  <mat-form-field>
    <mat-label>Select a connector:</mat-label>
    <mat-select 
      #select1
      formControlName="connector_name"
      (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
      (selectionChange)="updateSelectedConnector(connectorFormGroup, $event.value)"
    >
      <mat-option value=""></mat-option>
      <mat-option
        *ngFor="let connector of connectors"
        value="{{ connector.spec.name }}"
        >{{ connector.spec.name }}</mat-option
      >
    </mat-select>
    <mat-error *ngIf="required">Connector is required</mat-error>
  </mat-form-field>
</form>
