import { LabelAssociation, LabelledObject, LabelsService, MetadataWithId, PolicyTemplateInstance, Resource } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { InputSize } from '@app/shared/components/custom-chiplist-input/input-size.enum';
import { PolicyAndResourceAndLabelDataWithPermissions } from '@app/shared/components/policy-and-resource-and-label-data-with-permissions';
import { PolicyTemplateWithLabels } from '@app/shared/components/policy-template-with-labels';
import { getMatchingLabelledObjectFromResource } from '@app/shared/components/resource-utils';
import { ChiplistColumn, Column, createChipListColumn } from '@app/shared/components/table-layout/column-definitions';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, concatMap, forkJoin, map, Observable, of } from 'rxjs';
import {
  addLabelAssociationToObject$,
  createNewLabelledObjectWithLabels$,
  removeLabelAssociationFromObject$,
  updateObjectLabelsNavigationIfNotAllowed$,
} from '../api/labels/labels-api-utils';
import {
  getDetailedTemplateDefinitionBasedOnType,
  getPoliciesToAddAndRemove,
  getPolicyNameAndTypeString,
  getPolicyTemplateLabelNameFromPolicy,
  PolicyTemplateInstanceWithLabels,
  PolicyUpdateData,
  ResourceAndLabelsAndPolicyResponse,
  ResourceElementWithPolicies,
  ResourceTableElement,
} from '../api/policy-template-instance/policy-template-instance-utils';
import { AppState } from '../core.state';
import { NotificationService } from '../notifications/notification.service';
import { savingPolicyTemplateInstances } from '../policy-template-instance-state/policy-template-instance.actions';
import {
  selectPolicyTemplateInstanceRefreshDataValue,
  selectPolicyTemplateInstanceWithLabelsList,
} from '../policy-template-instance-state/policy-template-instance.selectors';
import { ResourceLabelLinkService } from '../resource-label-link-service/resource-label-link.service';

@Injectable({
  providedIn: 'root',
})
export class PolicyResourceLinkService<T extends ResourceTableElement> {
  private policiesColumnName = 'policies';

  constructor(
    private store: Store<AppState>,
    private labelsService: LabelsService,
    private notificationService: NotificationService,
    private resourceLabelLinkService: ResourceLabelLinkService
  ) {}

  public onSubmitPoliciesFinish(updatedTableElement: T, resourceAndLabelsAndPolicyResponse: ResourceAndLabelsAndPolicyResponse): void {
    updatedTableElement.backingResourceWithLabels.backingLabelledObject = resourceAndLabelsAndPolicyResponse.labelledObject;
    updatedTableElement.backingResourceWithLabels.labels = resourceAndLabelsAndPolicyResponse.labels;
    this.updatePolicyLabels(resourceAndLabelsAndPolicyResponse.policyUpdateData);
    updatedTableElement.previousPolicies = cloneDeep(updatedTableElement.policies);
  }

  public submitPoliciesResult$(updatedTableElement: T, orgId: string): Observable<ResourceAndLabelsAndPolicyResponse> {
    const labelAssociationsList: Array<LabelAssociation> = [];
    for (const policy of updatedTableElement.policies) {
      labelAssociationsList.push({
        label_name: getPolicyTemplateLabelNameFromPolicy(policy),
        org_id: orgId,
      });
    }
    updatedTableElement.backingResourceWithLabels.labels = labelAssociationsList;
    return this.getLabelsCreateResponse$(updatedTableElement, orgId);
  }

  private getLabelsCreateResponse$(updatedTableElement: T, orgId: string): Observable<ResourceAndLabelsAndPolicyResponse | undefined> {
    const labelledObjectCreateResult$ = this.getLabelledObjectCreateResult$(updatedTableElement, orgId);
    return labelledObjectCreateResult$.pipe(
      concatMap((labelsResultResp) => {
        const policyUpdateData: PolicyUpdateData = getPoliciesToAddAndRemove(
          updatedTableElement.policies,
          updatedTableElement.previousPolicies
        );
        const [labelAssociationsToAddObservablesArray, labelAssociationsToDeleteObservablesArray] =
          this.getLabelAssociationsToUpdateObservablesArray(updatedTableElement, policyUpdateData, orgId);

        const addResults = updateObjectLabelsNavigationIfNotAllowed$(
          labelAssociationsToAddObservablesArray,
          this.labelsService,
          orgId,
          false
        );
        let labelAssociationsToUpdateResult$: Observable<Array<any> | undefined> = of(undefined);
        const toRun = [...addResults, ...labelAssociationsToDeleteObservablesArray];
        if (toRun.length !== 0) {
          labelAssociationsToUpdateResult$ = forkJoin(toRun);
        }
        return labelAssociationsToUpdateResult$.pipe(
          map((labelAssociationsToUpdateResp) => {
            return {
              ...labelsResultResp,
              policyUpdateData: policyUpdateData,
            };
          })
        );
      })
    );
  }

  private getLabelledObjectCreateResult$(updatedTableElement: T, orgId: string): Observable<ResourceAndLabelsAndPolicyResponse> {
    let labelsResult$: Observable<ResourceAndLabelsAndPolicyResponse | undefined> = of(undefined);
    if (!updatedTableElement.backingResourceWithLabels.backingLabelledObject) {
      labelsResult$ = createNewLabelledObjectWithLabels$(
        this.labelsService,
        updatedTableElement.backingResourceWithLabels,
        updatedTableElement.backingResourceWithLabels.backingObject,
        orgId,
        false
      );
    } else {
      labelsResult$ = of({
        resource: updatedTableElement.backingResourceWithLabels.backingObject,
        labelledObject: updatedTableElement.backingResourceWithLabels.backingLabelledObject,
        labels: updatedTableElement.backingResourceWithLabels.labels,
      });
    }
    return labelsResult$;
  }

  private getLabelAssociationsToUpdateObservablesArray(
    updatedTableElement: T,
    policyUpdateData: PolicyUpdateData,
    orgId: string
  ): [Array<Observable<LabelAssociation>>, Array<Observable<any>>] {
    const labelAssociationsToAddObservablesArray = [];
    if (!!updatedTableElement.backingResourceWithLabels.backingLabelledObject && policyUpdateData.policies_to_add_label_to.length !== 0) {
      for (const policy of policyUpdateData.policies_to_add_label_to) {
        labelAssociationsToAddObservablesArray.push(
          addLabelAssociationToObject$(
            this.labelsService,
            updatedTableElement.backingResourceWithLabels.backingLabelledObject.object_id,
            orgId,
            getPolicyTemplateLabelNameFromPolicy(policy)
          )
        );
      }
    }
    const labelAssociationsToRemoveObservablesArray = [];
    if (policyUpdateData.policies_to_remove_from_resource.length !== 0) {
      for (const policy of policyUpdateData.policies_to_remove_from_resource) {
        labelAssociationsToRemoveObservablesArray.push(
          removeLabelAssociationFromObject$(
            this.labelsService,
            updatedTableElement.backingResourceWithLabels.backingLabelledObject.object_id,
            orgId,
            getPolicyTemplateLabelNameFromPolicy(policy)
          )
        );
      }
    }
    return [labelAssociationsToAddObservablesArray, labelAssociationsToRemoveObservablesArray];
  }

  private updatePolicyLabels(policyUpdateData: PolicyUpdateData) {
    for (const policy of policyUpdateData.policies_to_add_label_to) {
      const policyTemplateWithLabels = policy.spec.template as PolicyTemplateWithLabels;
      if (!policyTemplateWithLabels.labels) {
        return;
      }
      const existingLabel = policyTemplateWithLabels.labels.find((labelName) => labelName === getPolicyTemplateLabelNameFromPolicy(policy));
      if (!existingLabel) {
        policyTemplateWithLabels.labels.push(getPolicyTemplateLabelNameFromPolicy(policy));
      }
    }
    if (policyUpdateData.policies_to_add_label_to.length === 0) {
      this.notificationService.success('Policies have been successfully updated');
      return;
    }
    this.store.dispatch(
      savingPolicyTemplateInstances({
        objs: policyUpdateData.policies_to_add_label_to,
        trigger_update_side_effects: false,
        notifyUser: true,
      })
    );
  }

  public getAllPoliciesLinkedToResource(
    resource: Resource | undefined,
    policies: Array<PolicyTemplateInstanceWithLabels> | undefined,
    labelledObjects: Array<LabelledObject> | undefined
  ): Array<PolicyTemplateInstanceWithLabels> {
    if (!resource || !policies || !labelledObjects) {
      return [];
    }
    const targetLabelledObject = labelledObjects.find((labelledObject) => labelledObject.object_id === resource.metadata.id);
    const targetResourcesLabelAssociations = !!targetLabelledObject?.labels ? targetLabelledObject.labels : [];
    const associatedPoliciesList: Array<PolicyTemplateInstanceWithLabels> = [];
    for (const policy of policies) {
      const targetPolicyLabelName = getPolicyTemplateLabelNameFromPolicy(policy);
      const targetResourcesLabelAssociation = targetResourcesLabelAssociations.find(
        (labelAssociation) => labelAssociation.label_name === targetPolicyLabelName
      );
      if (!!targetResourcesLabelAssociation) {
        associatedPoliciesList.push(policy);
      }
    }
    return associatedPoliciesList;
  }

  public getResourceElementWithPolicies(
    targetResource: { metadata?: MetadataWithId | undefined },
    resourcesList: Array<Resource>,
    labelledObjectsList: Array<LabelledObject>,
    policyTemplateInstanceList: Array<PolicyTemplateInstanceWithLabels>
  ): ResourceElementWithPolicies {
    const targetBackingResource = !!resourcesList
      ? resourcesList.find((resource) => resource.metadata.id === targetResource.metadata.id)
      : (targetResource as Resource);
    const targetBackingLabelledObject = getMatchingLabelledObjectFromResource(targetBackingResource, labelledObjectsList);
    const policiesList = this.getAllPoliciesLinkedToResource(targetBackingResource, policyTemplateInstanceList, labelledObjectsList);
    return {
      backingResourceWithLabels: {
        resource_type: targetBackingResource?.spec?.resource_type,
        name: targetBackingResource?.spec?.name,
        labels: !!targetBackingLabelledObject ? targetBackingLabelledObject.labels : [],
        previousLabels: !!targetBackingLabelledObject ? cloneDeep(targetBackingLabelledObject.labels) : [],
        backingObject: targetBackingResource,
        backingLabelledObject: targetBackingLabelledObject,
      },
      policies: policiesList,
      previousPolicies: cloneDeep(policiesList),
    };
  }

  public setPolicyNameAndTypeStringMap(
    policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstance>,
    policyTemplateInstanceList: Array<PolicyTemplateInstance>
  ): void {
    for (const policy of policyTemplateInstanceList) {
      policyNameAndTypeStringToPolicyMap.set(getPolicyNameAndTypeString(policy), policy);
    }
  }

  public getResourceTablePoliciesColumn(policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstance>): ChiplistColumn<T> {
    const column = createChipListColumn(this.policiesColumnName);
    column.getDisplayValue = (policy: PolicyTemplateInstance) => {
      return getPolicyNameAndTypeString(policy);
    };
    column.getElementFromValue = (item: string): any => {
      return policyNameAndTypeStringToPolicyMap.get(item);
    };
    column.getHeaderTooltip = (): string => {
      return `Assign the resource to existing policies`;
    };
    column.getTooltip = (value: any): string => {
      const valueAsPolicyTemplateInstance = value as PolicyTemplateInstance;
      return getDetailedTemplateDefinitionBasedOnType(valueAsPolicyTemplateInstance);
    };
    column.getOptionTooltip = (option: PolicyTemplateInstance): string => {
      return getDetailedTemplateDefinitionBasedOnType(option);
    };
    column.inputSize = InputSize.STANDARD;
    return column;
  }

  public setResourceTablePoliciesColumnAllowedValuesList(
    columnDefs: Map<string, Column<T>>,
    policyTemplateInstanceList: Array<PolicyTemplateInstance>
  ): void {
    const policiesColumn = columnDefs.get(this.policiesColumnName);
    if (!policiesColumn) {
      return;
    }
    policiesColumn.allowedValues = policyTemplateInstanceList;
  }

  public getPolicyAndResourceAndLabelDataWithPermissions$(): Observable<PolicyAndResourceAndLabelDataWithPermissions> {
    const resourceAndLabelDataWithPermissions$ = this.resourceLabelLinkService.getResourceAndLabelDataWithPermissions$();
    const policyTemplateInstanceWithLabelsListState$ = this.store.pipe(select(selectPolicyTemplateInstanceWithLabelsList));
    const refreshPolicyTemplateInstanceDataState$ = this.store.pipe(select(selectPolicyTemplateInstanceRefreshDataValue));
    return combineLatest([
      resourceAndLabelDataWithPermissions$,
      policyTemplateInstanceWithLabelsListState$,
      refreshPolicyTemplateInstanceDataState$,
    ]).pipe(
      map(
        ([
          resourceAndLabelDataWithPermissionsResp,
          policyTemplateInstanceWithLabelsListStateResp,
          refreshPolicyTemplateInstanceDataStateResp,
        ]) => {
          return {
            policyTemplateInstanceList: policyTemplateInstanceWithLabelsListStateResp,
            refreshPolicyTemplateInstanceDataStateValue: refreshPolicyTemplateInstanceDataStateResp,
            ...resourceAndLabelDataWithPermissionsResp,
          };
        }
      )
    );
  }
}
