import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppState } from '@app/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, forkJoin } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { BulkUpdateMetadataRequestParams, BulkUserMetadata, OrganisationsService, Tracker, _Organisation } from '@agilicus/angular';
import { Organisation } from '@agilicus/angular';
import { UsersService } from '@agilicus/angular';
import { User } from '@agilicus/angular';
import { NotificationService } from '@app/core';
import { ActionOrganisationsSetCurrentOrg } from '@app/core/organisations/organisations.actions';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectUserSelector } from '@app/core/user/user.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { capitalizeFirstLetter } from '../utils';
import { addSecondsToDate, convertDaysToSeconds, convertSecondsToDays, getCurrentDate } from '../date-utils';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { createDialogData } from '../dialog-utils';
import { ActionUserLogout } from '@app/core/user/user.actions';
import {
  OrgDeleteConfirmationDialogComponent,
  OrgDeleteDialogData,
} from '../org-delete-confirmation-dialog/org-delete-confirmation-dialog.component';
import { OptionalGroupElement } from '../optional-types';
import { cloneDeep } from 'lodash-es';
import { markOrgAsDeleted, updateOrg$ } from '../org-utils';
import { getOrganisationProductGuideLink } from '@app/core/api/organisations/organisation-utils';

export interface ExtendedOrganisation extends Organisation {
  contact_email?: string;
}

@Component({
  selector: 'portal-org-admin',
  templateUrl: './org-admin.component.html',
  styleUrls: ['./org-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgAdminComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private org_id: string;
  private organisation$: Observable<Organisation>;
  public organisation: ExtendedOrganisation;
  private users$: Observable<Array<User>>;
  public updateOrgForm: UntypedFormGroup;
  private userIdToEmail: Map<string, string> = new Map();
  private userEmailToId: Map<string, string> = new Map();
  public fixedTable = true;
  public selectable = false;
  public groupType: User.TypeEnum = User.TypeEnum.sysgroup;
  public title = 'Permissions';
  private hasUsersPermissions$: Observable<OrgQualifiedPermission>;
  public hasUsersPermissions: boolean;
  public deleteRedirectURI = 'https://www.agilicus.com/';
  public filteredUserOptions$: Observable<Array<string>>;
  public hideGroupsList: Array<string> = ['sys-authentication-admin', 'sys-user-service-account', 'sys-provisioner-admin'];
  public pageDescriptiveText = `An organisation (Tenant, Project in other cloud systems) is a unique scope of billing and control`;
  public productGuideLink = getOrganisationProductGuideLink();
  public permissionsTableDescriptiveText =
    'These groups control who administers different aspects of the system. Hover over each group name to see a description of whom you should put into the group.';

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public capitalizeFirstLetter = capitalizeFirstLetter;

  constructor(
    private store: Store<AppState>,
    private organisationsService: OrganisationsService,
    private usersService: UsersService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private customValidatorsService: CustomValidatorsService,
    private changeDetector: ChangeDetectorRef,
    private appErrorHandler: AppErrorHandler,
    public dialog?: MatDialog
  ) {}

  public onSubmit(): void {}

  public ngOnInit(): void {
    this.hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    this.hasUsersPermissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((hasUsersPermissionsResp) => {
      this.org_id = hasUsersPermissionsResp.orgId;
      if (!this.org_id) {
        return;
      }
      this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
      if (this.hasUsersPermissions) {
        this.setupData();
      }
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupData(): void {
    this.organisation$ = this.organisationsService.getOrg({
      org_id: this.org_id,
    });
    this.users$ = this.usersService.listUsers({ org_id: this.org_id }).pipe(
      map((usersResp) => {
        return usersResp.users;
      })
    );

    forkJoin([this.organisation$, this.users$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([orgResp, usersResp]) => {
          this.organisation = orgResp;
          usersResp.forEach((user) => {
            this.userIdToEmail.set(user.id, user.email);
            this.userEmailToId.set(user.email, user.id);
          });
          this.initializeFormGroup();
          this.setUserAutocomplete(usersResp);
          this.changeDetector.detectChanges();
        },
        (error) => {
          // Reset the organisation so that the form will be hidden.
          this.organisation = undefined;
          this.changeDetector.detectChanges();
        }
      );
  }

  private initializeFormGroup(): void {
    const contactEmailValidators = [Validators.email, this.customValidatorsService.checkValidExistingEmail(this.userEmailToId)];
    if (!!this.organisation.contact_email) {
      // If the contact_email is set, we make the field required so that it cannot be unset.
      // If it is not set, then having this field unset will not prevent the user from updating
      // other fields.
      contactEmailValidators.push(Validators.required);
    }
    this.updateOrgForm = this.formBuilder.group({
      contact_email: [!!this.organisation.contact_email ? this.organisation.contact_email : '', contactEmailValidators],
      external_id: this.getExternalId(),
      subdomain: [
        this.organisation.subdomain ? this.organisation.subdomain : '',
        [Validators.required, this.customValidatorsService.hostnameValidator()],
      ],
      trust_on_first_use_duration: [
        convertSecondsToDays(this.organisation.trust_on_first_use_duration),
        [this.customValidatorsService.positiveNumberValidator()],
      ],
      auto_create_users: this.organisation.auto_create ? true : false,
      disable_user_requests: this.organisation?.owner_config?.disable_user_requests
        ? this.organisation.owner_config.disable_user_requests
        : false,
    });
  }

  private getExternalId(): string {
    if (this.organisation.external_id === undefined || this.organisation.external_id === null) {
      return '';
    }
    return this.organisation.external_id;
  }

  private updateOrgFromForm(): void {
    this.organisation.contact_email = this.updateOrgForm.get('contact_email').value;
    this.organisation.contact_id = this.userEmailToId.get(this.organisation.contact_email);
    this.organisation.external_id = this.updateOrgForm.get('external_id').value;
    if (this.organisation.external_id === '') {
      delete this.organisation.external_id;
    }
    this.organisation.subdomain = this.updateOrgForm.get('subdomain').value;
    const trustOnFirstUseDuration = this.getTrustOnFirstUseDurationFromForm();
    if (!trustOnFirstUseDuration) {
      delete this.organisation.trust_on_first_use_duration;
    } else {
      this.organisation.trust_on_first_use_duration = trustOnFirstUseDuration;
    }
    this.organisation.auto_create = this.updateOrgForm.get('auto_create_users').value;
    if (this.organisation.owner_config) {
      this.organisation.owner_config.disable_user_requests = this.updateOrgForm.get('disable_user_requests').value;
    } else {
      this.organisation.owner_config = {
        disable_user_requests: this.updateOrgForm.get('disable_user_requests').value,
      };
    }
  }

  private getTrustOnFirstUseDurationFromForm(): number {
    const formValue = this.updateOrgForm.get('trust_on_first_use_duration').value;
    return convertDaysToSeconds(formValue);
  }

  private putOrg$(): Observable<Organisation> {
    this.updateOrgFromForm();
    return updateOrg$(this.organisation, this.organisationsService);
  }

  public saveOrg(deletingOrg = false): void {
    this.putOrg$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (orgResp: Tracker<_Organisation>) => {
          if (deletingOrg) {
            this.notificationService.success('Organisation "' + this.organisation.organisation + '" was successfully marked for deletion');
            this.openRedirectDialog();
          } else {
            this.notificationService.success('Organisation "' + this.organisation.organisation + '" was successfully updated');
            this.organisation = orgResp;
            this.store.dispatch(new ActionOrganisationsSetCurrentOrg(cloneDeep(this.organisation)));
          }
        },
        error: (errorResp) => {
          let baseMessage = 'Failed to update organisation "' + this.organisation.organisation + '"';
          if (deletingOrg) {
            baseMessage = 'Failed to delete organisation "' + this.organisation.organisation + '"';
          }
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        },
      });
  }

  public updateUserMFADeadlines(): void {
    const orgDuration = this.organisation.trust_on_first_use_duration;
    const currentDate = getCurrentDate();
    const updatedDate = addSecondsToDate(orgDuration, currentDate);
    const bulkUpdateMetadataParams: BulkUpdateMetadataRequestParams = {
      BulkUserMetadata: {
        org_id: this.organisation.id,
        data_type: BulkUserMetadata.DataTypeEnum.mfa_enrollment_expiry,
        data: updatedDate.toISOString(),
      },
    };
    this.usersService.bulkUpdateMetadata(bulkUpdateMetadataParams).subscribe(
      (bulkUpdateMetadataResp) => {
        this.notificationService.success(
          'The deadline for all users of organisation "' + this.organisation.organisation + '" was successfully updated'
        );
      },
      (errorResp) => {
        const baseMessage = 'Failed to update the deadline for all users of organisation "' + this.organisation.organisation + '"';
        this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
      }
    );
  }

  public submitFormOnBlur(inputValue: string, formField: string): void {
    if (this.updateOrgForm.invalid) {
      return;
    }
    const targetValue = inputValue.trim();
    // Do not proceed if the value has not been changed
    if (this.organisation[formField] !== undefined && this.organisation[formField] === targetValue) {
      return;
    }
    if (this.organisation[formField] === undefined && targetValue === '') {
      return;
    }
    if (
      formField === 'trust_on_first_use_duration' &&
      convertSecondsToDays(this.organisation.trust_on_first_use_duration).toString() === targetValue
    ) {
      return;
    }
    if (formField === 'trust_on_first_use_duration') {
      this.openConfirmationDialog(
        'Resetting trust on first use duration',
        'Do you want to update the deadline for existing users?',
        this.updateUserMFADeadlines.bind(this)
      );
    }
    this.saveOrg();
  }

  private submitForm(): void {
    if (this.updateOrgForm.invalid) {
      return;
    }

    this.saveOrg();
  }

  public checkboxChanged(): void {
    this.submitForm();
  }

  private userSearchFilter(value: string, users: Array<User>): Array<string> {
    const filterValue = value.toLowerCase();
    const userEmails = users.map((e) => e.email);
    return userEmails.filter((option) => option.toLowerCase().includes(filterValue));
  }

  private setUserAutocomplete(users: Array<User>): void {
    const contactEmailControl = this.updateOrgForm.get('contact_email');
    this.filteredUserOptions$ = contactEmailControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.userSearchFilter(value, users))
    );
  }

  public openConfirmationDialog(messagePrefix: string, message: string, callback: () => void): void {
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.buttonText = { confirm: 'Yes', cancel: 'No' };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        callback();
        return;
      }
    });
  }

  public openRedirectDialog(): void {
    const dialogData = createDialogData(
      'Organisation Deleted',
      'Organisation "' +
        this.organisation.organisation +
        '" was successfully marked for deletion. You will now be redirected to ' +
        this.deleteRedirectURI +
        '.'
    );
    dialogData.buttonText = { confirm: 'Continue', cancel: '' };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // clear state
        this.store.dispatch(new ActionUserLogout(''));
        this.store
          .select(selectUserSelector)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((state) => {
            if (!state.user) {
              // log out complete
              window.location.href = this.deleteRedirectURI;
            }
          });
      }
    });
  }

  public deleteOrganisation(): void {
    const dialogData: OrgDeleteDialogData = { orgName: this.organisation.organisation };
    const dialogRef = this.dialog.open(OrgDeleteConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        markOrgAsDeleted(this.organisation);
        this.saveOrg(true);
      }
    });
  }

  public getCustomTooltip(element: OptionalGroupElement): string {
    if (element.first_name === 'sys-admin') {
      return 'Users in this group administer all aspects of the organisation';
    }
    if (element.first_name === 'sys-apps-admin') {
      return 'Users in this group administer applications and resources in the organisation. These users typically have developer or IT roles in the organisation.';
    }
    if (element.first_name === 'sys-users-admin') {
      return 'Users in this group administer the users of the organisation including permissions and group membership. These users typically have team leadership roles in the organisation.';
    }
    return element.first_name;
  }

  public getOrganisationPageTitle(): string {
    if (this.organisation !== undefined && !!this.hasUsersPermissions) {
      return `Organisation: ${capitalizeFirstLetter(this.organisation.organisation)}`;
    } else {
      return 'Organisation';
    }
  }
}
