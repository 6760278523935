export enum SecuritySettings {
  csp = 'csp',
  cors = 'cors',
  hsts = 'hsts',
  xss_protection = 'xss_protection',
  certificate_transparency = 'certificate_transparency',
  frame_options = 'frame_options',
  content_type_options = 'content_type_options',
  permitted_cross_domain_policies = 'permitted_cross_domain_policies',
  coep = 'coep',
  coop = 'coop',
  corp = 'corp',
  referrer_policy = 'referrer_policy',
}
