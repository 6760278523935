    <mat-grid-list cols="5">
      <mat-grid-tile>
        <a rel="noopener" href="https://www.agilicus.com/?utm_source=portal" target="_blank">
          <img width=16 src="assets/img/Agilicus-logo-only.svg">
          <span>Web</span>
        </a>
      </mat-grid-tile>
      <mat-grid-tile>
        <a rel="noopener" href="https://www.agilicus.com/blog?utm_source=portal" target="_blank">
          <img width=16 src="assets/img/Agilicus-logo-only.svg">
          <span>Blog</span>
        </a>
      </mat-grid-tile>
      <mat-grid-tile>
        <a rel="noopener noreferrer" href="https://www.twitter.com/Agilicus" target="_blank">
          <fa-icon [icon]="['fab','twitter']"></fa-icon>
          <span>Twitter</span>
        </a>
      </mat-grid-tile>
      <mat-grid-tile>
        <a rel="noopener noreferrer" href="https://www.youtube.com/c/Agilicus" target="_blank">
          <fa-icon [icon]="['fab','youtube']"></fa-icon>
          <span>Youtube</span>
        </a></mat-grid-tile>

      <mat-grid-tile>
        <a rel="noopener noreferrer" href="https://www.linkedin.com/company/agilicus" target="_blank">
          <fa-icon [icon]="['fab','linkedin']"></fa-icon>
          <span>Linkedin</span>
        </a></mat-grid-tile>

    </mat-grid-list>
