import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { ApplicationService } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadApplicationServices = createAction(
  '[ApplicationService/API] Load ApplicationServices',
  props<{ objs: ApplicationService[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addApplicationService = createAction('[ApplicationService/API] Add ApplicationService', props<{ obj: ApplicationService }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertApplicationService = createAction(
  '[ApplicationService/API] Upsert ApplicationService',
  props<{ obj: ApplicationService; refreshData: boolean }>()
);

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addApplicationServices = createAction(
  '[ApplicationService/API] Add ApplicationServices',
  props<{ objs: ApplicationService[] }>()
);

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertApplicationServices = createAction(
  '[ApplicationService/API] Upsert ApplicationServices',
  props<{ objs: ApplicationService[]; refreshData: boolean }>()
);

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateApplicationService = createAction(
  '[ApplicationService/API] Update ApplicationService',
  props<{ obj: Update<ApplicationService> }>()
);

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we only update a single data object in the api at a time.
 */
export const updateApplicationServices = createAction(
  '[ApplicationService/API] Update ApplicationServices',
  props<{ objs: Update<ApplicationService>[] }>()
);

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteApplicationService = createAction(
  '[ApplicationService/API] Delete ApplicationService',
  props<{ id: string; obj: ApplicationService; refreshData?: boolean }>()
);

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteApplicationServices = createAction(
  '[ApplicationService/API] Delete ApplicationServices',
  props<{ ids: string[]; refreshData?: boolean }>()
);

/**
 * Will delete all data from the ngrx entity state
 */
export const clearApplicationServices = createAction('[ApplicationService/API] Clear ApplicationServices');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initApplicationServices = createAction(
  '[ApplicationService/API] Init ApplicationServices',
  props<{ force: boolean; blankSlate: boolean }>()
);

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getApplicationServices = createAction(
  '[ApplicationService/API] Get ApplicationServices',
  props<{ org_id: string; blankSlate: boolean }>()
);

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingApplicationService = createAction(
  '[ApplicationService/API] Saving ApplicationService',
  props<{
    obj: ApplicationService;
    trigger_update_side_effects: boolean;
    notifyUser: boolean;
    refreshData?: boolean;
    refreshApiData?: boolean;
  }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingApplicationServices = createAction(
  '[ApplicationService/API] Saving ApplicationServices',
  props<{
    objs: Array<ApplicationService>;
    trigger_update_side_effects: boolean;
    notifyUser: boolean;
    refreshData?: boolean;
    refreshApiData?: boolean;
  }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingApplicationService = createAction(
  '[ApplicationService/API] Deleting ApplicationService',
  props<{ obj: ApplicationService; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingApplicationServices = createAction(
  '[ApplicationService/API] Deleting ApplicationServices',
  props<{ objs: Array<ApplicationService>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshApplicationServices = createAction('[ApplicationService/API] Refresh ApplicationService');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainApplicationServices = createAction('[ApplicationService/API] Maintain ApplicationServices');

/**
 * Custom action for application services. When an application service assignment is created/modified/deleted
 * it will also modify the associated application objects in the backend. This action will trigger a refresh of
 * the applications to get the updated application data and update the ngrx state with this data.
 */
export const resetAppListRefreshFlagApplicationServices = createAction(
  '[ApplicationService/API] Reset App List Refresh Flag ApplicationServices'
);

/**
 * Custom action
 */
export const resetApiDataRefreshFlagApplicationServices = createAction(
  '[ApplicationService/API] Reset Api Data Refresh Flag ApplicationServices'
);
