import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'portal-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoPermissionsComponent {
  @Input() public restrictionType = 'page';

  constructor() {}
}
