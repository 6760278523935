<div class="img-background-container" [style]="getImageContainerStyle()">
  <div
    class="drop-area-wrapper"
    portalDroppable
    (handleOnDrop)="onFileDrop($event)"
  >
    <div 
      class="drop-area"
      [ngClass]="{ 'drop-area-no-border': !!iconUrl }"
    >
     <p *ngIf="iconUrl !== undefined && !iconUrl" class="no-icon-text">{{ defaultText }}</p>
    </div>
    <input 
      type="file" 
      name="upload-file"
      id="input-file"
      title=" "
      (change)="onReadFile($event)"
      [matTooltip]="tooltipText"
      aria-label="Area that uploads a file"
      [disabled]="isUploading"
      portalDisableKeyTab
    />
  </div>
</div>