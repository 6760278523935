import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState } from '..';
import * as BillingAccountFullActions from './billing-account-full.actions';
import { BillingAccountFullState } from './billing-account-full.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
} from '../helpers/effect-factories';
import { BillingAccountFullStateService } from '../state-services/billing-state.service';
import {
  selectBillingAccountFull,
  selectBillingAccountFullEntity,
  selectBillingAccountFullShouldPopulateValue,
  selectBillingAccountFullsList,
} from './billing-account-full.selectors';
import { BillingAccountFull } from '../models/billing/billing-account-full';
import { createCombinedPermissionsSelector } from '../user/permissions/permissions.selectors';
import { selectCanAdminOrReadUsers, selectCanAdminUsers } from '../user/permissions/users.selectors';

@Injectable()
export class BillingAccountFullEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private billingAccountFullStateService: BillingAccountFullStateService
  ) {}

  public initState$ = createInitStateEffect<BillingAccountFullState>(
    this.store,
    this.actions$,
    BillingAccountFullActions.initBillingAccountFull,
    BillingAccountFullActions.getBillingAccountFull,
    BillingAccountFullActions.maintainBillingAccountFull,
    selectBillingAccountFull
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    BillingAccountFullActions.getBillingAccountFull,
    BillingAccountFullActions.maintainBillingAccountFull,
    selectBillingAccountFullShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<BillingAccountFull, string>(
    this.store,
    this.actions$,
    BillingAccountFullActions.getBillingAccountFull,
    BillingAccountFullActions.clearBillingAccountFulls,
    BillingAccountFullActions.maintainBillingAccountFull,
    this.billingAccountFullStateService,
    createCombinedPermissionsSelector(selectCanAdminOrReadUsers, selectCanAdminUsers),
    true, // checkParentOrg
    true // checkOrgSubdomain
  );

  public setCrudState$ = createSetCrudStateEffect<BillingAccountFull, string>(
    this.store,
    this.actions$,
    BillingAccountFullActions.loadBillingAccountFulls,
    BillingAccountFullActions.maintainBillingAccountFull,
    this.billingAccountFullStateService,
    selectBillingAccountFullsList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<BillingAccountFull, string>(
    this.store,
    this.actions$,
    BillingAccountFullActions.savingBillingAccountFull,
    BillingAccountFullActions.maintainBillingAccountFull,
    this.billingAccountFullStateService,
    selectBillingAccountFullEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<BillingAccountFull, string>(
    this.store,
    this.actions$,
    BillingAccountFullActions.savingBillingAccountFulls,
    BillingAccountFullActions.maintainBillingAccountFull,
    this.billingAccountFullStateService,
    selectBillingAccountFull
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<BillingAccountFull, string>(
    this.actions$,
    BillingAccountFullActions.deletingBillingAccountFull,
    BillingAccountFullActions.refreshBillingAccountFull,
    BillingAccountFullActions.maintainBillingAccountFull,
    this.billingAccountFullStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<BillingAccountFull, string>(
    this.actions$,
    BillingAccountFullActions.deletingBillingAccountFulls,
    BillingAccountFullActions.refreshBillingAccountFull,
    BillingAccountFullActions.maintainBillingAccountFull,
    this.billingAccountFullStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<BillingAccountFullState>(
    this.store,
    this.actions$,
    BillingAccountFullActions.getBillingAccountFull,
    BillingAccountFullActions.maintainBillingAccountFull,
    selectBillingAccountFull
  );

  /**
   * Custom side effect. We want to refresh the billingAccountFull when the
   * trigger_update_side_effects is set to true
   */
  public refreshBillingAccountFullSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BillingAccountFullActions.savingBillingAccountFull),
      map((action) => {
        if (action.trigger_update_side_effects) {
          return BillingAccountFullActions.refreshBillingAccountFull();
        }
        return BillingAccountFullActions.maintainBillingAccountFull();
      })
    )
  );
}
