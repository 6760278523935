import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'portal-progress-spinner-dialog',
  templateUrl: './progress-spinner-dialog.component.html',
  styleUrls: ['./progress-spinner-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressSpinnerDialogComponent {
  constructor() {}
}
