<portal-table-layout
  class="table-template"
  [tableData]="tableData"
  [columnDefs]="columnDefs"
  [filterManager]="filterManager"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName"
  [dropdownSelector]="dropdownSelector"
  [hideFilter]="hideFilter"
  [buttonsToShow]="buttonsToShow"
  [customButtons]="customButtons"
  (updateEvent)="updateEvent($event)"
  (removeSelected)="deleteSelected()"
>
</portal-table-layout>