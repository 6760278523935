import { CipherDiffieHellmanGroup, CipherEncryptionAlgorithm, CipherIntegrityAlgorithm, IpsecConnection } from '@agilicus/angular';
import { createEnumChecker } from './utils';

export function getValidIkeCipherOptions(): Array<string> {
  return [`${CipherEncryptionAlgorithm.aes256}-${CipherIntegrityAlgorithm.sha256}-${CipherDiffieHellmanGroup.ecp384}`];
}

export function getValidEspCipherOptions(): Array<string> {
  return [`${CipherEncryptionAlgorithm.aes256}-${CipherIntegrityAlgorithm.sha256}-${CipherDiffieHellmanGroup.ecp384}`];
}

export function setIkeCipherValues(ipsecConnection: IpsecConnection, ikeCipherOptions: string): void {
  const ikeCipherOptionsArray = ikeCipherOptions.split('-');
  const cipherEncryptionAlgorithmValue = ikeCipherOptionsArray[0];
  const cipherIntegrityAlgorithmValue = ikeCipherOptionsArray[1];
  const cipherDiffieHellmanGroupValue = ikeCipherOptionsArray[2];

  const isCipherEncryptionAlgorithm = createEnumChecker(CipherEncryptionAlgorithm);
  if (isCipherEncryptionAlgorithm(cipherEncryptionAlgorithmValue)) {
    ipsecConnection.spec.ike_cipher_encryption_algorithm = cipherEncryptionAlgorithmValue;
  }
  const isCipherIntegrityAlgorithm = createEnumChecker(CipherIntegrityAlgorithm);
  if (isCipherIntegrityAlgorithm(cipherIntegrityAlgorithmValue)) {
    ipsecConnection.spec.ike_cipher_integrity_algorithm = cipherIntegrityAlgorithmValue;
  }
  const isCipherDiffieHellmanGroup = createEnumChecker(CipherDiffieHellmanGroup);
  if (isCipherDiffieHellmanGroup(cipherDiffieHellmanGroupValue)) {
    ipsecConnection.spec.ike_cipher_diffie_hellman_group = cipherDiffieHellmanGroupValue;
  }
}

export function setEspCipherValues(ipsecConnection: IpsecConnection, espCipherOptions: string): void {
  const espCipherOptionsArray = espCipherOptions.split('-');
  const cipherEncryptionAlgorithmValue = espCipherOptionsArray[0];
  const cipherIntegrityAlgorithmValue = espCipherOptionsArray[1];
  const cipherDiffieHellmanGroupValue = espCipherOptionsArray[2];

  const isCipherEncryptionAlgorithm = createEnumChecker(CipherEncryptionAlgorithm);
  if (isCipherEncryptionAlgorithm(cipherEncryptionAlgorithmValue)) {
    ipsecConnection.spec.esp_cipher_encryption_algorithm = cipherEncryptionAlgorithmValue;
  }
  const isCipherIntegrityAlgorithm = createEnumChecker(CipherIntegrityAlgorithm);
  if (isCipherIntegrityAlgorithm(cipherIntegrityAlgorithmValue)) {
    ipsecConnection.spec.esp_cipher_integrity_algorithm = cipherIntegrityAlgorithmValue;
  }
  const isCipherDiffieHellmanGroup = createEnumChecker(CipherDiffieHellmanGroup);
  if (isCipherDiffieHellmanGroup(cipherDiffieHellmanGroupValue)) {
    ipsecConnection.spec.esp_cipher_diffie_hellman_group = cipherDiffieHellmanGroupValue;
  }
}
