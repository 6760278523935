import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core';
import { GettingStartedTask } from '@app/core/user/preferences/getting-started-tasks.enum';
import { GettingStartedData } from '@app/core/user/preferences/getting-started-data';
import {
  getTaskCompletedStatus,
  getTaskCompleteToggleText,
  toggleGettingStartedTask,
} from '@app/core/user/preferences/user-preference-utils';
import { selectApiOrgId, selectGettingStartedData } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';

export enum IdentityProviderType {
  microsoft = 'microsoft',
  other = 'other',
  own = 'own',
}

@Component({
  selector: 'portal-identify-users-guide',
  templateUrl: './identify-users-guide.component.html',
  styleUrls: ['./identify-users-guide.component.scss', '../getting-started-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentifyUsersGuideComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public gettingStartedDataCopy: GettingStartedData;
  public pageDescriptiveText = `The users of this system are authenticated via a set of external identity providers. 
  These can be any system supporting OpenID Connect, Azure Active Directory, On-Site Identity providers, or individual Web Application local users. 
  Commonly you will have one Identity provider for your first-party users, one for each company you do business with (e.g. an MSP, a joint-venture).
  It is also common to enable public identity providers (e.g. Sign In with Google, Apple) to manage contractors and temps.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/authentication-issuer/`;
  public isTaskComplete = false;
  public targetTask = GettingStartedTask.completed_identify_users;
  public identifyUsersFormGroup: UntypedFormGroup;

  public getTaskCompletedStatus = getTaskCompletedStatus;
  public getTaskCompleteToggleText = getTaskCompleteToggleText;

  // This is required in order to reference the enums in the html template.
  public identityProviderType = IdentityProviderType;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private routerHelperService: RouterHelperService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    combineLatest([orgId$, gettingStartedData$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, gettingStartedDataResp]) => {
        this.orgId = orgIdResp;
        this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
        this.isTaskComplete = getTaskCompletedStatus(this.targetTask, this.gettingStartedDataCopy);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.identifyUsersFormGroup = this.formBuilder.group({
      identityProviderType: ['', Validators.required],
      microsoftAccountType: ['', Validators.required],
      hasPermissionsOrGroups: ['', Validators.required],
    });
  }

  public getIdentityProviderDataFromForm(formField: string): string {
    return this.identifyUsersFormGroup.get(formField).value;
  }

  public useAgilicusMicrosoftProvider(): boolean {
    const identityProviderTypeValue = this.getIdentityProviderDataFromForm('identityProviderType');
    const microsoftAccountTypeValue = this.getIdentityProviderDataFromForm('microsoftAccountType');
    const hasPermissionsOrGroupsValue = this.getIdentityProviderDataFromForm('hasPermissionsOrGroups');
    return (
      identityProviderTypeValue === IdentityProviderType.microsoft &&
      microsoftAccountTypeValue === 'yes' &&
      hasPermissionsOrGroupsValue === 'no'
    );
  }

  public useAgilicusOtherProvider(): boolean {
    const identityProviderTypeValue = this.getIdentityProviderDataFromForm('identityProviderType');
    return identityProviderTypeValue === IdentityProviderType.other;
  }

  public useOwnProvider(): boolean {
    const identityProviderTypeValue = this.getIdentityProviderDataFromForm('identityProviderType');
    const microsoftAccountTypeValue = this.getIdentityProviderDataFromForm('microsoftAccountType');
    const hasPermissionsOrGroupsValue = this.getIdentityProviderDataFromForm('hasPermissionsOrGroups');
    if (identityProviderTypeValue === IdentityProviderType.own) {
      return true;
    }
    if (identityProviderTypeValue !== IdentityProviderType.microsoft) {
      return false;
    }
    return microsoftAccountTypeValue === 'no' || hasPermissionsOrGroupsValue === 'yes';
  }

  public onImageClick(routerLink: string): void {
    this.routerHelperService.routeToScreen(routerLink, this.orgId);
  }

  public onCompleteToggle(isChecked: boolean): void {
    toggleGettingStartedTask(isChecked, this.targetTask, this.gettingStartedDataCopy, this.store);
  }
}
