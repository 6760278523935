import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import { select, Store } from '@ngrx/store';
import {
  CreateResourcePermissionRequestParams,
  DeleteResourcePermissionRequestParams,
  ListResourcePermissionsRequestParams,
  ListResourcePermissionsResponse,
  ListResourceRolesRequestParams,
  ListResourceRolesResponse,
  ListUsersRequestParams,
  ListUsersResponse,
  PermissionsService,
  Resource,
  ResourcePermission,
  ResourceRole,
  ResourceTypeEnum,
  ResourcesService,
  User,
  UsersService,
  UserStatusEnum,
} from '@agilicus/angular';
import { concat, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { PaginatorActions, PaginatorConfig, UpdateTableParams } from '../table-paginator/table-paginator.component';
import { PermissionsFilterOptions } from '../permissions-filter-options';
import { createCombinedPermissionsSelector, OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { concatMap, debounceTime, distinctUntilChanged, filter, reduce, takeUntil } from 'rxjs/operators';
import { CheckboxOption, FilterManager } from '../filter/filter-manager';
import { PermissionElement } from '../permission-element';
import { MatSort } from '@angular/material/sort';
import {
  AutoInputColumn,
  ChiplistColumn,
  Column,
  createAutoInputColumn,
  createChipListColumn,
  createIconColumn,
  createSelectRowColumn,
  setColumnDefs,
  setColumnFormControlFilters,
} from '../table-layout/column-definitions';
import { UntypedFormControl } from '@angular/forms';
import { OptionalResourcePermissionElement, OptionalResourcePermission } from '../optional-types';
import { createEnumChecker, getUserTypeIcon, getUserTypeTooltip, resetIndices } from '../utils';
import { PermissionsFilterLabel } from '../permissions-admin/permissions-admin.component';
import { PermissionsCheckboxOptions } from '../permissions-checkbox-options.enum';
import { PropertyNamesOfType } from '@app/shared/utilities/generics/type-scrubbers';
import { FilterType } from '../filter-type.enum';
import { selectCanAdminOrReadResources } from '@app/core/user/permissions/resources.selectors';
import {
  convertResourceTypeToResourceRoleType,
  getResourceColumnName,
  getResourceNameAndTypeString,
  getResourcePermissionChipValue,
} from '../resource-utils';
import { getResouces } from '@app/core/api/resources/resources-api-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import {
  getChiplistDialogMultiAutocompleteColumnName,
  getFilteredValues,
  getMultiAutocompleteInputValues,
} from '../custom-chiplist-input/custom-chiplist-input.utils';
import { getDefaultPermissionFilterOptions } from '../filter-utils';
import { ColorType } from '../color-type.enum';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { TableLayoutComponent } from '../table-layout/table-layout.component';

export interface ResourcePermissionElement extends PermissionElement {
  permissions: Array<ResourcePermission>;
  previousPermissionsList: Array<ResourcePermission>;
}

/**
 * Used for the chiplist dialog
 */
export function getResourcePermissionElementIdentifyingProperty(): string {
  return 'display_name';
}

@Component({
  selector: 'portal-resource-permissions',
  templateUrl: './resource-permissions.component.html',
  styleUrls: ['./resource-permissions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcePermissionsComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private permissionCreated$: Subject<void> = new Subject<void>(); // signals that the new permission has been created
  private permissions$: Observable<OrgQualifiedPermission>;
  public columnDefs: Map<string, Column<ResourcePermissionElement>> = new Map();
  private orgId: string;
  private users$: Observable<ListUsersResponse>;
  private resources$: Observable<Array<Resource>>;
  private resources: Array<Resource> = [];
  private resourcePermissions$: Observable<ListResourcePermissionsResponse>;
  private resourceRoles$: Observable<ListResourceRolesResponse>;
  private resourceRoles: Array<ResourceRole>;
  public filterOptions = getDefaultPermissionFilterOptions();
  public filterManager: FilterManager = new FilterManager();
  public tableData: Array<ResourcePermissionElement> = [];
  private resourceColumnNameToResourceMap: Map<string, Resource> = new Map();
  private resourceIdToResourceMap: Map<string, Resource> = new Map();
  private resourceNameAndTypeToResourceMap: Map<string, Resource> = new Map();
  private resourceMemberIds: Array<string> = [];
  public rowObjectName = 'RESOURCE PERMISSION';
  public hasPermissions: boolean;
  public linkDataSource = false;
  public paginatorConfig = new PaginatorConfig<ResourcePermissionElement>(
    true,
    true,
    25,
    5,
    new PaginatorActions<ResourcePermissionElement>(),
    'email',
    {
      previousKey: '',
      nextKey: '',
      previousWindow: [],
    }
  );
  public pageDescriptiveText = `Resources such as Shares or generic TCP services used for things like SSH or database access`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/permissions/`;

  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<ResourcePermissionElement>;

  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'type',
      {
        name: 'type',
        displayName: PermissionsFilterLabel.TYPE,
        icon: 'group',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'status',
      {
        name: 'status',
        displayName: PermissionsFilterLabel.USER,
        icon: 'person_add_disabled',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'resource',
      {
        name: 'resource',
        displayName: PermissionsFilterLabel.RESOURCE,
        icon: 'apps',
        type: FilterMenuOptionType.checkbox,
      },
    ],
  ]);

  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private usersSubscription: Subscription = new Subscription();

  constructor(
    private usersService: UsersService,
    private permissionsService: PermissionsService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private resourcesService: ResourcesService
  ) {
    this.setCheckboxFilterOptions();
  }

  private setCheckboxFilterOptions(): void {
    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.DISABLED,
      displayName: PermissionsCheckboxOptions.DISABLED,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'showDisabledUsers'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.PENDING,
      displayName: PermissionsCheckboxOptions.PENDING,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'showPendingUsers'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.USERS,
      displayName: 'User',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeUser'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.GROUPS,
      displayName: 'Group',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeGroup'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.BIGROUPS,
      displayName: 'Built-in Group',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeBigroup'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.SERVICE_ACCOUNT,
      displayName: 'Service Account',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeServiceAccount'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.HIDE_DELETED_RESOURCES,
      displayName: PermissionsCheckboxOptions.HIDE_DELETED_RESOURCES,
      label: this.filterMenuOptions.get('resource').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'hideDeletedResources'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.RESOURCE_GROUP,
      displayName: PermissionsCheckboxOptions.RESOURCE_GROUP,
      label: this.filterMenuOptions.get('resource').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleResourceGroupCheckbox(checkbox.isChecked),
    });
  }

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.permissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanAdminUsers, selectCanAdminOrReadResources)));
    this.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((permissions) => {
      this.orgId = permissions.orgId;
      this.hasPermissions = permissions.hasPermission;
      if (!this.orgId || !this.hasPermissions) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      this.resetLocalData();
      if (this.resources$ === undefined) {
        // we are fetching data for the first time
        this.updateTable();
        this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((params: UpdateTableParams) => {
          this.updateTable(params.key, params.searchDirection, params.limit);
        });
      } else {
        // this case could happen when the org is changed - we don't want to subscribe to updateTableSubject again
        this.reloadFirstPage();
      }
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private resetLocalData(): void {
    this.clearMaps();
    // Reset the list of resources so only those that apply to that org
    // are shown when switching between orgs.
    for (const resource of this.resources) {
      this.columnDefs.delete(getResourceColumnName(resource));
    }
    this.resources.length = 0;
  }

  private clearMaps(): void {
    this.resourceColumnNameToResourceMap.clear();
    this.resourceIdToResourceMap.clear();
    this.resourceNameAndTypeToResourceMap.clear();
  }

  public updateTable(
    emailKey = '',
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): void {
    const listUserParams = this.getListUserParams(emailKey, searchDirectionParam, limitParam);
    this.getData(listUserParams);

    // Combine data from above api calls into single dataSource for use
    // in the MatTable.
    forkJoin([this.users$, this.resources$, this.resourcePermissions$, this.resourceRoles$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([usersResp, resourcesResp, resourcePermissionsResp, resourceRolesResp]: [
          ListUsersResponse,
          Array<Resource>,
          ListResourcePermissionsResponse,
          ListResourceRolesResponse
        ]) => {
          if (resourcesResp.length === 0) {
            this.paginatorConfig.actions.dataFetched({
              data: [],
              searchDirection: 'forwards',
              limit: limitParam,
              nextKey: '',
              previousKey: '',
            });
            return;
          }
          this.resources = this.getFilteredResourcesList(resourcesResp);
          const permissionsColumn: ChiplistColumn<ResourcePermissionElement> = this.columnDefs.get('permissions');
          permissionsColumn.allowedValues = this.getPermissionColumnResourceAllowedValues(this.resources);
          this.resourceRoles = resourceRolesResp.resource_roles;
          this.resourceMemberIds = this.getAllResourceMemberIds(this.resources);
          this.setResourceMaps();
          this.createTableDataSource(usersResp.users, resourcePermissionsResp.resource_permissions, searchDirectionParam, limitParam);
          this.replaceTableWithCopy();
          this.paginatorConfig.actions.dataFetched({
            data: this.tableData,
            searchDirection: searchDirectionParam,
            limit: limitParam,
            nextKey: usersResp.next_page_email,
            previousKey: usersResp.previous_page_email,
          });
        },
        (err) => {
          this.paginatorConfig.actions.errorHandler(err);
        }
      );
  }

  private getPermissionColumnResourceAllowedValues(resourceList: Array<Resource>): Array<string> {
    return resourceList.map((resource) => getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type));
  }

  /**
   * We need to filter out the applications from the resources list until we finish merging the infrastructure.
   */
  private getFilteredResourcesList(resourcesList: Array<Resource>): Array<Resource> {
    return resourcesList.filter((resource) => resource.spec.resource_type !== ResourceTypeEnum.application);
  }

  private getAllResourceMemberIds(resourcesList: Array<Resource>): Array<string> {
    const resourceMemberIds: Array<string> = [];
    for (const resource of resourcesList) {
      if (!resource.spec.resource_members) {
        continue;
      }
      for (const resourceMember of resource.spec.resource_members) {
        resourceMemberIds.push(resourceMember.id);
      }
    }
    // Convert to a set to remove duplicate entries.
    return Array.from(new Set(resourceMemberIds));
  }

  private getListUserParams(
    email_key: string,
    search_direction_param: 'forwards' | 'backwards',
    limit_param: number
  ): ListUsersRequestParams {
    const params: ListUsersRequestParams = {
      org_id: this.orgId,
      status: [UserStatusEnum.active],
      limit: limit_param,
      previous_email: email_key,
      search_direction: search_direction_param,
      type: [],
      has_resource_roles: true,
    };
    if (this.filterOptions.showDisabledUsers) {
      params.status.push(UserStatusEnum.disabled);
    }
    if (this.filterOptions.showPendingUsers) {
      params.status.push(UserStatusEnum.pending);
    }
    if (this.filterOptions.onlyShowTypeUser) {
      params.type.push(User.TypeEnum.user);
    }
    if (this.filterOptions.onlyShowTypeGroup) {
      params.type.push(User.TypeEnum.group);
    }
    if (this.filterOptions.onlyShowTypeBigroup) {
      params.type.push(User.TypeEnum.bigroup);
    }
    if (this.filterOptions.onlyShowTypeServiceAccount) {
      params.type.push(User.TypeEnum.service_account);
    }
    return params;
  }

  private reloadFirstPage(): void {
    // reload table from the first page
    this.paginatorConfig.actions.reloadFirstPage();
  }

  private reloadWindow(): void {
    this.paginatorConfig.actions.reloadWindow();
  }

  private getTypeColumn(): Column<ResourcePermissionElement> {
    const typeColumn = createIconColumn('type');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    typeColumn.getDisplayValue = (element: OptionalResourcePermissionElement) => {
      if (element.status === UserStatusEnum.disabled) {
        return 'person_add_disabled';
      }
      if (element.status === UserStatusEnum.pending) {
        return 'pending_actions';
      }
      const isUserTypeEnum = createEnumChecker(User.TypeEnum);
      if (isUserTypeEnum(element.type)) {
        return getUserTypeIcon(element.type);
      }
      // This will lead to no icon being displayed.
      return '';
    };
    typeColumn.getTooltip = (element: OptionalResourcePermissionElement) => {
      if (element.status === UserStatusEnum.disabled) {
        return 'Disabled User';
      }
      if (element.status === UserStatusEnum.pending) {
        return 'Pending User';
      }
      const isUserTypeEnum = createEnumChecker(User.TypeEnum);
      if (isUserTypeEnum(element.type)) {
        return getUserTypeTooltip(element.type);
      }
      return '';
    };
    return typeColumn;
  }

  private getEmailColumn(): Column<ResourcePermissionElement> {
    const emailColumn = createAutoInputColumn('emailFormControl');
    emailColumn.displayName = 'Identity';
    emailColumn.requiredField = () => true;
    emailColumn.isEditable = true;
    emailColumn.isUnique = true;
    emailColumn.allowAnyValue = false;
    emailColumn.freezeWhenSet = true;
    emailColumn.getDisplayValue = (element: OptionalResourcePermissionElement): string => {
      return !!element.display_name ? element.display_name : element.email;
    };
    emailColumn.isReadOnly = (element: OptionalResourcePermissionElement): boolean => {
      return element.email !== '';
    };
    emailColumn.getFilteredValues = (
      element: OptionalResourcePermissionElement,
      column: AutoInputColumn<ResourcePermissionElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.emailFormControl, column);
    };
    emailColumn.isValidEntry = (
      value: string,
      element: OptionalResourcePermissionElement,
      column: AutoInputColumn<OptionalResourcePermissionElement>
    ) => {
      if (column.isReadOnly(element)) {
        // If the column is readonly, then the value has already been set. Therefore, we do not need to validate it.
        return true;
      }
      if (column.allowAnyValue) {
        return true;
      }
      return !!column.allowedValues.map(
        (item: OptionalResourcePermissionElement) => item.display_name.includes(value) || item.email.includes(value)
      );
    };
    return emailColumn;
  }

  private getPermissionsDisplayValue(
    permission: OptionalResourcePermission | string,
    column: ChiplistColumn<ResourcePermissionElement>
  ): string {
    const valueAsPermission = permission as OptionalResourcePermission;
    if (!valueAsPermission.spec) {
      const valueAsString = permission as string;
      return column.getMultiAutocompleteOptionDisplayValue(valueAsString, column);
    }
    const targetResource = this.resourceIdToResourceMap.get(valueAsPermission.spec.resource_id);
    const resourceName = !!targetResource ? targetResource.spec.name : valueAsPermission.spec.resource_id;
    const resourceRole = valueAsPermission.spec.resource_role_name;
    return getResourcePermissionChipValue(getResourceNameAndTypeString(resourceName, targetResource?.spec?.resource_type), resourceRole);
  }

  private getPermissionsSecondaryAllowedValuesList(
    inputValue: string | undefined,
    column: ChiplistColumn<ResourcePermissionElement>
  ): Array<string> {
    if (!inputValue) {
      return [];
    }
    const multiAutocompleteInputValues = getMultiAutocompleteInputValues(inputValue, column);
    const resourceValue = multiAutocompleteInputValues.firstInputValue;
    const targetResource = this.resourceNameAndTypeToResourceMap.get(resourceValue);
    if (!targetResource) {
      return [];
    }
    const targetResourceRoles = this.resourceRoles.filter(
      (role) => role.spec.resource_type === convertResourceTypeToResourceRoleType(targetResource.spec.resource_type)
    );
    return targetResourceRoles.map((role) => getResourcePermissionChipValue(resourceValue, role.spec.role_name));
  }

  /**
   * Permission string will be in the format of "resource:role"
   */
  private getResourcePermission(
    permissionString: string,
    element: OptionalResourcePermissionElement,
    column: ChiplistColumn<ResourcePermissionElement>
  ): ResourcePermission | undefined {
    const permissionStringArray = permissionString.split(column.getMultiAutocompleteChipValueSeparator());
    const targetResourceName = permissionStringArray[0];
    const targetResource = this.resourceNameAndTypeToResourceMap.get(targetResourceName);
    if (!targetResource) {
      return undefined;
    }
    const targetRole = permissionStringArray[1];
    const resourcePermission: ResourcePermission = {
      spec: {
        user_id: element.id,
        org_id: this.orgId,
        resource_id: targetResource.metadata.id,
        resource_type: convertResourceTypeToResourceRoleType(targetResource.spec.resource_type),
        resource_role_name: targetRole,
      },
    };
    return resourcePermission;
  }

  private getPermissionsColumn(): ChiplistColumn<ResourcePermissionElement> {
    const column = createChipListColumn('permissions');
    column.displayName = 'Permissions';
    column.getDisplayValue = (permission: OptionalResourcePermission) => {
      return this.getPermissionsDisplayValue(permission, column);
    };
    column.getElementFromValue = (permissionString: string, element: OptionalResourcePermissionElement): any => {
      return this.getResourcePermission(permissionString, element, column);
    };
    column.hasMultiAutocomplete = true;
    column.getSecondaryAllowedValues = (inputValue: string) => {
      return this.getPermissionsSecondaryAllowedValuesList(inputValue, column);
    };
    column.getHeaderTooltip = () => {
      return `First select the resource by name from the provided list. 
      The name is suffixed with the resource type in brackets, for example, "myShare(share)". 
      Next, select the role from the secondary list. 
      The permission will be in the format of "<resource_name>(resource_type):<role_name>".`;
    };
    column.getChipColor = (permission: OptionalResourcePermission) => {
      const targetResource = this.resourceIdToResourceMap.get(permission.spec.resource_id);
      if (!targetResource) {
        return ColorType.warn;
      }
      return ColorType.primary;
    };
    column.requiredField = () => true;
    const filterMenuOptions: Map<string, FilterMenuOption> = new Map([
      [
        getChiplistDialogMultiAutocompleteColumnName(),
        {
          name: getChiplistDialogMultiAutocompleteColumnName(),
          displayName: 'Role',
          icon: 'work',
          type: FilterMenuOptionType.text,
          placeholder: 'Enter name of role here',
        },
      ],
    ]);
    column.filterMenuOptions = filterMenuOptions;
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getTypeColumn(), this.getEmailColumn(), this.getPermissionsColumn()], this.columnDefs);
  }

  /**
   * Maps the resources/permissions to the user for display in the table.
   */
  private createTableDataSource(
    users: Array<User>,
    resourcePermissions: Array<ResourcePermission>,
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): void {
    this.tableData = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      this.setUserData(user, resourcePermissions, this.tableData, true, i);
    }
    if (this.tableData.length === 0 && this.paginatorConfig.cachedResults.nextKey !== this.paginatorConfig.cachedResults.previousKey) {
      // If all the users on the page are hidden we want to skip to the next page, if there is a next page.
      this.updateTable(this.paginatorConfig.cachedResults.nextKey, searchDirectionParam, limitParam);
    }
  }

  private setResourcePermissionElementPermissions(
    userElement: ResourcePermissionElement,
    resourcePermissions: Array<ResourcePermission>
  ): void {
    const unchangedPermissions = [];
    for (const resourcePermission of userElement.permissions) {
      // We need to remove the permission objects with metadata that were added by the user and replace them with
      // the objects retrieved from the api.
      if (!!resourcePermission.metadata) {
        unchangedPermissions.push(resourcePermission);
      }
    }
    userElement.permissions = [...unchangedPermissions];
    userElement.previousPermissionsList = [...unchangedPermissions];
    for (const resourcePermission of resourcePermissions) {
      const targetResource = this.resourceIdToResourceMap.get(resourcePermission?.spec?.resource_id);
      if (!targetResource && !!this.filterOptions.hideDeletedResources) {
        continue;
      }
      if (resourcePermission.spec.user_id === userElement.id) {
        userElement.permissions.push(resourcePermission);
        userElement.previousPermissionsList.push(resourcePermission);
      }
    }
  }

  private setUserData(
    user: User,
    resourcePermissions: Array<ResourcePermission>,
    displayedUsers: Array<ResourcePermissionElement>,
    showRow: boolean,
    index: number
  ): void {
    const userElement: ResourcePermissionElement = {
      userSelected: false,
      emailFormControl: new UntypedFormControl(),
      permissions: [],
      previousPermissionsList: [],
      ...getDefaultTableProperties(index),
      ...user,
      elementIdentifyingProperty: getResourcePermissionElementIdentifyingProperty(),
    };
    // Set the users 'showRow' property so the row will remain accurate when the
    // data is refreshed.
    userElement.showRow = showRow;
    userElement.userSelected = true;
    this.setFormControlValues(userElement);
    this.setResourcePermissionElementPermissions(userElement, resourcePermissions);
    if (userElement.permissions.length === 0) {
      // We do not want to add the user row to the table if they do not have any permissions to show
      return;
    }
    displayedUsers.push(userElement);
  }

  private setResourceMaps(): void {
    for (const resource of this.resources) {
      this.resourceColumnNameToResourceMap.set(getResourceColumnName(resource), resource);
      this.resourceIdToResourceMap.set(resource.metadata.id, resource);
      this.resourceNameAndTypeToResourceMap.set(getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type), resource);
    }
  }

  private getData(listUserParams: ListUsersRequestParams): void {
    this.users$ = this.usersService.listUsers(listUserParams);
    this.resources$ = getResouces(this.resourcesService, this.orgId);
    const listResourcePermissionsRequestParams: ListResourcePermissionsRequestParams = {
      org_id: this.orgId,
    };
    this.resourcePermissions$ = this.permissionsService.listResourcePermissions(listResourcePermissionsRequestParams);
    const listResourceRolesRequestParams: ListResourceRolesRequestParams = {
      org_id: this.orgId,
    };
    this.resourceRoles$ = this.permissionsService.listResourceRoles(listResourceRolesRequestParams);
  }

  public makeEmptyTableElement(): ResourcePermissionElement {
    const element: ResourcePermissionElement = {
      ...getDefaultNewRowProperties(),
      created: null,
      email: '',
      id: '',
      org_id: this.orgId,
      roles: {},
      userSelected: false,
      emailFormControl: new UntypedFormControl(),
      permissions: [],
      previousPermissionsList: [],
      elementIdentifyingProperty: getResourcePermissionElementIdentifyingProperty(),
    };
    this.setFormControlValues(element);
    return element;
  }

  public permissionsDefined(): boolean {
    return this.hasPermissions !== undefined;
  }

  public hasAllPermissions(): boolean {
    return this.hasPermissions;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private setFormControlValues(data: ResourcePermissionElement): void {
    data.emailFormControl.setValue(data.display_name);

    if (!data.isNew) {
      // don't need to get drop down for elements that are not new since they are read only
      return;
    }

    data.emailFormControl.valueChanges
      .pipe(
        takeUntil(this.permissionCreated$),
        takeUntil(this.unsubscribe$),
        filter((input) => input !== null),
        debounceTime(350), // allow for some delay so we don't make api calls on every keyup, only the last value is returned after 350ms
        distinctUntilChanged() // only make api calls if the latest value is different from the previous value
      )
      .subscribe((input: string) => {
        // cancel old subscription
        if (!this.usersSubscription.closed) {
          this.usersSubscription.unsubscribe();
        }
        // get users whose email prefix matches the input
        this.users$ = this.usersService.listUsers({ org_id: this.orgId, prefix_email_search: input, has_resource_roles: false });
        this.usersSubscription = this.users$.pipe(takeUntil(this.unsubscribe$)).subscribe((usersResp) => {
          // Get allowed values from list of suggested users
          this.columnDefs.get('emailFormControl').allowedValues = usersResp.users;
          // reset the form control so it rebuilds the list of allowed members
          this.columnDefs.get('emailFormControl').formControl.reset();
          // re-evaluate dropdown
          data.emailFormControl.enable();
        });
      });
  }

  private toggleCheckbox(checkboxOption: CheckboxOption, option: PropertyNamesOfType<PermissionsFilterOptions, boolean>): void {
    this.filterOptions[option] = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  private toggleResourceGroupCheckbox(isCheckboxOptionChecked: boolean): void {
    this.filterOptions.hideResourcesInGroups = isCheckboxOptionChecked;
    const permissionsColumn: ChiplistColumn<ResourcePermissionElement> = this.columnDefs.get('permissions');
    if (!!isCheckboxOptionChecked) {
      const filteredResourceList = this.resources.filter((resource) => !this.resourceMemberIds.includes(resource.metadata.id));
      permissionsColumn.allowedValues = this.getPermissionColumnResourceAllowedValues(filteredResourceList);
    } else {
      permissionsColumn.allowedValues = this.getPermissionColumnResourceAllowedValues(this.resources);
    }
    setColumnFormControlFilters(this.columnDefs);
  }

  /**
   * Adds a user without a permission to the table when selected from the
   * autocomplete dropdown.
   */
  public updateAutoInput(params: {
    optionValue: string;
    column: Column<ResourcePermissionElement>;
    element: ResourcePermissionElement;
  }): void {
    if (!params.optionValue) {
      return;
    }
    const selectedUser = this.columnDefs
      .get('emailFormControl')
      .allowedValues.find((user) => user.display_name === params.optionValue || user.email === params.optionValue);
    if (!selectedUser) {
      return;
    }
    for (const key of Object.keys(selectedUser)) {
      params.element[key] = selectedUser[key];
    }
    params.element.isNew = true;
    params.element.userSelected = true;
    resetIndices(this.tableData);
  }

  public removeSelected<T extends ResourcePermissionElement>(elementsToDelete: Array<T>): void {
    this.deleteAllPermissions(elementsToDelete);
  }

  private deleteResourcePermission$(permissionToDelete: ResourcePermission): Observable<any> {
    const deleteResourcePermissionRequestParams: DeleteResourcePermissionRequestParams = {
      resource_permission_id: permissionToDelete.metadata.id,
      org_id: this.orgId,
    };
    return this.permissionsService.deleteResourcePermission(deleteResourcePermissionRequestParams);
  }

  private prepareDeletePermissions$(elementsToDelete: Array<ResourcePermissionElement>): Array<Observable<User>> {
    const observablesArray = [];
    for (const element of elementsToDelete) {
      if (element.isChecked && element.id !== '') {
        for (const permission of element.permissions) {
          observablesArray.push(this.deleteResourcePermission$(permission));
        }
      }
    }
    return observablesArray;
  }

  private getPermissionsToDelete(updatedElement: ResourcePermissionElement): Array<ResourcePermission> {
    const currentPermissionIds = updatedElement.permissions.map((permission) => permission.metadata?.id);
    const permissionsToDelete: Array<ResourcePermission> = [];
    for (const permission of updatedElement.previousPermissionsList) {
      if (!currentPermissionIds.includes(permission.metadata.id)) {
        permissionsToDelete.push(permission);
      }
    }
    return permissionsToDelete;
  }

  private deletePermissionsList$(itemsToDelete: Array<ResourcePermission>): Array<Observable<null>> {
    const observablesArray: Array<Observable<null>> = [];
    for (const item of itemsToDelete) {
      observablesArray.push(this.deleteResourcePermission$(item));
    }
    return observablesArray;
  }

  private deleteAllPermissions<T extends ResourcePermissionElement>(elementsToDelete: Array<T>): void {
    const observablesArray = this.prepareDeletePermissions$(elementsToDelete);
    if (observablesArray.length === 0) {
      this.reloadWindow();
      this.changeDetector.detectChanges();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Permissions were successfully removed from all selected users');
        },
        (errorResp) => {
          this.notificationService.error('Failed to remove permissions from all selected users');
          this.reloadWindow();
        },
        () => {
          this.reloadWindow();
          this.changeDetector.detectChanges();
        }
      );
  }

  private createPermissionsList$(itemsToCreate: Array<ResourcePermission>): Array<Observable<ResourcePermission>> {
    const observablesArray: Array<Observable<ResourcePermission>> = [];
    for (const item of itemsToCreate) {
      observablesArray.push(this.createNewPermission$(item));
    }
    return observablesArray;
  }

  private createNewPermission$(newPermission: ResourcePermission): Observable<ResourcePermission> {
    const createResourcePermissionRequestParams: CreateResourcePermissionRequestParams = {
      ResourcePermission: newPermission,
    };
    return this.permissionsService.createResourcePermission(createResourcePermissionRequestParams);
  }

  private getPermissionsToCreate(updatedElement: ResourcePermissionElement): Array<ResourcePermission> {
    const permissionsToCreate: Array<ResourcePermission> = [];
    for (const permission of updatedElement.permissions) {
      if (!permission.spec.user_id) {
        permission.spec.user_id = updatedElement.id;
      }
      if (!permission.metadata) {
        permissionsToCreate.push(permission);
      }
    }
    return permissionsToCreate;
  }

  private updateResourcePermissionElement(
    updatedElement: ResourcePermissionElement,
    updatedPermissionsResp: Array<ResourcePermission>
  ): void {
    this.setFormControlValues(updatedElement);
    this.setResourcePermissionElementPermissions(updatedElement, updatedPermissionsResp);
    updatedElement.isNew = false;
  }

  private preparePermissionsToUpdate$(updatedElement: ResourcePermissionElement): Array<Observable<Array<ResourcePermission>>> {
    const permissionsToCreate = this.getPermissionsToCreate(updatedElement);
    const permissionsToDelete = this.getPermissionsToDelete(updatedElement);
    const createdPermissions$ = this.createPermissionsList$(permissionsToCreate);
    const deletedPermissions$ = this.deletePermissionsList$(permissionsToDelete);
    const combinedObservablesArray$ = [...createdPermissions$, ...deletedPermissions$];
    const forkJoinArray: Array<Observable<Array<ResourcePermission>>> = [];
    let accumulatorArray: Array<Observable<ResourcePermission>> = [];
    for (const obs of combinedObservablesArray$) {
      accumulatorArray.push(obs);
      if (accumulatorArray.length % 5 === 0) {
        forkJoinArray.push(forkJoin(accumulatorArray));
        accumulatorArray = [];
      }
    }
    if (accumulatorArray.length > 0) {
      forkJoinArray.push(forkJoin(accumulatorArray));
    }
    return forkJoinArray;
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: ResourcePermissionElement): void {
    const observablesArray$ = this.preparePermissionsToUpdate$(updatedElement);
    concat(...observablesArray$)
      .pipe(
        reduce((permissions: Array<ResourcePermission>, permission) => permissions.concat(permission), []),
        concatMap((updatedPermissionsResp) => {
          return [updatedPermissionsResp];
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (updatedPermissionsResp) => {
          // Deleted permission responses are null, so we need to remove these from the list
          const filteredUpdatedPermissionsResp = updatedPermissionsResp.filter((permission) => !!permission);
          this.notificationService.success(`Permissions were successfully updated for "${updatedElement.display_name}"`);
          this.updateResourcePermissionElement(updatedElement, filteredUpdatedPermissionsResp);
          this.changeDetector.detectChanges();
        },
        (errorResp) => {
          this.notificationService.error(
            `Failed to update all permissions for "${updatedElement.display_name}". Please refresh the page and try again.`
          );
        }
      );
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
