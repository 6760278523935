import { Component, ChangeDetectionStrategy, Input, OnChanges } from '@angular/core';
import { Group, MFAChallengeAnswerResult, PolicyGroup, PolicyRule } from '@agilicus/angular';
import { createDropdownSelector, DropdownSelector } from '../dropdown-selector/dropdown-selector-utils';
import { createEnumChecker, getDefaultMfaActionDisplayValue, getMFAChallengeAnswerResultOptions } from '../utils';
import { ActionPolicyDeletingPolicyRule, ActionPolicySavingPolicyRule } from '@app/core/issuer/issuer.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core';
import { cloneDeep } from 'lodash-es';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';

@Component({
  selector: 'portal-auth-issuer-policy-rule',
  templateUrl: './auth-issuer-policy-rule.component.html',
  styleUrls: ['./auth-issuer-policy-rule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthIssuerPolicyRuleComponent implements OnChanges {
  @Input() public currentPolicyRule: PolicyRule;
  @Input() public currentPolicyGroup: PolicyGroup;
  @Input() public groupIdToGroupMap: Map<string, Group> = new Map();
  @Input() public groupDisplayNameToGroupMap: Map<string, Group> = new Map();
  public currentPolicyRuleCopy: PolicyRule;
  public policyRuleActionDropdownSelector: DropdownSelector;
  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  constructor(private store: Store<AppState>) {}

  public ngOnChanges(): void {
    if (!this.currentPolicyRule) {
      return;
    }
    this.currentPolicyRuleCopy = cloneDeep(this.currentPolicyRule);
    this.policyRuleActionDropdownSelector = this.createpolicyRuleActionDropdownSelector();
  }

  private getActionFromPolicyRule(policyRule: PolicyRule): MFAChallengeAnswerResult.ActionEnum | undefined {
    const isMfaActionEnum = createEnumChecker(MFAChallengeAnswerResult.ActionEnum);
    if (isMfaActionEnum(policyRule?.spec?.action)) {
      return policyRule.spec.action;
    } else {
      return undefined;
    }
  }

  public updatePolicyRuleAction(selectedAction: MFAChallengeAnswerResult.ActionEnum): void {
    this.currentPolicyRuleCopy.spec.action = selectedAction;
    this.savePolicyRule();
  }

  public updateRuleName(updatedRuleName: string): void {
    if (!updatedRuleName) {
      return;
    }
    if (this.currentPolicyRuleCopy.spec.name === updatedRuleName) {
      return;
    }
    this.currentPolicyRuleCopy.spec.name = updatedRuleName;
    this.savePolicyRule();
    return;
  }

  public createpolicyRuleActionDropdownSelector(): DropdownSelector {
    const dropdownSelector = createDropdownSelector();
    dropdownSelector.selectorLabel = 'Action';
    dropdownSelector.selectorElement = this.currentPolicyRuleCopy;
    dropdownSelector.selectorData = getMFAChallengeAnswerResultOptions();
    dropdownSelector.selectorTooltipText = `Select the action to take if the rule's conditions are met`;
    dropdownSelector.getSelectorValueFromElement = this.getActionFromPolicyRule.bind(this);
    dropdownSelector.getSelectorOptionDisplayValue = getDefaultMfaActionDisplayValue;
    dropdownSelector.onSelectionChange = this.updatePolicyRuleAction.bind(this);
    return dropdownSelector;
  }

  private savePolicyRule(): void {
    this.store.dispatch(new ActionPolicySavingPolicyRule(this.currentPolicyRuleCopy));
  }

  public deletePolicyRule(): void {
    this.store.dispatch(new ActionPolicyDeletingPolicyRule(this.currentPolicyRuleCopy));
  }
}
