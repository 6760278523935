import { PolicyTemplateInstance, PolicyTemplatesService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import {
  createNewPolicyTemplateInstance$,
  deleteExistingPolicyTemplateInstance$,
  getPolicyTemplateInstanceById$,
  getPolicyTemplateInstancesList$,
  updateExistingPolicyTemplateInstance$,
} from '../api/policy-template-instance/policy-template-instance-api-utils';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';
import * as PolicyTemplateInstanceActions from '../policy-template-instance-state/policy-template-instance.actions';

@Injectable({
  providedIn: 'root',
})
export class PolicyTemplateInstanceStateService extends SimpleCRUDInterface<PolicyTemplateInstance, string> {
  public crudRegistryName = CrudStateCollection.policy_template_instance;

  constructor(
    public store: Store<AppState>,
    private policyTemplatesService: PolicyTemplatesService,
    private crudRegistryService: CrudRegistryService<PolicyTemplateInstance, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<PolicyTemplateInstance, string> = {
        create: (id: string, obj: PolicyTemplateInstance, orgId: string) =>
          createNewPolicyTemplateInstance$(this.policyTemplatesService, obj),
        update: (id: string, obj: PolicyTemplateInstance, orgId: string) =>
          updateExistingPolicyTemplateInstance$(this.policyTemplatesService, obj),
        get: (id: string, orgId: string) => getPolicyTemplateInstanceById$(this.policyTemplatesService, id, orgId),
        list: (orgId: string) => getPolicyTemplateInstancesList$(this.policyTemplatesService, orgId),
        delete: (id: string, orgId: string) => deleteExistingPolicyTemplateInstance$(this.policyTemplatesService, id, orgId),
      };
      const crudRegistry: CrudRegistry<PolicyTemplateInstance, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: PolicyTemplateInstance) => obj.spec.name,
        doneSaveAction: PolicyTemplateInstanceActions.upsertPolicyTemplateInstance,
        doneSaveListAction: PolicyTemplateInstanceActions.upsertPolicyTemplateInstances,
        doneDeleteAction: PolicyTemplateInstanceActions.deletePolicyTemplateInstance,
        doneDeleteListAction: PolicyTemplateInstanceActions.deletePolicyTemplateInstances,
        setListAction: PolicyTemplateInstanceActions.loadPolicyTemplateInstances,
        refreshStateAction: PolicyTemplateInstanceActions.refreshPolicyTemplateInstances,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
