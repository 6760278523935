import { FilterOption } from './filter/filter-manager';
import { PermissionsFilterOptions } from './permissions-filter-options';

/**
 * Utility function that searches a list for a specific entry with a matching name and label
 * @param name The name to match against
 * @param label The label to match against
 * @param list The list to search in
 */
export function findFilterOptionByName<T extends FilterOption>(name: string, label: string, list: Array<T>): T {
  for (const entry of list) {
    if (entry.name === name && entry.label === label) {
      return entry;
    }
  }
  return null;
}

/**
 * Utility function that removes an entry from a list
 */
export function removeFromList<T extends object>(entry: T, list: Array<T>): void {
  const index = list.indexOf(entry);
  if (index >= 0) {
    list.splice(index, 1);
  }
}

export function getDefaultPermissionFilterOptions(): PermissionsFilterOptions {
  return {
    showDisabledUsers: false,
    showPendingUsers: false,
    onlyShowTypeUser: false,
    onlyShowTypeGroup: false,
    onlyShowTypeBigroup: false,
    onlyShowTypeServiceAccount: false,
    hideResourcesInGroups: false,
    hideDeletedResources: false,
  };
}
