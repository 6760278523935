import { HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, of, throwError } from 'rxjs';

export interface ApiDataCreation<S, T> {
  service: S;
  newData: T;
  updateExistingFunc: (service: any, newData: T) => Observable<T>;
}

export function createNewApiDataHandleErrors<S, T extends object>(
  data$: Observable<T>,
  appModelCreationData?: ApiDataCreation<S, T>
): Observable<T> {
  return data$.pipe(
    map((resp) => {
      return resp;
    }),
    catchError((err) => {
      const existingData: T = getExistingData(err);
      if (!existingData) {
        return throwError(err);
      }
      if (!!appModelCreationData) {
        for (const key of Object.keys(appModelCreationData.newData)) {
          existingData[key] = appModelCreationData.newData[key];
        }
        return appModelCreationData.updateExistingFunc(appModelCreationData.service, existingData);
      }
      return of(existingData);
    })
  );
}

export function getExistingData<T extends object>(err: HttpErrorResponse): T | undefined {
  if (err.status === 409) {
    const existingData: T = err.error;
    return existingData;
  }
  return undefined;
}

export function getMoreThanOneResultErrorMessage(): string {
  return 'moreThanOneResult';
}

export function getMoreThanOneResultError(list: Array<any>): HttpErrorResponse | undefined {
  if (!!list && list.length > 1) {
    return new HttpErrorResponse({ error: { error_message: getMoreThanOneResultErrorMessage() } });
  }
  return undefined;
}
