import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { UsageMetricsComponent } from './usage-metrics.component';

@NgModule({
  imports: [CommonModule, MatTableModule, MatTooltipModule],
  declarations: [UsageMetricsComponent],
  exports: [UsageMetricsComponent],
})
export class UsageMetricsModule {}
