import { createSelector, select } from '@ngrx/store';
import { filter, map, pipe } from 'rxjs';
import { selectConnectorState } from '../core.state';
import { ConnectorState, selectAll, selectEntities } from './connector.reducer';

export const selectConnectors = createSelector(selectConnectorState, (state: ConnectorState) => state);

export const selectConnectorList = createSelector(selectConnectors, selectAll);

export const selectConnectorEntities = createSelector(selectConnectors, selectEntities);

export const selectConnectorEntity = (props: { id: string }) =>
  createSelector(selectConnectorEntities, (entities) => {
    return entities[props.id];
  });

export const selectConnectorRefreshDataValue = createSelector(selectConnectors, (state: ConnectorState) => state.refresh_data);

export const selectConnectorShouldPopulateValue = createSelector(selectConnectors, (state: ConnectorState) => state.should_populate);

export const selectHasAtLeastOneConnector = pipe(
  select(selectConnectorList, selectConnectorRefreshDataValue),
  filter((connectorsList, refreshVal) => !!connectorsList && refreshVal > 0),
  map((connectorsList, refreshVal) => connectorsList.length !== 0)
);
