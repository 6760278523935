import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';
import { FaqItem } from '@app/core/models/faq.model';

const faqKey = 'faqData';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  private perPageLimit = 100;
  private serviceUrl = `https://www.agilicus.com/wp-json/wp/v2/ufaq?per_page=${this.perPageLimit.toString()}`;

  constructor(private http: HttpClient) {}

  public getItems$(url: string, categoriesToExclude: Array<string> = []): Observable<Array<FaqItem> | undefined> {
    return this.http
      .get<Array<FaqItem>>(url, {
        headers: getIgnoreErrorsHeader(),
      })
      .pipe(
        map((res) => {
          for (const item of res) {
            const categoryList = [];
            for (const classItem of item.class_list) {
              if (classItem.includes(this.getFaqCategoryTag())) {
                categoryList.push(classItem.replace(this.getFaqCategoryTag(), ''));
              }
            }
            item.categories = categoryList;
          }
          return this.getValidFaqItems(res, categoriesToExclude).map((item) =>
            this.removeExcludedCategoriesFromItem(item, categoriesToExclude)
          );
        }),
        catchError((err) => {
          return of(undefined);
        })
      );
  }

  private removeExcludedCategoriesFromItem(faqItem: FaqItem, categoriesToExclude: Array<string> = []): FaqItem {
    const validCategories = [];
    for (const category of faqItem.categories) {
      if (!categoriesToExclude.includes(category)) {
        validCategories.push(category);
      }
    }
    faqItem.categories = validCategories;
    return faqItem;
  }

  public async getAllItemsAsPromise(categoriesToExclude: Array<string> = []): Promise<Array<FaqItem>> {
    let promiseArray: Array<Array<FaqItem>> = [];
    for (let i = 1; i < 1000; i++) {
      const url = `${this.serviceUrl}&page=${i}`;
      const result = await lastValueFrom(this.getItems$(url, categoriesToExclude));
      if (!result) {
        break;
      }
      promiseArray.push(result);
      if (result.length < this.perPageLimit) {
        break;
      }
    }
    return promiseArray.flat();
  }

  public getAllItemsAsObservableArray$(categoriesToExclude: Array<string> = []): Observable<Array<FaqItem>> {
    return from(this.getAllItemsAsPromise(categoriesToExclude));
  }

  public getFaqDataFromSessionStorage(): Array<FaqItem> | undefined {
    const faqItems: Array<FaqItem> = !!sessionStorage.getItem(faqKey) ? JSON.parse(sessionStorage.getItem(faqKey)) : undefined;
    return !!faqItems ? this.getValidFaqItems(faqItems) : undefined;
  }

  public getValidFaqItems(faqItems: Array<FaqItem>, categoriesToExclude: Array<string> = []): Array<FaqItem> {
    return faqItems.filter((item) => {
      return (
        item.class_list !== undefined &&
        item.content?.rendered !== undefined &&
        item.excerpt?.rendered !== undefined &&
        item.link !== undefined &&
        item.title?.rendered !== undefined &&
        item.categories !== undefined &&
        !this.excludeItemBasedOnCategories(item, categoriesToExclude)
      );
    });
  }

  public excludeItemBasedOnCategories(faqItem: FaqItem, categoriesToExclude: Array<string>): boolean {
    for (const category of faqItem.categories) {
      if (!categoriesToExclude.includes(category)) {
        return false;
      }
    }
    return true;
  }

  public getFaqCategoryTag(): string {
    return 'ufaq-category-';
  }
}
