import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Inject, Component, OnInit, OnDestroy, Renderer2, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ENTER, COMMA, TAB } from '@angular/cdk/keycodes';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { TableElement } from '../table-layout/table-element';
import {
  SelectColumn,
  Column,
  createInputColumn,
  createSelectRowColumn,
  createSelectColumn,
  createChipListColumn,
  setColumnDefs,
  AutoInputColumn,
  createAutoInputColumn,
  ChiplistColumn,
} from '../table-layout/column-definitions';
import { IssuerClient, AuthenticationAttribute, UpstreamAliasMapping, UpstreamAlias, Issuer } from '@agilicus/angular';
import { useValueIfNotInMap, createEnumChecker, capitalizeFirstLetter, updateTableElements, replaceCharacterWithSpace } from '../utils';
import { NotificationService } from '@app/core';
import { ActionIssuerClientsSavingIssuerClient } from '@app/core/issuer-clients/issuer-clients.actions';
import { cloneDeep } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core';
import { Observable, Subject } from 'rxjs';
import { map, filter, take, takeUntil } from 'rxjs/operators';
import { downloadTextFileData, uploadDataFromTextFile } from '../file-utils';
import { getAllPaths } from '@app/shared/utilities/model-helpers/issuers';
import { OptionalAuthenticationAttributeElement } from '../optional-types';
import { FilterChipOptions } from '../filter-chip-options';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { FilterManager } from '../filter/filter-manager';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { createChiplistInput, getFilteredValues } from '../custom-chiplist-input/custom-chiplist-input.utils';

export interface IssuerClientElement extends IssuerClient, TableElement {}

export enum ApplicationDefaultOption {
  ALL = 'All',
}

export interface AuthClientDialogData {
  columnDefs: Map<string, Column<IssuerClientElement>>;
  data: IssuerClientElement;
  type: 'Add' | 'Edit';
  orgMap: Map<string, string>;
  store: Store<AppState>;
  org_id: string;
  identyProviderNames: Array<string>;
  upstreamAlias: UpstreamAlias;
  issuer: Issuer;
}

export interface AuthenticationAttributeElement extends TableElement, AuthenticationAttribute {
  fieldFormControl: UntypedFormControl;
}

export interface UpstreamAliasElement extends TableElement, UpstreamAliasMapping {}

@Component({
  selector: 'portal-application-auth-clients-dialog',
  templateUrl: './application-auth-clients-dialog.component.html',
  styleUrls: ['./application-auth-clients.component.scss', '../../shared.scss'],
})
export class ApplicationAuthClientsDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public capitalizeFirstLetter = capitalizeFirstLetter;
  public updateAuthForm: UntypedFormGroup;
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA, TAB],
  };
  public tableLayout = new TableLayoutComponent(this.notificationService, this.changeDetector);
  public dialogTitle: string;
  public saveButton = 'SAVE';
  public saveDisabled = false;
  public authAttributes: Array<AuthenticationAttributeElement> = [];
  public attributeColumns: Map<string, Column<AuthenticationAttributeElement>> = new Map();
  public makeEmptyAuthAttributeElement = this.makeEmptyTableElement.bind(this);
  public removeAttributesFunc = this.removeAttributes.bind(this);
  private validSAMLMetadataFileType = 'xml';
  public aliasColumnDefs: Map<string, Column<UpstreamAliasElement>> = new Map();
  public aliasTableData: Array<UpstreamAliasElement> = [];
  public makeEmptyUpsteamAliasTableElementFunc = this.makeEmptyUpsteamAliasTableElement.bind(this);
  public filterManager: FilterManager = new FilterManager();
  public redirectsChiplistInput: ChiplistInput<IssuerClientElement>;

  public replaceCharacterWithSpace = replaceCharacterWithSpace;

  constructor(
    public dialogRef: MatDialogRef<ApplicationAuthClientsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AuthClientDialogData,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.dialogTitle = this.data.type + ' Client';
    this.initializeFormGroup();
    this.redirectsChiplistInput = this.getRedirectsChiplistInput();
    this.tableLayout.columnDefs = this.data.columnDefs;
    if (this.data.type === 'Add') {
      this.saveButton = 'ADD';
    }
    this.initializeAttributeColumns();
    this.initializeAliasColumnDefs();
    if (this.updateAuthForm.value.attributes) {
      this.buildData(this.updateAuthForm.value.attributes);
    }
    this.replaceTableWithCopy();
    if (this.data.upstreamAlias === undefined) {
      this.resetEmptyAliasesTable();
    } else {
      this.updateAliasesTable();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.changeDetector.detach();
  }

  private buildData(attributes: Array<AuthenticationAttribute>): void {
    const data: Array<AuthenticationAttributeElement> = [];
    for (let i = 0; i < attributes.length; i++) {
      data.push(this.createAuthAttributeElement(attributes[i], i));
    }
    updateTableElements(this.authAttributes, data);
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.authAttributes];
    this.authAttributes = tableDataCopy;
  }

  public createAuthAttributeElement(authAttribute: AuthenticationAttribute, index: number): AuthenticationAttributeElement {
    const data: AuthenticationAttributeElement = {
      fieldFormControl: new UntypedFormControl(),
      ...getDefaultTableProperties(index),
      ...authAttribute,
    };
    data.fieldFormControl.setValue(authAttribute.internal_attribute_path);
    return data;
  }

  private getNameColumn(): Column<AuthenticationAttributeElement> {
    const nameColumn = createInputColumn('attribute_name');
    nameColumn.displayName = 'Attribute Name';
    nameColumn.requiredField = () => true;
    nameColumn.isEditable = true;
    nameColumn.isCaseSensitive = true;
    nameColumn.isValidEntry = (name: string): boolean => {
      const namePattern = /^[A-Za-z][A-Za-z0-9_]*$/;
      return name.length < 511 && name.match(namePattern) !== null;
    };
    return nameColumn;
  }

  private getFieldColumn(): AutoInputColumn<AuthenticationAttributeElement> {
    const fieldColumn = createAutoInputColumn('fieldFormControl');
    fieldColumn.displayName = 'Field';
    fieldColumn.isEditable = true;
    fieldColumn.isCaseSensitive = true;
    fieldColumn.getDisplayValue = (element: OptionalAuthenticationAttributeElement) => {
      if (!!element?.internal_attribute_path) {
        return element.internal_attribute_path;
      }
      return element as string;
    };
    fieldColumn.allowedValues = getAllPaths();
    fieldColumn.getFilteredValues = (
      element: OptionalAuthenticationAttributeElement,
      column: AutoInputColumn<AuthenticationAttributeElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.fieldFormControl, column);
    };
    return fieldColumn;
  }

  public initializeAttributeColumns(): void {
    setColumnDefs([createSelectRowColumn(), this.getNameColumn(), this.getFieldColumn()], this.attributeColumns);
  }

  public makeEmptyTableElement(): AuthenticationAttributeElement {
    return {
      attribute_name: '',
      internal_attribute_path: '',
      fieldFormControl: new UntypedFormControl(),
      ...getDefaultNewRowProperties(),
    };
  }

  // updateAttributes is called after each edit of the attributes table
  public updateAttributes(): void {
    // re-enable the Add Attribute button which is disabled when the first element of the table has isNew as true (new row is added)
    this.authAttributes[0].isNew = false;
  }

  public removeAttributes(): void {
    // create a new list which doesn't have the checked elements
    const newAttributes = [];
    for (const element of this.authAttributes) {
      if (!element.isChecked) {
        newAttributes.push(element);
      }
    }
    this.authAttributes = newAttributes;
  }

  public disableDownload(): boolean {
    const metadata = this.data.data.saml_metadata_file;
    if (!metadata) {
      return true;
    }
    return metadata.length === 0;
  }

  public removeChip(redirect: string, redirects: Array<string>): void {
    const index = redirects.indexOf(redirect);
    if (index >= 0) {
      redirects.splice(index, 1);
    }
    this.changeDetector.detectChanges();
  }

  private initializeFormGroup(): void {
    const org_scope: SelectColumn<IssuerClientElement> = this.data.columnDefs.get('organisation_scope');
    this.updateAuthForm = this.formBuilder.group({
      client_id: [this.data.data.name, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
      application: [
        this.tableLayout.getSelectionDisplayValue(this.data.columnDefs.get('application'), this.data.data),
        [Validators.maxLength(100)],
      ],
      secret: [this.data.data.secret, [Validators.maxLength(255)]],
      organisation_scope: [org_scope.getMultipleDisplayValues(this.data.data)],
      mfa_challenge: [this.data.data.mfa_challenge],
      single_sign_on: [this.data.data.single_sign_on],
      redirects: [], // redirects is initially populated by the ngFor in the HTML
      saml_metadata_file: [this.data.data.saml_metadata_file, [Validators.maxLength(1048576)]],
      attributes: [this.data.data.attributes],
    });
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  public onSaveClick(): void {
    // update issuer client element with values from the form
    this.data.data.name = this.updateAuthForm.value.client_id;
    this.data.data.secret = this.updateAuthForm.value.secret;
    if (this.updateAuthForm.value.application === ApplicationDefaultOption.ALL) {
      this.data.data.application = '';
    } else {
      this.data.data.application = this.updateAuthForm.value.application;
    }
    this.data.data.mfa_challenge = this.updateAuthForm.value.mfa_challenge;
    this.data.data.single_sign_on = this.updateAuthForm.value.single_sign_on;
    if (!this.data.data.saml_metadata_file) {
      this.data.data.saml_metadata_file = '';
    }
    // if issuer client's and form's attributes are empty, leave it empty
    // otherwise update the client with the new values
    if (this.authAttributes.length !== 0 || this.data.data.attributes) {
      this.data.data.attributes = [];
      for (const attribute of this.authAttributes) {
        // don't take table element related fields
        this.data.data.attributes.push({
          attribute_name: attribute.attribute_name,
          internal_attribute_path: attribute.internal_attribute_path,
        });
      }
    }
    this.saveIssuerClient(this.data.data);
  }

  public getAllowedValues(column: string): Array<any> {
    return this.data.columnDefs.get(column).allowedValues;
  }

  public getOptionValue(columnName: string, option: any): string {
    const column: SelectColumn<IssuerClientElement> = this.data.columnDefs.get(columnName);
    return column.getOptionValue(option, this.data.data);
  }

  private setAllowedOrganisationsPropertiesOnUpdates(
    element: IssuerClientElement,
    orgScopeValue: IssuerClient.OrganisationScopeEnum,
    restrictedOrgsValue: Array<string>
  ): void {
    element.organisation_scope = orgScopeValue;
    element.restricted_organisations = restrictedOrgsValue;
  }

  /**
   * Triggered when a user checks an option from the multiple select dropdown menu.
   */
  public updateMultipleSelection(params: {
    value: Array<string | IssuerClient.OrganisationScopeEnum>;
    element: IssuerClientElement;
  }): void {
    const isOrganisationScopeEnum = createEnumChecker(IssuerClient.OrganisationScopeEnum);
    const firstValue = params.value[0];
    if (params.value.length === 1 && isOrganisationScopeEnum(firstValue)) {
      // A single organisation_scope has been selected.
      this.setAllowedOrganisationsPropertiesOnUpdates(params.element, firstValue, []);
      return;
    }
    for (const value of params.value) {
      const targetOrgId = this.data.orgMap.get(value);
      if (targetOrgId || !isOrganisationScopeEnum(value)) {
        continue;
      }
      // An organisation_scope enum has been selected.
      if (
        (value === IssuerClient.OrganisationScopeEnum.any && params.element.restricted_organisations.length !== 0) ||
        value !== params.element.organisation_scope
      ) {
        this.setAllowedOrganisationsPropertiesOnUpdates(params.element, value, []);
        return;
      }
    }
    // Only specific orgs have been selected.
    const updatedRestrictedOrgs = params.value
      .filter((item) => !isOrganisationScopeEnum(item))
      .map((orgName) => {
        return useValueIfNotInMap(orgName, this.data.orgMap);
      });
    this.setAllowedOrganisationsPropertiesOnUpdates(params.element, IssuerClient.OrganisationScopeEnum.any, updatedRestrictedOrgs);
  }

  private saveIssuerClient(updatedIssuerClient: IssuerClientElement): void {
    // Need to make a copy of the updatedIssuerClient or it will be
    // converted to readonly.
    this.setUpstreamAliasFromTable();
    // If the org does not have access to the issuer data then we cannot update the upstream alias
    const upstreamAlias = !!this.data.issuer ? this.data.upstreamAlias : undefined;
    this.data.store.dispatch(new ActionIssuerClientsSavingIssuerClient(cloneDeep(updatedIssuerClient), upstreamAlias));

    this.updateAuthForm.disable();
    this.saveDisabled = true;
    this.notificationService.default('Saving client "' + updatedIssuerClient.name + '"');

    this.data.store
      .select('issuerClients')
      .pipe(
        takeUntil(this.unsubscribe$),
        map((state) => {
          if (!state.saving_issuer_client && state.successful_issuer_client_save) {
            // close the dialog if the save was successful
            return 'close';
          }
          if (!state.saving_issuer_client && !state.successful_issuer_client_save) {
            // re-enable the form if the save was unsuccessful
            return 'do_nothing';
          } else {
            return 'skip';
          }
        }),
        filter((action) => action !== 'skip'),
        take(1)
      )
      .subscribe((action) => {
        if (action === 'close') {
          this.dialogRef.close(true);
        } else if (action === 'do_nothing') {
          this.updateAuthForm.enable();
          this.saveDisabled = false;
        }
      });
  }

  public downloadSPMetadata(): void {
    downloadTextFileData(this.data.data.saml_metadata_file, this.renderer, this.validSAMLMetadataFileType, 'metadata');
  }

  public uploadMetadata(event: any): void {
    uploadDataFromTextFile(event, this.notificationService, this.onReadSAMLFile.bind(this), this.validSAMLMetadataFileType);
  }

  public onReadSAMLFile(reader: FileReader): void {
    this.data.data.saml_metadata_file = reader.result.toString();
    this.updateAuthForm.value.saml_metadata_file = reader.result.toString();
  }

  public updateMetadata(): void {
    this.data.data.saml_metadata_file = this.updateAuthForm.value.saml_metadata_file;
  }

  public clearMetadata(): void {
    this.data.data.saml_metadata_file = '';
    this.updateAuthForm.value.saml_metadata_file = '';
  }

  private getUpstreamProviderNameColumn(): SelectColumn<IssuerClientElement> {
    const upstreamProviderNameColumn = createSelectColumn('upstream_provider_name');
    upstreamProviderNameColumn.displayName = 'Identity provider';
    upstreamProviderNameColumn.isEditable = true;
    upstreamProviderNameColumn.allowedValues = this.data.identyProviderNames;
    return upstreamProviderNameColumn;
  }

  private getAliasesColumn(): Column<UpstreamAliasElement> {
    const aliasesColumn = createChipListColumn('aliased_upstream_provider_names');
    aliasesColumn.isEditable = true;
    aliasesColumn.isUnique = true;
    aliasesColumn.allowedValues = this.data.identyProviderNames;
    return aliasesColumn;
  }

  private initializeAliasColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getUpstreamProviderNameColumn(), this.getAliasesColumn()], this.aliasColumnDefs);
  }

  private makeEmptyUpsteamAliasTableElement(): UpstreamAliasElement {
    return {
      upstream_provider_name: '',
      aliased_upstream_provider_names: [],
      ...getDefaultNewRowProperties(),
    };
  }

  public updateAliasSelection(params: { value: string; column: Column<UpstreamAliasElement>; element: UpstreamAliasElement }): void {
    params.element.upstream_provider_name = params.value;
  }

  private removeElements(): void {
    this.aliasTableData = this.aliasTableData.filter((element) => !element.isChecked);
  }

  public deleteSelectedUpstreamAliases(): void {
    this.removeElements();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyAliasesTable(): void {
    this.aliasTableData.length = 0;
  }

  private updateAliasesTable(): void {
    this.buildAliasesTableData();
    this.replaceAliasesTableWithCopy();
  }

  private buildAliasesTableData(): void {
    const data: Array<UpstreamAliasElement> = [];
    for (let i = 0; i < this.data.upstreamAlias.spec.aliases.length; i++) {
      data.push(this.createUpstreamAliasElement(this.data.upstreamAlias.spec.aliases[i], i));
    }
    updateTableElements(this.aliasTableData, data);
  }

  private createUpstreamAliasElement(upstreamAliasMapping: UpstreamAliasMapping, index: number): UpstreamAliasElement {
    const data: UpstreamAliasElement = {
      ...upstreamAliasMapping,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private replaceAliasesTableWithCopy(): void {
    const tableDataCopy = [...this.aliasTableData];
    this.aliasTableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private getUpstreamAliasMappingsFromTable(): Array<UpstreamAliasMapping> {
    const upstreamAliasMappings: Array<UpstreamAliasMapping> = [];
    for (const element of this.aliasTableData) {
      upstreamAliasMappings.push({
        upstream_provider_name: element.upstream_provider_name,
        aliased_upstream_provider_names: element.aliased_upstream_provider_names,
      });
    }
    return upstreamAliasMappings;
  }

  private setUpstreamAliasFromTable(): void {
    if (!!this.data.upstreamAlias) {
      this.data.upstreamAlias.spec.aliases = this.getUpstreamAliasMappingsFromTable();
      return;
    }
    const newUpstreamAlias: UpstreamAlias = {
      spec: {
        client_id: this.data.data.id,
        org_id: this.data.org_id,
        aliases: this.getUpstreamAliasMappingsFromTable(),
      },
    };
    this.data.upstreamAlias = newUpstreamAlias;
  }

  public updateAutoInput(params: {
    optionValue: string;
    column: AutoInputColumn<AuthenticationAttributeElement>;
    element: AuthenticationAttributeElement;
  }): void {
    if (params.column.name !== 'fieldFormControl') {
      return;
    }
    if (params.optionValue === params.element.internal_attribute_path) {
      // The value has not been changed.
      return;
    }
    params.element.internal_attribute_path = params.optionValue;
    params.element.dirty = true;
  }

  public getRedirectsColumn(): ChiplistColumn<IssuerClientElement> {
    return this.tableLayout.columnDefs.get('redirects');
  }

  private getRedirectsChiplistInput(): ChiplistInput<IssuerClientElement> {
    const redirectsChiplistInput = createChiplistInput('redirects');
    redirectsChiplistInput.allowedValues = this.data.data.redirects;
    redirectsChiplistInput.hasAutocomplete = false;
    redirectsChiplistInput.isFreeform = true;
    return redirectsChiplistInput;
  }
}
