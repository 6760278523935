import { UsersService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import { withLatestFrom, mergeMap, concatMap, map } from 'rxjs/operators';
import { AppState, NotificationService, selectSignupState } from '..';
import { getMergedRoute } from '../merged-route';
import { AuthService } from '../services/auth-service.service';
import { selectUser } from '../user/user.selectors';
import {
  ActionSignupBounce,
  ActionSignupReAuth,
  ActionSignupInit,
  SignupActionTypes,
  ActionSignupMaintainState,
  ActionSignupRedirecting,
  ActionSignupBegin,
  ActionSignupNukeState,
} from './signup.actions';

@Injectable()
export class SignupEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  public initState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupActionTypes.INIT),
      concatMap((action: ActionSignupInit) =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(getMergedRoute)),
            this.store.pipe(select(selectUser)),
            this.store.pipe(select(selectSignupState))
          )
        )
      ),
      mergeMap(([action, route, user, signupState]) => {
        if (route?.queryParams?.signup_bounce === 'true') {
          // arrived at new domain, do re-authentication
          return of(new ActionSignupReAuth());
        }
        if (!signupState.is_signing_up) {
          // Kick off signup
          return of(new ActionSignupBegin());
        }
        // show a blank page for a signed in user that is not going through signup
        return of(new ActionSignupMaintainState());
      })
    )
  );

  public onReAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupActionTypes.SIGNUP_RE_AUTH),
      concatMap((action: ActionSignupReAuth) => {
        // state has been updated with reAuth state, so login to new domain
        this.authService.login(window.location.pathname);
        return of(new ActionSignupRedirecting());
      })
    )
  );

  public signupBounce$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupActionTypes.SIGNUP_BOUNCE),
      concatMap((action: ActionSignupBounce) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectSignupState))))),
      mergeMap(([action, signupState]) => {
        if (!!signupState?.redirect_uri) {
          this.nukeState();
          this.navigateToURI(signupState.redirect_uri);
        } else {
          this.notificationService.error('Failed to redirect to new domain');
        }
        return of(new ActionSignupMaintainState()); // signup process complete on this domain
      })
    )
  );

  public cancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SignupActionTypes.CANCEL_SIGNUP),
      concatMap(() => {
        this.nukeState();
        return of(new ActionSignupNukeState());
      })
    )
  );

  public resetWindow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SignupActionTypes.SIGNUP_NUKE_STATE),
        map((action: ActionSignupNukeState) => {
          if (action.reload) {
            window.location.reload();
          }
        })
      ),
    { dispatch: false }
  );

  private nukeState(): void {
    // we cannot safely call logout.
    // we have half the ngrx state on this domain, and are about
    // to recreate on the new.
    // If something goes wrong and the user retries, we are stuck.
    window.localStorage.clear();
  }

  public navigateToURI(uri: string): void {
    window.location.href = uri;
  }
}
