import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { FilterManager } from '../filter/filter-manager';
import { Column, createInputColumn, createSelectRowColumn, setColumnDefs } from '../table-layout/column-definitions';
import { Subject, Observable, combineLatest } from 'rxjs';
import { Application, JSONBodyConstraint, RuleConfig, RuleQueryBody, RuleV2 } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { TableElement } from '../table-layout/table-element';
import { getEmptyStringIfUnset, updateTableElements, capitalizeFirstLetter } from '../utils';
import { ActionApiApplicationsSavingRule } from '@app/core/api-applications/api-applications.actions';
import { RuleQueryBodyJSON } from '@agilicus/angular';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectApiApplications, selectApiApplicationsRefreshData } from '@app/core/api-applications/api-applications.selectors';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import {
  selectPolicyTemplateInstanceRefreshDataValue,
  selectPolicyTemplateInstanceResourcesList,
} from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import {
  getPolicyDataBeforeApplicationInit$,
  getTargetPolicyTemplateInstanceResource,
  PolicyTemplateInstanceResource,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { savingPolicyTemplateInstance } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { getHttpConditionFromRule } from '@app/core/api-applications/api-applications-utils';

export interface JsonConstraintElement extends RuleQueryBodyJSON, TableElement {}

@Component({
  selector: 'portal-application-body',
  templateUrl: './application-body.component.html',
  styleUrls: ['./application-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationBodyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private currentApplication: Application;
  private currentRuleCopy: RuleV2 | RuleConfig;
  private policyTemplateInstanceResourceCopy: PolicyTemplateInstanceResource;

  // Can add future types with the '|' union operator
  public tableData: Array<JsonConstraintElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public columnDefs: Map<string, Column<JsonConstraintElement>> = new Map();
  public bodyConstraintOptions: Array<string> = ['json'];
  public currentBodyConstraintOption = 'json';
  public rowObjectName = 'CONSTRAINT';
  private localApplicationsRefreshDataValue = 0;
  private localPolicyTemplateInstanceRefreshDataValue = 0;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public capitalizeFirstLetter = capitalizeFirstLetter;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    const policyDataBeforeApplicationInit$ = getPolicyDataBeforeApplicationInit$(
      this.store,
      selectPolicyTemplateInstanceResourcesList,
      selectPolicyTemplateInstanceRefreshDataValue
    );
    const appState$ = this.store.pipe(select(selectApiApplications));
    const refreshApplicationsDataState$ = this.store.pipe(select(selectApiApplicationsRefreshData));
    combineLatest([policyDataBeforeApplicationInit$, appState$, refreshApplicationsDataState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([policyDataBeforeApplicationInitResp, appStateResp, refreshApplicationsDataStateResp]) => {
        const policyTemplateInstanceResourceListStateResp = policyDataBeforeApplicationInitResp[0];
        const refreshPolicyTemplateInstanceDataStateResp = policyDataBeforeApplicationInitResp[1];
        if (appStateResp === undefined) {
          this.resetEmptyTable();
          return;
        }
        this.currentApplication = appStateResp.current_application;
        if (!this.currentRuleCopy) {
          this.currentRuleCopy = cloneDeep(appStateResp.current_rule);
        }
        if (this.currentRuleCopy === undefined) {
          return;
        }
        const targetPolicyTemplateInstanceResource = !!policyTemplateInstanceResourceListStateResp
          ? getTargetPolicyTemplateInstanceResource(policyTemplateInstanceResourceListStateResp, this.currentApplication?.id)
          : undefined;
        this.policyTemplateInstanceResourceCopy = cloneDeep(targetPolicyTemplateInstanceResource);
        if (
          this.tableData.length === 0 ||
          this.localApplicationsRefreshDataValue !== refreshApplicationsDataStateResp ||
          this.localPolicyTemplateInstanceRefreshDataValue !== refreshPolicyTemplateInstanceDataStateResp
        ) {
          this.localApplicationsRefreshDataValue = refreshApplicationsDataStateResp;
          this.localPolicyTemplateInstanceRefreshDataValue = refreshPolicyTemplateInstanceDataStateResp;
          if (this.localApplicationsRefreshDataValue !== 0 && this.localPolicyTemplateInstanceRefreshDataValue !== 0) {
            this.currentRuleCopy = cloneDeep(appStateResp.current_rule);
            this.setEditableColumnDefs();
            this.updateTable();
          }
        }
      });
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getBodyFromRule(rule: RuleV2 | RuleConfig): RuleQueryBody | undefined {
    const ruleCondition = getHttpConditionFromRule(rule);
    return ruleCondition.body;
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const body = this.getBodyFromRule(this.currentRuleCopy);
    const data: Array<JsonConstraintElement> = [];
    if (body === undefined) {
      this.tableData = data;
      return;
    }
    const currentBodyConstraintOption = body[this.currentBodyConstraintOption];
    const currentBodyConstraintOptionLength = body[this.currentBodyConstraintOption].length;
    for (let i = 0; i < currentBodyConstraintOptionLength; i++) {
      data.push(this.createBodyConstraintOptionElement(currentBodyConstraintOption[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createBodyConstraintOptionElement(
    // Can add future types with the '|' union operator
    bodyConstraintOption: RuleQueryBodyJSON,
    index: number
  ): JsonConstraintElement {
    const data: JsonConstraintElement = {
      name: bodyConstraintOption.name,
      exact_match: getEmptyStringIfUnset(bodyConstraintOption.exact_match),
      match_type: getEmptyStringIfUnset(bodyConstraintOption.match_type),
      pointer: bodyConstraintOption.pointer,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private getPointerColumn(): Column<JsonConstraintElement> {
    const column = createInputColumn('pointer');
    column.requiredField = () => true;
    column.isUnique = true;
    column.getHeaderTooltip = () => {
      return `The JSON pointer path the system follows to store or retrieve data. The pointers are defined in "https://tools.ietf.org/html/rfc6901"`;
    };
    return column;
  }

  private getExactMatchColumn(): Column<JsonConstraintElement> {
    const column = createInputColumn('exact_match');
    column.getHeaderTooltip = () => {
      return `The value that is matched against and should be exactly the same to satisfy the rule.`;
    };
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getPointerColumn(), this.getExactMatchColumn()], this.columnDefs);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const pointerColumn = this.columnDefs.get('pointer');
    pointerColumn.isEditable = !this.fixedTable;

    const exactMatchColumn = this.columnDefs.get('exact_match');
    exactMatchColumn.isEditable = !this.fixedTable;
  }

  public makeEmptyTableElement(): JsonConstraintElement {
    return {
      name: '',
      exact_match: '',
      match_type: JSONBodyConstraint.MatchTypeEnum.string,
      pointer: '',
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Receives a JsonConstraintElement from the table then updates and saves
   * the current application.
   */
  public updateEvent(updatedBodyConstraintOption: JsonConstraintElement): void {
    // Setting name to pointer for now. Name will be removed in the future.
    updatedBodyConstraintOption.name = updatedBodyConstraintOption.pointer;
    this.updateBodyConstraintOptions();
    this.modifyCurrentRule();
  }

  public deleteSelected(bodyConstraintOptionToDelete: Array<JsonConstraintElement>): void {
    this.removeBodyConstraintOptions();
    this.modifyCurrentRule();
  }

  private getBodyConstraintOptionsFromTable(): Array<RuleQueryBodyJSON> {
    const bodyConstraintOptions: Array<RuleQueryBodyJSON> = [];
    for (const element of this.tableData) {
      const bodyConstraintOption: RuleQueryBodyJSON = {
        name: element.name,
        match_type: element.match_type,
        pointer: element.pointer,
      };
      if (!!element.exact_match) {
        bodyConstraintOption.exact_match = element.exact_match;
      }
      bodyConstraintOptions.push(bodyConstraintOption);
    }
    return bodyConstraintOptions;
  }

  private updateBodyConstraintOptions(): void {
    const bodyConstraintOptions = this.getBodyConstraintOptionsFromTable();
    const ruleCondition = getHttpConditionFromRule(this.currentRuleCopy);
    if (!ruleCondition.body) {
      ruleCondition.body = {
        json: bodyConstraintOptions,
      };
    } else {
      ruleCondition.body.json = bodyConstraintOptions;
    }
  }

  private removeBodyConstraintOptions(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
    this.updateBodyConstraintOptions();
  }

  private updatePolicyTemplateWithCurrentRuleChanges(): void {
    const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
    let targetRule = this.policyTemplateInstanceResourceCopy.spec.template.rules.find((rule) => rule.name === currentRuleAsRuleConfig.name);
    for (const key of Object.keys(targetRule)) {
      targetRule[key] = this.currentRuleCopy[key];
    }
  }

  private updateAndSavePolicyTemplateRule(): void {
    this.updatePolicyTemplateWithCurrentRuleChanges();
    this.store.dispatch(
      savingPolicyTemplateInstance({
        obj: this.policyTemplateInstanceResourceCopy,
        trigger_update_side_effects: false,
        notifyUser: true,
      })
    );
  }

  private modifyCurrentRule(): void {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    if (!currentRuleAsRuleV2.metadata) {
      // Is a RuleConfig
      this.updateAndSavePolicyTemplateRule();
    } else {
      this.store.dispatch(new ActionApiApplicationsSavingRule(currentRuleAsRuleV2));
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  /**
   * Sets the currentBodyConstraintOption when a new option is selected
   * from the dropdown list.
   */
  public currentBodyConstraintOptionSelection(optionValue: string): void {
    this.currentBodyConstraintOption = optionValue;
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }
}
