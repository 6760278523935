import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as FileShareServiceActions from './file-share-service.actions';
import { FileShareService } from '@agilicus/angular';
import { ResourceBasicCollectionState } from '@app/core/basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultIconStatus, getDefaultResourceBasicCollectionState } from '../helpers/entity-state-utils';

export const fileShareServicesFeatureKey = 'fileShareService';

export type FileShareServiceState = ResourceBasicCollectionState<FileShareService>;

export const adapter: EntityAdapter<FileShareService> = createEntityAdapter<FileShareService>({
  sortComparer: sortListByCreatedDate,
  selectId: (share) => share.metadata.id,
});

export const initialState: FileShareServiceState = adapter.getInitialState(getDefaultResourceBasicCollectionState<FileShareService>());

export const fileShareServiceReducer = createReducer(
  initialState,
  on(FileShareServiceActions.addFileShareService, (state, action) => adapter.addOne(action.obj, state)),
  on(FileShareServiceActions.upsertFileShareService, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(FileShareServiceActions.addFileShareServices, (state, action) => adapter.addMany(action.objs, state)),
  on(FileShareServiceActions.upsertFileShareServices, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(FileShareServiceActions.updateFileShareService, (state, action) => adapter.updateOne(action.obj, state)),
  on(FileShareServiceActions.updateFileShareServices, (state, action) => adapter.updateMany(action.objs, state)),
  on(FileShareServiceActions.deleteFileShareService, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(FileShareServiceActions.deleteFileShareServices, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(FileShareServiceActions.loadFileShareServices, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(FileShareServiceActions.clearFileShareServices, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(FileShareServiceActions.savingFileShareService, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(FileShareServiceActions.savingFileShareServices, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(FileShareServiceActions.initFileShareServices, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(FileShareServiceActions.refreshFileShareServices, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  }),
  // custom resource reducers:
  on(FileShareServiceActions.savingIconFileShareServices, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: true,
        icon_file_save_success: false,
        icon_file_save_fail: false,
      },
    };
  }),
  on(FileShareServiceActions.successfulIconSaveFileShareServices, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: true,
        icon_file_save_fail: false,
      },
    };
  }),
  on(FileShareServiceActions.failedIconSaveFileShareServices, (state, action) => {
    return {
      ...state,
      icon_status: {
        saving_icon_file: false,
        icon_file_save_success: false,
        icon_file_save_fail: true,
      },
    };
  }),
  on(FileShareServiceActions.resetIconStatusFileShareServices, (state, action) => {
    return { ...state, icon_status: getDefaultIconStatus() };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
