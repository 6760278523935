<portal-page-info
  pageTitle="Billing"
  [pageDescriptiveText]="getPageDescriptiveText()"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasUsersReadPermissions !== undefined && !hasUsersReadPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasUsersReadPermissions" 
  class="billing-main"
>
  <div *ngIf="!!billingAccountInfo" class="billing-container">
    <div class="billing-contact-container">
      <form
        class="auth-form"
        [formGroup]="billingInfoForm"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-form-field>
          <input
            matInput
            type="text"
            placeholder="Contact email"
            formControlName="contact_email"
            readonly
          >
        </mat-form-field>
        <mat-icon 
          class="help-icon" 
          [matTooltip]="getContactEmailTooltipText()"
        >
          help_outline
        </mat-icon>
      </form>
    </div>

    <div *ngIf="isManagedBillingEnabled()" class="start-subscription-container">
      <div class="button-container">
        <button
          type="button"
          mat-raised-button
          color="primary"
          (click)="onBuyProductClick()"
          [matTooltip]="getStartSubscriptionTooltipText()"
          [disabled]="!canAccessStartSubscriptionButton()"
        >
          START SUBSCRIPTION
        </button>
      </div>
    </div>

    <div class="subscription-info-container">
      <h3>Subscription Information</h3>
      <p *ngIf="!!billingAccountInfo?.status?.product?.spec?.description">{{ billingAccountInfo.status.product.spec.description }}</p>

      <div *ngIf="isManagedBillingEnabled()" class="setup-payment-container">
        <div
          class="button-container"
          [matTooltip]="getSubscriptionButtonDisabledTooltipText()"
          [matTooltipDisabled]="canAccessPaymentInfo()"
        >
          <button
            type="button"
            mat-raised-button
            color="primary"
            (click)="onSetupPaymentClick()"
            matTooltip="Click to setup/modify your payment subscription"
            [disabled]="!canAccessPaymentInfo()"
          >
            {{ subscriptionButtonText }}
          </button>
        </div>
      </div>

      <div class="table-controls">     
        <portal-download-button
          buttonDescription="SUBSCRIPTIONS"
          tooltipText="Click to download subscription information"
          (downloadFile)="downloadSubscriptions()"
        >
        </portal-download-button>

        <div class="flex-div"></div>
      </div>

      <div *ngIf="!showSubscriptionsInTable()">
        <div  class="subscription-cards-container">
          <div class="cards flex-row-wrap">
            <mat-card
              class="flex-xs-80 flex-sm-60"
              *ngFor="let data of subscriptionDisplayData"
            >
              <mat-card-header class="card-header-container">
                <mat-card-title class="title-container">
                  <div class="title-text-container">
                    {{ capitalizeFirstLetter(data.product_name) }}
                  </div>
                </mat-card-title>
              </mat-card-header> 
              <mat-card-content> Organisations: <span class="card-info-text">{{ getFormattedOrgsString(data.orgs) }}</span> </mat-card-content>

              <mat-card-content> Start Date: <span class="card-info-text">{{ getFormatedBillingDateString(data.start_date) }}</span> </mat-card-content>
              
              <mat-card-content> Payment Status: <span class="card-info-text">{{ getFormatedBillingPaymentStatus(data.payment_status) }}</span> </mat-card-content>

              <mat-card-content> Currency: <span class="card-info-text">{{ getCurrencyDisplayValue(data.currency) }}</span> </mat-card-content>

              <mat-card-content> Subscription Balance: <span class="card-info-text">{{ getSubscriptionBalanceDisplayValue(data.subscription_balance, data.currency) }}</span> </mat-card-content>
              
              <mat-card-content> Estimate Balance End Date: <span class="card-info-text" [ngClass]="{ 'warn-of-value': warnOnEstimateBalanceEndDate(data.estimate_balance_end_date) }">{{ convertDateToShortReadableFormat(data.estimate_balance_end_date) }}</span> </mat-card-content>
              
              <mat-card-content> Last Invoice Date: <span class="card-info-text">{{ getFormatedBillingDateString(data.last_invoice_date) }}</span> </mat-card-content>

              <mat-card-content> Next Invoice Date: <span class="card-info-text">{{ getFormatedBillingDateString(data.next_invoice_date) }}</span> </mat-card-content>

              <mat-card-content> Current Invoice Total: <span class="card-info-text">{{ convertCentsToReadableDollarsString(data.total, data.currency) }}</span> </mat-card-content>
              
              <mat-card-content> Current Invoice Amount Due: <span class="card-info-text">{{ convertCentsToReadableDollarsString(data.amount_due, data.currency) }}</span> </mat-card-content>
              
              <mat-card-content> Trial: <span class="card-info-text">{{ !!data.trial_active ? 'Active' : 'Not Active' }}</span> </mat-card-content>

              <mat-card-content> Trial End Date: <span class="card-info-text">{{ !!data.trial_end_date ? getFormatedBillingDateString(data.trial_end_date) : 'N/A' }}</span> </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="subscription-table-container">
          <portal-table-layout-readonly
            [tableData]="getSingleSubscriptionUsageMetricTableData(subscriptionDisplayData)"
            [columnDefs]="getSingleSubscriptionUsageMetricColumnDefs(subscriptionDisplayData)"
            hideFilter="true"
            [filterManager]="filterManager"
            [filterMenuOptions]="filterMenuOptions"
          >
          </portal-table-layout-readonly>
        </div>
      </div>

      <div *ngIf="showSubscriptionsInTable()" class="subscription-table-container">
        <portal-table-layout-readonly
          [tableData]="subscriptionDisplayData"
          [columnDefs]="columnDefs"
          [hideFilter]="hideFilter"
          [filterManager]="filterManager"
          [filterMenuOptions]="filterMenuOptions"
          [isNestedTable]="true"
        >
        </portal-table-layout-readonly>
      </div>
    </div>
  </div>

  <portal-usage-metrics
    [billingAccountInfo]="billingAccountInfo"
  >
  </portal-usage-metrics>
</div>
