import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { InboxItem, MessagesService } from '@agilicus/angular';
import { AppState } from '..';
import * as InboxItemActions from './inbox-item.actions';
import { selectInboxItems, selectInboxItemEntity, selectInboxItemList, selectInboxItemShouldPopulateValue } from './inbox-item.selectors';
import { InboxItemState } from './inbox-item.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSetCrudStateEffect,
} from '../helpers/effect-factories';
import { InboxItemStateService } from '../state-services/inbox-item-state.service';
import { selectCanAdminInboxItems } from '../user/permissions/inboxItems.selectors';
import { getUnreadInboxItemsCount } from '../api/inbox-items/inbox-items-api-utils';
import { catchError, concatMap, map, of, withLatestFrom } from 'rxjs';
import { selectApiOrgId, selectUser } from '../user/user.selectors';

@Injectable()
export class InboxItemEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private inboxItemStateService: InboxItemStateService,
    private messagesService: MessagesService
  ) {}

  public initState$ = createInitStateEffect<InboxItemState>(
    this.store,
    this.actions$,
    InboxItemActions.initInboxItems,
    InboxItemActions.getInboxItems,
    InboxItemActions.maintainInboxItems,
    selectInboxItems
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    InboxItemActions.getInboxItems,
    InboxItemActions.maintainInboxItems,
    selectInboxItemShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<InboxItem, string>(
    this.store,
    this.actions$,
    InboxItemActions.getInboxItems,
    InboxItemActions.clearInboxItems,
    InboxItemActions.maintainInboxItems,
    this.inboxItemStateService,
    selectCanAdminInboxItems
  );

  public setCrudState$ = createSetCrudStateEffect<InboxItem, string>(
    this.store,
    this.actions$,
    InboxItemActions.loadInboxItems,
    InboxItemActions.maintainInboxItems,
    this.inboxItemStateService,
    selectInboxItemList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<InboxItem, string>(
    this.store,
    this.actions$,
    InboxItemActions.savingInboxItem,
    InboxItemActions.maintainInboxItems,
    this.inboxItemStateService,
    selectInboxItemEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<InboxItem, string>(
    this.store,
    this.actions$,
    InboxItemActions.savingInboxItems,
    InboxItemActions.maintainInboxItems,
    this.inboxItemStateService,
    selectInboxItems
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<InboxItem, string>(
    this.actions$,
    InboxItemActions.deletingInboxItem,
    InboxItemActions.refreshInboxItems,
    InboxItemActions.maintainInboxItems,
    this.inboxItemStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<InboxItem, string>(
    this.actions$,
    InboxItemActions.deletingInboxItems,
    InboxItemActions.refreshInboxItems,
    InboxItemActions.maintainInboxItems,
    this.inboxItemStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<InboxItemState>(
    this.store,
    this.actions$,
    InboxItemActions.getInboxItems,
    InboxItemActions.maintainInboxItems,
    selectInboxItems
  );

  // special case effects:

  /**
   * Custom side effect. We want to set the unreadInboxItemCount when we get new data
   * from the api. Will fire when any action occurs that updates the state with new api data.
   */
  public setUnreadInboxItemCountSideEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        InboxItemActions.loadInboxItems,
        InboxItemActions.addInboxItem,
        InboxItemActions.addInboxItems,
        InboxItemActions.upsertInboxItem,
        InboxItemActions.upsertInboxItems,
        InboxItemActions.updateInboxItem,
        InboxItemActions.updateInboxItems,
        InboxItemActions.deleteInboxItem,
        InboxItemActions.deleteInboxItems,
        InboxItemActions.clearInboxItems
      ),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectUser)), this.store.select(selectApiOrgId)))),
      concatMap(([action, userResp, orgId]) => {
        return getUnreadInboxItemsCount(this.messagesService, userResp.id, orgId).pipe(
          map((unreadInboxItemsCountResp) => {
            return InboxItemActions.setUnreadInboxItemCount({ unreadInboxItemCount: unreadInboxItemsCountResp });
          }),
          catchError((_) => {
            return of(InboxItemActions.maintainInboxItems());
          })
        );
      })
    )
  );
}
