// TODO: rename file & folder to policy

import { createSelector } from '@ngrx/store';
import { selectPolicyState } from '../core.state';
import { PolicyState } from './issuer.models';

export const selectPolicy = createSelector(selectPolicyState, (state: PolicyState) => state);

export const selectCurrentTheme = createSelector(selectPolicyState, (state: PolicyState) => {
  if (!state) {
    return undefined;
  }
  return state.current_theme;
});

export const selectIssuerPolicy = createSelector(selectPolicyState, (state: PolicyState) => {
  if (!state) {
    return undefined;
  }
  return state.current_issuer_policy;
});
