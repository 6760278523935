import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from '@app/core';
import { GettingStartedTask } from '@app/core/user/preferences/getting-started-tasks.enum';
import { GettingStartedData } from '@app/core/user/preferences/getting-started-data';
import {
  getTaskCompletedStatus,
  getTaskCompleteToggleText,
  toggleGettingStartedTask,
} from '@app/core/user/preferences/user-preference-utils';
import { selectApiOrgId, selectGettingStartedData } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionUIOpenSideNavMenuTargetRoute } from '@app/core/ui/ui.actions';
import { selectNavMenuItemsListState } from '@app/core/ui/ui.selectors';
import { TourService } from 'ngx-ui-tour-md-menu';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';
import { getDefaultTourStepConfig } from '@app/shared/components/tour.utils';

@Component({
  selector: 'portal-connect-to-sites-guide',
  templateUrl: './connect-to-sites-guide.component.html',
  styleUrls: ['./connect-to-sites-guide.component.scss', '../getting-started-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectToSitesGuideComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public gettingStartedDataCopy: GettingStartedData;
  public pageDescriptiveText = `A connector connects resources in an isolated site to the Agilicus cloud. 
  Later you will configure the Agilicus cloud to give users access to these resources. 
  Some examples of sites where you would install a connector are: an office data center, your home network, or an unattended remote installation such as a water treatment plant. 
  A connector can also provide access to files on the machine where it’s installed using a share. 
  Further, it can act as an identity provider for the Agilicus cloud by authenticating users against a local authentication system like Active Directory.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/agilicus-connector/`;
  public isTaskComplete = false;
  public targetTask = GettingStartedTask.completed_connect_to_sites;
  public connectToSitesFormGroup: UntypedFormGroup;

  public getTaskCompletedStatus = getTaskCompletedStatus;
  public getTaskCompleteToggleText = getTaskCompleteToggleText;
  private isConnectorMenuOpen = false;
  public helpImageFilePath = 'https://www.agilicus.com/www/d6c678de-ssh-data-flow.svg';
  private helpTourStarted = false;
  private tourSteps: Array<IMdStepOption> = [
    {
      ...getDefaultTourStepConfig(),
      stepId: '0',
      anchorId: 'connector-new',
      title: 'Create a Connector',
      content: `A connector is a means of getting, securely, from one domain of connectivity to another`,
    },
  ];

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private tourService: TourService
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    const navMenuItemsListState$ = this.store.pipe(select(selectNavMenuItemsListState));
    combineLatest([orgId$, gettingStartedData$, navMenuItemsListState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, gettingStartedDataResp, navMenuItemsListStateResp]) => {
        this.orgId = orgIdResp;
        this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
        this.isTaskComplete = getTaskCompletedStatus(this.targetTask, this.gettingStartedDataCopy);
        if (!!navMenuItemsListStateResp && !this.isConnectorMenuOpen && !this.isTaskComplete) {
          this.isConnectorMenuOpen = true;
          this.store.dispatch(new ActionUIOpenSideNavMenuTargetRoute('connector-new'));
          // We need to delay this so that it is not called before the anchor tag
          // exists in the DOM before we start the tour.
          if (!this.helpTourStarted) {
            setTimeout(() => {
              this.tourService.initialize(this.tourSteps);
              this.tourService.startAt(`0`);
              this.helpTourStarted = true;
            }, 500);
          }
        }
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.connectToSitesFormGroup = this.formBuilder.group({
      exposeResources: ['', Validators.required],
    });
  }

  public getExposeResourcesValueFromForm(): string {
    return this.connectToSitesFormGroup.get('exposeResources').value;
  }

  public needToCreateAgentConnector(): boolean {
    const exposeResourcesValue = this.getExposeResourcesValueFromForm();
    return exposeResourcesValue === 'yes';
  }

  public doNotNeedToCreateAgentConnector(): boolean {
    const exposeResourcesValue = this.getExposeResourcesValueFromForm();
    return exposeResourcesValue === 'no';
  }

  public onImageClick(): void {
    this.routeToNewConnectorScreen();
  }

  private routeToNewConnectorScreen(): void {
    this.router.navigate(['/connector-new'], {
      queryParams: { org_id: this.orgId },
    });
  }

  public routeToNextTask(): void {
    this.router.navigate(['/expose-resource-guide'], {
      queryParams: { org_id: this.orgId },
    });
  }

  public onCompleteToggle(isChecked: boolean): void {
    toggleGettingStartedTask(isChecked, this.targetTask, this.gettingStartedDataCopy, this.store);
  }
}
