<p class="descriptive-text">
  The methods for which to allow access to resources. 
  These correspond to the <code class="inline-code">Access-Control-Allow-Methods</code> header, into which they are joined by commas. 
  If this value is null, then the methods are wildcarded. 
  Set a value to <code class="inline-code">\'*\'</code> to wildcard.
</p>

<portal-table-layout
  [tableData]="tableData"
  [columnDefs]="columnDefs"
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName"
  (updateEvent)="updateEvent()"
  (updateAutoInput)="updateAutoInput($event)"
  (removeSelected)="deleteSelected()"
>
</portal-table-layout>