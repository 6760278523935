import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'portal-getting-started-recommendations',
  templateUrl: './getting-started-recommendations.component.html',
  styleUrls: ['./getting-started-recommendations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GettingStartedRecommendationsComponent {
  @Input() public recommendationCustomText = '';
  @Input() public recommendationCommonText = `See the image below for directions on how to do so. 
  Click the image to navigate to this screen.`;
  @Input() public imageSourcePath = '';
  @Output() public imageClick = new EventEmitter<any>();

  constructor() {}

  public onImageClick(): void {
    this.imageClick.emit();
  }
}
