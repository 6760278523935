export enum OperatingSystemEnum {
  linux = 'linux',
  windows = 'windows',
  linux_arm = 'linux-arm',
  freebsd_arm = 'freebsd-arm',
  linux_mipsbe = 'linux-mipsbe',
  linux_mipsle = 'linux-mipsle',
  manual = 'manual',
  container = 'container',
}
