<div class="query-parameters-container">
  <div class="title">
    <h2>Query Parameters</h2>
  </div>

  <portal-table-layout 
    [tableData]="tableData" 
    [columnDefs]="columnDefs" 
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyTableElement"
    [rowObjectName]="rowObjectName" 
    (updateEvent)="updateEvent($event)"
    (updateSelection)="updateSelection($event)"
    (removeSelected)="deleteSelected($event)"
  >
  </portal-table-layout>
</div>
