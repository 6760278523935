import { CrudManagementState, CrudStateCollection } from './crud-management-state-definitions';
import { getGuidFromObject, getNewCrudStateObjGuid, ObjectState, ObjectStateStore } from './state-driven-crud';
import {
  CrudAction,
  isCrudCreateAction,
  isCrudDeleteAction,
  isCrudDeleteFinishedAction,
  isCrudDeleteListAction,
  isCrudDeleteListFinishedAction,
  isCrudInitEmptyAction,
  isCrudSaveFinishedAction,
  isCrudSaveListFinishedAction,
  isCrudSetAction,
  isCrudUpdateAction,
  isCrudUpdateListAction,
} from './state-driven-crud.actions';

function getDesiredStateCopy<DataType, IDType>(updatedState: CrudManagementState, crudRegistyName: string): ObjectStateStore<DataType> {
  return { ...updatedState[crudRegistyName].desiredState };
}

function getLastAppliedStateCopy<DataType, IDType>(updatedState: CrudManagementState, crudRegistyName: string): ObjectStateStore<DataType> {
  return { ...updatedState[crudRegistyName].lastAppliedState };
}

function updateObjectState<DataType, IDType>(
  state: ObjectStateStore<DataType>,
  guid: string | undefined,
  version: number,
  obj: DataType | undefined
): void {
  if (!guid) {
    return;
  }
  state[guid] = new ObjectState<DataType>(version, obj);
}

export function crudManagementReducer<DataType, IDType>(
  state: CrudManagementState,
  action: CrudAction<DataType, IDType>
): CrudManagementState {
  if (isCrudInitEmptyAction(action)) {
    const updatedState: CrudManagementState = { ...state };
    for (const val of Object.values(CrudStateCollection)) {
      updatedState[val] = {
        desiredState: {},
        lastAppliedState: {},
      };
    }
    return updatedState;
  }
  if (isCrudCreateAction<DataType, IDType>(action)) {
    // Do we make the caller responsible for allocating a temporary guid here?
    // If so, we'll need to link the 'temp' one to the final one on create finish.
    // Further, we'll need to maintain that link across multiple requests so that a user
    // may update an object which was created, but is still in flight.
    const guidVal = action.guid.toString();
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(desiredStateCopy, guidVal, 1, action.obj);
    const lastAppliedStateCopy = getLastAppliedStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(lastAppliedStateCopy, guidVal, 0, action.obj);
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
      lastAppliedState: lastAppliedStateCopy,
    };
    return updatedState;
  }
  if (isCrudUpdateAction<DataType, IDType>(action)) {
    const guidVal = action.guid.toString();
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    const existingObjectState: ObjectState<DataType> = desiredStateCopy[guidVal];
    if (existingObjectState.version === -1) {
      // If we are already deleting the object, do not update the state
      return updatedState;
    }
    updateObjectState(desiredStateCopy, guidVal, existingObjectState.version + 1, action.obj);
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
    };
    return updatedState;
  }
  if (isCrudUpdateListAction<DataType>(action)) {
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    const lastAppliedStateCopy = getLastAppliedStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    for (const obj of action.objs) {
      const guidVal = getGuidFromObject(obj).toString();
      if (guidVal === getNewCrudStateObjGuid()) {
        // creating new obj:
        updateObjectState(desiredStateCopy, guidVal, 1, obj);
        updateObjectState(lastAppliedStateCopy, guidVal, 0, obj);
        continue;
      }
      const existingObjectState: ObjectState<DataType> = desiredStateCopy[guidVal];
      if (existingObjectState.version === -1) {
        // If we are already deleting the object, do not update the state
        continue;
      }
      updateObjectState(desiredStateCopy, guidVal, existingObjectState.version + 1, obj);
    }
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
      lastAppliedState: lastAppliedStateCopy,
    };
    return updatedState;
  }
  if (isCrudDeleteAction<DataType, IDType>(action)) {
    const guidVal = action.guid.toString();
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(desiredStateCopy, guidVal, -1, action.obj);
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
    };
    return updatedState;
  }
  if (isCrudDeleteListAction<DataType>(action)) {
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    for (const obj of action.objs) {
      const guidVal = getGuidFromObject(obj).toString();
      updateObjectState(desiredStateCopy, guidVal, -1, obj);
    }
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
    };
    return updatedState;
  }
  if (isCrudSetAction<DataType, IDType>(action)) {
    const guidVal = !!action.guid ? action.guid.toString() : undefined;
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    if (!updatedState[crudRegistyNameVal]) {
      updatedState[crudRegistyNameVal] = {
        desiredState: {},
        lastAppliedState: {},
      };
    }
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(desiredStateCopy, guidVal, 0, action.obj);
    const lastAppliedStateCopy = getLastAppliedStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(lastAppliedStateCopy, guidVal, 0, action.obj);
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
      lastAppliedState: lastAppliedStateCopy,
    };
    return updatedState;
  }
  if (isCrudSaveFinishedAction<DataType, IDType>(action)) {
    let guidVal = action.guid.toString();
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    if (!desiredStateCopy[guidVal]) {
      // finished creating a new obj, so set initial desired state for this guid
      updateObjectState(desiredStateCopy, guidVal, 0, action.savedObj);
    }
    const lastAppliedStateCopy = getLastAppliedStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(lastAppliedStateCopy, guidVal, action.savedVersion === -1 ? 0 : action.savedVersion, action.savedObj);
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
      lastAppliedState: lastAppliedStateCopy,
    };
    return updatedState;
  }
  if (isCrudSaveListFinishedAction<DataType, IDType>(action)) {
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    const lastAppliedStateCopy = getLastAppliedStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    for (const obj of action.savedObjs) {
      const guidVal: IDType = getGuidFromObject(obj);
      const guidValAsString = guidVal.toString();
      if (!desiredStateCopy[guidValAsString]) {
        // finished creating a new obj, so set initial desired state for this guid
        updateObjectState(desiredStateCopy, guidValAsString, 0, obj);
      }
      const savedVersion = action.guidToSavedStateMap.get(guidVal).version;
      updateObjectState(lastAppliedStateCopy, guidValAsString, savedVersion === -1 ? 0 : savedVersion, obj);
    }
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
      lastAppliedState: lastAppliedStateCopy,
    };
    return updatedState;
  }
  if (isCrudDeleteFinishedAction<DataType, IDType>(action)) {
    const guidVal = action.guid.toString();
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(desiredStateCopy, guidVal, action.version, undefined);
    const lastAppliedStateCopy = getLastAppliedStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    updateObjectState(lastAppliedStateCopy, guidVal, action.version, undefined);
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
      lastAppliedState: lastAppliedStateCopy,
    };
    return updatedState;
  }
  if (isCrudDeleteListFinishedAction<IDType>(action)) {
    const crudRegistyNameVal = action.crudRegistyName;
    const updatedState: CrudManagementState = { ...state };
    const desiredStateCopy = getDesiredStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    const lastAppliedStateCopy = getLastAppliedStateCopy<DataType, IDType>(updatedState, crudRegistyNameVal);
    for (const id of action.ids) {
      const guidValAsString = id.toString();
      const version = action.guidToSavedStateMap.get(id).version;
      updateObjectState(desiredStateCopy, guidValAsString, version, undefined);
      updateObjectState(lastAppliedStateCopy, guidValAsString, version, undefined);
    }
    updatedState[crudRegistyNameVal] = {
      ...updatedState[crudRegistyNameVal],
      desiredState: desiredStateCopy,
      lastAppliedState: lastAppliedStateCopy,
    };
    return updatedState;
  }
  return state;
}
