import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { retryWhen, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { genericRetryStrategy } from '@app/rxjs-utils';
import { getIgnoreErrorsKey } from './http-interceptor-utils';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignoreErrors = request.headers.get(getIgnoreErrorsKey());
    let updatedRequest = request;
    if (!!ignoreErrors) {
      // We need to remove the header to avoid CORS errors.
      updatedRequest = request.clone({ headers: request.headers.delete(getIgnoreErrorsKey()) });
    }
    return next.handle(updatedRequest).pipe(
      retryWhen(genericRetryStrategy()),
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse && error.status === 409 && request.method === 'POST') {
          // For a POST, we will return the existing object and handle accordingly.
          // Therefore, this error message does not need to be displayed to the user.
          return throwError(() => error);
        }
        if (!!ignoreErrors) {
          // We do not want to notify the user of these errors.
          return throwError(() => error);
        }
        const appErrorHandler = this.injector.get(ErrorHandler);
        appErrorHandler.handleError(error);
        return throwError(() => error);
      })
    );
  }
}
