import * as sha256 from 'fast-sha256';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';

import {
  Auth,
  Catalogue,
  CatalogueEntry,
  CataloguesService,
  FilesService,
  Organisation,
  OrganisationsService,
  OrganisationStatus,
  TokensService,
  User,
} from '@agilicus/angular';
import { AuthService, construct_issuer, construct_scopes, default_scopes } from '@app/core/services/auth-service.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { select, Store } from '@ngrx/store';
import { AppState, NotificationService, selectSignupState } from '@app/core';
import { selectUser } from '@app/core/user/user.selectors';
import { combineLatest, EMPTY, from, Observable, of, Subject, throwError } from 'rxjs';
import { DynamicEnvironmentService } from '@app/core/services/dynamic-environment.init';
import { environment as environ } from '@env/environment';
import { catchError, concatMap, delay, map, take, takeUntil } from 'rxjs/operators';
import {
  ActionSignupBounce,
  ActionSignupComplete,
  ActionSignupCreateComplete,
  ActionSignupInit,
  ActionSignupSetBillingAccountId,
  ActionSignupSetProductLabelOverride,
  ActionSignupRedirecting,
  ActionSignupSetSelectedIndex,
  ActionSignupNukeState,
} from '@app/core/signup/signup.actions';
import { SignupState } from '@app/core/signup/signup.models';
import { SubdomainOption } from '@app/shared/components/subdomain-option.enum';
import { getFormattedSubdomainValue } from '../validation-utils';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { areFormFieldsValid, getUserNameFromUser } from '../utils';
import { createDialogData } from '@app/shared/components/dialog-utils';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { getIgnoreErrorsHeader } from '../../../core/http-interceptors/http-interceptor-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { Step, StepperProgressBarController } from '@agilicus/stepper-progress-bar';
import { getSubdomainFromIssuer } from '@app/core/issuer-state/issuer.utils';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { getProductLinkFromUser$ } from '../product-guide-link/product-guide-link.utils';
import { OptionalAuthArgs } from '@agilicus/angular/src/auth';
import { ProfileLinkService } from '@app/core/api/profile-link/profile-link.service';
import {
  failFirstProgressBarStep,
  failSecondProgressBarStep,
  getCnameDestination,
  getProductGuideSignupDeepLink,
  getProgressBarEstimateText,
  getSplitHorizonMessage,
  getSplitHorizonMessagePrefix,
  getSplitHorizonProductGuideDeepLink,
  getSubdomainHintLabel,
  getSubdomainProductGuidePostText,
  passAllProgressBarSteps,
  passProgressBarStep,
  resetProgressBarSteps,
  signupLog,
} from '@app/core/signup/signup-utils';
import { pollForCompletion$ } from '@app/core/api/organisations/organisations-api.utils';

export interface SignupStoreData {
  user: User;
  signupState: SignupState;
  profileLink: string;
}

export interface CombinedSignupData {
  user: User;
  signupState: SignupState;
  catalogues: Array<Catalogue>;
  customDivText: string | undefined;
  profileLink: string;
}

export interface resolveCnameData {
  status: boolean;
  statusMessage: string;
  response: any;
}

declare var Tawk_API;
declare function mautic(action: string, type: string, params: object | undefined): void;

@Component({
  selector: 'portal-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public scopes = construct_scopes(default_scopes);
  public issuer: string;
  public user$: Observable<User>;
  public isLinear = true;
  public authIsGood = true;
  public orgForm: UntypedFormGroup;
  public subdomainForm: UntypedFormGroup;
  public subdomainOwnForm: UntypedFormGroup;
  public subdomainAgilicusForm: UntypedFormGroup;
  public showProgress = false;
  private signupOrgId = '';
  public profileURL: string;
  public adminURL: string;
  public domain = '';
  public signupVideoURL: SafeResourceUrl;
  public signupState$: Observable<SignupState>;
  private signupState: SignupState;
  public curIndex = 0; // current index in stepper
  public subdomainOptionEnum = SubdomainOption; // allows this enum to be used in the template
  public loaded = false;
  public clusterInfoCatalogueEntries: Array<CatalogueEntry>;
  private catalogueNameToHostingDomainsMap: Map<string, Array<string>> = new Map();
  public hostingClusterForm: UntypedFormGroup;
  public combinedSignupData$: Observable<CombinedSignupData>;
  public cnameDestination = getCnameDestination();
  public productGuideLink = `https://www.agilicus.com/anyx-guide/signup/`;
  public productGuideSignupDeepLink = getProductGuideSignupDeepLink();
  public productGuidePostText = getSubdomainProductGuidePostText(this.cnameDestination);
  private _user: Object | undefined;
  private signaledSignin: boolean = false;
  public isVertical = false;
  public steps: Array<Step> = new Array<Step>();
  public progressStepper: StepperProgressBarController = new StepperProgressBarController();
  private no_seatbelts: boolean = false;
  private billing_account_id: string | undefined;
  private product_label_override: string | undefined;
  public customDivText: string | undefined;
  public allCnameValidatiorStepsPassed = false;
  private splitHorizonMessagePrefix = getSplitHorizonMessagePrefix();
  private splitHorizonProductGuideLink = 'https://www.agilicus.com/anyx-guide/signup/#note-split-horizon-dns';
  private splitHorizonProductGuideDeepLink = getSplitHorizonProductGuideDeepLink();
  private splitHorizonMessage = getSplitHorizonMessage(this.splitHorizonProductGuideDeepLink);
  private restrictiveFirewallProductGuideLink = 'https://www.agilicus.com/anyx-guide/signup/#restrictive-firewall';
  private restrictiveFirewallProductGuideDeepLink = 'https://www.agilicus.com/anyx-guide/signup/#restrictive-firewall';
  public subdomainHintLabel = getSubdomainHintLabel();

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getProgressBarEstimateText = getProgressBarEstimateText;

  @ViewChild('stepper') public stepper: MatStepper;
  @ViewChild('domainNameInput') domainNameInput: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>, // check if user is logged in
    private notificationService: NotificationService,
    public env: DynamicEnvironmentService,
    public orgsService: OrganisationsService,
    public http: HttpClient,
    private tokens: TokensService,
    private cataloguesService: CataloguesService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public errorDialog: MatDialog,
    private filesService: FilesService,
    private customValidatorsService: CustomValidatorsService,
    private authService: AuthService,
    private cookieService: CookieService,
    private profileLinkService: ProfileLinkService
  ) {
    this.issuer = construct_issuer(this.env.environment.overrideDomain);
    this.signupVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/cPefLCy-K10');
    this.adminURL = window.location.protocol + '//' + window.location.hostname;
  }

  private getStoreData$(): Observable<SignupStoreData> {
    this.user$ = this.store.pipe(select(selectUser));
    this.signupState$ = this.store.select(selectSignupState);
    const profileLink$ = this.profileLinkService.getProfileUrlForLink$();
    return combineLatest([this.user$, this.signupState$, profileLink$]).pipe(
      concatMap(([userResp, signupStateResp, profileLinkResp]: [User, SignupState, string]) => {
        const storeData: SignupStoreData = {
          user: userResp,
          signupState: signupStateResp,
          profileLink: profileLinkResp,
        };
        return of(storeData);
      })
    );
  }

  private getCombinedSignupData$(): Observable<CombinedSignupData> {
    const storeData$ = this.getStoreData$();
    const combinedSignupData$ = storeData$.pipe(
      concatMap((storeDataResp: SignupStoreData) => {
        let clusterInfoCatalogueData$: Observable<Array<Catalogue> | undefined> = of(undefined);
        if (!!storeDataResp.user && storeDataResp.signupState.selected_index === 1 && !storeDataResp.signupState.create_complete) {
          // We only want to retrieve the catalogue data after the user has logged in
          // and only when on step 2 (index 1) before creating the new org.
          clusterInfoCatalogueData$ = this.getClusterInfoCatalogue$();
        }
        let customDivText$: Observable<string | undefined> = of(undefined);
        if (this.customDivText === undefined) {
          customDivText$ = this.getCustomDivText$();
        }
        return combineLatest([clusterInfoCatalogueData$, customDivText$, of(storeDataResp)]);
      }),
      map(([clusterInfoCatalogueDataResp, customDivTextResp, storeDataResp]: [Array<Catalogue>, string, SignupStoreData]) => {
        const combinedSignupData: CombinedSignupData = {
          user: storeDataResp.user,
          signupState: storeDataResp.signupState,
          catalogues: clusterInfoCatalogueDataResp,
          customDivText: customDivTextResp,
          profileLink: storeDataResp.profileLink,
        };
        return combinedSignupData;
      })
    );
    return combinedSignupData$;
  }

  private sendMauticSignin(loc: string, user, eventName: string): void {
    try {
      if (user && typeof mautic === 'function') {
        let mautic_object = {
          page_title: loc,
          page_url: window.location.href,
          utm_source: 'portal',
          utm_campaign: 'signup',
          email: user.email.toLowerCase(),
          firstname: user.first_name,
          lastname: user.last_name,
          tags: eventName,
        };
        if (this.signupState?.signup_complete || eventName === 'signup-complete') {
          // The url is used in Mautic points allocation, the tag
          // is used to create the segment
          mautic_object.tags = 'signup';
          mautic_object.page_title = 'signup';
          mautic_object.page_url = 'https://admin.agilicus.cloud/signup-complete';
          mautic_object['org_domain'] = this.domain;
        }
        mautic('send', 'pageview', mautic_object);
      }
    } catch (err) {
      // best effort, no error handling
      console.log('Error sending mautic event: ', err);
    }
  }

  public ngOnInit(): void {
    signupLog('SIGNUP INIT');
    this.setStepperProgressBarSteps();
    this.route.queryParamMap.subscribe((params) => {
      const bid = params.get('bid');
      const plo = params.get('plo');
      const flush = params.get('flush');
      const noseatbelts = params.get('noseatbelts');
      const mtc_id = params.get('mtc_id');
      const mautic_device_id = params.get('mautic_device_id');
      if (mtc_id) {
        this.cookieService.set('mtc_id', mtc_id);
        localStorage.setItem('mtc_id', mtc_id);
      }
      if (mautic_device_id) {
        this.cookieService.set('mautic_device_id', mautic_device_id);
        localStorage.setItem('mautic_device_id', mautic_device_id);
      }
      if (noseatbelts) {
        this.no_seatbelts = noseatbelts === '1';
      }
      signupLog(`SIGNUP INIT: flush: ${flush}, plo: ${plo}, bid: ${bid}`);

      if (flush === '1') {
        console.log('NUKE THE WORLD: SIGNUP EDITION, keep bid=', bid, 'plo=', plo);
        this.billing_account_id = bid || undefined;
        this.product_label_override = plo || undefined;
        this.nukeState();
        window.sessionStorage.signup = {
          is_signing_up: true,
          initial_auth_complete: false,
        };

        this.store.dispatch(new ActionSignupInit());
        // Flush all, get these back again below if still set
        this.store.dispatch(new ActionSignupSetBillingAccountId(this.billing_account_id));
        this.store.dispatch(new ActionSignupSetProductLabelOverride(this.product_label_override));
        this.router.navigate(['/signup'], { queryParams: { flush: null, bid: bid, plo: plo, mtc_id: null, mautic_device_id: null } });
      }

      if (bid) {
        this.billing_account_id = bid;
        if (environ.billing_accounts !== undefined && environ.billing_accounts.length) {
          for (let billing_account of environ.billing_accounts) {
            if (bid == billing_account.name) {
              this.billing_account_id = billing_account.billing_account_id;
              break;
            }
          }
        }
        this.store.dispatch(new ActionSignupSetBillingAccountId(this.billing_account_id));
      }
      if (plo) {
        this.product_label_override = plo;
        this.store.dispatch(new ActionSignupSetProductLabelOverride(this.product_label_override));
        console.log(window.sessionStorage.signup);
        //        window.localStorage.signup.product_label_override = this.product_label_override;
      }
    });

    this.initializeFormGroups();
    this.setAllProductGuideDeepLinks();
    this.store.dispatch(new ActionSignupInit());
    this.checkAuthFirewall()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((authIsGood) => {
        this.authIsGood = authIsGood;
      });
    this.combinedSignupData$ = this.getCombinedSignupData$();
    this.combinedSignupData$.pipe(takeUntil(this.unsubscribe$)).subscribe((combinedSignupDataResp) => {
      this.profileURL = combinedSignupDataResp.profileLink;
      if (combinedSignupDataResp.customDivText !== undefined && this.customDivText === undefined) {
        this.customDivText = !!combinedSignupDataResp.customDivText ? combinedSignupDataResp.customDivText : '';
      }
      this.signupState = combinedSignupDataResp.signupState;
      this.clusterInfoCatalogueEntries = this.getClusterInfoCatalogueEntries(combinedSignupDataResp.catalogues);
      this.setCatalogueNameToHostingDomainsMap();
      // We need to re-initialize the form groups to set the default hosting_domain value.
      this.initializeFormGroups();
      if (!!combinedSignupDataResp.user) {
        this._user = combinedSignupDataResp.user;
        if (!this.signaledSignin) {
          // Squelch the multiple events that come from each of the phases of redirect.
          // Note: this is not squelched in the final case (just before signaling complete).
          // Note: we signal just *before* signaling complete, so that the user will get
          // the email even if they don't finish the 2nd authentication.
          this.signaledSignin = true;
          this.sendMauticSignin(window.location.href, this._user, 'signup-start');
        }
        if ((window as any).__karma__ === undefined && Tawk_API !== undefined && combinedSignupDataResp.user !== undefined) {
          const te = new TextEncoder();
          const hash = sha256.hmac(te.encode(environ.tawk_key), te.encode(combinedSignupDataResp.user.email.toLowerCase()));
          const hashHex = Array.prototype.map.call(hash, (x) => x.toString(16).padStart(2, '0')).join('');

          Tawk_API.onLoad = (): void => {
            const tawk_attr = {
              email: combinedSignupDataResp.user.email.toLowerCase(),
              id: combinedSignupDataResp.user.id,
              org: combinedSignupDataResp.user.org_id,
              hash: hashHex,
            };
            const userName = getUserNameFromUser(combinedSignupDataResp.user);
            if (!!userName) {
              tawk_attr['name'] = userName;
            }
            Tawk_API.setAttributes(tawk_attr, (error) => {
              if (error === undefined) {
                return;
              }
              console.log('Tawk reg error ', error);
            });
          };
        }
        this.signupOrgId = combinedSignupDataResp.user?.org_id;
      }
      if (!this.signupState) {
        return;
      }
      if (!this.isCorrectStepperIndexChosen()) {
        this.curIndex = this.signupState.selected_index;
        this.changeDetector.detectChanges();
      }
      if (this.isUserAuthenticatedAndSigningUp() && this.curIndex === 0) {
        // user is finished initial auth
        this.store.dispatch(new ActionSignupSetSelectedIndex(1));
        return;
      }
      if (this.signupState.create_complete && this.curIndex === 1) {
        this.store.dispatch(new ActionSignupSetSelectedIndex(2));
        return;
      }
      if (this.isSignupReAuthComplete(combinedSignupDataResp.user)) {
        // enable stepper when user is returning from re authentication
        this.store.dispatch(new ActionSignupComplete());
        return;
      }
    });
  }

  private setStepperProgressBarSteps() {
    this.steps.push(new Step('CNAME valid'));
    this.steps.push(new Step('CNAME correct'));
    this.steps.push(new Step('Split-horizon dns'));
    resetProgressBarSteps(this.progressStepper, this.steps);
  }

  private isCorrectStepperIndexChosen(): boolean {
    return this.signupState?.selected_index === this.curIndex;
  }

  private isUserAuthenticatedAndSigningUp(): boolean {
    return this.signupState?.is_signing_up && this.signupState?.initial_auth_complete && !this.signupState?.final_auth;
  }

  private isSignupReAuthComplete(user: User): boolean {
    return !!user && this.signupState?.is_signing_up && this.signupState?.final_auth;
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeHostingClusterFormGroup(): void {
    this.hostingClusterForm = this.formBuilder.group({
      selectedHostingCluster: ['ca-1', [Validators.required]],
    });
  }

  private initializeSubdomainFormGroup(): void {
    this.subdomainForm = this.formBuilder.group({
      option: ['', Validators.required],
    });
  }

  private initializeSubdomainOwnFormGroup(): void {
    this.subdomainOwnForm = this.formBuilder.group({
      domain: ['', [Validators.required, this.customValidatorsService.subdomainHostnameValidator(), Validators.maxLength(63)]],
    });
  }

  private initializeSubdomainAgilicusFormGroup(): void {
    this.subdomainAgilicusForm = this.formBuilder.group({
      domain: [
        '',
        [
          Validators.required,
          this.customValidatorsService.hostnameValidator(),
          this.customAgilicusDomainValidator.bind(this),
          Validators.maxLength(63),
        ],
      ],
      hostingDomain: [this.getRandomDefaultHostingDomain(this.getHostingDomainsToDisplay()), [Validators.required]],
    });
  }

  private getTrimmedOrgNameValue(orgName: string): string {
    return !!orgName ? orgName.trim() : '';
  }

  private initializeOrgFormGroup(): void {
    this.orgForm = this.formBuilder.group({
      orgName: ['', [Validators.required, Validators.maxLength(100)]],
      subdomainForm: this.subdomainForm,
      subdomainOwnForm: this.subdomainOwnForm,
      subdomainAgilicusForm: this.subdomainAgilicusForm,
      hostingClusterForm: this.hostingClusterForm,
    });
    this.orgForm
      .get('orgName')
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        const trimmedOrgNameValue = this.getTrimmedOrgNameValue(value);
        this.autofillDomain(trimmedOrgNameValue);
      });
  }

  private initializeFormGroups(): void {
    this.initializeHostingClusterFormGroup();
    this.initializeSubdomainFormGroup();
    this.initializeSubdomainAgilicusFormGroup();
    this.initializeSubdomainOwnFormGroup();
    this.initializeOrgFormGroup();
  }

  public customAgilicusDomainValidator(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const valueString = control.value.toString();
    const invalid = { invalidDomain: { value: valueString } };
    // subdomain should not contain periods
    const isValid = valueString.indexOf('.') === -1;
    if (!isValid) {
      return invalid;
    }
    return null;
  }

  private getSubdomainOptionValue(): SubdomainOption {
    return this.subdomainForm.value.option;
  }

  public autofillDomain(value: string): void {
    const domain = this.subdomainAgilicusForm.get('domain');
    if (!domain.dirty) {
      // update the domain with the organisation name if the domain has not been manually edited
      domain.setValue(value.replace(/\s|_/g, '-'));
      if (!domain.valid) {
        // show that the field is invalid
        domain.markAsTouched();
      }
    }
  }

  public onLoginClick(): void {
    this.store.dispatch(new ActionSignupRedirecting());
    const options: OptionalAuthArgs = {
      useSessionStorage: true,
      redirect: '/signup',
    };
    const auth = new Auth(this.env.environment.overrideClientId, this.issuer, this.tokens, this.scopes, options);
    const pr = auth.login({
      redirectURI: '/signup',
    });
    pr.catch((e) => {
      signupLog(`SIGNUP ERROR: restrictive firewall ${e}`);
      console.log('SIGNUP login error: ', e);
      const dialogData = createDialogData(
        'Error: network blocked',
        `A restrictive firewall is blocking access to the authentication
       endpoint (https://auth.agilicus.cloud/). This is preventing the signup.<br>
       You may need to contact your network administrator.<br>

       Specifically, a fetch of <a href=${this.issuer}/.well-known/openid-configuration target=_blankh>${this.issuer}/.well-known/openid-configuration</a> has failed.<br>

       For more information see <a href=${this.restrictiveFirewallProductGuideDeepLink} target=_blank>product-guide</a>
      `
      );
      dialogData.informationDialog = true;
      dialogData.buttonText = { confirm: '', cancel: 'Close' };
      this.errorDialog.open(ConfirmationDialogComponent, {
        data: dialogData,
      });
    });
  }

  public signupBounce(): void {
    const url = 'https://auth.' + this.domain + '/.well-known/openid-configuration';

    this.http.get(url, { headers: getIgnoreErrorsHeader(), responseType: 'json' }).subscribe(
      (response) => {
        this.store.dispatch(new ActionSignupBounce());
      },
      (error) => {
        this.disableCreateButtonAndDisplayErrorDialog(this.splitHorizonMessagePrefix, this.splitHorizonMessage);
      }
    );
  }

  //
  // Some restrictive firewalls block auth.* SNI. See
  // https://www.agilicus.com/anyx-guide/signup-firewall-configuration/
  // Check that we can load the (default) issuer discovery, and if not,
  // disable the signup button, show a message to the user.
  private checkAuthFirewall(): Observable<boolean> {
    const url = `${this.issuer}/.well-known/openid-configuration`;
    return this.http.get<boolean>(url, { headers: getIgnoreErrorsHeader(), responseType: 'json' }).pipe(
      map((response: any) => {
        return true;
      }),
      catchError((err: any) => {
        signupLog(`SIGNUP ERROR, firewall fetch ${url} failed ${err}`);
        console.log(`ERROR: fetching ${url} failed with ${err}`);
        return of(false);
      })
    );
  }

  private getOwnSubdomainFormValue(): string | undefined {
    return this.subdomainOwnForm.value.domain;
  }

  /**
   * Checks if cname resolves if the user is using their own domain
   */
  public verifySubdomain(): void {
    const formattedSubdomainValue = getFormattedSubdomainValue(this.getOwnSubdomainFormValue());
    if (!formattedSubdomainValue) {
      return;
    }
    if (this.no_seatbelts) {
      this.allCnameValidatiorStepsPassed = true;
      return;
    }
    resetProgressBarSteps(this.progressStepper, this.steps);
    passProgressBarStep(this.progressStepper);
    this.customValidatorsService
      .cnameResolves$(formattedSubdomainValue)
      .pipe(
        concatMap((resolves: resolveCnameData) => {
          if (resolves.status) {
            const regex = /agilicus/i;
            for (const answer of resolves.response.Answer) {
              // check that appropriate CNAME has been assigned
              if (answer.name === 'auth.' + formattedSubdomainValue + '.' && answer.data.match(regex) !== null) {
                // throw 'passAllTests' error, if cname passes all the validation steps. Writing this logic here instead of subscribe method to avoid scope limitations
                return throwError(() => new HttpErrorResponse({ error: { error: 'passAllTests' } }));
              }
            }
            return throwError(() => new HttpErrorResponse({ error: { error: 'cnameNotCorrect' } }));
          }
          return throwError(() => new HttpErrorResponse({ error: { error: resolves.statusMessage } }));
        }),
        catchError((err: HttpErrorResponse) => {
          const errorMessage: string = err?.error?.error;
          if (errorMessage === 'passAllTests') {
            this.allCnameValidatiorStepsPassed = true;
            passAllProgressBarSteps(this.progressStepper);
            this.domainNameInput.nativeElement.focus();
          } else {
            this.openCnameErrorMessageDialog(errorMessage, formattedSubdomainValue);
          }
          return of(undefined);
        })
      )
      .subscribe((res: Object | undefined) => {
        if (!res) {
          this.disableCreateOrgButton();
        }
      });
  }

  public disableOrgIfCnameValidationFails(): boolean {
    if (this.getSubdomainOptionValue() === SubdomainOption.agilicus_supplied) {
      return false;
    }
    return !this.allCnameValidatiorStepsPassed;
  }

  private openCnameErrorMessageDialog(errorMessage: string, subdomainValue: string): void {
    let messagePrefix = '';
    let message = errorMessage;
    if (errorMessage === 'cnameNotResolved') {
      failFirstProgressBarStep(this.progressStepper);
      messagePrefix = `Unable to resolve CNAME "${subdomainValue}"`;
      message = `ERROR: Please follow the instructions/wait longer for the dns to propagate. Click here to follow the <a href="${this.productGuideSignupDeepLink}" target="_blank">product guide</a>`;
    } else if (errorMessage === 'cnameNotCorrect') {
      failSecondProgressBarStep(this.progressStepper);
      messagePrefix = 'CNAME appears valid but does not point to Agilicus';
      message = `ERROR: If your dns name is run by your dns provider (e.g. godaddy, google domains, aws route 53, ...) and must be configured there. <a href="${this.productGuideSignupDeepLink}" target="_blank"> Please follow the instructions here</a>`;
    }
    if (errorMessage !== 'passAllTests') {
      this.disableCreateButtonAndDisplayErrorDialog(messagePrefix, message);
    }
  }

  public disableCreateButtonAndDisplayErrorDialog(messagePrefix: string, message: string) {
    this.allCnameValidatiorStepsPassed = false;
    this.showProgress = false;
    this.orgForm.enable();
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.informationDialog = true;
    dialogData.buttonText = { confirm: '', cancel: 'Close' };
    this.errorDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
  }

  public verifyAndCreate(): void {
    this.showProgress = true;
    this.orgForm.disable();
    this.createOrg();
  }

  private getSignupRedirectUri(org: Organisation): string {
    const mtc_id = window.localStorage.mtc_id;
    const mautic_device_id = window.localStorage.mautic_device_id;
    if (mtc_id && mautic_device_id) {
      return `https://admin.${org.subdomain}/signup?signup_bounce=true&mtc_id=${mtc_id}&mautic_device_id=${mautic_device_id}`;
    }
    return `https://admin.${org.subdomain}/signup?signup_bounce=true`;
  }

  public createOrg(): void {
    // create org /orgs/signup_org/orgs
    const orgNameFormControl = this.orgForm.get('orgName');
    const newSubOrgName: string = orgNameFormControl.value;
    const trimmedNewSubOrgName = this.getTrimmedOrgNameValue(newSubOrgName);
    if (this.getSubdomainOptionValue() === SubdomainOption.agilicus_supplied) {
      this.domain = this.subdomainAgilicusForm.value.domain.toLowerCase();
      this.domain += `.${this.subdomainAgilicusForm.get('hostingDomain').value.toLowerCase()}`;
    } else {
      this.domain = getFormattedSubdomainValue(this.getOwnSubdomainFormValue());
    }

    const billing_account_id = this.signupState?.billing_account_id;
    const product_label_override = this.signupState?.product_label_override;
    console.log('Creating new org... billing_account_id: ', billing_account_id, ' product_label_override: ', product_label_override);
    this.orgsService
      .createOrg({
        OrganisationAdmin: {
          parent_id: this.signupOrgId,
          organisation: trimmedNewSubOrgName,
          subdomain: this.domain,
          billing_account_id: billing_account_id,
          product_label_override: product_label_override,
        },
      })
      .pipe(
        takeUntil(this.unsubscribe$),
        delay(10000), // wait 10 seconds before polling since it normally takes >10 seconds to be ready
        concatMap((org: Organisation) => {
          if (!org.id) {
            return throwError('Organisation missing id');
          }
          const url = 'https://auth.' + getFormattedSubdomainValue(this.getOwnSubdomainFormValue()) + '/.well-known/openid-configuration';

          return pollForCompletion$(this.orgsService, org.id).pipe(
            concatMap(() => {
              // check split-horizon issue if own domain is selected
              if (this.getSubdomainOptionValue() === SubdomainOption.own) {
                return this.http.get(url, { headers: getIgnoreErrorsHeader(), responseType: 'json' }).pipe(
                  map((response: any) => {
                    this.sendMauticSignin('signup', this._user, 'signup-complete');
                    const redirectUri = this.getSignupRedirectUri(org);
                    // move on to the next step when ready
                    this.store.dispatch(new ActionSignupCreateComplete(redirectUri));
                  }),
                  catchError((err: HttpErrorResponse) => {
                    this.sendMauticSignin('signup', this._user, 'signup-complete');
                    const redirectUri = this.getSignupRedirectUri(org);
                    // move on to the next step when ready
                    this.store.dispatch(new ActionSignupCreateComplete(redirectUri));
                    this.disableCreateButtonAndDisplayErrorDialog(this.splitHorizonMessagePrefix, this.splitHorizonMessage);
                    return EMPTY;
                  })
                );
              } else {
                this.sendMauticSignin('signup', this._user, 'signup-complete');
                const redirectUri = this.getSignupRedirectUri(org);
                // move on to the next step when ready
                this.store.dispatch(new ActionSignupCreateComplete(redirectUri));
                return EMPTY;
              }
            })
          );
        }),
        catchError((err) => {
          this.showProgress = false;
          this.orgForm.enable();
          if (err.status === 409) {
            signupLog(`SIGNUP ERROR: duplicate org name picked "${trimmedNewSubOrgName}"`);
            this.notificationService.error(
              'An organisation with this name already exists. Please choose a different organisation name and try again.'
            );
            orgNameFormControl.setErrors({ notUnique: true });
          } else {
            this.notificationService.error('Failed to create organisation. Please try again.');
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  private getClusterInfoCatalogue$(): Observable<Array<Catalogue>> {
    return this.cataloguesService
      .listCatalogues({
        catalogue_category: 'cluster-information',
      })
      .pipe(
        map((resp) => {
          return resp.catalogues;
        }),
        catchError((_) => {
          return of([]);
        })
      );
  }

  private getClusterInfoCatalogueEntries(clusterInfoCatalogues: Array<Catalogue>): Array<CatalogueEntry> {
    if (!clusterInfoCatalogues || clusterInfoCatalogues.length === 0) {
      return [];
    }
    const allCatalogueEntries: Array<CatalogueEntry> = [];
    for (const catalogue of clusterInfoCatalogues) {
      for (const entry of catalogue.catalogue_entries) {
        allCatalogueEntries.push(entry);
      }
    }
    return allCatalogueEntries;
  }

  private setCatalogueNameToHostingDomainsMap(): void {
    for (const entry of this.clusterInfoCatalogueEntries) {
      const parsedEntry = JSON.parse(entry.content);
      this.catalogueNameToHostingDomainsMap.set(parsedEntry.name, parsedEntry.hosting_domains);
    }
  }

  public getHostingDomainsToDisplay(): Array<string> {
    if (!this.signupState?.selected_index || this.signupState.selected_index !== 1 || this.signupState.create_complete) {
      // We only want to display the catalogue data or alert the user of an api failure
      // when the user is on step 2 (index 1) and before creating the new org.
      return [];
    }
    const selectedHostingCluster = this.hostingClusterForm.get('selectedHostingCluster').value;
    const hostingDomains = this.catalogueNameToHostingDomainsMap.get(selectedHostingCluster);
    if (!hostingDomains) {
      if (this.isUserAuthenticatedAndSigningUp()) {
        this.notifyUserOfNoHostingDomains();
      }
      return [];
    }
    return hostingDomains;
  }

  private getRandomDefaultHostingDomain(hostingDomains: Array<string>): string {
    if (hostingDomains.length === 0) {
      return '';
    }
    return hostingDomains[Math.floor(Math.random() * hostingDomains.length)];
  }

  private getNoHostingDomainsErrorMessage(): string {
    return 'Failed to retrieve the hosting domains list. Please refresh the page and try again.';
  }

  private notifyUserOfNoHostingDomains(): void {
    this.notificationService.error(this.getNoHostingDomainsErrorMessage());
  }

  public getStartedNow(): void {
    this.router.navigate(['/getting-started'], {
      queryParamsHandling: 'merge',
    });
  }

  public disableCreateOrgButton(): boolean {
    let disableCreateOrgButton = !this.areFormsValid() || this.showProgress;
    if (this.getSubdomainOptionValue() === SubdomainOption.agilicus_supplied) {
      return disableCreateOrgButton || this.getHostingDomainsToDisplay().length === 0;
    }
    return disableCreateOrgButton;
  }

  private areFormsValid(): boolean {
    const isOrgFormValid = areFormFieldsValid(this.orgForm, ['orgName']);
    const isHostingClusterFormValid = areFormFieldsValid(this.hostingClusterForm);
    const isSubdomainFormValid = areFormFieldsValid(this.subdomainForm, ['option']);
    if (!isOrgFormValid || !isHostingClusterFormValid || !isSubdomainFormValid) {
      return false;
    }
    let domainFormControl;
    if (this.getSubdomainOptionValue() === SubdomainOption.agilicus_supplied) {
      domainFormControl = this.subdomainAgilicusForm.get('domain');
    } else {
      domainFormControl = this.subdomainOwnForm.get('domain');
    }

    const domainErrors = domainFormControl.errors;
    if (!domainFormControl.valid) {
      if (!!domainErrors.cnameNotResolved && !domainErrors.maxLength && !domainErrors.required) {
        // We do not want to mark the form as invalid if the only error is 'cnameNotResolved' since we
        // want the user to be able to re-submit the form in the event that this case occurs.
        return true;
      }
      return false;
    }
    return true;
  }

  private nukeState(): void {
    const keys = Object.keys(window.localStorage);
    for (const k of keys) {
      if (k !== 'mtc_id' && k !== 'mautic_device_id') {
        delete window.localStorage[k];
      }
    }
    this.store.dispatch(new ActionSignupNukeState(false));
  }

  private getCustomDivText$(): Observable<string | undefined> {
    return this.filesService
      .getDownloadPublic(
        {
          tag: 'agilicus-theme',
          subdomain: getSubdomainFromIssuer(window.location.href),
          file_in_zip: 'theme/var.txt',
        },
        'body',
        getIgnoreErrorsHeader()
      )
      .pipe(
        concatMap((resp) => {
          return from(resp.text());
        }),
        // If no text exists then return an empty string:
        catchError((_) => of(''))
      );
  }

  private getProductGuideDeepLink$(link: string): Observable<string> {
    return getProductLinkFromUser$(link, this.authService);
  }

  private setProductGuideDeepLink(): void {
    this.getProductGuideDeepLink$(this.productGuideLink)
      .pipe(take(1))
      .subscribe((linkResp) => {
        this.productGuideSignupDeepLink = linkResp;
        this.changeDetector.detectChanges();
      });
  }

  private setSplitHorizonProductGuideDeepLink(): void {
    this.getProductGuideDeepLink$(this.splitHorizonProductGuideLink)
      .pipe(take(1))
      .subscribe((linkResp) => {
        this.splitHorizonProductGuideDeepLink = linkResp;
        this.changeDetector.detectChanges();
      });
  }

  private setRestrictiveFirewallProductGuideDeepLink(): void {
    this.getProductGuideDeepLink$(this.restrictiveFirewallProductGuideLink)
      .pipe(take(1))
      .subscribe((linkResp) => {
        this.restrictiveFirewallProductGuideDeepLink = linkResp;
        this.changeDetector.detectChanges();
      });
  }

  private setAllProductGuideDeepLinks(): void {
    this.setProductGuideDeepLink();
    this.setSplitHorizonProductGuideDeepLink();
    this.setRestrictiveFirewallProductGuideDeepLink();
  }

  public getProfileUrlForLinkFunc(): string {
    return this.profileURL;
  }
}
