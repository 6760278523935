import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as BillingAccountFullActions from './billing-account-full.actions';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { BasicCollectionState } from '../basic-collection-state';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';
import { BillingAccountFull } from '../models/billing/billing-account-full';
import { getDefaultPaymentMethodFromBillingAccount, isStarterPlan } from './billing-api-utils';

export const billingAccountFullsFeatureKey = 'billing';

export interface BillingAccountFullState extends BasicCollectionState<BillingAccountFull> {
  default_payment_method: string | undefined | null;
  is_starter_plan: boolean | undefined;
}

export const adapter: EntityAdapter<BillingAccountFull> = createEntityAdapter<BillingAccountFull>({
  sortComparer: sortListByCreatedDate,
  selectId: (billingAccountFull) => billingAccountFull.metadata.id,
});

export const initialState: BillingAccountFullState = adapter.getInitialState({
  ...getDefaultBasicCollectionState<BillingAccountFull>(),
  // additional entity state properties
  state_save_success: false,
  default_payment_method: undefined,
  is_starter_plan: undefined,
});

export const billingAccountFullReducer = createReducer(
  initialState,
  on(BillingAccountFullActions.addBillingAccountFull, (state, action) =>
    adapter.addOne(action.obj, { ...state, saving_state: false, loaded_org_id: action.org_id })
  ),
  on(BillingAccountFullActions.upsertBillingAccountFull, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
      state_save_success: true,
    })
  ),
  on(BillingAccountFullActions.addBillingAccountFulls, (state, action) => adapter.addMany(action.objs, state)),
  on(BillingAccountFullActions.upsertBillingAccountFulls, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(BillingAccountFullActions.updateBillingAccountFull, (state, action) => adapter.updateOne(action.obj, state)),
  on(BillingAccountFullActions.updateBillingAccountFulls, (state, action) => adapter.updateMany(action.objs, state)),
  on(BillingAccountFullActions.deleteBillingAccountFull, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(BillingAccountFullActions.deleteBillingAccountFulls, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(BillingAccountFullActions.loadBillingAccountFulls, (state, action) =>
    adapter.setAll(action.objs, {
      ...state,
      saving_state: false,
      loaded_org_id: action.org_id,
      refresh_data: state.refresh_data + 1,
      default_payment_method: getDefaultPaymentMethodFromBillingAccount(action.objs[0]),
      is_starter_plan: isStarterPlan(action.objs[0]),
    })
  ),
  on(BillingAccountFullActions.clearBillingAccountFulls, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(BillingAccountFullActions.savingBillingAccountFull, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects, state_save_success: false };
  }),
  on(BillingAccountFullActions.savingBillingAccountFulls, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects, state_save_success: false };
  }),
  on(BillingAccountFullActions.initBillingAccountFull, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(BillingAccountFullActions.refreshBillingAccountFull, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
