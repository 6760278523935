import {
  Application,
  ApplicationService,
  ApplicationServicesService,
  ApplicationsService,
  Connector,
  ConnectorsService,
  DesktopResource,
  FileShareService,
  Group,
  GroupsService,
  IssuerClient,
  IssuersService,
} from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppState } from '@app/core';
import { EventsService } from '@app/core/services/events.service';
import {
  ActionApiApplicationsCreateDemo,
  ActionApiApplicationsDeleteDemo,
  ActionApiApplicationsResetDemoStatus,
} from '@app/core/api-applications/api-applications.actions';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import { getDefaultInitialModelStatus } from '@app/core/api-applications/api-applications.reducer';
import { selectApiApplicationsDemoStatus } from '@app/core/api-applications/api-applications.selectors';
import { getAllDemoResources$, getCreateDemoButtonName, getDeleteDemoButtonName } from '@app/core/api/demo-api-utils';
import { initBillingAccountFull } from '@app/core/billing-state/billing-account-full.actions';
import { selectCurrentBillingAccountFull } from '@app/core/billing-state/billing-account-full.selectors';
import { getBillingAccountFullTrialPeriodData } from '@app/core/billing-state/billing-api-utils';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorRefreshDataValue, selectHasAtLeastOneConnector } from '@app/core/connector-state/connector.selectors';
import { getDaysSinceSignup } from '@app/core/signup/signup-utils';
import { getAdminPortalDataFromUserMetadata } from '@app/core/user/preferences/user-preference-utils';
import { selectAdminPortalUserMetadata, selectCurrentOrg } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { TourService } from 'ngx-ui-tour-md-menu';
import { catchError, combineLatest, concatMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { getDefaultCreateDemoTourAnchor } from '../tour.utils';

@Component({
  selector: 'portal-demo-setup',
  templateUrl: './demo-setup.component.html',
  styleUrls: ['./demo-setup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoSetupComponent implements OnInit, OnDestroy {
  @Input() public overrideAllShowDemoChecks = false;
  @Input() public overrideShowDemoConnectorCheck = false;
  @Input() public overrideApiResourceCheckValue: boolean | undefined = undefined;
  @Input() public showButtonsOnly = false;
  @Input() public hideCreateButton = false;
  @Input() public hideDeleteButton = false;
  @Input() public tourAnchorId = getDefaultCreateDemoTourAnchor();
  @Input() public delayShowDemo = false;
  @Input() public blockDemoTour = false;
  @Output() public startTourInParent = new EventEmitter<any>();
  @Output() public startedDemoCreation = new EventEmitter<any>();
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  private orgName: string;
  public demoStatus: ApplicationModelStatus = getDefaultInitialModelStatus();
  public createDemoProgressBarController: ProgressBarController = new ProgressBarController();
  public showDemoAll = false;
  public hideApplyButton = true;
  public demoApplication: Application;
  public demoIssuerClient: IssuerClient;
  public demoGroup: Group;
  public demoDesktop: DesktopResource;
  public demoShare: FileShareService;
  public demoApplicationService: ApplicationService;
  public demoConnector: Connector;
  private trialDaysRemaining = 0;
  private hasAtLeastOneConnector: boolean;
  private daysSinceSignup = 0;
  private connectorRefreshValue = 0;
  private helpTourStarted = false;

  public getCreateDemoButtonName = getCreateDemoButtonName;
  public getDeleteDemoButtonName = getDeleteDemoButtonName;

  public productGuideLink = `https://www.agilicus.com/example/agilicus-anyx-demo/`;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private applicationsService: ApplicationsService,
    private issuersService: IssuersService,
    private groupService: GroupsService,
    private applicationServicesService: ApplicationServicesService,
    private connectorsService: ConnectorsService,
    private tourService: TourService,
    private eventsService: EventsService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initBillingAccountFull({ force: true, blankSlate: false }));
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    const adminPortalUserMetadata$ = this.store.pipe(select(selectAdminPortalUserMetadata));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrg));
    const demoStatusState$ = this.store.pipe(select(selectApiApplicationsDemoStatus));
    const currentBillingAccount$ = this.store.pipe(select(selectCurrentBillingAccountFull));
    const hasAtLeastOneConnector$ = this.store.pipe(selectHasAtLeastOneConnector);
    const refreshConnectorDataState$ = this.store.pipe(select(selectConnectorRefreshDataValue));
    combineLatest([
      currentOrg$,
      demoStatusState$,
      currentBillingAccount$,
      hasAtLeastOneConnector$,
      refreshConnectorDataState$,
      adminPortalUserMetadata$,
    ])
      .pipe(
        concatMap(
          ([
            currentOrgResp,
            demoStatusStateResp,
            currentBillingAccountResp,
            hasAtLeastOneConnectorResp,
            refreshConnectorDataStateResp,
            adminPortalUserMetadataResp,
          ]) => {
            let allDemoResources$: Observable<
              [Application, Array<IssuerClient>, Group, DesktopResource, FileShareService, ApplicationService, Connector] | undefined
            > = of(undefined);
            if (!!currentOrgResp && this.overrideApiResourceCheckValue === undefined) {
              allDemoResources$ = getAllDemoResources$(
                this.applicationsService,
                this.issuersService,
                this.groupService,
                this.applicationServicesService,
                this.connectorsService,
                currentOrgResp.id
              ).pipe(catchError((_) => of(undefined)));
            }
            return combineLatest([
              of(currentOrgResp),
              of(demoStatusStateResp),
              of(currentBillingAccountResp),
              of(hasAtLeastOneConnectorResp),
              of(refreshConnectorDataStateResp),
              of(adminPortalUserMetadataResp),
              allDemoResources$,
            ]);
          }
        )
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          currentOrgResp,
          demoStatusStateResp,
          currentBillingAccountResp,
          hasAtLeastOneConnectorResp,
          refreshConnectorDataStateResp,
          adminPortalUserMetadataResp,
          allDemoResourcesResp,
        ]) => {
          this.orgId = currentOrgResp?.id;
          this.orgName = currentOrgResp?.organisation;
          if (!!allDemoResourcesResp) {
            this.demoApplication = allDemoResourcesResp[0];
            this.demoIssuerClient = allDemoResourcesResp[1][0];
            this.demoGroup = allDemoResourcesResp[2];
            this.demoDesktop = allDemoResourcesResp[3];
            this.demoShare = allDemoResourcesResp[4];
            this.demoApplicationService = allDemoResourcesResp[5];
            this.demoConnector = allDemoResourcesResp[6];
          }
          this.demoStatus = demoStatusStateResp;
          if (this.demoStatus.saving) {
            this.onStartDemoCreationFunc();
          }
          const trialPeriodData = getBillingAccountFullTrialPeriodData(currentBillingAccountResp, currentOrgResp);
          this.trialDaysRemaining = !!trialPeriodData ? trialPeriodData.daysRemaining : 0;
          this.hasAtLeastOneConnector = hasAtLeastOneConnectorResp;
          this.daysSinceSignup = getDaysSinceSignup(getAdminPortalDataFromUserMetadata(adminPortalUserMetadataResp));
          this.connectorRefreshValue = refreshConnectorDataStateResp;
          this.delaySetShowDemoValueAndStartTour();
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public createDemo(): void {
    this.eventsService.SendEvent('demo_create', localStorage.email, this.orgName, window.location.href);

    this.store.dispatch(new ActionApiApplicationsCreateDemo());
    this.tourService.end();
  }

  public deleteDemo(): void {
    this.eventsService.SendEvent('demo_delete', localStorage.email, this.orgName, window.location.href);
    this.store.dispatch(new ActionApiApplicationsDeleteDemo());
  }

  public enableDemoCreateButton(): boolean {
    return !this.demoStatus.saving && !this.demoStatus.deleting;
  }

  public enableDemoDeleteButton(): boolean {
    return !this.demoStatus.saving && !this.demoStatus.deleting && (this.hasDemoResources() || !!this.demoStatus.delete_failed);
  }

  private hasDemoResources(): boolean {
    if (this.overrideApiResourceCheckValue !== undefined) {
      return this.overrideApiResourceCheckValue;
    }
    return (
      !!this.demoApplication ||
      !!this.demoIssuerClient ||
      !!this.demoGroup ||
      !!this.demoDesktop ||
      !!this.demoApplicationService ||
      !!this.demoConnector
    );
  }

  public resetModelStatus(): void {
    this.store.dispatch(new ActionApiApplicationsResetDemoStatus());
  }

  public showDemo(): boolean {
    if (this.overrideAllShowDemoChecks) {
      return true;
    }
    if (this.trialDaysRemaining === 0) {
      return false;
    }
    if (this.daysSinceSignup !== undefined && this.daysSinceSignup > 10) {
      return false;
    }
    if (!this.showDemoCreateButton() && !this.showDemoDeleteButton()) {
      return false;
    }
    return true;
  }

  public delaySetShowDemoValueAndStartTour(): void {
    if (this.blockDemoTour) {
      return;
    }
    if (!this.delayShowDemo) {
      this.setShowDemoValueAndStartTour();
      return;
    }
    setTimeout(() => {
      this.setShowDemoValueAndStartTour();
    }, 1000);
  }

  public setShowDemoValueAndStartTour(): void {
    const showDemoValue = this.showDemo();
    if (showDemoValue) {
      this.startTourInParentComponentFunc();
    }
    this.showDemoAll = showDemoValue;
    this.changeDetector.detectChanges();
  }

  public showDemoCreateButton(): boolean {
    if (this.overrideAllShowDemoChecks) {
      return true;
    }
    if (this.hideCreateButton) {
      return false;
    }
    if (this.showDemoCreateButtonWhenNoConnectorExists()) {
      return true;
    }
    return false;
  }

  private showDemoCreateButtonWhenNoConnectorExists(): boolean {
    if (this.overrideShowDemoConnectorCheck) {
      return true;
    }
    // The initial connector list we get from state will be empty until after the first refresh,
    // so we also need to check the state refresh value:
    return this.hasAtLeastOneConnector !== undefined && !this.hasAtLeastOneConnector && this.connectorRefreshValue > 0;
  }

  public showDemoDeleteButton(): boolean {
    if (this.overrideAllShowDemoChecks) {
      return true;
    }
    if (this.hideDeleteButton) {
      return false;
    }
    if (this.hasDemoResources()) {
      return true;
    }
    return false;
  }

  public startTourInParentComponentFunc(): void {
    if (this.helpTourStarted) {
      return;
    }
    this.eventsService.SendEvent('demo_offered', localStorage.email, this.orgName, window.location.href);
    this.helpTourStarted = true;
    this.startTourInParent.emit();
  }

  private onStartDemoCreationFunc(): void {
    this.startedDemoCreation.emit();
  }
}
