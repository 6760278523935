import { ApplicationModel } from '@app/core/models/application/application-model';
import {
  disableAgentHostingOption,
  disableAgilicusRuntimeOption,
  disableCloudHostingOption,
  disableOidcAuthMethod,
  disableOnPremHostingOptions,
  disableOwnRuntimeOption,
  disableProxyAuthOption,
  disableSamlAuthMethod,
  disableSelfAuthOptions,
  disableVpnHostingOption,
  enableUserDefinedRoles,
  setEnabledForNestedProperties,
} from '../../../core/models/application/application-model-api-utils';
import { getDefaultApplicationModel } from '../../../core/models/application/application-model-utils';

export class ApplicationStepperController {
  public handleInternetHostingOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    appModel.hosting.enabled = false;
    disableOnPremHostingOptions(appModel);
    disableCloudHostingOption(appModel);
    // Need to disable proxy because this option is not available if 'internet hosting' is selected.
    disableProxyAuthOption(appModel);
  }

  public handleAgentHostingOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    appModel.hosting.enabled = true;
    setEnabledForNestedProperties(appModel.hosting, ['on_prem', 'agent'], true);
    disableVpnHostingOption(appModel);
    disableCloudHostingOption(appModel);
  }

  public handleVpnHostingOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    appModel.hosting.enabled = true;
    setEnabledForNestedProperties(appModel.hosting, ['on_prem', 'vpn'], true);
    disableAgentHostingOption(appModel);
    disableCloudHostingOption(appModel);
  }

  public handleCloudHostingOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    appModel.hosting.enabled = true;
    setEnabledForNestedProperties(appModel.hosting, ['in_cloud', 'runtime'], true);
    disableOnPremHostingOptions(appModel);
  }

  public handleNoAuthOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.authentication) {
      appModel.authentication = getDefaultApplicationModel().authentication;
    }
    appModel.authentication.enabled = false;
    disableSelfAuthOptions(appModel);
    disableProxyAuthOption(appModel);
  }

  public handleOwnAuthOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.authentication) {
      appModel.authentication = getDefaultApplicationModel().authentication;
    }
    appModel.authentication.enabled = true;
    disableProxyAuthOption(appModel);
  }

  public handleProxyAuthOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.authentication) {
      appModel.authentication = getDefaultApplicationModel().authentication;
    }
    appModel.authentication.enabled = true;
    setEnabledForNestedProperties(appModel.authentication, ['proxy'], true);
    disableSelfAuthOptions(appModel);
  }

  public handleSamlAuthMethodSelection(appModel: ApplicationModel): void {
    if (!appModel.authentication) {
      appModel.authentication = getDefaultApplicationModel().authentication;
    }
    appModel.authentication.enabled = true;
    setEnabledForNestedProperties(appModel.authentication, ['saml'], true);
    disableOidcAuthMethod(appModel);
    disableProxyAuthOption(appModel);
  }

  public handleOidcAuthMethodSelection(appModel: ApplicationModel): void {
    if (!appModel.authentication) {
      appModel.authentication = getDefaultApplicationModel().authentication;
    }
    appModel.authentication.enabled = true;
    setEnabledForNestedProperties(appModel.authentication, ['oidc'], true);
    disableSamlAuthMethod(appModel);
    disableProxyAuthOption(appModel);
  }

  public handleAllUsersUserAuthSelection(appModel: ApplicationModel): void {
    if (!appModel.authorization) {
      appModel.authorization = getDefaultApplicationModel().authorization;
    }
    appModel.authorization.all_users = true;
    appModel.authorization.all_org_users = false;
    appModel.authorization.single_role_users = false;
    setEnabledForNestedProperties(appModel.authorization, ['user_defined_roles'], false);
  }

  public handleAllOrgUsersUserAuthSelection(appModel: ApplicationModel): void {
    if (!appModel.authorization) {
      appModel.authorization = getDefaultApplicationModel().authorization;
    }
    appModel.authorization.all_users = false;
    appModel.authorization.all_org_users = true;
    appModel.authorization.single_role_users = false;
    setEnabledForNestedProperties(appModel.authorization, ['user_defined_roles'], false);
  }

  public handleSingleRoleUsersUserAuthSelection(appModel: ApplicationModel): void {
    if (!appModel.authorization) {
      appModel.authorization = getDefaultApplicationModel().authorization;
    }
    appModel.authorization.all_users = false;
    appModel.authorization.all_org_users = false;
    appModel.authorization.single_role_users = true;
    setEnabledForNestedProperties(appModel.authorization, ['user_defined_roles'], false);
  }

  public handleDistinctRoleUsersUserAuthSelection(appModel: ApplicationModel): void {
    if (!appModel.authorization) {
      appModel.authorization = getDefaultApplicationModel().authorization;
    }
    appModel.authorization.all_users = false;
    appModel.authorization.all_org_users = false;
    appModel.authorization.single_role_users = false;
    enableUserDefinedRoles(appModel);
  }

  public handleAllOrgUsersPlusRolesUserAuthSelection(appModel: ApplicationModel): void {
    if (!appModel.authorization) {
      appModel.authorization = getDefaultApplicationModel().authorization;
    }
    appModel.authorization.all_users = false;
    appModel.authorization.all_org_users = true;
    appModel.authorization.single_role_users = false;
    enableUserDefinedRoles(appModel);
  }

  public handleOwnRuntimeSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    setEnabledForNestedProperties(appModel.hosting, ['in_cloud', 'runtime', 'own'], true);
    disableAgilicusRuntimeOption(appModel);
  }

  public handleAgilicusRuntimeSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    setEnabledForNestedProperties(appModel.hosting, ['in_cloud', 'runtime', 'agilicus'], true);
    disableOwnRuntimeOption(appModel);
  }

  public handleAutoFqdnAliasOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    if (!appModel.hosting.fqdnAliases) {
      appModel.hosting.fqdnAliases = getDefaultApplicationModel().hosting.fqdnAliases;
    }
    appModel.hosting.fqdnAliases.enabled = false;
  }

  public handleManualFqdnAliasOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    if (!appModel.hosting.fqdnAliases) {
      appModel.hosting.fqdnAliases = getDefaultApplicationModel().hosting.fqdnAliases;
    }
    appModel.hosting.fqdnAliases.enabled = true;
  }

  public handleCorsOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    if (!appModel.hosting.on_prem) {
      appModel.hosting.on_prem = getDefaultApplicationModel().hosting.on_prem;
    }
    if (!appModel.hosting.on_prem.cors) {
      appModel.hosting.on_prem.cors = getDefaultApplicationModel().hosting.on_prem.cors;
    }
  }

  public handleHttpConfigOptionSelection(appModel: ApplicationModel): void {
    if (!appModel.hosting) {
      appModel.hosting = getDefaultApplicationModel().hosting;
    }
    if (!appModel.hosting.on_prem) {
      appModel.hosting.on_prem = getDefaultApplicationModel().hosting.on_prem;
    }
    if (!appModel.hosting.on_prem.upstream_services) {
      appModel.hosting.on_prem.upstream_services = getDefaultApplicationModel().hosting.on_prem.upstream_services;
    }
    if (!appModel.hosting.on_prem.upstream_services[0].protocol_config) {
      appModel.hosting.on_prem.upstream_services[0].protocol_config =
        getDefaultApplicationModel().hosting.on_prem.upstream_services[0].protocol_config;
    }
    if (!appModel.hosting.on_prem.upstream_services[0].protocol_config.http_config) {
      appModel.hosting.on_prem.upstream_services[0].protocol_config.http_config =
        getDefaultApplicationModel().hosting.on_prem.upstream_services[0].protocol_config.http_config;
    }
    if (!appModel.hosting.on_prem.upstream_services[0].protocol_config.http_config.disable_http2) {
      appModel.hosting.on_prem.upstream_services[0].protocol_config.http_config.disable_http2 =
        getDefaultApplicationModel().hosting.on_prem.upstream_services[0].protocol_config.http_config.disable_http2;
    }
  }
}
