<div *ngIf="showButton()" class="save-button-container">
  <button
    type="button"
    mat-raised-button
    color="primary"
    class="action-button"
    matTooltip="Submit the completed {{ stepperType }}"
    attr.aria-label="Button to save changes to the completed {{ stepperType }}"
    (click)="submitApplicationModel()"
    [disabled]="isSubmittingAppModel"
  >
    APPLY
  </button>
</div>

<portal-progress-bar
  [progressBarController]="appModelSubmissionProgressBarController"
  [progressBarInfoText]="progressBarInfoText"
>
</portal-progress-bar>