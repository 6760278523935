// TODO: rename file & folder to policy

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppState, selectPolicyState } from '../core.state';
import { Store, select } from '@ngrx/store';
import { concatMap, map, catchError, withLatestFrom, switchMap, filter } from 'rxjs/operators';
import { of, EMPTY, Observable, forkJoin } from 'rxjs';
import { selectApiOrgId } from '../user/user.selectors';
import { NotificationService } from '../notifications/notification.service';
import {
  IssuersService,
  FilesService,
  FileSummary,
  Issuer,
  patch_via_put,
  ListPoliciesRequestParams,
  ListPoliciesResponse,
  Policy,
  ReplacePolicyRequestParams,
  PolicyRule,
  CreatePolicyRuleRequestParams,
  ReplacePolicyRuleRequestParams,
  GetPolicyRuleRequestParams,
  DeletePolicyRuleRequestParams,
  PolicyGroup,
  SetPolicyRequestParams,
} from '@agilicus/angular';
import {
  PolicyActionTypes,
  ActionPolicySetTheme,
  ActionPolicySavingTheme,
  ActionPolicySuccessfulThemeSave,
  ActionPolicyFailedThemeSave,
  ActionPolicyUpdatingIssuerThemeId,
  ActionPolicyDeletingTheme,
  ActionPolicySuccessfulThemeDeletion,
  ActionPolicyFailedThemeDeletion,
  ActionPolicyResetTheme,
  ActionPolicyDeletingIssuerThemeId,
  ActionPolicySetPolicy,
  ActionPolicySavingPolicy,
  ActionPolicyFailedPolicySave,
  ActionPolicySuccessfulPolicySave,
  ActionPolicyAddToPolicyRuleList,
  ActionPolicyUpdatePolicyRuleList,
  ActionPolicyRemoveFromPolicyRuleList,
  ActionPolicyLoadPolicy,
  ActionPolicyDeletingPolicyGroup,
  ActionPolicySuccessfulPolicyRulesDeletion,
  ActionPolicyFailedPolicyRulesDeletion,
  ActionPolicySavingPolicyRule,
  ActionPolicyMaintainState,
} from './issuer.actions';
import { AppErrorHandler } from '../error-handler/app-error-handler.service';
import { deleteAction, saveAction } from '@app/shared/components/utils';
import { cloneDeep } from 'lodash-es';
import { getCrudActionTypeName } from '../api/state-driven-crud/state-driven-crud';
import { updateIssuerThemeId } from '../issuer-state/issuer.utils';
import { selectCanAdminOrReadFiles } from '../user/permissions/files.selectors';
import * as IssuerActions from '../issuer-state/issuer.actions';
import { PolicyState } from './issuer.models';
import { savingIssuer } from '../issuer-state/issuer.actions';
import { selectCurrentIssuer } from '../issuer-state/issuer.selectors';
import { CrudActions, ResetStateAction } from '../api/state-driven-crud/state-driven-crud.actions';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';

@Injectable()
export class PolicyEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private issuersService: IssuersService,
    private filesService: FilesService,
    private notificationService: NotificationService,
    private appErrorHandler: AppErrorHandler
  ) {}

  public loadTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerActions.loadIssuers),
      concatMap((action) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCanAdminOrReadFiles)), this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, canReadFiles, orgId]) => {
        if (!canReadFiles.hasPermission || !action.objs || !action.objs[0]?.theme_file_id) {
          return of(new ActionPolicySetTheme(undefined));
        }
        return this.filesService.getFile({ file_id: action.objs[0].theme_file_id, org_id: orgId }).pipe(
          map((file: FileSummary) => {
            return new ActionPolicySetTheme(file);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to retrieve the issuer theme information. Please reload the page to try again.');
            return of(new ActionPolicySetTheme(undefined));
          })
        );
      })
    )
  );

  public saveTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.SAVING_THEME),
      concatMap((action: ActionPolicySavingTheme) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))),
      concatMap(([action, orgId]: [ActionPolicySavingTheme, string]) => {
        return this.filesService
          .addFile({ name: action.new_file.name, file_zip: action.new_file, org_id: orgId, visibility: 'public', tag: 'agilicus-theme' })
          .pipe(
            map((fileResp: FileSummary) => {
              this.notificationService.success('Theme "' + fileResp.name + '" was successfully uploaded');
              return new ActionPolicySuccessfulThemeSave(fileResp);
            }),
            catchError((_) => {
              this.notificationService.error('Failed to save new theme "' + action.new_file.name + '". Please try again.');
              return of(new ActionPolicyFailedThemeSave());
            })
          );
      })
    )
  );

  public updateIssuerThemeId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.SUCCESSFUL_THEME_SAVE),
      switchMap((action: ActionPolicySuccessfulThemeSave) => {
        return of(new ActionPolicyUpdatingIssuerThemeId(action.new_theme.id));
      })
    )
  );

  public saveIssuerThemeId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.UPDATING_ISSUER_THEME_ID),
      concatMap((action: ActionPolicyUpdatingIssuerThemeId) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentIssuer))))
      ),
      map(([action, currentIssuer]: [ActionPolicyUpdatingIssuerThemeId, Issuer]) => {
        const updatedIssuer = updateIssuerThemeId(action.new_theme_id, currentIssuer);
        return savingIssuer({ obj: updatedIssuer, trigger_update_side_effects: false, notifyUser: true });
      })
    )
  );

  public deleteThemeOnIssuerSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerActions.upsertIssuer),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectPolicyState))))),
      map(([action, issuerState]) => {
        if (issuerState.previous_theme === undefined) {
          return new ActionPolicyMaintainState();
        }
        return new ActionPolicyDeletingTheme(issuerState.previous_theme);
      })
    )
  );

  public deletingTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.DELETING_THEME),
      concatMap((action: ActionPolicyDeletingTheme) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))),
      concatMap(([action, orgId]: [ActionPolicyDeletingTheme, string]) => {
        return this.filesService.deleteFile({ file_id: action.theme_to_delete.id, org_id: orgId }).pipe(
          map((_) => {
            return new ActionPolicySuccessfulThemeDeletion();
          }),
          catchError((_) => {
            this.notificationService.error('Failed to delete previous theme "' + action.theme_to_delete.name + '"');
            return of(new ActionPolicyFailedThemeDeletion());
          })
        );
      })
    )
  );

  public resetThemeOnIssuerReset$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => action.type === getCrudActionTypeName(CrudActions.RESET_STATE, CrudStateCollection.issuer)),
      concatMap((action: ResetStateAction<string>) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectPolicyState))))),
      map(([action, policyState]) => {
        if (!policyState.previous_theme) {
          return new ActionPolicyMaintainState();
        }
        return new ActionPolicyResetTheme();
      })
    );
  });

  public onFailedThemeDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.FAILED_THEME_DELETION),
      concatMap((action: ActionPolicyFailedThemeDeletion) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectPolicyState))))),
      map(([action, issuerState]: [ActionPolicyFailedThemeDeletion, PolicyState]) => {
        if (!issuerState.deleting_current_theme) {
          return new ActionPolicyMaintainState();
        }
        return new ActionPolicyResetTheme();
      })
    )
  );

  public onThemeIdDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.DELETING_ISSUER_THEME_ID),
      concatMap((action: ActionPolicyDeletingIssuerThemeId) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentIssuer))))
      ),
      map(([action, currentIssuer]: [ActionPolicyDeletingIssuerThemeId, Issuer]) => {
        const updatedIssuer = updateIssuerThemeId('', currentIssuer);
        return savingIssuer({ obj: updatedIssuer, trigger_update_side_effects: false, notifyUser: true });
      })
    )
  );

  public loadPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IssuerActions.loadIssuers),
      switchMap((action) => {
        return of(new ActionPolicyLoadPolicy());
      })
    )
  );

  public setPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.LOAD_POLICY),
      concatMap((action: ActionPolicyLoadPolicy) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentIssuer)), this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, currentIssuerState, orgId]: [ActionPolicyLoadPolicy, Issuer, string]) => {
        if (!currentIssuerState) {
          return of(new ActionPolicySetPolicy(undefined));
        }
        const listPoliciesParams: ListPoliciesRequestParams = {
          org_id: orgId,
          issuer_id: currentIssuerState.id,
        };
        return this.issuersService.listPolicies(listPoliciesParams).pipe(
          map((policyResp: ListPoliciesResponse) => {
            const policies = policyResp.authentication_policies;
            if (policies.length === 0) {
              return new ActionPolicySetPolicy(undefined);
            }
            return new ActionPolicySetPolicy(policies[0]);
          }),
          catchError((_) => {
            this.notificationService.error('Failed to retrieve the policy information. Please reload the page to try again.');
            return of(new ActionPolicySetPolicy(undefined));
          })
        );
      })
    )
  );

  public savingPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.SAVING_POLICY),
      switchMap((action: ActionPolicySavingPolicy) => {
        if (action.overwrite_policy) {
          return this.overwritePolicy(action.issuer_policy);
        }
        return this.putPolicy(action.issuer_policy);
      })
    )
  );

  public savingPolicyRule$ = saveAction<PolicyRule, PolicyState>(
    this.store,
    this.actions$,
    PolicyActionTypes.SAVING_POLICY_RULE,
    selectPolicyState,
    this.postPolicyRule.bind(this),
    this.putPolicyRule.bind(this)
  );

  public deletePolicyRule$ = deleteAction<PolicyRule, PolicyState>(
    this.store,
    this.actions$,
    PolicyActionTypes.DELETING_POLICY_RULE,
    selectPolicyState,
    this.deletePolicyRule.bind(this)
  );

  public addRuleIdToGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.ADD_TO_POLICY_RULE_LIST),
      concatMap((action: ActionPolicyAddToPolicyRuleList) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectPolicyState))))),
      concatMap(([action, issuerState]: [ActionPolicyAddToPolicyRuleList, PolicyState]) => {
        const issuerPolicyCopy = cloneDeep(issuerState.current_issuer_policy);
        const groupToUpdate = issuerPolicyCopy.spec.policy_groups.find((group) => group.metadata.id === issuerState.updated_group_id);
        if (!groupToUpdate) {
          return EMPTY;
        }
        groupToUpdate.spec.rule_ids.push(action.api_obj.metadata.id);
        return of(new ActionPolicySavingPolicy(issuerPolicyCopy, false));
      })
    )
  );

  public refreshPolicyStateOnRuleDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.REMOVE_FROM_POLICY_RULE_LIST),
      switchMap((action: ActionPolicyRemoveFromPolicyRuleList) => {
        return of(new ActionPolicyLoadPolicy());
      })
    )
  );

  public deletingPolicyGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.DELETING_POLICY_GROUP),
      concatMap((action: ActionPolicyDeletingPolicyGroup) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectPolicyState))))),
      concatMap(([action, issuerState]: [ActionPolicyDeletingPolicyGroup, PolicyState]) => {
        const issuerPolicyCopy = cloneDeep(issuerState.current_issuer_policy);
        const updatedPolicyGroupsList = issuerPolicyCopy.spec.policy_groups.filter(
          (policyGroup: PolicyGroup) => policyGroup.metadata.id !== action.policyGroupToDelete.metadata.id
        );
        issuerPolicyCopy.spec.policy_groups = updatedPolicyGroupsList;
        return of(new ActionPolicySavingPolicy(issuerPolicyCopy, false));
      })
    )
  );

  // TODO: fix this:
  public deletePolicyRulesInList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PolicyActionTypes.SUCCESSFUL_POLICY_SAVE),
      concatMap((action: ActionPolicySuccessfulPolicySave) =>
        of(action).pipe(withLatestFrom(this.store.select(selectPolicyState), this.store.select(selectApiOrgId)))
      ),
      concatMap(([action, issuerState, orgId]: [ActionPolicySuccessfulPolicySave, PolicyState, string]) => {
        const policyRulesToDelete = [];
        for (const policyRule of issuerState.current_policy_rules_list) {
          if (issuerState.policy_rule_ids_to_delete.includes(policyRule.metadata.id)) {
            policyRulesToDelete.push(policyRule);
          }
        }
        const deletePolicyRulesObservablesArray = [];
        for (const policyRuleToDelete of policyRulesToDelete) {
          deletePolicyRulesObservablesArray.push(this.deletePolicyRule(policyRuleToDelete, issuerState, orgId));
        }
        return forkJoin(deletePolicyRulesObservablesArray).pipe(
          map((_) => {
            return new ActionPolicySuccessfulPolicyRulesDeletion();
          }),
          catchError((_) => {
            return of(new ActionPolicyFailedPolicyRulesDeletion());
          })
        );
      })
    )
  );

  private putPolicy(issuerPolicy: Policy): Observable<ActionPolicyFailedPolicySave | ActionPolicySuccessfulPolicySave> {
    const replacePolicyParams: ReplacePolicyRequestParams = {
      policy_id: issuerPolicy.metadata.id,
      Policy: issuerPolicy,
    };
    return this.issuersService.replacePolicy(replacePolicyParams).pipe(
      map((updatedPolicy) => {
        this.notificationService.success('The policy was successfully updated!');
        return new ActionPolicySuccessfulPolicySave(updatedPolicy);
      }),
      catchError((error) => {
        const baseMessage = 'Failed to update the policy';
        this.appErrorHandler.handlePotentialConflict(error, baseMessage);
        return of(new ActionPolicyFailedPolicySave());
      })
    );
  }

  private postPolicyRule(
    action: ActionPolicySavingPolicyRule,
    policyState: PolicyState
  ): Observable<ActionPolicyAddToPolicyRuleList | ActionPolicyMaintainState> {
    const createPolicyRuleParams: CreatePolicyRuleRequestParams = {
      policy_id: policyState.current_issuer_policy.metadata.id,
      PolicyRule: action.api_obj,
    };
    return this.issuersService.createPolicyRule(createPolicyRuleParams).pipe(
      map((policyRuleResp: PolicyRule) => {
        this.notificationService.success(`Policy rule "${policyRuleResp.spec.name}" was successfully created!`);
        return new ActionPolicyAddToPolicyRuleList(policyRuleResp);
      }),
      catchError((_) => {
        this.notificationService.error(`Failed to create policy rule "${action.api_obj.spec.name}"`);
        return of(new ActionPolicyMaintainState());
      })
    );
  }

  private putPolicyRule(
    policyRuleToModify: PolicyRule,
    policyState: PolicyState,
    orgId: string
  ): Observable<ActionPolicyUpdatePolicyRuleList | ActionPolicyMaintainState> {
    const putter = (policyRule: PolicyRule) => {
      const replacePolicyRuleParams: ReplacePolicyRuleRequestParams = {
        policy_id: policyState.current_issuer_policy.metadata.id,
        policy_rule_id: policyRule.metadata.id,
        PolicyRule: policyRule,
      };
      return this.issuersService.replacePolicyRule(replacePolicyRuleParams);
    };
    const getter = (policyRule: PolicyRule) => {
      const getPolicyRuleParams: GetPolicyRuleRequestParams = {
        policy_id: policyState.current_issuer_policy.metadata.id,
        policy_rule_id: policyRule.metadata.id,
        org_id: orgId,
      };
      return this.issuersService.getPolicyRule(getPolicyRuleParams);
    };
    const putPolicyRule$ = patch_via_put(policyRuleToModify, getter, putter);
    return putPolicyRule$.pipe(
      concatMap((updateResp) => {
        this.notificationService.success('Policy Rule "' + updateResp.spec.name + '" was successfully updated!');
        return of(new ActionPolicyUpdatePolicyRuleList(updateResp));
      }),
      catchError((_) => {
        this.notificationService.error('Failed to update policy rule "' + policyRuleToModify.spec.name + '"');
        return of(new ActionPolicyMaintainState());
      })
    );
  }

  private deletePolicyRule(
    policyRuleToDelete: PolicyRule,
    issuerState: PolicyState,
    orgId: string
  ): Observable<ActionPolicyRemoveFromPolicyRuleList> {
    const deletePolicyRuleParams: DeletePolicyRuleRequestParams = {
      policy_id: issuerState.current_issuer_policy.metadata.id,
      policy_rule_id: policyRuleToDelete.metadata.id,
      org_id: orgId,
    };
    return this.issuersService.deletePolicyRule(deletePolicyRuleParams).pipe(
      map((_) => {
        this.notificationService.success('Policy rule "' + policyRuleToDelete.spec.name + '" was successfully deleted!');
        return new ActionPolicyRemoveFromPolicyRuleList(policyRuleToDelete);
      }),
      catchError((_) => {
        this.notificationService.error('Failed to delete policy rule "' + policyRuleToDelete.spec.name + '"');
        return EMPTY;
      })
    );
  }

  private overwritePolicy(issuerPolicy: Policy): Observable<ActionPolicyFailedPolicySave | ActionPolicySuccessfulPolicySave> {
    const setPolicyRequestParams: SetPolicyRequestParams = {
      issuer_id: issuerPolicy.spec.issuer_id,
      PolicySpec: issuerPolicy.spec,
    };
    return this.issuersService.setPolicy(setPolicyRequestParams).pipe(
      map((updatedPolicy) => {
        this.notificationService.success('The policy was successfully updated!');
        return new ActionPolicySuccessfulPolicySave(updatedPolicy);
      }),
      catchError((error) => {
        const baseMessage = 'Failed to update the policy';
        this.appErrorHandler.handlePotentialConflict(error, baseMessage);
        return of(new ActionPolicyFailedPolicySave());
      })
    );
  }
}
