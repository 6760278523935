import { AppState } from '@app/core';
import { getCreateDemoButtonName } from '@app/core/api/demo-api-utils';
import { ActionUIOpenSideNavMenuTargetRoute } from '@app/core/ui/ui.actions';
import { Store } from '@ngrx/store';
import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';

export function getTourSteps(): Array<IMdStepOption> {
  return [
    {
      ...getDefaultTourStepConfig(),
      stepId: '0',
      anchorId: 'connector-new',
      title: 'Create a Connector',
      content: `A connector is a means of getting, securely, from one domain of connectivity to another`,
    },
    // Possible next step:
    /*
    {
      ...getDefaultTourStepConfig(),
      stepId: '1',
      anchorId: 'shared-identity',
      title: 'Identify Users',
      content: 'Allow your users to sign in with various public identity providers with no additional configuration',
    },
    */
  ];
}

export function getFirstConnectorDemoTourStep(anchorId: string): IMdStepOption {
  return {
    ...getDefaultTourStepConfig(),
    stepId: '0',
    anchorId,
    title: 'Create Demo Setup',
    content: `I see that this is your first time here. 
    Would you like to explore a demo environment? 
    If so, click "${getCreateDemoButtonName()}".`,
  };
}

export function getDefaultTourStepConfig(): IMdStepOption {
  return {
    enableBackdrop: true,
    placement: {
      xPosition: 'after',
      yPosition: 'below',
    },
    popoverClass: 'tour-overlay-custom',
    endBtnTitle: 'Done',
  };
}

export function getNextStepAnchorId(event: { name: string; value: any }, tourSteps: Array<IMdStepOption>): string | undefined {
  const currentStepIdAsNumber = parseFloat(event.value.step.stepId);
  if (isNaN(currentStepIdAsNumber)) {
    return undefined;
  }
  return tourSteps[currentStepIdAsNumber + 1]?.anchorId;
}

export function openSideMenuForTourNextStep(
  event: { name: string; value: any },
  tourSteps: Array<IMdStepOption>,
  store: Store<AppState>
): void {
  if (event.name !== 'stepHide') {
    return;
  }
  const nextStepAnchorId = getNextStepAnchorId(event, tourSteps);
  if (!nextStepAnchorId) {
    return;
  }
  // We need to open the correct side menu before the next tour step is fired.
  store.dispatch(new ActionUIOpenSideNavMenuTargetRoute(nextStepAnchorId));
}

export function getDefaultCreateDemoTourAnchor(): string {
  return 'create-demo';
}
