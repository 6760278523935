import { Directive, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { AppState } from '@app/core';
import { TabsState } from '@app/core/models/ui/ui-model';
import { ActionUIUpdateTabsState } from '@app/core/ui/ui.actions';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

@Directive({
  selector: '[portalTabState]',
})
export class AppTabStateDirective implements OnInit, OnChanges {
  @Input() private appTabState: TabsState;
  @Input() private tabGroupId: string;
  private appTabStateCopy: TabsState;

  constructor(private store: Store<AppState>) {}

  @HostListener('selectedIndexChange', ['$event']) private onSelectedIndexChange(index: number): void {
    // Need to create a copy here to avoid "read only property" errors
    const copyOfAppTabStateCopy = cloneDeep(this.appTabStateCopy);
    copyOfAppTabStateCopy.tabs[this.tabGroupId] = index;
    this.appTabStateCopy = copyOfAppTabStateCopy;
    this.store.dispatch(new ActionUIUpdateTabsState(this.appTabStateCopy));
  }

  public ngOnInit(): void {
    const tabsData = this.appTabStateCopy?.tabs;
    if (!tabsData) {
      return;
    }
    const selectedTab = tabsData[this.tabGroupId];
    if (selectedTab !== undefined) {
      return;
    }
    // If no tabs state exists, we must add it:
    this.appTabStateCopy.tabs[this.tabGroupId] = 0;
    this.store.dispatch(new ActionUIUpdateTabsState(this.appTabStateCopy));
  }

  public ngOnChanges(): void {
    this.appTabStateCopy = cloneDeep(this.appTabState);
  }
}
