import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Inject, Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl, ValidationErrors } from '@angular/forms';
import {
  AutoCreateStatus,
  Connector,
  Issuer,
  OIDCUpstreamIdentityProvider,
  LocalAuthUpstreamIdentityProvider,
  ConnectorsService,
  ApplicationUpstreamIdentityProvider,
  ApplicationUpstreamValidation,
  ApplicationUpstreamFormInfo,
  Application,
  Organisation,
} from '@agilicus/angular';
import { NotificationService, selectIssuerState } from '@app/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/core';
import { Subject } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  capitalizeFirstLetter,
  getDefaultLocalAuthUpstreamIssuer,
  getDuplicateChipValues,
  getDuplicateInputValues,
  getExistingChipValuesSet,
  getValuesFromInputEventValue,
  handleDuplicateChipValues,
  handleDuplicateInputValues,
} from '../utils';
import { UpstreamProviderOption } from './upstream-provider-option.enum';
import { enableConnectorLocalAuthIfNotEnabled } from '@app/core/api/connectors/connectors-api-utils';
import { getApplicationUpstreamIssuerUrl } from '@app/core/models/application/application-model-api-utils';
import { FilterChipOptions } from '../filter-chip-options';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { isANumber, isValidUpstreamDomainName } from '../validation-utils';
import { Router } from '@angular/router';
import { ComponentName } from '../component-type.enum';
import { savingIssuer } from '@app/core/issuer-state/issuer.actions';
import { getUpstreamDomainNameTooltipText } from '@app/core/issuer-state/issuer.utils';

export interface SharedUpstreamProviderProperties {
  name?: string;
  issuer?: string;
  upstream_type?: ApplicationUpstreamIdentityProvider.UpstreamTypeEnum;
  icon?: string;
  auto_create_status?: AutoCreateStatus;
}

export interface UniqueUpstreamProviderProperties {
  client_id?: string;
  client_secret?: string;
  issuer_external_host?: string;
  username_key?: string;
  email_key?: string;
  email_verification_required?: boolean;
  request_user_info?: boolean;
  user_id_key?: string;
  upstream_id?: string;
  validation?: ApplicationUpstreamValidation;
  form_info?: ApplicationUpstreamFormInfo;
  oidc_flavor?: OIDCUpstreamIdentityProvider.OidcFlavorEnum;
  upstream_domain_name?: string;
}

export interface UpstreamProviderDialogData {
  issuer: Issuer;
  store: Store<AppState>;
  sharedUpstreamProviderData: SharedUpstreamProviderProperties;
  uniqueUpstreamProviderData: UniqueUpstreamProviderProperties;
  connectors: Array<Connector>;
  applications: Array<Application>;
  currentOrg: Organisation;
  componentName: ComponentName;
}

enum IssuerSaveState {
  SAVING = 'saving',
  SUCCESS = 'success',
  FAILED = 'failed',
}

@Component({
  selector: 'portal-upstream-provider-setup-dialog',
  templateUrl: './upstream-provider-setup-dialog.component.html',
  styleUrls: ['./upstream-provider-setup-dialog.component.scss', '../../shared.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }, // display errors in the steps
    },
  ],
})
export class UpstreamProviderSetupDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  public allForms: UntypedFormGroup;
  public providerForm: UntypedFormGroup;
  public azureAppForm: UntypedFormGroup;
  public providerDetailsForm: UntypedFormGroup;
  public clientSecretForm: UntypedFormGroup;
  public providerOptions = [
    UpstreamProviderOption.azure,
    UpstreamProviderOption.connector,
    UpstreamProviderOption.application,
    UpstreamProviderOption.other,
  ];
  public addDisabled = false;
  private baseAzureIssuerURL = 'https://login.microsoftonline.com/';
  public connectorNameToConnectorMap: Map<string, Connector> = new Map();

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public autoCreateStatus = AutoCreateStatus;
  public upstreamProviderOption = UpstreamProviderOption;
  public getUpstreamDomainNameTooltipText = getUpstreamDomainNameTooltipText;

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };
  public newChipAdded = false;
  public advanceToDoneStep = false;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  @ViewChild('stepper') public stepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<UpstreamProviderSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpstreamProviderDialogData,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private connectorsService: ConnectorsService,
    private customValidatorsService: CustomValidatorsService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {
    if (this.data.componentName === ComponentName.custom_identity) {
      this.providerOptions = [UpstreamProviderOption.azure, UpstreamProviderOption.other];
    }
    if (this.data.componentName === ComponentName.onsite_identity) {
      this.providerOptions = [UpstreamProviderOption.connector];
    }
    if (this.data.componentName === ComponentName.application_identity) {
      this.providerOptions = [UpstreamProviderOption.application];
    }
  }

  public ngOnInit(): void {
    this.setMaps();
    this.initializeForms();
    this.allForms = this.formBuilder.group({
      providerForm: this.providerForm,
      azureAppForm: this.azureAppForm,
      providerDetailsForm: this.providerDetailsForm,
      clientSecretForm: this.clientSecretForm,
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.changeDetector.detach();
  }

  private initializeForms(): void {
    this.initializeProviderForm();
    this.initializeAzureAppForm();
    this.initializeProviderDetailsForm();
    this.initializeClientSecretForm();
  }

  private initializeProviderForm(): void {
    this.providerForm = this.formBuilder.group({
      provider: ['', Validators.required],
    });
    if (this.data.componentName === ComponentName.onsite_identity) {
      this.providerForm.patchValue({
        provider: UpstreamProviderOption.connector,
      });
    }
    if (this.data.componentName === ComponentName.application_identity) {
      this.providerForm.patchValue({
        provider: UpstreamProviderOption.application,
      });
    }
  }

  private initializeAzureAppForm(): void {
    this.azureAppForm = this.formBuilder.group({
      name: [this.data.sharedUpstreamProviderData.name, [Validators.required, Validators.maxLength(100)]],
      clientId: [this.data.uniqueUpstreamProviderData.client_id, [Validators.required, Validators.maxLength(100)]],
      tenantId: ['', [Validators.required, Validators.maxLength(100)], [this.checkValidTenantId.bind(this)]],
    });
  }

  private initializeProviderDetailsForm(): void {
    const connectorNameValidators = [];
    if (this.getProviderOptionFromForm() === UpstreamProviderOption.connector) {
      connectorNameValidators.push(Validators.required);
    }
    const applicationNameValidators = [];
    const pathValidators = [];
    const usernameFieldValidators = [];
    const passwordFieldValidators = [];
    const successfulResponseCodeValidators = [
      this.customValidatorsService.customValidator(isANumber),
      Validators.min(100),
      Validators.max(600),
    ];
    if (this.getProviderOptionFromForm() === UpstreamProviderOption.application) {
      applicationNameValidators.push(Validators.required);
      pathValidators.push(Validators.required);
      usernameFieldValidators.push(Validators.required);
      passwordFieldValidators.push(Validators.required);
      successfulResponseCodeValidators.push(Validators.required);
    }
    this.providerDetailsForm = this.formBuilder.group({
      name: [this.data.sharedUpstreamProviderData.name, [Validators.required, Validators.maxLength(100)]],
      icon: [this.data.sharedUpstreamProviderData.icon, [Validators.maxLength(100)]],
      connector_name: ['', connectorNameValidators],
      auto_create_status: [this.data.sharedUpstreamProviderData.auto_create_status, [Validators.required]],
      application_name: ['', applicationNameValidators],
      path: ['', pathValidators],
      username_field: ['', usernameFieldValidators],
      password_field: ['', passwordFieldValidators],
      successful_response_code: [null, successfulResponseCodeValidators],
      expected_cookies: '',
      upstream_domain_name: ['', this.customValidatorsService.customValidator(isValidUpstreamDomainName)],
    });
  }

  private initializeClientSecretForm(): void {
    this.clientSecretForm = this.formBuilder.group({
      clientSecret: [this.data.uniqueUpstreamProviderData.client_secret, [Validators.maxLength(255)]],
    });
  }

  private setConnectorNameToConnectorMap(): void {
    this.connectorNameToConnectorMap.clear();
    for (const connector of this.data.connectors) {
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  private setMaps(): void {
    this.setConnectorNameToConnectorMap();
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

  public isDuplicateName(): boolean {
    const upstreams = this.data.issuer.oidc_upstreams;
    const curName = this.azureAppForm.value.name;
    for (const upstream of upstreams) {
      if (upstream.name === curName) {
        this.azureAppForm.get('name').setValue('');
        this.notificationService.error(curName + ' already exists. Please choose a different name.');
        return true;
      }
    }
    return false;
  }

  public async checkValidTenantId(control: AbstractControl): Promise<ValidationErrors | null> {
    const invalid = { invalidTenantID: { value: control.value } };
    try {
      const response = await fetch(this.baseAzureIssuerURL + control.value + '/v2.0/.well-known/openid-configuration');
      if (response.status !== 200) {
        return invalid;
      }
      const jsonResp = await response.json();
      if (jsonResp.issuer !== this.baseAzureIssuerURL + control.value + '/v2.0') {
        return invalid;
      }
    } catch (e) {
      return invalid;
    }
    return null;
  }

  public onAddClick(): void {
    this.dialogRef.close(true);
  }

  private setOidcUpstreamFromForm(): void {
    this.data.sharedUpstreamProviderData.name = this.azureAppForm.value.name;
    this.data.sharedUpstreamProviderData.issuer = this.baseAzureIssuerURL + this.azureAppForm.value.tenantId + '/v2.0';
    this.data.sharedUpstreamProviderData.icon = 'microsoft';
    this.data.uniqueUpstreamProviderData.client_id = this.azureAppForm.value.clientId;
    this.data.uniqueUpstreamProviderData.client_secret = this.clientSecretForm.value.clientSecret;
    this.data.uniqueUpstreamProviderData.email_verification_required = true;
    this.data.uniqueUpstreamProviderData.oidc_flavor = OIDCUpstreamIdentityProvider.OidcFlavorEnum.microsoft;
  }

  private setSharedUpstreamProviderPropertiesFromForm(): void {
    this.data.sharedUpstreamProviderData.name = this.providerDetailsForm.value.name;
    this.data.sharedUpstreamProviderData.icon = this.providerDetailsForm.value.icon;
    this.data.sharedUpstreamProviderData.auto_create_status = this.providerDetailsForm.value.auto_create_status;
  }

  private setUniqueUpstreamProviderPropertiesFromForm(): void {
    this.data.uniqueUpstreamProviderData.validation.successful_response_code = parseInt(
      this.providerDetailsForm.value.successful_response_code,
      10
    );
    this.data.uniqueUpstreamProviderData.form_info.username_field = this.providerDetailsForm.value.username_field;
    this.data.uniqueUpstreamProviderData.form_info.password_field = this.providerDetailsForm.value.password_field;
    this.data.uniqueUpstreamProviderData.upstream_domain_name = this.providerDetailsForm.value.upstream_domain_name;
  }

  private getSavingUpstreamMessage(): string {
    let message = 'Saving upstream';
    if (this.azureAppForm.valid && this.providerDetailsForm.valid) {
      message += `s "${this.data.sharedUpstreamProviderData.name}" and "${this.data.sharedUpstreamProviderData.name}"`;
      return message;
    }
    if (this.azureAppForm.valid) {
      message += ` "${this.data.sharedUpstreamProviderData.name}"`;
      return message;
    }
    if (this.providerDetailsForm.valid) {
      message += ` "${this.data.sharedUpstreamProviderData.name}"`;
    }
    return message;
  }

  private getOidcUpstreamIdentityProviderFromData(): OIDCUpstreamIdentityProvider {
    return {
      name: this.data.sharedUpstreamProviderData.name,
      issuer: this.data.sharedUpstreamProviderData.issuer,
      icon: this.data.sharedUpstreamProviderData.icon,
      client_id: this.data.uniqueUpstreamProviderData.client_id,
      client_secret: this.data.uniqueUpstreamProviderData.client_secret,
      auto_create_status: this.data.sharedUpstreamProviderData.auto_create_status,
      issuer_external_host: this.data.uniqueUpstreamProviderData.issuer_external_host,
      username_key: this.data.uniqueUpstreamProviderData.username_key,
      email_key: this.data.uniqueUpstreamProviderData.email_key,
      user_id_key: this.data.uniqueUpstreamProviderData.user_id_key,
      email_verification_required: this.data.uniqueUpstreamProviderData.email_verification_required,
      request_user_info: this.data.uniqueUpstreamProviderData.request_user_info,
      oidc_flavor: this.data.uniqueUpstreamProviderData.oidc_flavor,
    };
  }

  private getLocalAuthUpstreamIdentityProviderFromData(): LocalAuthUpstreamIdentityProvider {
    return {
      name: this.data.sharedUpstreamProviderData.name,
      issuer: getDefaultLocalAuthUpstreamIssuer(),
      upstream_type: LocalAuthUpstreamIdentityProvider.UpstreamTypeEnum.local_auth,
      icon: this.data.sharedUpstreamProviderData.icon,
      auto_create_status: this.data.sharedUpstreamProviderData.auto_create_status,
      upstream_id: this.data.uniqueUpstreamProviderData.upstream_id,
      upstream_domain_name: this.data.uniqueUpstreamProviderData.upstream_domain_name,
    };
  }

  private getApplicationUpstreamIdentityProviderIssuer(): string {
    const appName = this.providerDetailsForm.get('application_name').value;
    const path = this.providerDetailsForm.get('path').value;
    return getApplicationUpstreamIssuerUrl(appName, path, this.data.currentOrg.subdomain);
  }

  private getApplicationUpstreamIdentityProviderFromData(): ApplicationUpstreamIdentityProvider {
    return {
      name: this.data.sharedUpstreamProviderData.name,
      issuer: this.getApplicationUpstreamIdentityProviderIssuer(),
      upstream_type: LocalAuthUpstreamIdentityProvider.UpstreamTypeEnum.application,
      icon: this.data.sharedUpstreamProviderData.icon,
      auto_create_status: this.data.sharedUpstreamProviderData.auto_create_status,
      validation: {
        successful_response_code: this.data.uniqueUpstreamProviderData.validation.successful_response_code,
        expected_cookies: this.data.uniqueUpstreamProviderData.validation.expected_cookies,
      },
      form_info: {
        username_field: this.data.uniqueUpstreamProviderData.form_info.username_field,
        password_field: this.data.uniqueUpstreamProviderData.form_info.password_field,
      },
    };
  }

  public saveIssuer(): void {
    const providerOption = this.getProviderOptionFromForm();
    // add new upstream
    const issuer = cloneDeep(this.data.issuer);
    this.setSharedUpstreamProviderPropertiesFromForm();
    this.setUniqueUpstreamProviderPropertiesFromForm();
    if (providerOption === UpstreamProviderOption.azure) {
      this.setOidcUpstreamFromForm();
      const oidcUpstreamIdentityProvider = this.getOidcUpstreamIdentityProviderFromData();
      issuer.oidc_upstreams.push(oidcUpstreamIdentityProvider);
    }
    if (providerOption === UpstreamProviderOption.connector) {
      const localAuthUpstreamIdentityProvider = this.getLocalAuthUpstreamIdentityProviderFromData();
      issuer.local_auth_upstreams.push(localAuthUpstreamIdentityProvider);
      const targetConnector = this.connectorNameToConnectorMap.get(this.providerDetailsForm.get('connector_name').value);
      enableConnectorLocalAuthIfNotEnabled(this.connectorsService, this.notificationService, targetConnector);
    }
    if (providerOption === UpstreamProviderOption.application) {
      const applicationUpstreamIdentityProvider = this.getApplicationUpstreamIdentityProviderFromData();
      issuer.application_upstreams.push(applicationUpstreamIdentityProvider);
    }
    // We want to trigger the refresh of the table when the dialog closes so the new provider will
    // appear in the table. Therefore, we set the trigger_update_side_effects flag to true here:
    this.data.store.dispatch(savingIssuer({ obj: issuer, trigger_update_side_effects: true, notifyUser: true }));
    this.disableForms();
    this.notificationService.default(this.getSavingUpstreamMessage());

    // check if save is successful
    this.checkSaveStatus();
  }

  private checkSaveStatus(): void {
    this.data.store
      .pipe(select(selectIssuerState))
      .pipe(
        takeUntil(this.unsubscribe$),
        map((state) => {
          if (state.saving_state) {
            return IssuerSaveState.SAVING;
          }
          if (!state.state_save_success) {
            // save was unsuccessful
            return IssuerSaveState.FAILED;
          }
          // save was successful
          return IssuerSaveState.SUCCESS;
        }),
        filter((action) => action !== IssuerSaveState.SAVING),
        take(1)
      )
      .subscribe((action) => {
        if (action === IssuerSaveState.FAILED) {
          // re-enable form to retry saving
          this.enableForms();
        } else {
          this.advanceToDoneStep = true;
          setTimeout(() => {
            this.stepper.next();
          }, 1000);
        }
      });
  }

  public disableForms(): void {
    this.addDisabled = true;
    this.allForms.disable();
    this.providerDetailsForm.disable();
  }

  public enableForms(): void {
    this.addDisabled = false;
    this.allForms.enable();
    this.providerDetailsForm.enable();
  }

  public updateConnector(connectorName: string): void {
    const connectorNameFormControl = this.providerDetailsForm.get('connector_name');
    connectorNameFormControl.setValue(connectorName);
    this.data.uniqueUpstreamProviderData.upstream_id = this.connectorNameToConnectorMap.get(connectorName).metadata.id;
  }

  public getProviderOptionFromForm(): UpstreamProviderOption {
    return this.providerForm.value.provider;
  }

  public getApplicationSelectionTooltip(): string {
    return `Select your previously configured application from the list below. If you would like to configure a new application, you can do so now using the "New" sub-menu under the "Applications" tab on the left. After configuring your application you can return to this step and select that application.`;
  }

  public removeChip(cookie: string, cookies: Array<string>): void {
    const index = cookies.indexOf(cookie);
    if (index >= 0) {
      cookies.splice(index, 1);
    }
    this.changeDetector.detectChanges();
  }

  /**
   * Adds a new chip to the chips input when the user enters a 'separatorKeysCode'.
   * @param event contains the values typed by the user
   */
  public addChipOnInputEvent(event: MatChipInputEvent): void {
    const input = event.input;
    if (input.value === '') {
      this.newChipAdded = false;
      return;
    }
    const valuesArray = getValuesFromInputEventValue(event.value);
    if (!valuesArray) {
      return;
    }
    const duplicateInputValues = getDuplicateInputValues(valuesArray);
    if (duplicateInputValues.length > 0) {
      handleDuplicateInputValues(duplicateInputValues, this.notificationService);
      return;
    }
    const existingChipValues = getExistingChipValuesSet(this.data.uniqueUpstreamProviderData.validation.expected_cookies);
    const duplicateChipValues = getDuplicateChipValues(valuesArray, existingChipValues);
    if (duplicateChipValues.length > 0) {
      handleDuplicateChipValues(duplicateChipValues, this.notificationService);
      return;
    }
    for (const item of valuesArray) {
      this.data.uniqueUpstreamProviderData.validation.expected_cookies.push(item);
    }
    this.newChipAdded = true;
    // Resets the input value so that the next chip to be entered starts as empty.
    if (input) {
      input.value = '';
    }
    this.changeDetector.detectChanges();
  }

  public onProviderSelection(): void {
    this.initializeProviderDetailsForm();
  }

  public onConfigureAliasesClick(): void {
    this.router.navigate(['application-authentication-clients'], {
      queryParamsHandling: 'merge',
    });
    this.dialogRef.close(false);
  }
}
