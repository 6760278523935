/*
 * Given an (optional) environment override file in assets/app-config.json,
 * merge it in to the global environment which is compiled in.
 */
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { EnvironmentState } from '../environment/environment.model';
import { ActionEnvironmentSetState } from '../environment/environment.actions';
import { getPortalVersionFromCookie } from '@app/shared/components/utils';
import { CookieService } from 'ngx-cookie-service';
import { PortalVersion } from '@app/shared/components/portal-version.enum';

@Injectable({
  providedIn: 'root',
})
export class DynamicEnvironmentService {
  public override: any;

  public constructor(private store: Store<AppState>, private cookieService: CookieService) {}

  public init(): Promise<any> {
    // initialise to an empty-object, only override on
    // successful fetch.
    this.override = {};

    return from(
      fetch(this.getConfigPath())
        .then((response) => {
          const override = response.json();
          return override;
        })
        .catch((error) => {
          // if we fail to fetch, return the global default state
          return env;
        })
    )
      .pipe(
        map((config) => {
          this.override = config;
          this.store.dispatch(new ActionEnvironmentSetState(this.environment));
          return config;
        })
      )
      .toPromise()
      .catch(); // This is an override for development, don't log
  }

  public get environment(): EnvironmentState {
    return { ...env, ...this.override };
  }

  public getPortalVersion(): PortalVersion {
    const cookie = this.cookieService.get('ver');
    return getPortalVersionFromCookie(cookie);
  }

  public getPortalGate(): PortalVersion {
    const fromEnv = this.environment.gate;
    if (!!fromEnv) {
      return fromEnv;
    }

    return this.getPortalVersion();
  }

  public getConfigPath(): string {
    return `assets/app-config.json?ver=${this.getPortalVersion()}`;
  }
}
