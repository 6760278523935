import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as InboxItemActions from './inbox-item.actions';
import { InboxItem } from '@agilicus/angular';
import { BasicCollectionState } from '@app/core/basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';

export const inboxItemFeatureKey = 'inboxItem';

export interface InboxItemState extends BasicCollectionState<InboxItem> {
  unreadInboxItemCount: number;
}

export const adapter: EntityAdapter<InboxItem> = createEntityAdapter<InboxItem>({
  sortComparer: sortListByCreatedDate,
  selectId: (inboxItem) => inboxItem.metadata.id,
});

export const initialState: InboxItemState = adapter.getInitialState({
  ...getDefaultBasicCollectionState<InboxItem>(),
  // additional entity state properties
  unreadInboxItemCount: 0,
});

export const inboxItemReducer = createReducer(
  initialState,
  on(InboxItemActions.addInboxItem, (state, action) => adapter.addOne(action.obj, state)),
  on(InboxItemActions.upsertInboxItem, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(InboxItemActions.addInboxItems, (state, action) => adapter.addMany(action.objs, state)),
  on(InboxItemActions.upsertInboxItems, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(InboxItemActions.updateInboxItem, (state, action) => adapter.updateOne(action.obj, state)),
  on(InboxItemActions.updateInboxItems, (state, action) => adapter.updateMany(action.objs, state)),
  on(InboxItemActions.deleteInboxItem, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(InboxItemActions.deleteInboxItems, (state, action) =>
    adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })
  ),
  on(InboxItemActions.loadInboxItems, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(InboxItemActions.clearInboxItems, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(InboxItemActions.savingInboxItem, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(InboxItemActions.savingInboxItems, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(InboxItemActions.initInboxItems, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(InboxItemActions.refreshInboxItems, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  }),
  // special case reducers:
  on(InboxItemActions.setUnreadInboxItemCount, (state, action) => {
    return { ...state, unreadInboxItemCount: action.unreadInboxItemCount };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
