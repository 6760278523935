import validator from 'validator';
import * as EmailValidator from 'email-validator';
import { Validator } from 'ip-num/Validator';
import { RuleConfig, RuleV2, SimpleResourcePolicyTemplateStructureNode, User } from '@agilicus/angular';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationService } from '@app/core';
import { PolicyResult } from './utils-models';
import * as cspParser from 'content-security-policy-parser';
import { CspDirectiveEnum } from './csp-directive.enum';
import { createEnumChecker, getDefaultReportURI, getValuesArray } from './utils';
import { MatLegacyChipInputEvent } from '@angular/material/legacy-chips';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { PolicyTemplateInstanceResource } from '@app/core/api/policy-template-instance/policy-template-instance-utils';

export interface FqdnAliasValidationResponse {
  isValid: boolean;
  message: string;
}

export function isValidHostname(str: string | undefined | null): boolean {
  const hostnamePattern = '^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$';
  if (str === undefined || str === null) {
    return false;
  }
  if (str.match(hostnamePattern) === null) {
    return false;
  }
  return true;
}

export function getFormattedSubdomainValue(value: string | undefined): string | undefined {
  let formattedValue = !!value ? value.toLowerCase() : undefined;
  if (!formattedValue) {
    return undefined;
  }
  const indexCheckOne = formattedValue.indexOf('*');
  if (indexCheckOne === 0) {
    formattedValue = formattedValue.slice(1);
  }
  const indexCheckTwo = formattedValue.indexOf('.');
  if (indexCheckTwo === 0) {
    formattedValue = formattedValue.slice(1);
  }
  return formattedValue;
}

export function isValidSubdomainHostname(str: string): boolean {
  const formattedValue = getFormattedSubdomainValue(str);
  return isValidHostname(formattedValue);
}

export function isANumber(value: string | number): boolean {
  return validator.isNumeric(value.toString());
}

export function isValidPort(port: string | number): boolean {
  const portAsString = port.toString();
  if (
    portAsString.indexOf('.') === -1 &&
    portAsString.indexOf('-') === -1 &&
    isANumber(portAsString) &&
    parseInt(portAsString, 10) <= 65535 &&
    parseInt(portAsString, 10) >= 0
  ) {
    return true;
  }
  return false;
}

export function isValidPortRange(portRange: string): boolean {
  const portRangePattern = /^[0-9]+$|^[0-9]+-[0-9]+$/;
  if (portRange === undefined || portRange === null) {
    return false;
  }
  if (portRange.match(portRangePattern) === null) {
    return false;
  }
  const portRangesArray = portRange.split('-');
  if (portRangesArray.length === 2 && parseInt(portRangesArray[0], 10) > parseInt(portRangesArray[1], 10)) {
    return false;
  }
  for (const port of portRangesArray) {
    if (!isValidPort(port)) {
      return false;
    }
  }
  return true;
}

export function isValidPortRangeList(portRangeList: string): boolean {
  const portRangesStringArray = portRangeList.split(',').map((str) => str.trim());
  for (const portRange of portRangesStringArray) {
    if (!isValidPortRange(portRange)) {
      return false;
    }
  }
  return true;
}

export function isValidIp4(ip: string): boolean {
  const result = Validator.isValidIPv4String(ip);
  return result[0];
}

export function isValidIp6(ip: string): boolean {
  const result = Validator.isValidIPv6String(ip);
  return result[0];
}

export function isValidIp4OrIp6(ip: string): boolean {
  return isValidIp4(ip) || isValidIp6(ip);
}

export function isValidHostnameOrIp4(value: string): boolean {
  if (!isValidHostname(value) && !isValidIp4(value)) {
    return false;
  }
  return true;
}

export function isValidHostnameOrIp4OrIp6(value: string): boolean {
  if (!isValidHostname(value) && !isValidIp4OrIp6(value)) {
    return false;
  }
  return true;
}

export function isValidSubnet(subnet: string): boolean {
  const result = Validator.isValidIPv4CidrNotation(subnet);
  return result[0];
}

export function isValidPath(str: string): boolean {
  const pathPattern = '^([/][a-zA-Z0-9-:.-_]+)+[^/]$';
  if (str === undefined || str === null) {
    return false;
  }
  if (str.match(pathPattern) === null) {
    return false;
  }
  return true;
}

export function checkIfUserEmailFieldValid(field: string): boolean {
  return field.trim().length < 101;
}

export function checkIfValidUserEmail(user: User): boolean {
  return user.email.length > 0 && checkIfUserEmailFieldValid(user.email);
}

export function isFormValid(formField: string, formGroup: UntypedFormGroup): boolean {
  if (formGroup.controls[formField].invalid) {
    return false;
  }
  if (formGroup.invalid) {
    return false;
  }
  return true;
}

export function isValidCsp(csp: string, notificationService: NotificationService): boolean {
  if (csp.length === 0) {
    return true;
  }
  const parsedCsp: PolicyResult = cspParser(csp);
  if (!parsedCsp) {
    notificationService.error('Failed to parse the content security policy.');
    return false;
  }
  for (const directiveName of Object.keys(parsedCsp)) {
    if (directiveName === CspDirectiveEnum.report_uri && parsedCsp[directiveName][0] !== getDefaultReportURI()) {
      notificationService.error(
        'Failed to update the content security policy. The redirect uri cannot be changed. Please set to "/.well-known/csp-violation-report-endpoint/"'
      );
      return false;
    }
    const isCspDirectiveEnum = createEnumChecker(CspDirectiveEnum);
    if (!isCspDirectiveEnum(directiveName)) {
      notificationService.error('Failed to update the content security policy. "' + directiveName + '" is not a valid option.');
      return false;
    }
  }
  return true;
}

export function handleInvalidFqdnAliases(invalidFqdnAliases: Array<string>, notificationService: NotificationService): void {
  let message = invalidFqdnAliases.join(', ');
  if (invalidFqdnAliases.length === 1) {
    message += ' is not a valid domain name';
  } else {
    message += ' are not valid domain names';
  }
  notificationService.error(message);
}

export function areAllFqdnAliasesValid(valuesArray: Array<string>, notificationService: NotificationService): boolean {
  const invalidFqdnAliases = [];
  for (const value of valuesArray) {
    if (!isValidHostname(value)) {
      invalidFqdnAliases.push(value);
    }
  }
  if (invalidFqdnAliases.length !== 0) {
    handleInvalidFqdnAliases(invalidFqdnAliases, notificationService);
    return false;
  }
  return true;
}

export async function validateFqdnAliasValues(
  event: MatLegacyChipInputEvent,
  fqdnAliasList: Array<string>,
  notificationService: NotificationService,
  customValidatorsService: CustomValidatorsService
): Promise<FqdnAliasValidationResponse> {
  const valuesArray = getValuesArray(event, fqdnAliasList, notificationService);
  if (!valuesArray) {
    return {
      isValid: false,
      message: '',
    };
  }
  if (!areAllFqdnAliasesValid(valuesArray, notificationService)) {
    return {
      isValid: false,
      message: '',
    };
  }
  const isValidCnameArray: Array<{ value: string; isValid: boolean }> = [];
  for (const val of valuesArray) {
    const resultOfCnameCheck: boolean = await customValidatorsService.isValidCname(val, true);
    isValidCnameArray.push({ value: val, isValid: resultOfCnameCheck });
  }
  const invalidList = [];
  for (const resultOfCheck of isValidCnameArray) {
    if (!resultOfCheck.isValid) {
      invalidList.push(resultOfCheck.value);
    }
  }
  if (invalidList.length !== 0) {
    return {
      isValid: false,
      message: `Unable to resolve CNAME(s) ${invalidList.map((val) => `"${val}"`).join(',')}. Please enter a valid CNAME and try again.`,
    };
  }
  return {
    isValid: true,
    message: '',
  };
}

export function getResourceNamePattern(): string {
  return '^[a-zA-Z0-9-_.:]+$';
}

export function isValidResourceName(str: string): boolean {
  if (str === undefined || str === null) {
    return false;
  }
  if (str.match(getResourceNamePattern()) === null) {
    return false;
  }
  return true;
}

export function getRoleNamePattern(): string {
  return '^[A-Za-z0-9-]+$';
}

export function isValidRoleName(str: string): boolean {
  if (str === undefined || str === null) {
    return false;
  }
  if (str.match(getRoleNamePattern()) === null) {
    return false;
  }
  return true;
}

export function isValidIconValue(str: string): boolean {
  const regex = /^[0-9a-zA-Z-_.]+$/g;
  return str.length < 51 && regex.test(str);
}

export function isVncPasswordValid(password: string): boolean {
  return password.length <= 256;
}

export function isValidQualifiedDomain(str: string): boolean {
  const targetIndex = str.indexOf('.');
  if (targetIndex === 0 || targetIndex === str.length - 1 || targetIndex === -1) {
    return false;
  }
  return isValidHostname(str);
}

export function isValidUpstreamDomainName(str: string): boolean {
  if (str.length > 253) {
    return false;
  }
  return isValidQualifiedDomain(str);
}

export function isValidFQDNSingleLabel(str: string): boolean {
  if (stringContainsUppercase(str)) {
    return false;
  }
  if (str.includes('.') || str.includes('_')) {
    return false;
  }
  // First and/or last character cannot be "-":
  if (str.indexOf('-') === 0 || str.indexOf('-') === str.length - 1) {
    return false;
  }
  return isValidResourceName(str);
}

export function stringContainsUppercase(str: string): boolean {
  return /[A-Z]/.test(str);
}

export function getLauncherNamePattern(): string {
  return '^[a-zA-Z0-9-_ ]+$';
}

export function isValidLauncherName(str: string): boolean {
  if (str === undefined || str === null) {
    return false;
  }
  if (str.match(getLauncherNamePattern()) === null) {
    return false;
  }
  return true;
}

export function doesStringStartWithSlash(str: string): boolean {
  if (str.length === 0 || str[0] !== '/') {
    return false;
  }
  return true;
}

export function isValidCommonPathPrefix(str: string): boolean {
  return doesStringStartWithSlash(str);
}

export function getLabelNamePattern(): string {
  return '^[a-zA-Z0-9:.-]+$';
}

export function isValidLabelName(str: string): boolean {
  if (str === undefined || str === null) {
    return false;
  }
  if (str.match(getLabelNamePattern()) === null) {
    return false;
  }
  return true;
}

export function getSshCredentialPrivateKeyMaxLength(): number {
  return 65536;
}

export function getSshCredentialPrivateKeyPassphraseMaxLength(): number {
  return 1024;
}

export function getSshCredentialPasswordMaxLength(): number {
  return 1024;
}

export function isSshCredentialPrivateKeyValid(password: string): boolean {
  return password.length <= getSshCredentialPrivateKeyMaxLength();
}

export function isSshCredentialPrivateKeyPassphraseValid(password: string): boolean {
  return password.length <= getSshCredentialPrivateKeyPassphraseMaxLength();
}

export function isSshCredentialPasswordValid(password: string): boolean {
  return password.length <= getSshCredentialPasswordMaxLength();
}

export function isWithinValidPriorityRange(priority: number): boolean {
  return !isNaN(priority) && priority >= -65535 && priority <= 65535;
}

export function getPriorityNotWithinRangeErrorMessage(): string {
  return `Priority must be between -65535 and 65535`;
}

export function getPolicyRulePriorityErrorMessage(
  priorityValue: string,
  rule: RuleV2 | RuleConfig,
  policyTemplateInstanceResource: PolicyTemplateInstanceResource
): string {
  const priorityValueAsNumber = parseInt(priorityValue, 10);
  if (!isWithinValidPriorityRange(priorityValueAsNumber)) {
    return getPriorityNotWithinRangeErrorMessage();
  }
  if (!isUniquePolicyRulePriority(priorityValue, rule, policyTemplateInstanceResource)) {
    return `Priority of nested rules must be unique`;
  }
  return ``;
}

/**
 * Will find the siblings of a "child" rule within a parent node
 * and check that the priorities of those children are unique
 */
export function isUniquePolicyRulePriority(
  priorityValue: string,
  rule: RuleV2 | RuleConfig,
  policyTemplateInstanceResource: PolicyTemplateInstanceResource
): boolean {
  const ruleAsRuleConfig = rule as RuleConfig;
  const targetRuleName = ruleAsRuleConfig.name;
  let targetChildRuleNodes: Array<SimpleResourcePolicyTemplateStructureNode> | undefined = undefined;
  for (const policyStructure of policyTemplateInstanceResource.spec.template.policy_structure) {
    if (policyStructure.name === targetRuleName) {
      // Is not a nested rule, so priority is not unique
      return true;
    }
    for (const nestedRule of policyStructure.root_node.children) {
      if (nestedRule.rule_name === targetRuleName) {
        // Found the nested rule
        targetChildRuleNodes = [...policyStructure.root_node.children];
        break;
      }
    }
    if (!!targetChildRuleNodes) {
      break;
    }
  }
  if (!!targetChildRuleNodes) {
    for (const childRuleNode of targetChildRuleNodes) {
      if (childRuleNode.rule_name !== targetRuleName && childRuleNode.priority.toString() === priorityValue.toString()) {
        return false;
      }
    }
  }
  return true;
}

export function isValidPolicyRulePriority(
  priorityValue: string,
  rule: RuleV2 | RuleConfig,
  policyTemplateInstanceResource: PolicyTemplateInstanceResource
): boolean {
  const priorityErrorMessage = getPolicyRulePriorityErrorMessage(priorityValue, rule, policyTemplateInstanceResource);
  if (!!priorityErrorMessage) {
    return false;
  }
  return true;
}

export function doesStringHaveSpace(str: string): boolean {
  const trimmedStr = str.trim();
  return trimmedStr.includes(' ');
}

export function isValidPolicyName(str: string): boolean {
  const policyNamePattern = '^[a-zA-Z0-9-_.]+$';
  if (str === undefined || str === null) {
    return false;
  }
  const trimmedStr = str.trim();
  if (trimmedStr.match(policyNamePattern) === null) {
    return false;
  }
  if (trimmedStr.length > 32) {
    return false;
  }
  return true;
}
