import { Injectable } from '@angular/core';
import { ApplicationsService, FilesService, ApplicationEnvironmentConfig } from '@agilicus/angular';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentConfigService {
  private envConfig: ApplicationEnvironmentConfig;

  constructor(files: FilesService, applications: ApplicationsService) {
    this.envConfig = new ApplicationEnvironmentConfig(files, applications);
  }

  public getEnvConfig(): ApplicationEnvironmentConfig {
    return this.envConfig;
  }
}
