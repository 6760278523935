export function getPageSizeOptions(length: number, paginatorPageSize?: number): number[] {
  // make the max page size option equal to the size of the data
  if (length > 100) {
    if (!!paginatorPageSize && paginatorPageSize < 25) {
      return [paginatorPageSize, 25, 50, length];
    }
    return [25, 50, length];
  } else {
    if (!!paginatorPageSize && paginatorPageSize < 25) {
      return [paginatorPageSize, 25, 50, 100];
    }
    return [25, 50, 100];
  }
}
