import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AppState } from '@app/core';
import { GettingStartedTask } from '@app/core/user/preferences/getting-started-tasks.enum';
import { GettingStartedData } from '@app/core/user/preferences/getting-started-data';
import {
  getTaskCompletedStatus,
  getTaskCompleteToggleText,
  toggleGettingStartedTask,
} from '@app/core/user/preferences/user-preference-utils';
import { selectApiOrgId, selectGettingStartedData } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'portal-setup-payment-guide',
  templateUrl: './setup-payment-guide.component.html',
  styleUrls: ['./setup-payment-guide.component.scss', '../getting-started-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupPaymentGuideComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public gettingStartedDataCopy: GettingStartedData;
  public pageDescriptiveText = `You can:`;
  public pageSubDescriptiveList = [
    `See the metering of resources and setup payment`,
    `Choose to be automatically charged (via Credit or Debit), or to have invoices emailed to you for offline payment`,
    `View previous invoices`,
    `Cancel any time`,
  ];
  public productGuideLink = `https://www.agilicus.com/anyx-guide/billing/`;
  public isTaskComplete = false;
  public targetTask = GettingStartedTask.completed_setup_payment;

  public getTaskCompletedStatus = getTaskCompletedStatus;
  public getTaskCompleteToggleText = getTaskCompleteToggleText;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    combineLatest([orgId$, gettingStartedData$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, gettingStartedDataResp]) => {
        this.orgId = orgIdResp;
        this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
        this.isTaskComplete = getTaskCompletedStatus(this.targetTask, this.gettingStartedDataCopy);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public onImageClick(): void {
    this.routeToBillingScreen();
  }

  private routeToBillingScreen(): void {
    this.router.navigate(['/billing'], {
      queryParams: { org_id: this.orgId },
    });
  }

  public onCompleteToggle(isChecked: boolean): void {
    toggleGettingStartedTask(isChecked, this.targetTask, this.gettingStartedDataCopy, this.store);
  }
}
