import { Label, LabelsService, PolicyTemplateInstance, PolicyTemplateInstanceSpec } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import {
  deletingPolicyTemplateInstances,
  initPolicyTemplateInstances,
} from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { selectPolicyTemplateInstanceList } from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { catchError, combineLatest, concatMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { ButtonType } from '../button-type.enum';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { FilterManager } from '../filter/filter-manager';
import { PolicyTemplateDialogComponent, PolicyTemplateDialogData } from '../policy-template-dialog/policy-template-dialog.component';
import {
  ActionMenuOptions,
  Column,
  createActionsColumn,
  createReadonlyColumn,
  createSelectRowColumn,
  ReadonlyColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { getDefaultDialogConfig } from '../dialog-utils';
import { getObjectLabelsList$ } from '@app/core/api/labels/labels-api-utils';
import { updateTableElements } from '../utils';
import { getDefaultTableProperties } from '../table-layout-utils';
import {
  getDetailedTemplateDefinitionBasedOnType,
  getTemplateTypeOptionValueText,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { selectCanAdminRules } from '@app/core/user/permissions/rules.selectors';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { PolicyTemplateType } from '@app/core/api/policy-template-instance/policy-template-type';

export interface PolicyTemplateInstanceElement extends TableElement, PolicyTemplateInstanceSpec {
  backingObject: PolicyTemplateInstance;
}

@Component({
  selector: 'portal-policy-templates',
  templateUrl: './policy-templates.component.html',
  styleUrls: ['./policy-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyTemplatesComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasRulesPermissions: boolean;
  private orgId: string;
  private labelsList: Array<Label> = [];
  public tableData: Array<PolicyTemplateInstanceElement> = [];
  public columnDefs: Map<string, Column<PolicyTemplateInstanceElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public hideFilter = false;
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton('ADD POLICY', ButtonColor.PRIMARY, 'Configure a new policy', 'Button that configures a new policy', () => {
      this.openPolicyTemplateDialog();
    }),
  ];
  public rowObjectName = 'POLICY';
  public warnOnNOperations = 1;
  public pageDescriptiveText = `Configure your organisation's policy, such as how often certain resources require multifactor authentication.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/authentication-rules/`;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog,
    private labelsService: LabelsService,
    private notificationService: NotificationService,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  public ngOnInit(): void {
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public showNoPermissionsText(): boolean {
    return this.hasRulesPermissions !== undefined && !this.hasRulesPermissions;
  }

  private getAllData$(): Observable<[OrgQualifiedPermission, Array<PolicyTemplateInstance>, Array<Label>]> {
    const hasRulesPermissions$ = this.store.pipe(select(selectCanAdminRules));
    const policyTemplateInstanceListState$ = this.store.pipe(select(selectPolicyTemplateInstanceList));
    return combineLatest([hasRulesPermissions$, policyTemplateInstanceListState$]).pipe(
      concatMap(([hasRulesPermissionsResp, policyTemplateInstanceListStateResp]) => {
        let labelsList$: Observable<Array<Label> | undefined> = of(undefined);
        if (!!hasRulesPermissionsResp?.orgId && hasRulesPermissionsResp?.hasPermission) {
          labelsList$ = getObjectLabelsList$(this.labelsService, hasRulesPermissionsResp?.orgId).pipe(
            catchError((_) => {
              this.notificationService.error('Failed to list labels');
              return of(undefined);
            })
          );
        }
        return combineLatest([of(hasRulesPermissionsResp), of(policyTemplateInstanceListStateResp), labelsList$]);
      })
    );
  }

  private getAndSetAllData(): void {
    this.getAllData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([hasRulesPermissionsResp, policyTemplateListStateResp, labelsListResp]) => {
        this.orgId = hasRulesPermissionsResp.orgId;
        this.hasRulesPermissions = hasRulesPermissionsResp.hasPermission;
        this.labelsList = labelsListResp;
        if (!this.hasRulesPermissions || !policyTemplateListStateResp || policyTemplateListStateResp.length === 0) {
          this.resetEmptyTable();
          return;
        }
        const filteredPolicyTemplateInstanceList = this.getFilteredPolicyTemplateInstanceList(policyTemplateListStateResp);
        this.updateTable(filteredPolicyTemplateInstanceList);
        this.changeDetector.detectChanges();
      });
  }

  private getFilteredPolicyTemplateInstanceList(policyTemplateInstanceList: Array<PolicyTemplateInstance>): Array<PolicyTemplateInstance> {
    return policyTemplateInstanceList.filter(
      (policyTemplateInstance) =>
        policyTemplateInstance.spec.template.template_type === PolicyTemplateType.mfa ||
        policyTemplateInstance.spec.template.template_type === PolicyTemplateType.source_info
    );
  }

  /**
   * Parent table column
   */
  private getTypeColumn(): ReadonlyColumn<PolicyTemplateInstanceElement> {
    const column = createReadonlyColumn('type');
    column.getDisplayValue = (element: PolicyTemplateInstanceElement) => {
      const templateType = element?.template?.template_type ? element.template.template_type : '';
      return getTemplateTypeOptionValueText(templateType as PolicyTemplateType);
    };
    column.isReadOnly = () => true;
    return column;
  }

  /**
   * Parent table column
   */
  private getNameColumn(): ReadonlyColumn<PolicyTemplateInstanceElement> {
    const column = createReadonlyColumn('name');
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return `The unique name provided by the user when the policy was created`;
    };
    return column;
  }

  /**
   * Parent table column
   */
  private getTemplateDefinitionColumn(): ReadonlyColumn<PolicyTemplateInstanceElement> {
    const column = createReadonlyColumn('definition');
    column.isReadOnly = () => true;
    column.getDisplayValue = (element: PolicyTemplateInstanceElement) => {
      return getDetailedTemplateDefinitionBasedOnType(element.backingObject);
    };
    column.getHeaderTooltip = () => {
      return `A description of what the policy is designed to achieve`;
    };
    return column;
  }

  /**
   * Parent table column
   */
  private getDescriptionColumn(): ReadonlyColumn<PolicyTemplateInstanceElement> {
    const column = createReadonlyColumn('description');
    column.displayName = 'User Description';
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return `A description of the policy provided by the user`;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<PolicyTemplateInstanceElement> {
    const column = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<PolicyTemplateInstanceElement>> = [
      {
        displayName: 'Configure Policy',
        icon: 'edit',
        tooltip: 'Click to configure this policy',
        onClick: (element: PolicyTemplateInstanceElement) => {
          this.openPolicyTemplateDialog(element);
        },
      },
    ];
    column.allowedValues = menuOptions;
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getTypeColumn(),
        this.getNameColumn(),
        this.getTemplateDefinitionColumn(),
        this.getDescriptionColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private updateTable(data: Array<PolicyTemplateInstance>): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  private buildData(data: Array<PolicyTemplateInstance>): void {
    const dataForTable: Array<PolicyTemplateInstanceElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(item: PolicyTemplateInstance, index: number): PolicyTemplateInstanceElement {
    const data: PolicyTemplateInstanceElement = {
      ...item.spec,
      backingObject: cloneDeep(item),
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  public getItemsFromTableElements(tableElements: Array<PolicyTemplateInstanceElement>): Array<PolicyTemplateInstance> {
    return tableElements.map((item) => item.backingObject);
  }

  public deleteSelected(itemElementsToDelete: Array<PolicyTemplateInstanceElement>): void {
    const itemsToDelete: Array<PolicyTemplateInstance> = this.getItemsFromTableElements(itemElementsToDelete);
    // Need to make a copy of the itemsToDelete or it will be converted to readonly.
    this.store.dispatch(
      deletingPolicyTemplateInstances({ objs: cloneDeep(itemsToDelete), trigger_update_side_effects: false, notifyUser: true })
    );
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public openPolicyTemplateDialog(element?: PolicyTemplateInstanceElement): void {
    const dialogData: PolicyTemplateDialogData = {
      labelsList: this.labelsList,
      orgId: this.orgId,
    };
    if (!!element) {
      dialogData.policyTemplateInstance = element.backingObject;
    }
    const dialogRef = this.dialog.open(
      PolicyTemplateDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
  }
}
