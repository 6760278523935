import { Launcher, LaunchersService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '..';
import {
  createNewLauncher,
  deleteExistingLauncher,
  getLauncherById,
  getLaunchersList,
  updateExistingLauncher,
} from '../api/launchers/launchers-api-utils';
import { CrudStateCollection } from '../api/state-driven-crud/crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from '../api/state-driven-crud/crud-registry-service.service';
import { CRUD, SimpleCRUDInterface } from '../api/state-driven-crud/state-driven-crud';

import * as LauncherActions from '../launcher-state/launcher.actions';

@Injectable({
  providedIn: 'root',
})
export class LauncherStateService extends SimpleCRUDInterface<Launcher, string> {
  public crudRegistryName = CrudStateCollection.launcher;

  constructor(
    public store: Store<AppState>,
    private launchersService: LaunchersService,
    private crudRegistryService: CrudRegistryService<Launcher, string>
  ) {
    super();
    if (!this.crudRegistryService.crudRegistry.get(this.crudRegistryName)) {
      let apiInterface: CRUD<Launcher, string> = {
        create: (id: string, obj: Launcher, orgId: string) => createNewLauncher(this.launchersService, obj),
        update: (id: string, obj: Launcher, orgId: string) => updateExistingLauncher(this.launchersService, obj),
        get: (id: string, orgId: string) => getLauncherById(this.launchersService, id, orgId),
        list: (orgId: string) => getLaunchersList(this.launchersService, orgId),
        delete: (id: string, orgId: string) => deleteExistingLauncher(this.launchersService, id, orgId),
      };
      const crudRegistry: CrudRegistry<Launcher, string> = {
        crudInterface: apiInterface,
        getNameFromObject: (obj: Launcher) => obj.spec.name,
        doneSaveAction: LauncherActions.upsertLauncher,
        doneSaveListAction: LauncherActions.upsertLaunchers,
        doneDeleteAction: LauncherActions.deleteLauncher,
        doneDeleteListAction: LauncherActions.deleteLaunchers,
        setListAction: LauncherActions.loadLaunchers,
        refreshStateAction: LauncherActions.refreshLaunchers,
      };
      this.crudRegistryService.crudRegistry.set(this.crudRegistryName, crudRegistry);
    }
  }
}
