import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { FilterManager } from '../filter/filter-manager';
import { Column, createSelectRowColumn, createInputColumn, setColumnDefs, createSelectColumn } from '../table-layout/column-definitions';
import { Subject, Observable, combineLatest } from 'rxjs';
import { Application, RuleConfig, RuleV2 } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { RuleQueryParameter } from '@agilicus/angular';
import { TableElement } from '../table-layout/table-element';
import { ActionApiApplicationsSavingRule } from '@app/core/api-applications/api-applications.actions';
import { updateTableElements } from '../utils';
import { selectApiApplications, selectApiApplicationsRefreshData } from '@app/core/api-applications/api-applications.selectors';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import {
  getPolicyDataBeforeApplicationInit$,
  getTargetPolicyTemplateInstanceResource,
  PolicyTemplateInstanceResource,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import {
  selectPolicyTemplateInstanceRefreshDataValue,
  selectPolicyTemplateInstanceResourcesList,
} from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import {
  initPolicyTemplateInstances,
  savingPolicyTemplateInstance,
} from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { getHttpConditionFromRule } from '@app/core/api-applications/api-applications-utils';

export interface QueryParameterElement extends RuleQueryParameter, TableElement {}

export enum QueryParameterMatchTypeOptionEnum {
  exact = 'exact',
  regex = 'regex',
}

@Component({
  selector: 'portal-application-query-parameters',
  templateUrl: './application-query-parameters.component.html',
  styleUrls: ['./application-query-parameters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationQueryParametersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private currentApplication: Application;
  private currentRuleCopy: RuleV2 | RuleConfig;
  private policyTemplateInstanceResourceCopy: PolicyTemplateInstanceResource;
  public tableData: Array<QueryParameterElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public columnDefs: Map<string, Column<QueryParameterElement>> = new Map();
  public rowObjectName = 'QUERY PARAMETER';
  private localApplicationsRefreshDataValue = 0;
  private localPolicyTemplateInstanceRefreshDataValue = 0;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    const policyDataBeforeApplicationInit$ = getPolicyDataBeforeApplicationInit$(
      this.store,
      selectPolicyTemplateInstanceResourcesList,
      selectPolicyTemplateInstanceRefreshDataValue
    );
    const appState$ = this.store.pipe(select(selectApiApplications));
    const refreshApplicationsDataState$ = this.store.pipe(select(selectApiApplicationsRefreshData));
    combineLatest([policyDataBeforeApplicationInit$, appState$, refreshApplicationsDataState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([policyDataBeforeApplicationInitResp, appStateResp, refreshApplicationsDataStateResp]) => {
        const policyTemplateInstanceResourceListStateResp = policyDataBeforeApplicationInitResp[0];
        const refreshPolicyTemplateInstanceDataStateResp = policyDataBeforeApplicationInitResp[1];
        if (appStateResp === undefined) {
          this.resetEmptyTable();
          return;
        }
        this.currentApplication = appStateResp.current_application;
        if (!this.currentRuleCopy) {
          this.currentRuleCopy = cloneDeep(appStateResp.current_rule);
        }
        if (this.currentRuleCopy === undefined) {
          return;
        }
        const targetPolicyTemplateInstanceResource = !!policyTemplateInstanceResourceListStateResp
          ? getTargetPolicyTemplateInstanceResource(policyTemplateInstanceResourceListStateResp, this.currentApplication?.id)
          : undefined;
        this.policyTemplateInstanceResourceCopy = cloneDeep(targetPolicyTemplateInstanceResource);
        if (
          this.tableData.length === 0 ||
          this.localApplicationsRefreshDataValue !== refreshApplicationsDataStateResp ||
          this.localPolicyTemplateInstanceRefreshDataValue !== refreshPolicyTemplateInstanceDataStateResp
        ) {
          this.localApplicationsRefreshDataValue = refreshApplicationsDataStateResp;
          this.localPolicyTemplateInstanceRefreshDataValue = refreshPolicyTemplateInstanceDataStateResp;
          if (this.localApplicationsRefreshDataValue !== 0 && this.localPolicyTemplateInstanceRefreshDataValue !== 0) {
            this.currentRuleCopy = cloneDeep(appStateResp.current_rule);
            this.setEditableColumnDefs();
            this.updateTable();
          }
        }
      });
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getQueryParameterListFromRule(rule: RuleV2 | RuleConfig): Array<RuleQueryParameter> | undefined {
    const ruleCondition = getHttpConditionFromRule(rule);
    return ruleCondition.query_parameters;
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const queryParameters = this.getQueryParameterListFromRule(this.currentRuleCopy);
    const data = [];
    if (queryParameters !== undefined && queryParameters.length !== 0) {
      for (let i = 0; i < queryParameters.length; i++) {
        data.push(this.createQueryParameterElement(queryParameters[i], i));
      }
    }
    updateTableElements(this.tableData, data);
  }

  private createQueryParameterElement(queryParam: RuleQueryParameter, index: number): QueryParameterElement {
    const data: QueryParameterElement = {
      exact_match: queryParam.exact_match,
      name: queryParam.name,
      match_type: queryParam.match_type,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private getNameColumn(): Column<QueryParameterElement> {
    const column = createInputColumn('name');
    column.requiredField = () => true;
    column.isUnique = true;
    return column;
  }

  private getExactMatchColumn(): Column<QueryParameterElement> {
    const column = createInputColumn('exact_match');
    column.displayName = 'Match';
    column.getHeaderTooltip = () => {
      return `The given query parameter must exist and match this value according to the "Match type" column`;
    };
    return column;
  }

  private getMatchTypeColumn(): Column<QueryParameterElement> {
    const column = createSelectColumn('match_type');
    column.allowedValues = [QueryParameterMatchTypeOptionEnum.exact, QueryParameterMatchTypeOptionEnum.regex];
    column.getHeaderTooltip = () => {
      return `Choose "Exact" for case sensitive, exact matching. Choose "Regex" for a regex match.`;
    };
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getNameColumn(), this.getExactMatchColumn(), this.getMatchTypeColumn()], this.columnDefs);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const nameColumn = this.columnDefs.get('name');
    nameColumn.isEditable = !this.fixedTable;

    const exactMatchColumn = this.columnDefs.get('exact_match');
    exactMatchColumn.isEditable = !this.fixedTable;

    const matchTypeColumn = this.columnDefs.get('match_type');
    matchTypeColumn.isEditable = !this.fixedTable;
  }

  public makeEmptyTableElement(): QueryParameterElement {
    return {
      exact_match: '',
      name: '',
      match_type: QueryParameterMatchTypeOptionEnum.exact,
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Receives a QueryParameterElement from the table then updates and saves
   * the current application.
   */
  public updateEvent(updatedQueryParameter: QueryParameterElement): void {
    this.updateQueryParameters();
    this.modifyCurrentRule();
  }

  public deleteSelected(queryParametersToDelete: Array<QueryParameterElement>): void {
    this.removeQueryParameters();
    this.modifyCurrentRule();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private getQueryParametersFromTable(): Array<RuleQueryParameter> {
    const queryParameters: Array<RuleQueryParameter> = [];
    for (const element of this.tableData) {
      const queryParameter: RuleQueryParameter = {
        name: element.name,
        exact_match: element.exact_match,
        match_type: element.match_type,
      };
      queryParameters.push(queryParameter);
    }
    return queryParameters;
  }

  private updateQueryParameters(): void {
    const queryParameters = this.getQueryParametersFromTable();
    const ruleCondition = getHttpConditionFromRule(this.currentRuleCopy);
    ruleCondition.query_parameters = queryParameters;
  }

  private removeQueryParameters(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
    this.updateQueryParameters();
  }

  private updatePolicyTemplateWithCurrentRuleChanges(): void {
    const currentRuleAsRuleConfig = this.currentRuleCopy as RuleConfig;
    let targetRule = this.policyTemplateInstanceResourceCopy.spec.template.rules.find((rule) => rule.name === currentRuleAsRuleConfig.name);
    for (const key of Object.keys(targetRule)) {
      targetRule[key] = this.currentRuleCopy[key];
    }
  }

  private updateAndSavePolicyTemplateRule(): void {
    this.updatePolicyTemplateWithCurrentRuleChanges();
    this.store.dispatch(
      savingPolicyTemplateInstance({
        obj: this.policyTemplateInstanceResourceCopy,
        trigger_update_side_effects: false,
        notifyUser: true,
      })
    );
  }

  private modifyCurrentRule(): void {
    const currentRuleAsRuleV2 = this.currentRuleCopy as RuleV2;
    if (!currentRuleAsRuleV2.metadata) {
      // Is a RuleConfig
      this.updateAndSavePolicyTemplateRule();
    } else {
      this.store.dispatch(new ActionApiApplicationsSavingRule(currentRuleAsRuleV2));
    }
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<QueryParameterElement>; element: QueryParameterElement }): void {
    if (params.column.name !== 'match_type') {
      return;
    }
    params.element.match_type = params.value;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }
}
