import { OrgQualifiedPermission, selectPermissionsSelector } from './permissions.selectors';
import { UserPermissions } from '../user.models';
import { createSelector, MemoizedSelector, DefaultProjectorFn, Store, select } from '@ngrx/store';
import { selectApiOrgId } from '../user.selectors';
import { PropertyNamesOfType } from '@app/shared/utilities/generics/type-scrubbers';
import { combineLatest, map, Observable } from 'rxjs';
import { selectCanAdminApps, selectCanAdminOrReadApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminOrReadResources, selectCanAdminResources } from '@app/core/user/permissions/resources.selectors';
import { selectCanAdminOrReadUsers, selectCanAdminUsers, selectCanReadUsers } from '@app/core/user/permissions/users.selectors';
import { selectCanReadDiagnostics } from '@app/core/user/permissions/diagnostics.selectors';
import { selectCanReadMetrics } from '@app/core/user/permissions/metrics.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { selectCanAdminAuditDestinations } from '@app/core/user/permissions/audit-destinations.selectors';
import { createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { selectCanReadAudits } from '@app/core/user/permissions/audits.selectors';
import { AppState } from '@app/core';
import { selectCanAdminLabels } from './labelled-objects.selectors';
import { selectCanAdminRules } from './rules.selectors';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';

export interface AllPermissions {
  hasUsersReadOrAdminPermissions: boolean;
  hasUsersAdminPermissions: boolean;
  hasApplicationReadOrAdminPermissions: boolean;
  hasApplicationAdminPermissions: boolean;
  hasResourceReadOrAdminPermissions: boolean;
  hasResourceAdminPermissions: boolean;
  hasIssuersAdminPermissions: boolean;
  hasAuditPermissions: boolean;
  hasAuditDestinationAdminPermissions: boolean;
  hasDiagnosticsReadOrAdminPermissions: boolean;
  hasMetricsReadonlyPermissions: boolean;
  hasLabelAdminPermissions: boolean;
  hasRulesAdminPermissions: boolean;
  requestFlowEnabled: boolean;
}

export function hasRole(role: string, permissions: string[]): boolean {
  return !!permissions.find((value) => value === role);
}

export function makePermission(orgId: string, permissions: string[], roles: string[]): OrgQualifiedPermission {
  let hasPermissions: boolean | undefined;
  if (permissions) {
    hasPermissions = roles.map((role) => hasRole(role, permissions)).reduce((previous, current) => previous || current, false);
  }
  const result: OrgQualifiedPermission = {
    hasPermission: hasPermissions,
    orgId,
  };
  return result;
}

type UserPermissionTypeName = PropertyNamesOfType<UserPermissions, string[]>;

export function createPermissionSelector(
  api: UserPermissionTypeName,
  roles: string[]
): MemoizedSelector<AppState, OrgQualifiedPermission, DefaultProjectorFn<OrgQualifiedPermission>> {
  const selector = createSelector(selectApiOrgId, selectPermissionsSelector, (orgId: string, permissions: UserPermissions | undefined) => {
    let apiPermissions: string[];
    if (permissions) {
      apiPermissions = permissions[api];
    }
    return makePermission(orgId, apiPermissions, roles);
  });
  return selector;
}

/**
 * Gets all permissions used to determine access to all admin portal screens.
 */
export function getCombinedPermissionsData$(store: Store<AppState>): Observable<AllPermissions> {
  const hasUsersReadOrAdminPermissions$ = store.pipe(select(selectCanAdminOrReadUsers));
  const hasUsersAdminPermissions$ = store.pipe(select(selectCanAdminUsers));
  const hasAuditPermissions$ = store.pipe(select(createCombinedPermissionsSelector(selectCanReadAudits, selectCanReadUsers)));
  const hasAuditDestinationAdminPermissions$ = store.pipe(select(selectCanAdminAuditDestinations));
  const hasIssuersAdminPermissions$ = store.pipe(select(selectCanAdminIssuers));
  const hasApplicationReadOrAdminPermissions$ = store.pipe(select(selectCanAdminOrReadApps));
  const hasApplicationAdminPermissions$ = store.pipe(select(selectCanAdminApps));
  const hasResourceReadOrAdminPermissions$ = store.pipe(select(selectCanAdminOrReadResources));
  const hasResourceAdminPermissions$ = store.pipe(select(selectCanAdminResources));
  const hasDiagnosticsReadOrAdminPermissions$ = store.pipe(select(selectCanReadDiagnostics));
  const hasMetricsReadonlyPermissions$ = store.pipe(select(selectCanReadMetrics));
  const hasLabelAdminPermissions$ = store.pipe(select(selectCanAdminLabels));
  const hasRulesAdminPermissions$ = store.pipe(select(selectCanAdminRules));
  const currentOrganisation$ = store.pipe(select(selectCurrentOrganisation));
  return combineLatest([
    hasUsersAdminPermissions$,
    hasAuditPermissions$,
    hasAuditDestinationAdminPermissions$,
    hasIssuersAdminPermissions$,
    hasApplicationReadOrAdminPermissions$,
    hasResourceAdminPermissions$,
    hasUsersReadOrAdminPermissions$,
    hasDiagnosticsReadOrAdminPermissions$,
    hasResourceReadOrAdminPermissions$,
    hasApplicationAdminPermissions$,
    hasMetricsReadonlyPermissions$,
    hasLabelAdminPermissions$,
    hasRulesAdminPermissions$,
    currentOrganisation$,
  ]).pipe(
    map(
      ([
        hasUsersAdminPermissionsResp,
        hasAuditPermissionsResp,
        hasAuditDestinationAdminPermissionsResp,
        hasIssuersAdminPermissionsResp,
        hasApplicationReadOrAdminPermissionsResp,
        hasResourceAdminPermissionsResp,
        hasUsersReadOrAdminPermissionsResp,
        hasDiagnosticsReadOrAdminPermissionsResp,
        hasResourceReadOrAdminPermissionsResp,
        hasApplicationAdminPermissionsResp,
        hasMetricsReadonlyPermissionsResp,
        hasLabelAdminPermissionsResp,
        hasRulesAdminPermissionsResp,
        currentOrganisationResp,
      ]) => {
        return {
          hasUsersReadOrAdminPermissions: hasUsersReadOrAdminPermissionsResp?.hasPermission,
          hasUsersAdminPermissions: hasUsersAdminPermissionsResp?.hasPermission,
          hasApplicationReadOrAdminPermissions: hasApplicationReadOrAdminPermissionsResp?.hasPermission,
          hasApplicationAdminPermissions: hasApplicationAdminPermissionsResp?.hasPermission,
          hasResourceReadOrAdminPermissions: hasResourceReadOrAdminPermissionsResp?.hasPermission,
          hasResourceAdminPermissions: hasResourceAdminPermissionsResp?.hasPermission,
          hasIssuersAdminPermissions: hasIssuersAdminPermissionsResp?.hasPermission,
          hasAuditPermissions: hasAuditPermissionsResp?.hasPermission,
          hasAuditDestinationAdminPermissions: hasAuditDestinationAdminPermissionsResp?.hasPermission,
          hasDiagnosticsReadOrAdminPermissions: hasDiagnosticsReadOrAdminPermissionsResp?.hasPermission,
          hasMetricsReadonlyPermissions: hasMetricsReadonlyPermissionsResp?.hasPermission,
          hasLabelAdminPermissions: hasLabelAdminPermissionsResp?.hasPermission,
          hasRulesAdminPermissions: hasRulesAdminPermissionsResp?.hasPermission,
          requestFlowEnabled: currentOrganisationResp?.owner_config?.disable_user_requests === false,
        };
      }
    )
  );
}
