  <div class="status-form-container">
    <div class="title">
      <h4>Status</h4>
    </div>

    <form 
      class="status-form" 
      [formGroup]="statusForm"
      spellcheck="false"
      autocomplete="off"
    >
      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="{{ getStatusPlaceholder() }}" 
          formControlName="overall_status"
        >
        <span *ngIf="getStatusPlaceholder() === ''" matPrefix>
          <mat-icon 
            [ngClass]="{ 
              'success-color': getInstanceStatusIconColor() === 'success',
              'intermediate-color': getInstanceStatusIconColor() === 'intermediate',
              'warn-color': getInstanceStatusIconColor() === 'warn'
            }"
          >
            {{ getInstanceStatusIcon() }}
          </mat-icon>
        </span>
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Running Replicas" 
          formControlName="running_replicas"
        >
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Error Message" 
          formControlName="error_message"
        >
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Restarts" 
          formControlName="restarts"
        >
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Last Apply Time" 
          formControlName="last_apply_time"
        >
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Running Image" 
          formControlName="running_image"
        >
      </mat-form-field>

      <mat-form-field>
        <input 
          matInput 
          type="text" 
          placeholder="Running Hash" 
          formControlName="running_hash"
        >
      </mat-form-field>
    </form>
  </div>