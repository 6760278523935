import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core';
import { GettingStartedTask } from '@app/core/user/preferences/getting-started-tasks.enum';
import { GettingStartedData } from '@app/core/user/preferences/getting-started-data';
import {
  getTaskCompletedStatus,
  getTaskCompleteToggleText,
  toggleGettingStartedTask,
} from '@app/core/user/preferences/user-preference-utils';
import { selectApiOrgId, selectGettingStartedData } from '@app/core/user/user.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceTypeEnum } from '@agilicus/angular';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';

@Component({
  selector: 'portal-expose-resource-guide',
  templateUrl: './expose-resource-guide.component.html',
  styleUrls: ['./expose-resource-guide.component.scss', '../getting-started-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExposeResourceGuideComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public gettingStartedDataCopy: GettingStartedData;
  public pageDescriptiveText = `A Zero-Trust Architecture provides access to individual resources for individual users. 
  Earlier you set up a connector to link resources to our cloud. Now we’re defining those resources. 
  Resources can include:`;
  public pageSubDescriptiveList = [
    `Web applications (exposed via Identity-Aware Web Application Firewall Proxy)`,
    `Desktops (acting as a Remote Desktop Gateway)`,
    `Shares (exposing directories and files via HTTPS and WebDav for browsers and desktops alike)`,
    `Network resources (e.g. a Database, an ERP server)`,
  ];
  public productGuideLink = `https://www.agilicus.com/anyx-guide/resources-overview/`;
  public isTaskComplete = false;
  public targetTask = GettingStartedTask.completed_expose_resource;
  public exposeResourceFormGroup: UntypedFormGroup;

  public getTaskCompletedStatus = getTaskCompletedStatus;
  public getTaskCompleteToggleText = getTaskCompleteToggleText;

  // This is required in order to reference the enums in the html template.
  public resourceTypeEnum = ResourceTypeEnum;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private routerHelperService: RouterHelperService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    combineLatest([orgId$, gettingStartedData$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, gettingStartedDataResp]) => {
        this.orgId = orgIdResp;
        this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
        this.isTaskComplete = getTaskCompletedStatus(this.targetTask, this.gettingStartedDataCopy);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.exposeResourceFormGroup = this.formBuilder.group({
      resourceType: ['', Validators.required],
    });
  }

  public getResourceTypeValueFromForm(): string {
    return this.exposeResourceFormGroup.get('resourceType').value;
  }

  public needToExposeApplication(): boolean {
    return this.getResourceTypeValueFromForm() === ResourceTypeEnum.application;
  }

  public needToExposeApplicationService(): boolean {
    return this.getResourceTypeValueFromForm() === ResourceTypeEnum.application_service;
  }

  public needToExposeLauncher(): boolean {
    return this.getResourceTypeValueFromForm() === ResourceTypeEnum.launcher;
  }

  public needToExposeDesktop(): boolean {
    return this.getResourceTypeValueFromForm() === ResourceTypeEnum.desktop;
  }

  public needToExposeShare(): boolean {
    return this.getResourceTypeValueFromForm() === ResourceTypeEnum.fileshare;
  }

  public needToExposeOther(): boolean {
    return this.getResourceTypeValueFromForm() === 'other';
  }

  public onImageClick(routerLink: string): void {
    this.routerHelperService.routeToScreen(routerLink, this.orgId);
  }

  public onCompleteToggle(isChecked: boolean): void {
    toggleGettingStartedTask(isChecked, this.targetTask, this.gettingStartedDataCopy, this.store);
  }
}
