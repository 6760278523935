import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FileShareService, FilesService } from '@agilicus/angular';
import { AppState, NotificationService } from '..';
import { selectCanAdminApps } from '../user/permissions/app.selectors';
import * as FileShareServiceActions from './file-share-service.actions';
import {
  selectFileShareServices,
  selectFileShareServiceEntity,
  selectFileShareServiceList,
  selectFileShareServiceShouldPopulateValue,
} from './file-share-service.selectors';
import { FileShareServiceState } from './file-share-service.reducer';
import {
  createDeleteListCrudStateObjectsEffect,
  createDeletingCrudStateObjectEffect,
  createEntityRefreshOrgDependentEffect,
  createInitStateEffect,
  createLoadStateEffect,
  createOrgSwitchedEffect,
  createSaveListCrudStateObjectsEffect,
  createSaveOneCrudStateObjectEffect,
  createSavingResourceIconFileEffect,
  createSetCrudStateEffect,
  createUpdateResourceWithIconFileEffect,
} from '../helpers/effect-factories';
import { FileShareServiceStateService } from '../state-services/file-share-service-state.service';

@Injectable()
export class FileShareServiceEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private fileShareServiceStateService: FileShareServiceStateService,
    private filesService: FilesService,
    private notificationService: NotificationService
  ) {}

  public initState$ = createInitStateEffect<FileShareServiceState>(
    this.store,
    this.actions$,
    FileShareServiceActions.initFileShareServices,
    FileShareServiceActions.getFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    selectFileShareServices
  );

  public refreshOrgState$ = createEntityRefreshOrgDependentEffect(
    this.store,
    this.actions$,
    FileShareServiceActions.getFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    selectFileShareServiceShouldPopulateValue
  );

  public loadState$ = createLoadStateEffect<FileShareService, string>(
    this.store,
    this.actions$,
    FileShareServiceActions.getFileShareServices,
    FileShareServiceActions.clearFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    this.fileShareServiceStateService,
    selectCanAdminApps
  );

  public setCrudState$ = createSetCrudStateEffect<FileShareService, string>(
    this.store,
    this.actions$,
    FileShareServiceActions.loadFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    this.fileShareServiceStateService,
    selectFileShareServiceList
  );

  public savingObjectState$ = createSaveOneCrudStateObjectEffect<FileShareService, string>(
    this.store,
    this.actions$,
    FileShareServiceActions.savingFileShareService,
    FileShareServiceActions.maintainFileShareServices,
    this.fileShareServiceStateService,
    selectFileShareServiceEntity
  );

  public savingObjectListState$ = createSaveListCrudStateObjectsEffect<FileShareService, string>(
    this.store,
    this.actions$,
    FileShareServiceActions.savingFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    this.fileShareServiceStateService,
    selectFileShareServices
  );

  public deletingObjectState$ = createDeletingCrudStateObjectEffect<FileShareService, string>(
    this.actions$,
    FileShareServiceActions.deletingFileShareService,
    FileShareServiceActions.refreshFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    this.fileShareServiceStateService
  );

  public deletingObjectListState$ = createDeleteListCrudStateObjectsEffect<FileShareService, string>(
    this.actions$,
    FileShareServiceActions.deletingFileShareServices,
    FileShareServiceActions.refreshFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    this.fileShareServiceStateService
  );

  public orgSwitched$ = createOrgSwitchedEffect<FileShareServiceState>(
    this.store,
    this.actions$,
    FileShareServiceActions.getFileShareServices,
    FileShareServiceActions.maintainFileShareServices,
    selectFileShareServices
  );

  // Custom Effects:

  public savingIconFile$ = createSavingResourceIconFileEffect<FileShareService>(
    this.store,
    this.actions$,
    FileShareServiceActions.savingIconFileShareServices,
    FileShareServiceActions.successfulIconSaveFileShareServices,
    FileShareServiceActions.failedIconSaveFileShareServices,
    this.filesService,
    this.notificationService
  );

  public updateResourceWithIconFile$ = createUpdateResourceWithIconFileEffect<FileShareService>(
    this.actions$,
    FileShareServiceActions.successfulIconSaveFileShareServices,
    FileShareServiceActions.savingFileShareService
  );
}
